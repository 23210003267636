import React, { useEffect, useState } from "react";
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  Switch,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import commonClasses from "../../../../../constants/common";
import { prettyString } from "../../../../ui/TextTransform";
import { useStyles } from "../../../../../assets/styles/ManagersListStyles";
import managerListIcon from "../../../../../assets/images/managerListIcon.png";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#fafafa",
    color: "#333",
    fontSize: 16,
    border: "none",
    borderRadius: 0,
    outline: "none",
  },
  body: {
    fontSize: 16,
    border: "none",
    outline: "none",
    borderRadius: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 60,
    border: "none",
    outline: "none",
    borderRadius: 0,
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
  },
}))(TableRow);

const StyledTableBody = withStyles(() => ({
  root: {
    border: "none",
    outline: "none",
    height: 40,
    borderRadius: 0,
  },
}))(TableBody);

const ManagersTable = (props) => {
  const classes = useStyles();
  const commonStyles = commonClasses();
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));
  const {
    data,
    onManagerDelete = {},
    onManagerEdit = {},
    title,
    emailToggle = {},
  } = props;
  const [list, setList] = useState(data);

  useEffect(() => {
    setList(data);
  }, [data]);

  const RenderData = (item) => (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <Avatar className={commonStyles.managerIcon}>
          <img
            className={classes.imageIconStyle}
            src={item.icon}
            alt="managerIcon"
          />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={`${prettyString(item?.heading)}`}
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" display="block">
              {item?.value1 !== undefined
                ? `${prettyString(item?.value1)} ${prettyString(item?.value2)}`
                : `${prettyString(item.value4)}`}
            </Typography>
            <Typography component="span" variant="body2" display="block">
              {item?.value3}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );

  const RenderAction = (item, index, value) => (
    <div>
      <IconButton
        edge="end"
        disabled={auth.userType === 9}
        aria-label="edit"
        onClick={() => onManagerEdit(index, item, value)}
      >
        <EditIcon />
      </IconButton>
      {value === "Additional Manager" ? (
        <IconButton
          edge="end"
          disabled={auth.userType === 9}
          aria-label="delete"
          onClick={() => onManagerDelete(index, item)}
        >
          <DeleteIcon />
        </IconButton>
      ) : null}
    </div>
  );

  const RenderHeading = () => (
    <>
      <StyledTableCell>{title}</StyledTableCell>
      <StyledTableCell align="right">Emails</StyledTableCell>
      <StyledTableCell align="right">Action</StyledTableCell>
    </>
  );

  return (
    <Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className={classes.headingStyle}>
            <StyledTableRow>{RenderHeading()}</StyledTableRow>
          </TableHead>
          <StyledTableBody>
            {list.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="item">
                  {RenderData({
                    icon: managerListIcon,
                    heading: item?.title,
                    value1: item?.first_name,
                    value2: item?.last_name,
                    value3: item?.email,
                    value4: item?.name,
                  })}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Switch
                    disabled={auth.userType === 9}
                    checked={item.email_group ? item.email_group.arrival_request : false}
                    onChange={() => {
                      emailToggle(item, title);
                    }}
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </StyledTableCell>
                <StyledTableCell align="right" style={{ width: 150 }}>
                  {RenderAction(item, index, title)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default ManagersTable;
