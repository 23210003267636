import React from "react";
import { Link } from "@material-ui/core";

import sessionWarning from "../../../../assets/images/sessionWarning.png";
import { useStyles } from "../../../../assets/styles/HomeStyles";

const TermsAndConditionPopup = (props) => {
  const classes = useStyles();
  const { declineTermsAndConditions = {}, openDialog = {}, navigation, openAircraftListScreen = {} } = props;

  return (
    <div className={classes.modalCont}>
      <img alt="" src={sessionWarning} className={classes.warningImageStyle} />
      <div className={classes.linkContMain}>
        <div className={classes.linkCont}>
          {
            navigation === "terms" ?
              <div>
                Please accept NOZL's&nbsp;
                <Link
                  href={process.env.REACT_APP_PRIVACY_POLICY}
                  variant="body2"
                  alignItems="center"
                  alignContent="center"
                  className={classes.link}
                >
                  Privacy Policy
                </Link>
                &nbsp;and&nbsp;
                <Link
                  href={process.env.REACT_APP_TERMS_CONDITION}
                  variant="body2"
                  alignItems="center"
                  alignContent="center"
                  className={classes.link}
                >
                  Terms & Conditions
                </Link>{" "}
                to continue.
              </div>
              :
              <div>
                There are no aircraft assigned to you. You can add an aircraft to proceed with the arrival request.
              </div>
          }
        </div>
      </div>
      { navigation === "terms" ?
      <div className={classes.buttonCont}>
        <button
          onClick={declineTermsAndConditions}
          className={classes.buttonStyle}
        >
          Decline
        </button>
        <button onClick={openDialog} className={classes.buttonAcceptStyle}>
          Accept
        </button>
      </div>
      : 
      <div className={classes.buttonCont2}>
        <button onClick={openAircraftListScreen} className={classes.buttonAcceptStyle}>
          OK
        </button>
      </div> 
      }
    </div>
  );
};

export default TermsAndConditionPopup;
