import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useButtonStyles = makeStyles(() => ({
  bottomButtonCont: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: 50,
  },
  buttonCont : {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50
  },
  backButtonText: {
    width: 140,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    cursor: "pointer",
    backgroundColor: "#ebecf0",
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#212121",
    textTransform: "initial",
  },
  nextButtonText: {
    marginLeft: 20,
    textTransform: "initial",
    background: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
    color: "#fff",
    "&:hover": {
      background: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
      backgroundColor: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.4,
      color: "#fff",
    },
  },
  paragonNextButtonText: {
    marginLeft: 20,
    textTransform: "initial",
    background: colors.paragonPrimaryColor,
    color: "#fff",
    "&:hover": {
      background: colors.paragonPrimaryColor,
      backgroundColor: colors.paragonPrimaryColor,
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.4,
      color: "#fff",
    },
  },
  disabledButton: {
    width: 140,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    cursor: "not-allowed",
    background: "linear-gradient(180deg, #DF2935 0%, #B10000 100%)",
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#fff",
    opacity: 0.4,
    marginLeft: 20,
    textTransform: "initial",
  },
  paragonDisabledButton:{
    background: colors.paragonPrimaryColor,
    color: "#fff",
    "&:hover": {
      background: colors.paragonPrimaryColor,
      backgroundColor: colors.paragonPrimaryColor,
    },
  }
}));

export { useButtonStyles };
