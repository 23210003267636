export default {
  rateType: {
    retailMinusPrice: 1,
    costPlusPrice: 2,
    retailMinusDiscountPrice: 3,
    costPlusDiscountPrice: 4,
    retailMinusCfpPrice: 5,
    costPlusCfpPrice: 6,
    retailMinusCaaPrice: 7,
    costPlusCaaPrice: 8,
    bothCommonPrice: 9,
    bothDiscountPrice: 10,
    bothCfpPrice: 11,
    bothCaaPrice: 12,
    onlyRetailPrice: 13,
  },
  discount: {
    fuel_Type: 0,
    jet_A: 1,
    hundredLL: 2,
    saf: 4,
    jetA_Saf_Both: 5,
  },
  fuelType: {
    fuel_type: 0,
    JET_A: 1,
    HUNDRED_LL: 2,
    SAF: 4
  },
  pricingMethod: {
    pricingMethod1: 1,
    pricingMethod2: 2
  },
  fboArrived:{
    fboArrived: 1,
    fboNotArrived: 2
  },
  rampFee: {
    noRampFee: 0,
    isRampFee: 1
  },
  fuelApplied: {
    noHundredLL: 0,
    hundredLL: 1,
    noJetA: 0,
    jetA: 1,
    noSaf: 0,
    saf: 1
  },
  string: {
    jetA: "JET A",
    hundredLL: "100 LL",
    saf: "SAF"
  },
  waiveTypeLocal: {
    waiveTypeLocal0: 0,
    waiveTypeLocal1: 1,
    waiveTypeLocal2: 2,
    waiveTypeLocal3: 3
  }
};
