import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FlightIcon from "@material-ui/icons/Flight";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";

import { getSearchList } from "./SearchAircraftResult";
import {
  deleteAircraft,
  getAircraftList,
  submitProfile,
  getAssignedPilotAircraftList,
  getAircraftMakerList,
  getAirplaneList,
} from "../../../redux/account/account.actions";
import { favouriteFboItem } from "../../../redux/fbos/fbos.actions";
import { getAllInvitedPilots } from "../../../redux/pilots/pilots.actions";
import Popup from "../../scene/profile-setup/Popup";
import ReactSectionList from "../../ui/ReactSectionList";
import AddAircraftForm from "./AddAircraftForm";
import commonClasses from "../../../constants/common";

import algoliasearch from "algoliasearch";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import colors from "../../../constants/colors";
import { toast } from "react-toastify";
import CustomLoader from "../../ui/CustomLoader";
import styled from "styled-components";
import { calculateUpdateList } from "../app/Fuelrequest/FuelRequestHelper";

const StyledListContainer = styled.div`
  display: flex-wrap;
`;

const useStyles = makeStyles(() => ({
  header: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "space-between",
  },
  button: {
    width: 300,
  },
  button2: {
    width: "400px !important",
    marginLeft: 100,
    marginRight: 100,
  },
  appMain: {},
  submitBtn: {
    background: colors.primaryColor,
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  paragonSubmit: {
    background: colors.paragonPrimaryColor,
    backgroundColor: colors.paragonPrimaryColor,
    "&:hover": {
      backgroundColor: colors.paragonPrimaryColor,
    },
  },
  baseLocationTextStyle: {
    fontSize: 14,
    color: "#555",
    opacity: 0.8,
    marginTop: -5,
    fontWeight: "bold",
  },
  notchedOutline: {
    borderWidth: "1px !important",
    padding: 5,
    borderColor: "#dcdcdc !important",
    color: "#fff",
    hintColor: "#fff",
  },
  emptyListStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    height: "calc(100vh - 270px)",
    color: colors.lightGreyBlue,
  },
  label: {
    "&$focusedLabel": {
      color: "black",
    },
    "&$erroredLabel": {
      color: "black",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
}));

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_APPLICATION_KEY
);

const searchIndex = searchClient.initIndex(
  process.env.REACT_APP_ALGOLIA_INIT_INDEX
);
let params = {};
const AircraftList = (props) => {
  const dispatch = useDispatch();
  const { auth, account, notifications } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
    notifications: state.notifications,
  }));
  const classes = useStyles();
  const commonStyles = commonClasses();

  const [sectionListData, setSectionListData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [aircraftList, setAircraftList] = useState([]);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [open, setOpen] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [bookingsCount, setBookingsCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(
      getAircraftList({
        token: auth.token,
      })
    );
    dispatch(getAllInvitedPilots(auth.token));
    dispatch(getAirplaneList());
    dispatch(
      getAircraftMakerList({
        token: auth.token,
      })
    );
  }, [auth.token]);

  const updateList = async () => {
    try {
      let finalList = await calculateUpdateList(account, "withCustom");
      setSectionListData(finalList[1]);
      setAircraftList(finalList[1]);
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(favouriteFboItem({}));
    searchIndex
      .search("", { hitsPerPage: 10 })
      .then((response) => {
        setSearchResult(response.hits);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    updateList();
  }, [
    account.aircraftList,
    account.assignedAircraftList,
    account.tempAircraft,
    notifications.notifications,
  ]);

  const onAircraftDelete = async (index, item) => {
    setBookingsCount(item.bookingsCount !== undefined ? item.bookingsCount : 0);
    params = {
      aircraft: {
        _id: item._id,
      },
      token: auth.token,
      onSearchList: searchText === "" ? false : true,
    };
    if (index === true) {
      dispatch(deleteAircraft(params));
    } else {
      setOpen(true);
    }
  };

  const onAircraftEdit = (index, item) => {
    dispatch(getAssignedPilotAircraftList(auth.token, item?._id));
    setTimeout(() => {
      setEditObj(item);
      setBookingsCount(
        item.bookingsCount !== undefined ? item.bookingsCount : 0
      );
      setOpenEditPopup(true);
    }, 400);
  };

  const setOpenAddPopup = async (value) => {
    setOpenPopup(false);
  };

  useEffect(() => {}, [account.aircraftList]);


  useEffect(() => {}, [
    account?.assignedPilotListForAircraft,
    account.tempAircraft,
  ]);

  const openDialog = () => {
    let searchList = [];
    dispatch(deleteAircraft(params));
    setOpen(false);
    if (searchText !== "") {
      sectionListData.filter((e) => {
        let list = e.data.filter((value) => {
          return value._id !== params.aircraft._id;
        });
        if (list.length > 0) {
          let data = {
            title: e.title,
            data: list,
          };
          searchList.push(data);
        }
        setSectionListData(searchList);
      });
    }
  };

  const searchHandle = async (e) => {
    setSearchText(e.target.value);
    let result = await getSearchList(e.target.value, aircraftList);
    setSectionListData(result);
    if (e.target.value === "") {
      dispatch(
        getAircraftList({
          token: auth.token,
        })
      );
    }
  };

  useEffect(() => {}, [auth.token, account.aircraftList]);

  const editAircrafts = (value, item) => {
    if (value === false) {
      setOpenEditPopup(value);
      if (item === true) {
        onAircraftDelete(item, editObj);
      }
    }
  };

  return (
    <div style={{ padding: "0 20px" }}>
      {account.status === "loading" ? (
        <CustomLoader />
      ) : (
        <div className={classes.appMain}>
          <br />
          <div style={{ paddingBottom: 30 }} className={classes.header}>
            <div style={{ minWidth: 200 }}>
              <Button
                fullWidth
                className={classes.submitBtn}
                onClick={() => {
                  setOpenPopup(true);
                }}
                disableElevation
              >
                + Add Aircraft
              </Button>
              </div>
              <div style={{ minWidth: 200 }}>
                <TextField
                  id="search"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => searchHandle(e)}
                  value={searchText}
                  className={classes.searchStyle}
                  placeholder="Search"
                  inputProps={{
                    style: {
                      height: 5,
                    },
                  }}
                  InputProps={{
                    style: { color: "#000" },
                    classes: {
                      notchedOutline: classes.notchedOutline,
                      input: classes.inputLine,
                    },
                    color: "#000",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focusedLabel,
                      error: classes.erroredLabel,
                    },
                    className: classes.floatingLabelFocusStyle,
                  }}
                />
              </div>
            </div>
          <StyledListContainer
            style={{
              maxHeight: "calc(100vh - 190px)",
              overflowY: "auto",
            }}
          >
            <ReactSectionList
              keyExtractor={(item, index) => item.title + index}
              renderSectionHeader={(item) => (
                <div>{`${
                  sectionListData.length > 0 && item.title === "Aircraft"
                    ? auth?.userType === 2
                      ? "Flight Department Aircraft"
                      : "Aircraft"
                    : item.title
                }`}</div>
              )}
              renderItem={(item, index) => {
                return (
                  <ListItem className={classes.listItem}>
                    <ListItemAvatar>
                      <Avatar className={commonStyles.avatarAirplane}>
                        <FlightIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <div>
                      <ListItemText
                        primary={`${item.registryName}`.toUpperCase()}
                        secondary={
                          item.isOtherMaker === true
                            ? item.name !== undefined
                              ? `${item.name.toUpperCase()}`
                              : ""
                            : item.name !== undefined
                            ? `${item.name.toUpperCase()}`
                            : ""
                        }
                      />
                      <div className={classes.baseLocationTextStyle}>
                        {item.baseLocation}
                      </div>
                    </div>
                    {item.operatorName === "Aircraft" ? (
                      <ListItemSecondaryAction>
                        {aircraftList[0]?.data?.length !== 1 ? (
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => onAircraftEdit(index, item)}
                          >
                            <EditIcon />
                          </IconButton>
                        ) : null}

                        {aircraftList[0]?.data?.length !== 1 ? (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => onAircraftDelete(index, item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </ListItemSecondaryAction>
                    ) : null}
                  </ListItem>
                );
              }}
              sections={sectionListData}
            />
            {aircraftList.length === 0 ||
            (aircraftList[0]?.data?.length === 0 &&
              aircraftList.length === 1) ? (
              <h4 className={classes.emptyListStyle}>
                Please add atleast one active aircraft to proceed. Following
                approval, you will have the ability to mass upload or email a
                complete fleet list.
              </h4>
            ) : null}
            {(sectionListData.length === 0 ||
              (sectionListData[0]?.data?.length === 0 &&
                sectionListData.length === 1)) &&
            aircraftList.length > 0 ? (
              <h4 className={classes.emptyListStyle}>No result found.</h4>
            ) : null}
          </StyledListContainer>
          <Popup
            title="Add Aircraft"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            normalPopUp={true}
          >
            <AddAircraftForm
              searchResult={searchResult}
              setOpenPopup={setOpenAddPopup}
              location={props.location}
              operatorName={account?.user?.name}
            />
          </Popup>
          {bookingsCount === 0 ? (
            <Popup
              title="Aircraft Update"
              openPopup={openEditPopup}
              setOpenPopup={setOpenEditPopup}
              normalPopUp={true}
            >
              <AddAircraftForm
                searchResult={searchResult}
                setOpenEditPopup={editAircrafts}
                location={props.location}
                editObj={editObj}
                isEdit={true}
              />
            </Popup>
          ) : (
            <Dialog
              open={openEditPopup}
              onClose={() => setOpenEditPopup(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Aircraft with arrival request are not allowed to be edited.
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: 40,
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => setOpenEditPopup(false)}
                  color="secondary"
                  style={{
                    width: 80,
                    background: colors.primaryColor,
                    color: "white",
                    marginBottom: 10,
                  }}
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {/* Dialog for show to confirm before dialog close  */}

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {bookingsCount !== 0
                  ? "You have an upcoming arrival request for this aircraft. Deleting the aircraft will remove the arrival request. Do you still want to delete it?"
                  : "Are you sure you want to delete this aircraft?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={openDialog} color="secondary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default AircraftList;