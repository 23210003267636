import React, { useEffect, useState } from "react";
import { useStyles } from "../../../../assets/styles/HomeStyles";
import colors from "../../../../constants/colors";
import { isMobile } from "react-device-detect";
import algoliasearch from "algoliasearch";
import { uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Modal } from "@material-ui/core";
import { searchHistoryList, userBaseLocation } from "../../../../redux/account/account.actions";
import {
  getFbos,
  getGeoloc,
  favouriteFboItem,
  getFboGeoloc,
  getFboDefaultServices,
} from "../../../../redux/fbos/fbos.actions";
import * as mainServices from "../../../../services/mainService";
import AircraftListComponent from "../../../../components/scene/app/AirportFboScreen";
import CustomLoader from "../../../ui/CustomLoader";
import HomeBar from "../map/HomeBar";
import { useHistory } from "react-router-dom";
import * as authService from "../../../../services/authService.js";
import { logout, termsAndCondition } from "../../../../redux/auth/auth.actions";
import TermsAndConditionPopup from "./TermsAndConditionPopup";
import { toast } from "react-toastify";
import NozlMapView from "../map/NozlMapView";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_APPLICATION_KEY
);

const searchIndex = searchClient.initIndex(
  process.env.REACT_APP_ALGOLIA_INIT_INDEX
);

const Home = (props) => {
  const classes = useStyles(colors, isMobile);
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth, fbos, account } = useSelector((state) => ({
    auth: state.auth,
    fbos: state.fbos,
    account: state.account,
  }));
  
  const [currentLatLong, setCurrentLatLong] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [userLocation, setUserLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [selectedSearchItem, setSelectedSearchItem] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [airportArray, setAirportArray] = useState([]);
  const [selectionType, setSelectionType] = useState("");
  const [onSearch, setOnSearchText] = useState("");
  const [isTerms, setIsTerms] = useState(false);
  const [locationLatLong, setLocationLatLong] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isAircraftCountZero, setIsAircraftCountZero] = useState(false);

  useEffect(() => {
    if (auth.termsAndConditions === false) {
      setIsTerms(true);
    }
    else if (account.aircraftStatus && account.aircraftList.length === 0 && account.tempAircraft.length === 0 && account.assignedAircraftList.length === 0) {
      setIsAircraftCountZero(true);
    }
    if(account.aircraftList.length > 0 || account.tempAircraft.length > 0 || account.assignedAircraftList.length > 0) {
      setIsAircraftCountZero(false);
    }
  }, [account.isParagon, account.aircraftList, account.tempAircraft,searchResults, account.assignedAircraftList]);

  useEffect(() => {
    if (
      Object.values(selectedSearchItem).length === 0 &&
      Object.values(fbos.favouriteFboItem).length === 0
    ) {
      dispatch(getGeoloc({}));
      setSearchText("");
    }
    checkStatus();
  }, []);

  const onSearchClick = (value) => {
    dispatch(favouriteFboItem({}));
    setSearchText("");
    setOnSearchText(value);
    setSelectedSearchItem({});
  };

  const getSearchValue = () => {
    searchIndex
      .search("", {
        hitsPerPage: 1000,
        numericFilters: [
          ["nozlFboCount>0", "paragonFboCount>0", "unregisteredFboCount>0"],
        ],
      })
      .then((response) => {
        if (Object.values(fbos.favouriteFboItem).length > 0) {
          dispatch(getGeoloc({}));
          if (account?.baseLocation === "") {
            dispatch(userBaseLocation({}));
          }
          response.hits.map((item) => {
            if (item.icao === fbos.favouriteFboItem.icaoCode) {
              setCurrentLatLong({
                latitude: item.latitude,
                longitude: item.longitude,
              });
            }
          });
          setSelectionType("fboItem");
          setSelectedSearchItem(fbos.favouriteFboItem);
        }
        setSearchResults(response.hits);
      })
      .catch((err) => {});
  };

  // get aircraft list
  useEffect(() => {
    if (onSearch === "") {
      dispatch(getFbos({ token: auth.token }));
    }
    if(!!fbos.fboDefaultServices && fbos.fboDefaultServices.length===0){
      dispatch(getFboDefaultServices({token: auth.token}))
     }
  }, [auth.token]);

  const checkStatus = async () => {
    const res = await mainServices.getProfile({
      token: auth.token,
    });
    if (
      res.response_type === "success"
    ) {
      if(res.response.data.operatorUserDetail?.operatorDetail?.baseLocation !== ""){
        handleSelect(res.response.data.operatorUserDetail?.operatorDetail?.baseLocation)
      } else {
        dispatch(userBaseLocation({}));
        res.response.data.operatorUserDetail.isParagonUser === false
        ? setCurrentLatLong({
            latitude: 37.0902,
            longitude: -95.7128,
          })
        : setCurrentLatLong({
            latitude: 36.493588,
            longitude: -43.021871,
        })
      }
    }
    getSearchValue();
  };

  const handleSelect = (baseLocation) => {
    geocodeByAddress(baseLocation)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setCurrentLatLong({
          latitude: latLng.lat,
          longitude: latLng.lng,
        });
        setUserLocation({
          latitude: latLng.lat,
          longitude: latLng.lng,
        });
        dispatch(
          userBaseLocation({ latitude: latLng.lat, longitude: latLng.lng })
        );
      })
      .catch((error) => {
        account.isParagon === false || account.isParagon === ""
          ? setCurrentLatLong({
              latitude: 37.0902,
              longitude: -95.7128,
            })
          : setCurrentLatLong({
              latitude: 36.493588,
              longitude: -43.021871,
            });
      });
  };

  const getgeoloc = (item) => {
    setCurrentLatLong({
      latitude: item.latitude,
      longitude: item.longitude,
    });
    dispatch(
      getGeoloc({
        latitude: item.latitude,
        longitude: item.longitude,
      })
    );
    dispatch(
      getFboGeoloc({
        latitude: item.latitude,
        longitude: item.longitude,
      })
    );
  };

  const onClickMarker = (item, isClick) => {
    setCurrentLatLong({
      latitude: item.latitude,
      longitude: item.longitude,
    });
    dispatch(
      getFboGeoloc({
        latitude: item.latitude,
        longitude: item.longitude,
      })
    );
    setAirportArray([]);
    setSelectedSearchItem({});
    dispatch(favouriteFboItem({}));
    if (isClick === true) {
      setSelectionType("fboList");
      setSelectedSearchItem(item);
    } else {
      setSelectedSearchItem({});
    }
  };

  const selectedItem = (item, title) => {
    setLocationLatLong({});
    setAirportArray([]);
    getgeoloc(item);
    if (title === "ICAO") {
      setSearchText(item.icao);
    } else if (title === "IATA") {
      setSearchText(item.iata);
    } else if (title === undefined) {
      setSearchText(item.icao);
    } else {
      setSearchText(item.name);
    }
    setSelectionType("fboList");
    dispatch(favouriteFboItem({}));
    if (item.fromGoogle === undefined) {
      setSelectedSearchItem(item);
    }
    let history =
     !!account.searchHistoryList && account.searchHistoryList.length > 0 ? account.searchHistoryList : [];
    if (!item) {
      return;
    } else if (history && history.length > 4) {
      history.pop();
      history
        .filter((dataItem) => {
          return dataItem.icao !== item.icao;
        })
        .map((item) => {
          return item;
        });
      history.unshift(item);
      history.length = 5;
    } else if (history && history.length) {
      history = history
        .filter((dataItem) => {
          return dataItem.icao !== item.icao;
        })
        .map((item) => {
          return item;
        });
      history.unshift(item);
    } else {
      history
        .filter((dataItem) => {
          return dataItem.icao !== item.icao;
        })
        .map((item) => {
          return item;
        });
      history.push(item);
    }
    history = uniq(history);
    dispatch(searchHistoryList([...history]));
  };

  const selectedFboItem = (item) => {
    if (Object.values(item).length > 0) {
      setSelectionType("fboItem");
      setSelectedSearchItem(item);
      setLocationLatLong({});
      setSearchText(item.fboDetail !== undefined ? item.fboDetail.fboName : item.fboName);
    }
  };

  const getNewLat = (value, description) => {
    setOnSearchText("location");
    setSearchText(description.structured_formatting.main_text);
    setLocationLatLong({ latitude: value.lat, longitude: value.lng });
    let localArray = [];
    if (
      account.searchHistoryList !== undefined &&
      (account.searchHistoryList.length > 0 ||
        Object.values(account.searchHistoryList).length > 0)
    ) {
      localArray = account.searchHistoryList;
    }
    localArray.push(description);
    let history =
    account.searchHistoryList.length > 0 ? account.searchHistoryList : localArray;
    history = history
      .filter((dataItem) => {
        return (
          (dataItem.description !== undefined && dataItem.description) !==
          description.description
        );
      })
      .map((item) => {
        return item;
      });
    history.push(description);
    dispatch(searchHistoryList(history));
  };

  const searchAirportList = (airportItemValue, text) => {
    setAirportArray(airportItemValue);
  };

  const showAirportList = (value) => {
    if (value === true) {
      setOnSearchText("");
      setSelectedSearchItem({});
    }
  };

  const onCloseLayout = (onClose) => {
    if (onClose === true) {
      setSearchText("");
      setSelectedSearchItem({});
      dispatch(favouriteFboItem({}));
    }
  };

  const closeList = (value) => {
    setOnSearchText("");
    setSelectedSearchItem({});
    dispatch(favouriteFboItem({}));
  };

  const openDialog = async () => {
    const res = await authService.termsAndConditionsApi({
      token: auth.token,
    });
    if (res.response_type === "fail") {
      toast.info(res.response.message, {
        position: "bottom-center",
        closeButton: false,
      });
    } else {
      dispatch(termsAndCondition(false));
      setIsTerms(false);
    }
  };

  const openAircraftListScreen = async () => {
    setIsAircraftCountZero(false)
    history.push("aircraft-list")
  }
  const declineTermsAndConditions = () => {
    history.push("/login");
    dispatch(logout({ token: auth.token }));
  };

  const clearText = (value) => {
    setSearchText("");
    setOnSearchText("");
    setSelectedSearchItem({});
    setLocationLatLong({});
    dispatch(getGeoloc({}));
  };

  const dragMap = (value) => {
    if (value === true) {
      setOnSearchText("");
    }
  };

  return (
    <div className={classes.container}>
      {account.isParagon === "" || currentLatLong.latitude === 0 ? (
        <div className={classes.loaderStyle}>
          <CustomLoader />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <HomeBar
            searchText={searchText}
            setSearchText={setSearchText}
            setLocationLatLong={setLocationLatLong}
            clearText={clearText}
            onSearchValue={onSearch}
            currentLatLong={currentLatLong}
            onSearchClick={onSearchClick}
            searchAirportList={searchAirportList}
          />
          <div className="left-panel"></div>
          <NozlMapView
            {...props}
            centerLatLong={currentLatLong}
            userLocation={userLocation}
            onClickMarker={onClickMarker}
            showAirportList={showAirportList}
            selectedItem={selectedItem}
            selectedFboItem={selectedFboItem}
            searchResults={searchResults}
            selectedSearchItem={selectedSearchItem}
            closeList={closeList}
            locationLatLong={locationLatLong}
            onGoogleSearch={onSearch}
            airportItemValue={airportArray}
            getNewLat={getNewLat}
            searchResult={searchResults}
            dragMap={dragMap}
            selectionType={selectionType}
          />
        </div>
      )}
      {Object.values(selectedSearchItem).length > 0 && (
        <div style={{ width: 400, zIndex: 10, backgroundColor: "white" }}>
          <AircraftListComponent
            selectionType={selectionType}
            selectedFbo={selectedSearchItem}
            closelayout={onCloseLayout}
            airportItemValue={airportArray}
            unlistedFbos={fbos?.unlistedFbos}
          />
        </div>
      )}

      <Modal
        open={isTerms || isAircraftCountZero}
        disableBackdropClick={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isAircraftCountZero ?
          <TermsAndConditionPopup
            declineTermsAndConditions={declineTermsAndConditions}
            openAircraftListScreen={openAircraftListScreen}
          /> :
          <TermsAndConditionPopup
            declineTermsAndConditions={declineTermsAndConditions}
            openDialog={openDialog}
            navigation="terms"
          />}
      </Modal>
    </div>
  );
};
export default React.memo(Home);