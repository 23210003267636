import * as actionTypes from "./loader.action-types";

const initialState = {
  isLoaderVisible: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADER:
      return { ...state, isLoaderVisible: true };

    case actionTypes.REMOVE_LOADER:
      return { ...state, isLoaderVisible: false };

    default:
      return state;
  }
};
