import React from "react";

import FboPriceView from "./FboPriceView";
import { useStyles } from "../../../../assets/styles/FboItemStyles";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";
import constants from "../../../../config/constants";

const FboJetASafPrice = (props) => {
  const classes = useStyles();
  const { item, navigation } = props;
  let isSaf = item.discountTiers.length > 0 || item.secondDiscountTier.length > 0 || item.tiersSAF.length > 0;
  let isJetA = item.discountTiers.length > 0 || item.tiersJetA.length > 0;

  const renderSafTier = () => (
      item.discountFuelType === constants.discount.saf
        ? item.discountTiers
        : item.discountFuelType === constants.discount.jetA_Saf_Both
          ? item.secondDiscountTier
          : item.tiersSAF
  )

const renderJetATier = () => {
  let jetADiscount = item.discountFuelType === constants.discount.jet_A;
  let bothJetaandSafDiscount = item.discountFuelType === constants.discount.jetA_Saf_Both;
  return (jetADiscount|| bothJetaandSafDiscount)?item.discountTiers : item.tiersJetA;
}

  const isDiscount = (val) => (
    val ? !item.isParagonUser ? "(NOZL Direct)" : "(Paragon Preferred)" : ""
  )
  return (
    <div className={classes.cont}>
      <div className={classes.priceTextCont}>
        <div className={classes.textCont}>
          {navigation === "fromFboList" ? `Jet A ${isDiscount(item.isJetPreferredDiscount)
            }` : `SAF ${isDiscount(item.isSAFPreferredDiscount)
            }`}
        </div>
        {isSaf || isJetA ?
          <FboPriceView
            tiers={
              navigation === "fromFboList" ?
              renderJetATier()
                :
                renderSafTier()
            }
            retailPrice={navigation === "fromFboList" ? item.retailPrice : item.retailPriceSAF}
            item={item}
          />
          :
          <div className={classes.priceTextStyle}>
            {`$${formatNumWithCommas(
              parseFloat(navigation === "fromFboList" ? item.retailPrice : item.retailPriceSAF).toFixed(2)
            )}/gal`}
          </div>
        }
      </div>
    </div>
  );
};

export default FboJetASafPrice;
