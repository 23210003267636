import { Button } from "@material-ui/core";
import { useStyles } from "../../../../../../assets/styles/PilotProfileStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/Container";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../../../../../constants/colors";
import { getAssignedAircraftList } from "../../../../../../redux/pilots/pilots.actions";
import AssigneAircraftPage from "./AssigneAircraftPage";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const PilotProfile = (props) => {
  const userData = props?.location?.state?.param?.data ?? {};
  const classes = useStyles(colors);
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    dispatch(getAssignedAircraftList(userData?.pilotUserId, auth.token));
  }, []);

  const closeModal = () => {
    setOpenPopup(false);
  };

  const { auth, pilots } = useSelector((state) => ({
    auth: state.auth,
    pilots: state.pilots,
  }));

  const openManageAircraftPopop = () => {
    setOpenPopup(true);
  };

  useEffect(() => {
    dispatch(getAssignedAircraftList(userData?.pilotUserId, auth.token));
  }, [auth.token, userData?.pilotUserId]);

  return (
    <div>
      <div className={classes.pageContent}>
        <div className={classes.leftPage}>
          <Card>
            <CardContent className={classes.cardItem}>
              <div className={classes.head}>Full Name :</div>
              <div className={classes.tail}>
                {`${userData?.pilotDetail[0]?.name === undefined
                    ? "NA"
                    : userData?.pilotDetail[0]?.name
                  }`}
              </div>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardContent className={classes.cardItem}>
              <div className={classes.head}>Mobile No. :</div>
              <div className={classes.tail}>
                {`${userData?.pilotDetail[0]?.countryCode === undefined
                    ? "NA"
                    : `${userData?.pilotDetail[0]?.countryCode}`
                  } ${userData?.pilotDetail[0]?.mobileNumber === undefined
                    ? ""
                    : userData?.pilotDetail[0]?.mobileNumber
                  }`}
              </div>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardContent className={classes.cardItem}>
              <div className={classes.head}>Email :</div>
              <div className={classes.tail}>{userData?.email}</div>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardContent className={classes.cardItem}>
              <div className={classes.head1}>
                {pilots?.assignedAircraftList?.length > 0
                  ? pilots?.assignedAircraftList?.length > 1
                    ? `More than one aircraft assigned`
                    : `One aircraft assigned`
                  : "No assigned aircraft for this pilot."}
              </div>
            </CardContent>
          </Card>
          <br />
          <Button
            onClick={openManageAircraftPopop}
            variant="contained"
            className={classes.submitBtn}
          >
            Manage Aircraft
          </Button>
        </div>

        <div className={classes.rightPage}></div>
      </div>

      <Dialog
        open={openPopup}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AssigneAircraftPage
            assignedAircraftList={pilots?.assignedAircraftList}
            userId={userData?.pilotUserId}
            setOpenPopup={setOpenPopup}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PilotProfile;
