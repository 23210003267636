import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    height: 40,
  },
  notchedOutline: {
    borderWidth: "1px",
    padding: 5,
    borderColor: "black !important",
    color: "#000",
    hintColor: "#000",
  },
  listItem: {
    cursor: "pointer",
  },
  formControl: {
    width: "100%",
    borderRadius: 3,
    height: 35,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  fieldStyle: {
    width: "100%",
  },
  fuelAmountText: {
    width: 170,
    display: "flex",
    color: "#555",
    height: "100%",
    fontSize: 16,
    marginLeft: 3,
    alignItems: "center",
  },
  mainContainer: {
    width: 115,
    fontSize: 14,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

const PriceToggle = (props) => {
  const { onChange } = props;
  const [alignment, setAlignment] = React.useState(1);
  const classes = useStyles();

  const handleFuelAmount = (value) => {
    setAlignment(value);
    onChange(value);
  };

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <div className={classes.fuelAmountText}>Wish to negotiate?</div>
        <div
          style={{
            display: "flex",
            width: 220,
            height: 35,
            borderRadius: 20,
          }}
        >
          <div
            className={classes.mainContainer}
            style={{
              backgroundColor: alignment === 1 ? "#df2935" : "#DCDCDC",
              color: alignment === 1 ? "#fff" : "#555",
              zIndex: alignment === 1 ? 9 : 0,
            }}
            onClick={() => handleFuelAmount(1)}
          >
            Best Price
          </div>

          <div
            className={classes.mainContainer}
            style={{
              backgroundColor: alignment === 2 ? "#df2935" : "#DCDCDC",
              color: alignment === 2 ? "#fff" : "#555",
              marginLeft: -15,
              zIndex: alignment === 2 ? 9 : 0,
            }}
            onClick={() => handleFuelAmount(2)}
          >
            Price Match
          </div>
        </div>
      </FormControl>
    </div>
  );
};

export default PriceToggle;
