import React from "react";
import SettingsComponent from "../../components/scene/app/menu/Settings";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import commonStyles from "../../constants/common";
import fontStyles from "../../constants/fonts";

import AppLayout from '../Layout/AppLayout';
import { StyledContainer, StyledGridMainContainer, StyledMain } from '../History';

export default function Settings(props) {
  const commonClasses = commonStyles();
  const fontClasses = fontStyles();

  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <StyledMain style={{maxHeight: "100vh"}}>
        <AppLayout>
          <StyledGridMainContainer container={true} spacing={0} className="mainContainer">
            <Grid item={true} xs={12} className="contentContainer">
              <AppBar position="static" className={commonClasses.pageHeader}>
                <Toolbar className={commonClasses.pl0}>
                  <Typography className={fontClasses.pageHeading}>Settings</Typography>
                </Toolbar>
              </AppBar>
              <Grid container spacing={0} >
                <Grid item xs={3}>
                  <SettingsComponent />
                </Grid>
                <Grid item xs={9}>
                  {props.children}
                </Grid>
              </Grid>
            </Grid>
          </StyledGridMainContainer>
        </AppLayout>
      </StyledMain>
    </StyledContainer>
  );
}
