import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  headingTextStyle: {
    width: 240,
    height: 32,
    fontFamily: "Arial",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "-0.25px",
    fontWeight: "500",
    fontSize: 24,
    color: "#333333",
  },
}));

const Heading = (props) => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.headingTextStyle}>
      {props.heading}
    </Grid>
  );
};

export default Heading;
