import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import PrimarySignUp from "./PrimarySignUpFirstScreen";

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: "100%",
    height: "100vh"
  },
}));

const SignUp = () => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.mainContainer}>
      <PrimarySignUp />
    </Grid>
  );
};

export default SignUp;
