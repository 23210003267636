import React from "react";
import { Grid } from "@material-ui/core";
import VerifyDetails from "./VerifyDetails";

const ProfileSummary = (props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <VerifyDetails />
      </Grid>
    </Grid>
  );
};

export default ProfileSummary;
