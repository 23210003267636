import { createAsyncActionTypes } from '../utils';

export const UPDATE_PROFILE_STATUS = createAsyncActionTypes("UPDATE_PROFILE_STATUS");

export const LOGIN_WITH_EMAIL = createAsyncActionTypes('LOGIN_WITH_EMAIL');

export const LOG_OUT = createAsyncActionTypes('LOG_OUT');

export const GET_PROFILE_BY_TOKEN = 'GET_PROFILE_BY_TOKEN';

export const IS_IFRAME = createAsyncActionTypes("IS_IFRAME");

export const TERMS_CONDITION = createAsyncActionTypes("TERMS_CONDITION");

export const INVITED_PILOT = createAsyncActionTypes("INVITED_PILOT");

export const GET_PROFILE_BY_INVITEE_PILOT = createAsyncActionTypes("GET_PROFILE_BY_INVITEE_PILOT");