import React from "react";
import { Grid } from "@material-ui/core";

import AddAircraft from "./AddAircraft";

const Aircrafts = () => {
  return (
    <Grid container>
      <AddAircraft  />
    </Grid>
  );
};

export default Aircrafts;
