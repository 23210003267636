import React from "react";
import styled from "styled-components";

import { LoaderGrid } from "../../assets/styles/LoadingStyles";
import airplaneLoader from '../../assets/images/gif/loader.gif';

const StyledGrid = styled.div`
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
`;

const CustomLoader = (props) => {
  return (
    <div>
      {props.navigation === 'darkTheme' ?
        <StyledGrid>
          <img src={airplaneLoader} alt='' style={{ width: 200, height: 150 }} />
        </StyledGrid>
        :
        <LoaderGrid>
          <img src={airplaneLoader} alt='' style={{ width: 200, height: 150 }} />
        </LoaderGrid>
      }
    </div>
  );
};

export default CustomLoader;
