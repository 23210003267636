import { createAsyncActionTypes } from "../utils";

export const GET_FBOS = createAsyncActionTypes("GET_FBOS");
export const GET_FBO_DETAIL_LIST = createAsyncActionTypes(
  "GET_FBO_DETAIL_LIST"
);
export const SEND_NUDGE = createAsyncActionTypes("SEND_NUDGE");
export const INVITE_FBO = createAsyncActionTypes("INVITE_FBO");
export const LOG_OUT = createAsyncActionTypes("LOG_OUT");
export const SEND_UNLISTED_NUDGE = createAsyncActionTypes(
  "SEND_UNLISTED_NUDGE"
);
export const GET_UNLISTEDFBO_DETAIL_LIST = createAsyncActionTypes(
  "GET_UNLISTEDFBO_DETAIL_LIST"
);
export const GET_UNLISTEDFBOS = createAsyncActionTypes("GET_UNLISTEDFBOS");
export const SEARCH_FBO_TEXT = createAsyncActionTypes("SEARCH_FBO_TEXT");
export const FAVOURITE_FBOS = createAsyncActionTypes("FAVOURITE_FBOS");
export const FAVOURITE_FBO_ITEM = createAsyncActionTypes("FAVOURITE_FBO_ITEM");
export const SAVE_GEOLOC = createAsyncActionTypes("SAVE_GEOLOC");
export const SAVE_FBO_GEOLOC = createAsyncActionTypes("SAVE_FBO_GEOLOC");
export const ADD_FAVORITE_FBO= createAsyncActionTypes("ADD_FAVORITE_FBO");
export const UNMARK_FAVORITE_FBO = createAsyncActionTypes("UNMARK_FAVORITE_FBO");
export const FBO_DEFAULT_SERVICES = createAsyncActionTypes("FBO_DEFAULT_SERVICES");