import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  headingTextStyle: {
    marginTop: 8,
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#666666",
  },
}));

const HeadingText = (props) => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.headingTextStyle}>
      <Grid
        item
        xs={9}
      >
        {props.text}
        </Grid>
    </Grid>
  );
};

export default HeadingText;
