import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    display: "flex",
  },
  imageStyle: {
    width: "50px",
    height: "50px",
    marginBottom: "-7px",
  },
  mainHeading: {
    width: 270,
    height: 15,
    marginTop: "13px",
    fontFamily: "Arial",
    fontSize: 24,
    fontWeight: "500",
    ontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#333",
  },
  subHeading: {
    width: 270,
    height: 10,
    marginTop: 20,
    marginLeft: 46,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    ontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#999",
  },
  buttonStyle: {
    display: "flex",
    background: "linear-gradient(to bottom, #666 0%, #333 100%)",
    color: "white",
    borderRadius: "5px",
    width: "60px",
    height: "38px",
    marginLeft: "91px",
    marginTop: "25px",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export { useStyles };
