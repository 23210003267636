import React, { useEffect, useState } from "react";
import { useStyles } from "../../../../assets/styles/AirportSearchResultListStyles";
import colors from "../../../../constants/colors";
import { Avatar, Grid, ClickAwayListener } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import icaoCircle from "../../../../assets/images/icaoCircle.png";
import iataCircle from "../../../../assets/images/iataCircle.png";
import fboCircle from "../../../../assets/images/fboCircle.png";
import location from "../../../../assets/images/location.png";
import closeIcon from "../../../../assets/images/closeicon.png";
import planeIcon from "../../../../assets/images/planeIcon.png";
import paragonMarker from "../../../../assets/images/paragonMarker.png";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import recentHistory from "../../../../assets/images/recentHistory.png";

const AirportSearchResultList = (props) => {
  const classes = useStyles(colors);
  const [searchItems, setSearchItems] = useState([]);
  const [viewMore, setViewMore] = useState([false, false, false, false]);

  const handleChildRateClick = (e, index) => {
    e.stopPropagation();
    const data = viewMore;
    data[index] = !data[index];
    setViewMore([...data]);
  };

  const handleClickAway = () => {
    props.showAirportList(true);
  };

  useEffect(() => {
    setSearchItems(!!props.airportItemValue ? props.airportItemValue : []);
    setViewMore([false, false, false, false]);
  }, [props.selectedItem, props.airportItemValue]);

  const onPressCloseIcon = () => {
    props.closeList(true);
  };

  const selectedItem = (item, title) => {
    props.selectedItem(item, title);
  };

  const selectedFboItem = (item) => {
    props.selectedFboItem(item);
  };

  const handleSelect = (item) => {
    getGeocode({ address: item.description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        props.getCityLat({ lat, lng }, item);
      })
      .catch((error) => {});
  };

  const paragonImg = () => (
    <img src={paragonMarker} className={classes.paragonImgStyle} alt="" />
  );

  const renderView = (item, data) => {
    return (
      <Grid
        container
        style={{ cursor: "pointer" }}
        alignItems="center"
        item
        xs={12}
        key={data.id}
        onClick={() => {
          if (item.title === "Locations") {
            handleSelect(data);
          } else if (item.title === "FBO") {
            selectedFboItem(data);
          } else {
            selectedItem(data, item.title);
            selectedFboItem({});
          }
        }}
        className={classes.listContainer}
      >
        <Grid item xs={2}>
          <Avatar className={classes.avatar}>
            {item.title === "ICAO" && <img src={icaoCircle} alt="icao" />}
            {item.title === "IATA" && <img src={iataCircle} alt="iata" />}
            {item.title === "FBO" && <img src={fboCircle} alt="fbo" />}
            {item.title === "Airport" && <img src={planeIcon} alt="airport" />}
            {item.title === "Locations" && <img src={location} alt="city" />}
          </Avatar>
        </Grid>
        <Grid item xs={6} style={{ marginLeft: -10 }}>
          {item.title === "IATA" ? (
            <div>
              <div className={classes.airportNameCont}>
                <div className={classes.airportName}>{data.name}</div>
                {data.isParagonUser === true ? <div>{paragonImg()}</div> : null}
              </div>
              <div className={classes.code}>{`${data.iata}`}</div>
            </div>
          ) : (
            <div>
              {item.title === "ICAO" ? (
                <div>
                  <div className={classes.airportNameCont}>
                    <div
                      className={classes.airportName}
                      style={{ marginTop: 3 }}
                    >
                      {data.name}
                    </div>
                    {data.isParagonUser === true ? (
                      <div>{paragonImg()}</div>
                    ) : null}
                  </div>
                  <div className={classes.code}>{data.icao}</div>
                </div>
              ) : (
                <div>
                  {item.title === "Locations" ? (
                    <div>
                      <div className={classes.airportName}>
                        {data.structured_formatting.main_text}
                      </div>
                      <div className={classes.code}>
                        {data.structured_formatting.secondary_text}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {item.title === "Airport" ? (
                        <div>
                          <div className={classes.airportNameCont}>
                            <div
                              className={classes.airportName}
                              style={{ marginTop: 3 }}
                            >
                              {data.name}
                            </div>
                            {data.isParagonUser === true ? (
                              <div>{paragonImg()}</div>
                            ) : null}
                          </div>
                          {item.icao !== undefined && (
                            <div className={classes.code}>{item.icao}</div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {item.title === "FBO" ? (
                            <div>
                              <div className={classes.airportNameCont}>
                                <div
                                  className={classes.airportName}
                                  style={{ marginTop: 3 }}
                                >
                                  {data.fboDetail !== undefined
                                    ? data.fboDetail.fboName
                                    : data.fboName}
                                </div>
                                {data.isParagonUser === true ? (
                                  <div>{paragonImg()}</div>
                                ) : null}
                              </div>
                              <div className={classes.code}>
                                {data.fboDetail !== undefined
                                  ? data.fboDetail.icaoCode
                                  : data.icaoCode}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={3}>
          {data.fbos !== undefined && <div>{fboDetail(data)} </div>}
        </Grid>
        {nextSideIcon()}
      </Grid>
    );
  };

  const fboDetail = (data) => (
    <div>
      {data.fbos.length + data.unListedfbos?.length > 0 ? (
        <div>
          {data.fbos.length + data.unListedfbos?.length === 1 ? (
            <div className={classes.fboCount}>{`${
              data.fbos.length + data.unListedfbos?.length
            } FBO`}</div>
          ) : (
            <div className={classes.fboCount}>{`${
              data.fbos.length + data.unListedfbos?.length
            } FBOs`}</div>
          )}
        </div>
      ) : null}
    </div>
  );

  const nextSideIcon = () => (
    <Grid container item xs={1} className={classes.iconStyle}>
      <div style={{ color: "#ddd" }}>
        <NavigateNextIcon />
      </div>
    </Grid>
  );

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStarts"
      onClickAway={handleClickAway}
    >
      <div
        className={classes.innerCont}
        style={{
          border: props.getTextValue !== "" ? "1px solid #e4e4e4" : null,
        }}
      >
        {searchItems.length > 0 ? (
          <div className={classes.imageCont}>
            <img
              src={closeIcon}
              alt="Close"
              onClick={onPressCloseIcon}
              className={classes.imageStyle}
            />
          </div>
        ) : null}
        {searchItems !== undefined &&
        searchItems !== null &&
        searchItems &&
        searchItems.length > 0 ? (
          <div>
            {searchItems.map((item, idx) => {
              return (
                <div>
                  {item.title !== undefined && (
                    <div
                      style={{
                        background:
                          "linear-gradient(to right,  #848484 ,#5A5959)",
                      }}
                    >
                      <div className={classes.titleStyle}>{item.title}</div>
                    </div>
                  )}
                  {item.data !== undefined ? (
                    item.data.map((data, index) => {
                      if (!viewMore[idx] && index > 4) {
                        if (item.data.length === index + 1) {
                          return (
                            <div
                              className={classes.view}
                              onClick={(e) => handleChildRateClick(e, idx)}
                            >
                              <div>View More</div>
                              <ExpandMoreIcon />
                            </div>
                          );
                        }
                      } else {
                        if (
                          item.data.length === index + 1 &&
                          viewMore[idx] &&
                          item.data.length > 5
                        ) {
                          return (
                            <div>
                              <div>{renderView(item, data)}</div>
                              <div
                                className={classes.view}
                                onClick={(e) => handleChildRateClick(e, idx)}
                              >
                                <div>View Less</div>
                                <ExpandLessIcon />
                              </div>
                            </div>
                          );
                        } else {
                          return <div>{renderView(item, data)}</div>;
                        }
                      }
                    })
                  ) : (
                    <Grid
                      container
                      style={{ cursor: "pointer" }}
                      alignItems="center"
                      item
                      xs={12}
                      key={item !== undefined && item !== null && item.id}
                      onClick={() => {
                        if (
                          item !== null &&
                          item !== undefined &&
                          item.fboName !== undefined
                        ) {
                          selectedFboItem(item);
                        } else if (
                          item !== null &&
                          item !== undefined &&
                          item.icao !== undefined
                        ) {
                          selectedItem(item);
                        } else if (item.structured_formatting !== undefined) {
                          handleSelect(
                            item !== null && item !== undefined && item
                          );
                        } 
                      }}
                      className={classes.listContainer}
                    >
                      <Grid item xs={2}>
                        <Avatar className={classes.avatar}>
                          <img src={recentHistory} alt="fbo " />
                        </Avatar>
                      </Grid>
                      <Grid item xs={6} style={{ marginLeft: -10 }}>
                        {item !== undefined &&
                        item !== null &&
                        item.fboName !== undefined &&
                        item.fboName !== null ? (
                          <div>
                            <div style={{ display: "flex" }}>
                              <div
                                className={classes.airportName}
                                style={{ marginTop: 3 }}
                              >
                                {item.fboName}
                              </div>
                              {item.isParagonUser === true ? (
                                <div>{paragonImg()}</div>
                              ) : null}
                            </div>

                            <div
                              className={classes.code}
                            >{`${item.fboDetail.icaoCode}`}</div>
                          </div>
                        ) : (
                          <div>
                            {item !== undefined &&
                            item !== null &&
                            item.icao !== undefined ? (
                              <div>
                                <div className={classes.airportNameCont}>
                                  <div
                                    className={classes.airportName}
                                    style={{ marginTop: 3 }}
                                  >
                                    {item.name}
                                  </div>
                                  {item.isParagonUser === true ? (
                                    <div>{paragonImg()}</div>
                                  ) : null}
                                </div>
                                <div
                                  className={classes.code}
                                >{`${item.icao}`}</div>
                              </div>
                            ) : (
                              <div>
                                {item !== undefined &&
                                item !== null &&
                                item.structured_formatting !== undefined ? (
                                  <div>
                                    <div className={classes.airportName}>
                                      {item !== undefined &&
                                        item !== null &&
                                        item.structured_formatting.main_text}
                                    </div>
                                    <div className={classes.code}>
                                      {item !== undefined &&
                                        item !== null &&
                                        item.structured_formatting
                                          .secondary_text}
                                    </div>
                                  </div>
                                ) : (
                                  <div className={classes.airportName}>
                                    {item !== undefined &&
                                      item !== null &&
                                      item.name}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        {item.fbos !== undefined && (
                          <div>{fboDetail(item)} </div>
                        )}
                      </Grid>
                      {nextSideIcon()}
                    </Grid>
                  )}
                </div>
              );
            })}
          </div>
        ) : null}
        {searchItems !== undefined && searchItems.length === 0 && (
          <div className={classes.emptyListStyle}>No result found</div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default AirportSearchResultList;