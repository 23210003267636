import React from "react";

import { Grid } from "@material-ui/core";
import ProfileSetUp from "./ProfileSetUp";
import Contacts from "./Contacts/Contacts";
import Aircrafts from "./aircrafts/Aircrafts";
import ProfileSummary from "./profileSummary";
import { useSelector } from "react-redux";

const SignUpProfileSetUp = (props) => {
  const { signup } = useSelector((state) => ({
    signup: state.signup,
  }));

  return (
    <Grid container spacing={2}>
      {window.location.pathname === "/signup/profileSetup" ? (
        <ProfileSetUp />
      ) : window.location.pathname === "/signup/users" &&
        signup.registerAs === 2 ? (
        <Contacts />
      ) : window.location.pathname === "/signup/aircrafts" ? (
        <Aircrafts />
      ) : (
        window.location.pathname === "/signup/verifyDetails" && (
          <ProfileSummary />
        )
      )}
    </Grid>
  );
};

export default SignUpProfileSetUp;
