import axios from "axios";
import { toast } from "react-toastify";
import SessionExpireMsg from './SessionExpireMsg'

var blockCall = false;

export async function callApi(authOptions) {
  const returnVal = {};
  let responsecode = {};


  await axios(authOptions)
    .then((res) => {
      blockCall = false
      returnVal.response_type = "success";
      returnVal.response = res.data;
      responsecode = returnVal;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        toast.info(error?.response, { position: "bottom-center", closeButton: false });
      }
      if (error?.response?.status === 500) {
        toast.info(error?.response?.data?.message, { position: "bottom-center", closeButton: false });
      }
      if (error?.response?.status === 403) {
        if (!blockCall) {
          toast.info(<SessionExpireMsg/>, {
            position: "top-center",
            closeButton: false,
            autoClose: false,
            style: {
              width: 380,
              height: 200,
              color: "black",
              backgroundColor: "white"
            }

          })
          blockCall = true
        }
      } else {
        returnVal.response_type = "fail";
        returnVal.response = error?.response?.data ?? {
          message: "Network error",
        };
        responsecode = returnVal;
      }
    });
  return responsecode;
}

export const apiRequest = ({ apiUrl, method, payload, token = "" }) => {
  let authOptions = {
    method: method,
    url: apiUrl,
    data: payload,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: token,
      requestFrom: "web"
    },
    json: true,
  };
  if (method === "GET") {
    authOptions = {
      method: method,
      url: apiUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
        requestFrom: "web"
      },
      json: true,
    };
  }
  return callApi(authOptions);
};

export const apiRequestForTwillio = ({ apiUrl, method, payload }) => {
  let authOptions = {
    method: method,
    url: apiUrl,
    data: payload,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_TWILIO_TOKEN}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    json: true,
  };
  return callApi(authOptions);
};
