import { Box, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import TextField from "../../components/controls/Input";
import colors from "../../constants/colors";
import textStyles from "../../constants/fonts";
import SettingsLayout from "../Settings";
import * as mainServices from "../../services/mainService";
import { toast } from "react-toastify";
import NozlInnerHeading from "../../components/ui/NozlInnerHeading";

const useStyles = makeStyles(() => ({
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));
  const textClasses = textStyles();
  const [currentpassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const validateForm = () => {
    return (
      currentpassword.length > 6 &&
      newPassword.length > 6 &&
      newPassword === confirmPassword
    );
  };

  return (
    <SettingsLayout>
      <NozlInnerHeading title="Change Password" />
      <div>
        <Box m={3}>
          <Typography className={textClasses.heading3}>
            In order to securely update your password please enter your current
            password. You may then create a new password.
          </Typography>
          <br />

          <Grid container spacing={0}>
            <Grid item xs={5}>
              <Grid item xs={12}>
                <Typography
                  className={`${textClasses.heading3} ${textClasses.colorGray}`}
                >
                  Current Password
                </Typography>
                <TextField
                  theme={"black"}
                  fullWidth
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  value={currentpassword}
                  type="password"
                  placeholder={"Enter current password"}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={`${textClasses.heading3} ${textClasses.colorGray}`}
                >
                  New Password
                </Typography>
                <TextField
                  theme={"black"}
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                  type="password"
                  placeholder={"Enter new password"}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={`${textClasses.heading3} ${textClasses.colorGray}`}
                >
                  Confirm Password
                </Typography>
                <TextField
                  theme={"black"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  type="password"
                  placeholder="Confirm new password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={!validateForm()}
                  className={classes.submitBtn}
                  onClick={async () => {
                    const credentials = {
                      oldPassword: currentpassword,
                      newPassword: newPassword,
                    };
                    const res = await mainServices.changePassword({
                      password: credentials,
                      token: auth.token,
                      isFromPilot: false,
                    });
                    if (res.response.status === true) {
                      setCurrentPassword("")
                      setNewPassword("")
                      setConfirmPassword("")
                      toast(res.response.message, { position: "bottom-center", closeButton: false });
                    } else {
                      setCurrentPassword("")
                      setNewPassword("")
                      setConfirmPassword("")
                      toast.info(res.response.message, { position: "bottom-center", closeButton: false });
                    }
                  }}
                  fullWidth
                >
                  change password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </SettingsLayout>
  );
};

export default ChangePassword;
