import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(3),
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "row",
        flex: 1,
    },
    notfound: {
        justifyContent: "center",
        alignItems: "center",
    },
    page: {
        height: "100vh",
        width: "100%",
        backgroundColor: "#fff",
    },
    leftPage: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        margin: theme.spacing(1),
        flex: 1,
    },
    rightPage: {
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        margin: theme.spacing(1),
        flex: 1,
    },
    cardItem: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        padding: 20,
    },
    head: {
        display: "flex",
        width: 130,
    },
    head1: {
        display: "flex",
    },
    tail: {
        flex: 2,
    },
    rootHead: {
        backgroundColor: colors => colors.denim,
    },
    search: {
        marginLeft: 10,
    },
    loginBtn: {
        background: "linear-gradient(270deg, rgb(63, 152, 255), rgb(71, 215, 231))",
        color: "#fff",
        margin: 10,
    },
    main: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    form: {
        width: "100%",
        marginTop: -20,
    },
    submit: {
        background: "linear-gradient(270deg, rgb(63, 152, 255), rgb(71, 215, 231))",
        color: "#fff",
        margin: theme.spacing(3, 0, 2),
        justifyContent: "center",
        alignSelf: "center",
        alignItems: "center",
    },
    floatingLabelFocusStyle: {
        color: "#000",
    },
    label: {
        "&$focusedLabel": {
            color: "#000",
        },
        "&$erroredLabel": {
            color: "#000",
        },
    },
    focusedLabel: {},
    erroredLabel: {},
    underline: {
        "&$error:after": {
            borderBottomColor: "black",
        },
        "&:after": {
            borderBottom: `2px solid black`,
        },
    },
    error: {},
    notchedOutline: {
        borderWidth: "1px",
        padding: 5,
        borderColor: "black !important",
        color: "#fff",
        hintColor: "#fff",
    },
    
}));

export { useStyles };