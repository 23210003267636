import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import "../Contacts/contactAdded.css";
import DetailContacts from "../Contacts/DetailContacts";
import { useStyles } from "../../../../assets/styles/DetailContactStyles";
import styled from "styled-components";
import AddAircraftDetails from "./AddAircraftDetails";
import { useSelector } from "react-redux";

const StyledGrid = styled(Grid)`
  ::-webkit-scrollbar {
    display: none;
  }
`;

const AircraftAdded = (props) => {
  let aircrafts = []
  const classes = useStyles();
  const { account, auth } = useSelector((state) => ({
    account: state.account,
    auth: state.auth
  }))
  const { aircraftList, addAircraft, editListItem } = props;
  if (auth.status === 1 && account.assignedAircraftList.length > 0) {
    aircrafts = [...account.assignedAircraftList, ...aircraftList];
  }
  else {
    aircrafts= [...aircraftList];
  }

  const deleteItem = (item) => {
    props.deleteItem(item);
  };

  const editItem = (item, index) => {
    props.editItem(item, index);
  };

  const addNewAircraft = (newAircrafts) => {
    props.addNewAircraft(newAircrafts);
  };

  useEffect(() => {
    if(addAircraft) {
      let scrollContainer = document.getElementById("mainScroller");
      setTimeout(function() {
        scrollContainer.scrollLeft = scrollContainer.scrollWidth;
     }, 100);
    }
  }, [addAircraft])
  
  const cancelUser = (value) => {
    props.cancelUser(value);
  };

  return (
    <StyledGrid className={classes.mainContainer} id="mainScroller">
      {aircrafts.map((item, index) => {
        return (
          <div
            style={{
              direction:
                addAircraft === true &&
                (editListItem === undefined || editListItem.length === 0)
                  ? "rtl"
                  : "ltr",
            }}
          >
            {editListItem.length > 0 &&
            editListItem[0].registryName === item.registryName &&
            addAircraft === false ? (
              <Grid style={{ marginRight: 30 }}>
                <AddAircraftDetails
                  cancelUser={cancelUser}
                  addNewAircraft={addNewAircraft}
                  title={item.title}
                  aircraftList={aircraftList}
                  editListItem={editListItem}
                />
              </Grid>
            ) : (
              <DetailContacts
                item={item}
                index={index}
                editItem={editItem}
                aircraftList={aircrafts}
                navigation="aircraft"
                deleteItem={deleteItem}
              />
            )}
          </div>
        );
      })}
    </StyledGrid>
  );
};

export default AircraftAdded;
