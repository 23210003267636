import { makeStyles } from "@material-ui/core/styles";
import Colors from "./colors";

const textStyles = makeStyles((theme) => ({
  heading1: {
    fontSize: 24,
    fontWeight: "500",
  },
  heading2: {
    fontSize: 18,
    fontWeight: "400",
  },
  heading3: {
    fontSize: 16,
    fontWeight: "400",
  },
  link: {
    color: Colors.primaryColor,
    fontSize: 16,
  },
  paragonLink: {
    fontSize: 16,
    color: Colors.paragonPrimaryColor
  },

  authHeading: {
    borderBottom: "1px solid #ababab",
    paddingBottom: 15,
    maxWidth: "55%",
    color: "#ababab",
    marginBottom: 20,
  },
  authSubHeading: {
    fontSize: 24,
  },
  pageHeading: {
    fontSize: 24,
    fontWeight: "600",
  },
  colorGray: {
    color: "#555",
  },
}));
export default textStyles;
