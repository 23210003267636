import { createAction } from '../utils';
import * as actionTypes from "./pilots.action-types";

export const inviteToPilot = (pilotEmail, token) =>
createAction(actionTypes.INVITE_PILOT.REQUEST, { pilotEmail, token });

export const getAllInvitedPilots = (token) =>
createAction(actionTypes.INVITE_PILOTS_LIST.REQUEST, { token });

export const getAssignedAircraftList = (pilotUserId, token) =>
createAction(actionTypes.ASSIGNED_AIRCRAFT_LIST.REQUEST, { pilotUserId, token });

export const getAircraftList = (token) =>
createAction(actionTypes.SELECTED_AIRCRAFT_LIST.REQUEST, { token });

export const assignAirCraftToPilot = (token, pilotUserId, aircraftId) =>
createAction(actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.REQUEST, {
    token,
    pilotUserId,
    aircraftId,
  });

export const removePilotsByID = (token, pilotUserId) =>
createAction(actionTypes.REMOVE_PILOT_BY_ID.REQUEST, {token, pilotUserId});