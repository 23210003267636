import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import search from "../../../../assets/images/search.png";
import { getSearchResult } from "../AirportSearch/SearchResult";
import * as services from "../../../../services/mainService";
import searchClearIcon from "../../../../assets/images/searchClearIcon.png";

const useStyles = makeStyles(() => ({
  mainCont: {
    width: "100%",
    display: "flex",
    border: "1px solid #E4E2E2",
    boxShadow:
      "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: 5,
    height: 56,
  },
  imgCont: {
    height: "100%",
    display: "flex",
    padding: 10,
    alignItems: "center",
  },
  textCont: {
    width: "92%",
    fontSize: 16,
    outline: "none",
    border: "none",
  },
  imageStyle: {
    width: 25,
    height: 25,
    cursor: "pointer",
  },
}));

let googleValue = "";

export default function SearchAddress(props) {
  const classes = useStyles();
  const [isClick, setIsClick] = useState(false);
  const { searchText, setValue, clearText, value, currentLatLong, userId, userData, onClick, icao } = props;
  const { fbos, account, auth } = useSelector((state) => ({
    fbos: state.fbos,
    account: state.account,
    auth: state.auth,
  }));
  useEffect(() => {
    if (searchText !== "" && isClick === true) {
      setValue(searchText);
      setIsClick(false);
    }
  }, [isClick, searchText, setValue]);

  const handleInput = async (e) => {
    if(e.target.value === ""){
      clearText('');
    }
    setValue(e.target.value);
    const res = await services.fetchGooglePlacesApi({
      name: e.target.value,
      token: auth.token,
    });
    let googleList = [];
    if (res.response_type === "success") {
      googleList = res.response;
    }
      let result = await getSearchResult(
        e.target.value,
        fbos?.fbos,
        fbos?.unlistedFbos,
        account?.advanceSearch,
        account?.userSelectedAircraft,
        currentLatLong,
        [],
        userId,
        userData,
        googleList,
        auth.token,
        account.isParagon === true ? true : false
      );
      onClick("onChangeText");
      icao(result || [], value);
  };

  const handleClick = (e) => {
    setIsClick(true);
    onClick("onSeachClick");
  };

  const clearSearch = () => {
    setIsClick(true);
    icao([], "");
    setValue("");
    clearText("");
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.imgCont}>
        <img src={search} alt="" />
      </div>
      <input
        type="text"
        id="input"
        value={value}
        onChange={handleInput}
        onClick={handleClick}
        className={classes.textCont}
        autoComplete="off"
        placeholder="Search for ICAO, IATA, City or Airport"
      />
      {value !== "" && value !== undefined && (
        <div className={classes.imgCont} onClick={clearSearch}>
          <img src={searchClearIcon} alt="" className={classes.imageStyle} />
        </div>
      )}
    </div>
  );
}