import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    overflowX: "scroll",
    display: "flex",
    overflowY: "hidden",
  },
  iframeMainContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  mainCont: {
    width: 300,
    height: 244,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "30px 20px",
    borderRadius: 5,
    marginLeft: 5,
    boxShadow: "0 4px 8px 0 rgba(153, 153, 153, 0.4)",
    border: "1px solid #e6ebef",
    background: "#f9f9fb",
    backgroundColor: "#f9f9fb",
    marginRight: 40,
  },
  iframeMainCont: {
    width: "100%",
    height: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "10px",
    borderRadius: 5,
    boxShadow: "0 4px 8px 0 rgba(153, 153, 153, 0.4)",
    border: "1px solid #e6ebef",
    background: "#f9f9fb",
    backgroundColor: "#f9f9fb",
    marginRight: 40,
  },
  firstDetailCont: {
    display: "flex",
    width: "100%",
    padding: "30px, 20px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  nameTextCont: {
    height: 30,
    flexGrow: 0,
    fontFamily: "arial",
    fontSize: 20,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#000",
  },
  dotStyle: {
    height: 25,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
  },
  emailTextStyle: {
    height: 20,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#999",
  },
  textStyle2: {
    height: 24,
    width: "100%",
    flexGrow: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontFamily: "Arial",
    fontSize: 16,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#7a869a",
  },
  iframeTextStyle2: {
    fontSize: 12,
  },
  secondContainer: {
    height: 20,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonCont: {
    display: "flex",
    justifyContent: "flex-end",
  },
  imageStyle: {
    width: 16,
    height: 16,
    cursor: "pointer",
  },
}));

export { useStyles };
