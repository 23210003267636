import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  baseLocation: {
    color: "white",
  },
  margin: {
    color: "black",
    "& *::-webkit-input-placeholder": {
      color: "black",
      opacity: 1,
    },
  },
  margin1: {
    color: "white",
    "& *::-webkit-input-placeholder": {
      color: "white",
    },
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  label1: {
    color: "white",
    "&$focusedLabel": {
      color: "white",
    },
    "&$erroredLabel": {
      color: "white",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
}));

export { useStyles };

export const ValidationTextField = withStyles({
  root: {
    width: "100%",
    color: "white",
    textTransform: "lowercase",
    "& > :first-letter": {
      textTransform: "capitalize",
    },
    "& label.Mui-focused": {
      color: "black",
      textTransform: "lowercase",
      "& > :first-letter": {
        textTransform: "capitalize",
      },
      border: "1px solid black !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
      color: "black",
      textTransform: "lowercase",
      "& > :first-letter": {
        textTransform: "capitalize",
      },
      border: "1px solid black !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        color: "black",
        textTransform: "lowercase",
        "& > :first-letter": {
          textTransform: "capitalize",
        },
      },
        "&.Mui-focused fieldset": {
          borderColor: "black",
          color: "black",
          textTransform: "lowercase",
          "& > :first-letter": {
            textTransform: "capitalize",
          },
          border: "1px solid black !important",
        },
    },
  },
})(TextField);
export const BaselocationTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      color: "white",
      border: "1px solid white !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        color: "white",
      },
    },
  },
})(TextField);

export const IframeTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "black",
    },
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      border: "1px solid #ddd !important",
      color: "#000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#000",
        border: "1px solid #ddd",
      },
      "&:hover fieldset": {
        color: "#000",
        border: "1px solid #ddd !important",
      },
      "&.Mui-focused fieldset": {
        color: "#000",
        border: "1px solid #ddd !important",
      },
      color: "#000",
    },
    "& ::placeholder": {
      color: "#000",
    },
  },
})(TextField);
