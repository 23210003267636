import { makeStyles } from "@material-ui/core";
import { isMobile } from "react-device-detect";

import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "300p",
    paddingLeft: 10,
    border: "1px solid black !important",
    borderRadius: 5,
    height: 56,
    display: "flex",
    alignItems: "center",
    color: "grey",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  inputLabel: {
    "&.Mui-focused": {
      color: "black",
    },
  },
  items: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    alignItems: "center",
    borderBottom: "#000",
    cursor: "pointer",
  },
  checkMark: {
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    borderWidth: 2,
    borderColor: colors.primaryColor,
    fontSize: isMobile ? 10 : 14,
    padding: 5,
    textAlign: "left",
    marginRight: 20,
    border: "1px solid red",
    cursor: "pointer",
  },
  checkMarkFill: {
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    borderWidth: 2,
    borderColor: colors.primaryColor,
    backgroundColor: colors.primaryColor,
    fontSize: isMobile ? 10 : 14,
    padding: 5,
    textAlign: "left",
    marginRight: 20,
    border: "1px solid red",
    cursor: "pointer",
  },
  textStyle: {
    color: "grey",
    fontSize: 14,
  },
  emailTextStyle: {
    fontSize: 14,
    color: "black",
  },
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  paragonSubmit: {
    background: colors.paragonPrimaryColor,
    backgroundColor: colors.paragonPrimaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.paragonPrimaryColor,
    },
  },
  aircraftNameStyle: {
    backgroundColor: "#ddd",
    border: "1px solid #ddd",
    borderColor: "#000",
    height: 56,
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
  },
  container: {
    width: "100%",
    position: "relative",
  },
  itemStyle: {
    textTransform: "capitalize",
  },
  aircraftStyle: {
    color: "black",
    textTransform: "capitalize",
  },
  paper: {
    textTransform: "capitalize",
  },
}));

export { useStyles };