import React, { useEffect, useState } from "react";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Button, TextField, Grid, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import errorIcon from "../../assets/images/errorIcon.png";
import { login } from "../../redux/auth/auth.actions";
import LoginSchema from "../../utils/forms/schema.iframeLogin";
import paragonMainLogoDark from "../../assets/images/paragonMainLogoDark.png";
import iframeBackSign from "../../assets/images/iframeBackSign.png";
import { useStyles } from "../../assets/styles/IframeStyles";
import IframeForgotPassword from "./IframeForgotPassword";

import {
  registerAs,
  usersList,
  aircraftsList,
  basicDetails,
  timeline,
} from "../../redux/signup/signup.actions";

const IframeLogin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [seletedOption, setSeletedOption] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  useEffect(() => {
    dispatch(registerAs(""));
    dispatch(usersList([]));
    dispatch(aircraftsList([]));
    dispatch(basicDetails({}));
    dispatch(timeline({}));
  }, []);

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCancel = () => {
    if (!forgotPassword) {
      setSeletedOption("");
    } else {
      setForgotPassword(false);
    }
  };

  const closeForgotPassword = (value) => {
    setForgotPassword(false);
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.innerCont}>
        <div>
          <Grid container className={classes.imageCont}>
            <Grid item xs={1} className={classes.backButtonCont}>
              {seletedOption !== "" ? (
                <img
                  alt=""
                  onClick={() => handleCancel()}
                  src={iframeBackSign}
                  className={classes.backButtonStyle}
                />
              ) : null}
            </Grid>
            <Grid
              item
              xs={seletedOption !== "" ? 11 : 12}
              className={classes.logoImageCont}
            >
              <img
                src={paragonMainLogoDark}
                alt="logo"
                className={classes.logoImageStyle}
              />
            </Grid>
          </Grid>
          {forgotPassword === false ? (
            <div>
              <div
                className={`${classes.textStyle} ${classes.textCommonStyle}`}
              >
                Direct. The way it should be.
              </div>
              {seletedOption !== "" ? (
                <div
                  className={`${classes.textCommonStyle} ${classes.textStyle1}`}
                >
                  Good to see you again!
                </div>
              ) : null}
              {seletedOption !== "" ? (
                <div
                  className={`${classes.textStyle2} ${classes.textCommonStyle}`}
                >
                  Flight Department
                </div>
              ) : null}
              {seletedOption === "" ? (
                <div className={classes.optionFieldCont}>
                  <div className={classes.optionCont}>
                    Do you want to continue as
                  </div>
                  <button
                    className={classes.optionStyle}
                    onClick={() => {
                      setSeletedOption("1");
                    }}
                  >
                    Flight Department
                  </button>
                  <button
                    className={classes.optionStyle}
                    onClick={() => {
                      window.location.replace(
                        `${process.env.REACT_APP_FBO_PARAGON_URL}/login/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4=`
                      );
                    }}
                  >
                    FBO
                  </button>
                </div>
              ) : (
                <div className={`${classes.fieldCont} ${classes.fieldCont}`}>
                <Formik
                  validationSchema={LoginSchema}
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  isInitialValid={false}
                  onSubmit={async (values) => {
                    dispatch(login(email.toLowerCase(), password, "", true));
                  }}
                >
                  {({
                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                    handleSubmit,
                  }) => (
                   <>
                      {/* <div className={classes.textfieldStyle}> */}
                        <TextField
                          required
                          id="email"
                          fullWidth
                          placeholder="Email Address"
                          value={email}
                          variant="outlined"
                          autoComplete="off"
                          onChange={(e) => {
                            if (
                              e.target.value.match(
                                "^(?!\\s)[a-zA-Z 0-9_@.'&/#-]*$"
                              ) != null
                            ) {
                              handleChange(e);
                              setEmail(e.target.value);
                            }
                          }}
                          onBlur={handleBlur}
                          type="text"
                          inputProps={{
                            style: {
                              height: 5,
                            },
                            autoComplete: "off",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          className={classes.customLabelInput}
                          InputProps={{
                            style: { color: "#000" },
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon style={{ color: "#7a869a" }} />
                              </InputAdornment>
                            ),
                            endAdornment:
                              (email !== "" || touched.email) &&
                              errors.email ? (
                                <div position="end">
                                  <img
                                    alt=''
                                    src={errorIcon}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginTop: 3,
                                    }}
                                  />
                                </div>
                              ) : null,
                          }}
                          {...((email !== "" || touched.email) &&
                            errors.email && {
                              error: true,
                              helperText: (
                                <div className={classes.errorTextStyle}>
                                  {errors.email}
                                </div>
                              ),
                            })}
                        />
                        <TextField
                          required
                          id="password"
                          fullWidth
                          placeholder="Password"
                          value={password}
                          variant="outlined"
                          style={{ marginTop: 30 }}
                          autoComplete="off"
                          onChange={(e) => {
                            handleChange(e);
                            setPassword(e.target.value);
                          }}
                          onBlur={handleBlur}
                          type={showPassword === true ? "text" : "password"}
                          inputProps={{
                            style: {
                              height: 5,
                            },
                            autoComplete: "off",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          className={classes.customLabelInput}
                          InputProps={{
                            style: { color: "#000" },
                            classes: {
                              notchedOutline: classes.notchedOutline,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon style={{ color: "#7a869a" }} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ width: "20px" }}
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword === true ? (
                                    <Visibility className={classes.iconStyle} />
                                  ) : (
                                    <VisibilityOff
                                      className={classes.iconStyle}
                                    />
                                  )}
                                </IconButton>
                                {touched.password &&
                                Object.values(errors).length > 0 &&
                                errors.password ? (
                                  <div position="end">
                                    <img
                                      alt=''
                                      src={errorIcon}
                                      style={{
                                        width: 20,
                                        height: 20,
                                        marginLeft: 5,
                                        marginTop: 3,
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                          {...(touched.password &&
                            Object.values(errors).length > 0 &&
                            errors.password && {
                              error: true,
                              helperText: (
                                <div className={classes.errorTextStyle}>
                                  {errors.password}
                                </div>
                              ),
                            })}
                        />
                      <div className={classes.forgotPasswordStyle}>
                        <span onClick={handleForgotPassword}>
                          Forgot password?
                        </span>
                      </div>
                      <Button
                        onClick={handleSubmit}
                        fullWidth
                        className={`${classes.submitButton} ${classes.textButtonStyle}`}
                        disableElevation
                      >
                        Login
                      </Button>
                      </>
                  )}
                </Formik>
                </div>
              )}
            </div>
          ) : (
            <IframeForgotPassword
              closeForgotPassword={closeForgotPassword}
              handleCancel={handleCancel}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IframeLogin;
