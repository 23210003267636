import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import colors from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 20,
  },
  card: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 60,
    backgroundColor: "#fff",
    boxShadow: "[5px 10px]",
    paddingRight: 20,
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
  buttonContainer: {
    marginTop: 30,
  },
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
}));

let phoneNumber = "";

const InviteFriends = () => {
  const classes = useStyles();
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");

  const { account } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
  }));

  const sendMsgTwilio = async () => {
    try {
      const url = process.env.REACT_APP_TWILIO_URL;
      let toNumber = `+${countryCode}${phoneNumber.slice(countryCode.length)}`;
      const request = {
        method: "POST",
        url: url,
        data: qs.stringify({
          To: `${toNumber}`,
          From: process.env.REACT_APP_TWILIO_FROM_NUMBER,
          Body: `Hey, ${account.user.name} has invited you to join the NOZL network. NOZL is a network of FBOs that was built by pilots, for pilots. Try it now: nozlapp.com`,
        }),
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TWILIO_TOKEN}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      axios(request)
        .then((res) => {
          setMobileNumber("");
          toast("Invite sent successfully.", { position: "bottom-center", closeButton: false });
        })
        .catch((error) => {
          if (error.response.data.code === 21211) {
            toast.info(`The number ${toNumber}  is not a valid phone number`, { position: "bottom-center", closeButton: false });
          } else {
            toast.info(error.response.data.message, { position: "bottom-center", closeButton: false });
          }
        });
    } catch (error) {}
  };

  const onSendInviteClick = () => {
    phoneNumber.slice(countryCode.length) > 9
      ? sendMsgTwilio()
      : toast.info("Enter valid mobile number", { position: "bottom-center", closeButton: false });
  };

  return (
    <div style={{ paddingLeft: 20 }}>
      <Grid container item xs={12} sm={4}>
        <PhoneInput
          country={'us'}
          value={mobileNumber}
          enableSearch={true}
          enableAreaCodes={false}
          disableSearchIcon={true}
          searchStyle={{ width: "100%", margin: "auto" }}          
          inputStyle={{ height: 54, width: '100%', border: 'none', color: 'black' }}
          containerStyle={{ width: '100%', border: '1px solid black', height: 40, borderRadius: 5 }}
          placeholder="Mobile Number"
          className={classes.margin}
          autoFormat={true}
          buttonStyle={{ border: 'none' }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
            className: classes.floatingLabelFocusStyle,
          }}
          specialLabel="Phone"
          onChange={(phone, data) => {
            phoneNumber = phone;
            setMobileNumber(phone);
            setCountryCode(data.dialCode);
          }}
        />
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => onSendInviteClick()}
            fullWidth
            variant="contained"
            className={classes.submitBtn}
          >
            Send Invite
          </Button>
        </div>
      </Grid>
    </div>
  );
};

export default InviteFriends;

