import qs from "qs";
import { apiRequest, apiRequestForTwillio } from "./service";
import {
  FBO_LIST,
  SEND_NUDGE,
  GET_PROFILE,
  FBO_DETAIL_LIST,
  CHANGE_PASSWORD,
  UPDATE_MEMBERSHIP,
  SEND_FEEDBACK,
  GET_BOOKINGS,
  CREATE_BOOKING,
  CANCEL_BOOKING,
  FBO_INVITE,
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATION_COUNT,
  MARK_NOTIFICATION_READ,
  GET_NOTIFICATION_DETAIL,
  GET_INVITED_PILOT_LIST,
  GET_ASIGNED_AIRCRAFT_LIST,
  ASSIGN_AIRCRAFT_TO_PILOT,
  GET_PILOTS_LIST_RELATED_TO_AIRCRAFT,
  REMOVE_PILOT_BY_ID,
  AIRCRAFT_LIST,
  SEND_NUDGE_UNLISTED_FBO,
  ADD_AIRCRAFT,
  DELETE_AIRCRAFT,
  EDIT_AIRCRAFT,
  GET_AIRPLANE_LIST,
  SUBMIT_PROFILE,
  EDIT_PROFILE,
  GET_ASSIGN_PILOT_AIRCRFT_LIST,
  ASSIGN_PILOT_TO_AIRCRFT,
  ADD_CUSTOM_AIRCRAFT,
  GET_AIRCRAFT_MAKER_LIST,
  INVITE_PILOT_USER,
  CLEAR_NOTIFICATION,
  ADD_MANAGER,
  SUBMIT_MANAGER,
  ADD_ADDITIONAL_MANAGER,
  SUBMIT_ADDITIONAL_MANAGER,
  DELETE_MANAGER_URL,
  EDIT_MANAGER,
  ADD_FAVOURITE_FBO,
  REMOVE_FAVOURITE_FBO,
  GET_FAVOURITE_FBOS,
  FETCH_GOOGLE_PLACES,
  CONTACT_ADMIN,
  SIGN_UP_URL,
  SIGN_UP_VERIFY,
  GET_NUDGE_DETAIL,
  AIRCRAFT_LIST_BY_INVITEE_PILOT,
  GET_DETAIL_OF_INVITEE_PILOT,
  GET_DEFAULT_SERVICES
} from "./apiUrl";

export const getProfile = (payload) => {
  return apiRequest({
    apiUrl: GET_PROFILE,
    method: "GET",
    token: payload.token,
  });
};

export const getFbos = (payload) => {
  return apiRequest({
    apiUrl: FBO_LIST,
    method: "GET",
    token: payload.token,
  });
};

export const getFboDetailList = (payload) => {
  const params = payload.fbo;
  return apiRequest({
    apiUrl: FBO_DETAIL_LIST,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const sendNudge = (payload) => {
  const params = payload.nudge;
  return apiRequest({
    apiUrl: SEND_NUDGE,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const changePassword = (payload) => {
  const params = payload.password;
  return apiRequest({
    apiUrl: CHANGE_PASSWORD,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const updateMembership = (payload) => {
  const params = payload.memberShip;
  return apiRequest({
    apiUrl: UPDATE_MEMBERSHIP,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const sendFeedback = (payload) => {
  const params = payload.feedback;
  return apiRequest({
    apiUrl: SEND_FEEDBACK,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const getBookings = (payload) => {
  const params = payload.record;
  return apiRequest({
    apiUrl: GET_BOOKINGS,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const createBooking = (payload) => {
  const params = payload.booking;
  return apiRequest({
    apiUrl: CREATE_BOOKING,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const cancelBooking = (payload) => {
  const params = payload.booking;
  return apiRequest({
    apiUrl: CANCEL_BOOKING,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const fboInvite = (payload) => {
  const params = payload.invite;
  return apiRequest({
    apiUrl: FBO_INVITE,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const getNotifications = (payload) => {
  const params = payload.record;
  return apiRequest({
    apiUrl: GET_NOTIFICATIONS,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const getUnreadNotificationCount = (payload) => {
  return apiRequest({
    apiUrl: GET_UNREAD_NOTIFICATION_COUNT,
    method: "GET",
    token: payload.token,
  });
};

export const getNotificationDetail = (payload) => {
  const params = payload.notification;
  return apiRequest({
    apiUrl: GET_NOTIFICATION_DETAIL,
    payload: params,
    method: "POST",
    token: payload.token,
  });
};

export const getNudgeDetailApi = (payload) => {
  const id = payload.nudgeId;
  const URL = `${GET_NUDGE_DETAIL}/${id}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
};


export const markNotificationRead = (payload) => {
  const params = payload.notification;
  return apiRequest({
    apiUrl: MARK_NOTIFICATION_READ,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const invitePilots = (payload) => {
  const params = {
    pilotEmail: payload.pilotEmail,
  };
  return apiRequest({
    apiUrl: INVITE_PILOT_USER,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const getInvitesPilotsList = (payload) => {
  return apiRequest({
    apiUrl: GET_INVITED_PILOT_LIST,
    method: "GET",
    token: payload.token,
  });
};

export const getAssingedAircraftList = (payload) => {
  const params = {
    pilotUserId: payload.pilotUserId,
  };
  return apiRequest({
    apiUrl: GET_ASIGNED_AIRCRAFT_LIST,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const getAssingedAircraftListForSignlePilots = (payload) => {
  const params = {
    pilotUserId: payload.pilotUserId,
  };
  return apiRequest({
    apiUrl: GET_ASIGNED_AIRCRAFT_LIST,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const assignedAircraftPilot = (payload) => {
  const params = {
    pilotUserId: payload.pilotUserId,
    aircraftId: payload.aircraftId,
  };
  return apiRequest({
    apiUrl: ASSIGN_AIRCRAFT_TO_PILOT,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const getPilots = (payload) => {
  const params = {
    aircraftId: payload.aircraftId,
  };
  return apiRequest({
    apiUrl: GET_PILOTS_LIST_RELATED_TO_AIRCRAFT,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const removePilots = (payload) => {
  const params = {
    pilotUserId: payload.pilotUserId,
  };
  return apiRequest({
    apiUrl: REMOVE_PILOT_BY_ID,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const getAirplaneList = (payload) => {
  return apiRequest({
    apiUrl: GET_AIRPLANE_LIST,
    method: "GET",
  });
};

export const getAircraftList = (payload) => {
  return apiRequest({
    apiUrl: `${AIRCRAFT_LIST}?isInvitePilot=${!!payload.isInvitePilot}`,
    method: "GET",
    token: payload.token,
  });
};

export const getAircraftListByInviteePilotApi = (payload) => {
  return apiRequest({
    apiUrl: `${AIRCRAFT_LIST_BY_INVITEE_PILOT}/${payload.userId}?isInvitePilot=${!!payload.isInvitePilot}`,
    method: "GET",
  });
}

export const getDetailOfInviteePilotApi = (payload) => {
  return apiRequest({
    apiUrl: `${GET_DETAIL_OF_INVITEE_PILOT}/${payload.userId}`,
    method: "GET",
  });
}

export const addAircraft = (payload) => {
  const params = payload.aircraft;
  return apiRequest({
    apiUrl: ADD_AIRCRAFT,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const deleteAircraft = (payload) => {
  const params = payload.aircraft;
  return apiRequest({
    apiUrl: DELETE_AIRCRAFT,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const deleteManagerApi = (payload) => {
  const URL = `${DELETE_MANAGER_URL}/${payload.id}`;
  return apiRequest({
    apiUrl: URL,
    method: "DELETE",
    token: payload.token,
  });
};

export const editAircraft = (payload) => {
  const params = payload.aircraft;
  return apiRequest({
    apiUrl: EDIT_AIRCRAFT,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const editManagerApi = (payload) => {
  const params = payload.manager;
  return apiRequest({
    apiUrl: EDIT_MANAGER,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const submitProfile = (payload) => {
  const params = {};
  return apiRequest({
    apiUrl: SUBMIT_PROFILE,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const editProfile = (payload) => {
  const params = payload.user;
  return apiRequest({
    apiUrl: EDIT_PROFILE,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const sendUnlistedNudge = (payload) => {
  const params = payload.nudge;
  return apiRequest({
    apiUrl: SEND_NUDGE_UNLISTED_FBO,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const getAssignedPilotAircraftList = (payload) => {
  const params = {
    aircraftId: payload.aircraftId,
  };
  return apiRequest({
    apiUrl: GET_ASSIGN_PILOT_AIRCRFT_LIST,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const assignPilotToAircraft = (payload) => {
  const params = {
    pilotUserId: payload.pilotUserId,
    aircraftId: payload.aircraftId,
  };
  return apiRequest({
    apiUrl: ASSIGN_PILOT_TO_AIRCRFT,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const addCustomAircraft = (payload) => {
  const params = payload.aircraft;
  return apiRequest({
    apiUrl: ADD_CUSTOM_AIRCRAFT,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const addOperatorManagerApi = (payload) => {
  const params = payload.manager;
  return apiRequest({
    apiUrl: ADD_MANAGER,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const addAdditionalOperatorManagerApi = (payload) => {
  const params = payload.manager;
  return apiRequest({
    apiUrl: ADD_ADDITIONAL_MANAGER,
    method: "POST",
    payload: params,
    token: payload.token,
  });
};

export const submitOperatorManagerApi = (payload) =>
  apiRequest({
    apiUrl: SUBMIT_MANAGER,
    method: "POST",
    token: payload.token,
  });

export const submitAdditionalOperatorManagerApi = (payload) =>
  apiRequest({
    apiUrl: SUBMIT_ADDITIONAL_MANAGER,
    method: "POST",
    token: payload.token,
  });

export const getAircraftMakerListApi = (payload) => {
  return apiRequest({
    apiUrl: GET_AIRCRAFT_MAKER_LIST,
    method: "GET",
    token: payload.token,
  });
};

export const clearNotificationApi = (payload) => {
  const params = payload.notification;
  return apiRequest({
    apiUrl: CLEAR_NOTIFICATION,
    payload: params,
    method: "PUT",
    token: payload.token,
  });
};

export const addFavouriteFbo = (payload) => {
  const { fboUserId, type } = payload;
  return apiRequest({
    apiUrl: ADD_FAVOURITE_FBO,
    method: "POST",
    payload: {
      fboUserId,
      type,
    },
    token: payload.token,
  });
};

export const removeFavouriteFbo = (payload) => {
  const id = payload.fboUserId;
  const URL = `${REMOVE_FAVOURITE_FBO}/${id}`;
  return apiRequest({
    apiUrl: URL,
    method: "DELETE",
    token: payload.token,
  });
};

export const getFavouriteFbos = (payload) => {
  return apiRequest({
    apiUrl: GET_FAVOURITE_FBOS,
    method: "GET",
    token: payload.token,
  });
};

export const fetchGooglePlacesApi = (payload) => {
  const URL = `${FETCH_GOOGLE_PLACES}${payload.name}`;
  return apiRequest({
    apiUrl: URL,
    method: "GET",
    token: payload.token,
  });
};

export const inviteFriends = (payload) => {
  const url = process.env.REACT_APP_TWILIO_URL;
  return apiRequestForTwillio({
    apiUrl: url,
    method: "POST",
    payload: qs.stringify({
      To: `${payload.toNumber}`,
      From: process.env.REACT_APP_TWILIO_FROM_NUMBER,
      Body: `Hey, ${payload.name} has invited you to join the NOZL network. NOZL is a network of FBOs that was built by pilots, for pilots. Try it now: nozlapp.com`,
    }),
    token: payload.token,
  });
};

export const contactAdminApi = (payload) =>
  apiRequest({
    apiUrl: CONTACT_ADMIN,
    method: "POST",
    token: payload.token,
    payload: payload.body,
  });

export const signUpUrl = (payload) => {
  return apiRequest({
    apiUrl: SIGN_UP_URL,
    method: "POST",
    payload: payload,
  });
};

export const signUpVerifyUrl = (payload) => {
  return apiRequest({
    apiUrl: SIGN_UP_VERIFY,
    method: "POST",
    payload: payload,
  });
};

export const getdefaultServicesApi = (payload) => {
  return apiRequest({
    apiUrl: GET_DEFAULT_SERVICES,
    method: "GET",
    token: payload.token,
  });
};
