import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import "./contactAdded.css";
import DetailContacts from "./DetailContacts";
import { useStyles } from "../../../../assets/styles/DetailContactStyles";
import styled from "styled-components";
import AddUsers from "./AddUserDetail";

export const StyledScrollarGrid = styled(Grid)`
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ContactAdded = (props) => {
  const classes = useStyles();
  const { primaryContactDetails, addAdditionalUsers, editListItem, editIndex } = props;
  const deleteItem = (item, index) => {
    props.deleteItem(item, index);
  };

  const addNewUser = (newUser) => {
    props.addNewUser(newUser);
  };

  const editItem = (item, index) => {
    props.editItem(item, index);
  };

  const cancelUser = (value) => {
    props.cancelUser(value);
  };

  useEffect(() => {
    if(addAdditionalUsers) {
      let scrollContainer = document.getElementById("mainScroller");
      setTimeout(function() {
        scrollContainer.scrollLeft = scrollContainer.scrollWidth;
     }, 100);
    }
  }, [addAdditionalUsers])

  return (
    <StyledScrollarGrid id="mainScroller" className={classes.mainContainer}>
      {primaryContactDetails.map((item, index) => {
        return (
          <div
            style={{
              direction:
                addAdditionalUsers === true &&
                (editListItem === undefined || Object.values(editListItem).length === 0)
                  ? "rtl"
                  : "ltr",
            }}
          >
            {(editListItem.length > 0 || Object.values(editListItem).length > 0) &&
            editIndex === index &&
            addAdditionalUsers === false ? (
              <Grid style={{ marginRight: 30 }}>
                <AddUsers
                  addNewUser={addNewUser}
                  cancelUser={cancelUser}
                  title={item.contactTitle}
                  editListItem={editListItem}
                />
              </Grid>
            ) : (
              <DetailContacts
                item={item}
                index={index}
                editItem={editItem}
                navigation="contacts"
                addAdditionalUsers={addAdditionalUsers}
                deleteItem={deleteItem}
              />
            )}
          </div>
        );
      })}
    </StyledScrollarGrid>
  );
};

export default ContactAdded;
