import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { ValidationSignUpTextField } from "../../../../assets/styles/SignUpManufacturerStyles";

const useStyles = makeStyles(() => ({
  itemStyle: {
    textTransform: "lowercase",
    "& > :first-letter": {},
  },
  aircraftStyle: {
    color: "black",
    textTransform: "capitalize",
  },
  paper: {
    textTransform: "capitalize",
  },
}));

const fueltypeArray = [
  {
    name: "Jet A",
    value: "1",
  },
  {
    name: "100 LL",
    value: "2",
  },
];
const FuelType = (props) => {
  const classes = useStyles();
  const { onBlur = {} } = props;

  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('');

  const onModalTypeChange = (data, obj) => {
    if (obj !== null) {
      props.handleFuelType(obj.value);
    } else {
      props.handleFuelType("");
    }
  };

  useEffect(() => {
    setValue(fueltypeArray.find(e => e.name?.toLocaleLowerCase() === props.selectedFuelType?.toLocaleLowerCase()));
    setInputValue(props.selectedFuelType || "");
  }, [fueltypeArray])

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        inputValue={inputValue}
        value={value}
        size="small"
        classes={{ input: classes.paper, paper: classes.paper }}
        options={fueltypeArray}
        className={classes.itemStyle}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <ValidationSignUpTextField
            {...params}
            className={classes.aircraftStyle}
            label="Fuel Type*"
            variant="outlined"
            fullWidth
          />
        )}
        onChange={(event, newValue) => {
          setValue(newValue);
          onModalTypeChange(event, newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onBlur={onBlur}
      />
    </div>
  );
};

export default React.memo(FuelType);
