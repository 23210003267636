
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loginBtn: {
    alignSelf: "center",
    justifyContent: "center",
    marginLeft: 12,
    backgroundColor: colors => colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 46,
    "&:hover": {
      backgroundColor: colors => colors.primaryColor,
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  floatingLabelFocusStyle: {
    color: "#000",
  },
  label: {
    "&$focusedLabel": {
      color: "#000"
    },
    "&$erroredLabel": {
      color: "#000"
    }
  },
  focusedLabel: {},
  erroredLabel: {},
  underline: {
    "&$error:after": {
      borderBottomColor: "black"
    },
    "&:after": {
      borderBottom: `2px solid black`
    }
  },
  error: {},
  notchedOutline: {
    borderWidth: "1px",
    padding: 5,
    borderColor: "black !important",
    color: "#fff",
    hintColor: "#fff",
  },
}));
export { useStyles };