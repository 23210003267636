import { makeStyles } from "@material-ui/core";
import React from "react";
import locationMarker2 from "../../../../assets/images/locationMarker2.png";

const useStyles = makeStyles(() => ({
  marker: {
    width: 30,
    height: 40,
    position: "absolute",
    transform: "translateZ(0) translate(-50%, -100%)",
  },
}));

const GoogleMarker = (props) => {
  const { googleSearch } = props;
  const classes = useStyles();

  return (
    googleSearch !== undefined && (
      <img src={locationMarker2} className={classes.marker} alt={""} />
    )
  );
};

export default GoogleMarker;
