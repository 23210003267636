import React from "react";
import SettingsLayout from "../Settings";
import AppLayout from "../Layout/AppLayout";
import { StyledContainer } from "../History";
import ManageOperator from "../../components/scene/app/settings/ManageOperator";
import NozlHeadings from "../../components/ui/NozlHeadings";

export default function ManagerOperator(props) {
  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <AppLayout>
        <NozlHeadings title="Manage Contacts" />
        <br />
        <ManageOperator location={props.location} />
      </AppLayout>
    </StyledContainer>
  );
}
