import React, { useEffect, useState } from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import ContactsSchema from "../.../../../../../utils/forms/schema.contact";
import TextField from "../SignupTextInput";
import { useStyles } from "../../../../assets/styles/ContactStyles";
import { oneSpace, textString } from "../../../ui/TextTransform";
import "../phoneInput.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import * as services from "../../../../services/mainService";
import { toast } from "react-toastify";
import colors from "../../../../constants/colors";

const AddUsers = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [buttonText, setButtonText] = useState("Add");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { signup, account, auth } = useSelector((state) => ({
    signup: state.signup,
    account: state.account,
    auth: state.auth
  }));

  useEffect(() => {
    if (
      props.editListItem !== undefined &&
      (props.editListItem.length > 0 ||
        Object.values(props.editListItem).length > 0)
    ) {
      setIsEdit(true);
      setButtonText("Save");
      setEmail(props.editListItem.email);
      setFirstName(props.editListItem.first_name);
      setLastName(props.editListItem.last_name);
      setJobTitle(props.editListItem.title);
      setCountryCode(props.editListItem.countryCode);
      setPhoneNumber(props.editListItem.phone);
    }
  }, []);

  const cancelUser = () => {
    props.cancelUser(true);
  };

  return (
    <Formik
      validationSchema={ContactsSchema}
      initialValues={{
        email: props.editListItem ? props.editListItem?.email : "",
        first_name: props.editListItem ? props.editListItem?.first_name : "",
        last_name: props.editListItem ? props.editListItem?.last_name : "",
        title: props.editListItem ? props.editListItem?.title : "",
        phone: props.editListItem ? props.editListItem?.phone : "",
        countryCode: props.editListItem
          ? props.editListItem?.countryCode
          : "+1",
      }}
      isInitialValid={false}
      validateOnMount={true}
      onSubmit={async (values) => {
        setIsSubmitting(true);
        let userDetail = {
          email: email,
          first_name: firstName,
          last_name: lastName,
          title: jobTitle,
          phone: phoneNumber,
          countryCode: countryCode,
        };
        if (signup.contactList.length === 0) {
          userDetail.contactTitle =
            props.editListItem !== undefined &&
            (props.editListItem.length > 0 ||
              Object.values(props.editListItem).length > 0)
              ? props.editListItem.contactTitle
              : "Primary";
          userDetail.isPrimary = true;
        } else {
          userDetail.contactTitle =
            props.editListItem !== undefined &&
            (props.editListItem.length > 0 ||
              Object.values(props.editListItem).length > 0)
              ? props.editListItem.contactTitle
              : "Additional";
          userDetail.isPrimary =
            props.editListItem !== undefined &&
            (props.editListItem.length > 0 ||
              Object.values(props.editListItem).length > 0)
              ? props.editListItem.isPrimary
              : false;
        }
        let newArray = JSON.parse(JSON.stringify(signup.contactList));
        let index = -1;
        if (isEdit) {
          index = newArray.findIndex(
            (it) => it.email === props.editListItem.email
          );
        }

        if (index > -1) {
          newArray[index] = userDetail;
        } else {
          newArray = [...newArray, userDetail];
        }
        newArray = JSON.parse(JSON.stringify(newArray));
        newArray = newArray.map(function (item) {
          delete item.contactTitle;
          return item;
        });
        const detail = {
          stepNumber: 2,
          contacts: newArray,
        };
        const res = await services.signUpUrl(detail);
        setIsSubmitting(false);
        if (res.response_type === "success") {
          props.addNewUser(userDetail);
        } else {
          toast.error(res.response.message, {
            position: "bottom-center",
            closeButton: false,
            style: {
              color: "white",
              backgroundColor: account.isParagonUser || auth.isIframe ? colors.paragonPrimaryColor : colors.primaryColor
            }
          });
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        setTouched,
        touched,
        errors,
        handleSubmit,
      }) => (
        <Grid container className={classes.secondInnerCont}>
          <Grid container className={classes.headingStyle}>
            {props.title}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onChange={(e) => {
                  if (e.target.value.match("^(?!\\s)[a-zA-Z', &]*$") !== null) {
                    handleChange(e);
                    setFirstName(textString(e.target.value.split('').filter(oneSpace).join('')));
                  }
                }}
                onBlur={handleBlur}
                id="first_name"
                name="first_name"
                value={firstName}
                label="First Name*"
                error={
                  touched.first_name && errors.first_name
                    ? errors.first_name
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) => {
                  if (e.target.value.match("^(?!\\s)[a-zA-Z', &]*$") !== null) {
                    handleChange(e);
                    setLastName(textString(e.target.value.split('').filter(oneSpace).join('')));
                  }
                }}
                onBlur={handleBlur}
                id="lastNameSetup"
                name="last_name"
                value={lastName}
                label="Last Name*"
                error={
                  touched.last_name && errors.last_name ? errors.last_name : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="jobTitle"
                name="title"
                onChange={(e) => {
                    if (e.target.value[0] === " " && e.target.value[0] !== undefined) {
                      setJobTitle("");
                    } else {
                      handleChange(e);
                      setJobTitle(textString(e.target.value.split('').filter(oneSpace).join(''))); 
                    }
                }}
                onBlur={handleBlur}
                value={jobTitle}
                label="Job Title*"
                error={touched.title && errors.title ? errors.title : ""}
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{background:'transparent'}}>
                <PhoneInput
                  country={"us"}
                  id="mobileNumber"
                  name="phone"
                  value={phoneNumber}
                  enableSearch={true}
                  withDarkTheme={true}
                  withShadow
                  autoFormat={true}
                  enableAreaCodes={false}
                  disableSearchIcon={true}
                  inputStyle={{
                    height: 40,
                    width: "100%",
                    border: "none",
                    background: "transparent",
                    color: "#000",
                  }}
                  containerStyle={{
                    width: "100%",
                    border: "2px solid #ddd",
                    height: 40,
                    borderRadius: 5,
                    display:'flex',
                    alignItems:'center'
                  }}
                  buttonStyle={{
                    color: "black",
                    border: "none",
                    background: "transparent",
                  }}
                  placeholder="(750) 777-7777"
                  label="Phone Number"
                  className={classes.margin}
                  searchStyle={{ width: "100%", margin: "auto" }}
                  onChange={(value, country) => {
                    setCountryCode(`+${country.dialCode}`);
                    setPhoneNumber(value);
                    handleChange("phone")(value);
                  }}
                  onBlur={() => setTouched({ ...touched, phone: true })}
                />
                {touched.phone && errors.phone && (
                  <span className={classes.errorMsg}>{errors.phone || ""}</span>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid>
            <TextField
              id="email"
              name="email"
              onChange={(e) => {
                if (
                  e.target.value.match("^(?!\\s)[a-zA-Z 0-9_@.'&/#-]*$") != null
                ) {
                  handleChange(e);
                  setEmail(e.target.value);
                }
              }}
              onBlur={handleBlur}
              value={email}
              label="Email*"
              error={touched.email && errors.email ? errors.email : ""}
            />
          </Grid>
          <Grid>
            <Grid container spacing={2}>
              {signup.contactList.length > 0 && (
                <Grid item xs={6}>
                  <Button
                    className={classes.backButtonText}
                    onClick={cancelUser}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
              <Grid item xs={signup.contactList.length > 0 ? 6 : 12}>
                <Button
                  className={classes.buttonCont}
                  disabled={Object.keys(errors).length > 0 || isSubmitting}
                  onClick={handleSubmit}
                >
                  {isSubmitting === true && (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  )}
                  {!isSubmitting && buttonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default AddUsers;
