import { put, takeLatest, call } from "redux-saga/effects";

import * as actionTypes from "./requests.action-types";
import * as services from "../../services/mainService";
import * as authActionTypes from "../auth/auth.actionTypes";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { showLoader, removeLoader } from "../loader/loader.actions";

const showAlert = (title, msg) => {
  setTimeout(() => {
    if (msg !== undefined && msg !== "") {
    }
  }, 500);
};

// get history list
function* getRequests(action) {
  yield put(showLoader());
  const apiResponse = yield call(services.getBookings, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_REQUESTS.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_REQUESTS.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
  yield put(removeLoader());
}

function* getRequestsSuccess(action) { }

function* getRequestsFailure(action) {
  if (action.payload.message.includes("User not found") || action.payload.message === "Invalid Token") {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
}

// cancel booking request
function* cancelRequest(action) {
  const apiResponse = yield call(services.cancelBooking, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.CANCEL_REQUEST.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.CANCEL_REQUEST.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* cancelRequestSuccess(action) {
  yield put(
    push({
      pathname: "/home",
    })
  );
}

function* cancelRequestFailure(action) {
  showAlert("", action.payload.message);
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
}

// send feedback
function* sendFeedback(action) {
  const apiResponse = yield call(services.sendFeedback, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.SEND_FEEDBACK.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.SEND_FEEDBACK.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* sendFeedbackSuccess(action) {
  yield put(
    push({
      pathname: "/home",
    })
  );
}

function* sendFeedbackFailure(action) {
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
  showAlert("", action.payload.message);
}

export function* requestsSaga() {
  // get history list
  yield takeLatest(actionTypes.GET_REQUESTS.REQUEST, getRequests);
  yield takeLatest(actionTypes.GET_REQUESTS.SUCCESS, getRequestsSuccess);
  yield takeLatest(actionTypes.GET_REQUESTS.FAILURE, getRequestsFailure);

  // cancel booking request
  yield takeLatest(actionTypes.CANCEL_REQUEST.REQUEST, cancelRequest);
  yield takeLatest(actionTypes.CANCEL_REQUEST.SUCCESS, cancelRequestSuccess);
  yield takeLatest(actionTypes.CANCEL_REQUEST.FAILURE, cancelRequestFailure);

  // send feedback
  yield takeLatest(actionTypes.SEND_FEEDBACK.REQUEST, sendFeedback);
  yield takeLatest(actionTypes.SEND_FEEDBACK.SUCCESS, sendFeedbackSuccess);
  yield takeLatest(actionTypes.SEND_FEEDBACK.FAILURE, sendFeedbackFailure);
}
