import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Login from "../components/scene/Login";
import ManageOperators from "../components/scene/profile-setup/ManageOperators";
import AdditionalOperatorManager from "../components/scene/profile-setup/AdditionalOperatorManager";
import { NotFound } from "../components/scene/NotFound";
import AuthRoute from "./AuthRoute";
import AppRoute from "./AppRoute";
import Home from "../containers/Home";
import Profile from "../containers/Profile";
import PasswordChange from "../containers/ChangePassword";
import History from "../containers/History";
import AircraftLists from "../containers/AircraftList";
import ManagePilots from "../containers/ManagePilots";
import PilotProfile from "../containers/PilotProfile";
import BookingDetail from "../containers/BookingDetail";
import InviteFriends from "../containers/InviteFriends";
import ManagerOperator from "../containers/ManagerOperator";
import FavouriteFbos from "../containers/FavouriteFbos";
import ContactUs from "../containers/ContactUs";
import Notification from "../containers/Notifications";
import ResetPassword from "../components/scene/profile-setup/ResetPassword";
import SignUp from "../components/scene/signUp/index.js";
import PrimarySignUp from "../components/scene/signUp/PrimarySignUpFirstScreen";
import IframeLogin from "../components/scene/IframeLogin";
import NudgeDetails from "../containers/NudgeDetail";

export default (
  <Switch>
    <PrivateRoute
      exact
      path="/"
      componentProps={{ to: "/home" }}
      component={Redirect}
    />
    <AppRoute exact path="/signup/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4=" component={SignUp} />
    <AppRoute exact path="/signup" component={SignUp} />
    <AppRoute exact path="/signup/profileSetup" component={PrimarySignUp} />
    <AppRoute exact path="/signup/users" component={PrimarySignUp} />
    <AppRoute exact path="/signup/aircrafts" component={PrimarySignUp} />
    <AppRoute exact path="/signup/verifyDetails" component={PrimarySignUp} />
    <AppRoute exact path="/login" component={Login} />
    <AppRoute exact path="/login/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4=" component={IframeLogin} />
    <AuthRoute path="/manageContacts" component={ManageOperators} />
    <AuthRoute
      path="/additionalManager"
      component={AdditionalOperatorManager}
    />
    <AuthRoute path="/home" component={Home} />
    <AuthRoute path="/profile" component={Profile} />
    <AuthRoute path="/contact-us" component={ContactUs} />
    <AuthRoute path="/change-password" component={PasswordChange} />
    <AuthRoute path="/history" component={History} />
    <AuthRoute path="/aircraft-list" component={AircraftLists} />
    <AuthRoute path="/manage-pilots" component={ManagePilots} />
    <AuthRoute path="/pilot-profile" component={PilotProfile} />
    <AuthRoute path="/bookingDetail" component={BookingDetail} />
    <AuthRoute path="/notification-booking-detail" component={BookingDetail} />
    <AuthRoute path="/nudge-detail" component={NudgeDetails} />
    <AuthRoute path="/managePilots" component={ManagePilots} />
    <AuthRoute path="/pilotProfile" component={PilotProfile} />
    <AuthRoute path="/invite-friends" component={InviteFriends} />
    <AuthRoute path="/manage-managers" component={ManagerOperator} />
    <AuthRoute path="/favourite-fbos" component={FavouriteFbos} />
    <AuthRoute path="/notification" component={Notification} />
    <AppRoute path="/reset-password" component={ResetPassword} />
    <Route component={NotFound} />
  </Switch>
);
