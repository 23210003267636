import React, { useEffect, useState } from "react";

import Fade from "@material-ui/core/Fade";
import {
  Button,
  Typography,
  Popper,
  Grid,
  ClickAwayListener,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import filter from "../../../../assets/images/filter.png";
import filterOutline from "../../../../assets/images/filterOutline.png";
import { advancedSearch, rangeValue } from "../../../../redux/account/account.actions";
import { PrettoSlider, useStyles } from "../../../../assets/styles/AdvancedFilterStyles";
import { getGeoloc } from "../../../../redux/fbos/fbos.actions";

export let arrayList = [
  {
    key: "iata",
    index: 0,
    value: "IATA",
    itemSelected: false,
  },
  {
    key: "icao",
    index: 1,
    value: "ICAO",
    itemSelected: false,
  },
  {
    key: "name",
    index: 2,
    value: "Airport",
    itemSelected: false,
  },
  {
    key: "city",
    index: 3,
    value: "Locations",
    itemSelected: false,
  },
  {
    key: "fbo",
    index: 4,
    value: "FBO",
    itemSelected: false,
  },
];

let list = [];

const AdvancedFilter = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [slider, setSlider] = useState(0);
  const [, setTemp] = useState(0);
  const [filterApply, setFilterApply] = useState(false);
  const { account } = useSelector((state) => ({ account: state.account }));

  useEffect(() => {
    if (account?.advanceSearch.length === 0) {
      arrayList.map((item, index) => {
        return (arrayList[index].itemSelected = false);
      });
      setFilterApply(false);
    }
  }, [account?.advanceSearch]);

  useEffect(() => {
    getValue();
  }, [account?.advanceSearch, account?.slidervalue, props.filterList.length]);

  const getValue = () => {
    if (account?.advanceSearch.length > 0 || account?.slidervalue !== 0) {
      setFilterApply(true);
    } else {
      setFilterApply(false);
    }
    setSlider(0);
    arrayList.map((item, index) => {
      return (arrayList[index].itemSelected = false);
    });
    if (account?.advanceSearch.length > 0) {
      arrayList.map((arrayListItem) => {
        account?.advanceSearch.map((listItem) => {
          if (listItem.key === arrayListItem.key) {
            return (arrayListItem.itemSelected = true);
          }
        });
      });
      setTemp(Math.random());
    }
    if (account?.slidervalue !== 0) {
      setSlider(account?.slidervalue);
    }
  };

  const handleClick1 = (newPlacement) => (event) => {
    getValue();
    setAnchorEl1(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChange = (item, index) => {
    arrayList[index].itemSelected = !item.itemSelected;
    setTemp(Math.random());
  };

  const clearFilter = () => {
    props.setValue("")
    setFilterApply(false);
    arrayList.map((item, index) => {
      return (arrayList[index].itemSelected = false);
    });
    list = [];
    dispatch(rangeValue(0));
    dispatch(advancedSearch({}));
    dispatch(getGeoloc({}))
    setSlider(0);
    setOpen(false);
    props.setLocationLatLong({})
    props.filters([], 0);
  };

  const handleSlider = (event, newValue) => {
    setSlider(newValue);
  };

  const applyFilter = () => {
    props.setValue("")
    list = arrayList
      .filter((item) => {
        return item.itemSelected === true;
      })
      .map((item) => item);
    if (list.length > 0 || slider !== "0" || slider !== 0) {
      setFilterApply(true);
      props.filters(list, slider);
      setOpen(false);
      dispatch(rangeValue(slider));
      dispatch(advancedSearch(list));
    } else {
      setFilterApply(false);
      props.filters(list, slider);
      setOpen(false);
      dispatch(rangeValue(slider));
      dispatch(advancedSearch(list));
    }
    dispatch(getGeoloc({}))
    props.setLocationLatLong({})
  };

  const handleClickAway = () => {
    getValue();
    setOpen(false);
  };

  return (
    <Grid container>
      <Popper
        open={open}
        anchorEl={anchorEl1}
        placement={placement}
        transition
        className={classes.popperCont}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps}>
              <div style={{ background: "#fff" }}>
                <Typography className={classes.textStyle}>
                  ADVANCE SEARCH
                </Typography>
                <Grid container item xs={12}>
                  {arrayList.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={4}
                        onClick={() => handleChange(item, index)}
                      >
                        <div className={classes.valCont}>
                          <div
                            className={classes.valueCont}
                            style={{
                              backgroundColor:
                                item.itemSelected === true ? "#555" : null,
                            }}
                          ></div>
                          <div className={classes.valueTextStyle}>
                            {item.value}
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
                <div className={classes.distanceCont}>
                  <div className={classes.textStyle}>
                    DISTANCE RANGE (in miles)
                  </div>

                  <PrettoSlider
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    value={slider}
                    max={150}
                    onChange={handleSlider}
                    className={classes.root}
                  />
                </div>
                <Grid container item xs={12}>
                  <Grid item xs={6} className={classes.textAlignStyle}>
                    <Button
                      className={classes.clearButton}
                      onClick={clearFilter}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={6} className={classes.textAlignStyle}>
                    <Button
                      className={classes.applyButton}
                      onClick={applyFilter}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <div className={classes.buttonCont}>
        <Button
          onClick={handleClick1("bottom")}
          className={classes.filterButton}
        >
          <div className={classes.filterTextStyle}> Filter </div>
          <img
            className={classes.imgCont}
            alt=""
            src={
              account?.advanceSearch.length > 0 || account?.slidervalue !== 0
                ? filter
                : filterOutline
            }
          />
          {filterApply === true ? (
            <div className={classes.appliedFilterCont} />
          ) : null}
        </Button>
      </div>
    </Grid>
  );
};

export default AdvancedFilter;
