import React, { useState } from "react";
import styled from "styled-components";
import FuelRequestComponent from "../../components/scene/app/Fuelrequest";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Tooltip, Box } from "@material-ui/core";

import commonStyles from "../../constants/common";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import BookingCreated from "../../components/scene/app/Fuelrequest/BookingCreated";

const IconWrapper = styled.div`
  background-color: ${(props) => props.bgColor && props.bgColor};
  width: 38px;
  height: 38px;
  padding: 10px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  margin-right: 8px;
`;

const StyledHeadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles(() => ({
  heading: {
    display: "flex",
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid #ddd",
    paddingLeft: 0,
  },
  nameHeading: {
    width: "100%",
    background: "linear-gradient(99deg, #212121 15%, #000000 85%)",
    color: "#fff",
  },
  icon: {
    cursor: "pointer",
  },
  mainCont: {
    display: "flex",
  },
  iconMainCont: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  tooltipCont: {
    width: 250,
    whiteSpace: "nowrap",
  },
  boxStyle: {
    fontWeight: "bold",
    fontSize: 16,
  },
  noCont: {
    width: 30,
    height: 40,
  },
}));

let bookingResults = {};
export default function FuelRequest(props) {
  const commonClasses = commonStyles();
  const classes = useStyles();

  const [successful, setSuccessful] = useState(false);
  const [fboDetail, setFboDetail] = useState("");
  const [assignedPilotList, setAssignedPilot] = useState([]);

  const closeLayout = (isClick, value, fboDetails, assignedPilot) => {
    if (isClick === true) {
      bookingResults = value;
      setFboDetail(fboDetails);
      setAssignedPilot(assignedPilot);
      setSuccessful(true);
    }
  };

  const showFboList = (show) => {
    {
      show && props.showFboList(show);
    }
  };

  return (
    <Grid spacing={0} container>
      <StyledHeadingContainer className={classes.nameHeading}>
        <div className={`${commonClasses.pl0} ${classes.mainCont}`}>
          {successful === false ? (
            <div className={classes.iconMainCont}>
              <IconWrapper onClick={() => props.goBack(true)}>
                <Icon
                  icon={faArrowLeft}
                  onClick={() => props.goBack(true)}
                  color="white"
                  className={classes.icon}
                />
              </IconWrapper>
              <div className={classes.tooltipCont}>
                <Tooltip title={props.selectedFboItem?.fboName}>
                  <Box
                    component="div"
                    my={2}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    className={classes.boxStyle}
                  >
                    {props.selectedFboItem?.fboName}
                  </Box>
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className={classes.noCont} />
          )}
        </div>
      </StyledHeadingContainer>
      <Grid>
        {successful === false ? (
          <FuelRequestComponent
            selectedFboItem={props.selectedFboItem}
            closeLayout={closeLayout}
          />
        ) : (
          <BookingCreated
            showFboList={showFboList}
            bookingResult={bookingResults}
            fboDetail={fboDetail}
            assignedPilot={assignedPilotList}
          />
        )}
      </Grid>
    </Grid>
  );
}
