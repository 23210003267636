import React from "react";
import { Grid, Tooltip } from "@material-ui/core";

import { useStyles } from "../../../../assets/styles/ProfileSummaryStyles";

const ContactDetailView = (props) => {
  const classes = useStyles();
  const { navigation, detailList } = props;

  return (
    <Grid container>
      {detailList.map((item, index) => {
        return (
          <Grid item xs={4}>
            <Grid container className={classes.mainCont}>
              <Grid item xs={1} className={classes.indexStyle}>
                {index + 1}.
              </Grid>
              <Grid item xs={11} style={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={`${classes.userNameTextStyle} ${classes.commonStyle}`}
                  >
                    <Tooltip
                      title={
                        navigation === "aircraftProfileSummary"
                          ? item.operatoraircraftsDetail === undefined
                            ? `${item.registryName} ${item.isOtherMaker === true || item.isCustomAircraft === true
                                ? "(Custom)"
                                : ""
                             }` :  item.operatoraircraftsDetail.registryName.toUpperCase()
                          :
                          `${item.first_name} ${item.last_name} ${
                            item.contactTitle === "Primary" ? "(Primary)" : ""
                          } `
                          }
                    >
                      <div className={classes.dotStyle}>
                        { navigation === "aircraftProfileSummary"
                          ? item.operatoraircraftsDetail === undefined
                            ? `${item.registryName} ${item.isOtherMaker === true || item.isCustomAircraft === true
                                ? "(Custom)"
                                : ""
                             }` :  item.operatoraircraftsDetail.registryName.toUpperCase()
                          : 
                          `${item.first_name} ${item.last_name} ${
                            item.contactTitle === "Primary" ? "(Primary)" : ""
                          } `
                            }
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={`${classes.jobTitleTextStyle} ${classes.commonStyle}`}
                  >
                    <Tooltip
                      title={
                        navigation === "aircraftProfileSummary"
                          ? item.operatoraircraftsDetail === undefined
                            ? item.modelName !== "Other" && item.modelName !== "" && item.isOtherMaker === false
                              ? item.modelName
                              : item.name
                            : item.operatoraircraftsDetail.airplaneDetail.name
                          : item.title
                      }
                    >
                      <div className={classes.dotStyle}>
                        { 
                        navigation === "aircraftProfileSummary"
                          ? item.operatoraircraftsDetail === undefined
                            ? item.modelName !== "Other" && item.modelName !== "" && item.isOtherMaker === false
                              ? item.modelName
                              : item.name
                            : item.operatoraircraftsDetail.airplaneDetail.name
                          : item.title
                        }
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={`${classes.userEmailTextStyle} ${classes.commonStyle}`}
                  >
                    <Tooltip
                      title={
                        navigation === "aircraftProfileSummary"
                          ? item.operatoraircraftsDetail === undefined
                            ? item.manufacturerName !== "Other" && item.manufacturerName !== ""
                              ? item.manufacturerName
                              : item.otherMakerName
                            : item.operatorDetail.name
                          : item.email
                      }
                    >
                      <div className={classes.dotStyle}>
                        {
                        navigation === "aircraftProfileSummary"
                          ? item.operatoraircraftsDetail === undefined
                            ? item.manufacturerName !== "Other" && item.manufacturerName !== ""
                              ? item.manufacturerName
                              : item.otherMakerName
                            : item.operatorDetail.name
                          : item.email
                        }
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={`${classes.userEmailTextStyle} ${classes.commonStyle}`}
                  >
                    <Tooltip
                      title={
                        navigation === "aircraftProfileSummary"
                          ? item.operatoraircraftsDetail === undefined
                            ? item.baseLocation
                            : item.operatoraircraftsDetail.baseLocation
                          : `+ ${item.phone}`
                      }
                    >
                      <div className={classes.dotStyle}>
                        {
                        navigation === "aircraftProfileSummary"
                          ? item.operatoraircraftsDetail === undefined
                            ? item.baseLocation
                            : item.operatoraircraftsDetail.baseLocation
                          : `+ ${item.phone}`
                        }
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ContactDetailView;
