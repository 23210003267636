import { makeStyles } from "@material-ui/core";
import React from "react";

import fboItem from "../../../../assets/images/fboItem.png";
import { getImageURLFromAWS } from "./FboDetailHelper";

const useStyles = makeStyles(()=>({
  imageStyle:{
    maxHeight: 100, width: 140, objectFit: "contain" 
  },
  fboDetailStyle:{
    maxHeight: 160, maxWidth: 400, objectFit: 'contain'
  }
}))

const FboLogo = (props) => {
  const classes = useStyles();
  const { logo, navigation} = props;
  return (
    <>
      {logo !== undefined && logo !== null ? (
        <img
          src={getImageURLFromAWS(logo, 300, 150)}
          alt="logo"
          className={navigation ==="fboItem" ?  classes.imageStyle : classes.fboDetailStyle}
        />
      ) : (
        <img src={fboItem} alt="logo" style={{ width: 50, height: 50 }} />
      )}
    </>
  );
};

export default FboLogo;
