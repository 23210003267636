import { createAsyncActionTypes } from "../utils";

export const GET_PROFILE = createAsyncActionTypes("GET_PROFILE");
export const UPDATE_PROFILE = createAsyncActionTypes("UPDATE_PROFILE");
export const UPDATE_PROFILE_SETTINGS = createAsyncActionTypes(
  "UPDATE_PROFILE_SETTINGS"
);
export const GET_AIRPLANE_LIST = createAsyncActionTypes("GET_AIRPLANE_LIST");
export const ADD_AIRCRAFT = createAsyncActionTypes("ADD_AIRCRAFT");
export const DELETE_AIRCRAFT = createAsyncActionTypes("DELETE_AIRCRAFT");
export const EDIT_AIRCRAFT = createAsyncActionTypes("EDIT_AIRCRAFT");
export const GET_AIRCRAFT_LIST = createAsyncActionTypes("GET_AIRCRAFT_LIST");
export const GET_AIRCRAFT_LIST_BY_INVITEE_PILOT = createAsyncActionTypes("GET_AIRCRAFT_LIST_BY_INVITEE_PILOT");
export const SUBMIT_PROFILE = createAsyncActionTypes("SUBMIT_PROFILE");

export const LOG_OUT = createAsyncActionTypes("LOG_OUT");

export const SET_USER_SELECTED_AIRCRAFT = "SET_USER_SELECTED_AIRCRAFT";
export const CLEAR_USER_SELECTED_AIRCRAFT = "CLEAR_USER_SELECTED_AIRCRAFT";
export const CLEAR_ASSIGNED_AIRCRAFT = "CLEAR_ASSIGNED_AIRCRAFT";

export const GET_ASSIGNED_PILOT_AIRCRAFT_LIST = createAsyncActionTypes(
  "GET_ASSIGNED_PILOT_AIRCRAFT_LIST"
);
export const ASSIGN_PILOTS = createAsyncActionTypes("ASSIGN_PILOTS");

export const ADD_CUSTOM_AIRCRAFT = createAsyncActionTypes(
  "ADD_CUSTOM_AIRCRAFT"
);
export const ADD_OPERATOR_MANAGER = createAsyncActionTypes(
  "ADD_OPERATOR_MANAGER"
);
export const ADD_ADDITIONAL_MANAGER = createAsyncActionTypes(
  "ADD_ADDITIONAL_MANAGER"
);
export const SUBMIT_MANAGER = createAsyncActionTypes("SUBMIT_MANAGER");

export const DELETE_MANAGER = createAsyncActionTypes("DELETE_MANAGER");
export const EDIT_MANAGER = createAsyncActionTypes("EDIT_MANAGER");

export const GET_AIRCRAFT_MAKER_LIST = createAsyncActionTypes(
  "GET_AIRCRAFT_MAKER_LIST"
);

export const ADVANCED_SEARCH = createAsyncActionTypes("ADVANCED_SEARCH");
export const SLIDER_VALUE = createAsyncActionTypes("SLIDER_VALUE");

export const CONTACT_ADMIN = createAsyncActionTypes("CONTACT_ADMIN");

export const IS_PARAGON = createAsyncActionTypes("IS_PARAGON");

export const IS_PARAGON_USER = createAsyncActionTypes("IS_PARAGON_USER");

export const SEARCH_HISTORY = createAsyncActionTypes("SEARCH_HISTORY");

export const USER_BASE_LOCATION = createAsyncActionTypes("USER_BASE_LOCATION")