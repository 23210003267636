import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  triggerValueStyle: {
    width: "100%",
    background: "#131415",
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.4)",
  },
  bookingTriggerValueStyle: {
    width: "100%",
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: "#fff",
  },
  valuePrice: {
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    display: "flex",
    letterSpacing: "normal",
    justifyContent: "flex-end",
    color: "#fff",
    alignItems: "center",
  },
  fuelPriceStyle: {
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    color: "#fafafa",
    marginLeft: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  bookingFuelPriceStyle: {
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    color: "#666",
    marginLeft: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  headingTextStyle2: {
    display: "flex",
    width: "100%",
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "15px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    alignItems: "center",
  },
  bookingHeadingTextStyle2: {
    display: "flex",
    width: "100%",
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "15px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#333",
    alignItems: "center",
  },
  infoStyle: {
    marginLeft: 5,
    width: 15,
    height: 15,
    color: "#e8e8e8",
    borderRadius: 10,
    cursor: "pointer",
  },
  expandIconStyle: {
    width: 20,
    height: 20,
    color: "#fff",
  },
  bookingExpandIconStyle: {
    width: 20,
    height: 20,
    color: "#333",
  },
  valuePriceStyle: {
    height: 32,
    width: "100%",
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    display: "flex",
    textAlign: "right",
    color: "#fff",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  bookingValuePriceStyle: {
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    display: "flex",
    textAlign: "right",
    color: "#333",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  totalPriceTextHeading: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#eee",
  },
  bookingTotalPriceTextHeading: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#666",
  },
  totalPriceText: {
    margin: "4px 0",
    fontSize: "32px",
    fontWeight: "bold",
    color: "#fafafa",
  },
  rampFeeTextStyle: {
    color: "#fafafa",
  },
  priceCont1: {
    display: "flex",
    lineHeight: 2.29,
  },
  crossAmount: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    color: "#e8e8e8",
  },
  bookingCrossAmount: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    color: "#666",
  },
  priceStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  headingTextStyle1: {
    width: "100%",
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  bookingHeadingTextStyle1: {
    width: "100%",
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#666",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  valueStyle2: {
    width: "100%",
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#fff",
  },
  bookingValueStyle2: {
    width: "100%",
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#666",
  },
  collapsibleStyle: {
    gap: 8,
  },
  collapsibleViewStyle: {
    width: "100%",
    boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.2)",
    border: "solid 1px #131415",
    backgroundColor: "#181818",
  },
  bookingCollapsibleViewStyle: {
    width: "100%",
    boxShadow: "inset 0 2px 4px 0 rgba(153, 153, 153, 0.2);",
    border: "solid 1px #fff",
    backgroundColor: "#f9f9fb",
  },
  extraSpace: {
    width: 20,
    height: 20,
  },
}));
export { useStyles };
