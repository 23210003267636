import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import authReducer from "../auth/auth.reducer";
import accountReducer from "../account/account.reducer";
import fbosReducer from "../fbos/fbos.reducer";
import notificationsReducer from "../notifications/notifications.reducer";
import requestsReducer from "../requests/requests.reducer";
import pilotsReducer from "../pilots/pilots.reducer";
import loaderReducer from "../loader/loader.reducer";
import userSelectedAircraft from "../account/account.reducer";
import signupReducer from "../signup/signup.reducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    signup: signupReducer,
    account: accountReducer,
    fbos: fbosReducer,
    notifications: notificationsReducer,
    requests: requestsReducer,
    pilots: pilotsReducer,
    loader: loaderReducer,
    userSelectedAircraft: userSelectedAircraft,
  });
