import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Page } from "./components/ui/layout";
import { GET_PROFILE } from "./services/apiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { getProfileByToken, logout, updateProfileStatus } from "./redux/auth/auth.actions";
import { isParagon, getProfileResponse } from "./redux/account/account.actions";
import CustomLoader from "./components/ui/CustomLoader";
import packageJson from "../package.json";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import DetectMobilePopup from "./containers/Login/DetectMobilePopup";
import { Modal } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import InternetConnection from "./components/scene/app/InternetConnection";
import noNetworkIcon from "./assets/images/noNetworkIcon.png";

const App = (props) => {
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [openPopUp, setOpenPopUp] = useState("");
  const [isInternetConnect, setIsInternetConnect] = useState(!navigator.onLine ? true : false);

  useEffect(() => {
    if (isMobile && !isTablet && !isDesktop) {
      setOpenPopUp(true);
    }
    let url = window.location.origin;
    if (url.includes("paragon")) {
      dispatch(isParagon(true));
    } else {
      dispatch(isParagon(false));
    }
    let currentVersion = localStorage.getItem("nozl_version");
    let reduxValue = localStorage.getItem("persist:root");
    let reduxObj = JSON.parse(reduxValue);

    if (packageJson.version !== currentVersion) {
     
      if ("caches" in window) {
        localStorage.removeItem("persist:root");
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
      // also remove all data except auth
      for (let key in reduxObj) {
        if (key !== "auth") {
          delete reduxObj[key];
        }
      }
      //  below seIitem for key persist:root can be removed as the user is still not getting logged off if we remove
      //  the key from local storage, it is added only for safety purpose.
      localStorage.setItem("persist:root", JSON.stringify(reduxObj));
      localStorage.setItem("nozl_version", packageJson.version);
      localStorage.setItem('noNetConnectionIcon', noNetworkIcon )
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    }
  }, []);

  const setOnline = () => {
    setIsInternetConnect(true);
  };
  const setOffline = () => {
    setIsInternetConnect(false);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const authOptions = {
        method: "GET",
        url: GET_PROFILE,
        timeout: 2000,
        headers: {
          "Content-Type": "application/json",
          token:
            auth.token !== undefined && auth.token !== null ? auth.token : "",
        },
      };
      if (auth.token !== undefined && auth.token !== null) {
        axios(authOptions)
          .then((res) => {
            if (res.status === 200 && res.data.status) {
              setIsInternetConnect(false);
              dispatch(getProfileByToken(res.data?.data?.operatorUserDetail ?? {}));
              dispatch(getProfileResponse(res.data?.data ?? {}))
              dispatch(updateProfileStatus(res.data?.data?.operatorUserDetail ?? {}))
              setLoading(true);
            } else {
              setIsInternetConnect(false);
              setLoading(true);
              toast.info(res.data.message, {
                position: "bottom-center",
                closeButton: false,
              });
              push("/login");
            }
          })
          .catch((error) => {
            if (!!error.response) {
              if (error.response.status === 401) {
                if (error.response.data.message.includes("User not found")) {
                  toast.info("Session expired. Please login.", {
                    position: "bottom-center",
                    closeButton: false,
                  });
                  dispatch(
                    logout({
                      token:
                        auth.token !== undefined && auth.token !== null
                          ? auth.token
                          : "",
                    })
                  );
                }
              }
            }
            else {
              setIsInternetConnect(true);
            }
            setLoading(true);
          });
      } else {
        setLoading(true);
      }
    }
  }, [auth.token, dispatch]);

  function renderLoader() {
    return <CustomLoader />;
  }

  // event listeners to update the state 
  window.addEventListener('online', () => {
    setOffline()
  });

  window.addEventListener('offline', () => {
    setOnline()
  });

  return (
    <>
      <Page>
        {loading ? props.children : renderLoader()}
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={openPopUp}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DetectMobilePopup />
        </Modal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={isInternetConnect}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <InternetConnection />
        </Modal>
      </Page>
    </>
  );
};

export default Sentry.withProfiler(App);
