import React from "react";
import Info from "@material-ui/icons/Info";
import { Tooltip, Box } from "@material-ui/core";

import TransactionDetailRow from "./TransactionDetailRow";
import { useStyles } from "../../../../assets/styles/FboItemStyles";
import TransactionDetailRowWithRateCross from "./TransactionDetailRowWithRateCross";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";

const ShowInfoTiers = (props) => {
  const { discount, tiers, retailPrice } = props;
  const classes = useStyles();

  return (
    <div>
      <Tooltip
        title={
          <div>
            <div className={classes.infoHeadingContainer}>
              <div className={classes.headingContStyle}>Tier</div>
              <div className={classes.headingContStyle}>Price</div>
            </div>
            <div className={classes.tierPriceStyle}>
              {tiers.map((value, idx) => {
                const label =
                  value.limitLow === value.limitHigh
                    ? `${value.limitLow}+`
                    : `${value.limitLow} - ${value.limitHigh}`;
               return (
                  discount ? (
                    <TransactionDetailRowWithRateCross
                      key={idx}
                      label={label}
                      fromTooltip="fromTooltip"
                      value={`$${formatNumWithCommas(
                        parseFloat(value?.retailPrice).toFixed(2)
                      )}/gal`}
                      valueMeta={`$${formatNumWithCommas(
                        parseFloat(retailPrice).toFixed(2)
                      )}/gal`}
                    />
                  ) : (
                     <TransactionDetailRow
                      fromTooltip="fromTooltip"
                      key={idx}
                      label={label}
                      value={`$${formatNumWithCommas(
                        parseFloat(value.retailPrice).toFixed(2)
                      )}/gal`}
                    />
                  )
               )
              })}
            </div>
          </div>
        }
      >
        <Box
          component="div"
          textOverflow="ellipsis"
          overflow="hidden"
          className={classes.boxStyle}
        >
          <Info className={classes.infoListStyle} />
        </Box>
      </Tooltip>
    </div>
  );
};

export default ShowInfoTiers;
