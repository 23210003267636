import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { ValidationSignUpTextField } from "../../../../assets/styles/SignUpManufacturerStyles";

const useStyles = makeStyles(() => ({
  itemStyle: {
    textTransform: "lowercase",
    "& > :first-letter": {},
  },
  aircraftStyle: {
    color: "black",
    textTransform: "capitalize",
  },
  paper: {
    textTransform: "capitalize",
  },
}));
const CustomAircraft = (props) => {
  const classes = useStyles();
  const { filterAirPlanesName, modelName, onBlur = {} } = props;
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setValue(filterAirPlanesName.find(e => e?.toLowerCase() === modelName?.toLowerCase()))
    setInputValue(modelName || "")
  }, [filterAirPlanesName,modelName])

  const onModalTypeChange = (data, obj) => {
    props.handleAircraftModal(obj);
  };

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        inputValue={inputValue}
        value={value}
        size="small"
        classes={{ input: classes.paper, paper: classes.paper }}
        options={filterAirPlanesName}
        className={classes.itemStyle}
        renderInput={(params) => (
          <ValidationSignUpTextField
            {...params}
            className={classes.aircraftStyle}
            label="Select Model*"
            variant="outlined"
            fullWidth
          />
        )}
        onChange={(event, newValue) => {
          setValue(newValue);
          onModalTypeChange(event, newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onBlur={onBlur}
      />
    </div>
  );
};

export default React.memo(CustomAircraft);
