import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Grid } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import constants from "../../../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
    height: 56,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
  },
  notchedOutline: {
    borderWidth: "1px",
    padding: 5,
    borderColor: "black !important",
    color: "#000",
    hintColor: "#000",
  },
  listItem: {
    cursor: "pointer",
  },
  formControl: {
    width: "100%",
    borderRadius: 3,
    height: 35,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  fieldStyle: {
    width: "100%",
  },
  staticTextStyle: {
    width: 120,
    display: "flex",
    color: "#555",
    height: "100%",
    alignItems: "center",
    fontSize: 14,
  },
  innerCont: {
    height: 35,
    width: "100%",
    borderRadius: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  optionStyle: {
    width: "100%",
    fontSize: 14,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  selectedOptionStyle: {
    backgroundColor: "#df2935",
    color: "#fff",
    zIndex: 9,
  },
  unselectOptionStyle: {
    backgroundColor: "#DCDCDC",
    color: "#555",
    marginLeft: -10,
    zIndex: 0,
  },
  margin: {
    marginLeft: -10,
  },
}));

const FuelTypeToggle = (props) => {
  const { onFuelChange={}, getFuelType } = props;
  const classes = useStyles();

  const handleFuelAmount = (value) => {
    onFuelChange(value);
  };

  return (
    <FormControl variant="outlined" className={classes.root}>
      <Grid container className={classes.formControl}>
        <Grid item xs={7} className={classes.staticTextStyle}>
          Fuel Type
        </Grid>
        <Grid item xs={5}>
          <Grid container className={classes.innerCont}>
            <Grid
              item
              xs={6}
              className={
                getFuelType === constants.fuelType.JET_A
                  ? `${classes.optionStyle} ${classes.selectedOptionStyle}`
                  : `${classes.optionStyle} ${classes.unselectOptionStyle}`
              }
              onClick={() => handleFuelAmount(constants.fuelType.JET_A)}
            >
              JET A
            </Grid>

            <Grid
              item
              xs={6}
              className={
                getFuelType === constants.fuelType.SAF
                  ? `${classes.optionStyle} ${classes.selectedOptionStyle} ${classes.margin}`
                  : `${classes.optionStyle} ${classes.unselectOptionStyle} ${classes.margin}`
              }
              onClick={() => handleFuelAmount(constants.fuelType.SAF)}
            >
              SAF
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default FuelTypeToggle;
