import {
  Grid,
  Typography,
  Switch,
  Button,
  Box,
  Toolbar,
  AppBar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingsLayout from "../Settings";
import TextField from "../../components/controls/Input";
import textStyles from "../../constants/fonts";
import axios from "axios";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CorporateAddress from "./CorporateAddress";

import {
  updateProfileSettings,
  getProfile,
} from "../../redux/account/account.actions";
import colors from "../../constants/colors";
import nozlBg2 from "../../assets/images/nozlBg2.png";
import paragonBg1 from "../../assets/images/paragonBg1.png";
import poweredByNozl from "../../assets/images/poweredByNozl.png";
import { prettyString } from "../../components/ui/TextTransform";
import NozlInnerHeading from "../../components/ui/NozlInnerHeading";
import { ValidationTextField } from "../../assets/styles/CurrentStyles";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    ".react-tel-input": {
      padding: 10,
      height: "55px !important",
      position: "relative",
    },
    ".form-control": {
      position: "relative",
      height: "55px !important",
    },
  },
  paperContainer: {
    backgroundImage: `url(${nozlBg2})`,
    backgroundRepeat: "no-repeat",
    color: "white",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 11px 16px",
    objectFit: "contain",
    borderRadius: 3,
  },
  paragonCont: {
    backgroundImage: `url(${paragonBg1})`,
    backgroundRepeat: "no-repeat",
    color: "white",
    width: 374,
    height: 205,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "16px 16px 11px 16px",
    alignItems: "center",
    objectFit: "contain",
    borderRadius: 3,
  },
  detailContainer: {
    width: 338,
    height: "calc(205px - 40px)",
  },
  poweredByImgCont: {
    width: 338,
    height: 60,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  phoneWrap: {
    ".react-tel-input": {
      padding: 10,
      position: "relative",
    },
    ".form-control": {
      position: "relative",
      height: 56,
    },
    textColor: {
      color: "grey",
      textTransform: "capitalize",
    },
  },
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    marginTop: 18,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  phoneContainerStyle: {
    height: 56,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

let phoneNumber = "";
const ProfilePage = () => {
  const textClasses = textStyles();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [baseLocation, setBaseLocation] = useState("");
  const [addLat, setAddLat] = useState("");
  const [addLng, setAddLng] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { auth, account } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
    user: state.user,
  }));

  useEffect(() => {
    dispatch(getProfile({ token: auth.token }));
    data();
  }, []);

  const data = async () => {
    setName(account?.user?.name);
    setEmail(account?.user?.email);
    setCity(account?.user?.city);
    setBaseLocation(account?.user?.baseLocation);
    setState(account?.user?.state);
    setCountryCode(account?.user?.countryCode);
    setCountry(account?.user?.country);
    setCreatedAt(account?.user?.createdAt);
    setMobileNumber(account?.user?.mobileNumber);
    if (
      account?.user?.mobileNumber !== "" &&
      account?.user?.mobileNumber !== undefined
    ) {
      phoneNumber = "";
      let phoneNo = account?.user?.mobileNumber;
      phoneNo = phoneNo.toString().replace(/\D/g, "");
      setMobileNumber(account?.user?.countryCode + phoneNo);
      setIsLoading(false)
    }
  };

  const submitAllDetails = async () => {
    setIsLoading(true)
    const dataUser = {
      name: name.trim(),
      countryCode: countryCode[0] === "+" ? countryCode : `+${countryCode}`,
      mobileNumber: phoneNumber.slice(countryCode.length),
      baseLocation: baseLocation,
      city: city,
      state: state,
      country: country,
      addLat: addLat,
      addLng: addLng,
    };
    dispatch(updateProfileSettings({ user: dataUser, token: auth.token }));
  };

  useEffect(() => {
    data();
  }, [account?.user]);

  function validateForm() {
    return phoneNumber.length > 8 && name.trim().length > 2;
  }

  const getAddress = (address) => {
    phoneNumber = mobileNumber;
    if (address === null || address === '' || address === undefined) {
      setBaseLocation("");
    } else {
      setBaseLocation(address);
    }
  };
  const setCorporateAddress = (address) => {
    phoneNumber = mobileNumber;
    if (address === null || address === '' || address === undefined) {
      setBaseLocation("");
    } else {
      setBaseLocation(address);
    }
  };

  const baseLocationLatLong = (baseLocationLatLong) => {
    phoneNumber = mobileNumber;
    setAddLat(baseLocationLatLong.lat);
    setAddLng(baseLocationLatLong.lng);
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          baseLocationLatLong.lat +
          "," +
          baseLocationLatLong.lng +
          "&key=AIzaSyClgWmN0aJIplOG6yEL9g1sjxVPq8IytQo"
      )
      .then((response) => {
        const res = response.data.results;
        let data = res[res.length - 4].address_components;
        setCity(data[0].long_name);
        setState(data[1].long_name);
        setCountry(data[2].long_name);
      })
      .catch(function (error) {});
  };

  return (
    <SettingsLayout>
      <NozlInnerHeading title="Profile" />
      <div
        style={{
          display: "flexWrap",
          overflowY: "auto",
          maxHeight: "calc(100vh - 150px)",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Grid item xs={12} style={{ margin: "0px 0px 20px 0px" }}>
              <Box mx={3}>
                <Typography
                  className={`${textClasses.heading3} ${textClasses.colorGray}`}
                >
                  Email
                </Typography>
                <TextField
                  disabled
                  className={classes.textColor}
                  theme="black"
                  id="email"
                  value={email}
                  placeholder="Email"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mx={3}>
                <Typography
                  className={`${textClasses.heading3} ${textClasses.colorGray}`}
                >
                  {auth?.userType === 2
                    ? "Flight Department Name"
                    : "Full Name"}
                </Typography>
                <ValidationTextField
                  id="name"
                  variant="outlined"
                  disabled={auth.userType === 9}
                  className={classes.textColor}
                  value={name}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  placeholder={
                    auth?.userType === 2
                      ? "Flight Department Name"
                      : "Full Name"
                  }
                  onChange={(e) => {
                    phoneNumber = mobileNumber;
                    if (
                      e.target.value.match("^[a-zA-Z 0-9_@.'&/#-]*$") != null
                    ) {
                      setName(prettyString(e.target.value));
                    }
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
              <Box mx={3}>
                <Typography
                  className={`${textClasses.heading3} ${textClasses.colorGray}`}
                >
                  Main Phone Number
                </Typography>
                <PhoneInput
                  disabled={auth.userType === 9}
                  value={mobileNumber}
                  enableSearch={true}
                  enableAreaCodes={false}
                  autoFormat={true}
                  searchStyle={{ width: "100%", margin: "auto" }}
                  inputStyle={{
                    height: 56,
                    width: "100%",
                    border: "none",
                    color: "black",
                    cursor: "text",
                  }}
                  containerStyle={{
                    width: "100%",
                    border: "1px solid black",
                    opacity: auth.userType === 9 ? 0.3 : 1,
                    height: 56,
                    cursor: "text",
                    borderRadius: 5,
                  }}
                  placeholder="Main Phone Number"
                  className={classes.margin}
                  containerClass={classes.phoneContainerStyle}
                  autocompleteSearch={true}
                  searchPlaceholder="Search"
                  disableSearchIcon={true}
                  buttonStyle={{ border: "none" }}
                  onChange={(phone, data) => {
                    phoneNumber = phone;
                    setMobileNumber(phone);
                    setCountryCode(data.dialCode);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography
                  className={`${textClasses.heading3} ${textClasses.colorGray}`}
                  style = {{ paddingLeft: 25 }}
              >
                Corporate Address Location (Optional)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box mx={3}>
                <CorporateAddress
                 baseLocationLatLong={baseLocationLatLong}
                 address={getAddress}
                 setCorporateAddress={setCorporateAddress}
                 baseLocation={baseLocation}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div
              className={
                account?.isParagon !== undefined && account?.isParagon === true
                  ? classes.paragonCont
                  : classes.paperContainer
              }
            >
              <div className={classes.detailContainer}>
                <div className={classes.heading} style={{ marginTop: 5 }}>
                  <div style={{ fontWeight: "bold" }}>{prettyString(name)}</div>
                </div>
                {account?.isParagon === true ? (
                  <div className={classes.heading} style={{ marginTop: 5 }}>
                    <div style={{ fontWeight: "bold" }}>P09837AG</div>
                  </div>
                ) : (
                  <div className={classes.heading} style={{ marginTop: 5 }}>
                    <div style={{ fontWeight: "bold" }}>N09837ZL</div>
                  </div>
                )}

                <div className={classes.heading} style={{ marginTop: 5 }}>
                  <div style={{ fontWeight: "bold" }}>
                    Member since:{" "}
                    {createdAt !== "Invalid date" && createdAt !== ""
                      ? moment(createdAt).format("YYYY")
                      : ""}
                  </div>
                </div>
              </div>
              {account?.isParagon !== undefined && account?.isParagon === true && (
                <Grid item xs={12} className={classes.poweredByImgCont}>
                  <img alt="" src={poweredByNozl} style={{ width: 108, height: 60 }} />
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <Box p={3}>
                <Button
                  disabled={!validateForm() || isLoading}
                  onClick={() => submitAllDetails()}
                  fullWidth
                  className={classes.submitBtn}
                  disableElevation
                >
                  Save Details
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </SettingsLayout>
  );
};

export default ProfilePage;
