import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles(() => ({
  mainCont: {
    width: "100%",
    height: "calc(100vh - 70px)",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonTextStyle: {
    height: 32,
    width: 95,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  acceptedTextStyle: {
    color: "#fff",
    background: "#5cb082",
  },
  declinedTextStyle: {
    background: "#ebecf0",
    color: "#42526e",
  },
  mainContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  innerCont: {
    maxHeight: "570px",
    borderRadius: "10px",
    background: "#fafafa",
  },
  innerMainCont: {
    width: "100%",
    height: "100%",
    padding: 40,
  },
  titleStyle: {
    fontSize: "24px",
    fontFamily: "Arial",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "-0.25px",
    textAlign: "left",
    color: "#333",
  },
  itemContainer: {
    margin: "20px 0px 0px 0px",
  },
  detailCont: {
    padding: "0px 40px 0px 0px",
  },
  margin: {
    margin: "20px 0px 0px 0px",
  },
  headingStyle: {
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "0.25px",
    textAlign: "left",
    color: "#999",
  },
  commentText: {
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#131415",
  },
  secondInnerCont: {
    padding: "20px",
    background: "#fff2e5",
  },
  firstCont: {
    height: "100%",
  },
  textStyle: {
    opacity: 0.8,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    marginBottom: 22,
  },
  buttonCont: {
    width: "100%",
    display: "flex",
    marginTop: 25,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonStyle: {
    height: 40,
    width: 140,
    flexGrow: 0,
    gap: "10px",
    padding: "12px 30px",
    borderRadius: 5,
    background: colors.primaryColor,
    lineHeight: 1.14,
    color: "#fff",
    fontWeight: "600",
    fontFamily: "Arial",
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
    textTransform: "capitalize",
  },
}));
export { useStyles };
