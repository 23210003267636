import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formCard: {
        marginTop: 20,
        backgroundColor: "#fff",
        justifyContent: "center",
        alignSelf: "center",
        border: "0px solid black",
        borderRadius: "5px!important",
    },
    regitry2: {
        "&:disabled": {
            color: "green",
        },
        justifyContent: "center",
        alignContent: "center",
        width: (colors, isMobile) => isMobile ? "90%" : "80%",
        marginTop: 20,
        backgroundColor: "#fff",
        background: "#fff",
        color: "#fff",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
    },
    dateArea: {
        marginTop: 20,
        width: 590,
        height: 56,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
    },
    head: {
        display: "flex",
        width: "100%",
        height: 60,
        marginBottom: 100,
        textAlign: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        color: (colors) => colors.white,
        backgroundColor: (colors) => colors.denim,
        justifyContent: "flex-start",
    },
    innerHead: {
        display: "flex",
        textAlign: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        marginLeft: 20,
    },
    mid: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: 400,
        overflowY: "auto",
        marginBottom: 15,
        justifyContent: "flex-start",
    },
    foot: {
        display: "flex",
        marginBottom: 10,
        marginTop: 10,
        justifyContent: "flex-end",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    items: {
        display: "flex",
        flexDirection: "row",
        margin: 8,
        borderBottom: "#000",
        cursor: 'pointer'
    },
    pilotName: {
        fontSize: 18,
        color: "#000",
        fontWeight: "400",
    },
    pilotName1: {
        fontSize: 16,
        color: "#000",
        fontWeight: "300",
    },
    checkMark: {
        width: 20,
        height: 20,
        borderRadius: 20 / 2,
        borderWidth: 2,
        borderColor: (colors) => colors.primaryColor,
        fontSize: (colors, isMobile) => isMobile ? 10 : 14,
        padding: 5,
        textAlign: "left",
        marginRight: 20,
        marginTop: 10,
        border: "1px solid red",
        cursor: 'pointer',
    },
    checkMarkFill: {
        width: 20,
        height: 20,
        borderRadius: 20 / 2,
        borderWidth: 2,
        borderColor: (colors) => colors.primaryColor,
        backgroundColor: (colors) => colors.primaryColor,
        fontSize: (colors, isMobile) => isMobile ? 10 : 14,
        padding: 5,
        textAlign: "left",
        marginRight: 20,
        marginTop: 10,
        border: "1px solid red",
        cursor: 'pointer',
    },
    bg: {
        backgroundColor: "#000",
    },
    loginBtn: {
        background: "linear-gradient(270deg, rgb(63, 152, 255), rgb(71, 215, 231))",
        color: "#fff",
        margin: 10,
    },
    titleCon: {
        height: "50px",
        display: "flex",
        alignItems: "center",
        marginRight: 10,
        justifyContent: "space-between",
    },
    titleText: {
        color: "#161617",
        fontWeight: 500,
        fontSize: 22,
    },
    submitBtn: {
        backgroundColor: (colors) => colors.primaryColor,
        color: "#fff",
        padding: "0px 20px 0px 20px",
        borderRadius: 5,
        height: 35,
        "&:hover": {
            backgroundColor: (colors) => colors.primaryColor,
        },
    },

}));

export { useStyles };