import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { useStyles } from "../../../../assets/styles/NudgeDetailStyles";

import colors from "../../../../constants/colors";
import { prettyString, textString } from "../../../ui/TextTransform";
import moment from "moment";
import { useHistory } from "react-router-dom";

const NudgeDetail = (props) => {
  const classes = useStyles(colors);
  const history = useHistory();
  const nudgeItem = props.location.state.param.item;

  const handleClickButton = () => {
    history.push("/home");
  };

  return (
    <Grid container className={classes.mainCont}>
      <Grid container={true} item xs={12} className={classes.mainContainer}>
        <Grid item xs={8} className={classes.innerCont}>
          <Grid container xs={12} className={classes.innerMainCont}>
            <Grid item xs={10} className={classes.titleStyle}>
              {props.location.state.param.title.length > 100 ? (
                <Tooltip title={textString(props.location.state.param.title)}>
                  <div>
                    {props.location.state.param.title}
                    <span>...</span>
                  </div>
                </Tooltip>
              ) : (
                <div>{props.location.state.param.title.slice(0, 100)}</div>
              )}
            </Grid>
            <Grid item xs={2} className={classes.buttonContainer}>
              <Grid
                className={
                  nudgeItem.status === 2
                    ? `${classes.buttonTextStyle} ${classes.acceptedTextStyle}`
                    : `${classes.buttonTextStyle} ${classes.declinedTextStyle}`
                }
              >
                {nudgeItem.status === 2 ? "Accepted" : "Declined"}
              </Grid>
            </Grid>
            <Grid container className={classes.itemContainer}>
              <Grid item xs={6} className={classes.detailCont}>
                <Grid item xs={12}>
                  <Grid item xs={12} className={classes.headingStyle}>
                    Comments
                  </Grid>
                  <Grid item xs={12} className={classes.commentText}>
                    {nudgeItem.message.length > 150 ? (
                      <Tooltip
                        title={
                          <div className={classes.commentStyle}>
                            {textString(nudgeItem.message)}
                          </div>
                        }
                      >
                        <div>
                          {textString(nudgeItem.message.slice(0, 150))}
                          <span>...</span>
                        </div>
                      </Tooltip>
                    ) : (
                      <div>{textString(nudgeItem.message)}</div>
                    )}
                  </Grid>
                </Grid>
                <Grid container={true} item xs={12} className={classes.margin}>
                  <Grid item xs={12} className={classes.headingStyle}>
                    Reply
                  </Grid>
                  <Grid item xs={12} className={classes.commentText}>
                    {nudgeItem.reply.length > 150 ? (
                      <Tooltip
                        title={
                          <div className={classes.commentStyle}>
                            {textString(nudgeItem.reply)}
                          </div>
                        }
                      >
                        <div>
                          {textString(nudgeItem.reply.slice(0, 150))}
                          <span>...</span>
                        </div>
                      </Tooltip>
                    ) : (
                      <div>{textString(nudgeItem.reply)}</div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.secondInnerCont}>
                <Grid container>
                  <Grid item xs={6} className={classes.firstCont}>
                    <Grid item xs={12} className={classes.headingStyle}>
                      ETA
                    </Grid>
                    <Grid item xs={12} className={classes.textStyle}>
                      {nudgeItem.eta !== null
                        ? moment(nudgeItem.eta).format("YYYY-MM-DD HH:mm")
                        : "NA"}
                    </Grid>
                    <Grid item xs={12} className={classes.headingStyle}>
                      Gallons Requested
                    </Grid>
                    <Grid item xs={12} className={classes.textStyle}>
                      {nudgeItem.gallons}
                    </Grid>
                    {nudgeItem.aircraftDetail !== undefined ? (
                      <>
                        <Grid item xs={12} className={classes.headingStyle}>
                          Aircraft Tail
                        </Grid>
                        <Grid item xs={12} className={classes.textStyle}>
                          {nudgeItem.aircraftDetail.registryName}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>

                  <Grid item xs={6} className={classes.firstCont}>
                    <Grid item xs={12} className={classes.headingStyle}>
                      ETD
                    </Grid>
                    <Grid item xs={12} className={classes.textStyle}>
                      {nudgeItem.etd !== null
                        ? moment(nudgeItem.etd).format("YYYY-MM-DD HH:mm")
                        : "NA"}
                    </Grid>
                    <Grid item xs={12} className={classes.headingStyle}>
                      Negotiation Price
                    </Grid>
                    <Grid item xs={12} className={classes.textStyle}>
                      {nudgeItem.negotiationType === 1
                        ? "Best Price" : `$${parseFloat(nudgeItem.negotiationPrice.priceMatch).toFixed(
                          2
                        )}/gal`}
                    </Grid>
                    {nudgeItem.aircraftDetail !== undefined ? (
                      <>
                        <Grid item xs={12} className={classes.headingStyle}>
                          Aircraft Type
                        </Grid>
                        <Grid item xs={12} className={classes.textStyle}>
                          {prettyString(
                            nudgeItem.aircraftDetail.airplaneDetail
                              .airplaneMakerDetail.name
                          )}{" "}
                          {prettyString(
                            nudgeItem.aircraftDetail.airplaneDetail.name
                          )}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} className={classes.buttonCont}>
          <Button className={classes.buttonStyle} onClick={handleClickButton}>
            home
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NudgeDetail;