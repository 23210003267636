import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import colors from "../../constants/colors";

const useStyles = makeStyles(() => ({
  root: {
    "&$checked": {
      color: colors.primaryColor,
    },
  },
  paragonRoot: {
    "&$checked": {
      color: colors.paragonPrimaryColor,
    },
  },
  checked: {},
  firstCont: {
    marginTop: 40,
    height: 80,
  },
  secondCont: {
    width: "100%",
    marginTop: 40,
  },
  iframeSecondCont: {
    width: "100%",
    marginTop: 20,
  },
  summaryCont: {
    overflowY: "scroll",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 60px)",
  },
  dotStyle: {
    height: 20,
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
  },
  lineStyle: {
    height: 1,
    marginTop: 40,
    borderBottom: "1px solid #e8e8e8",
  },
  secondInnerCont: {
    height: "356px",
    width: "410px",
    padding: 30,
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    border: "1px solid #e8e8e8",
  },
  secondContainer: {
    height: "356px",
    width: "410px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  profileDetail: {
    marginTop: 20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px",
    borderRadius: 5,
    border: "1px solid #ddd",
    background: "#fafbfc",
  },
  iframeProfileDetail: {
    padding: 10,
    marginTop: 20,
  },
  thirdCont: {
    marginTop: 28,
  },
  profileTextHeading: {
    height: 20,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    justifyContent: "space-between",
    display: "flex",
    color: "#333",
  },
  headingTextStyle: {
    height: 20,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#999",
  },
  iframeHeadingTextStyle: {
    width: "100%",
    lineHeight: "25px",
  },
  iframeDotStyle: {
    fontSize: 14,
    width: "100%",
    lineHeight: "25px",
  },
  valueStyle: {
    height: 24,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 16,
    fontWeight: "500",
  },
  userCont: {
    width: "100%",
  },
  mainCont: {
    width: 260,
    height: 80,
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  userNameTextStyle: {
    height: 24,
    fontSize: 16,
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#333",
    width: "100%",
  },
  jobTitleTextStyle: {
    fontSize: 12,
    fontWeight: "500",
    letterSpacing: 0.25,
    color: "#797f8a",
  },
  commonStyle: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "left",
  },
  userEmailTextStyle: {
    fontSize: 12,
    fontWeight: "500",
    letterSpacing: 0.25,
    color: "#999",
  },
  indexStyle: {
    fontSize: 12,
    fontWeight: 500,
    marginTop: 3,
    height: 30,
    color: "#333",
    letterSpacing: "normal",
  },
  mainTermsCont: {
    height: 525,
    flexGrow: 0,
    padding: "20px 0 20px",
    borderRadius: 10,
    marginTop: 40,
    margin: 5,
    boxShadow:
      "2px 2px 5px 0 rgba(0, 0, 0, 0.1), -2px -2px 5px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fafbfc",
  },
  iframeMainTermsCont: {
    padding: "5px 0 5px",
    marginTop: 32,
    height: 500,
  },
  termsAndPolicyHeadingStyle: {
    width: "100%",
    height: 50,
    padding: "0 30px 0.1px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ebecf0",
  },
  iframeTermsAndPolicyHeadingStyle: {
    padding: 0,
  },
  termsTextHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: 50,
    fontFamily: "Arial",
    fontSize: 14,
    cursor: "pointer",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: 1,
    paddingLeft: "20px",
    color: "#212121",
    borderBottom: "2px solid transparent",
  },
  iframeTermsTextHeading: {
    fontSize: 12,
    fontWeight: "600",
  },
  mainTermsPolicyCont: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle1: {
    fontFamily: "Arial",
    fontSize: 16,
    marginLeft: 10,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#666",
  },
  iframeTextStyle1: {
    marginLeft: 5,
  },
  textStyle2: {
    margin: "10px 20px 10px 10px",
    fontFamily: "Arial",
    fontSize: 14,
    lineHeight: 1.8,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#666",
  },
  iframeTextStyle2: {
    margin: "5px",
    fontSize: 12,
    lineHeight: 1.8,
  },
  termsAndCondInnerCont: {
    overflowY: "scroll",
    height: 350,
    display: "flex",
    flexDirection: "column",
    margin: 20,
    padding: 10,
    borderRadius: 5,
    boxShadow: "2px 2px 4px 0 #e6ebef, -2px -2px 4px 0 #e6ebef",
    border: "1px solid #e6ebef",
  },
  iframeTermsAndCondInnerCont: {
    margin: 10,
  },
  checkboxCont: {
  },
  checkboxStyle: {
    height: 20,
    flexGrow: 0,
    margin: "0 0 0 10px",
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#212121",
  },
  iframeCheckBoxStyle: {
    fontSize: 12,
    margin: 0,
    fontWeight: "normal",
  },
  editButton: {
    width: "100%",
    height: 20,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#1e69f6",
  },
  headingBorderColor: {
    borderBottom: "2px solid #212121",
  },
  headingStyle: {
    height: 20,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#333",
  },
  showMoreTextStyle: {
    fontSize: 12,
    width:'100%',
    textAlign:'right',
    color: '#1e69f6',
    cursor: "pointer",
    textDecoration: "underline"
  }
}));

export { useStyles };

export const CheckboxWithGreenCheck = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fill: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "1px solid #bbbbbb",
        borderRadius: 3,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "green",
        zIndex: -1,
        borderColor: "transparent",
      },
    },
    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      border: "1px solid #bbbbbb",
      borderRadius: 3,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
      borderColor: "transparent",
    },
  },
  checked: {},
})(Checkbox);
