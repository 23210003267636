import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import ContactAdded from "./ContactAdded";
import AddUsers from "../Contacts/AddUserDetail";

const useStyles = makeStyles(() => ({
  addNewUserStyle: {
    marginLeft: 30,
  },
}));

const SecondaryUser = (props) => {
  const classes = useStyles();
  const { primaryContactDetails, addAdditionalUsers, editListItem, editIndex } = props;

  const addNewUser = (newUser) => {
    props.addNewUser(newUser);
  };

  const deleteItem = (item, index) => {
    props.deleteItem(item, index);
  };

  const editItem = (item, index) => {
    props.editItem(item, index);
  };

  const cancelUser = (value) =>{
    props.cancelUser(value);
  }

  return (
    <div style={{ overflowX: "scroll", display: "flex", overflowY: "hidden" }}>
      <ContactAdded
        primaryContactDetails={primaryContactDetails}
        editListItem={editListItem}
        editIndex={editIndex}
        addAdditionalUsers={addAdditionalUsers}
        editItem={editItem}
        addNewUser={addNewUser}
        deleteItem={deleteItem}
        cancelUser={cancelUser}
      />
      <Grid className={classes.addNewUserStyle}>
        {addAdditionalUsers === true && (
          <AddUsers addNewUser={addNewUser} cancelUser={cancelUser} title="Secondary User" />
        )}
      </Grid>
    </div>
  );
};

export default SecondaryUser;
