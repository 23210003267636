import React from "react";

import { Grid, makeStyles } from "@material-ui/core";
import noNetworkIcon from "../../../../assets/images/noNetworkIcon.png";

const useStyles = makeStyles(() => ({
  firstCont: {
    width: "513px",
    height: "362.8px",
    display: "flex",
    flexDirection: "column",
    alignItems:"center",
    justifyContent: "center",
    gap: "20px",
    borderRadius: 10,
    boxShadow: "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff"
  },
  imageStyle: {
    width: 227.3,
    height: 168.8,
    flexGrow: 0,
    objectFit: "contain",
  },
  textStyle: {
    width: '100%',
    height: 40,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#333"
  },
  textStyle2: {
    height: 24,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#666"
  },
}));

const InternetConnection = () => {
  const classes = useStyles();
  let icon = localStorage.getItem("noNetConnectionIcon");

  return (
    <Grid container className={classes.firstCont}>
      <img alt="" src={!!icon ? icon : noNetworkIcon} className={classes.imageStyle} />
      <Grid className={classes.textStyle}>Connect to the internet</Grid>
      <Grid className={classes.textStyle2}>
        You're offline. Check your connection.
      </Grid>
    </Grid>
  );
};

export default InternetConnection;
