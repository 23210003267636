import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { toast } from "react-toastify";
import ClearIcon from "@material-ui/icons/Clear";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import NudgePrice from "./NudgePrice";
import PriceToggle from "./PriceToggle";
import colors from "../../../../constants/colors";
import RampFeeType from "../../../../config/RampFeeType";
import { prettyString } from "../../../ui/TextTransform";
import FuelRequestDate from "../Fuelrequest/FuelRequestDate";
import { useStyles } from "../../../../assets/styles/FboItemStyles";
import { calculateRateType } from "./FboDetailHelper";
import { getTimezoneByLatLong } from "../Fuelrequest/TimeZoneHelper";
import { sendNudge } from "../../../../redux/fbos/fbos.actions";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";
import constants from "../../../../config/constants";
import ShowInfoTiers from "./ShowInfoTiers";

let currentTime = "";

const NudgePopup = (props) => {
  const { item } = props;
  const dispatch = useDispatch();
  const classes = useStyles(colors, isMobile);
  const [comment, setComment] = React.useState("");
  const [mtowLb, setMtowLB] = useState(0);
  const [mlwLbs, setMlwLBs] = useState(0);
  const [gallons, setGallons] = useState("");
  const [priceType, setPriceType] = useState(1);
  const [isPriceType, setIsPriceType] = useState(false);
  const [waiveOff, setWaiveOff] = useState(0);
  const [priceMatch, setPriceMatch] = useState(0);
  const [timeZone, setTimeZone] = useState("");
  const [showRampFee, setShowRampFee] = useState(false);
  const [parkingAreaSf, setParkingAreaSf] = useState(0);
  const [parkingFee, setParkingFee] = useState(0);
  const [estimateDepartureTime, setEstimateDepartureTime] = useState("");
  const [estimateArrivalTime, setEstimateArrivalTime] = useState("");

  const { account, fbos, auth } = useSelector((state) => ({
    account: state.account,
    fbos: state.fbos,
    auth: state.auth,
  }));

  const isAircraftSelected =
    Object.values(account?.userSelectedAircraft).length > 0
      ? account.userSelectedAircraft
      : false;
  const fuelType =
    account?.userSelectedAircraft !== undefined &&
      Object.values(account?.userSelectedAircraft).length > 0
      ? account?.userSelectedAircraft?.airplaneDetail?.fuelType
      : 0;

  const disabledMode = Object.values(account?.userSelectedAircraft).length > 0 ? !((fuelType === 1 && item.isJetAFuel === 1) || (fuelType === 1 && item.isSAFFuel === 1) ||
    (item.is100LLFuel === 1 && fuelType === 2)) :
    !(item.isJetAFuel === 1 || item.is100LLFuel === 1 || item.isSAFFuel === 1);

  useEffect(() => {
    currentTime = new Date();
    setMtowLB(
      Object.values(account?.userSelectedAircraft).length > 0
        ? account.userSelectedAircraft?.airplaneDetail?.mtowLb
        : 0
    );
    setMlwLBs(
      Object.values(account?.userSelectedAircraft).length > 0
        ? account.userSelectedAircraft?.airplaneDetail?.mlwLbs
        : 0
    );
    setParkingAreaSf(
      Object.values(account?.userSelectedAircraft).length > 0
        ? account.userSelectedAircraft?.airplaneDetail?.parkingAreaSf
        : 0
    );
  }, [isAircraftSelected]);

  useEffect(() => {
    if (Object.values(account?.userSelectedAircraft).length > 0) {
      if (item.isRampFee === 0) {
        setShowRampFee(false);
      } else {
        calculaterampFee();
      }
    }
  }, [waiveOff, mlwLbs, parkingAreaSf]);

  const calculaterampFee = async () => {
    let calculaterampFee = await calculateRateType(
      item,
      RampFeeType,
      account,
      mtowLb,
      mlwLbs,
      parkingAreaSf
    );
    setShowRampFee(calculaterampFee.showRampFee);
    setParkingFee(calculaterampFee.parkingFee);
    setWaiveOff(calculaterampFee.waiveOff);
  };

  const handleEditText = (e) => {
    props.handleEditText(e);
  };

  const handleClose = () => {
    props.handleClose(true, item.nudgeCount);
  };

  const onChange = (value) => {
    if (value === 1) {
      setPriceType(value);
      setIsPriceType(false);
    } else {
      setPriceType(value);
      setIsPriceType(true);
    }
  };

  const selectedEstimateArrivalTime = (date) => {
    if (date === "Invalid date:undefined") {
      setEstimateArrivalTime("");
    } else {
      let abc = new Date(date);
      setEstimateArrivalTime(abc);
    }
  };

  const selectedEstimateDepartureTime = (date) => {
    if (date === "Invalid date:undefined") {
      setEstimateDepartureTime("");
    } else {
      let abc = new Date(date);
      setEstimateDepartureTime(abc);
    }
    var startTime = new Date(estimateArrivalTime);
    var endTime = new Date(date);
    if (startTime > endTime && date !== null) {
      toast.info("ETA should be earlier than ETD", {
        position: "bottom-center",
        closeButton: false,
      });
    } else {
    }
  };

  const timezone = (zone) => {
    setTimeZone(zone);
  };

  const nudgeMessage = (value) => {
    setComment(value.trim());
  };

  const onPriceChange = (e) => {
    setPriceMatch(e);
  };

  const onGallonChange = (e) => {
    setGallons(e);
  };

  const onSendNudge = async () => {
    let zone = "";
    if (timeZone === "") {
      zone = await getTimezoneByLatLong(fbos.fboGeoLoc);
    } else {
      zone = timeZone;
    }
    const nudgeRequest = {
      fboId: item.fboId,
      message: comment,
      negotiationType: priceType,
      gallons: gallons,
      negotiationPrice: {
        priceMatch: priceType === 1 ? 0 : priceMatch,
        retailPrice: item.retailPrice,
        ll100Price: item.retailPriceLL,
      },
      operatorTimeZone: moment.tz.guess(),
      airportTimeZone: zone,
      eta: estimateArrivalTime,
      etd: estimateDepartureTime,
    };
    if (Object.values(account?.userSelectedAircraft).length > 0) {
      nudgeRequest.aircraftId = account?.userSelectedAircraft?._id;
    }
    if (
      waiveOff > 0 &&
      Object.values(account?.userSelectedAircraft).length > 0
    ) {
      nudgeRequest.rampFee = `Request ${waiveOff} gal or more to waive $${formatNumWithCommas(
        parseFloat(parkingFee).toFixed(2)
      )} ramp fee`;
    }
    var startTime = new Date(estimateArrivalTime);
    var endTime = new Date(estimateDepartureTime);
    startTime = startTime.setSeconds(startTime.getSeconds() + 30);
    if (
      priceType === 2 &&
      (priceMatch === 0 || priceMatch === "0" || priceMatch === "")
    ) {
      toast.info("Price should be greater than 0", {
        position: "bottom-center",
        closeButton: false,
      });
    } else if (gallons === "0" || gallons === 0 || gallons === "") {
      toast.info("Please enter valid gallon.", {
        position: "bottom-center",
        closeButton: false,
      });
    } else if (estimateArrivalTime === "" && estimateDepartureTime === "") {
      dispatch(sendNudge({ nudge: nudgeRequest, token: auth.token }));
      // TODO discountCountValue should be greater than 0
      item.nudgeCount = 1;
      setComment("");
      handleClose(false);
    } else if (
      (estimateDepartureTime !== "" || estimateDepartureTime !== null) &&
      startTime > endTime
    ) {
      toast.info("ETA should be earlier than ETD", {
        position: "bottom-center",
        closeButton: false,
      });
    } else if (estimateArrivalTime === "") {
      toast.info("Please enter ETA", {
        position: "bottom-center",
        closeButton: false,
      });
    } else if (currentTime > startTime) {
      toast.info("Please enter valid ETA", {
        position: "bottom-center",
        closeButton: false,
      });
    } else if (estimateDepartureTime === "") {
      toast.info("Please enter ETD", {
        position: "bottom-center",
        closeButton: false,
      });
    } else if (priceType === 2 && (priceMatch === 0 || priceMatch === "0")) {
      toast.info("Price should be greater than 0", {
        position: "bottom-center",
        closeButton: false,
      });
    } else {
      dispatch(sendNudge({ nudge: nudgeRequest, token: auth.token }));
      //TODO discountCountValue should be greater than 0
      item.nudgeCount = 1;
      setComment("");
      handleClose(false);
    }
  };

  const isDiscount = () => {
    return item.discountFuelType < constants.discount.hundredLL &&
      (item.discountCount === 1 ||
        item.operatorRegistryDiscountCount === 1 ||
        item.operatorSpecificDiscountCount === 1 ||
        item.tailWiseDiscountCount === 1);
  }

  const renderPrice = (type) => {
    let preferredDiscount = false;
    let fuelTiers = [];
    let fuelType = '';
    const discountJetAandSafBoth = item.discountFuelType === constants.discount.jetA_Saf_Both;
    let retailPrice = 0;
    switch (type) {
      case constants.discount.jet_A:
        {
          preferredDiscount = item.isJetPreferredDiscount;
          retailPrice = formatNumWithCommas(parseFloat(item.retailPrice).toFixed(2));
          fuelTiers =
            discountJetAandSafBoth || item.discountFuelType === constants.discount.jet_A
              ? item.discountTiers
              : item.tiersJetA;
          fuelType = constants.string.jetA;
        }
        break;
      case constants.discount.saf:
        {
          preferredDiscount = item.isSAFPreferredDiscount;
          retailPrice = formatNumWithCommas(parseFloat(item.retailPriceSAF).toFixed(2));
          fuelTiers = discountJetAandSafBoth
            ? item.secondDiscountTier
            : item.discountFuelType === constants.discount.saf
              ? item.discountTiers
              : item.tiersSAF;
          fuelType = constants.string.saf;
        }
        break;
      case constants.discount.hundredLL:
        {
          retailPrice = item.retailPriceLL;
          fuelType = constants.string.hundredLL;
        }
        break;
      default:
    }

    return (
      <div className={classes.priceStyle}>
        <div className={classes.fuelPriceStyle}>
          <div className={classes.statictext}>{`${fuelType}
            ${preferredDiscount ? !item.isParagonUser ? "(NOZL Direct)" : "(Paragon Preferred)" : ""}:`
          }</div>
          {fuelTiers.length > 0 ? (
            <div className={classes.fuelPriceStyle}>
              {parseFloat(fuelTiers[0].retailPrice).toFixed(2) !== retailPrice ? (
                <div className={classes.discountedStyle}>
                  {`$${formatNumWithCommas(parseFloat(retailPrice).toFixed(2))}/gal`}
                </div>
              ) : null}
              <div className={classes.textPriceStyle}>
                {`$${parseFloat(fuelTiers[0].retailPrice).toFixed(2)}/gal`}
              </div>
            </div>
          ) : (
            <div className={classes.textPriceStyle}>
              {`$${parseFloat(retailPrice).toFixed(2)}/gal`}
            </div>
          )}
          {fuelTiers.length>1 ? (
            <ShowInfoTiers tiers={fuelTiers} retailPrice={retailPrice} discount={isDiscount} />
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <div
      style={{ padding: 20 }}
      className={classes.paper}
      onClick={handleEditText}
    >
      <div className={classes.headingStyle}>
        <div className={classes.headingTextStyle}>Nudge</div>
        <div className={classes.imageCont}>
          <ClearIcon
            className={classes.imageStyle}
            onClick={() => handleClose()}
          />
        </div>
      </div>
      <div className={classes.headingContainer}>
        <div className={classes.fboNameStyle}>{item.fboName}</div>
        {account.userSelectedAircraft !== undefined &&
          Object.values(account.userSelectedAircraft).length > 0 && (
            <div className={classes.priceCont}>
              <div className={classes.selectedAircraftStyle}>
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: "500" }}>
                    Aircraft Tail: &nbsp;{" "}
                  </div>
                  <div>
                    {" "}
                    {account.userSelectedAircraft.registryName.toUpperCase()}
                  </div>
                </div>
              </div>
              <div
                className={classes.selectedAircraftStyle}
                style={{ marginTop: 5 }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ fontWeight: "500" }}>
                    {" "}
                    Aircraft Type: &nbsp;{" "}
                  </div>
                  <div> {prettyString(account.userSelectedAircraft.name)}</div>
                </div>
              </div>
            </div>
          )}
        <div className={classes.priceCont}>
            {fuelType < constants.fuelType.HUNDRED_LL && item.isJetAFuel === constants.fuelApplied.jetA ? (
              renderPrice(constants.discount.jet_A)
            ) : null}
             {fuelType < constants.fuelType.HUNDRED_LL && item.isSAFFuel === constants.fuelApplied.saf ? (
              renderPrice(constants.discount.saf)
            ) : null}
            {item.is100LLFuel === constants.fuelApplied.hundredLL &&
              (fuelType === constants.fuelType.fuel_type || fuelType === constants.fuelType.HUNDRED_LL) ? (
              renderPrice(constants.discount.hundredLL)
            ) : null}
        </div>
        <div className={classes.noFueltypeTextStyle}>
            {fuelType < constants.fuelType.HUNDRED_LL && item.isSAFFuel === constants.fuelApplied.noSaf && item.isJetAFuel === constants.fuelApplied.noJetA && !!account?.userSelectedAircraft &&
              Object.values(account?.userSelectedAircraft).length > 0 ? (
               "FBO does not provide Jet A and SAF type fuel."
            ) : null}
            {(fuelType === constants.fuelType.fuel_type || fuelType === constants.fuelType.HUNDRED_LL) && item.is100LLFuel === constants.fuelApplied.noHundredLL && !!account?.userSelectedAircraft &&
              Object.values(account?.userSelectedAircraft).length > 0 ? (
               "FBO does not provide 100LL type fuel."
            ) : null}
        </div>
        {showRampFee && (
          <div className={classes.priceStyle} style={{ marginBottom: 10 }}>
            {`Request ${waiveOff} gal or more to waive $${formatNumWithCommas(
              parseFloat(parkingFee).toFixed(2)
            )} ramp fee`}
          </div>
        )}
      </div>

      <Grid container item xs={12}>
        <FuelRequestDate
          navigation={"fromNudge"}
          selectedEstimateArrivalTime={selectedEstimateArrivalTime}
          selectedEstimateDepartureTime={selectedEstimateDepartureTime}
          timezone={timezone}
        />
      </Grid>

      <Grid container item xs={12}>
        <PriceToggle onChange={onChange} />
      </Grid>

      <Grid container item xs={12}>
        <NudgePrice
          isPriceType={isPriceType}
          nudgeMsg={nudgeMessage}
          onPriceChange={onPriceChange}
          totalGallons={onGallonChange}
        />
      </Grid>
      <Button
        disabled={comment.length < 3 || disabledMode}
        onClick={onSendNudge}
        fullWidth
        variant="contained"
        className={classes.submitBtn}
      >
        Submit Request
      </Button>
    </div>
  );
};

export default NudgePopup;
