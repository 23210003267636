import { makeStyles } from "@material-ui/core/styles";
import Colors from "./colors";

const commonStyles = makeStyles((theme) => ({
  pageHeader: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid #ddd",
    paddingLeft: 0,
  },
  innerHeader: {
    backgroundColor: "#fafafa",
    color: "#000",
    marginBottom: 20,
    boxShadow: "none",
  },
  submitBtn: {
    backgroundColor: Colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: Colors.primaryColor,
    },
  },
  submitBtnsmall: {
    backgroundColor: Colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: Colors.primaryColor,
    },
  },
  submitParagonBtnsmall: {
    backgroundColor: Colors.paragonPrimaryColor,
    "&:hover": {
      backgroundColor: Colors.paragonPrimaryColor,
    },
  },
  submitBtnFuel: {
    backgroundColor: Colors.primaryColor,
    color: "#fff",
    padding: 12,
    width: '35%',
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 45,
    marginTop: 40,
    "&:hover": {
      backgroundColor: Colors.primaryColor,
    },
  },
  submitBtnSaveButton: {
    backgroundColor: Colors.primaryColor,
    color: "#fff",
    padding: 12,
    width: '55%',
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 45,
    marginTop: 40,
    "&:hover": {
      backgroundColor: Colors.primaryColor,
    },
  },
  pl0: {
    paddingLeft: 0,
  },
  avatarAirplane: {
    transform: "rotate(45deg)",
    color: "#df2935",
    backgroundColor: "#f4f9fd"
  },
  managerIcon: {
    backgroundColor: "#f4f9fd",
  },
  selectBox: {
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderColor: "#000",
    '&:focus': {
      backgroundColor: "#fff",
    },
    borderRadius: 5
  }
}));
export default commonStyles;
