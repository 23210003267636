import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    position: "absolute",
    background: "#fff",
    width: "60%",
    top: 70,
    zIndex: 99,
    left: "17%",
    maxHeight: "60vh",
    overflowY: "auto",
    borderRadius: 5,
  },
  innerCont: {
    position: "absolute",
    background: "#fff",
    width: "60%",
    top: 70,
    zIndex: 99,
    left: "17%",
    maxHeight: "60vh",
    overflowY: "auto",
    borderRadius: 5,
    border: "1px solid #e4e4e4",
    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
  },
  view: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    alignContent: "center",
    textAlign: "center",
    paddingTop: 3,
    paddingBottom: 3,
    borderBottom: "1.5px solid lightgrey",
    cursor: "pointer",
  },
  imageCont: {
    textAlign: "right",
    marginTop: 5,
    marginBottom: 3,
    borderBottom: "1px solid #e4e4e4",
  },
  imageStyle: {
    width: 20,
    height: 20,
    cursor: "pointer",
    marginRight: 10,
  },
  titleStyle: {
    color: "white",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
  },
  paragonImgStyle: {
    width: 25,
    height: 25,
    marginLeft: 10,
  },
  airportNameCont: {
    display: "flex",
    maxWidth: 300,
    whiteSpace: "nowrap",
  },
  listContainer: {
    borderBottom: "1px solid #eee",
    padding: "10px 0",
  },
  locationContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  detailContainer: {
    marginLeft: 5,
    padding: 7,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  airportName: {
    color: "#000",
    fontSize: 16,
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  iconStyle: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  fboCount: {
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "right",
    color: "#000",
  },
  code: {
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: 0.3,
    color: "#555555",
  },
  rootHead: {
    backgroundColor: (colors) => colors.denim,
  },
  distance: {
    fontSize: 14,
    color: "#555",
    textAlign: "right",
    fontWeight: 400,
  },
  avatar: {
    width: 62,
    height: 62,
    marginLeft: 10,
    backgroundColor: "#f4f9fd",
    color: "#df2935",
  },
  emptyListStyle: {
    width: "100%",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
  }
}));

export { useStyles };