import * as actionTypes from "./pilots.action-types";
import { LOG_OUT } from "../auth/auth.actionTypes";

const initialState = {
  status: "pending",
  pilotList: [],
  assignedAircraftList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // invite pilot
    case actionTypes.INVITE_PILOT.REQUEST:
      return {
        ...state,
        status: "pending",
      };
    case actionTypes.INVITE_PILOT.SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case actionTypes.INVITE_PILOT.FAILURE:
      return {
        ...state,
        status: "pending",
      };

    // pilot list
    case actionTypes.INVITE_PILOTS_LIST.REQUEST:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.INVITE_PILOTS_LIST.SUCCESS:
      return {
        ...state,
        pilotList: action.payload,
        status: "success",
      };
    case actionTypes.INVITE_PILOTS_LIST.FAILURE:
      return {
        ...state,
        pilotList: action.payload,
        status: "pending",
      };

    // assigned aircraft list
    case actionTypes.ASSIGNED_AIRCRAFT_LIST.SUCCESS:
      return {
        ...state,
        status: "success",
        assignedAircraftList: action.payload,
      };

    // assign pilot to aircraft
    case actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.REQUEST:
      return {
        ...state,
        status: "loading",
      };

    case actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.SUCCESS:
      return {
        ...state,
        status: "success",
      };

    case actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.FAILURE:
      return {
        ...state,
        status: "pending",
      };

    // remove pilot from pilot list
    case actionTypes.REMOVE_PILOT_BY_ID.REQUEST:
      return {
        ...state,
        status: "pending",
      };
    case actionTypes.REMOVE_PILOT_BY_ID.SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case actionTypes.REMOVE_PILOT_BY_ID.FAILURE:
      return {
        ...state,
        status: "pending",
      };

    case actionTypes.REMOVE_PROGRESS:
      return {
        ...state,
        status: "pending",
      };

    case LOG_OUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
