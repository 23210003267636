import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import ClearIcon from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  ListSubheader,
  MenuItem,
  Menu,
  Grid,
  TextField,
} from "@material-ui/core";

import colors from "../../../../constants/colors";
import { useStyles } from "../../../../assets/styles/HomeStyles";
import greyPlane from "../../../../assets/images/greyPlane.png";
import { calculateUpdateList } from "../Fuelrequest/FuelRequestHelper";
import { setUserSelectedAircraft } from "../../../../redux/account/account.actions";
import { searchHelper } from "./AircraftSearchHelper";

export const SearchTextField = withStyles({
  root: {
    width: "100%",
    height: 30,
    color: "white",
    background: "#fff",
    "& label.Mui-focused": {
      color: "black",
      border: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
      color: "black",
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        color: "black",
        border: "none",
      },
      "&:hover fieldset": {
        borderColor: "white",
        color: "black",
        border: "none",
      },
      "&.Mui-focused fieldset": {
        height: 30,
        borderColor: "white",
        color: "black",
        border: "0px",
      },
    },
  },
})(TextField);

const StyledMenu = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  paper: {
    border: "1px solid #d3d4d5",
    "&:hover": {
      backgroundColor: "white",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

let arrayList = [];

const AircraftSelection = (props) => {
  const classes = useStyles(colors, isMobile);
  const dispatch = useDispatch();
  const { aircraftSelection } = props;
  const { account } = useSelector((state) => ({ account: state.account }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [aircraftType, setAircraftType] = useState(aircraftSelection ?? "");
  const [aircraftArray, setSectionListData] = useState([]);
  const [searchByAircraftName, setSearchByAircraftName] = useState("");

  useEffect(async () => {
    let registryData = await calculateUpdateList(account, "withoutCustom");
    registryData[1].map((item) => {
      item.data.sort(function (a, b) {
        if (a?.registryName < b?.registryName) {
          return -1;
        }
        if (a?.registryName > b?.registryName) {
          return 1;
        }
        return 0;
      });
    })
    arrayList = registryData[1];
    setSectionListData(registryData[1]);
    setAircraftType(account?.userSelectedAircraft?.registryName || "");
  }, [account.aircraftList, account?.assignedAircraftList, account.tempAircraft, account?.userSelectedAircraft?.registryName]);

  const searchAircrafts = async (search) => {
    setSearchByAircraftName(search);
    let tempList = [];
    if (search !== "") {
      tempList = await searchHelper(search, arrayList);
      setSectionListData(tempList);
    } else {
      setSectionListData(arrayList);
    }
  };

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    if (event === "clear") {
      dispatch(setUserSelectedAircraft({ item: "" }));
      setAircraftType("");
    } else if (value === true) {
      dispatch(setUserSelectedAircraft({ item: event }));
      setAircraftType(event?.registryName);
    } else if (event === "") {
      setAnchorEl(event.currentTarget);
    } else {
      setAircraftType(account?.userSelectedAircraft?.registryName || "");
    }
  };

  const handleAircraftModal = () => {
    setAnchorEl(null);
    setSearchByAircraftName("");
    setAircraftType(
      account?.userSelectedAircraft?.registryName || "Select Aircraft"
    );
  };

  const searchAircraft = () => {
    return (
      <SearchTextField
        fullWidth
        autoFocus={true}
        variant="outlined"
        style={{ color: "grey", paddingLeft: 8 }}
        className={classes.margin}
        InputProps={{
          style: { color: "#000", height: 30 },
          classes: { notchedOutline: classes.notchedOutline },
        }}
        placeholder="Search..."
        onChange={(e) => searchAircrafts(e.target.value)}
        value={searchByAircraftName}
        autoComplete="off"
      />
    )
  }

  return (
    <Grid className={classes.mainCont}>
      <div
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={(e) => handleClick(e, false)}
        label="Select Aircraft"
        className={classes.menuButtonStyle}
      >
        <div className={classes.iconStyle}>
          <img
            src={greyPlane}
            style={{ width: 20, height: 20, marginRight: 10 }}
            alt="plane"
          />
        </div>
        <div
          className={classes.titleStyle}
          style={{
            opacity:
              aircraftType === "Select Aircraft" || aircraftType === ""
                ? 0.5
                : 1,
          }}
        >
          {aircraftType !== ""
            ? aircraftType === "Select Aircraft"
              ? aircraftType
              : aircraftType.toUpperCase()
            : "Select Aircraft"}
        </div>
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        disableRipple
        open={Boolean(anchorEl)}
        onClose={handleAircraftModal}
      >
         {searchAircraft()}       
        {aircraftArray.length > 0 ? (
          <div>
            {aircraftArray.map((aircraftType, index) => {
              return (
                <div>
                  <ListSubheader key={index} classes={{ gutters: classes.gutters }} className={classes.aircraftTitleStyle}>
                    <div className={classes.aircraftTitle}>{aircraftType.title}</div>
                  </ListSubheader>
                  {aircraftType.data.map((aircraft, index) => {
                    return (
                      <div
                        className={classes.menuItemCont}
                        style={{
                          background:
                            account?.userSelectedAircraft?._id === aircraft?._id
                              ? colors.primaryColor
                              : "transparent",
                        }}
                      >
                        <MenuItem
                          key={index}
                          value={aircraft}
                          className={classes.aircraftNameStyle}
                          name={aircraft.name}
                          style={{
                            color:
                              account?.userSelectedAircraft?._id ===
                                aircraft?._id
                                ? "white"
                                : null,
                          }}
                          onClick={() => handleClick(aircraft, true)}
                        >
                          {aircraft?.registryName.toUpperCase()} (
                          {aircraft?.makerName} {aircraft.name})
                        </MenuItem>
                        <div className={classes.iconContStyle}>
                          {account?.userSelectedAircraft?._id ===
                            aircraft?._id ? (
                            <ClearIcon
                              className={classes.closeIconStyle}
                              onClick={() => handleClick("clear")}
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.noResultTextStyle}>No result</div>
        )}
      </StyledMenu>
    </Grid>
  );
};

export default AircraftSelection;