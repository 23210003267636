export const getSearchList = async (searchValue, sectionListData) => {
  return searchResults(searchValue, sectionListData);
};

const searchResults = (searchValue, sectionListData) => {
  try {
    let finalDetails = [];
    let data = {};
    if (sectionListData && sectionListData.length && sectionListData[0]) {
      sectionListData.filter((e) => {
        const listResut = e.data
          .filter((item) =>
            item.registryName
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) ||
            item.name
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) ||
            item.baseLocation
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) ||
            item.name
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase())
          )
          .map((item) => {
            return {
              ...item,
              key: e._id,
            };
          });
        if (listResut.length > 0) {
          data = {
            title: e.title,
            data: listResut,
          };
          finalDetails.push(data);
        }
      });
    }
    return finalDetails;
  } catch (error) {}
};
