import React, { useState } from "react";
import NotificationData from "../../components/scene/items/NotificationData";
import { getNotifications, getUnreadNotificationCount } from "../../redux/notifications/notifications.actions";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import commonStyles from "../../constants/common";
import textStyles from "../../constants/fonts";
import { Button } from "@material-ui/core";
import * as services from "../../services/mainService";

import AppLayout from "../Layout/AppLayout";
import { StyledContainer, StyledGridMainContainer } from '../History';
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  notificationView: {
    position: "absolute",
    top: 0,
    textAlign: "center",
    padding: 30,
    zIndex: 1000,
    left: 0,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 240px)`,
      left: "240px",
    },
  },
  heading: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  deleteAllTextStyle: {
    width: 161,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    justifyContent: 'center',
    borderRadius: 5,
    background: colors.primaryColor,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  disableDeleteAll: {
    width: 161,
    height: 44,
    display: 'flex',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    background: colors.primaryColor,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  deleteTextStyle: {
    color: '#666'
  }
}));

export default function Notifications({ closeNotification }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const commonClasses = commonStyles();
  const textClasses = textStyles();

  const [userNotifications, setUserNotifications] = useState([]);

  const { auth, notifications } = useSelector((state) => ({
    auth: state.auth,
    notifications: state.notifications,
  }));

  React.useEffect(() => {
    setUserNotifications([]);
    dispatch(getNotifications({ token: auth.token, record: {page: 1, rowsNumber: 20} }));
  }, [auth.token]);

  React.useEffect(() => { }, [notifications.notifications, auth.token]);

  React.useEffect(() => {
    setUserNotifications(notifications?.notifications);
  }, [notifications?.notifications, userNotifications]);

  const clearNotification = async () => {
    if (userNotifications.length > 0) {
      const res = await services.clearNotificationApi({
        token: auth.token,
      });
      if (res.response_type === "success") {
        dispatch(getNotifications({ token: auth.token, record: {page: 1, rowsNumber: 20} }));
        dispatch(getUnreadNotificationCount({ token: auth.token }));
      }
    }
  };

  const Appbar = () => {
    return (
      <AppBar position="static" className={commonClasses.pageHeader}>
        <Toolbar className={classes.heading}>
          <Typography className={textClasses.pageHeading}>
            Inbox
          </Typography>
          <Button
            disabled={userNotifications.length > 0 ? false : true}
            className={userNotifications.length > 0 ? classes.deleteAllTextStyle : classes.disableDeleteAll}
            onClick={() => clearNotification()}
            style={{ cursor: userNotifications.length > 0 ? "pointer" : 'dafault' }}>
            Delete All
          </Button>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <AppLayout>
        <StyledGridMainContainer
          container={true}
          spacing={0}
          className="mainContainer"
        >
          <Grid item={true} xs={12} className="contentContainer">
            <Appbar />
            <NotificationData userNotifications={userNotifications} />
          </Grid>
        </StyledGridMainContainer>
      </AppLayout>
    </StyledContainer>
  );
}
