import React, { useState, useEffect } from "react";
import { useForm } from "../../ui/useForm";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "../../controls/Input";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import BaseLocation from "./BaseLocation";
import axios from "axios";
import { getUnreadNotificationCount } from "../../../redux/notifications/notifications.actions";

import {
  addAircraft,
  editAircraft,
  addCustomAircraft,
  assignPILOTS,
} from "../../../redux/account/account.actions";
import commonStyles from "../../../constants/common";
import { useStyles } from "../../../assets/styles/AddAircraftFormStyles";
import CustomAircraft from "./CustomAircraft";
import NozlPilotSelection from "../../ui/NozlPilotSelection";
import { prettyString } from "../../ui/TextTransform";

const initialFValues = {
  id: 0,
  fullName: "",
  email: "",
  mobile: "",
  city: "",
  gender: "male",
  departmentId: "",
  hireDate: new Date(),
  isPermanent: false,
};

export const ValidationTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "black",
      borderRadius: 5,
      textTransform: "capitalize",
    },
    "& label.Mui-focused": {
      color: "black",
      textTransform: "capitalize",
    },
    "& label.MuiFormLabel-filled":{
      color: "black"
    },
    "& .MuiInput-underline:after": {
      border: "1px solid black !important",
      color: "black",
      textTransform: "capitalize",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        color: "black",
        textTransform: "capitalize",
      },
      "&:hover fieldset": {
        textTransform: "capitalize",
        color: "black",
        border: "1px solid black !important",
      },
      "&.Mui-focused fieldset": {
        textTransform: "capitalize",
        color: "black",
        border: "1px solid black !important",
      },
      color: "black",
      textTransform: "capitalize",
    },
    "& ::placeholder": {
      textTransform: "capitalize",
      color: "black",
    },
  },
})(TextField);

export default function AddAircraftForm(props) {
  const {
    recordForEdit,
    setOpenPopup: setOpenAddPopup,
    setOpenEditPopup,
    editObj = {},
    isEdit = false,
    operatorName = "",
  } = props;

  const classes = useStyles();
  const commomnClasses = commonStyles();
  const dispatch = useDispatch();

  const { auth, account, pilots } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
    pilots: state.pilots,
  }));

  const [pilotList, setPilotList] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [aircraftType, setAircraftType] = useState("");
  const [customAircraft, setCustomAircraft] = useState("");
  const [, setCustomAircraftId] = useState("");
  const [registryValue, setRegistryValue] = useState("");
  const [operatorValue, setOperatorValue] = useState("");
  const [invoiceEmailValue, setInvoiceEmailValue] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [aircraftName, setAircraftName] = useState("");
  const [filterAirPlanesName, setFilterAirPlanesName] = useState([]);
  const [filterAirplanesId, setFilterAirplanesId] = useState([]);
  const [isCustomAircraft, setIsCustomAircraft] = useState(false);
  const [baseLocation, setBaseLocation] = useState("");
  const [addLat, setAddLat] = useState("");
  const [addLong, setAddLong] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [otherMaker, setOtherMaker] = useState(false);
  const [otherMakerName, setOtherMakerName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedPilot, setSelectedPilot] = useState([]);
  const [manufacturerName, setManufacturerName] = useState("");
  const [name, setName] = useState(isEdit === false ? "" : editObj?.name);
  const [selectedPilots, setselectedPilots] = useState([]);
  const [selectedPilotValue, setSelectedPilotValue] = useState(false);

  useEffect(() => {}, [
    props.searchResult,
    selectedPilot,
    account?.assignedPilotListForAircraft,
  ]);
  useEffect(() => {
    const assignedPilotListData = account?.assignedPilotListForAircraft || [];
    const tempData = pilots?.pilotList;
    tempData.map((item) => {
      const filterData = assignedPilotListData.filter(function (assignedItem) {
        return (
          assignedItem?.pilotDetail?.userId === item.pilotUserId ||
          assignedItem?.pilotUserId === item.pilotUserId
        );
      });
      if (filterData === undefined || filterData.length === 0) {
        item.isSelect = false;
      } else {
        item.isSelect = true;
      }
      return item;
    });
    setPilotList(tempData);
    let pilot = [];
    tempData.filter((item) => {
      if (item.isSelect === true) {
        pilot.push(item);
        setSelectedPilot(pilot);
      }
    });
    if (isEdit === true) {
      setAircraftType({
        value: editObj?.airplaneId,
        name: editObj?.name,
        children: editObj?.name,
      });
      setManufacturerName(editObj.airplaneDetail.airplaneMakerDetail.name);
      setOperatorValue(editObj?.operatorNameValue);
      setRegistryValue(editObj?.registryName);
      setInvoiceEmailValue(editObj?.invoiceEmail);
      setBaseLocation(
        editObj?.baseLocation === undefined ? "" : editObj?.baseLocation
      );
      setCity(editObj?.city);
      setState(editObj?.state);
      setCountry(editObj?.country);
    }
  }, [
    pilotList,
    account?.assignedPilotListForAircraft,
    isEdit,
    pilots?.pilotList,
    account?.assignedPilotListForAircraft,
  ]);

  useEffect(() => {}, [selectedPilot]);

  const validateForm = () => {
    if (aircraftType.name !== "Other" && aircraftType.name !== undefined) {
      return (
        aircraftType?.name !== (null || undefined) &&
        aircraftType?.name !== "" &&
        registryValue !== "" &&
        registryValue?.length >= 2
      );
    } else if (otherMaker === true) {
      return (
        otherMakerName !== null &&
        otherMakerName !== "" &&
        registryValue !== "" &&
        registryValue?.length >= 2 &&
        fuelType !== "" &&
        aircraftName !== ""
      );
    } else {
      return (
        aircraftType?.name !== (null || undefined) &&
        aircraftType?.name !== "" &&
        registryValue !== "" &&
        registryValue?.length >= 2 &&
        customAircraft !== "" &&
        customAircraft !== null &&
        fuelType !== "" &&
        aircraftName !== ""
      );
    }
  };

  useEffect(() => {
    if (auth?.userType !== 4) {
      setOperatorValue(operatorName);
    }
  }, [pilotList, auth?.userType, operatorName]);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("departmentId" in fieldValues)
      temp.departmentId =
        fieldValues.departmentId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, setValues]);

  const sumbmitPilotsAssign = () => {
    if (isEdit) {
      setOpenEditPopup(false);
    } else {
      setOpenAddPopup(false);
    }
    onAircraftFormSubmit();
  };

  const filterAirplaneData = (item) => {
    const makerId = item?._id;
    let tempPlanesName = [];
    let temPlanesId = [];
    account?.airplaneList !== undefined &&
      account?.airplaneList?.map((item, index) => {
        if (item.airplaneMakerId === makerId) {
          tempPlanesName.push(item.name);
          temPlanesId.push(item._id);
        }
      });
    setFilterAirPlanesName(tempPlanesName);
    if (
      aircraftType?.name !== null &&
      aircraftType?.name !== undefined &&
      Object.values(aircraftType).length > 0
    ) {
      tempPlanesName.map((aircraftType) => {
        if (item === aircraftType?.name) {
          setName(aircraftType?.name);
        }
      });
    } else {
      setName("");
    }
    setFilterAirplanesId(temPlanesId);
  };

  const onModalTypeChange = (obj) => {
    if (obj !== null && obj !== "" && obj !== undefined) {
      if (obj.name === "Other") {
        setOtherMaker(true);
        setIsCustomAircraft(true);
      } else {
        setCustomAircraft(obj);
        setCustomAircraftId(obj._id);
        filterAirplaneData(obj);
        setOtherMaker(false);
        setAircraftType("");
      }
    } else {
      setFilterAirPlanesName([]);
      setFilterAirplanesId([]);
      setOtherMaker(false);
    }
  };

  const aircarftTypChange = (obj) => {
    setAircraftType(obj.props);
    setName(obj.props.name);
    setIsCustomAircraft(obj.props.name === "Other" ? true : false);
  };

  const onAircraftFormSubmit = () => {
    setLoading(true);
    if (auth?.userType !== 4 && pilotList.length > 0 && isEdit) {
      let selectedPilotId = selectedPilot.map(i => i.pilotUserId)
      if (selectedPilots.length === 0 && selectedPilotValue === false){
        dispatch(assignPILOTS(auth?.token, selectedPilotId, editObj?._id));
      } else {
        dispatch(assignPILOTS(auth?.token, selectedPilots, editObj?._id));
      }
    }
    if (aircraftType.value === "Other" || otherMaker === true) {
      const obj = {
        name: aircraftName,
        fuelType: fuelType.value,
        registryName: registryValue,
        formattedAddress: formattedAddress,
        operatorName: auth?.userType === 4 ? operatorValue : auth?.userType === 2 ? account?.user?.name : account?.user?.operatorName,
        invoiceEmail: invoiceEmailValue,
        baseLocation: baseLocation,
        addLat: addLat,
        addLng: addLong,
        city: city,
        state: state,
        country: country,
        isOtherMaker: otherMaker,
      };
      if (otherMaker === true) {
        obj.otherMakerName = otherMakerName;
      } else {
        obj.airplaneMakerId = customAircraft._id;
      }
      const aircraftObj = {
        aircraft: obj,
        token: auth.token,
      };
      dispatch(addCustomAircraft(aircraftObj));
      setTimeout(() =>  {
        dispatch(
        getUnreadNotificationCount({
          token: auth.token,
        })
      )}, 400)
      if (isEdit) {
        setOpenEditPopup(false, true);
      } else {
        setOpenAddPopup(false);
      }
    } else if (isEdit) {
      const airplaneIndex = filterAirPlanesName.indexOf(aircraftType.value);
      const airplaneId = filterAirplanesId[airplaneIndex];
      const objUpdate = {
        _id: editObj?._id,
        airplaneId: airplaneId !== undefined ? airplaneId : aircraftType.value,
        invoiceEmail: invoiceEmailValue,
        registryName: registryValue.trim(),
        formattedAddress: formattedAddress,
        operatorName: auth?.userType === 4 ? operatorValue : auth?.userType === 2 ? account?.user?.name : account?.user?.operatorName,
        baseLocation: baseLocation,
        addLat: addLat,
        addLng: addLong,
        city: city,
        state: state,
        country: country,
      };
      const editAircraftObj = {
        aircraft: objUpdate,
        token: auth.token,
      };
      dispatch(editAircraft(editAircraftObj));
      setOpenEditPopup(
        false,
        airplaneId === undefined || otherMaker === false ? false : true
      );
    } else {
      const airplaneIndex = filterAirPlanesName.indexOf(aircraftType.value);
      const airplaneId = filterAirplanesId[airplaneIndex];
      const obj = {
        airplaneId: airplaneId,
        invoiceEmail: invoiceEmailValue,
        registryName: registryValue.trim(),
        operatorName: auth?.userType === 4 ? operatorValue : auth?.userType === 2 ? account?.user?.name : account?.user?.operatorName,
        baseLocation: baseLocation,
        formattedAddress: formattedAddress,
        addLat: addLat,
        addLng: addLong,
        city: city,
        state: state,
        country: country,
      };
      const addAircraftObj = {
        aircraft: obj,
        token: auth.token,
      };
      dispatch(addAircraft(addAircraftObj));
      setOpenAddPopup(false);
    }
  };

  const getAirportAddress = (address) => {
    if (address !== null) {
      setBaseLocation(address.icao);
      setAddLat(address.latitude);
      setAddLong(address.longitude);
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            address.latitude +
            "," +
            address.longitude +
            "&key=AIzaSyClgWmN0aJIplOG6yEL9g1sjxVPq8IytQo"
        )
        .then((response) => {
          const res = response.data.results;
          setFormattedAddress(res[0].formatted_address);
          let data = res[res.length - 4].address_components;
          setCity(data[0].long_name);
          setState(data[1].long_name);
          setCountry(data[2].long_name);
        })
        .catch(function (error) {});
    } else {
      setBaseLocation("");
    }
  };

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  };

  return (
    <form autoComplete="off" className={classes.form} noValidate>
      <div className={classes.container}>
        <TextField
          value={registryValue.toUpperCase()}
          onChange={(e) => {
            if (e.target.value.match("^[a-zA-Z 0-9-]*$") != null) {
              setRegistryValue(e.target.value.toUpperCase());
            }
          }}
          theme={"black"}
          required
          inputProps={{ maxLength: 7, minLength: 7 }}
          label="Aircraft Tail"
          placeholder="Aircraft Tail"
        />
        {editObj.bookingsCount === 0 || isEdit === false ? (
          <div>
            <CustomAircraft
              modalType={onModalTypeChange}
              manufactureName={isEdit === false ? "" : manufacturerName}
            />
            <br />
          </div>
        ) : null}
        {editObj.bookingsCount === 0 || isEdit === false ? (
          <div>
            {otherMaker === true ? (
              <div style={{ height: 56 }}>
                <TextField
                  theme={"black"}
                  required
                  label="Manufacturer Name"
                  value={otherMakerName}
                  onChange={(e) => {
                    if (e.target.value.match("^[a-zA-Z 0-9'&]*$") != null) {
                      setOtherMakerName(prettyString(e.target.value));
                    }
                  }}
                ></TextField>
              </div>
            ) : (
              <FormControl
                variant="filled"
                required
                fullWidth
                style={{ borderRadius: 5 }}
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={classes.inputLabel}
                >
                  Select Model
                </InputLabel>
                <Select
                  disableUnderline
                  inputProps={{ style: { color: "#000", background: "white" } }}
                  defaultValue={
                    aircraftType?.name !== undefined ? name.toUpperCase() : name
                  }
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={
                    aircraftType?.name !== undefined
                      ? name.toUpperCase()
                      : aircraftType?.name
                  }
                  MenuProps={menuProps}
                  onChange={(data, obj) => {
                    aircarftTypChange(obj);
                  }}
                  className={commomnClasses.selectBox}
                >
                  {filterAirPlanesName.map((item, index) => {
                    return (
                      <MenuItem key={index} name={`${item}`} value={`${item}`}>
                        {item.toUpperCase()}
                      </MenuItem>
                    );
                  })}
                  <MenuItem name="Other" value="Other">
                    Other
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
        ) : (
          <div>
            <div style={{ background: "#ddd", height: 56 }}>
              <TextField
                theme={"black"}
                required
                disabled
                label="Model"
                value={aircraftType.name}
              ></TextField>
            </div>
          </div>
        )}
        <br />

        {isCustomAircraft === true ? (
          <TextField
            value={aircraftName}
            required
            onChange={(e) => {
              if (e.target.value.match("^[a-zA-Z 0-9'&]*$") != null) {
                setAircraftName(prettyString(e.target.value));
              }
            }}
            theme={"black"}
            label="Aircraft Name"
            placeholder="Aircraft name"
          />
        ) : null}
        {isCustomAircraft === true ? (
          <div>
            <FormControl variant="filled" required fullWidth>
              <InputLabel
                id="demo-simple-select"
                className={classes.inputLabel}
              >
                Fuel Type
              </InputLabel>
              <Select
                disableUnderline
                MenuProps={menuProps}
                labelId="demo-simple-select"
                id="demo-simple-select"
                value={fuelType.name}
                onChange={(data, obj) => {
                  setFuelType(obj.props);
                }}
                className={commomnClasses.selectBox}
              >
                <MenuItem name="Jet A" value="1">
                  Jet A
                </MenuItem>
                <MenuItem name="100 LL" value="2">
                  100 LL
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        ) : null}

        {auth?.userType === 4 ? (
          <TextField
            onChange={(e) => setOperatorValue(prettyString(e.target.value))}
            value={operatorValue}
            placeholder="Flight Department (Optional)"
            name="operator"
            label="Flight Department"
            autoComplete="off"
            theme={"black"}
          />
        ): null}

        <div
          style={{
            width: "100%",
            marginBottom: 10,
            marginTop: -10,
            fontSize: 14,
            color: "grey",
          }}
        >
          Based ICAO (Optional)
        </div>
        <div style={{ width: "100%" }}>
          <BaseLocation
            icaoCode={baseLocation}
            searchResult={props.searchResult}
            address={getAirportAddress}
          />
        </div>
      </div>
      <div>
        {auth?.userType !== 4 && pilotList.length > 0 && isEdit ? (
          <div style={{ marginTop: -20 }}>
            <NozlPilotSelection
              data={pilotList}
              navigation="fromAircraft"
              className={classes.dateArea}
              onChange={(data, pilotsList, value) => {
                setselectedPilots(pilotsList);
                setSelectedPilotValue(value);
              }}
              selectedPilot={selectedPilot}
              editObj={editObj}
            />
            <div style={{ height: 10 }}></div>
          </div>
        ) : null}
        <Button
          disabled={!validateForm() || loading === true}
          onClick={sumbmitPilotsAssign}
          fullWidth
          className={classes.submitBtn}
        >
          Save
        </Button>
      </div>
    </form>
  );
}