import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ component, path }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      render={(props) =>
        auth.token !== null && auth.token !== '' ? (
          <Route exact path={path} component={component} />
        ) : (
            <Redirect to="/login" />
          )
      }
    />
  );
};

export default AuthRoute;