export default {
   HIDE_PROGRESS : 'HIDE_PROGRESS',
   SUCCESS: 'success',
   ERROR: 'error',
   WARNING: 'warning',
   INFO: 'info',
   requestSent: "Your Request Has Been Sent!",
   requestSent1: "You'll be notified once the FBO has responded. You can monitor the request in History. You can expect an email with your receipt.",
   profileSetUpText: "Enter your personal details to create a profile. You can add additional users to the same account in the next step. Each user will have unique login credentials.",
   userSetUpText:" These are the users that can login into this account. Additional users can be added here. These users will receive emails pertaining to arrival and fuel reservations.",
   aircraftHeadingDetailtext: "Create at least one aircraft profile to finish the account setup. You can add all of your aircraft now or complete this at a later time under the Manage Aircraft tab. (Fees are auto-calculated based on aircraft type.)",
   verifyDetailTextStyle: "Please confirm that all of your information is correct. Once you submit, your account will be sent to our Customer Relations team for verification."
  };
  
