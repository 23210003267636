import { makeStyles, Slider, withStyles } from "@material-ui/core";
import colors from "../../constants/colors";

const useStyles = makeStyles(() => ({
  root: {
    "&>.MuiSlider-thumb::after": {
      boxShadow: "-0.8em -1.4em 0 -0.5em transparent",
      "&:nth-child(4)": {
        color: "#df2935 !important",
      },
      "&:nth-child(5)": {
        color: "#df2935 !important",
      },
    },
  },
  distanceCont: {
    marginTop: 30,
  },
  clearButton: {
    margin: 10,
    height: 50,
    width: 150,
    textAlign: "center",
    boxShadow: "0 3px 10px rgb(0 0 0/0.2)",
  },
  applyButton: {
    border: `2px solid ${colors.primaryColor}`,
    backgroundColor: colors.primaryColor,
    color: "#fff",
    margin: 10,
    height: 50,
    width: 150,
    textAlign: "center",
    "&:hover": { backgroundColor: colors.primaryColor },
    boxShadow: `0 2px 5px ${colors.primaryColor}`,
  },
  textStyle: {
    color: "#848484",
    marginTop: 10,
    marginBottom: 10,
  },
  filterTextStyle: {
    opacity: 0.5,
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: 400,
  },
  filterButton: {
    height: 55,
    width: "100%",
    border: "1px solid #E4E2E2",
    color: "black",
    borderRadius: 5,
    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
  },
  buttonCont: {
    height: "100%",
    display: "flex",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: "center",
  },
  appliedFilterCont: {
    background: "red",
    width: 7,
    height: 7,
    borderRadius: 7 / 2,
    marginTop: -15,
    marginLeft: 3,
  },
  imgCont: {
    width: 15, height: 18, marginLeft: 20
  },
  valueCont: {
    width: 20,
    height: 20,
    border: "2px solid #555",
    cursor: "pointer",
    borderRadius: 3,
  },
  valCont: {
    display: "flex", padding: 10
  },
  valueTextStyle: {
    marginLeft: 10, fontSize: 14
  },
  textAlignStyle: {
    textAlign: "center"
  },
  popperCont: {
    marginTop: 3,
    zIndex: 99,
    width: 450,
    borderRadius: 5,
    marginRight: 20,
    padding: 10,
    background: "#fff",
    border: "1px solid #e4e4e4",
    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
  }
}));

export { useStyles };

export const PrettoSlider = withStyles({
  root: {
    color: "#df2935",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#f4f4f4",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    color: "#df2935",
  },
  rail: {
    height: 8,
    color: "#555",
    opacity: 0.5,
    borderRadius: 4,
  },
})(Slider);