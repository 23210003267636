import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Typography, AppBar, Toolbar, Grid } from "@material-ui/core";
import { IconWrapper } from '../../containers/BookingDetail';
import commonStyles from "../../constants/common";
import fontStyles from "../../constants/fonts";
import { StyledGridMainContainer } from '../../containers/History';

const NozlHeadings = (props) => {
  const commonClasses = commonStyles();
  const fontClasses = fontStyles();

  return (
    <StyledGridMainContainer
      container={true}
      spacing={0}
      className="mainContainer"
    >
      <Grid item={true} xs={12} className="contentContainer">
        <AppBar position="static" className={commonClasses.pageHeader}>
          <Toolbar>
            {props.goBack === true &&
              <IconWrapper onClick={() => props.onClickBack(true)}>
                <Icon icon={faArrowLeft} color="black" style={{ cursor: 'pointer' }} />
              </IconWrapper>}
            <Typography className={fontClasses.pageHeading}>{props.title}</Typography>
          </Toolbar>
        </AppBar>
      </Grid>
    </StyledGridMainContainer>
  );
};

export default NozlHeadings;