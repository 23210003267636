import React from "react";

import { Grid } from "@material-ui/core";
import youtubeIcon from "../../../assets/images/youTubeIcon.png";
import instaIcon from "../../../assets/images/instaIcon.png";
import linkedin from "../../../assets/images/linkedin.png";
import submitSignup from "../../../assets/images/submitSignup.png";
import { useStyles } from "../../../assets/styles/SubmitSignupStyles";
import { useSelector } from "react-redux";

const SubmitSignUp = (props) => {
  const classes = useStyles();
  const { clickOkButton = {} } = props;
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  return (
    <Grid container className={classes.firstCont}>
      <Grid container style={{ width: "100%" }}>
        <Grid item xs={12} className={classes.firstInnerCont}>
          <img alt="" src={submitSignup} className={classes.submitImageStyle} />
          <Grid className={classes.thankyouStyle}>Thank You!</Grid>
          <Grid className={classes.textStyle}>
            Your account has been submitted for approval, we will notify you
            once it is confirmed.
          </Grid>
        </Grid>
        {!auth.isIframe ? (
          <Grid item xs={12} className={classes.secondInnerCont}>
            <Grid className={classes.textStyle2}>
              In the meantime, check us out on social media:
            </Grid>

            <Grid className={classes.imageCont}>
              <img alt=""
                src={linkedin}
                onClick={() => {
                  var win = window.open(
                    process.env.REACT_APP_LINKEDIN,
                    "_blank"
                  );
                  win.focus();
                }}
                className={classes.imageStyle}
              />
              <img
                src={youtubeIcon}
                className={classes.youTubeStyle}
                onClick={() => {
                  var win = window.open(
                    process.env.REACT_APP_YOUTUBE,
                    "_blank"
                  );
                  win.focus();
                }}
                alt=""
              />
              <img
                src={instaIcon}
                onClick={() => {
                  var win = window.open(
                    process.env.REACT_APP_INSTAGRAM,
                    "_blank"
                  );
                  win.focus();
                }}
                className={classes.imageInstaStyle}
                alt=""
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            className={classes.okButtonStyle}
            onClick={clickOkButton}
          >
            OK
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SubmitSignUp;
