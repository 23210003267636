import moment from "moment";
import moment_timezone from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForceUpdate } from "../../../customHooks/useForceUpdate";
import {
  clearNotificationDetailResponse,
  getNotificationDetail,
  getNotifications,
  markAsReadNotification,
  clearNotificationReadResponse,
} from "../../../redux/notifications/notifications.actions";
import NotificationItem from "./NotificationItem";
import TablePaginationActions from "../../controls/Pagination";
import TablePagination from "@material-ui/core/TablePagination";
import * as services from "../../../services/mainService";
import { makeStyles } from "@material-ui/core";
import colors from "../../../constants/colors";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  paginationStyle: {
    display: "flex",
    justifyContent: "end",
    borderTop: "1px solid #ddd",
  },
  noDataFound: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    height: "calc(100vh - 150px)",
    color: colors.lightGreyBlue,
  },
}));

let rowsPerPage = 20;
export default function NotificationData({ userNotifications }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const { notifications, auth } = useSelector((state) => ({
    notifications: state.notifications,
    auth: state.auth,
    fbos: state.fbos,
  }));

  const [localNotifications, setLocalNotifications] = useState([]);

  useEffect(() => {
    setLocalNotifications(
      userNotifications !== undefined ? userNotifications : []
    );
  }, [auth.token]);

  useEffect(() => {}, [notifications?.notifications, localNotifications]);

  const getSortedNotification = (notificationList) => {
    let todayDate = moment(new Date());
    let yesterdayDate = moment().add(-1, "days");
    let otherNotifications = [];
    let tempNotifications = [];
    let todayNotifiations = [];
    let yesterdayNotifications = [];
    notificationList.map((noti) => {
      if (
        todayDate.format("DD/MM/YYYY") ===
        moment(noti.createdAt).format("DD/MM/YYYY")
      ) {
        todayNotifiations.push(noti);
      } else if (
        yesterdayDate.format("DD/MM/YYYY") ===
        moment(noti.createdAt).format("DD/MM/YYYY")
      ) {
        yesterdayNotifications.push(noti);
      } else {
        otherNotifications.push(noti);
      }
    });
    if (todayNotifiations.length) {
      tempNotifications.push({
        title: "Today",
        data: [...todayNotifiations],
      });
    }
    if (yesterdayNotifications.length) {
      tempNotifications.push({
        title: "Yesterday",
        data: [...yesterdayNotifications],
      });
    }
    if (otherNotifications.length) {
      tempNotifications.push({
        title: "Older",
        data: [...otherNotifications],
      });
    }

    return tempNotifications;
  };

  useEffect(() => {
    if (Object.values(notifications.notificationReadResponse).length > 0) {
      dispatch(clearNotificationReadResponse());
      dispatch(
        getNotifications({
          token: auth.token,
          record: { page: pageCount, rowsNumber: rowsPerPage },
        })
      );
      const noti = getSortedNotification(notifications?.notifications);
      setLocalNotifications(noti);
    }
    if (Object.values(notifications?.notificationDetail)?.length > 0) {
      moveToFinalAmountScreen();
    }
  }, [
    notifications?.notificationDetail,
    notifications?.notifications,
    localNotifications,
  ]);

  const moveToFinalAmountScreen = async () => {
    const item = notifications.notificationDetail;
    const isRampFee = item.isRampFee;
    let status = "pending";
    if (item.status === 2) {
      status = "acknowledged";
    } else if (item.status === 3) {
      status = "completed";
    } else if (item.status === 4) {
      status = "cancelled";
    } else if (item.status === 5) {
      status = "expired";
    }
    const transaction = {
      bookingId: item?.bookingId,
      fboId: item?.fboId,
      fbo: item?.fboName,
      airport: item.aircraftDetail?.airplaneDetail?.name ?? "",
      airport: item?.aircraftRegistryName ?? "",
      date: item.operatorDateOfUplift ? moment(item.operatorDateOfUplift).utc().format("MMM/DD/YYYY HH:mm") : moment(item.dateOfUplift).tz(item.operatorTimeZone).format("MMM/DD/YYYY HH:mm") + ' (' + (moment_timezone().tz(item.operatorTimeZone).zoneAbbr()) + ')',
      status: status,
      aircraft: item?.aircraftDetail?.airplaneDetail?.name ?? "",
      registry: item?.aircraftRegistryName ?? "",
      fuelCost: item.fuelCost.toString(),
      oldFuelCost: item.actualFuelCost.toString(),
      rampFee: item.rampFee.toString(),
      volume: item.volume.toString(),
      rate: item.rate.toString(),
      oldRate: item.actualRate.toString(),
      discountAmount: item.discountAmount || 0,
      isDiscount: item.isDiscount,
      finalAmount: item.actualFuelCost || 0,
      isRated: item.isRated || false,
      rating: item.rating || 0,
      isRampFee,
      rateType: item.rateType,
      isPriceChange: item.isPriceChange || false,
      isPristFee: item?.isPristFee || false,
      pristFee: item?.pristFee || 0,
      requestType: item?.requestType,
      pilotsDetail: item?.pilotsDetail || [],
      ancillaryServices: item.ancillaryServices || [],
      eta: item.operatorDateOfUplift ? moment(item.operatorDateOfUplift).utc().format("MMM/DD/YYYY HH:mm") : moment(item.dateOfUplift).tz(item.operatorTimeZone).format("MMM/DD/YYYY HH:mm") || "NA",
      etd: item.operatorEtd ? moment(item.operatorEtd).utc().format("MMM/DD/YYYY HH:mm") : moment(item.etd).tz(item.operatorTimeZone).format("MMM/DD/YYYY HH:mm") + ' (' + (moment_timezone().tz(item.operatorTimeZone).zoneAbbr()) + ')' || "NA",
      flyingFrom: item.flyingFrom || "",
      flyingTo: item.flyingTo || "",
      comments: item?.message,
      isServiceRequest: item?.isServiceRequest,
      internationalFeeAndTaxes: item?.internationalFeeAndTaxes,
      isFBOInternationalServices: item?.isFBOInternationalServices,
      internationalServicesCost: item?.internationalServicesCost,
      pristFeeAmount: item?.pristFeeAmount,
      actualRate: item?.actualRate
    };

    const bookingDetails = await services.getNotificationDetail({
      notification: {
        bookingId: item.bookingId,
      },
      token: auth.token,
    });
    if (bookingDetails.response_type === "success") {
      transaction.isParagonUser = bookingDetails.response.data?.isParagonUser;
      transaction.memberSince =
        bookingDetails.response.data?.bookingCreatorRegDate;
        history.push({
          pathname: "/notification-booking-detail",
          state: {
            param: {
              fbo: transaction,
            },
          },
        });
        dispatch(clearNotificationDetailResponse());
    }
  };

  const onItemPress = (item, index) => {
    const valueOFRead = item.isRead || false;
    if (!valueOFRead) {
      dispatch(
        markAsReadNotification({
          notification: { notificationIds: [item._id] },
          token: auth.token,
        })
      );
      let temp = notifications?.notifications;
      temp[index].isRead = !temp[index]?.isRead || true;
      getSortedNotification(temp);
      forceUpdate();
    }
    if (item.type === 3) {
      getNudgeDetail(item);
    } else if (item.type === 4) {
      history.push({
        pathname: "./aircraft-list",
        state: {
          param: {
            fromSetting: true,
          },
        },
      });
    } else {
      dispatch(
        getNotificationDetail({
          notification: { bookingId: item.data.id },
          token: auth.token,
        })
      );
    }
  };

  const getNudgeDetail = async (item) => {
    const res = await services.getNudgeDetailApi({
      nudgeId: item.data.id,
      token: auth.token,
    });
    if (res.response_type === "success") {
      history.push({
        pathname: "/nudge-detail",
        state: {
          param: {
            item: res.response.data,
            title: item.data.fboName,
          },
        },
      });
    } else {
      toast.info(res.response.message, {position: "bottom-center", closeButton: false});
    }
  };

  const handleChangePage = (event, newPage, pageCount) => {
    setPage(newPage);
    setPageCount(pageCount);
    dispatch(
      getNotifications({
        token: auth.token,
        record: { page: pageCount, rowsNumber: rowsPerPage },
      })
    );
  };

  useEffect(() => {
    const noti = getSortedNotification(notifications?.notifications);
    setLocalNotifications(noti);
  }, [notifications?.notifications]);

  return localNotifications.length > 0 ? (
    <div>
      <NotificationItem
        key={"NotificationItem"}
        onItemPress={onItemPress}
        notifications={localNotifications}
      />
      <div className={classes.paginationStyle}>
        <TablePagination
          rowsPerPageOptions={[]}
          colSpan={3}
          count={notifications?.notificationsMetaData[0]?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          ActionsComponent={() => (
            <TablePaginationActions
              notificationLength={
                notifications?.notificationsMetaData[0]?.total
              }
              page={page}
              pageCount={pageCount}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          )}
        />
      </div>
    </div>
  ) : (
    <div>
      <h4 className={classes.noDataFound}>
        You have no new notifications at this time.
      </h4>
    </div>
  );
}
