import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
    height: 56,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
  },
  notchedOutline: {
    borderWidth: "1px",
    padding: 5,
    borderColor: "black !important",
    color: "#000",
    hintColor: "#000",
  },
  listItem: {
    cursor: "pointer",
  },
  formControl: {
    width: "100%",
    borderRadius: 3,
    height: 35,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  fieldStyle: {
    width: "100%",
  },
  fuelAmountText: {
    width: 120,
    display: "flex",
    color: "#555",
    height: "100%",
    alignItems: "center",
    fontSize: 14,
  },
}));

const FuelRequestAsPerPilot = (props) => {
  const { onChange } = props;
  const [alignment, setAlignment] = React.useState(1);
  const classes = useStyles();

  const handleFuelAmount = (value) => {
    setAlignment(value);
    onChange(value);
  };

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <div className={classes.fuelAmountText}>Fuel amount</div>
        <div
          style={{
            display: "flex",
            width: 280,
            height: 35,
            borderRadius: 20,
          }}
        >
          <div
            style={{
              width: 140,
              backgroundColor: alignment === 1 ? "#df2935" : "#DCDCDC",
              color: alignment === 1 ? "#fff" : "#555",
              fontSize: 14,
              borderRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              zIndex: alignment === 1 ? 9 : 0,
            }}
            onClick={() => handleFuelAmount(1)}
          >
            Captain's Request
          </div>

          <div
            style={{
              width: 140,
              fontSize: 14,
              backgroundColor: alignment === 2 ? "#df2935" : "#DCDCDC",
              color: alignment === 2 ? "#fff" : "#555",
              borderRadius: 20,
              marginLeft: -10,
              display: "flex",
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
              zIndex: alignment === 2 ? 9 : 0,
            }}
            onClick={() => handleFuelAmount(2)}
          >
            Enter Quantity
          </div>
        </div>
      </FormControl>
    </div>
  );
};

export default FuelRequestAsPerPilot;
