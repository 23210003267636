import React from "react";
import { Grid,Link } from "@material-ui/core";

import { useStyles } from "../../../../assets/styles/ProfileSummaryStyles";
import { useSelector } from "react-redux";
import ContactDetailView from "./ContactDetailView";
import { useHistory } from "react-router-dom";

const AircraftDetails = () => {
  let aircrafts = [];
  const classes = useStyles();
  const history = useHistory();
  const { signup, account, auth } = useSelector((state) => ({
    signup: state.signup,
    account: state.account,
    auth: state.auth
  }));

  if (auth.status === 1 && account.assignedAircraftList.length > 0) {
    aircrafts = [...account.assignedAircraftList, ...signup.aircraftList];
  }
  else {
    aircrafts = [...signup.aircraftList];
  }

  return (
    <Grid container item xs={12} className={classes.secondCont}>
      <Grid item xs={12} className={classes.profileTextHeading}>
      <Grid container>
          <Grid item xs={11} className={classes.headingStyle}>
            Aircraft
          </Grid>
          <Grid item xs={1} className={classes.editButton}>
            <Link
              href="#"
              id="edit"
              variant="body2"
              color={"inherit"}
              onClick={() => {history.push("/signup/aircrafts")}}
            >
              Edit
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.profileDetail}>
        <ContactDetailView
          detailList={aircrafts}
          navigation="aircraftProfileSummary"
        />
      </Grid>
    </Grid>
  );
};

export default AircraftDetails;
