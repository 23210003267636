import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    display: 'flex',
    width: 71,
    height: 17,
    fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#fff'
  },
  input: {
    height: 45,
  },
  tableBody: {
    fontSize: 16,
    fontFamily: 'Arial',
    color: "#000"
  },
  collapsibleBody: {
    fontSize: 16,
    fontFamily: 'Arial',
    color: "#000",
    paddingLeft:10
  },
  fuelCostStyle: {
    fontSize: 14,
    fontFamily: 'Arial',
    color: "#000",
    paddingLeft: 36
  },
  tableBody2: {
    fontSize: 14,
    fontFamily: 'Arial',
    color: "#000",
    paddingLeft: 36
  },
  valueDiscount: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
    fontSize: 13,
    paddingLeft: 36
  },
  searchIcon: {
    position: "absolute",
    left: 10,
    top: '30%',
    display: 'flex',
    width: 17, height: 17,
  },
  dflex: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'sapce-between',
    display: "flex",
    flexDirection: "row",
    height: 37,
    flexGrow: 0,
    padding: "10px 0 0",
    background: "#797f8a",
  },
  bodyCont: {
    width: '100%',
    maxWidth: '100%',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: "flex",
    flexDirection: "row",
  },
  inputCont: {
    padding: 18,
    fontSize: 16,
    marginBottom: 10,
    paddingLeft: 40,
    width: 440,
    height: 44,
    color: "black",
    borderRadius: 5,
    border: '2px solid #999',
    "*:focus": {
      border: '2px solid #999',
    },
  },
  mainCont: {
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: "space-between",
    width: '100%',
  },
  formControl: {
    width: 190,
    height: 44,
    borderRadius: 5,
    margin: 0,
    fullWidth: true,
    display: 'flex',
    wrap: 'nowrap'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  filterHeading: {
    width: "100%",
    height: 40,
    marginTop: -8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  floatingLabelFocusStyle: {
    color: "#000",
  },
  resetButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    height: 40,
    marginLeft: '5%',
    borderRadius: 5,
    alignContent: 'center',
    background: colors.primaryColor,
    cursor: 'pointer'
  },
  resetButtonText: {
    color: "#fff",
    marginTop: -2,
  },
  labelStyle: {
    background: 'grey'
  },
  imageContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
    },
  collapsedCont: {
    width: '100%',
    background: "#e4e2e2",
    borderRadius: 5,
    border: '1px solid lightgrey',
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: "flex",
    flexDirection: "row",
  },
  rampFeeStyle: {
    width: '100%',
    padding: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  quantityRoot: {
    color: "#999",
    fontWeight: '500',
    borderRadius: 5,
    "&:hover": {
      color: "#000",
      background: 'white',
      borderRadius: "5px",
    },
    "&:focus-within": {
      color: "#000",
      borderRadius: "5px",
      background: 'white'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      color: "#000",
      border: "2px solid #999",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      color: "#000",
      border: "2px solid #999"
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      color: "#000",
      border: "2px solid #999",
      borderRadius: 5
    },
    "& .Mui-disabled": {
      color: "#000",
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      color: "#000",
      border: "2px solid #999"
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "black",
      background: 'white'
    }
  },
  fboNameStyle: {
    width: '100%',
    display: 'flex',
    height: 17,
    fontFamily: 'Arial',
    fontSize: 14,
    justifyContent: 'center',
    fontWeight: '500',
    color: '#fff'
  },
  finalAmountStyle: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center'
  },
  tableHeading: {
    fontSize: 14,
    fontFamily: 'Arial',
    color: "#797f8a",
    fontWeight: 'bold',
  },
  mainHeading: {
    display:'flex',
    padding: 5,
    paddingLeft: 80
  },
  collapsibleCont: {
    borderWidth: 1,
    borderColor: 'lightgrey'
  },
  collapsibleContainer:{
    paddingLeft: 20
  },
  disableText:{
    background:'#050B17',
    width: 190,
    fontWeight: "bold",
    color:'#fff'
  },
  historyListStyles:{
    display: "flexWrap",
    overflowY: "auto",
    height: "calc(100vh - 220px)"
  },
  noDataStyle:{
    display: 'flex',
    width:'100%',
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "calc(100vh - 280px)",
    color: colors.lightGreyBlue,
  },
  commentStyle:{
    fontSize: 12,
    padding: 1
  },
  historyFilterStyle:{
    height: "45px",
    background: "white",
    fontSize: 16
  },
  containerStyle: {
    width: '100%',
    color: "#797f8a",
    fontWeight: "bold",
    textAlign: "left",
    padding: 10,
    fontSize: 14,
    paddingLeft: 85,
    cursor: "pointer"
  }
}));

export { useStyles };