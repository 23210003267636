import { createAction } from "../utils";
import * as actionTypes from "./auth.actionTypes";

export const login = (email, password, deviceToken, isIFrameLogin = false) =>
  createAction(actionTypes.LOGIN_WITH_EMAIL.REQUEST, {
    email,
    password,
    deviceToken,
    isIFrameLogin,
  });

export const logout = (payload) =>
  createAction(actionTypes.LOG_OUT.REQUEST, payload);

export const getProfileByToken = (data) =>
  createAction(actionTypes.GET_PROFILE_BY_TOKEN, { data });
  
export const updateProfileStatus = (payload) => 
  createAction(actionTypes.UPDATE_PROFILE_STATUS.REQUEST, payload);
  
export const isIframe = (payload) => {
  return createAction(actionTypes.IS_IFRAME, payload);
};

export const termsAndCondition = (payload) => {
  return createAction(actionTypes.TERMS_CONDITION, payload);
};

export const invitedPilot = (payload) => {
  return createAction(actionTypes.INVITED_PILOT, payload);
};

export const getDetailOfInviteePilot = (payload) =>
  createAction(actionTypes.GET_PROFILE_BY_INVITEE_PILOT.REQUEST, payload);
