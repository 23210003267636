import React, { useEffect } from "react";
import {
  LinearProgress,
  IconButton,
  Grid,
  Drawer,
  Hidden,
  AppBar,
  Toolbar,
  Button,
  ListItem,
  ListItemIcon,
  Dialog,
  ListItemText,
  makeStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Styled from "styled-components";
import homeFilled from "../../assets/images/homefilled.png";
import homeOutline from "../../assets/images/homeoutline.png";
import logoutOutline from "../../assets/images/logoutoutline.png";
import mainlogo from "../../assets/images/mainlogo.png";
import unreadnotifyOutline from "../../assets/images/unreadnotifyOutline.png";
import readnotifyOutline from "../../assets/images/readnotifyOutline.png";
import unreadnotifyFilled from "../../assets/images/unreadnotifyFilled.png";
import readnotifyFilled from "../../assets/images/readnotifyFilled.png";
import settingsFilled from "../../assets/images/settingfilled.png";
import aircraftFilled from "../../assets/images/aircraftfilled.png";
import aircraftOutline from "../../assets/images/aircraftoutline.png";
import settingOutline from "../../assets/images/settingoutline.png";
import favouriteFill from "../../assets/images/favouriteFill.png";
import favouriteOutline from "../../assets/images/favouriteOutline.png";
import timeFilled from "../../assets/images/timefilled.png";
import timeOutline from "../../assets/images/timeoutline.png";
import pilotFilled from "../../assets/images/pilotFilled.png";
import pilotOutlined from "../../assets/images/pilotOutlined.png";
import managerFilled from "../../assets/images/managerFilled.png";
import managerOutline from "../../assets/images/managerOutline.png";
import { logout } from "../../redux/auth/auth.actions";
import { favouriteFboItem } from "../../redux/fbos/fbos.actions";
import { getUnreadNotificationCount } from "../../redux/notifications/notifications.actions";
import styled from "styled-components";
import colors from "../../constants/colors";
import { resetStore } from "../../redux/store/rootAction";

const useStyles = makeStyles(() => ({
  rightWrapPad0: {
    padding: "0",
  },
  drawer: {
    width: "240px",
    flexShrink: 0,
  },
  logo: {
    width: "130px",
    height: "60px",
    display: "block",
  },
  menuLink: {
    color: "#fff",
    paddingBottom: 10,
  },
  whiteColor: {
    color: "#fff",
  },
  menuLinkTxt: {
    color: "#555",
    fontWeight: 400,
    fontSize: 18,
  },
  activeMenuTxt: {
    color: "#fff",
    fontWeight: 500,
  },
  loader: {
    position: "fixed",
    top: 0,
    left: "240px",
    width: "calc(100% - 240px)",
    zIndex: 99999,
  },
  card: {
    border: "1px solid lightgrey",
    borderRadius: 5,
    borderColor: "#444",
    background: "#5A5757",
  },
}));

const StyledDrawer = Styled(Drawer)`  
    width: 240px;
    & {
        .MuiDrawer-paper {
            width: 240px;
            background: linear-gradient(99deg, #212121 15%, #000000 85%);
            color: #fff;
            height: 100vh;
            overflow-y: auto;
            display: flex-wrap;
            clip-path: inset(0px -15px 0px -15px);
            position: relative;
        }
    }
`;

const StyledAppBar = Styled(AppBar)`
    background: ${colors.denim};
    color: #fff;
`;

const StyledLayoutGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

let seeAllNoti = false;

const Layout = (props) => {
  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { auth, loader, notifications } = useSelector((state) => ({
    notifications: state.notifications,
    auth: state.auth,
    loader: state.loader,
  }));
  const history = useHistory();
  const classes = useStyles();
  const handleNavigate = (val) => {
    history.push(val);
  };

  useEffect(() => {
    dispatch(getUnreadNotificationCount({ token: auth.token }));
    if ( notifications.unreadNotification > 0) {
      seeAllNoti = true;
    } else {
      seeAllNoti = false;
    }
  }, [notifications.unreadNotification]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    setOpen(false);
    dispatch(logout({ token: auth.token }));
    dispatch(resetStore())
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const SideBar = () => {
    return (
      <StyledLayoutGrid>
        <div
          style={{
            height: 150,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img className={classes.logo} src={mainlogo} alt="mainlogo" />
        </div>
        <div>
          <div 
            className={`${
              history.location.pathname === "/home" ? classes.card : ""}`}>
            <ListItem
              button
              key={1}
              onClick={() => {
                dispatch(favouriteFboItem({}));
                handleNavigate("home")
                dispatch(favouriteFboItem({}));
              }} className={classes.menuLink}>
              <ListItemIcon>
                <img
                  style={{ width: 40, height: 40 }}
                  src={ 
                    history.location.pathname === "/home" 
                      ? homeFilled 
                      : homeOutline
                    }
                  alt="logo"
                />
              </ListItemIcon>
              <ListItemText
                className={`
                ${classes.menuLinkTxt}  ${
                  history.location.pathname === "/home" 
                    ? classes.activeMenuTxt 
                    : ""
                   }`}
                primary={"Home"}
              />
            </ListItem>
          </div>
          <div className={`${(history.location.pathname === "/notification"  ||
                history.location.pathname === "/notificationBookingDetail")
            ? classes.card : ""}`}>
            <ListItem
              button
              key={2}
              onClick={() => { handleNavigate("notification") }}
              className={classes.menuLink}
            >
              <ListItemIcon>
                <img
                  style={{ maxWidth: 40, maxHeight: 40 }}
                  src={(history.location.pathname === "/notification" ||
                  history.location.pathname === "/nudge-detail" ||
                    history.location.pathname === "/notification-booking-detail")
                     ? seeAllNoti === false 
                        ? readnotifyFilled 
                        : unreadnotifyFilled
                      : seeAllNoti === false
                        ? readnotifyOutline 
                        : unreadnotifyOutline
                  }
                  alt="notify"
                />
              </ListItemIcon>
              <ListItemText
                className={`
              ${classes.menuLinkTxt}  ${(history.location.pathname === "/notification" ||history.location.pathname === "/nudge-detail" || history.location.pathname === "/notification-booking-detail") 
               ? classes.activeMenuTxt : ""}`}
                primary={"Inbox"}
              />
            </ListItem>
          </div>
          <div className={`${(history.location.pathname === "/history" || 
            history.location.pathname === "/bookingDetail") 
              ? classes.card 
              : ""
            }`}>
            <ListItem
              button
              key={3}
              onClick={() => {
                handleNavigate("history")
              }}
              className={classes.menuLink}
            >
              <ListItemIcon>
                <img
                  style={{ maxWidth: 40, maxHeight: 40 }}
                  src={(history.location.pathname === "/history" ||
                    history.location.pathname === "/bookingDetail") 
                    ? timeFilled : timeOutline}
                  alt="icon"
                />
              </ListItemIcon>
              <ListItemText
                className={`
              ${classes.menuLinkTxt}  ${(history.location.pathname === "/history" ||
                    history.location.pathname === "/bookingDetail") 
                     ? classes.activeMenuTxt : ""}`}
                primary={"History"}
              />
            </ListItem>
          </div>

          <div className={`${(history.location.pathname === "/profile" ||
            history.location.pathname === "/invite-friends" || 
            history.location.pathname === "/change-password" ||
            history.location.pathname === "/contact-us") ? classes.card : ""}`}>
            <ListItem
              button
              key={4}
              onClick={() => {
                handleNavigate("profile")
              }}
              className={classes.menuLink}
            >
              <ListItemIcon>
                <img
                  style={{ maxWidth: 40, maxHeight: 40 }}
                  src={
                    (history.location.pathname === "/profile" ||
                      history.location.pathname === "/invite-friends" ||
                      history.location.pathname === "/change-password" ||
                      history.location.pathname === "/contact-us")
                      ? settingsFilled : settingOutline
                  }
                  alt="icon"
                />
              </ListItemIcon>
              <ListItemText
                className={`${classes.menuLinkTxt}  ${
                (history.location.pathname === "/profile" ||
                  history.location.pathname === "/invite-friends" ||
                  history.location.pathname === "/change-password" ||
                  history.location.pathname === "/contact-us")
                  ? classes.activeMenuTxt : ""}`}
                primary={"Settings"}
              />
            </ListItem>
          </div>

          <div className={`${history.location.pathname === "/aircraft-list" ? classes.card : ""}`}>
            <ListItem
              button
              key={5}
              onClick={() => {
                history.push({
                  pathname: "./aircraft-list",
                  state: { param: { fromSetting: true } },
                });
              }}
              className={classes.menuLink}
            >
              <ListItemIcon>
                <img
                  style={{ maxWidth: 40, maxHeight: 40 }}
                  src={
                    history.location.pathname === "/aircraft-list"
                      ? aircraftFilled
                      : aircraftOutline
                  }
                  alt="icon"
                />
              </ListItemIcon>
              <ListItemText
                className={`${classes.menuLinkTxt}  ${history.location.pathname === "/aircraft-list" ? classes.activeMenuTxt : ""}`}
                primary={"Manage Aircraft"}
              />
            </ListItem>
          </div>

          {auth.userType !== 4 ? (
            <div className={`${history.location.pathname === "/manage-managers" ? classes.card : ""}`}>
              <ListItem
                button
                key={6}
                onClick={() => {
                  history.push({
                    pathname: "./manage-managers",
                    state: { param: { fromSetting: true } },
                  });
                }}
                className={classes.menuLink}
              >
                <ListItemIcon>
                  <img
                    style={{ maxWidth: 40, maxHeight: 40 }}
                    src={history.location.pathname === "/manage-managers" ? managerFilled : managerOutline}
                    alt="icon"
                  />
                </ListItemIcon>
                <ListItemText
                  className={`${classes.menuLinkTxt}  ${history.location.pathname === "/manage-managers" 
                    ? classes.activeMenuTxt
                    : ""
                    }`}
                  primary={"Manage Managers"}
                />
              </ListItem>
            </div>
          ) : null}

          {auth.userType !== 4 ? (
            <div className={`${(history.location.pathname === "/manage-pilots" || history.location.pathname === "/pilot-profile") ? classes.card : ""}`}>
              <ListItem
                button
                key={7}
                onClick={() => {
                  history.push({
                    pathname: "./manage-pilots",
                    state: {
                      param: {
                        fromSetting: true,
                      },
                    },
                  });
                }}
                className={classes.menuLink}
              >
                <ListItemIcon>
                  <img
                    style={{ maxWidth: 40, maxHeight: 40 }}
                    src={
                      (history.location.pathname === "/manage-pilots" ||
                        history.location.pathname === "/pilot-profile") 
                        ? pilotFilled
                        : pilotOutlined
                    }
                    alt="icon"
                  />
                </ListItemIcon>
                <ListItemText
                  className={`
                    ${classes.menuLinkTxt}  ${(history.location.pathname === "/manage-pilots" ||
                      history.location.pathname === "/pilot-profile")
                       ? classes.activeMenuTxt : ""}`}
                  primary={"Manage Pilots"}
                />
              </ListItem>
            </div>
          ) : null}

          <div className={`${history.location.pathname === "/favourite-fbos" ? classes.card : ""}`}>
            <ListItem
              button
              key={8}
              onClick={() => {
                history.push({ pathname: "./favourite-fbos" });
              }}
              className={classes.menuLink}
            >
              <ListItemIcon>
                <img
                  style={{ maxWidth: 40, maxHeight: 40 }}
                  src={history.location.pathname === "/favourite-fbos" ? favouriteFill : favouriteOutline}
                  alt="icon"
                />
              </ListItemIcon>
              <ListItemText
                className={`${classes.menuLinkTxt} ${history.location.pathname === "/favourite-fbos" ? classes.activeMenuTxt : ""}`}
                primary={"Favorite FBOs"}
              />
            </ListItem>
          </div>

          <ListItem
            button
            key={9}
            onClick={() => handleClickOpen("logout")}
            className={classes.menuLink}
          >
            <ListItemIcon>
              <img
                style={{ maxWidth: 40, maxHeight: 40 }}
                src={logoutOutline}
                alt="logoutIcom"
              />
            </ListItemIcon>
            <ListItemText
              className={`${classes.menuLinkTxt} `}
              primary={"Logout"}
            />
          </ListItem>
        </div>
        <Divider />
      </StyledLayoutGrid>
    );
  };

  return (
    <Grid container spacing={0}>
      <Hidden smUp implementation="css">
        <StyledAppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className="menuButton"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </StyledAppBar>
        <StyledDrawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <SideBar />
        </StyledDrawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <StyledDrawer variant="permanent" anchor="left">
          <SideBar />
        </StyledDrawer>
      </Hidden>
      <Grid
        container
        item
        xs={10}
        spacing={0}
        className={`
          ${classes.rightWrap}  ${
          history.location.pathname === "/home" ||
          history.location.pathname === "/notification"
            ? classes.rightWrapPad0
            : ""
        }`}
        direction="column"
        alignItems="center"
      >
        <main style={{ width: "calc(100% - 240px)" }}>
          {loader?.isLoaderVisible ? (
            <LinearProgress className={classes.loader} color="secondary" />
          ) : null}

          {props.children}
        </main>
        {/* {openNotifcation ? (
          <main style={{ width: "100%" }}>
            <Notifications closeNotification={() => closeNotification()} />
          </main>
        ) : null} */}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"NOZL"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to log out of NOZL ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="secondary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Layout;
