import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, makeStyles, Modal } from "@material-ui/core";
import { Formik } from "formik";
import ProfileSetupSchema from "../.../../../../utils/forms/schema.profileSetup";
import ParagonProfileSetupSchema from "../.../../../../utils/forms/schema.paragonProfileSetup";
import Heading from "./Heading";
import BasicDetails from "./BasicDetails";
import LoginDetails from "./LoginDetails";
import HeadingText from "./HeadingText";
import BottomButton from "./BottomButton";
import { useDispatch } from "react-redux";
import { basicDetails, timeline } from "../../../redux/signup/signup.actions";
import { useHistory } from "react-router-dom";
import * as services from "../../../services/mainService";
import ErrorPopUp from "./ErrorPopUp";

const useStyles = makeStyles(() => ({
  firstCont: {
    marginTop: 40,
    height: 80,
  },
  secondCont: {
    marginTop: 36,
    height: "calc(100vh - 236px)",
  },
  thirdCont: {
    height: 50,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
}));

const ProfileSetUp = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [temp, setTemp] = useState(0);
  const { signup, auth, account } = useSelector((state) => ({
    signup: state.signup,
    auth: state.auth,
    account: state.account,
  }));

  const [openPopUp, setPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details] = useState({
    name: "",
    mobileNumber: "",
    baseLocation: "",
    addLat: "",
    addLng: "",
    city: "",
    state: "",
    country: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: "",
    isParagonUser: account.isParagonUser,
    stepNumber: 1,
    countryCode: "",
    isInviteePilot: auth.status === 1 ? true : false,
  });

  useEffect(() => {
    if (window.location.search !== '') {
      let pilotDetail = window.location.search;
      let pilot = pilotDetail.split("&email=");
      let pilotEmail = pilot[1].split("&userType");
      details.email = decodeURIComponent(pilotEmail[0]);
    }
  }, [details]);


  const enterFullNameValue = (value) => {
    details.name = value;
    setTemp(temp + 1);
  };

  const enterPhoneNoValue = (countryeCode, value) => {
    details.countryCode = countryeCode;
    details.mobileNumber = value;
    setTemp(temp + 1);
  };

  const corporateAddValue = (value) => {
    details.baseLocation = value;
    setTemp(temp + 1);
  };

  const getLatLong = (lat, lng, city, state, country) => {
    details.addLat = lat;
    details.addLng = lng;
    details.city = city;
    details.state = state;
    details.country = country;
    setTemp(temp + 1);
  };

  const enterEmailValue = (value) => {
    details.email = value;
    setTemp(temp + 1);
  };

  const enterPasswordValue = (value) => {
    details.password = value;
    setTemp(temp + 1);
  };

  const enterConfirmPasswordValue = (value) => {
    details.confirmPassword = value;
    setTemp(temp + 1);
  };

  useEffect(() => {
    if (
      Object.values(signup.basicDetails).length > 0 &&
      signup.basicDetails.email !== ""
    ) {
      details.email = signup.basicDetails.email || "";
      details.password = signup.basicDetails.password || "";
      details.confirmPassword = signup.basicDetails.confirmPassword || "";
      details.countryCode = signup.basicDetails.countryCode || "";
      details.name = signup.basicDetails.name || "";
      details.mobileNumber = signup.basicDetails.mobileNumber || "";
      details.baseLocation = signup.basicDetails.baseLocation || "";
      details.addLat = signup.basicDetails.addLat || "";
      details.addLng = signup.basicDetails.addLng || "";
      details.city = signup.basicDetails.city || "";
      details.state = signup.basicDetails.state || "";
      details.country = signup.basicDetails.country || "";
    }
  }, []);

  useEffect(() => {
    details.userType = signup.registerAs;
  }, [temp, details]);

  const handleClosePopUp = () => {
    setPopUp(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container item xs={12} className={classes.firstCont}>
          <Heading heading="Profile Setup" />
          <Grid container item xs={12}>
            <HeadingText
              navigation="profileSetUp"
              text="Enter your personal details to create a profile. You can add additional users to the same account in the next step. Each user will have unique login credentials."
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.secondCont}>
        <Formik
         validationSchema={!account.isParagonUser || auth.status === 1 ? ProfileSetupSchema : ParagonProfileSetupSchema}
          initialValues={details}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            if (details.name !== "") {
              dispatch(basicDetails(details));
              const res = await services.signUpUrl(details);
              setSubmitting(false);
              setSubmitting(false);
              if (res.response_type === "success") {
                if (signup.registerAs === 4) {
                  dispatch(
                    timeline({
                      ...signup.timelineSteps,
                      profilesetup: {
                        ...signup.timelineSteps.profilesetup,
                        selected: true,
                      },
                      aircraftDetails: {
                        ...signup.timelineSteps.aircraftDetails,
                        isActive: true,
                      },
                    })
                  );
                } else {
                  dispatch(
                    timeline({
                      ...signup.timelineSteps,
                      profilesetup: {
                        ...signup.timelineSteps.profilesetup,
                        selected: true,
                      },
                      userDetails: {
                        ...signup.timelineSteps.userDetails,
                        isActive: true,
                      },
                    })
                  );
                }
                history.push(
                  `${
                    signup.registerAs === 2
                      ? "/signup/users"
                      : "/signup/aircrafts"
                  }`
                );
              } else {
                setErrorMessage(res.response.message);
                setPopUp(true);
              }
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            setTouched,
            touched,
            errors,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <Grid
                item
                xs={12}
                style={{ height: "calc(100vh - 236px - 50px)" }}
              >
                <BasicDetails
                  enterFullNameValue={enterFullNameValue}
                  enterPhoneNoValue={enterPhoneNoValue}
                  corporateAddValue={corporateAddValue}
                  getLatLong={getLatLong}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onTouch={setTouched}
                  errors={errors}
                  touched={touched}
                />
                <LoginDetails
                  enterEmailValue={enterEmailValue}
                  enterPasswordValue={enterPasswordValue}
                  enterConfirmPasswordValue={enterConfirmPasswordValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
              </Grid>
              <Grid item xs={12} className={classes.thirdCont}>
                <BottomButton
                  isInvitePilot={auth.status === 1}
                  isSubmitting={isSubmitting}
                  disabled={Object.keys(errors).length > 0 || isSubmitting}
                  onNextClick={handleSubmit}
                  onBackClick={() =>
                    auth.isIframe
                      ? history.push(
                          "/signup/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4="
                        )
                      : history.push("/signup")
                  }
                />
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openPopUp}
        onClose={handleClosePopUp}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ErrorPopUp
          errorMessage={errorMessage}
          handleCloseModal={handleClosePopUp}
        />
      </Modal>
    </Grid>
  );
};

export default ProfileSetUp;