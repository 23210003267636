import React from "react";
import Info from "@material-ui/icons/Info";
import { Grid, Tooltip } from "@material-ui/core";
import { useStyles } from "../../../../assets/styles/FboDetailStyles";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";
import constants from "../../../../config/constants";
import ShowJETASAFTiers from "./ShowJETASAFTiers";

const FboItemPrice = (props) => {
  const { item, _100LLDiscount, fuelType, isUnlisted } = props;
  const classes = useStyles();

  const showNotes = (notes) => (
    <Tooltip title={notes} classes={{ tooltip: classes.customTooltipStyle }}>
      <Info className={classes.showNotesStyle}/>
    </Tooltip>
  );

  const renderSafTiers = () => {
    return item.discountFuelType === constants.discount.saf
    ? item.discountTiers
    : item.discountFuelType === constants.discount.jetA_Saf_Both
      ? item.secondDiscountTier
      : item.tiersSAF
  };

  const renderJetATiers = () => {
    let jetADiscount = item.discountFuelType === constants.discount.jet_A;
    let bothJetaandSafDiscount = item.discountFuelType === constants.discount.jetA_Saf_Both;
    return (jetADiscount|| bothJetaandSafDiscount)?item.discountTiers : item.tiersJetA;
  }

  return (
    <div className={classes.mainCont}>
      {(fuelType < constants.fuelType.HUNDRED_LL) && item.isJetAFuel === constants.fuelApplied.jetA
        ? <ShowJETASAFTiers tiers={renderJetATiers()} item={item} navigation="Jet A" />
        : null}
      {fuelType < constants.fuelType.HUNDRED_LL && item.isSAFFuel === constants.fuelApplied.saf
        ? <ShowJETASAFTiers tiers={renderSafTiers()} item={item} navigation="saf" />
        : null}
      {item.is100LLFuel === constants.fuelApplied.hundredLL &&
        (fuelType === constants.fuelType.fuel_type || fuelType === constants.fuelType.HUNDRED_LL || item.discountFuelType === constants.discount.hundredLL) && (
          <Grid className={classes.priceCont}>
            <Grid className={classes.textStyleHundredLL}>100 LL</Grid>
            <Grid className={classes.priceContainer}>
              {item.discountFuelType === constants.discount.hundredLL && item.retailPriceLL !== _100LLDiscount ? (
                <Grid className={classes.hundredllPrice}>
                  {`$${formatNumWithCommas(parseFloat(item.retailPriceLL).toFixed(2))}/gal`}
                </Grid>
              ) : null}
              <div className={classes.priceTextStyle}>
                {`$${formatNumWithCommas(parseFloat(_100LLDiscount).toFixed(2))}/gal`}
              </div>
              {item.discountFuelType === constants.discount.hundredLL &&item.discountTiers.length > 0 ? (
                item.discountTiers[0].comment !== "" &&
                  !!item.discountTiers[0].comment
                    ? showNotes(item.discountTiers[0].comment)
                    : null
              ) : null}
            </Grid>
          </Grid>
        )}
    </div>
  );
};

export default FboItemPrice;