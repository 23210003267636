import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
const PrivateRoute = ({
  component: Component,
  componentProps,
  auth,
  account,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Object.values(account.user).length === 0 && auth.status === 1 ? (
          <Redirect
            to={{
              pathname: "/signup/profileSetup",
              state: {
                param: {
                  isFromPilot: true,
                },
              },
            }}
          />
        ) : auth.isActive === 1 && auth.profileStatus === 4 ? (
          <Component {...{ ...props, ...componentProps }} />
        ) : auth.isActive === 0 && auth.profileStatus === 2 ? (
          <Redirect to="/aircraftList" />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default connect(
  ({ auth, account }) => ({ auth, account }),
  {}
)(PrivateRoute);
