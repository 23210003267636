import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        padding: theme.spacing(1),
        position: "absolute",
        top: theme.spacing(5),
        width: 450,
        marginLeft: 300,
    },
    dialogWrapperNested: {
        padding: theme.spacing(1),
        position: "absolute",
        top: theme.spacing(5),
        width: 450,
        marginLeft: 300,
    },
    dialogWrapperNormal: {
        padding: theme.spacing(1),
        width: 450,
    },
    dialogTitle: {
        paddingRight: "0px",
    },
    inviteDialogTitle: {
        paddingRight: "0px",
        paddingBottom: 0
    },
    conataint: {},
    conataintCenter: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
    },
    inviteConataintCenter: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        paddingTop: 0,
        paddingLeft: 8
    },
    submitBtn: {
        backgroundColor: colors => colors.primaryColor,
        color: "#fff",
        width: 70,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 35,
        borderRadius: 5,
    },
    textStyle: {
        flexGrow: 1,
    },
    inviteTextStyle: {
        flexGrow: 1,
        textAlign: "center",
        marginTop: 5,
        marginLeft: 30
    }
}));
export { useStyles };