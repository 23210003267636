import { all, fork } from 'redux-saga/effects';

import { authSaga } from '../auth/auth.saga';
import { accountSaga } from '../account/account.saga';
import { fbosSaga } from '../fbos/fbos.saga';
import { notificationsSaga } from '../notifications/notifications.saga';
import { requestsSaga } from '../requests/requests.saga';
import { pilotsSaga } from '../pilots/pilots.saga';

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(accountSaga),
    fork(fbosSaga),
    fork(notificationsSaga),
    fork(requestsSaga),
    fork(pilotsSaga),
  ]);
}
