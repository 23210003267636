import React from 'react';

import { Typography, AppBar, Toolbar, Grid } from "@material-ui/core";
import fontStyles from "../../constants/fonts";
import commonStyles from "../../constants/common";

const NozlInnerHeading = (props) => {
  const { title } = props;
  const textClasses = fontStyles();
  const commonClasses = commonStyles();
  
  return (
    <Grid>
      <AppBar position="static" className={commonClasses.innerHeader}>
        <Toolbar>
          <Typography className={textClasses.heading2}>{title}</Typography>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default NozlInnerHeading;