import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SignupTextField from "../../assets/styles/SignUpLabelStyles";
import location from "../../assets/images/location.png";
import searchClearIcon from "../../assets/images/searchClearIcon.png";
import {
  Avatar,
  InputAdornment,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { useSelector } from "react-redux";

export const BaselocationTextField = withStyles({
  root: {
    width: "100%",
    color: "white",
    textTransform: "lowercase",
    "& > :first-letter": {
      textTransform: "capitalize",
    },
    "& label.Mui-focused": {
      color: "black",
      textTransform: "lowercase",
      "& > :first-letter": {
        textTransform: "capitalize",
      },
      border: "1px solid black !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
      color: "black",
      textTransform: "lowercase",
      "& > :first-letter": {
        textTransform: "capitalize",
      },
      border: "1px solid black !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        color: "black",
        textTransform: "lowercase",
        "& > :first-letter": {
          textTransform: "capitalize",
        },
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
        color: "black",
        textTransform: "lowercase",
        "& > :first-letter": {
          textTransform: "capitalize",
        },
        border: "1px solid black !important",
      },
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  notchedOutline: {
    padding: 5,
    borderColor: "#ddd !important",
    borderWidth: "2px",
    boxSizing: "border box",
    borderRadius: 5,
    color: "#666",
    hintColor: "#666",
  },
}));

const CorporateAddress = (props) => {
  const classes = useStyles();
  const { baseLocation, navigation } = props;
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);

  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => props.baseLocationLatLong(latLng))
      .catch((error) => console.error("Error", error));
    props.address(address);
  };

  return (
    <PlacesAutocomplete
      value={baseLocation}
      onChange={(value) => props.setCorporateAddress(value)}
      onSelect={handleSelect}
    >{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div>
          {navigation === "signup" ? (
            <SignupTextField
              {...getInputProps()}
              label="Corporate Address Location (Optional)"
              variant="outlined"
              onFocus={onFocus}
              inputProps={{
                style: {
                  height: 5,
                },
              }}
              InputProps={{
                style: { color: "#000" },
                readOnly: focused ? false : true,
                classes: {
                  notchedOutline: classes.notchedOutline,
                  input: classes.inputLine,
                },
                color: "#000",
                endAdornment:
                  baseLocation !== null &&
                  baseLocation !== "" &&
                  baseLocation !== undefined ? (
                    <InputAdornment position="end">
                      <img alt=""
                        src={searchClearIcon}
                        style={{
                          width: 30,
                          height: 30,
                          marginRight: -10,
                          cursor: "pointer",
                        }}
                        onClick={() => props.setCorporateAddress("")}
                      />
                    </InputAdornment>
                  ) : null,
              }}
            />
          ) : (
            <BaselocationTextField
            {...getInputProps()}
              variant="outlined"
              disabled={auth.userType === 9}
              InputProps={{
                endAdornment:
                  baseLocation !== null &&
                  baseLocation !== "" &&
                  baseLocation !== undefined ? (
                    <InputAdornment position="end">
                      <input
                        type="image"
                        alt=""
                        disabled={auth.userType === 9}
                        src={searchClearIcon}
                        style={{
                          width: 40,
                          height: 40,
                          opacity: auth.userType === 9 ? 0.7 : 1,
                          marginRight: -10,
                          cursor: "pointer",
                        }}
                        onClick={() => props.setCorporateAddress("")}
                      />
                    </InputAdornment>
                  ) : null,
              }}
            />
          )}
          <div className="autocomplete-dropdown-container">
            {loading ? <div>loading...</div> : null}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              const style = suggestion.active
                ? {
                    backgroundColor: "#f7f7f7",
                    cursor: "pointer",
                    paddingTop: 11,
                  }
                : {
                    backgroundColor: "#faf9f9",
                    cursor: "pointer",
                    paddingTop: 11,
                  };
              return (
                <div style={{ display: "flex", padding: 5 }}>
                  <div>
                    <Avatar
                      style={{
                        width: 30,
                        height: 30,
                        marginTop: 6,
                        marginRight: 7,
                      }}
                    >
                      <img
                        src={location}
                        alt="city"
                        style={{ width: 30, height: 30 }}
                      />
                    </Avatar>
                  </div>
                  <div>
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      {suggestion.description}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default CorporateAddress;
