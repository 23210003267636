import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  secondInnerCont: {
    width: 520,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 5,
    border: "1px solid #e8e8e8",
  },
  secondIframeCont: {
    width: "100%",
    padding: 10,
    marginBottom: 20
  },
  inputFieldStyle: {
    marginTop: 14,
    width: "100%",
  },
  notchedOutline: {
    padding: 5,
    borderColor: "#ddd !important",
    borderWidth: "2px",
    boxSizing: "border box",
    borderRadius: 5,
    color: "#666",
    hintColor: "#666",
  },
  notchedIframeOutline: {
    padding: 5,
    borderColor: "#ddd !important",
    borderWidth: "2px",
    boxSizing: "border box",
    borderRadius: 5,
    color: "#666",
    hintColor: "#666",
  },
  buttonCont: {
    width: "100%",
    height: 40,
    flexGrow: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    fontSize: 14,
    fontWeight: "500",
    cursor: "pointer",
    borderRadius: 5,
    background: "linear-gradient(to bottom, #666 0%, #333 100%)",
    color: "#fff",
    textTransform: "initial",
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.4,
      background: "linear-gradient(to bottom, #666 0%, #333 100%)",
      color: "#fff",
    },
  },
  buttonCont1: {
    width: "48%",
  },
  inputLabel: {
    background: "red",
  },
  menuButtonStyle: {
    marginLeft: 5,
    width: "100%",
    height: 50,
    display: "flex",
    paddingRight: 10,
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "normal",
    cursor: "pointer",
  },
  paper: {
    textTransform: "capitalize",
  },
  backButtonText: {
    width: "100%",
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    cursor: "pointer",
    backgroundColor: "#ebecf0",
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#212121",
    textTransform: "initial",
  }, 
  iframeBackButtonText: {
    width: "48%",
  },
  errorMsg: {
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Arial",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    marginLeft: "14px",
    marginRight: "14px",
    color: "#f44336",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
}));

export { useStyles };
