import React, { useEffect, useState, useMemo } from "react";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/controls/Pagination";

import colors from "../../constants/colors";
import AppLayout from "../Layout/AppLayout";
import textStyles from "../../constants/fonts";
import commonStyles from "../../constants/common";
import { getRequests } from "../../redux/requests/requests.actions";
import HistoryItems from "../../components/scene/app/menu/HistoryItems";
import CustomLoader from "../../components/ui/CustomLoader";
import moment from "moment";

export const StyledContainer = styled.div`
  .contentContainer {
    padding: 0px 10px 0px 15px;
  }
`;

export const Wrap = styled.div`
  .Collapsible__contentInner {
    padding: 0;
    border: 0;
  }
  .Collapsible__trigger {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: none;
    content: '\f0ab';
    border-top: 0px solid rgb(177, 157, 157);
  }
`;

export const StyledGridMainContainer = styled(Grid)``;

const useStyles = makeStyles((theme) => ({
  padding: {
    minHeight: "0px!important",
    padding: "0!important",
    paddingTop: "0px",
    paddingRight: "0px",
  },
  root: {
    height: "100vh",
    width: "100%",
  },
  noData: {
    height: "calc(100vh - 65px)",
    paddingTop: 20,
  },
  header: {
    height: 110,
    backgroundColor: colors.denim,
  },
  top: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  history: {
    color: "#fff",
    fontSize: 25,
    fontWeight: "600",
  },
  filterr: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "600",
  },
  filterArea: {
    width: 90,
    height: 27,
    backgroundColor: "#5C6F8E",
    border: 20,
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#fff",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    color: colors.white,
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  heading: {
    width: "100%",
    justifyContent: "space-between",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterHeading: {
    width: "100%",
    height: 40,
    marginTop: -8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "lightgrey",
  },
  floatingLabelFocusStyle: {
    color: "#000",
  },
  resetButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    height: 40,
    marginLeft: "5%",
    borderRadius: 5,
    alignContent: "center",
    background: colors.primaryColor,
    cursor: "pointer",
  },
  resetButtonText: {
    color: "#fff",
    marginTop: -2,
  },
  dialogRoot: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  datePickerStyle: {
    maxWidth: 400,
    maxHeight: 400,
  },
  paginationStyle: {
    display: "flex",
    justifyContent: "end",
    borderTop: "1px solid #ddd",
  },
  labelStyle: {},
}));

export const StyledMain = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  display: flex-wrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

let date = "";
let rowsPerPage = 20;
export default function History() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const textClasses = textStyles();
  const commonClasses = commonStyles();

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [filteredValue, setFilteredValue] = useState("");
  const [search, setSearch] = useState("");
  const [historyFilter, setHistoryFilter] = useState("");
  const [selectDate, setselectDate] = useState(new Date());

  const { auth, requests } = useSelector((state) => ({
    auth: state.auth,
    requests: state.requests,
  }));

  const bookingStatus = {
    "Show all": "",
    Pending: 1,
    Acknowledged: 2,
    Completed: 3,
    Cancelled: 4,
    Expired: 5,
  };
  const getRecords = (pageCount, search, date, filteredValue) => {
    dispatch(
      getRequests({
        token: auth.token,
        record: {
          page: pageCount,
          rowsNumber: rowsPerPage,
          sortBy: "",
          descending: true,
          search: search,
          bookingDate: date,
          status: bookingStatus[filteredValue],
        },
      })
    );
  };
  useEffect(() => {
    getRecords(1);
  }, [auth.token]);

  const handleChangePage = (event, newPage, newPageCount) => {
    setPage(newPage);
    setPageCount(newPageCount);
    getRecords(newPageCount, search, date, filteredValue);
  };

  const handleChangeSelect = (targetValue) => {
    setPage(0);
    setPageCount(1);
    setFilteredValue(targetValue);
    date = "";
    getRecords(1, search, date, targetValue);
  };

  const handleSearch = (value, filteredValue) => {
    getRecords(1, value, date, filteredValue);
  };

  const onSelectDate = (selectedDate) => {
    date = moment(selectedDate).format("YYYY-MM-DD");
    getRecords(pageCount, search, date, filteredValue);
  };

  const handleDebounce = (event) => {
    debouncedHandleSearch(event, filteredValue);
    setSearch(event);
    setPage(0);
    setPageCount(1);
  };
  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 700), []);

  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <AppLayout>
        <StyledGridMainContainer
          container={true}
          spacing={0}
          className="mainContainer"
        >
          <Grid item={true} xs={12} className="contentContainer">
            <AppBar position="static" className={commonClasses.pageHeader}>
              <Toolbar className={(commonClasses.pl0, classes.heading)}>
                <Typography className={textClasses.pageHeading}>
                  History
                </Typography>
              </Toolbar>
            </AppBar>
            {requests.status === "loading" && navigator.onLine ? (
              <CustomLoader />
            ) : (
              <div>
                <div>
                  <HistoryItems
                    key={"HistoryItems"}
                    requests={requests?.requests}
                    setFilteredValue={setFilteredValue}
                    filteredValue={filteredValue}
                    handleChangeSelect={handleChangeSelect}
                    handleDebounce={handleDebounce}
                    onSelectDate={onSelectDate}
                    search={search}
                    setHistoryFilter={setHistoryFilter}
                    historyFilter={historyFilter}
                    setselectDate={setselectDate}
                    selectDate={selectDate}
                  />
                  <div className={classes.paginationStyle}>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={requests.requestsMetaData?.total}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      ActionsComponent={() => (
                        <TablePaginationActions
                          notificationLength={requests.requestsMetaData?.total}
                          page={page}
                          pageCount={pageCount}
                          rowsPerPage={rowsPerPage}
                          onPageChange={handleChangePage}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </StyledGridMainContainer>
      </AppLayout>
    </StyledContainer>
  );
}
