import * as Yup from "yup";

export default Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Please enter a valid email address"
    )
    .required("Email is required")
});
