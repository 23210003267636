import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "../controls/Input";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { login } from "../../redux/auth/auth.actions";
import strings from "../../constants/strings";
import textStyles from "../../constants/fonts";
import EmailIcon from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import nozlSignUpLogo from "../../assets/images/nozlSignUpLogo.png";
import paragonNozlLogo from "../../assets/images/paragonNozlLogo.png";
import Popup from "../scene/profile-setup/Popup";
import ForgotPassword from "./profile-setup/ForgotPassword";
import { useCustomEventListener } from "react-custom-events";
import colors from "../../constants/colors";
import { useStyles } from "../../assets/styles/LoginStyles";
import {
  clearSelectedAircraft,
  isParagon,
  searchHistoryList,
} from "../../redux/account/account.actions";
import {advancedSearch, rangeValue} from "../../redux/account/account.actions";
import CustomLoader from "../ui/CustomLoader";
import {
  aircraftsList,
  basicDetails,
  registerAs,
  usersList,
  timeline,
} from "../../redux/signup/signup.actions";
import SubmitSignUp from "./signUp/SubmitSignUp";

const Login = () => {
  const textClasses = textStyles();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));
  const classes = useStyles(colors);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openThankyou, setOpenThankyou] = useState(false);
  let url = window.location.origin;

  useEffect(() => {
    if (url.includes("paragon")) {
      dispatch(isParagon(true));
    } else {
      dispatch(isParagon(false));
    }
    dispatch({ type: strings.HIDE_PROGRESS });
    dispatch(clearSelectedAircraft({}));
  }, []);

  useEffect(() => {
    dispatch(registerAs(""));
    dispatch(usersList([]));
    dispatch(aircraftsList([]));
    dispatch(basicDetails({}));
    dispatch(timeline({}));
  }, []);

  function validateForm() {
    var pattern = new RegExp(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i
    );
    return pattern.test(email) && password.length > 6;
  }

  useCustomEventListener("nozlEvent", (data) => {
    if (data?.showPopup) {
      setOpenThankyou(true);
    }
  });

  const handleCloseThankyou = () => {
    setOpenThankyou(false);
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch(searchHistoryList({}));
    dispatch(advancedSearch({}));
    dispatch(rangeValue(0));
    dispatch(login(email.toLowerCase(), password, ""));
  };

  return (
    <div className={classes.pageBackground}>
      {auth.loading && <CustomLoader navigation="darkTheme" />}
      <Container component="main" maxWidth="lg">
        <AppBar position="fixed" className={classes.fixedBar}>
          <Toolbar>
            <img
              alt=""
              src={url.includes("paragon") ? paragonNozlLogo : nozlSignUpLogo}
              className={
                url.includes("paragon")
                  ? classes.paragonImageLogoStyle
                  : classes.imageLogoStyle
              }
            />
          </Toolbar>
        </AppBar>
        <div className={classes.page}>
          <Grid container spacing={5}>
            <Grid
              container
              item
              xs={5}
              spacing={0}
              className={classes.leftWrap}
              direction="column"
              justify="center"
            >
              <Typography
                noWrap
                component="h5"
                variant="h4"
                className={`${textClasses.heading2} ${textClasses.authHeading}`}
              >
                Direct. The way it should be.
              </Typography>
              <Typography
                component="h1"
                variant="h3"
                className={`${textClasses.heading1} ${textClasses.authSubHeading}`}
              >
                Good to see you again!
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={7}
              spacing={0}
              className={classes.rightWrap}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
                container
                item
                xs={8}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <form
                  autoComplete="off"
                  className={classes.form}
                  noValidate
                  onSubmit={onFormSubmit}
                >
                  <TextField
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email Address"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br />
                  <Typography
                    className={
                      url.includes("paragon")
                        ? `${classes.paragonLink} ${classes.right}`
                        : `${classes.link} ${classes.right}`
                    }
                  >
                    <Link
                      href="#"
                      variant="body2"
                      color={"inherit"}
                      onClick={() => setOpenPopup(true)}
                    >
                      Forgot password?
                    </Link>
                  </Typography>
                  <br />

                  <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      disabled={!validateForm()}
                      fullWidth
                      className={
                        url.includes("paragon") ? classes.paragonSubmit : classes.submit
                      }
                      disableElevation
                    >
                      Sign In
                    </Button>
                  </Grid>

                  <br />

                  <Grid
                    container
                    alignItems="center"
                    alignContent="center"
                    justify="center"
                  >
                    <Grid item alignItems="center" alignContent="center" xs={8}>
                      <Typography
                        noWrap
                        component="p"
                        variant="subtitle1"
                        className={textClasses.heading3}
                      >
                        Don't have an account?{" "}
                        <Link
                          href="/signup"
                          variant="body2"
                          alignItems="center"
                          alignContent="center"
                          className={
                            url.includes("paragon")
                              ? textClasses.paragonLink
                              : textClasses.link
                          }
                        >
                          {"Sign Up"}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Popup
        title="Reset Password"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        isCenter={true}
        normalPopUp={true}
        isNested={false}
      >
        <ForgotPassword setOpenPopup={setOpenPopup} />
      </Popup>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openThankyou}
        onClose={handleCloseThankyou}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SubmitSignUp />
      </Modal>
    </div>
  );
};

export default Login;
