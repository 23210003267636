import * as actionTypes from "./account.action-types";

const initialState = {
  status: "pending",
  user: {},
  airplaneList: [],
  userSelectedAircraft: {},
  aircraftList: [],
  tempAircraft: [],
  assignedAircraftList: [],
  assignedPilotListForAircraft: [],
  addCustomAircraftList: {},
  addOperatorManagerList: {},
  addAdditionalManagerList: [],
  aircraftMakerList: [],
  addAircraft: {},
  advanceSearch: {},
  slidervalue: 0,
  isParagon: "",
  baseLocation: "",
  isParagonUser: false,
  searchHistoryList: [],
  userBaseLocation: {},
  aircraftStatus: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // fetch user profile details
    case actionTypes.GET_PROFILE.REQUEST:
      return { ...state, status: "loading", isParagon: "" };
    case actionTypes.GET_PROFILE.SUCCESS: {
      const { operatorUserDetail } = action.payload;
      const { primaryManager } = action.payload;
      const { additionalManager } = action.payload;
      const user = {
        name: operatorUserDetail?.operatorDetail?.name ?? "",
        countryCode: operatorUserDetail?.operatorDetail?.countryCode ?? "",
        mobileNumber: operatorUserDetail?.operatorDetail?.mobileNumber ?? "",
        baseLocation:
          operatorUserDetail !== undefined
            ? operatorUserDetail?.operatorDetail?.baseLocation
            : "",
        city: operatorUserDetail?.operatorDetail?.city ?? "",
        state: operatorUserDetail?.operatorDetail?.state ?? "",
        country: operatorUserDetail?.operatorDetail?.country ?? "",
        userId: operatorUserDetail?.operatorDetail?.userId ?? "",
        cfpMembership:
          operatorUserDetail?.operatorDetail?.cfpMembership ?? null,
        caaMembership:
          operatorUserDetail?.operatorDetail?.caaMembership ?? null,
        email: operatorUserDetail?.email ?? "",
        isParagon: operatorUserDetail?.isParagonUser ?? "",
        createdAt:
          operatorUserDetail !== undefined ? operatorUserDetail.createdAt : "",
        primaryManagerDetail: primaryManager,
        additionalManagerDetail: additionalManager,
        operatorName: operatorUserDetail?.operatorDetail?.operatorName ?? "",
      };

      return {
        ...state,
        status: "success",
        isParagon: operatorUserDetail?.isParagonUser ?? "",
        user: user,
        baseLocation:
          operatorUserDetail !== undefined
            ? operatorUserDetail?.operatorDetail?.baseLocation
            : "",
      };
    }
    case actionTypes.GET_PROFILE.FAILURE:
      return { ...state, status: "failure" };
    // get airplane list
    case actionTypes.GET_AIRPLANE_LIST.REQUEST:
      return {
        ...state,
      };
    case actionTypes.GET_AIRPLANE_LIST.SUCCESS:
      return { ...state, airplaneList: action.payload, status: "success" };
    case actionTypes.GET_AIRPLANE_LIST.FAILURE:
      return {
        ...state,
      };

    // updated profile details
    case actionTypes.UPDATE_PROFILE_SETTINGS.REQUEST:
      return { ...state, status: "loading" };
    case actionTypes.UPDATE_PROFILE_SETTINGS.SUCCESS: {
      const { operatorDetail } = action.payload;
      const { user } = state;
      const userData = {
        name: operatorDetail?.name ?? user?.name,
        countryCode: operatorDetail?.countryCode ?? user?.countryCode,
        mobileNumber: operatorDetail?.mobileNumber ?? user?.mobileNumber,
        baseLocation: operatorDetail?.baseLocation ?? user?.baseLocation,
        city: operatorDetail?.city ?? user?.city,
        state: operatorDetail?.state ?? user?.state,
        country: operatorDetail?.country ?? user?.country,
        userId: operatorDetail?.userId ?? user?.userId,
        cfpMembership: operatorDetail?.cfpMembership ?? user?.cfpMembership,
        caaMembership: operatorDetail?.caaMembership ?? user?.caaMembership,
        email: action.payload.user?.email ?? user?.email,
      };
      return {
        ...state,
        user: userData,
        status: "success",
        baseLocation: operatorDetail?.baseLocation ?? user?.baseLocation,
      };
    }
    case actionTypes.UPDATE_PROFILE_SETTINGS.FAILURE:
      return { ...state, status: "failure" };

      // add aircraft from manage aircraft
    case actionTypes.ADD_AIRCRAFT.REQUEST:
    case actionTypes.ADD_AIRCRAFT.SUCCESS:
      return {
        ...state,
        addAircraft: action.payload,
        status: "success",
      };
    case actionTypes.ADD_AIRCRAFT.FAILURE:
      return {
        ...state,
        status: "failure",
      };
    // delete aircraft
    case actionTypes.DELETE_AIRCRAFT.REQUEST:
    case actionTypes.DELETE_AIRCRAFT.SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case actionTypes.DELETE_AIRCRAFT.FAILURE:
      break;
    // edit aircraft detail
    case actionTypes.EDIT_AIRCRAFT.REQUEST:
    case actionTypes.EDIT_AIRCRAFT.SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case actionTypes.EDIT_AIRCRAFT.FAILURE:
      break;
    // submit profile
    case actionTypes.SUBMIT_PROFILE.REQUEST:
    case actionTypes.SUBMIT_PROFILE.SUCCESS:
      return { ...state, status: "success" };
    case actionTypes.SUBMIT_PROFILE.FAILURE:
      break;
    // get aircraft List
    case actionTypes.GET_AIRCRAFT_LIST.REQUEST:
      return { ...state, status: "loading" };
    case actionTypes.GET_AIRCRAFT_LIST.SUCCESS: {
      return {
        ...state,
        aircraftList: action.payload.aircraftDetail,
        status: "success",
        tempAircraft: action.payload.tempAircraft,
        assignedAircraftList: action.payload.assignedAircraftDetail,
        aircraftStatus: true,
      };
    }
    case actionTypes.GET_AIRCRAFT_LIST.FAILURE:
      return {
        ...state,
        status: "failed",
        aircraftStatus: false,
      };

 // get aircraft List by invitee pilot
 case actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.REQUEST:
  return { ...state, status: "loading" };
case actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.SUCCESS: {
  return {
    ...state,
    aircraftList: action.payload.aircraftDetail,
    status: "success",
    tempAircraft: action.payload.tempAircraft,
    assignedAircraftList: action.payload.assignedAircraftDetail,
  };
}
case actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.FAILURE:
  return {
    ...state,
    status: "failed",
  };

    // get aircraft maker list
    case actionTypes.GET_AIRCRAFT_MAKER_LIST.REQUEST:
      return { ...state, status: "loading" };
    case actionTypes.GET_AIRCRAFT_MAKER_LIST.SUCCESS:
      return {
        ...state,
        status: "success",
        aircraftMakerList: action.payload,
      };
    case actionTypes.GET_AIRCRAFT_MAKER_LIST.FAILURE:
      break;


    // get assigned pilot aircraft list
    case actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.REQUEST:
      return { ...state, status: "loading" };
    case actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.SUCCESS:
      return {
        ...state,
        status: "success",
        assignedPilotListForAircraft: action.payload,
      };
    case actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.FAILURE:
      return { ...state, status: "failed" };

    // get data from selected aircraft type
    case actionTypes.SET_USER_SELECTED_AIRCRAFT: {
      return {
        ...state,
        userSelectedAircraft: action.payload?.item || {},
      };
    }

    // custom aircraft
    case actionTypes.ADD_CUSTOM_AIRCRAFT.REQUEST:
      return { ...state, status: "loading" };
    case actionTypes.ADD_CUSTOM_AIRCRAFT.SUCCESS:
      return {
        ...state,
        addCustomAircraftList: action.payload,
        status: "success",
      };
    case actionTypes.ADD_CUSTOM_AIRCRAFT.FAILURE:
      break;

    // Manage Contacts
    case actionTypes.ADD_OPERATOR_MANAGER.REQUEST:
    case actionTypes.ADD_OPERATOR_MANAGER.SUCCESS:
      return {
        ...state,
        addOperatorManagerList: action.payload,
        status: "success",
      };
    case actionTypes.ADD_OPERATOR_MANAGER.FAILURE:
      break;

    // additional Manage Contacts
    case actionTypes.ADD_ADDITIONAL_MANAGER.REQUEST:
    case actionTypes.ADD_ADDITIONAL_MANAGER.SUCCESS:
      return {
        ...state,
        addAdditionalManagerList: action.payload,
        status: "success",
      };
    case actionTypes.ADD_ADDITIONAL_MANAGER.FAILURE:
      break;

    // additional Manage Contacts
    case actionTypes.SUBMIT_MANAGER.REQUEST:
    case actionTypes.SUBMIT_MANAGER.SUCCESS:
      return {
        ...state,
        // addAdditionalManagerList: action.payload,
        status: "success",
      };
    case actionTypes.SUBMIT_MANAGER.FAILURE:
      break;

    // delete manager
    case actionTypes.DELETE_MANAGER.REQUEST:
    case actionTypes.DELETE_MANAGER.SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case actionTypes.DELETE_MANAGER.FAILURE:
      break;

    // edit manager
    case actionTypes.EDIT_MANAGER.REQUEST:
    case actionTypes.EDIT_MANAGER.SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case actionTypes.EDIT_MANAGER.FAILURE:
      break;

    //clear user selected aircraft
    case actionTypes.CLEAR_USER_SELECTED_AIRCRAFT:
      return {
        ...state,
        userSelectedAircraft: {},
      };
    case actionTypes.CLEAR_ASSIGNED_AIRCRAFT:
      return {
        ...state,
        assignedAircraftList: [],
      };

    case actionTypes.ADVANCED_SEARCH:
      return {
        ...state,
        advanceSearch: action.payload,
      };

    case actionTypes.SLIDER_VALUE:
      return {
        ...state,
        slidervalue: action.payload,
      };

    // edit manager
    case actionTypes.CONTACT_ADMIN.REQUEST:
    case actionTypes.CONTACT_ADMIN.SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case actionTypes.CONTACT_ADMIN.FAILURE:
      break;

    // paragon
    case actionTypes.IS_PARAGON:
      return {
        ...state,
        isParagonUser: action.payload,
      };

    case actionTypes.IS_PARAGON_USER:
      return {
        ...state,
        isParagon: "",
      };

    case actionTypes.SEARCH_HISTORY:
      return {
        ...state,
        searchHistoryList: action.payload,
      };

    case actionTypes.USER_BASE_LOCATION:
      return {
        ...state,
        userBaseLocation: action.payload
      }

    default:
      return state;
  }
};
