import React, { useEffect, useState } from "react";
import { Grid, Modal } from "@material-ui/core";
import { useSelector } from "react-redux";

import Heading from "../Heading";
import HeadingText from "../HeadingText";
import { useStyles } from "../../../../assets/styles/ContactStyles";
import addUserContacts from "../../../../assets/images/addUserContacts.png";
import AddUsers from "../Contacts/AddUserDetail";
import BottomButton from "../BottomButton";
import SecondaryUser from "./SecondaryUser";
import { useDispatch } from "react-redux";
import { usersList, timeline } from "../../../../redux/signup/signup.actions";
import { useHistory } from "react-router-dom";
import * as services from "../../../../services/mainService";
import { toast } from "react-toastify";
import DeleteModal from "../DeleteModal";
import colors from "../../../../constants/colors";

let userArray = [];
let deleteIndex = "";

const AddPrimaryContacts = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { signup, account, auth } = useSelector((state) => ({
    signup: state.signup,
    account: state.account,
    auth: state.auth
  }));

  const [editListItem, setEditListItem] = useState({});
  const [primaryContactDetail, setAddPrimaryContactDetail] = useState([]);
  const [addAdditionalUsers, setAddAdditionalUsers] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const addAdditionalUser = () => {
    setAddAdditionalUsers(true);
    setEditListItem([]);
  };

  useEffect(() => {
    signup.contactList.length > 0 && setEditListItem([]);
    setAddPrimaryContactDetail(signup.contactList);
  }, []);

  const addNewUser = (newUsers) => {
    setAddAdditionalUsers(false);
    if (editListItem.length > 0 || Object.values(editListItem).length > 0) {
      let list = [...primaryContactDetail];
      let index1 = list.findIndex((it, index) => editIndex === index);
      list[index1] = newUsers;
      userArray = list;
    } else {
      userArray = signup.contactList;
      userArray.push(newUsers);
    }
    setAddPrimaryContactDetail(userArray);
    dispatch(usersList(userArray));
    setEditListItem([]);
    setEditIndex("");
  };

  const deleteItem = (item, index) => {
    deleteIndex = index;
    setOpenDeletePopup(true);
  };

  const handleDelete = () => {
    let list = [];
    setEditListItem([]);
    setEditIndex("");
    list = primaryContactDetail.filter((e, ind) => {
      return deleteIndex !== ind;
    });
    if (list.length > 0) {
      let data = list;
      setAddPrimaryContactDetail(data);
      dispatch(usersList(data));
    } else if (list.length === 0) {
      setAddPrimaryContactDetail([]);
    }
    deleteIndex = "";
    setOpenDeletePopup(false);
  };

  const editItem = (item, index) => {
    let list1 = [...primaryContactDetail];
    let index1 = list1.findIndex((it, ind) => ind === index);
    setEditIndex(index1);
    if (addAdditionalUsers === true) {
      setAddAdditionalUsers(false);
    }
    setEditListItem(item);
  };

  useEffect(() => {
    setEditListItem([]);
    setAddPrimaryContactDetail(signup.contactList);
  }, [signup, primaryContactDetail]);

  const cancelUser = () => {
    setEditListItem([]);
    setAddAdditionalUsers(false);
  };

  const handleCloseModal = () => {
    setOpenDeletePopup(false);
  };

  const onClickNextButton = async () => {
    setIsLoading(true);
    let newArray = JSON.parse(JSON.stringify(primaryContactDetail));
    newArray = newArray.map(function (item) {
      delete item.contactTitle;
      return item;
    });
    const detail = {
      stepNumber: 2,
      contacts: newArray,
    };
    const res = await services.signUpUrl(detail);
    setIsLoading(false);
    if (res.response_type === "success") {
      if (signup.registerAs === 2) {
      }
      dispatch(
        timeline({
          ...signup.timelineSteps,
          userDetails: { ...signup.timelineSteps.userDetails, selected: true },
          aircraftDetails: {
            ...signup.timelineSteps.aircraftDetails,
            isActive: true,
          },
        })
      );
      history.push("/signup/aircrafts");
    } else {
      toast.error(res.response.message, {
        position: "bottom-center",
        closeButton: false,
        style: {
          color: "white",
          backgroundColor: account.isParagonUser || auth.isIframe ? colors.paragonPrimaryColor : colors.primaryColor
        }
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container item xs={12} className={classes.firstCont}>
          <Grid item xs={12}>
            <Heading heading="Users" />
            <HeadingText
              navigation="contacts"
              text="These are the users that can login into this account. Additional users can be added here. These users will receive emails pertaining to arrival and fuel reservations."
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.secondCont}>
        <Grid container>
          <Grid item xs={11} style={{ height: "calc(100vh - 236px - 50px)" }}>
            {signup.contactList.length === 0 ? (
              <AddUsers
                addNewUser={addNewUser}
                cancelUser={cancelUser}
                title="Primary User"
              />
            ) : (
              <SecondaryUser
                primaryContactDetails={primaryContactDetail}
                addNewUser={addNewUser}
                cancelUser={cancelUser}
                editIndex={editIndex}
                editListItem={editListItem}
                deleteItem={deleteItem}
                editItem={editItem}
                addAdditionalUsers={addAdditionalUsers}
              />
            )}
          </Grid>
          {signup.contactList.length > 0 ? (
            <Grid item xs={1} className={classes.imageCont}>
              <img alt=""
                src={addUserContacts}
                onClick={addAdditionalUsers === true ||
                  editListItem.length > 0 ||
                  Object.values(editListItem).length > 0 ? null : addAdditionalUser}
                className={
                  addAdditionalUsers === true ||
                    editListItem.length > 0 ||
                    Object.values(editListItem).length > 0
                    ? classes.imageDisableStyle
                    : classes.imageStyle
                }
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container item xs={12} className={classes.thirdCont}>
          <BottomButton
            disabled={
              primaryContactDetail.length === 0 ||
              editListItem.length > 0 ||
              Object.values(editListItem).length > 0 ||
              isLoading
            }
            isSubmitting={isLoading}
            onNextClick={onClickNextButton}
            onBackClick={() => history.push("/signup/profileSetup")}
          />
        </Grid>
      </Grid>

      <Modal
        open={openDeletePopup}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DeleteModal
          openDeletePopup={openDeletePopup}
          handleCloseModal={handleCloseModal}
          handleDelete={handleDelete}
        />
      </Modal>
    </Grid>
  );
};

export default AddPrimaryContacts;
