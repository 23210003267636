import * as Yup from "yup";

export default Yup.object().shape({
  aircraftTail: Yup.string().matches(
    /^[a-zA-Z 0-9-]*$/,
    "Invalid input"
  ).min(2,"Minimum 2 characters required").max(7, "Maximum 7 characters allowed").required("Aircraft Tail is required"),
  manufacturerName: Yup.string().required("Manufacturer is required"),
  model: Yup.string().when("manufacturerName", {
    is: (manufacturerName) => manufacturerName !== 'Other',
    then: Yup.string().matches(
      /^[a-zA-Z 0-9-@.,'&/#-+_]*$/,
      "Invalid input"
    ).required("Model is required")
      .min(2,'Minimum 2 characters required')
  }),
  otherMakerName: Yup.string().when("manufacturerName", {
    is: (manufacturerName) => manufacturerName === 'Other',
    then: Yup.string().matches(
      /^[a-zA-Z 0-9-@.,'&/#-+_]*$/,
      "Invalid input"
    ).required("Manufacturer Name is required")
      .min(2,'Minimum 2 characters required')
  }),
  aircraftName: Yup.string().when("manufacturerName", {
    is: (manufacturerName) => manufacturerName === 'Other',
    then: Yup.string().required("Aircraft Name is required").min(2,'Minimum 2 characters required')
  }).when("model", {
    is: (model) => model === 'Other',
    then: Yup.string().required("Aircraft Name is required")
      .min(2,'Minimum 2 characters required')
  }),
  fuelType: Yup.string().when("manufacturerName", {
    is: (manufacturerName) => manufacturerName === 'Other',
    then: Yup.string().required("Fuel Type is required")
  }).when("model", {
    is: (model) => model === 'Other',
    then: Yup.string().required("Fuel Type is required")
  }),
});
