import axios from "axios";

export const getTimezoneByLatLong = async (location) => {
  try {
    let result = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${location.latitude},${location.longitude}&timestamp=1331161200&key=AIzaSyAlHpWIMqHXMAji1Kjp2c7AkjGViBwhLFg`
    );
    if (result && result?.data?.status === "OK") {
      return result.data.timeZoneId;
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};
