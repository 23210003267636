import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headingCont: {
    height: 32,
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    marginTop: 12,
    color: "#333",
  },
  headingTextStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: "24px",
    color: "#666666",
  },
  inputFieldStyle: {
    width: "100%",
    marginTop: 14,
  },
  firstCont: {
    marginTop: 40,
    height: 80,
  },
  firstPilotCont: {
    height: 80,
  },
  secondCont: {
    marginTop: 36,
    height: "calc(100vh - 236px)",
  },
  secondPilotCont: {
    marginTop: 20,
    height: "calc(100vh - 280px)",
  },
  secondInnerCont: {
    height: "356px",
    width: 520,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 5,
    border: "1px solid #e8e8e8",
  },
  iframeSecondInnerCont: {
    width: "100%",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 5,
    border: "1px solid #e8e8e8",
    marginBottom: 20,
  },
  secondContainer: {
    height: "356px",
    width: "410px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  thirdCont: {
    height: 50,
    width: '100%',
    display:'flex',
    marginTop: 32,
    flexDirection:'column',
    justifyContent:'flex-end',
  },
  imageCont: {
    display: "flex",
    justifyContent: "flex-end",
  },
  imageStyle: {
    width: 50,
    height: 50,
    cursor: "pointer",
    marginTop: 94,
  },
  imageDisableStyle: {
    width: 50,
    height: 50,
    opacity: 0.4,
    cursor: "not-allowed",
    marginTop: 94,
  },
  lastnameCont: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  notchedOutline: {
    padding: 5,
    borderColor: "#ddd !important",
    borderWidth: "2px",
    boxSizing: "border box",
    borderRadius: 5,
    color: "#666",
    hintColor: "#666",
  },
  headingStyle: {
    height: 24,
    padding: 3,
    margin: "0 0 5px 0",
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 16,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
  },
  buttonCont: {
    width: "100%",
    height: 40,
    flexGrow: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    fontSize: 14,
    fontWeight: "500",
    cursor: "pointer",
    borderRadius: 5,
    background: "linear-gradient(to bottom, #666 0%, #333 100%)",
    color: "#fff",
    textTransform: "initial",
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.4,
      background: "linear-gradient(to bottom, #666 0%, #333 100%)",
      color: "#fff",
    },
  },
  buttonCont1: {
    width: "48%",
  },
  buttonCont2: {
    width: "100%",
  },
  formControl: {
    width: "100%",
    height: 40,
  },
  selectEmpty: {},
  selectBoxStyle: {
    width: "100%",
  },
  inputLabel: {},
  rootCont: {
    width: "100%",
    height: 40,
    background: "white",
    border: "1px solid #000 !important",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04))",
    borderRadius: 5,
  },
  menuButtonStyle: {
    marginLeft: 5,
    width: "100%",
    height: 50,
    display: "flex",
    paddingRight: 10,
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "normal",
    cursor: "pointer",
  },
  root: {
    width: "100%",
    height: 50,
    background: "white",
    border: "1px solid #000 !important",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04))",
    borderRadius: 5,
  },
  itemStyle: {
    textTransform: "lowercase",
    "& > :first-letter": {},
  },
  aircraftStyle: {
    color: "black",
    textTransform: "capitalize",
  },
  paper: {
    textTransform: "capitalize",
  },
  backButtonText: {
    width: "100%",
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    cursor: "pointer",
    backgroundColor: "#ebecf0",
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#212121",
    textTransform: "initial",
  },
  iframeBackButtonText: {
    width: "48%",
  },
  errorMsg: {
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Arial",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    marginLeft: "14px",
    marginRight: "14px",
    color: "#f44336",
  },
  dialogCont: {
    width: 553,
    height: 219,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 0",
    alignItems: "center",
    gap: "20px",
    borderRadius: 10,
    border: "none",
    outline: "none",
    boxShadow:
      "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  iframeDialogCont: {
    width: 300,
    height: 175,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 0",
    alignItems: "center",
    gap: "20px",
    borderRadius: 10,
    border: "none",
    outline: "none",
    boxShadow:
      "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  deleteUserStyle: {
    width: 27.5,
    height: 27,
    flexGrow: 0,
    objectFit: "contain",
  },
  iframeDeleteUserStyle: {
    width: 20,
    height: 20,
    flexGrow: 0,
    objectFit: "contain",
  },
  deleteText: {
    width: 356,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 24,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#333",
  },
  iframeDeleteText: {
    width: 234,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 16,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#333",
  },
  deleteText2: {
    width: "100%",
    height: 20,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#999",
  },
  iframeDeleteText2: {
    width: "100%",
    height: 20,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#999",
  },
  buttonContainer2: {
    width: "100%",
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonContainer3: {
    justifyContent: "flex-start",
  },
  cancelButton: {
    width: 116,
    height: 40,
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    border: "solid 1px #dfe5e9",
    backgroundColor: "#fff",
  },
  iframeCancelButton: {
    width: 90,
    height: 35,
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    border: "solid 1px #dfe5e9",
    backgroundColor: "#fff",
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  deleteCont: {
    color: "#fff",
    background: "linear-gradient(to bottom, #666 0%, #333 100%)",
    backgroundColor: "linear-gradient(to bottom, #666 0%, #333 100%)",
  },
  iframeDeleteCont: {
    color: "#fff",
    background: "#ae0061",
    "&:hover": {
      backgroundColor: '#ae0061',
    },
  },
  disableButtonCont: {
    opacity: 0.5,
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
}));

export { useStyles };
