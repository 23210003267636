import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650
  },
  tableRow: {
    height: 30,
    background:'red'
  },
  tableCell: {
    height:70,
    padding: "0px 16px"
  },
  pilotName: {
    fontSize: 18,
    color: "#000",
    fontWeight: "500",
  },
  imageIconStyle: {
    maxWidth: 40,
    maxHeight: 40,
  },
  header: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "item",
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "space-between",
  },
  items: {
    display: "flex",
    flexDirection: "item",
    borderBottom: "#000",
    cursor: "pointer",
  },
  button: {
    width: 300,
  },
  button2: {
    width: "400px !important",
    marginLeft: 100,
    marginRight: 100,
  },
  appMain: {},
  submitBtn: {
    background: colors.primaryColor,
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  marginTop: {
    marginTop: 2,
  },
  primaryTableRow: {
    background: "grey",
    height: 30,
  },
  cellStyle: {
    width: 60,
    background: "blue",
  },
  headingStyle: {
    outline: "none",
    background: "#fafafa",
    color: "#ccc",
    fontWeight: "500",
    fontSize: 18,
    padding: 15,
    boxShadow: 'none',
    border: "0px solid transparent",
  },
  toggle: {
    width:50,
   '& .Mui-checked': {
      color: '#109125',
      transform:'translateX(25px) !important'
  },
  '& .MuiSwitch-track': {
      backgroundColor:'#008000e0'
  }
},
}));

export { useStyles };
