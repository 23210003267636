import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles, Grid } from "@material-ui/core";
import TextField from "./SignupTextInput";
import { oneSpace, textString } from "../../ui/TextTransform";
import { useSelector } from "react-redux";
import "./phoneInput.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./BasicDetails.css";
import CorporateAddress from "../../../containers/Profile/CorporateAddress";

const useStyles = makeStyles(() => ({
  secondCont: {
    marginTop: 40,
  },
  notchedOutline: {
    padding: 5,
    borderColor: "#ddd !important",
    borderWidth: "2px",
    boxSizing: "border box",
    borderRadius: 5,
    color: "#666",
    hintColor: "#666",
  },
  basicDetailText: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    height: 20,
    fontSize: 16,
    color: "#333333",
  },
  inputFieldStyle: {
    marginTop: 28,
  },
  buttonCont: {
    width: "100%",
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
  },
  firstNameStyle: {
    width: 332,
    height: 40,
  },
  phoneInputCont: {
    border: "none",
    height: 40,
    color: "#666",
    width: "100%",
    borderRadius: 5,
  },
  margin: {
    height: 40,
  },
  phonenNumberStyle: {
    width: "100%",
    height: 40,
  },
  errorMsg: {
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Arial",
    fontWeight: 400,
    lineHeight: "1.66px",
    letterSpacing: "0.03333em",
    marginLeft: "14px",
    marginRight: "14px",
    color: "#f44336",
  },
}));

const BasicDetails = (props) => {
  const classes = useStyles();
  const { onChange, onBlur, errors, touched, onTouch } = props;
  const { signup } = useSelector((state) => ({
    signup: state.signup,
  }));
  const [fullName, setFullName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [, setCountryCode] = useState("+1");
  const [corporateAdd, setCorporateAdd] = useState("");
  const [, setCity] = useState("");
  const [, setState] = useState("");
  const [, setAddLat] = useState("");
  const [, setAddLng] = useState("");
  const [, setCountry] = useState("");

  useEffect(() => {
    if (Object.values(signup.basicDetails).length > 0) {
      setFullName(signup.basicDetails.name);
      setCountryCode(signup.basicDetails.countryCode);
      setPhoneNo(signup.basicDetails.mobileNumber);
      setCorporateAdd(signup.basicDetails.baseLocation);
      setAddLat(signup.basicDetails.addLat);
      setAddLng(signup.basicDetails.addLng);
      setCity(signup.basicDetails.city);
      setState(signup.basicDetails.state);
      setCountry(signup.basicDetails.country);
    }
  }, []);

  const getAddress = (address) => {
    if (address === null || address === "" || address === undefined) {
      setCorporateAdd("");
      props.corporateAddValue("");
    } else {
      setCorporateAdd(address);
      props.corporateAddValue(address);
    }
  };

  const setCorporateAddress = (address) => {
    if (address === null || address === "" || address === undefined) {
      setCorporateAdd("");
      props.corporateAddValue("");
    } else {
      setCorporateAdd(address);
    }
  };
  
  const baseLocationLatLong = (baseLocationLatLong) => {
    setAddLat(baseLocationLatLong.lat);
    setAddLng(baseLocationLatLong.lng);
    axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          parseFloat(baseLocationLatLong.lat).toFixed(5) +
          "," +
          parseFloat(baseLocationLatLong.lng).toFixed(5) +
          "&key=AIzaSyClgWmN0aJIplOG6yEL9g1sjxVPq8IytQo"
      )
      .then((response) => {
        const res = response.data.results;
        let data = res[res.length - 4].address_components;
        setCity(data[0].long_name);
        setState(data[1].long_name);
        setCountry(data[2].long_name);
        props.getLatLong(
          baseLocationLatLong.lat,
          baseLocationLatLong.lng,
          data[0].long_name,
          data[1].long_name,
          data[2].long_name
        );
      })
      .catch(function (error) {});
  };

  return (
    <Grid item xs={12}>
      <Grid container item xs={12} className={classes.basicDetailText}>
        Basic Details
      </Grid>
      <Grid
        container
        item
        xs={8}
        spacing={3}
        className={classes.inputFieldStyle}
      >
        <Grid item xs={6}>
          <TextField
            id="name"
            name="name"
            fullWidth
            label={
              signup.registerAs === 2
                ? "Flight Department Name*"
                : signup.registerAs === 4
                ? "Pilot Name*"
                : "Full Name*"
            }
            onChange={(e) => {
              if (
                e.target.value.match("^(?!\\s)[a-zA-Z ,0-9_@.'&#-]*$") !==
                    null
              ) {
                onChange(e);
                setFullName(textString(e.target.value.split('').filter(oneSpace).join('')));
                props.enterFullNameValue(e.target.value.split('').filter(oneSpace).join(''));
              }
            }}
            onBlur={onBlur}
            value={fullName}
            error={touched.name && errors.name ? errors.name : ""}
          />
        </Grid>
        <Grid item xs={6}>
          <div>
            <PhoneInput
              country={"us"}
              id="mobileNumber"
              value={phoneNo}
              enableSearch={true}
              withDarkTheme={true}
              withShadow
              autoFormat={true}
              enableAreaCodes={false}
              disableSearchIcon={true}
              inputStyle={{
                height: 40,
                width: "100%",
                border: "none",
                color: "#000",
              }}
              containerStyle={{
                width: "100%",
                border: "2px solid #ddd",
                height: 40,
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
              }}
              buttonStyle={{
                color: "black",
                border: "none",
              }}
              placeholder="(750) 777-7777"
              label="Phone Number"
              className={classes.margin}
              searchStyle={{ width: "100%", margin: "auto" }}
              onChange={(phone, data) => {
                setPhoneNo(phone);
                onChange("mobileNumber")(phone);
                setCountryCode(`+${data.dialCode}`);
                props.enterPhoneNoValue(`+${data.dialCode}`, phone);
              }}
              onBlur={() => onTouch({ ...touched, mobileNumber: true })}
            />
            {touched.mobileNumber && errors.mobileNumber && (
              <span className={classes.errorMsg}>
                {errors.mobileNumber || ""}
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <CorporateAddress
            navigation="signup"
            baseLocationLatLong={baseLocationLatLong}
            address={getAddress}
            setCorporateAddress={setCorporateAddress}
            baseLocation={corporateAdd}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicDetails;
