import * as actionTypes from "./signup.actionTypes";

let operatorTimeline = {
  profilesetup: {
    stepIndex: 1,
    name: "Profile",
    selected: false,
    isActive: true,
  },
  userDetails: {
    stepIndex: 2,
    name: "Users",
    selected: false,
    isActive: false,
  },
  aircraftDetails: {
    stepIndex: 3,
    name: "Aircraft",
    selected: false,
    isActive: false,
  },
  verifyDetails: {
    stepIndex: 4,
    name: "Verify Details",
    selected: false,
    isActive: false,
  },
};

let pilotTimeline = {
  profilesetup: {
    stepIndex: 1,
    name: "Profile",
    selected: false,
    isActive: true,
  },
  aircraftDetails: {
    stepIndex: 2,
    name: "Aircraft",
    selected: false,
    isActive: false,
  },
  verifyDetails: {
    stepIndex: 3,
    name: "Verify Details",
    selected: false,
    isActive: false,
  },
};

const initialState = {
  registerAs: "",
  basicDetails: {},
  contactList: [],
  aircraftList: [],
  aircraftPayloadList: [],
  signupVerify: [],
  timelineSteps: operatorTimeline,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USERS_LIST: {
      return {
        ...state,
        contactList: action.payload,
      };
    }

    case actionTypes.BASIC_DETAILS: {
      return {
        ...state,
        basicDetails: action.payload,
      };
    }

    case actionTypes.REGISTER_AS: {
      let timeline = {};
      let basicDetailArray = {};
      let contactListArray = [];
      let aircraftListArray = [];
      let signupVerifyArray = [];
      if (action.payload === state.registerAs && state.registerAs) {
        timeline = state.timelineSteps;
        basicDetailArray = state.basicDetails;
        contactListArray = state.contactList;
        aircraftListArray = state.aircraftList;
        signupVerifyArray = state.signupVerify;
      } else {
        timeline = action.payload === 2 ? operatorTimeline : pilotTimeline;
        basicDetailArray = {};
        contactListArray = [];
        aircraftListArray = [];
        signupVerifyArray = [];
      }
      return {
        ...state,
        registerAs: action.payload,
        timelineSteps: timeline,
        basicDetails: basicDetailArray,
        aircraftList: aircraftListArray,
        contactList: contactListArray,
        signupVerify: signupVerifyArray
      };
    }

    case actionTypes.TIMELINE: {
      return {
        ...state,
        timelineSteps: action.payload,
      };
    }

    case actionTypes.AIRCRAFTS_LIST: {
      return {
        ...state,
        aircraftList: action.payload,
      };
    }

    default:
      return state;
  }
};
