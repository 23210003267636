import * as actionTypes from "./notifications.action-types";
import { LOG_OUT } from "../../redux/auth/auth.actionTypes";

const initialState = {
  status: "pending",
  notifications: [],
  notificationDetail: {},
  notificationsMetaData: {},
  notificationReadResponse: {},
  nudgeDetail: {},
  unreadNotification: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.GET_UNREAD_NOTIFICATION_COUNT.REQUEST:
      return {
        ...state,
        status: "loading",
      };

    case actionTypes.GET_NOTIFICATION_DETAIL.REQUEST:
      return { ...state, status: "loading" };

    case actionTypes.GET_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        notifications: action?.payload[0]?.data,
        notificationsMetaData: action?.payload[0]?.metaData,
        status: "success",
      }
      
    case actionTypes.GET_UNREAD_NOTIFICATION_COUNT.SUCCESS:
      return {
        ...state,
        unreadNotification: action?.payload?.unreadNotificationCount,
        status: "success",
      };
    case actionTypes.GET_NOTIFICATION_DETAIL.SUCCESS:
      return {
        ...state,
        notificationDetail: action?.payload ?? {},
        status: "success",
      };

    case actionTypes.MARK_NOTIFICATION_READ.SUCCESS:
      return {
        ...state,
        notificationReadResponse: action.payload,
      };

    case actionTypes.CLEAR_NOTIFICATION_DETAIL_RESPONSE:
      return {
        ...state,
        notificationDetail: {},
      };

    case actionTypes.CLEAR_NOTIFICATION_READ_RESPONSE:
      return {
        ...state,
        notificationReadResponse: {},
      };

    case actionTypes.GET_NOTIFICATIONS.FAILURE:
    case actionTypes.GET_NOTIFICATION_DETAIL.FAILURE:
      return { ...state, status: "failed" };

    case actionTypes.GET_UNREAD_NOTIFICATION_COUNT.FAILURE:
      return {
        ...state,
        status: "failed",
      };

    case LOG_OUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
