import { useStyles } from "../../../assets/styles/NotificationItemStyles";
import moment from "moment";
import React from "react";
import { Grid } from "@material-ui/core";

import colors from "../../../constants/colors";
import NotificationListItem from "../../ui/Notification/NotificationListItem";

const NotificationItem = (props) => {
  const { notifications = [], onItemPress } = props;
  const classes = useStyles(colors);

  const RenderData = (item, index) => {
    const date = moment(item.createdAt).format("MMM/DD/yyyy HH:mm");
    return (
      <Grid container style={{ cursor: "pointer", display: 'flex', background: item.isRead ? "#fbfbfb" : 'rgba(1, 66, 106, 0.05)', margin: '2px 0 2px', }}
        key={`${index}-notify-item-wrap`}
        onClick={() => onItemPress(item, index)}
      >
        <Grid item xs={12} className={classes.listContainer}>
          <div className={item.isRead === false ? classes.notifyCont : classes.unreadNotifyCont}></div>
          <Grid container style={{ width: '100%' }}>
            <Grid item xs={12} className={classes.detailContainer}>
              <Grid container className={classes.topContainer} style={{ marginBottom: item.data !== undefined && item.data.reply !== undefined ? 5 : 10 }}>
                <div style={{ fontWeight: item.isRead === true ? '300' : '600', marginTop: 5 }}
                  className={classes.messageTextStyle}>
                  {item.message}
                </div>
              </Grid>
              <Grid container> {item.data !== undefined && item.data.reply !== undefined && (
                <div className={classes.replyContainer}>
                  <div className={classes.code}>
                    {item.data.reply}
                  </div>
                </div>
              )}
              </Grid>
              <Grid container style={{ marginBottom: 5 }}>
                <Grid item xs={12} className={classes.bottomCont}>
                  <div className={classes.bottomContainer}>
                    <div className={classes.datetextStyle}>{` ${date}`}</div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} id="scrollableDiv" className={classes.mainContainer} style={{ display: "flexWrap", overflowY: 'auto' }}>
        <NotificationListItem
          keyExtractor={(item, index) => item.title + index}
          renderSectionHeader={(item) => (
            <Grid container spacing={0} className={classes.headingTextStyle}>{item.title}</Grid>
          )}
          renderItem={(item, index) => {
            return (
              <Grid container spacing={0} >{RenderData(item, index)}</Grid>
            );
          }}
          sections={notifications}
        />
      </Grid>
    </Grid>
  );
};

export default NotificationItem;
