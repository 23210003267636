import { createAction } from "../utils";
import * as actionTypes from "./requests.action-types";

// history list
export const getRequests = (payload) =>
  createAction(actionTypes.GET_REQUESTS.REQUEST, payload);

export const clearFuelRequestResponse = () =>
  createAction(actionTypes.CLEAR_ADD_FUEL_REQUEST_RESPONSE, {});

export const cancelRequest = (request) =>
  createAction(actionTypes.CANCEL_REQUEST.REQUEST, request);

export const sendFeedback = (feedback) =>
  createAction(actionTypes.SEND_FEEDBACK.REQUEST, feedback);
