import React from "react";
import { Grid, Button } from "@material-ui/core";

import { useStyles } from "../../../assets/styles/ContactStyles";
import deleteAlert from "../../../assets/images/deleteAlert.png";

const DeleteModal = (props) => {
  const { handleCloseModal = {}, handleDelete = {} } = props;
  const classes = useStyles();
  return (
    <Grid className={classes.dialogCont}>
        <img
          src={deleteAlert}
          alt="delete"
          className={classes.deleteUserStyle}
        />
      <Grid className={classes.deleteText}>
        Are you sure you want to delete?
      </Grid>
      <Grid className={classes.deleteText2}>You can't undo this action.</Grid>

      <Grid container spacing={2} className={classes.buttonStyle}>
        <Grid item xs={6} className={classes.buttonContainer2}>
          <Button className={classes.cancelButton} onClick={handleCloseModal}>
            Cancel
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          className={`${classes.buttonContainer2} ${classes.buttonContainer3}`}
        >
          <Button
            className={`${classes.cancelButton} ${classes.deleteCont}`}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeleteModal;
