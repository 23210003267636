import Grid from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";

import Layout from '../Layout';

const StyledContainer = styled.div`
    margin: 0 auto;
    padding: 0;
    display: flex;
    overflow: auto;
    width: 100%;
`;

const StyledGridMainContainer = styled(Grid)`
    padding: 0px;
    margin: 0px;
    width: calc(100% - 240px);
`;

const ChildrenDiv = styled(Grid)`
    margin-top: 55px;
    padding-left: 0 !important;
`;

const AppLayout = (props) => {
    return (
        <StyledContainer className="rootContainer">
            <Grid style={{width: '240px'}}>
            <Layout/>
            </Grid>
            <StyledGridMainContainer container={true} spacing={0} className="mainContainer">
                <ChildrenDiv item={true} xs={12} className="contentContainer">
                    {props.children}
                </ChildrenDiv>
            </StyledGridMainContainer>
        </StyledContainer>
    )
}

export default AppLayout;