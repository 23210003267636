import React, { useState, useEffect, useRef } from "react";
import algoliasearch from "algoliasearch";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidationTextField } from "./AddAircraftForm";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import {
  IFrameSignUpTextField,
  ValidationSignUpTextField,
} from "../../../assets/styles/SignUpManufacturerStyles";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_APPLICATION_KEY
);

const searchIndex = searchClient.initIndex(
  process.env.REACT_APP_ALGOLIA_INIT_INDEX
);

const BaseLocation = (props) => {
  const [searchFor] = useState("");
  const [currentLatLong] = useState({
    latitude: 37.0902,
    longitude: -95.7128,
  });
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = useState(null);
  const [searchList, setSearchList] = useState([]);
  const algoliaClientRef = useRef(null);
  const [code, setCode] = useState("");
  const [isSetInitial, setIsSetInitial] = useState(true);

  useEffect(() => {
    setCode(
      props.icaoCode !== undefined || props.icaoCode !== ""
        ? props.icaoCode
        : ""
    );
  }, [props.icaoCode, props.searchResult]);

  const filterList = (value) => {
    let arrayList = [];
    value.map((item) => {
      var pattern = new RegExp(/^[A-Za-z]+$/);
      if (pattern.test(item.icao) === true) {
        arrayList.push(item);
      }
    });
    setSearchList(arrayList);
  };

  useEffect(() => {
    if (isSetInitial && props.icaoCode) {
      onSearch(props.icaoCode?.toUpperCase() || "");
    }
  }, [props.icaoCode]);

  useEffect(() => {
    if (isSetInitial) {
      if (props.icaoCode) {
        setValue(
          searchList.find(
            (e) => e.icao?.toUpperCase() === props.icaoCode?.toUpperCase()
          )
        );
        setInputValue(props.icaoCode?.toUpperCase() || "");
        setIsSetInitial(false);
      }
    }
  }, [searchList]);

  const onSearch = (inputValue) => {
    if (inputValue !== null) {
      searchIndex
        .search(inputValue, { hitsPerPage: 10 })
        .then((response) => {
          filterList(response.hits);
        })
        .catch((err) => {});
    }
  };

  const addBaseLocation = (values) => {
    props.address(values);
  };

  const filterOptions = createFilterOptions({ ignoreCase: false });

  return (
    <div style={{ width: "100%" }}>
      <InstantSearch
        ref={algoliaClientRef}
        searchClient={searchClient}
        indexName="dev_airports"
        searchState={{ query: searchFor || null }}
      >
        <Configure
          aroundLatLng={`${currentLatLong.latitude},${currentLatLong.longitude}`}
          aroundRadius={160934}
        />
        <Autocomplete
          size={
            props.navigation === "signup" || props.navigation === "iframeSignup"
              ? "small"
              : "normal"
          }
          options={searchList}
          value={value}
          inputValue={inputValue}
          filterOptions={filterOptions}
          getOptionLabel={(option) =>
            option.icao !== undefined
              ? option.icao !== null
                ? option.icao
                : ""
              : ""
          }
          renderInput={(params) =>
            props.navigation === "iframeSignup" ? (
              <IFrameSignUpTextField
                {...params}
                InputLabelProps={code !== "" ? { shrink: true } : undefined}
                label="ICAO Code"
                variant="outlined"
                fullWidth
                style={{ textTransform: "capitalize" }}
              />
            ) : props.navigation === "signup" ? (
              <ValidationSignUpTextField
                {...params}
                InputLabelProps={code !== "" ? { shrink: true } : undefined}
                label="ICAO Code"
                variant="outlined"
                fullWidth
                style={{ textTransform: "capitalize" }}
              />
            ) : (
              <ValidationTextField
                {...params}
                label="ICAO Code"
                InputLabelProps={code !== "" ? { shrink: true } : undefined}
                placeholder={code !== "" ? props.icaoCode : "ICAO Code"}
                variant="outlined"
                fullWidth
                style={{ textTransform: "capitalize" }}
              />
            )
          }
          onChange={(event, newValue) => {
            setValue(newValue);
            addBaseLocation(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue.toUpperCase());
            onSearch(newInputValue);
          }}
        />
      </InstantSearch>
    </div>
  );
};

export default BaseLocation;
