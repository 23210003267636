import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CardContent, Button, TextField } from "@material-ui/core";

import { useStyles } from "../../../../assets/styles/InviteFboFormStyles";
import { Form } from "../../../ui/useForm";
import colors from "../../../../constants/colors";
import commonClasses from "../../../../constants/common";
import * as services from "../../../../services/mainService";
import { toast } from "react-toastify";
import { prettyString } from "../../../ui/TextTransform";

const InviteFboForm = (props) => {
  const { setOpenPopup, airPortName, icaoCode } = props;
  const classes = useStyles(colors);
  const commonStyles = commonClasses();

  const { auth } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
  }));

  const [fboName, setFboName] = useState("");
  const [fboEmail, setFboEmail] = useState("");
  const [otherDetail, setOtherDetail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    return (
      fboName !== null &&
      fboName !== "");
  };

  const submitInviteFbo = async () => {
    setIsLoading(true);
    const obj = {
      fboName: fboName,
      fboEmail: fboEmail,
      otherDetail: otherDetail,
      airportName: airPortName,
      icaoCode: icaoCode
    };
    const res = await services.fboInvite({
      invite: obj,
      token: auth.token,
    });
    if (res.response_type === "success") {
      toast("FBO referred successfully.", { position: "bottom-center", closeButton: false });
      setIsLoading(false);
      setOpenPopup(false);
    } else {
      toast.info(res.response.message, { position: "bottom-center", closeButton: false });
      setIsLoading(false);
    }
  };

  return (
    <Form>
      {/* <Card> */}
      <CardContent style={{paddingTop: 0, paddingLeft: 10}}>
        <form autoComplete="off" className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            size="small"
            fullWidth
            id="fboName"
            onChange={(e) => {
              if ( e.target.value.match("^(?!\\s)[a-zA-Z 0-9_@.,/#&+-]*$") != null ) {
                setFboName(prettyString(e.target.value))
              }}}
            value={fboName}
            label="FBO Name"
            name="fboName"
            autoComplete="off"
            autoFocus
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focusedLabel,
                error: classes.erroredLabel,
              },
              className: classes.floatingLabelFocusStyle,
            }}
            InputProps={{
              style: { color: "#000" },
              classes: {
                notchedOutline: classes.notchedOutline,
                input: classes.inputLine,
                colorSecondary: {
                  color: "#000",
                },
              },
              color: "#fff",
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            value={fboEmail}
            onChange={(e) => setFboEmail(e.target.value)}
            fullWidth
            name="fboEmail"
            label="FBO Email (Optional)"
            id="fboEmail"
            autoComplete="off"
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focusedLabel,
                error: classes.erroredLabel,
              },
              className: classes.floatingLabelFocusStyle,
            }}
            InputProps={{
              style: { color: "#000" },
              classes: {
                notchedOutline: classes.notchedOutline,
                input: classes.inputLine,
                colorSecondary: {
                  color: "#000",
                },
              },
              color: "#fff",
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            id="otherDetail"
            multiline={true}
            rows={4}
            onChange={(e) => setOtherDetail(e.target.value)}
            value={otherDetail}
            helperText={`${otherDetail.length}/500`}
            inputProps={{
              maxLength: 500,
            }}
            label="Send a note to FBO (Optional)"
            name="otherDetail"
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focusedLabel,
                error: classes.erroredLabel,
              },
              className: classes.floatingLabelFocusStyle,
            }}
            InputProps={{
              style: { color: "#000" },
              classes: {
                notchedOutline: classes.notchedOutline,
                input: classes.inputLine,
                colorSecondary: {
                  color: "#000",
                },
              },
              color: "#fff",
            }}
          />
          <br />
          <br />
          <Button
            disabled={!validateForm() || isLoading === true}
            onClick={submitInviteFbo}
            fullWidth
            variant="contained"
            className={(commonStyles.submitBtnsmall, classes.loginBtn)}
          >
            Send
          </Button>
        </form>
      </CardContent>
    </Form>
  );
};

export default InviteFboForm;
