import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pageBackground: {
    backgroundColor: "#000",
  },
  page: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
  },
  submit: {
    backgroundColor: (colors) => colors.primaryColor,
    color: "#fff",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: (colors) => colors.primaryColor,
    },
    borderRadius: 5,
  },
  paragonSubmit: {
    backgroundColor: (colors) => colors.paragonPrimaryColor,
    color: "#fff",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: (colors) => colors.paragonPrimaryColor,
    },
    borderRadius: 5,
  },
  leftWrap: {
    backgroundColor: "#555",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  rightWrap: {
    backgroundColor: "#222",
    minHeight: "70vh",
    borderRadius: 5,
  },
  link: {
    color: (colors) => colors.primaryColor,
  },
  paragonLink: {
    color: (colors) => colors.paragonPrimaryColor,
  },
  right: {
    textAlign: "right",
  },
  fixedBar: {
    backgroundColor: "#000",
    paddingLeft: 40,
  },
  imageLogoStyle: {
    maxWidth: 130,
    height: 60,
    marginTop: 25,
  },
  paragonImageLogoStyle: {
    maxWidth: 225,
    height: 50,
    marginTop: 25,
  },
}));

export { useStyles };
