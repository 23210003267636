import React from "react";
import { Grid, Tooltip } from "@material-ui/core";
import Collapsible from "react-collapsible";
import { Wrap } from "../../../../containers/History";
import { useStyles } from "../../../../assets/styles/PriceView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";

const PriceView = (props) => {
  const { item, fuelPrice, navigation } = props;
  const classes = useStyles();
  const triggerView = (item) => {
    return (
      item.triggerViewValue !== "" && (
        <Grid container className={navigation === "finalBookingScreen" ? classes.bookingTriggerValueStyle : classes.triggerValueStyle }>
          <Grid item xs={6} className={navigation === "finalBookingScreen" ? classes.bookingHeadingTextStyle2  : classes.headingTextStyle2}>
            <div>{item.triggerViewHeading}</div>&nbsp;
          </Grid>
          <Grid item xs={6} className={navigation === "finalBookingScreen" ? classes.bookingValuePriceStyle  : classes.valuePriceStyle}>
            {item.triggerViewValue}
            <ExpandMoreIcon className={navigation === "finalBookingScreen" ? classes.bookingExpandIconStyle  : classes.expandIconStyle} />
          </Grid>
        </Grid>
      )
    );
  };

  const fuelPriceCollapsibleView = (item) => (
    <Grid container={true} item={12}>
      {!!fuelPrice ? (
        <>
          <Grid
            item
            xs={6}
            className={navigation === "finalBookingScreen" ? classes.bookingTotalPriceTextHeading :`${classes.totalPriceTextHeading} ${classes.rampFeeTextStyle}`}
          >
            <div className={classes.priceCont1}>
              <div>{item.collapsibleHeading}</div> &nbsp;(
              <div className={navigation === "finalBookingScreen" ? classes.bookingCrossAmount : classes.crossAmount}>
                {item.collapsibleBracketValue}
              </div>
              &nbsp;{item.collapsibleBracketValue1}/gal)
            </div>
          </Grid>
          <Grid item xs={6} className={classes.priceStyle}>
            <div className={ navigation === "finalBookingScreen" ? classes.bookingFuelPriceStyle : classes.fuelPriceStyle}>
              {item.collapsibleValue}
              <div className={classes.extraSpace} />
            </div>
          </Grid>
          <Grid item xs={6} className={navigation === "finalBookingScreen" ? classes.bookingHeadingTextStyle1 : classes.headingTextStyle1}>
            {item.collapsibleHeading1}
          </Grid>
          <Grid item xs={6} className={navigation === "finalBookingScreen" ? classes.bookingValueStyle2 : classes.valueStyle2}>
            {item.collapsibleValue1}
            <div className={classes.extraSpace} />
          </Grid>
        </>
      ) : item.collapsibleServices !== "" ? (
        item.collapsibleServices.map((item) => (
          <>
          <Tooltip title={item.name}>
            <Grid item xs={6} className={navigation === "finalBookingScreen" ? classes.bookingHeadingTextStyle1 : classes.headingTextStyle1}>
              {item.name}
            </Grid>
          </Tooltip>
            <Grid item xs={6} className={navigation === "finalBookingScreen" ? classes.bookingValueStyle2 : classes.valueStyle2}>
              {`$${formatNumWithCommas(parseFloat(item.price).toFixed(2))}`}
              <div className={classes.extraSpace} />
            </Grid>
          </>
        ))
      ) : null}
    </Grid>
  );

  return (
    <Wrap className={classes.collapsibleStyle}>
      <Collapsible
        tabIndex={0}
        trigger={triggerView({
          triggerViewHeading: !!fuelPrice ? "Fuel Total" : "Services & Fees Total",
          triggerViewValue: !!fuelPrice
            ? item.fuelCost === ("0" || 0) && item.volume !== (0 || "0")
              ? "NA"
              : item.volume === 0 || item.volume === "0"
              ? "Captain's Request"
              : `$${formatNumWithCommas(parseFloat(item.fuelCost).toFixed(2))}`
            : `$${formatNumWithCommas(parseFloat(item.internationalServicesCost).toFixed(2))}`,
        })}
      >
        {fuelPriceCollapsibleView(
          !!fuelPrice
            ? {
                collapsibleHeading: "Fuel",
                collapsibleValue: `$${formatNumWithCommas(
                  parseFloat(item.fuelCost - item.pristFeeAmount - item.rampFee).toFixed(2))}`,
                collapsibleBracketValue:
                  (item.rateType === 3 ||
                    item.rateType === 4 ||
                    item.rateType === 10 ||
                    item.isDiscount ||
                    item.isPriceChange ||
                    item.isOperatorSpecificDiscount ||
                    item.isRegistryDiscount ||
                    item.isTailWiseDiscount) &&
                    parseFloat(item.actualRate).toFixed(2) !== parseFloat(item.rate).toFixed(2)
                    ? `$${formatNumWithCommas(parseFloat(item.actualRate).toFixed(2))}`
                    : "",
                collapsibleBracketValue1: `$${formatNumWithCommas(parseFloat(item.rate).toFixed(
                  2
                ))}`,
                collapsibleHeading1: "Prist Fee",
                collapsibleValue1: item.isPristFee
                  ? `$${formatNumWithCommas(parseFloat(item.pristFeeAmount).toFixed(2))}`
                  : "$0.00",
              }
            : {
                collapsibleServices:
                  item.internationalFeeAndTaxes.length > 0
                    ? item.internationalFeeAndTaxes
                    : "",
              }
        )}
      </Collapsible>
    </Wrap>
  );
};

export default PriceView;
