import React from "react";
import { Grid, Link,Tooltip } from "@material-ui/core";

import { useStyles } from "../../../../assets/styles/ProfileSummaryStyles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ProfileDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { signup } = useSelector((state) => ({
    signup: state.signup,
  }));

  return (
    <Grid container item xs={12} className={classes.secondCont}>
      <Grid item xs={12} className={classes.profileTextHeading}>
        <Grid container>
          <Grid item xs={11} className={classes.headingStyle}>
            Profile
          </Grid>
          <Grid item xs={1} className={classes.editButton}>
            <Link
              href="#"
              id="edit"
              variant="body2"
              color={"inherit"}
              onClick={() => history.push("/signup/profileSetup")}
            >
              Edit
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.profileDetail}>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Grid container style={{ width: "100%", height: "100%" }}>
              <Grid item xs={12} className={classes.headingTextStyle}>
                Full Name
              </Grid>
              <Grid item xs={12} className={classes.valueStyle}>
                <Tooltip title={signup.basicDetails.name}>
                  <div className={classes.dotStyle}>
                    {signup.basicDetails.name}
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container style={{ width: "100%", height: "100%" }}>
              <Grid item xs={12} className={classes.headingTextStyle}>
                User Type
              </Grid>
              <Grid item xs={12} className={classes.valueStyle}>
                {signup.registerAs === 2 ? "Flight Department" : "Pilot"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container style={{ width: "100%", height: "100%" }}>
              <Grid item xs={12} className={classes.headingTextStyle}>
                Phone Number
              </Grid>
              <Grid item xs={12} className={classes.valueStyle}>
                {signup.basicDetails !== undefined &&
                  `+${signup.basicDetails.mobileNumber}`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container style={{ width: "100%", height: "100%" }}>
              <Grid item xs={12} className={classes.headingTextStyle}>
                Email
              </Grid>
              <Grid item xs={12} className={classes.valueStyle}>
              <Tooltip title={signup.basicDetails.email}>
                  <div className={classes.dotStyle}>
                  {signup.basicDetails.email}
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={12} className={classes.headingTextStyle}>
            Corporate Location
          </Grid>
          <Grid item xs={12} className={classes.valueStyle}>
            {signup.basicDetails.baseLocation}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileDetails;
