import React from "react";

import AppLayout from "../Layout/AppLayout";
import { StyledContainer } from "../History";
import ManagePilotsComponent from "../../components/scene/app/settings/ManagePilots";
import NozlHeadings from "../../components/ui/NozlHeadings";

export default function ManagePilots(props) {
  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <AppLayout>
        <NozlHeadings title="Manage Pilots" />
        <ManagePilotsComponent location={props.location} />
      </AppLayout>
    </StyledContainer>
  );
}
