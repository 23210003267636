const v1 = "api/v1/operator/";
const v2="api/v2/operator/";

const BASE_URL = process.env.REACT_APP_BASE_URL;
//const BASE_URL = 'https://stagingapi.nozlapp.com/'

export const EDIT_PROFILE = `${BASE_URL}${v1}edit-profile`;
export const GET_AIRPLANE_LIST = `${BASE_URL}${v1}airplane-list`;
export const ADD_AIRCRAFT = `${BASE_URL}${v1}add-aircraft`;
export const DELETE_AIRCRAFT = `${BASE_URL}${v1}delete-aircraft`;
export const EDIT_AIRCRAFT = `${BASE_URL}${v1}edit-aircraft`;
export const AIRCRAFT_LIST = `${BASE_URL}${v1}aircraft-list`;
export const AIRCRAFT_LIST_BY_INVITEE_PILOT = `${BASE_URL}${v2}aircraft-list`;
export const SUBMIT_PROFILE = `${BASE_URL}${v1}submit-profile`;
export const GET_PROFILE = `${BASE_URL}${v1}get-profile`;
export const FORGOT_PASSWORD = `${BASE_URL}${v1}forget-password`;
export const LOGIN = `${BASE_URL}${v1}login`;
export const FBO_LIST = `${BASE_URL}${v1}fbo-list`;
export const SEND_NUDGE = `${BASE_URL}${v1}send-nudge`;
export const FBO_DETAIL_LIST = `${BASE_URL}${v2}fbo-detail-list`;
export const CHANGE_PASSWORD = `${BASE_URL}${v1}change-password`;
export const UPDATE_MEMBERSHIP = `${BASE_URL}${v1}update-cfp-caa-membership-status`;
export const SEND_FEEDBACK = `${BASE_URL}${v1}submit-feedback`;
export const GET_BOOKINGS = `${BASE_URL}${v2}get-bookings`;
export const CREATE_BOOKING = `${BASE_URL}${v1}create-booking`;
export const CANCEL_BOOKING = `${BASE_URL}${v1}cancel-booking`;
export const FBO_INVITE = `${BASE_URL}${v1}refer-fbo`;
export const GET_NOTIFICATIONS = `${BASE_URL}${v2}get-notification-list`;
export const GET_UNREAD_NOTIFICATION_COUNT = `${BASE_URL}${v2}get-unread-notification-count`;
export const GET_NOTIFICATION_DETAIL = `${BASE_URL}${v1}get-booking-detail`;
export const MARK_NOTIFICATION_READ = `${BASE_URL}${v1}mark-read`;
export const GET_INVITED_PILOT_LIST = `${BASE_URL}${v1}get-invite-pilot-list`;
export const INVITE_PILOT_USER = `${BASE_URL}${v1}invite-pilot`;
export const GET_ASIGNED_AIRCRAFT_LIST = `${BASE_URL}${v1}assigned-aircraft-list`;
export const ASSIGN_AIRCRAFT_TO_PILOT = `${BASE_URL}${v1}assign-aircraft`;
export const GET_PILOTS_LIST_RELATED_TO_AIRCRAFT = `${BASE_URL}${v1}get-assigned-aircraft-pilot-list`;
export const REMOVE_PILOT_BY_ID = `${BASE_URL}${v1}remove-pilot`;
export const SEND_NUDGE_UNLISTED_FBO = `${BASE_URL}${v1}nudge-unlisted-fbo`;
export const ASSIGN_PILOT_TO_AIRCRFT = `${BASE_URL}${v1}assign-pilot`;
export const GET_ASSIGN_PILOT_AIRCRFT_LIST = `${BASE_URL}${v1}get-assigned-pilot-aircraft-list`;
export const ADD_CUSTOM_AIRCRAFT = `${BASE_URL}${v1}add-custom-aircraft`;
export const GET_AIRCRAFT_MAKER_LIST = `${BASE_URL}${v1}get-aircraft-maker-list`;
export const CLEAR_NOTIFICATION = `${BASE_URL}${v1}clear-notification`;
export const ADD_MANAGER = `${BASE_URL}${v1}add-operator-primary-manager`;
export const SUBMIT_MANAGER = `${BASE_URL}${v1}submit-operator-primary-manager`;
export const ADD_ADDITIONAL_MANAGER = `${BASE_URL}${v1}add-operator-additional-manager`;
export const SUBMIT_ADDITIONAL_MANAGER = `${BASE_URL}${v1}submit-operator-additional-manager`;
export const EDIT_MANAGER = `${BASE_URL}${v1}edit-manager`;
export const DELETE_MANAGER_URL = `${BASE_URL}${v1}delete-manager`;
export const LOGOUT = `${BASE_URL}${v1}logout`;
export const ADD_FAVOURITE_FBO = `${BASE_URL}${v1}add-favourite-fbo`;
export const REMOVE_FAVOURITE_FBO = `${BASE_URL}${v1}remove-favourite-fbo`;
export const GET_FAVOURITE_FBOS = `${BASE_URL}${v1}get-favourite-fbos`;
export const FETCH_GOOGLE_PLACES = `${BASE_URL}${v1}get-google-address?search=`;
export const CONTACT_ADMIN = `${BASE_URL}${v1}contact-admin`;
export const VERIFY_RESET_PASSWORD = `${BASE_URL}${v1}verify-reset-password-token`;
export const RESET_PASSWORD = `${BASE_URL}${v1}reset-password`;
export const TERMS_AND_CONDITIONS = `${BASE_URL}${v1}accept-terms-conditions`;
export const SIGN_UP_URL = `${BASE_URL}${v2}validate-operator-signup`;
export const SIGN_UP_VERIFY = `${BASE_URL}${v2}signup`;
export const GET_NUDGE_DETAIL = `${BASE_URL}${v1}nudge`;
export const GET_DETAIL_OF_INVITEE_PILOT = `${BASE_URL}${v2}get-profile-status`;
export const GET_DEFAULT_SERVICES = `${BASE_URL}${v1}get-ancillary-list`;
