import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: 400,
    boxShadow: "0px -4px 10px #222222",
    clipPath: "inset(0px -15px 0px -15px)",
    position: "relative",
  },
  marginTop: {
    marginTop: 10,
  },
  bottomFboContainer: {
    width: 400,
    height: 115,
    display: "flex",
    position: "fixed",
    bottom: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    background: "#fff",
    boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px 10px 0px 0px",
  },
  fboTextStyle: {
    padding: 5,
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "center",
    color: "#333",
  },
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    width: 210,
    borderRadius: 5,
    height: 37,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
    textTransform: "capitalize",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "center",
  },
  nameHeading: {
    width: "100%",
    background: "linear-gradient(99deg, #212121 15%, #000000 85%)",
    color: "#fff",
    height: 50,
  },
  noData: {
    marginTop: 20,
    marginLeft: 10,
  },
  airportNameCont: {
    width: 300,
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageCont: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
}));

export { useStyles };
