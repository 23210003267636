import React from "react";
import InviteFriendsComponent from '../../components/scene/app/settings/InviteFriends';

import SettingsLayout from '../Settings';
import NozlInnerHeading from "../../components/ui/NozlInnerHeading";

const InviteFriends = () => {
  return (
    <SettingsLayout>
      <NozlInnerHeading title="Invite Friends" />
      <InviteFriendsComponent />
    </SettingsLayout>
  )
}

export default InviteFriends;