import React, { useEffect, useState } from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import algoliasearch from "algoliasearch";
import axios from "axios";
import { Formik } from "formik";
import AddAircraftSchema from "../.../../../../../utils/forms/schema.addAircrafts";

import { useStyles } from "../../../../assets/styles/AircraftSignupStyles";
import { prettyString } from "../../../ui/TextTransform";
import CustomAircraft from "../../profile-setup/CustomAircraft";
import AircraftModel from "./AircraftModel";
import BaseLocation from "../../profile-setup/BaseLocation";
import FuelType from "./FuelType";
import TextField from "../SignupTextInput";
import * as services from "../../../../services/mainService";
import { toast } from "react-toastify";
import colors from "../../../../constants/colors";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_APPLICATION_KEY
);

const searchIndex = searchClient.initIndex(
  process.env.REACT_APP_ALGOLIA_INIT_INDEX
);

const AddAircraftDetails = (props) => {
  const classes = useStyles();
  const { account, signup, auth } = useSelector((state) => ({
    account: state.account,
    signup: state.signup,
    auth: state.auth
  }));
  const [, setCustomAircraftId] = useState("");
  const [aircraftTail, setAircraftTail] = useState("");
  const [name, setName] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [customAircraft, setCustomAircraft] = useState("");
  const [aircraftType, setAircraftType] = useState("");
  const [filterAirplanesId, setFilterAirplanesId] = useState([]);
  const [otherMaker, setOtherMaker] = useState(false);
  const [otherMakerName, setOtherMakerName] = useState("");
  const [isCustomAircraft, setIsCustomAircraft] = useState(false);
  const [filterAirPlanesName, setFilterAirPlanesName] = useState([]);
  const [baseLocation, setBaseLocation] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [, setAirplaneId] = useState("");
  const [address] = useState({ city: "", state: "", country: "" });
  const [operatorValue, setOperatorValue] = useState("");
  const [invoiceEmailValue, setInvoiceEmailValue] = useState("");
  const [aircraftName, setAircraftName] = useState("");
  const [addLat, setAddLat] = useState("");
  const [addLong, setAddLong] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [manufacturerName, setManufacturerName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [buttonText, setButtonText] = useState('Add');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    searchIndex
      .search("", { hitsPerPage: 10 })
      .then((response) => {
        setSearchResult(response.hits);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (props.editListItem !== undefined && props.editListItem.length > 0) {
      setIsEdit(true);
      setButtonText('Save');
      setAircraftTail(props.editListItem[0].registryName);
      setOtherMakerName(
        props.editListItem[0].otherMakerName !== undefined
          ? props.editListItem[0].otherMakerName
          : ""
      );
      setCustomAircraft(
        props.editListItem[0].customAircraftObj !== undefined
          ? props.editListItem[0].customAircraftObj
          : ""
      );
      setAddLat(props.editListItem[0].addLat);
      setAddLong(props.editListItem[0].addLng);
      setBaseLocation(props.editListItem[0].baseLocation);
      address.city = props.editListItem[0].city;
      address.state = props.editListItem[0].state;
      address.country = props.editListItem[0].country;
      setFormattedAddress(props.editListItem[0].formattedAddress);
      setInvoiceEmailValue(props.editListItem[0].invoiceEmail);
      setManufacturerName(
        props.editListItem[0].otherMakerName !== undefined
          ? "Other"
          : props.editListItem[0].manufacturerName
      );
      setAircraftType(props.editListItem[0].modelName);
      setName(props.editListItem[0].name);
      setOperatorValue(props.editListItem[0].operatorName);
      setAirplaneId(props.editListItem[0].airplaneId);
      setFuelType(
        props.editListItem[0].fuelType !== undefined
          ? props.editListItem[0].fuelType === 1
            ? "JET A"
            : "100 LL"
          : ""
      );
      setAircraftName(
        props.editListItem[0].name !== undefined
          ? props.editListItem[0].name
          : ""
      );
      setOtherMaker(
        props.editListItem[0].isOtherMaker !== undefined
          ? props.editListItem[0].isOtherMaker
          : false
      );
      setIsCustomAircraft(
        props.editListItem[0].isCustomAircraft !== undefined
          ? props.editListItem[0].isCustomAircraft
          : false
      );
    }
  }, []);

  useEffect(() => {
    if (props.editListItem !== undefined && props.editListItem.length > 0) {
      filterAirplaneData(props.editListItem[0].customAircraftObj);
    }
  }, []);

  const filterAirplaneData = (item) => {
    const makerId = item?._id;
    let tempPlanesName = [];
    let temPlanesId = [];
    account?.airplaneList !== undefined &&
      account?.airplaneList?.map((item, index) => {
        if (item.airplaneMakerId === makerId) {
          tempPlanesName.push(item.name);
          temPlanesId.push(item._id);
        }
      });
    const localArray = [...tempPlanesName, "Other"];
    setFilterAirPlanesName(localArray);
    if (aircraftType !== "") {
      tempPlanesName.map((aircraftType) => {
        if (item === aircraftType) {
          setName(aircraftType);
        }
      });
    } else {
      setName("");
    }
    setFilterAirplanesId(temPlanesId);
  };

  const onModalTypeChange = (obj, isClearModal) => {
    if (obj !== null && obj !== "" && obj !== undefined) {
      if (obj.name === "Other") {
        setOtherMaker(true);
        setIsCustomAircraft(true);
      } else {
        setCustomAircraft(obj);
        setCustomAircraftId(obj._id);
        filterAirplaneData(obj);
        setOtherMaker(false);
        setIsCustomAircraft(false);
        setManufacturerName(prettyString(obj.name));
        if (isClearModal) {
          setAircraftType("");
          setName("");
        }
      }
    } else {
      setFilterAirPlanesName([]);
      setFilterAirplanesId([]);
      setOtherMaker(false);
      setIsCustomAircraft(false);
      setManufacturerName("");
    }
  };

  const modalType = (obj) => {
    if (obj !== null && obj !== "" && obj !== undefined) {
      if (obj === "Other") {
        setAircraftType(obj);
        setName(obj);
        setIsCustomAircraft(true);
      } else {
        setAircraftType(obj);
        setName(obj);
        setIsCustomAircraft(false);
      }
    } else {
      setAircraftType(obj);
      setName(obj);
      setIsCustomAircraft(false);
    }
  };

  const getAirportAddress = (address) => {
    if (address !== null) {
      setBaseLocation(address.icao);
      setAddLat(address.latitude);
      setAddLong(address.longitude);
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            parseFloat(address.latitude).toFixed(5) +
            "," +
            parseFloat(address.longitude).toFixed(5) +
            "&key=AIzaSyClgWmN0aJIplOG6yEL9g1sjxVPq8IytQo"
        )
        .then((response) => {
          const res = response.data.results;
          let data = res[res.length - 4].address_components;
          address.city = data[0].long_name;
          address.state = data[1].long_name;
          address.country = data[2].long_name;
          setFormattedAddress(res[0].formatted_address);
        })
        .catch(function (error) {
          setBaseLocation("");
        });
    } else {
      setBaseLocation("");
    }
  };

  const handleFuelType = (value) => {
    setFuelType(value);
  };

  const cancelUser = () => {
    props.cancelUser(true);
  };

  return (
    <Formik
      validationSchema={AddAircraftSchema}
      initialValues={{
        aircraftTail:
          props.editListItem && props.editListItem[0]
            ? props.editListItem[0]?.registryName
            : "",
        manufacturerName:
          props.editListItem && props.editListItem[0]
            ? props.editListItem[0]?.manufacturerName
            : "",
        model:
          props.editListItem && props.editListItem[0]
            ? props.editListItem[0]?.modelName
            : "",
        aircraftName:
          props.editListItem && props.editListItem[0]
            ? props.editListItem[0].name
            : "",
        otherMakerName:
          props.editListItem && props.editListItem[0]
            ? props.editListItem[0].otherMakerName
            : "",
        fuelType:
          props.editListItem && props.editListItem[0]
            ? props.editListItem[0].fuelType
            : "",
      }}
      isInitialValid={false}
      validateOnMount={true}
      onSubmit={async (values) => {
        setIsSubmitting(true);
        let payloadResult = {};
      
        if (aircraftType === "Other" || otherMaker === true) {
          let type = "";
          if (fuelType === "JET A" || fuelType === "1") {
            type = 1;
          } else {
            type = 2;
          }
          payloadResult = {
            registryName: aircraftTail,
            formattedAddress: formattedAddress,
            operatorName: operatorValue !== undefined ? operatorValue : "",
            invoiceEmail: invoiceEmailValue,
            baseLocation: baseLocation,
            addLat: addLat,
            addLng: addLong,
            city: address.city,
            state: address.state,
            manufacturerName: manufacturerName,
            country: address.country,
            isOtherMaker: otherMaker,
            modelName: aircraftType === "Other" ? "Other" : aircraftType,
            name: aircraftName,
            fuelType: parseInt(type),
            isCustomAircraft: isCustomAircraft,
            customAircraftObj: customAircraft,
          };

          if (otherMaker === true) {
            payloadResult.otherMakerName = otherMakerName;
          } else {
            payloadResult.airplaneMakerId = customAircraft._id;
          }
        } else {
          const airplaneIndex = filterAirPlanesName.indexOf(aircraftType);
          const airplaneId = filterAirplanesId[airplaneIndex];
          payloadResult = {
            airplaneId: airplaneId,
            invoiceEmail: invoiceEmailValue,
            registryName: aircraftTail.trim(),
            operatorName: operatorValue !== undefined ? operatorValue : "",
            baseLocation: baseLocation,
            formattedAddress: formattedAddress,
            addLat: addLat,
            addLng: addLong,
            city: address.city,
            state: address.state,
            country: address.country,
            manufacturerName: manufacturerName,
            modelName: aircraftType === "Other" ? "Other" : aircraftType,
            isCustomAircraft: false,
            isOtherMaker: false,
            customAircraftObj: customAircraft,
          };
        }
        let tempAircrafts = JSON.parse(JSON.stringify(signup.aircraftList));
        let index = -1;
        if (isEdit) {
          index = tempAircrafts.findIndex(
            (it) => it.registryName === props.editListItem[0].registryName
          );
        }
        if (index > -1) {
          tempAircrafts[index] = { ...payloadResult };
        } else {
          tempAircrafts = [...tempAircrafts, payloadResult];
        }
        tempAircrafts = JSON.parse(JSON.stringify(tempAircrafts));
        tempAircrafts = tempAircrafts.map(function (item) {
          delete item.manufacturerName;
          delete item.modelName;
          delete item.customAircraftObj;
          return item;
        });
        // validate local
        for (let air of tempAircrafts) {
          let duplicateRegistry = tempAircrafts.filter(
            (e) => e.registryName === air.registryName
          );
          if (duplicateRegistry.length > 1) {
            setIsSubmitting(false);
            toast.error("Cannot create duplicate aircraft. Please try with the different tail number.", {
              position: "bottom-center",
              closeButton: false,
              style: {
                color: "white",
                backgroundColor: account.isParagonUser || auth.isIframe ? colors.paragonPrimaryColor : colors.primaryColor
              }
            });
            return false;
          }
        }
        const detail = {
          stepNumber: 3,
          aircrafts: tempAircrafts,
          isInviteePilot: auth.status === 1 ? true : false
        };
        // validate in database
        const res = await services.signUpUrl(detail);
        if (res.response_type === "success") {
          setIsSubmitting(false);
          props.addNewAircraft(payloadResult);
        } else {
          setIsSubmitting(false);
          toast.error(res.response.message, {
            position: "bottom-center",
            closeButton: false,
            style: {
              color: "white",
              backgroundColor: account.isParagonUser || auth.isIframe ? colors.paragonPrimaryColor : colors.primaryColor
            }
          });
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        touched,
        errors,
        setTouched,
        handleSubmit,
      }) => (
        <Grid container className={classes.secondInnerCont}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={(e) => {
                  if (
                    e.target.value.match("^(?!\\s)[a-zA-Z 0-9_@.'&/#-]*$") !=
                    null
                  ) {
                    handleChange(e);
                    setAircraftTail(e.target.value?.toUpperCase());
                  }
                }}
                onBlur={handleBlur}
                id="aircraftTailId"
                name="aircraftTail"
                value={aircraftTail}
                label="Aircraft Tail*"
                error={
                  touched.aircraftTail && errors.aircraftTail
                    ? errors.aircraftTail
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CustomAircraft
                navigation="signup"
                modalType={(e, isClearModal) => {
                  if (e?.name === "Other") {
                    handleChange("manufacturerName")("Other");
                  } else {
                    handleChange("manufacturerName")(e?.name || "");
                  }
                  onModalTypeChange(e, isClearModal);
                }}
                manufactureName={manufacturerName}
                onBlur={() =>
                  setTouched({ ...touched, manufacturerName: true })
                }
              />
              {touched.manufacturerName && errors.manufacturerName && (
                <span className={classes.errorMsg}>
                  {errors.manufacturerName || ""}
                </span>
              )}
            </Grid>
            {otherMaker === true ? (
              <Grid container={true} item xs={12}>
                <TextField
                  name="otherMakerName"
                  fullWidth
                  onChange={(e) => {
                    if (
                      e.target.value.match("^(?!\\s)[a-zA-Z 0-9_@.'&/#-]*$") !=
                      null
                    ) {
                      handleChange(e);
                      setOtherMakerName(prettyString(e.target.value));
                    }
                  }}
                  onBlur={handleBlur}
                  value={otherMakerName}
                  label="Manufacturer Name*"
                  error={
                    touched.otherMakerName && errors.otherMakerName
                      ? errors.otherMakerName
                      : ""
                  }
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <AircraftModel
                  handleAircraftModal={(e) => {
                    handleChange("model")(e || "");
                    modalType(e);
                  }}
                  modelName={
                    aircraftType !== "" ? aircraftType?.toUpperCase() : name
                  }
                  filterAirPlanesName={filterAirPlanesName}
                  onBlur={() => setTouched({ ...touched, model: true })}
                />
                {touched.model && errors.model && (
                  <span className={classes.errorMsg}>{errors.model || ""}</span>
                )}
              </Grid>
            )}

            {isCustomAircraft === true || otherMaker === true ? (
              <Grid item xs={6}>
                <TextField
                  name="aircraftName"
                  fullWidth
                  onChange={(e) => {
                    if (
                      e.target.value.match("^(?!\\s)[a-zA-Z 0-9_@.'&/#-]*$") !=
                      null
                    ) {
                      handleChange(e);
                      setAircraftName(prettyString(e.target.value));
                    }
                  }}
                  onBlur={handleBlur}
                  value={aircraftName}
                  label="Aircraft Name*"
                  error={
                    touched.aircraftName && errors.aircraftName
                      ? errors.aircraftName
                      : ""
                  }
                />
              </Grid>
            ) : null}

            {signup.registerAs === 2 ? null : (
              <Grid
                item
                xs={isCustomAircraft === true || otherMaker === true ? 6 : 12}
              >
                <TextField
                  fullWidth
                  onChange={(e) => {
                    if (e.target.value.match("^(?!\\s)[a-zA-Z 0-9-'&]*$") != null) {
                      setOperatorValue(prettyString(e.target.value));
                    }
                  }}
                  value={operatorValue}
                  label="Flight Department Name"
                />
              </Grid>
            )}
            {isCustomAircraft === true || otherMaker === true ? (
              <Grid item xs={6}>
                <div>
                  <FuelType
                    handleFuelType={(e) => {
                      handleChange("fuelType")(e);
                      handleFuelType(e);
                    }}
                    selectedFuelType={fuelType}
                    onBlur={() => setTouched({ ...touched, fuelType: true })}
                  />
                  {touched.fuelType && errors.fuelType && (
                    <span className={classes.errorMsg}>
                      {errors.fuelType || ""}
                    </span>
                  )}
                </div>
              </Grid>
            ) : null}

            <Grid
              item
              xs={
                (isCustomAircraft === true || otherMaker === true) &&
                signup.registerAs === 4
                  ? 6
                  : 12
              }
            >
              <BaseLocation
                navigation="signup"
                icaoCode={baseLocation}
                searchResult={searchResult}
                address={getAirportAddress}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {signup.aircraftList.length > 0 && (
              <Grid item xs={6}>
                <Button className={classes.backButtonText} onClick={cancelUser}>
                  Cancel
                </Button>
              </Grid>
            )}
            <Grid item xs={signup.aircraftList.length > 0 ? 6 : 12}>
              <Button
                className={classes.buttonCont}
                disabled={Object.keys(errors).length > 0 || isSubmitting}
                onClick={handleSubmit}
              >
                {isSubmitting === true && (
                  <CircularProgress
                    style={{ color: "white" }}
                    size={20}
                    thickness={5}
                  />
                )}
                {!isSubmitting  && buttonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default AddAircraftDetails;
