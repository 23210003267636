import { put, call, takeLatest } from "redux-saga/effects";

import * as actionTypes from "./fbos.action-types";
import * as services from "../../services/mainService";
import * as authActionTypes from "./fbos.action-types";
import { toast } from "react-toastify";
import { showLoader, removeLoader } from "../loader/loader.actions";

function* getFbos(action) {
  const apiResponse = yield call(services.getFbos, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_FBOS.SUCCESS,
        payload: apiResponse.response.data.fbo,
      });
      yield put({
        type: actionTypes.GET_UNLISTEDFBOS.SUCCESS,
        payload: apiResponse.response.data?.unlistedFbo ?? [],
      });
    } else {
      yield put({
        type: actionTypes.GET_FBOS.FAILURE,
        payload: apiResponse.response,
      });
      yield put({ type: actionTypes.GET_UNLISTEDFBOS.SUCCESS, payload: [] });
    }
  }
}

function* getFbosSuccess(action) {
  //TODO - commented for now
}

function* getFbosFailure(action) {
  // if(action.payload.message === "User not found"){
  //   yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  // }
 }

function* getFboDetailList(action) {
  const apiResponse = yield call(services.getFboDetailList, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_FBO_DETAIL_LIST.SUCCESS,
        payload: apiResponse.response.data.fbo,
      });
      yield put({
        type: actionTypes.GET_UNLISTEDFBO_DETAIL_LIST.SUCCESS,
        payload: apiResponse.response.data.unlistedFbo,
      });
    } else {
      yield put({
        type: actionTypes.GET_FBO_DETAIL_LIST.FAILURE,
        payload: apiResponse.response,
      });
      yield put({
        type: actionTypes.GET_UNLISTEDFBO_DETAIL_LIST.FAILURE,
        payload: [],
      });
    }
  }
  yield put(removeLoader());
}

function* getFboDetailListSuccess(action) { }

function* getFboDetailListFailure(action) {
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    toast.info("Session expired. Please login.", { position: "bottom-center", closeButton: false });
  }
}

function* sendNudge(action) {
  const apiResponse = yield call(services.sendNudge, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.SEND_NUDGE.SUCCESS,
        payload: apiResponse.response.data.fbo,
      });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.SEND_NUDGE.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* sendNudgeSuccess(action) { }

function* sendNudgeFailure(action) {
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
 }

function* inviteFbo(action) {
  const apiResponse = yield call(services.fboInvite, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.INVITE_FBO.SUCCESS,
        payload: apiResponse.response,
      });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.INVITE_FBO.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* inviteFboSuccess(action) { }

function* inviteFboFailure(action) {
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
 }

//unlisted Nudge
function* sendUnlistedNudge(action) {
  const apiResponse = yield call(services.sendUnlistedNudge, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast("Request sent successfully.", { closeButton: false });
    }
  }
}

function* sendUnlistedNudgeSuccess(action) { }

function* sendUnlistedNudgeFailure(action) { 
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    toast.info("Session expired. Please login.", { position: "bottom-center", closeButton: false });
  }
}

// get favourite fbo
function* getFavouriteFbos(action) {
  yield put(showLoader());
  const apiResponse = yield call(services.getFavouriteFbos, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.FAVOURITE_FBOS.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.FAVOURITE_FBOS.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
  yield put(removeLoader());
}

function* getFavouriteFbosSuccess(action) { }
function* getFavouriteFbosFailure(action) {
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
 }

// Mark a FBO favourite
function* markFavoriteFbo(action) {
  const apiResponse = yield call(services.addFavouriteFbo, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.ADD_FAVORITE_FBO.SUCCESS,
        payload: apiResponse.response,
      });
      yield put({
        type: actionTypes.FAVOURITE_FBOS.SUCCESS,
        payload: action.payload,
      });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.ADD_FAVORITE_FBO.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* markFavoriteFboSuccess(action) { }

function* markFavoriteFboFailure(action) { 
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
}

// unmark a FBO favourite
function* unmarkFavoriteFbo(action) {
  const apiResponse = yield call(services.removeFavouriteFbo, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.UNMARK_FAVORITE_FBO.SUCCESS,
        payload: apiResponse.response,
      });
      yield put({
        type: actionTypes.FAVOURITE_FBOS.SUCCESS,
        payload: action.payload,
      });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center", closeButton: false });
      yield put({
        type: actionTypes.UNMARK_FAVORITE_FBO.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* unmarkFavoriteFboSuccess(action) { }

function* unmarkFavoriteFboFailure(action) { 
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
}

function* getDefaultServices(action) {
  const apiResponse = yield call(services.getdefaultServicesApi, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.FBO_DEFAULT_SERVICES.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.FBO_DEFAULT_SERVICES.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
  yield put(removeLoader());
}

function* getDefaultServicesSuccess(action) { }

function* getDefaultServicesFailure(action) { 
  if(action.payload.message.includes("User not found")){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
}

export function* fbosSaga() {
  // fetch fbos
  yield takeLatest(actionTypes.GET_FBOS.REQUEST, getFbos);
  yield takeLatest(actionTypes.GET_FBOS.SUCCESS, getFbosSuccess);
  yield takeLatest(actionTypes.GET_FBOS.FAILURE, getFbosFailure);

  // get fbo detail list
  yield takeLatest(actionTypes.GET_FBO_DETAIL_LIST.REQUEST, getFboDetailList);
  yield takeLatest(
    actionTypes.GET_FBO_DETAIL_LIST.SUCCESS,
    getFboDetailListSuccess
  );
  yield takeLatest(
    actionTypes.GET_FBO_DETAIL_LIST.FAILURE,
    getFboDetailListFailure
  );

  // send nudge request
  yield takeLatest(actionTypes.SEND_NUDGE.REQUEST, sendNudge);
  yield takeLatest(actionTypes.SEND_NUDGE.SUCCESS, sendNudgeSuccess);
  yield takeLatest(actionTypes.SEND_NUDGE.FAILURE, sendNudgeFailure);

  // invite fbo
  yield takeLatest(actionTypes.INVITE_FBO.REQUEST, inviteFbo);
  yield takeLatest(actionTypes.INVITE_FBO.SUCCESS, inviteFboSuccess);
  yield takeLatest(actionTypes.INVITE_FBO.FAILURE, inviteFboFailure);

  // get favourite fbos
  yield takeLatest(actionTypes.FAVOURITE_FBOS.REQUEST, getFavouriteFbos);
  yield takeLatest(
    actionTypes.FAVOURITE_FBOS.SUCCESS,
    getFavouriteFbosSuccess
  );
  yield takeLatest(
    actionTypes.FAVOURITE_FBOS.FAILURE,
    getFavouriteFbosFailure
  );

  // mark favorite fbo
  yield takeLatest(actionTypes.ADD_FAVORITE_FBO.REQUEST, markFavoriteFbo);
  yield takeLatest(actionTypes.ADD_FAVORITE_FBO.SUCCESS, markFavoriteFboSuccess);
  yield takeLatest(actionTypes.ADD_FAVORITE_FBO.FAILURE, markFavoriteFboFailure);

  // unmark favorite fbo
  yield takeLatest(actionTypes.UNMARK_FAVORITE_FBO.REQUEST, unmarkFavoriteFbo);
  yield takeLatest(actionTypes.UNMARK_FAVORITE_FBO.SUCCESS, unmarkFavoriteFboSuccess);
  yield takeLatest(actionTypes.UNMARK_FAVORITE_FBO.FAILURE, unmarkFavoriteFboFailure);

  // send unlisted nudge request
  yield takeLatest(actionTypes.SEND_UNLISTED_NUDGE.REQUEST, sendUnlistedNudge);
  yield takeLatest(
    actionTypes.SEND_UNLISTED_NUDGE.SUCCESS,
    sendUnlistedNudgeSuccess
  );
  yield takeLatest(
    actionTypes.SEND_UNLISTED_NUDGE.FAILURE,
    sendUnlistedNudgeFailure
  );
  // get default services
  yield takeLatest(actionTypes.FBO_DEFAULT_SERVICES.REQUEST, getDefaultServices);
  yield takeLatest(actionTypes.FBO_DEFAULT_SERVICES.SUCCESS, getDefaultServicesSuccess);
  yield takeLatest(actionTypes.FBO_DEFAULT_SERVICES.FAILURE, getDefaultServicesFailure);
}
