import { Grid, Button, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useStyles } from "../../../assets/styles/RegisterOptionStyles";

const RegisterOption = (props) => {
  const classes = useStyles();

  const { signup, account, auth } = useSelector((state) => ({
    signup: state.signup,
    account: state.account,
    auth: state.auth
  }));
  const [userType, setUserType] = useState(
    signup.regiterAs !== "" ? signup.registerAs : ""
  );
  const [loading, setLoading] = useState(false);

  const openFbo = () => {
    var win = window.open(
      account.isParagonUser === false
        ? process.env.REACT_APP_FBO_URL
        : process.env.REACT_APP_FBO_PARAGON_URL,
      "_blank"
    );
    win.focus();
  };

  return (
    <Grid container className={classes.mainCont}>
      <Grid
        item
        xs={12}
        style={{ width: "100%", height: "calc(100vh - 130px)" }}
      >
        {!auth.isIframe ?
          <Grid item xs={12} className={classes.memberStyle}>
            Are you an FBO?&nbsp;
            <span className={classes.linkStyle} onClick={openFbo}>
              {"Login"}&nbsp;
            </span>
            here.
          </Grid>
          :
          <Grid item xs={12} className={classes.memberStyle}/>
        }
        <Grid item xs={12} className={classes.mainText}>
          Do you want to register as
        </Grid>
        <Grid item xs={12} className={classes.buttonCont}>
          <Grid
            className={classes.buttonTextStyle}
            style={{
              background:
                userType === 2
                  ? "linear-gradient(to bottom, #666 0%, #333 100%)"
                  : "#fff",
              color: userType === 2 ? "#fff" : "#000000",
            }}
            onClick={() => {
              setUserType(2);
            }}
          >
            Flight Department
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.buttonCont}>
          <Grid
            className={classes.buttonTextStyle}
            onClick={() => {
              setUserType(4);
            }}
            style={{
              background:
                userType === 4
                  ? "linear-gradient(to bottom, #666 0%, #333 100%)"
                  : "#fff",
              color: userType === 4 ? "#fff" : "#000000",
            }}
          >
            Pilot
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <Grid container item xs={12} className={classes.footerStyle}>
        {!auth.isIframe ?
          <Grid item xs={10} className={classes.memberStyle1}>
            Already a member?&nbsp;
            <Link className={classes.linkStyle} to="/login" variant="body2">
              {" Login "}
            </Link>
            &nbsp;to continue.
          </Grid>
          : 
          <Grid item xs={10} className={classes.memberStyle1}/>
        }
          <Grid item xs={2}>
            <Button
              disabled={userType === "" ? true : false}
              className={
                userType === ""
                  ? (account.isParagonUser !== undefined &&
                    account.isParagonUser === true) || auth.isIframe
                    ? `${classes.nextButtonWithoutSelected} ${classes.paagonNextButtonWithoutSelected}`
                    : classes.nextButtonWithoutSelected
                  : (account.isParagonUser !== undefined &&
                    account.isParagonUser === true) || auth.isIframe
                  ? `${classes.nextButtonCont} ${classes.paagonNextButtonWithoutSelected}`
                  : classes.nextButtonCont
              }
              onClick={() => {
                if (userType !== "") {
                  setLoading(true);
                  props.handleClick(userType);
                }
              }}
            >
              {loading === true && (
                <CircularProgress
                  style={{ color: "white" }}
                  size={20}
                  thickness={5}
                />
              )}
              {!loading && "Next"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegisterOption;
