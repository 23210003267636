import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  mainCont: {
    width: "100%",
    padding: 40,
  },
  innerCont: {
    display: "flex",
    flexDirection: "column",
  },
  imageStyle: {
    width: 130,
    height: 60,
  },
  paragonImageStyle:{
    width: 220,
    height: 50,
  },
  paragonIframeImageStyle:{
    width: "100%",
    height: 50,
  },
  textCont: {
    height: 64,
    marginTop: 40,
    fontFamily: "Arial",
    fontSize: 24,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: -0.25,
    textAlign: "left",
    color: "#fff",
  },
  iframeTextCont: {
    marginTop: 40,
    fontFamily: "Arial",
    fontSize: 22,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: -0.25,
    textAlign: "left",
    color: "#000",
  },
  imageContainer: {
    width: 274,
    height: 229,
    marginTop: 36,
  },
  imageIframeContainer:{
    width: "100%",
    height: 229,
    marginTop: 36,
  },
  textStyle2: {
    width: 274,
    height: 60,
    margin: "26px 0 0",
    fontFamily: "Arial",
    fontSize: 24,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff"
  },
  iframeTextStyle2: {
      width: 274,
      height: 60,
      margin: "26px 0 0",
      fontFamily: "Arial",
      fontSize: 22,
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#000"
    },
}));

export { useStyles };
