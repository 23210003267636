import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import colors from "../../../../constants/colors";
import fontWeights from "../../../../constants/fontWeights";

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 4,
    height: 40
  },
  label: {
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
  },
  value: {
    fontWeight: "600",
    fontStyle: "bold",
  },
  valueMeta: {
    fontWeight: "600",
    fontStyle: "bold",
    fontSize: 16,
    color: "#000",
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  tooltipCont: {
    display: "flex",
    justifyContent: "center",
  },
  customTooltipStyle: {
    width: 250,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: fontWeights.fontWeight500,
    marginTop: 0,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    textAlign: "left",
    color: colors.white,
  },
  infoImageStyle: {
    width: 15,
    height: 15,
  },
  priceDetailCont: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  showNotesStyle: {
    width: 18,
    height: 18,
    color: "#666",
    borderRadius: 10,
    cursor: "pointer",
    marginLeft: 10
  },
}));

const TransactionDetailRowWithRateCross = (props) => {
  const { key, label, value, valueMeta, tier, navigation } = props;
  const classes = useStyles();

  const showNotes = (notes) => (
    <Tooltip title={notes} classes={{ tooltip: classes.customTooltipStyle }}>
      <Info
        className={classes.showNotesStyle}
      />
    </Tooltip>
  );

  return (
    <div>
      <div
        key={key}
        className={classes.main}
        style={{
          width: props.fromTooltip === "fromTooltip" ? 150 : "100%",
          borderBottomColor: props.fromTooltip === "fromTooltip" ? "#fff" : "#666",
          paddingTop: props.fromTooltip === "fromTooltip" ? 5 : 12,
          paddingBottom: props.fromTooltip === "fromTooltip" ? 5 : 12,
        }}
      >
        <div
          className={classes.label}
          style={{
            color: props.fromTooltip === "fromTooltip" ? "#fff" : "#666",
            fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,
          }}
        >
          {label}
        </div>
        <div className={classes.priceDetailCont}>
          {props.navigation !== "fromDetail" ?
          <div className={classes.meta}>
            {typeof value === "string" ? (
              <div className={classes.value} style={{
                color: props.fromTooltip === "fromTooltip" ? "#fff" : "#999",
                fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,
                marginRight: props.navigation === "fromDetail" ? 5 : 0,
              }}>{value}</div>
            ) : (
              <div className={classes.value} style={{
                color: props.fromTooltip === "fromTooltip" ? "#fff" : "#999",
                fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,
                marginRight: props.navigation === "fromDetail" ? 5 : 0,
              }}>NaN</div>
            )}
            {valueMeta !== "" && valueMeta !== value ? (
              <div className={classes.valueMeta} style={{
                color: props.fromTooltip === "fromTooltip" ? "#fff" : "#666",
                fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,
              }}>{valueMeta}</div>
            ) : null}
          </div>
          :
          <div className={classes.meta} style={{ display: 'flex', flexDirection: "row" }}>
            {valueMeta !== "" && valueMeta !== value ? (
              <div className={classes.valueMeta} style={{
                color: props.fromTooltip === "fromTooltip" ? "#fff" : "#999",
                fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,
                marginRight: props.navigation === "fromDetail" ? 5 : 0,
              }}>{valueMeta}</div>
            ) : null}
            {typeof value === "string" ? (
              <div className={classes.value} style={{
                color: props.fromTooltip === "fromTooltip" ? "#fff" : "#666",
                fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,

              }}>{value}</div>
            ) : (
              <div className={classes.value} style={{
                color: props.fromTooltip === "fromTooltip" ? "#fff" : "#666",
                fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,
              }}>NaN</div>
            )}
          </div>
        }
          {navigation === 'fromDetail' ? ((!!tier?.note && tier?.note !== "") ||
            (!!tier?.comment && tier?.comment !== "")) ? (
            <div item className={classes.tooltipCont}>
              {showNotes(tier?.note || tier?.comment)}
            </div>) : null
            : null}
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default TransactionDetailRowWithRateCross;