import constants from "../../../../config/constants";
import RampFeeType from "../../../../config/RampFeeType";
import SafAndJetAType from "../../../../config/SafAndJetAType";

export const calculateUpdateList = (account, navigation) => {
  return new Promise((resolve, reject) => {
    try {
      let personalData = [];
      let newDataForRefistry = [];
      let aircraftArr = [];
      if (
        account?.aircraftList !== undefined &&
        account?.aircraftList.length > 0
      ) {
        aircraftArr = account?.aircraftList.map(function (val) {
          let dataSet = {
            _id: val._id,
            operatorUserId: "",
            operatorName: "Aircraft",
            airplaneId: val.airplaneId,
            invoiceEmail: val.invoiceEmail,
            registryName: val.registryName,
            size: val?.airplaneDetail?.size,
            name: val?.airplaneDetail?.name,
            airplaneDetail: val.airplaneDetail,
            makerName: val?.airplaneDetail?.airplaneMakerDetail?.name,
            baseLocation: val?.baseLocation,
            operatorNameValue: val.operatorName,
            bookingsCount: val?.bookingsCount,
          };
          personalData.push(dataSet);
          return val.airplaneDetail?.name ?? "";
        });
        if (personalData.length > 0) {
          personalData.sort(function (a, b) {
            if (a?.name < b?.name) {
              return -1;
            }
            if (a?.name > b?.name) {
              return 1;
            }
            return 0;
          });
        }
        newDataForRefistry.push({
          title: "Aircraft",
          data: personalData,
        });
      }

      let tempId = [];
      if (account?.assignedAircraftList?.length > 0) {
        for (let c = 0; c < account?.assignedAircraftList.length; c++) {
          const mData = account?.assignedAircraftList[c];
          const operatorUserId = mData.operatorUserId;
          const operatorName = mData.operatorDetail.name;
          const status = tempId.includes(`${operatorUserId}`);
          if (status === false) {
            tempId.push(`${operatorUserId}`);
            const assigneddata = account?.assignedAircraftList.filter(
              (source) => source.operatorUserId === operatorUserId
            );
            let assignedInner = [];
            assigneddata.map((op) => {
              assignedInner.push({
                _id: op.aircraftId,
                operatorUserId: op.operatorUserId,
                operatorName: op.operatorDetail.name,
                airplaneId: op.operatoraircraftsDetail.airplaneId,
                invoiceEmail: op.operatoraircraftsDetail.invoiceEmail,
                registryName: op.operatoraircraftsDetail.registryName,
                size: op.operatoraircraftsDetail.airplaneDetail.size,
                name: op.operatoraircraftsDetail.airplaneDetail.name,
                airplaneDetail: op.operatoraircraftsDetail.airplaneDetail,
                pilotUserId: op.pilotUserId,
                operatorNameValue: op.operatorDetail.operatorName,
                baseLocation: op.operatoraircraftsDetail.baseLocation,
                bookingsCount: op?.bookingsCount,
              });
            });
            assignedInner.sort(function (a, b) {
              if (a?.name < b?.name) {
                return -1;
              }
              if (a?.name > b?.name) {
                return 1;
              }
              return 0;
            });
            newDataForRefistry.push({
              title: `${operatorName}`,
              data: assignedInner,
            });
          }
        }
      }
      if (navigation !== "withoutCustom") {
        let id = [];
        if (
          account?.tempAircraft !== undefined &&
          account?.tempAircraft?.length > 0
        ) {
          for (let c = 0; c < account?.tempAircraft.length; c++) {
            const mData = account?.tempAircraft[c];
            const operatorUserId = mData.operatorUserId;
            const status = id.includes(`${operatorUserId}`);
            if (status === false) {
              id.push(`${operatorUserId}`);
              const tempAircraftAssigneddata = account?.tempAircraft.filter(
                (source) => source.operatorUserId === operatorUserId
              );

              let tempAssignedInner = [];
              tempAircraftAssigneddata.map((op) => {
                tempAssignedInner.push({
                  _id: op.operatorUserId,
                  baseLocation: op?.baseLocation,
                  operatorUserId: op.operatorUserId,
                  name: op?.name,
                  otherMakerName: op?.otherMakerName,
                  isOtherMaker: op?.isOtherMaker,
                  registryName: op.registryName,
                  operatorName: op.operatorName,
                  airplaneId: op?.airplaneMakerId?._id,
                  invoiceEmail: op.invoiceEmail,
                  size: "",
                  pilotUserId: "",
                  bookingsCount: op?.bookingsCount,
                });
              });
              tempAssignedInner.sort(function (a, b) {
                if (a?.name < b?.name) {
                  return -1;
                }
                if (a?.name > b?.name) {
                  return 1;
                }
                return 0;
              });
              newDataForRefistry.push({
                title: "Custom Aircraft",
                data: tempAssignedInner,
              });
            }
          }
        }
      }
      resolve([aircraftArr, newDataForRefistry]);
    } catch (error) {
      reject(error);
    }
  });
};

export const calculateRateType = (params, fuelselectedType, listedFboStatus) => {
  return new Promise((resolve, reject) => {
    try {
      let tempRateType = 0
      const objProps = params;
      if(listedFboStatus) {
        if (
          (objProps.discountCount === 1 ||
          objProps.operatorRegistryDiscountCount === 1 ||
          objProps.operatorSpecificDiscountCount === 1 ||
          objProps.tailWiseDiscountCount === 1) && (
            objProps.discountFuelType === fuelselectedType ||
            (objProps.discountFuelType === constants.discount.jetA_Saf_Both && fuelselectedType !== constants.fuelType.HUNDRED_LL)
          )
        ) { // this is checking the discount price
          if (objProps.pricingMethod === constants.pricingMethod.pricingMethod1) {
            tempRateType = constants.rateType.retailMinusDiscountPrice
          } else if (objProps.pricingMethod === constants.pricingMethod.pricingMethod2) {
            tempRateType = constants.rateType.costPlusDiscountPrice
          } else {
            tempRateType = constants.rateType.bothDiscountPrice
          }
        } else if ((objProps.isCfpPricing && fuelselectedType === constants.fuelType.JET_A) || (objProps.isSAFCfpPricing && fuelselectedType === constants.fuelType.SAF)) { // this is checkign cfp price
          if (objProps.pricingMethod === constants.pricingMethod.pricingMethod1) {
            tempRateType = constants.rateType.retailMinusCfpPrice
          } else if (objProps.pricingMethod === constants.pricingMethod.pricingMethod2) {
            tempRateType = constants.rateType.costPlusCfpPrice
          } else {
            tempRateType = constants.rateType.bothCfpPrice
          }
        } else if (objProps.isCaaPricing) { // this is checking caa pricing
          if (objProps.pricingMethod === constants.pricingMethod.pricingMethod1) {
            tempRateType = constants.rateType.retailMinusCaaPrice
          } else if (objProps.pricingMethod === constants.pricingMethod.pricingMethod2) {
            tempRateType = constants.rateType.costPlusCaaPrice
          } else {
            tempRateType = constants.rateType.bothCaaPrice
          }
        } else if ((objProps.tiersJetA.length && fuelselectedType === constants.fuelType.JET_A) || (objProps.tiersSAF.length && fuelselectedType === constants.fuelType.SAF)) { // this is checking common pricing
          if (objProps.pricingMethod === constants.pricingMethod.pricingMethod1) {
            tempRateType = constants.rateType.retailMinusPrice
          } else if (objProps.pricingMethod === constants.pricingMethod.pricingMethod2) {
            tempRateType = constants.rateType.costPlusPrice
          } else {
            tempRateType = constants.rateType.bothCommonPrice
          }
        } else {
          tempRateType = constants.rateType.onlyRetailPrice
        } 
      } else {
        tempRateType = constants.rateType.onlyRetailPrice
      }
      
      resolve(tempRateType);
    } catch (error) {
      reject(error);
    }
  });
};

export const calculatePrice = (
  params,
  fuelselectedType,
  volume,
  getFuelType
) => {
  return new Promise((resolve, reject) => {
    try {
      // Always show Price/gal with discounted rate even when entered gal is 0 
      volume = volume === 0 ? 1 : volume;  

      let setrate = 0;
      let setoriginalRate = 0;
      const {
        retailPrice = "",
        retailPriceLL = 0,
        discountFuelType,
        retailPriceSAF,
        discountTiers,
        secondDiscountTier,
      } = params;
      let _fboTiers = [];
      let jetADiscount = discountFuelType === constants.discount.jet_A;
      let bothJetaandSafDiscount = discountFuelType === constants.discount.jetA_Saf_Both;
      if (getFuelType === SafAndJetAType.JET_A_TYPE) {
        _fboTiers = (jetADiscount || bothJetaandSafDiscount) ? params.discountTiers : params.tiersJetA;
      } else if (getFuelType === SafAndJetAType.SAF_TYPE) {
        _fboTiers =
          discountFuelType === constants.discount.saf ? discountTiers : discountFuelType === constants.discount.jetA_Saf_Both 
          ? secondDiscountTier : params.tiersSAF;
      }
      const tier = _fboTiers?.find((tier, idx) => {
        if (tier.limitHigh === tier.limitLow && volume >= tier.limitHigh) {
          return tier;
        } else if (volume <= tier.limitHigh && volume >= tier.limitLow) {
          return tier;
        } else if (
          tier.limitHigh === tier.limitLow &&
          volume < tier.limitHigh
        ) {
          return tier;
        }
      });
      let retailDiscountPrice = "";
      let retailDiscountPriceLL = "";
      if (fuelselectedType === constants.fuelType.HUNDRED_LL) {
        retailDiscountPriceLL =
          volume !== 0 && volume !== "" && discountFuelType === constants.discount.hundredLL
          ? discountTiers[0].retailPrice
            : retailPriceLL;
      } else {
        if (getFuelType === SafAndJetAType.SAF_TYPE) {
          retailDiscountPrice =
            volume !== 0 && volume !== "" && !!tier
              ? tier.retailPrice : params.discountFuelType === constants.discount.saf && volume !== 0 && volume !== "" ?
              discountTiers[0].retailPrice : secondDiscountTier?.length > 0 && volume !== 0 && volume !== "" ?
              secondDiscountTier[0].retailPrice : retailPriceSAF;
        } else {
          retailDiscountPrice =
            volume !== 0 && volume !== "" && !!tier
              ? tier.retailPrice
              : (jetADiscount || bothJetaandSafDiscount) && discountTiers.length > 0 && volume !== 0 && volume !== ""
              ? discountTiers[0].retailPrice
              : retailPrice;
        }
      }
      setrate =
        fuelselectedType === constants.fuelType.HUNDRED_LL
          ? retailDiscountPriceLL
          : retailDiscountPrice;
      setoriginalRate =
        fuelselectedType === constants.fuelType.HUNDRED_LL
          ? retailPriceLL
          : getFuelType === SafAndJetAType.SAF_TYPE
          ? retailPriceSAF
          : retailPrice;

      resolve({ setrate: setrate, setoriginalRate: setoriginalRate });
    } catch (error) {
      reject(error);
    }
  });
};



export const setWaiveoff = (params, obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      let setWaiveOff = '';
      let parkingfee = 0;
      let mtowLbLocal = 0;
      let setIsRampFeeLineWillShow = false;
      if (
        params.rampFeeType === RampFeeType.MTOW_RAMPFEE ||
        params.rampFeeType === RampFeeType.MLW_RAMPFEE ||
        params.rampFeeType === RampFeeType.PARKING_AREA_RAMPFEE
      ) {
        if (params.waiveOffTier?.length > 0) {
          switch (params.rampFeeType) {
            case RampFeeType.MTOW_RAMPFEE:
              mtowLbLocal = obj?.airplaneDetail?.mtowLb;
              break;
            case RampFeeType.MLW_RAMPFEE:
              mtowLbLocal = obj?.airplaneDetail?.mlwLbs;
              break;
            case RampFeeType.PARKING_AREA_RAMPFEE:
              mtowLbLocal = obj?.airplaneDetail?.parkingAreaSf;
              break;
            default:
              mtowLbLocal = 0;
              break;
          }
          let willApplyWaiveOff = false;
          let WaiveOffTierObj = {};
          params.waiveOffTier.map((item) => {
            if (mtowLbLocal >= item.limitLow && mtowLbLocal <= item.limitHigh) {
              willApplyWaiveOff = true;
              WaiveOffTierObj = item;
            }
          });
          if (willApplyWaiveOff) {
            setWaiveOff=WaiveOffTierObj.waiver;
            parkingfee = WaiveOffTierObj.parkingFee;
            setIsRampFeeLineWillShow=true;
          } else {
            parkingfee = 0;
            setIsRampFeeLineWillShow=false;
          }
        } else {
          parkingfee = 0;
          setIsRampFeeLineWillShow=false;
        }
      } else if (params.rampFeeType === RampFeeType.CUSTOM_RAMPFEE) {
        const filterCustomeRampFee = await params.customRampFee?.filter(
          (item) => item.categoryId === obj?.airplaneDetail?.airplaneCategoryId
        );
        if (filterCustomeRampFee?.length === 0) {
          parkingfee = 0;
          setIsRampFeeLineWillShow=false;
        } else if (
          filterCustomeRampFee?.length === 1 &&
          filterCustomeRampFee[0]?.rampFeeType == null
        ) {
          const WaiveOffTierObj = filterCustomeRampFee[0];
          setWaiveOff=WaiveOffTierObj.waiveOff;
          parkingfee = WaiveOffTierObj.rampFee;
          setIsRampFeeLineWillShow=true;
        } else {
          const rampFeeTypeLocal = filterCustomeRampFee[0].rampFeeType;
          let mtowLbLocal = 0;
          switch (rampFeeTypeLocal) {
            case RampFeeType.MTOW_RAMPFEE:
              mtowLbLocal = obj?.airplaneDetail?.mtowLb;
              break;
            case RampFeeType.MLW_RAMPFEE:
              mtowLbLocal = obj?.airplaneDetail?.mlwLbs;
              break;
            case RampFeeType.PARKING_AREA_RAMPFEE:
              mtowLbLocal = obj?.airplaneDetail?.parkingAreaSf;
              break;
            default:
              mtowLbLocal = 0;
              break;
          }
          let willApplyWaiveOff = false;
          let WaiveOffTierObj = {};
          filterCustomeRampFee?.map((item) => {
            if (mtowLbLocal >= item.min && mtowLbLocal <= item.max) {
              willApplyWaiveOff = true;
              WaiveOffTierObj = item;
            }
          });
          if (willApplyWaiveOff) {
            setWaiveOff=WaiveOffTierObj.waiveOff;
            parkingfee = WaiveOffTierObj.rampFee;
            setIsRampFeeLineWillShow=true;
          } else {
            parkingfee = 0;
            setIsRampFeeLineWillShow=false;
          }
        }
      } else if (params.rampFeeType === RampFeeType.NOZL_SIZE_RAMPFEE) {
        if (params?.nozlSizeRampFee?.length > 0) {
          const filterNozlSizeRampFee = params?.nozlSizeRampFee?.filter(
            (item) =>
              item.categoryId === obj?.airplaneDetail?.airplaneNozlSizeCategoryId
          );
          if (filterNozlSizeRampFee?.length === 0) {
            parkingfee = 0;
            setIsRampFeeLineWillShow=false;
          } else {
            setWaiveOff=filterNozlSizeRampFee[0].waiveOff;
            parkingfee = filterNozlSizeRampFee[0].rampFee;
            setIsRampFeeLineWillShow=true;
          }
        } else {
          parkingfee = 0;
          setIsRampFeeLineWillShow=false;
        }
      } else if (params.rampFeeType === RampFeeType.FILE_UPLOAD_RAMPFEE) {
        setIsRampFeeLineWillShow=false;
      }
      resolve({"parkingfee":parkingfee,"setIsRampFeeLineWillShow":setIsRampFeeLineWillShow,"setWaiveOff":setWaiveOff })
    } catch (error) {
      reject(error);
    }
  })
};
