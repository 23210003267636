import React, { useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../../assets/styles/HomeStyles";
import colors from "../../../../constants/colors";
import { isMobile } from "react-device-detect";
import SearchAddress from "./SearchAddress";
import AdvancedFilter from "./AdvancedFilter";
import AircraftSelection from "./AircraftSelection";
import filterClose from "../../../../assets/images/filterClose.png";
import {
  advancedSearch,
  rangeValue,
} from "../../../../redux/account/account.actions";
import { getAircraftList } from "../../../../redux/account/account.actions";

const HomeBar = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(colors, isMobile);
  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const [slider, setSlider] = useState(0);
  const [onSearch] = useState(props.onSearchValue);
  const [value, setValue] = useState(props.searchText);

  const { account, auth } = useSelector((state) => ({
    account: state.account,
    auth: state.auth,
  }));

  // get aircraft list
  useEffect(() => {
    dispatch(getAircraftList({ token: auth.token }));
  }, [auth.token]);

  useEffect(() => {
    if (account?.advanceSearch.length > 0) {
      setIsFilter(true);
      setFilters(account?.advanceSearch);
    }
    if (account?.slidervalue !== 0) {
      setIsFilter(true);
      setSlider(account?.slidervalue);
    }
    if (account?.advanceSearch?.length === 0 && account?.slidervalue === "") {
      setIsFilter(false);
    }
  }, [props, account?.userSelectedAircraft]);

  const getText = (value) => {
    props.getText(value);
  };

  const handleSearchClick = (value) => {
    props.onSearchClick(value);
  };

  const filterValues = (filters, sliderValue) => {
    if (sliderValue > 0 || filters.length > 0) {
      setIsFilter(true);
      if (sliderValue > 0) {
        setSlider(sliderValue);
        dispatch(rangeValue(sliderValue));
      }
      if (filters.length > 0) {
        setFilters(filters);
        dispatch(advancedSearch(filters));
      }
    } else {
      setFilters([]);
      setSlider(sliderValue);
      setIsFilter(false);
    }
  };

  const removeFilterItem = (item) => {
    const filterList = filters.filter((filterValue) => filterValue !== item);
    setFilters(filterList);
    dispatch(advancedSearch(filterList));
  };

  const removeSlider = () => {
    setSlider(0);
    dispatch(rangeValue(0));
  };

  const searchAirportResult = (listResult) => {
    props.searchAirportList(listResult);
  };

  const clearTexts = (value) => {
    props.clearText("");
  };

  return (
    <div
      className={
        isFilter === false
          ? classes.notFilterSearchContainer
          : classes.filterSearchContainer
      }
    >
      <Card
        className={
          isFilter === false
            ? classes.notFilterSearchcard
            : classes.filterSearchcard
        }
      >
        <Grid container item xs={12}>
          <Grid item xs={12} md={2} className={classes.filterCont}>
            <AircraftSelection />
          </Grid>
          <Grid item xs={12} md={8} className={classes.searchbody}>
            <SearchAddress
              value={value}
              setValue={setValue}
              searchText={props.searchText}
              icao={searchAirportResult}
              onClick={handleSearchClick}
              textValue={onSearch.text}
              getText={getText}
              currentLatLong={props.currentLatLong}
              userId={auth.uid}
              userData={auth}
              clearText={clearTexts}
            />
          </Grid>
          <Grid item xs={12} md={2} className={classes.filterCont}>
            <AdvancedFilter filters={filterValues} filterList={filters} setValue={setValue} setLocationLatLong={props.setLocationLatLong}/>
          </Grid>
        </Grid>
        {(filters !== undefined && filters.length > 0) || slider > 0 ? (
          <Grid container item xs={12} className={classes.filterBodyCont}>
            <div style={{ color: "#848484" }}>Filter by: </div>
            <div className={classes.filterStyle}>
              {filters !== undefined &&
                filters.length > 0 &&
                filters.map((item) => {
                  return (
                    <div className={classes.filterContainer}>
                      <div className={classes.filterItemStyle}>
                        {item.value}
                      </div>
                      <img
                        src={filterClose}
                        alt=""
                        onClick={() => removeFilterItem(item)}
                        className={classes.imageStyle}
                      />
                    </div>
                  );
                })}
              {slider > 0 && (
                <div className={classes.filterContainer}>
                  <div className={classes.filterItemStyle}>
                    Distance in range(0 - {slider} miles)
                  </div>
                  <img
                    src={filterClose}
                    alt=""
                    onClick={() => removeSlider()}
                    className={classes.imageStyle}
                  />
                </div>
              )}
            </div>
          </Grid>
        ) : null}
      </Card>
    </div>
  );
};

export default HomeBar;