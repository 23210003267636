import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PrimarySignUp from "../components/scene/signUp/PrimarySignUpFirstScreen";

const AppRoute = ({ component, path }) => {
  const auth = useSelector((state) => state.auth);
  const account  = useSelector((state) => state.account);
  const authRoutes = [
    "/login",
    "/signup"
  ];

  return (
    <Route
      render={(props) =>
        auth.token !== undefined &&
        auth.token !== null &&
        auth.token !== "" &&
        authRoutes.includes(path) ? (
         (Object.values(account.user).length === 0 && auth.status === 1) ? (
            <PrimarySignUp />
          ) : (
            auth.isActive === 1 &&
            (auth.status === 4 ||
              auth.status === 5 ||
              auth.status === 6) ? 
            <Redirect to="/home" />
            : <Route exact path={path} component={component} />
          )
        ) : (
          <Route exact path={path} component={component} />
        )
      }
    />
  );
};

export default AppRoute;
