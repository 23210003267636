import {
  Checkbox,
  FormControlLabel,
  Grid
} from "@material-ui/core";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../assets/styles/ProfileSummaryStyles";

const TermsAndConditions = (props) => {
  const classes = useStyles();
  const [checkTermsAndCond, setCheckTermsAndCond] = useState(false);
  const [checkPrivacyPolicyCond, setCheckPrivacyPolicyCond] = useState(false);
  const [clickButton, setClickButton] = useState("terms");
  const { account, auth } = useSelector((state) => ({
    account: state.account,
    auth: state.auth
  }));

  const handleChange = (event) => {
    let targetValue = event.target.checked;
    setCheckTermsAndCond(event.target.checked);
    if (targetValue === true) {
      props.acceptTermsandCondition(true);
    } else {
      props.acceptTermsandCondition(false);
    }
  };

  const handlePolicyChange = (event) => {
    let targetValue = event.target.checked;
    setCheckPrivacyPolicyCond(event.target.checked);
    if (targetValue === true) {
      props.acceptPrivacyPolicyCond(true);
    } else {
      props.acceptPrivacyPolicyCond(false);
    }
  };

  const changeClickButton = (value) => {
    setClickButton(value);
  };

  return (
    <Grid className={classes.mainTermsCont}>
      <Grid container className={classes.termsAndPolicyHeadingStyle}>
        <Grid
          item
          xs={6}
          className={
            clickButton === "terms"
              ? `${classes.termsTextHeading} ${classes.headingBorderColor}`
              : classes.termsTextHeading
          }
          onClick={() => changeClickButton("terms")}
        >
          TERMS AND CONDITIONS
        </Grid>
        <Grid
          item
          xs={6}
          className={
            clickButton === "privacy"
              ? `${classes.termsTextHeading} ${classes.headingBorderColor}`
              : classes.termsTextHeading
          }
          onClick={() => changeClickButton("privacy")}
        >
          PRIVACY POLICY
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.termsAndCondInnerCont}>
          <Grid item xs={12} className={classes.textStyle1}>
            Your agreement
          </Grid>
          {clickButton === "terms" ? (
            <Grid item xs={12} className={classes.textStyle2}>
              Service Terms & Conditions Use of this Service is governed by, and
              subject to, the Terms of Use located at [TERMS OF USE URL]. This
              Privacy Policy is incorporated into such terms. <br />
              Your use, or access, of the Service constitutes your agreement to
              be bound by these provisions. <br /> IF YOU DO NOT AGREE TO THESE
              TERMS AND CONDITIONS YOU MAY NOT ACCESS OR OTHERWISE USE THE
              SERVICE. United States: Our servers are maintained in the United
              States. By using the Service, you freely and specifically give us
              your consent to export your personal data to the United States and
              to store and use it in the United States as specified in this
              Privacy Policy. <br />
              You understand that data stored in the United States may be
              subject to lawful requests by the courts or law enforcement
              authorities in the United States. Governing Law: This Privacy
              Policy and our legal obligations hereunder are subject to the laws
              of Texas, U.S.A., regardless of your location. You hereby consent
              to the exclusive jurisdiction of and venue in the courts located
              in Harris County, Texas, U.S.A. in all disputes arising out of or
              relating to the Services. For EU Users: We comply with the General
              Data Protection Regulation 2016/679 framework as set forth by the
              European Union regarding the collection, use, and retention of
              personal data from European Union member countries and
              Switzerland. NOZL has certified that it adheres to the
              requirements of notice, choice, onward transfer, security, data
              integrity, access and enforcement. Contact: For questions or
              concerns relating to privacy, we can be contacted at
              info@iastech.org
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.textStyle2}>
              NOZL Privacy Policy Updated: May 21, 2019 NOZL (“NOZL,” "we," or
              "our"), recognizes the importance of your personal privacy.
              <br />
              Therefore, we have created this Privacy Policy so that you know
              how we use and disclose your data when you make it available to
              us.
              <br />
              The Privacy Policy below discloses our practices regarding data
              collection and usage for the website located at www.iastech.org,
              as well as the related mobile website and the mobile application
              called NOZL (the “App”) (collectively, the “Websites”), and the
              service offered thereon (the “Service”). By using or accessing the
              Service or participating in our services, you signify your
              agreement to be bound by our Privacy Policy. IF YOU DO NOT AGREE
              TO THIS PRIVACY POLICY YOU MAY NOT ACCESS OR OTHERWISE USE OUR
              SERVICE OR PARTICIPATE IN OUR SERVICES. Key aspects of our privacy
              practices described in this Privacy Policy include the following
              explanations: • The data we collect and why we collect it; • How
              we use that data; • How we share data; and • The choices we offer.
              Personal Data That We Collect: We do not collect personal data
              from you unless you voluntarily provide it to us. You may be
              required to submit certain personal data when you register on the
              Service. The personal data which you may provide to us could
              include, without limitation: • Your name; • Your contact
              information (including address, email address and phone number); •
              Payment information; • Registration information relating to your
              aircraft; and • Other personal data collected from you or through
              some other form of consent from you or notification to you.
              Non-Personal Or Aggregate Data That We Collect: When you access
              our Service, we may automatically collect non-personal data from
              you, such as IP host address, web pages viewed, browser type,
              operating system, referring service, search information, device
              type, page views, usage and browsing habits on the Service and
              similar data. We may also aggregate demographic information
              collected from our users (such as the number of users in a
              particular state or province) in a manner which does not identify
              any one individual.$
              <br />
              We may also aggregate information collected offline in connection
              with the Service or use of our services, obtain non-personal data
              from third party sources and develop aggregate data by anonymizing
              previously collected personal data. It is possible at times when
              collecting non-personal data through automatic means that we may
              unintentionally collect or receive personal data that is mixed in
              with the non-personal data.
              <br />
              While we will make reasonable efforts to prevent such incidental
              data collection, the possibility still exists.
              <br />
              If you believe that we have inadvertently collected your personal
              data, please notify us at info@iastech.org. Geo-Location
              Information:When you access or use the Service, NOZL may access,
              collect, monitor and/or remotely store “location data,” which may
              include GPS coordinates (including but not limited to latitude
              and/or longitude), IP addresses, and/or similar information
              regarding the actual location of your device. Location data may
              convey to us information about how you browse or use the Service.
              Location data does not collect or share any personally
              identifiable information about you. Location data may be used in
              conjunction with personally identifiable information. Some
              features of the Service, particularly any location-based services,
              may not function properly if use or availability of location data
              is impaired or disabled. Data Usage: We will only use your
              personal data as described below, unless you have specifically
              consented to another type of use, either at the time the personal
              data is collected from you or through some other form of consent
              from you or notification to you: • We may share your personal data
              collected in connection with providing the Service. • We may use
              your personal data to respond to your inquires or requests. • We
              may use your personal data to send you emails from time to time
              about our services, but we will not provide your personal data to
              third parties for them to contact you directly unless otherwise
              permitted by this Privacy Policy or you provide your consent. • We
              may permit our vendors and subcontractors to access your personal
              data, but they are only permitted to do so in connection with
              performing services for us.
              <br />
              They are not authorized by us to use the data for their own
              benefit. • If you are a Purchaser (as defined in our Terms of
              Use[LINK TO TERMS OF USE URL]), we may share your personal data
              with a Supplier for the purposes of facilitating your transaction.
              • We may share your personal data with third parties to further
              the purpose for which you provided such data to us (collectively,
              the “Third Party Vendors”). Specifically, we will share your data
              with the following Third Party Vendors: Third Party Vendor Privacy
              Policy Stripe https://stripe.com/privacy We urge you to read any
              third party's privacy practices, before submitting any personal
              data through the Service. • We may disclose personal data as
              required by law or legal process. • We may disclose personal data
              to investigate suspected fraud, harassment or other violations of
              any law, rule or regulation, or the terms or policies for our
              services or our sponsors. • We may transfer your personal data in
              connection with the sale or merger or change of control of NOZL or
              the division responsible for the services with which your personal
              data is associated. • We may share your personal data with an
              affiliate of NOZL who is in the same corporate family as us as
              long as their privacy practices are substantially similar to ours.
              Non- personally identifiable or aggregate personal data may be
              used by us for any purposes permitted by law and may be shared
              with any number of parties, provided that such data shall not
              specifically identify you. Communications with NOZL: By
              downloading the NOZL mobile application, you expressly consent to
              receive messages through the application's messaging system. By
              providing your email address to us, you expressly consent to
              receive emails from us.
              <br />
              We may use email to communicate with you, to send information that
              you have requested or to send information about other products or
              services developed or provided by us or our business partners,
              provided that, we will not give your email address to another
              party to promote their products or services directly to you
              without your consent or as set forth in this Privacy Policy. By
              providing your phone number to us, you expressly consent to
              receive phone calls and/or text messages from us. We will not give
              your phone number to another party to promote their products or
              services directly to you without your consent or as set forth in
              this Privacy Policy. Any phone calls and/or text messages
              delivered to your phone or device may cause you to incur extra
              data, text messaging, or other charges from your wireless carrier.
              MESSAGE AND DATA RATES MAY APPLY. You are solely responsible for
              any carrier charges incurred as a result of phone and/or text
              communications from NOZL. Any communication or material you
              transmit to us by email or otherwise, including any data,
              questions, comments, suggestions, or the like is, and will be
              treated as, non-confidential and nonproprietary.
              <br />
              Except to the extent expressly covered by this Privacy Policy,
              anything you transmit or post may be used by us for any purpose,
              including but not limited to, reproduction, disclosure,
              transmission, publication, broadcast and posting. Furthermore, you
              expressly agree that we are free to use any ideas, concepts,
              know-how, or techniques contained in any communication you send to
              us, as well as any data developed using the content of such
              communication, without compensation and for any purpose
              whatsoever, including but not limited to, developing,
              manufacturing and marketing products and services using such
              information. Cookies and Similar Technologies: “Cookies” are
              pieces of information that may be placed on your computer and
              mobile device by a service for the purpose of facilitating and
              enhancing your communication and interaction with that service.
              Many services use cookies for these purposes.
              <br />
              We do not currently use cookies on the Websites, but if we do in
              the future we will update this Privacy Policy. Analytics and
              Conversion Tracking:We use analytics services that use cookies,
              javascript and similar technologies to help us analyze how users
              use the Service. The information generated by these services about
              your use of the Service (including your IP address or a truncated
              version of your IP address) is transmitted to and stored by
              analytics service providers on their servers. Those service
              providers will use this information for the purpose of evaluating
              your, and other users', use of the Service, compiling reports for
              us on website activity and providing other services relating to
              website activity and Internet usage. We may collect information
              about your computer, including your IP address, operating system
              and browser type, for system administration and in order to create
              reports. This is statistical data about our users' browsing
              actions and patterns, and does not identify any individual. We use
              cookies on our site for Google Analytics. Google Analytics is a
              web analytics tool that helps website owners understand how
              visitors engage with their website. Google Analytics customers can
              view a variety of reports about how visitors interact with their
              website so that they can improve it. Like many services, Google
              Analytics uses first-party cookies to track visitor interactions
              as in our case, where they are used to collect information about
              how visitors use our site. We then use the information to compile
              reports and to help us improve our site. Cookies contain
              information that is transferred to your computer's hard drive.
              These cookies are used to store information, such as the time that
              the current visit occurred, whether the visitor has been to the
              site before and what site referred the visitor to the web page.
              Google Analytics collects information anonymously. It reports
              website trends without identifying individual visitors. You can
              opt out of Google Analytics without affecting how you visit our
              site. For more information on opting out of being tracked by
              Google Analytics across all websites you use, visit click. We also
              use Google conversion tracking and/or similar services to help us
              understand your and other users' use of the Service. Please see
              link for more information about conversion tracking provided by
              Google. Security:The security of your personal data is very
              important to us. When we collect your personal data online, we use
              reasonable efforts to protect it from unauthorized access.
              <br />
              However, due to the inherent open nature of the Internet, we
              cannot guarantee that your personal data will be completely free
              from unauthorized access by third parties such as hackers and your
              use of our Service and NOZL services demonstrates your assumption
              of this risk.
              <br />
              We have put in place reasonable physical, electronic, and
              managerial procedures to safeguard the data we collect. Only those
              employees who need access to your data in order to perform their
              duties are authorized to have access to your personal data.For
              more information on protecting your privacy, please visit
              www.ftc.gov/privacy.We shall investigate and report data breaches
              within 72 hours of becoming aware of such breach. When a breach of
              Personal Data is likely to result in a high risk to the rights and
              freedoms of individuals then NOZL, as Controller, will notify the
              affected individuals without undue delay. Your Disclosures In
              Blogs And Other Social Media:You should be aware that personal
              data which you voluntarily include and transmit online in a
              publicly accessible blog, chat room, social media platform or
              otherwise online, or that you share in an open forum such as an
              in-person panel or survey, may be viewed and used by others
              without any restrictions.
              <br />
              We are unable to control such uses of your personal data, and by
              using such services you assume the risk that the personal data
              provided by you may be viewed and used by third parties for any
              number of purposes. If you choose to connect with a social
              networking service, such as Facebook, we may share data with that
              service, and that service may share data about you with us.
              Uploading Content:You agree that, by uploading content to the
              Service, you are sharing that content with other users and/or
              visitors of the Service. This content could include, but is not
              limited to, text, images, audio or video content. If you do not
              wish for information to be shared publicly, do not upload such
              information to the Service. Protection for Children:We generally
              do not collect personal data from children under the age of 16.
              <br />
              If at any time in the future we plan to collect personal data from
              children under 16, such collection and use, to the extent
              applicable, shall, when required, be done in compliance with the
              General Data Protection Regulation (“GDPR”). If at any time in the
              future we plan to collect personal data from children under 13,
              such collection and use, to the extent applicable, shall, when
              required, be done in compliance with the Children's Online Privacy
              Protection Act (“COPPA”) and appropriate consent from the child's
              parent or guardian will be sought where required by COPPA.
              <br />
              When we become aware that personal data from a child under 13 has
              been collected without such child's parent or guardian's consent,
              we will use all reasonable efforts to delete such data from our
              database. Other Services: As a convenience to you, we may provide
              links to third-party Services from within our Service. We are not
              responsible for the privacy practices or content of these
              third-party sites.
              <br />
              When you link away from our Service, you do so at your own risk.
              Changes to this Privacy Policy: We reserve the right, at our
              discretion, to change, modify, add, or remove portions from this
              Privacy Policy at any time.
              <br />
              However, if at any time in the future we plan to use personal data
              in a way that materially differs from this Privacy Policy,
              including sharing such data with more third parties, we will email
              you and give you the opportunity to review the revised policy
              before you choose to continue using our Service. User Access to
              Data: You have the right to request access to the data information
              we have stored on you. You can do this by contacting us at
              info@iastech.org. We will make sure to provide you with a copy of
              the saved data particular to you. In order to comply with your
              request, we may ask you to verify your identity. We will fulfill
              your request by sending an electronic copy unless you expressly
              specify a different method of receipt. Data Correction and
              Deletion: If you believe the data NOZL has collected on you is
              incorrect, you are encouraged to contact us to update it. Any data
              that is no longer needed for the purposes specified in this Policy
              will be deleted. You may request to have your data updated or
              deleted at any point by emailing us at info@iastech.org. Opt-Out
              Process: You may unsubscribe or opt-out from emails by removing
              your email address from your account, or by clicking the
              “unsubscribe” link in any email, and we will process your request
              within a reasonable amount of time. However, we are not
              responsible for, and in some cases we are incapable of, removing
              your personal data from the lists of any third party who has
              previously been provided your data in accordance with this Privacy
              Policy or your consent.
              <br />
              You should contact such third parties directly. No Rights of Third
              Parties: This Privacy Policy does not create rights enforceable by
              third parties, nor does it require disclosure of any personal data
              relating to users of the Service. California Online Privacy
              Protection Act CalOPPA is the first state law in the nation to
              require commercial websites and online services to post a privacy
              policy. The law's reach stretches well beyond California to
              require any person or company in the United States (and
              conceivably the world) that operates websites collecting
              Personally Identifiable Information from California consumers to
              post a conspicuous privacy policy on its website stating exactly
              the information being collected and those individuals or companies
              with whom it is being shared. - See more at:
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
              According to CalOPPA, we agree to the following: • Users can visit
              our Website anonymously. • Once this privacy policy is created, we
              will add a link to it on our home page or as a minimum, on the
              first significant page after entering our Website. • Our Privacy
              Policy link includes the word "Privacy" and can easily be found on
              the page specified above. You will be notified of any Privacy
              Policy changes: • Via email and on our Privacy Policy page Can
              change your personal data: • By emailing us How does our Website
              handle Do Not Track signals? CalOPPA requires us to let you know
              how we respond to “Do Not Track” (“DNT”) signals. At the moment,
              there is no uniform industry or legal standard for recognizing or
              honoring DNT signals; therefore, we do not honor DNT requests at
              this time. Does our Website allow third-party behavioral tracking?
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.checkboxCont}
        style={{ marginBottom: 10, marginTop: -3 }}
      >
        <FormControlLabel
          className={classes.checkboxStyle}
          control={
            <Checkbox
              classes={{
                root:
                  (account.isParagonUser !== undefined &&
                  account.isParagonUser === true)  || auth.isIframe
                    ? classes.paragonRoot
                    : classes.root,
                checked: classes.checked,
              }}
              checked={checkTermsAndCond}
              onChange={handleChange}
              name="termsAndCondition"
            />
          }
          label={
            <span className={classes.checkboxStyle}>
              Do you agree to NOZL's Terms & Conditions?
            </span>
          }
        />
      </Grid>
      <Grid item xs={12} className={classes.checkboxCont}>
        <FormControlLabel
          className={classes.checkboxStyle}
          control={
            <Checkbox
              classes={{
                root:
                  (account.isParagonUser !== undefined &&
                  account.isParagonUser === true)  || auth.isIframe
                    ? classes.paragonRoot
                    : classes.root,
                checked: classes.checked,
              }}
              checked={checkPrivacyPolicyCond}
              onChange={handlePolicyChange}
              name="privacyPolicy"
            />
          }
          label={
            <span className={classes.checkboxStyle}>
              Do you agree to NOZL's Privacy Policy?
            </span>
          }
        />
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
