import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";

import { configureStore, history } from "../src/redux/store";
import { ToastContainer } from 'react-toastify';

import "./index.css";
import App from "./App";
import Routes from "./routes";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {version} from "../package.json"

const { store, persistor } = configureStore;

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <App>{Routes}</App>
        <ToastContainer
          autoClose={1800}
          hideProgressBar={true}
          newestOnTop={true}
          closeButton={false}
          position={'bottom-center'}
        />
      </ConnectedRouter>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById("root")
);
if (process.env.REACT_APP_SENTRY_ENV === 'staging' || process.env.REACT_APP_SENTRY_ENV === 'production') {
  Sentry.init({
    dsn: "https://14c2b1df631c42a580338cf777587717@o460774.ingest.sentry.io/4504020019445760",
    integrations: [new BrowserTracing()],
    release: version,
    environment: process.env.REACT_APP_SENTRY_ENV,
    tracesSampleRate: 1.0
  })
}
