import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Modal, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import Styled from "styled-components";
import FboButtons from "./FboButtons";
import FboItemPrice from "./FboItemPrice";
import NozlRatingBar from "../../../ui/NozlRatingBar";
import { useStyles } from "../../../../assets/styles/FboDetailStyles";
import bookmarkFill from "../../../../assets/images/bookmarkFill.png";
import {
  favouriteFboItem,
  markFavoriteFbo,
  unmarkFavoriteFbo,
} from "../../../../redux/fbos/fbos.actions";
import bookmarkOutline from "../../../../assets/images/bookmarkOutline.png";
import NudgePopup from "./NudgePopup";
import InternationalServies from "./InternationalServices";
import FboLogo from "./FboLogo";
import DiscountType from "../../../../config/constants";

const StyledGrid = styled.div`
  overflow: auto;
  display: flex-wrap;
`;

const StyledName = Styled(Grid)`
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function FboDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { item, airportName, isUnlisted } = props;
  const [data, setData] = useState(item);
  const [openModal, setOpenModal] = useState(false);

  const { auth, account, fbos } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
    fbos: state.fbos,
  }));

  const fuelType =
    account?.userSelectedAircraft !== undefined &&
    Object.values(account?.userSelectedAircraft).length > 0
      ? account?.userSelectedAircraft?.airplaneDetail?.fuelType
      : 0;

  const fboFuelType =
    fuelType === 0
      ? item.isJetAFuel === 1 || item.is100LLFuel === 1 || item.isSAFFuel === 1
      : (fuelType === 1 && item.isJetAFuel === 1) || (fuelType === 1 && item.isSAFFuel === 1) ||
        (fuelType === 2 && item.is100LLFuel === 1);

  const _100LLDiscount =
    item.discountFuelType === DiscountType.discount.hundredLL
      ? item.discountTiers[0]?.retailPrice
      : item.retailPriceLL;

  const fuelAvailability =  () => (
     (item.is100LLFuel !== 0 || item.isJetAFuel !== 0 || item.isSAFFuel !== 0) && (!!account?.userSelectedAircraft && Object.values(account?.userSelectedAircraft).length > 0) ?
    (account.userSelectedAircraft.airplaneDetail.fuelType === 2 && item.is100LLFuel === 0) ? "FBO does not provide 100LL type fuel." :
      "FBO does not provide Jet A and SAF type fuel."
    : "This FBO is not providing fuel services but you can still send an Arrival Request."
  )

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = (value, nudgeCount) => {
    if (nudgeCount === 1) {
      let list = data;
      list.nudgeCount = 1;
      setData(list);
    }
    setOpenModal(false);
  };

  useEffect(() => {
    setData(item);
  }, [item]);

  let offerVal = "";

  if (
    item.discountCount === 1 ||
    item.operatorRegistryDiscountCount === 1 ||
    item.operatorSpecificDiscountCount === 1 ||
    item.tailWiseDiscountCount === 1
  ) {
    offerVal = "Discounted";
  }

  const handleParentClick = (e) => {
    props.selectFboItem(data, true, !isUnlisted);
  };

  const handleChildClick = (e) => {
    handleOpen();
  };

  useEffect(() => {}, [fbos.currentFavoriteFbo, item]);

  const selectFavouriteFbo = async () => {
    let payload = {
      token: auth.token,
    };
    if (data.userId) {
      payload.fboUserId = data.userId;
      payload.type = 1;
    } else {
      payload.fboUserId = data._id;
      payload.type = 2;
    }
    if (data.isFavourite === 0) {
      dispatch(markFavoriteFbo(payload));
      dispatch(favouriteFboItem({}));
      let newData = data;
      newData.isFavourite = 1;
      setData(newData);
    } else {
      let params = {
        fboUserId:
          data.favouriteFboId === undefined
            ? fbos.currentFavoriteFbo
            : data.favouriteFboId,
        token: auth.token,
      };
      dispatch(unmarkFavoriteFbo(params));
      dispatch(favouriteFboItem({}));
      let newData = data;
      newData.isFavourite = 0;
      setData(newData);
    }
  };

  const handleEditText = (e) => {
    e.stopPropagation();
  };


  return (
    <StyledGrid container className={classes.mainContainer}>
      <Grid item xs={12}>
        <Grid container item xs={12} className={classes.imageCont}>
          <FboLogo logo={data.logo} navigation="fboDetail" />
        </Grid>
        <Grid container className={classes.innerCont}>
          <Grid item xs={12}>
            <Grid container className={classes.detailContainer}>
              <Grid item xs={10} className={classes.detailCont}>
                <StyledName>
                  <Tooltip title={data.fboName}>
                    <div className={classes.nameTextStyle}>{data.fboName}</div>
                  </Tooltip>
                </StyledName>
                <Grid className={classes.textStyle}>{airportName}</Grid>
                <NozlRatingBar
                  style={classes.rating}
                  rate={data.rating}
                  isUnlisted
                  navigation="fboDetail"
                />
                <Grid className={classes.textStyle}>{data.icaoCode}</Grid>
              </Grid>
              <Grid
                item
                xs={2} className={classes.markeFboIconCont}
              >
                <img
                  src={data.isFavourite === 1 ? bookmarkFill : bookmarkOutline}
                  onClick={selectFavouriteFbo}
                  className={classes.iconStyle}
                  alt="favorite fbo"
                />
              </Grid>
            </Grid>
            {Object.values(item).length>0?
            <Grid>
                <FboButtons
                  offerVal={offerVal}
                  item={data}
                  account={account}
                  nudgeCountValue={item.nudgeCount}
                  handleParentClick={handleParentClick}
                  handleChildClick={handleChildClick}
                  navigation="fromDetail"
                />
            {!isUnlisted ? (
              <Grid container className={classes.fuelTypeCont}>
                <Grid item xs={12} className={classes.fuelTypeTextStyle}>
                  Fuel Type
                </Grid>
              </Grid>
            ) : null}
            <Grid>
              {fboFuelType ? (
                <FboItemPrice
                  item={data}
                  _100LLDiscount={_100LLDiscount}
                  fuelType={fuelType}
                  isUnlisted={isUnlisted}
                  value="fromFboDetail"
                />
              ) : (
                !isUnlisted && 
               (<Grid className={classes.noFueltypeTextStyle}>
                  {fuelAvailability()}
                  </Grid>)
                  )}
                </Grid>
                <Grid item xs={12} className={classes.servicesCont}>
                  <InternationalServies
                    item={item?.internationalFeeTaxes?.length > 0 ? item.internationalFeeTaxes : fbos.fboDefaultServices}
                    navigation="fboDetail"
                  />
                </Grid>
              </Grid>
              : (<Grid className={classes.noFueltypeTextStyle}>
                There are no FBOs available at this airport.
              </Grid>)
            }
          </Grid>
        </Grid>
      </Grid>
      <Modal
        disableBackdropClick={false}
        open={openModal}
        onBackdropClick={handleClose}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.nudgeModal}
      >
        <NudgePopup
          item={item}
          handleClose={handleClose}
          handleEditText={handleEditText}
        />
      </Modal>
    </StyledGrid>
  );
}
