import sessionWarning from '../assets/images/sessionWarning.png'
import { Grid } from "@material-ui/core";
import { useStyles } from '../assets/styles/SessionExpireStyles';

const SessionExpireMsg = () => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid container item xs={12} className={classes.mainCont}>
                <Grid item xs={12}>
                    <img alt="" src={sessionWarning} className={classes.imageStyle} />
                </Grid>
                <Grid item xs={12} className={classes.mainHeading}>
                    Session Timeout!!
                </Grid>
                <Grid item xs={9} className={classes.subHeading}>
                    You are being timed out. Please login again.
                </Grid>
                <Grid item xs={6} className={classes.buttonStyle}>
                    OK
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SessionExpireMsg