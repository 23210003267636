import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
  },
  memberStyle: {
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
    height: 40,
    textAlign: "right",
    background: "#fff",
  },
  memberStyle1: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    height: 40,
    textAlign: "right",
    background: "#fff",
  },
  mainText: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "32px",
    height: 32,
    letterSpacing: "-0.25px",
    color: "#666666",
    marginTop: 60,
  },
  buttonCont: {
    marginTop: 40,
    height: 72,
    background: "#ffffff",
  },
  buttonTextStyle: {
    width: 350,
    height: 72,
    fontSize: 20,
    paddingLeft: 40,
    borderRadius: 5,
    display: "flex",
    cursor: "pointer",
    color: "#333",
    fontWeight: "500",
    fontFamily: "Arial",
    alignItems: "center",
    boxSizing: "border-box",
    border: "1px solid #dfe5e9",
    backgroundPosition: "right center",
    "&:hover": {
      color: "#333",
      boxShadow: "0 4px 8px 0 rgba(153, 153, 153, 0.4)",
    },
  },
  nextButtonStyle: {
    height: 40,
    background: "#666666",
    textTransform: "initial",
  },
  footerStyle: {
    height: 40,
    marginBottom: 15,
  },
  firstContainer: {
    width: "100%",
  },
  nextButtonCont: {
    width: "100%",
    display: "flex",
    textTransform: "initial",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    borderRadius: 5,
    background: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
    color: "#fff",
    cursor: "pointer",
    transition: "0.5s",
    "&:hover": {
      background: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
      backgroundColor: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
    },
  },
  nextButtonWithoutSelected: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    opacity: 0.4,
    borderRadius: 5,
    background: colors.primaryColor,
    color: "#fff",
    textTransform: "initial",
    cursor: "not-allowed",
  },
  paagonNextButtonWithoutSelected: {
    background: colors.paragonPrimaryColor,
    "&:hover": {
      background: colors.paragonPrimaryColor,
      backgroundColor: colors.paragonPrimaryColor,
    },
  },
  linkStyle: {
    cursor: "pointer",
    color: "#212121",
    fontWeight: "bold",
    textDecoration: "none",
  },
}));

export { useStyles };
