import React, { useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import "./services.css";

import { components } from "react-select";
import { makeStyles } from "@material-ui/core";
import FieldSet from "./FieldSet";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";

const SearchInput = styled.div`
  width:  358px;
  margin-bottom: 10px;
  z-index: 99;
  display: block;
  margin-top: 10px;
  .select__input {
    color: transparent;
  }
`;

const StyledLabelDiv = styled.div`
  color: #333;
  font-weight: bold;
  font-size: 14px;
`;

const StyledPriceLabel = styled.div`
  color: #333;
  font-size: 14px;
  margin-top: 2px;
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "358px",
    border: "none",
    outline: "none",
    fontSize: "1rem",
    borderStyle: "none",
    boxShadow: "0 !important",
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      width: "100%",
      display: "flex",
      marginTop: 8,
      alignItems: "center",
      color: "#333",
      backgroundColor: "#fff",
      overflow: "ellipsis",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: "#fff",
      },
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      color: "#333",
      background: "#F0F0F3",
      fontSize: "16px",
      height: 28,
      lineHeight: "28px",
      borderRadius: 5,
      marginRight: 10
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#333",
    width: "100%",
    display: 'flex',
    alignItems:'center',
    fontSize: 14,
    letterSpacing: "normal",
    borderRadius: 5,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#42526E",
    fontWeight: "bold",
  }),
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: "#999",
    };
  },
  input: (provided) => {
    return {
      ...provided,
      width: "100%",
    };
  },
};

const useStyles = makeStyles(() => ({
  optionStyle: {
    width: "100%",
    display: "flex",
    cursor: "pointer",
  },
  optionStyleCont: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "space-between",
  },
  checkboxCont: {
    display: "flex",
    alignItems: "center",
  },
  checkboxInput: {
    width: 18,
    height: 18,
    cursor: "pointer"
  }
}));

const InternationalServies = (props) => {
  const classes = useStyles();
  const [checkedServicesInfo, setCheckedServicesInfo] = useState([]);

  useEffect(() => {
    let list =props.internationalService.map((d) => {
      return d.price !== undefined ? {
        value: d._id,
        label: d.name,
        price: `$${formatNumWithCommas(parseFloat(d.price).toFixed(2))}`,
      } :  {
        value: d._id,
        label: d.name
      }
    })
    setCheckedServicesInfo(list);
  }, [props.internationalService]);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div className={classes.optionStyle}>
            <div className={classes.optionStyleCont}>
              <StyledLabelDiv>{props.data.label}</StyledLabelDiv>
              {!!props.data.price ?<StyledPriceLabel>{props.data.price}</StyledPriceLabel>:null}
            </div>
            <div className={classes.checkboxCont}>
              <input
                type="checkbox"
                checked={props.isSelected}
                className={classes.checkboxInput}
                onChange={() => null}
              />
            </div>
          </div>
        </components.Option>
      </div>
    );
  };

  const handleChange = (selectedOption) => {
    props.onChange(selectedOption);
  };

  return (
    <SearchInput>
      <FieldSet legend={"Services & Fees"} navigation="internationalServices">
        <Select
          isMulti
          menuPlacement="top"
          placeholder="Select Services & Fees"
          options={checkedServicesInfo}
          className="mainStyle"
          classNamePrefix="select"
          onChange={handleChange}
          autosize={false}
          components={{
            Option,
          }}
          styles={customStyles}
          allowSelectAll={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
        />
      </FieldSet>
    </SearchInput>
  );
};

export default InternationalServies;
