import { createAsyncActionTypes } from "../utils";

export const USERS_LIST = createAsyncActionTypes("USERS_LIST");

export const AIRCRAFTS_LIST = createAsyncActionTypes("AIRCRAFTS_LIST");

export const REGISTER_AS = createAsyncActionTypes("REGISTER_AS");

export const BASIC_DETAILS = createAsyncActionTypes("BASIC_DETAILS");

export const TIMELINE = createAsyncActionTypes("TIMELINE");