import React from "react";
import styled from "styled-components";
import { Typography, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

import { useStyles } from "../../../../assets/styles/FboTiersStyles";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";
import TransactionDetailRowWithRateCross from "./TransactionDetailRowWithRateCross";

const StyledledAccordion = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    border: "none",
    boxShadow: "none",
    minHeight: "100%",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "0px",
      padding: 0
    },
  },
  expanded: {},
})(MuiAccordion);

const StyledAccordionSummary = withStyles({
  root: {
    borderBottom: "none",
    marginBottom: -1,
    padding: 0,
    minHeight: 32,
    maxHeight: 32,
    "&$expanded": {
      minHeight: 32,
      maxHeight: 32,
    },
  },
  content: {
    "&$expanded": {
      margin: "0px",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const StyledAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    flexGrow: 1,
  },
}))(MuiAccordionDetails);

const StyledGrid = styled.div`
  overflow: auto;
  display: flex-wrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ShowJETASAFTiers = (props) => {
  const { tiers, item, navigation } = props;
  const classes = useStyles();

  const isDiscount = (val) => (
    val ? !item.isParagonUser ? "(NOZL Direct)" : "(Paragon Preferred)" : ""
  )

  const renderHeading = () => (
    navigation === "saf" ? `SAF ${isDiscount(item.isSAFPreferredDiscount)
      }` : `Jet A ${isDiscount(item.isJetPreferredDiscount)
      }`
  )

  const renderView = (tempTiers, retailPrice) =>
    tempTiers.map((tier, idx) => {
      const label =
        tier.limitLow === tier.limitHigh
          ? `${tier.limitLow}+`
          : `${tier.limitLow} - ${tier.limitHigh}`;
      return (
        <TransactionDetailRowWithRateCross
          key={idx}
          label={label}
          navigation="fromDetail"
          value={`$${formatNumWithCommas(parseFloat(tier?.retailPrice).toFixed(2))}/gal`}
          valueMeta={`$${formatNumWithCommas(parseFloat(retailPrice).toFixed(2))}/gal`}
          tier={tier}
        />
      );
    });

  return (
    <StyledGrid>
      {tiers?.length > 0 ?
      <div className={classes.mainCont}>
        <StyledledAccordion>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.root} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{ content: classes.content, expanded: classes.expanded }}
          >
            <Typography className={classes.headingTextStyle}>
              {renderHeading()}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails className={classes.detailStyle}>
            {renderView(tiers, navigation === "saf" ? item.retailPriceSAF : item.retailPrice)}
          </StyledAccordionDetails>
        </StyledledAccordion>
        </div>
        :
        <div className={classes.container}>
        <div className={classes.headingTextStyle}>
          {renderHeading()}
        </div>
        <div className={classes.headingTextStyle}>
          {`$${formatNumWithCommas(
            parseFloat(navigation === "Jet A" ? item.retailPrice : item.retailPriceSAF).toFixed(2)
          )}/gal`}
        </div>
        </div>
      }
    </StyledGrid>
  )

}

export default ShowJETASAFTiers;
