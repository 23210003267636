import { makeStyles } from "@material-ui/core/styles";
import planeBg1 from "../../assets/images/planeBg1.png";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  inputFocused: {},
  inputLabel: {
    justifyContent: "center",
    alignContent: "center",
    width: "100% !important",
    color: "#555",
    "&$focusedLabel": {
      color: "cyan",
    },
  },
  pageBackground: {
    width: 400,
    display: "flex",
    background: "white",
    flexDirection: "column",
    justifyContent: "center",
  },
  main: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  topContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mainAmount: {
    alignSelf: "center",
    justifyContent: "center",
    fontFamily: "Arial",
    fontSize: "32px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "-0.5px",
    textAlign: "center",
    color: "#fff",
  },
  mainCont: {
    overflow: "auto",
    display: "flexWrap",
    width: "100%",
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
  },
  priceApplyed: {
    color: "#ccc",
    alignSelf: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: "bold",
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  priceApplyedText: {
    color: "#fff",
    alignSelf: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: "bold",
  },
  rampFeeTag: {
    marginTop: 5,
    color: "#fff",
    fontSize: 14,
    alignSelf: "center",
    justifyContent: "center",
    fontWeight: "300",
  },
  rampFeeTag1: {
    marginLeft: 5,
    color: colors.primaryColor,
    fontSize: 12,
    alignSelf: "center",
    justifyContent: "center",
    fontWeight: "600",
  },
  rampfeeStyle: {
    display: "flex",
  },
  imageStyle: {
    width: 15,
    height: 15,
  },
  changeTag: {
    color: "#fff",
    fontSize: 14,
    alignSelf: "center",
    lineHeight: 1.71,
    fontFamily: "Arial",
    letterSpacing: "normal",
    justifyContent: "center",
    fontWeight: "500",
  },
  initialPriceTextStyle: {
    marginTop: 5,
    color: "#333",
    fontSize: 14,
    alignSelf: "center",
    justifyContent: "center",
    fontWeight: "500",
  },
  textStyle: {
    marginTop: 10,
    color: "#333",
    fontSize: 12,
    width: "100%",
    textAlign: "center",
    alignSelf: "center",
    justifyContent: "center",
    fontWeight: "600",
  },
  formCard: {
    backgroundColor: "#fff",
    width: "100%",
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    border: "0px solid black",
    borderRadius: "5px!important",
  },
  regitry: {
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    marginTop: 10,
  },
  regitry2: {
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    marginTop: 10,
  },
  rightSide: {
    justifyContent: "center",
    alignContent: "center",
    width: "100% !important",
  },
  flyingRightSide: {
    justifyContent: "center",
    alignContent: "center",
    width: "97% !important",
  },
  flyingDetailFieldMargin: {
    marginLeft: 5,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  rows: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
  },
  dateArea: {
    marginTop: 16,
    borderColor: "#C4C4C4",
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
  },
  loginBtn: {
    background: "linear-gradient(270deg, rgb(63, 152, 255), rgb(71, 215, 231))",
    color: "#fff",
  },
  submit: {},
  originalRateMask: {
    color: "#fff",
    alignSelf: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: "bold",
  },
  notchedOutline: {
    color: "#AFABAB",
    hintColor: "#AFABAB",
    border: "1px solid #AFABAB",
    boxSizing: "border-box",
    background: "transparent",
    filter: "drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04))",
    borderRadius: 5,
    height: 54,
  },
  notchedOutline1: {
    color: "#000",
    hintColor: "#555",
    background: "transparent",
    border: "1px solid #000 !important",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04))",
    borderRadius: 5,
    height: 54,
  },
  notchedOutline2: {
    color: "#000",
    hintColor: "#555",
    background: "transparent",
    border: "1px solid #000 !important",
    borderRadius: 5,
  },
  floatingLabelFocusStyle: {
    color: "#000",
  },
  label: {
    "&$focusedLabel": {
      color: "white",
    },
    "&$erroredLabel": {
      color: "black",
    },
  },
  checkbox: {
    color: "#df2935",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      zIndex: -1,
    },
  },
  submitBtnFuel: {
    background: (colors) => colors.primaryColor,
    backgroundColor: (colors) => colors.primaryColor,
    color: "#fff",
    width: "95%",
    marginLeft: 5,
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: (colors) => colors.primaryColor,
    },
    height: 45,
  },
  imageCont: {
    width: "100%",
    height: 160,
    background: colors.imageBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paperContainer: {
    width: "100%",
    height: 170,
    justifyContent: "center",
    display: "flex",
    objectFit: "fill",
    alignItems: "center",
    backgroundImage: `url(${planeBg1})`,
    backgroundRepeat: "no-repeat",
    "-webkit-backdrop-filter": "blur(5px)",
    backdropFilter: "blur(5px)",
  },
  focusedLabel: {},
  erroredLabel: {},
  buttonContainer1: {
    width: 400,
    height: 70,
    display: "flex",
    position: "fixed",
    bottom: 0,
    background: "#fff",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    boxShadow: "0px 0px 2.5px 0px lightgrey",
  },
  buttonContainer: {
    width: 395,
    height: 70,
    display: "flex",
    position: "fixed",
    bottom: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    boxShadow: "0px 0px 2.5px 0px lightgrey",
  },
  checkboxContainer: {
    marginTop: 10,
    height: 30,
    textAlign: "left",
  },
  commentContainer: {
    marginTop: 10,
  },
  underline: {
    "&:after": {
      borderBottomColor: "red",
      borderWidth: "1px",
    },
  },
  ancillaryListStyle: {
    padding: 5,
    display: "flex",
    justifyContent: "center",
    margin: 5,
    border: "1px solid #DBE2EA",
    boxSizing: "border-box",
    boxShadow:
      "1px 1px 2px rgba(44, 39, 56, 0.0001), 2px 2px 4px rgba(44, 39, 56, 0.08)",
    borderRadius: "17.5px",
  },
  ancillaryListIconStyle: {
    color: "white",
    background: "#073178",
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    marginLeft: 6,
    marginTop: 2,
    cursor: "pointer",
  },
  floatingLabelFocus: {
    color: "#df2935",
  },
  helperText: {
    width: "100%",
    textAlign: "right",
  },
  selectStyle: {},
  servicesPriceStyle: {
    height: 18,
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#666666",
    flex: "none",
    order: 1,
    flexGrow: 0,
  },
  fuelTotalCont: {
    display: "flex",
  },
  fuelTextStyle: {
    height: "24px",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
  },
  priceCont: {
    display: "flex",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
  },
  iconStyle: {
    maxHeight: 160,
    maxWidth: 400,
    objectFit: "contain",
  },
  noFboStyle: {
    width: 80,
    height: 80,
  },
}));
export { useStyles };
