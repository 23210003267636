import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles(() => ({
  mainCont: {
    width: "100%",
    padding: 40,
  },
  iframeMainCont: {
    width: "100%",
    height: 150,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  innerCont: {
    marginLeft: 20,
    display: "flex",
    flexDirection: "column",
  },
  imageStyle: {
    width: 90,
    height: 40,
  },
  iframeImageStyle: {
    width: 180,
    height: 40,
  },
  paragonImageStyle:{
    width: 220,
    height: 50,
  },
  paragonIframeImageStyle:{
    width: "100%",
    height: 50,
  },
  innerContainer: {
    width: 149,
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  iframeInnerContainer: {
    width: "87%",
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    marginTop: 24,
  },
  stepsCont: {
    height: 40,
    width: "100%",
    flexGrow: 0,
  },
  countCont: {
    width: 149,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  iframeCountCont: {
    height: 50,
    cursor: "pointer",
    alignItems: "center",
  },
  countFinishedCont: {
    width: 34,
    height: 34,
    flexGrow: 0,
    opacity: 1,
    display: "flex",
    alignItems: "center",
    objectFit: "contain",
    backgroundColor: "transparent",
    justifyContent: "center",
  },
  iframeCountFinishedCont: {
    width: 34,
    height: 34,
    flexGrow: 0,
    opacity: 1,
    display: "flex",
    alignItems: "center",
    objectFit: "contain",
    backgroundColor: "transparent",
    justifyContent: "center",
  },
  countInnerCont: {
    width: 32,
    height: 32,
    flexGrow: 0,
    opacity: 1,
    display: "flex",
    alignItems: "center",
    borderRadius: 32 / 2,
    backgroundColor: "transparent",
    justifyContent: "center",
    objectFit: "contain",
  },
  unselectedCountInnerCont: {
    opacity: 0.5,
    width: 32,
    height: 32,
    flexGrow: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 32 / 2,
    backgroundColor: "#fff",
    justifyContent: "center",
    objectFit: "contain",
  },
  iframeUnselectedCountInnerCont: {
    opacity: 0.5,
    width: 32,
    height: 32,
    flexGrow: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 32 / 2,
    backgroundColor: "#fff",
    justifyContent: "center",
    objectFit: "contain",
  },
  activeCountInnerCont: {
    opacity: 1,
    width: 32,
    height: 32,
    flexGrow: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 32 / 2,
    backgroundColor: "#fff",
    justifyContent: "center",
    objectFit: "contain",
  },
  iframeActiveCountInnerCont: {
    opacity: 1,
    width: 32,
    height: 32,
    flexGrow: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: 32 / 2,
    backgroundColor: colors.paragonPrimaryColor,
    justifyContent: "center",
    objectFit: "contain",
  },
  nameStyle: {
    marginLeft: 10,
  },
  iframeNameStyle: {
    marginLeft: 0,
  },
  selectedNameStyle: {
    color: "#fff",
    fontWeight: "bold",
    opacity: 1,
  },
  iframeSelectedNameStyle: {
    color: colors.paragonPrimaryColor,
    fontWeight: "bold",
    opacity: 1,
  },
  activeNameStyle: {
    color: "#fff",
    fontWeight: "bold",
    opacity: 1,
  },
  iframeActiveNameStyle: {
    color: colors.paragonPrimaryColor,
    fontWeight: "bold",
    opacity: 1,
  },
  unselectedNameStyle: {
    opacity: "0.5",
    color: "#fff",
  },
  iframeUnselectedNameStyle: {
    opacity: "0.5",
    color: "#000",
  },
  lineStyle: {
    width: 1,
    height: 60,
    opacity: 0.5,
    background: "#fff",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 15,
  },
  iframeLineStyle: {
    width: 1,
    height: 60,
    opacity: 0.5,
    background: "#333",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 15,
  },
  iframePilotLineStyle: {
    width: 107,
    opacity: 0.5,
    marginLeft: 10,
    marginTop: 2,
    height: 1,
    background: "#000",
   
  },
  iframeNumberInactiveStyle: {
    color: "#333",
  },
  iframeNumberActiveStyle: {
    color: "#fff",
  },
  lineSelectedStyle: {
    width: 1,
    marginTop: 10,
    marginLeft: 15,
    marginBottom: 10,
    height: 60,
    opacity: 1,
    background: "#fff",
  },
  iframeLineSelectedStyle: {
    width: 1,
    marginTop: 10,
    marginLeft: 15,
    marginBottom: 10,
    height: 60,
    opacity: 1,
    background: "#000",
  },
  iframePilotLineSelectedStyle: {
    width: 107,
    marginTop: -19,
    marginLeft: 32,
    height: 1,
    opacity: 1,
    background: "#000",
  },
  checkImageStyle: {
    width: 32,
    height: 32,
    flexGrow: 0,
    objectFit: "contain",
  },
  imageCont: {
    width: "100%",
    height: 40,
    display: "flex",
    paddingLeft: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  backButtonStyle: {
    width: 32,
    height: 32,
    flexGrow: 0,
    cursor: "pointer",
    objectFit: "contain",
  },
  backButtonCont: {
    width: "100%",
    height: 40,
    alignItems: "center",
    display: "flex",
  },
}));

export { useStyles };
