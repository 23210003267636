import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import {useStyles} from '../../../assets/styles/PopupStyles';
import Controls from "../../controls/Controls";
import CloseIcon from "@material-ui/icons/Close";
import Colors from "../../../constants/colors";
import colors from "../../../constants/colors";

export default function Popup(props) {
  const {
    title,
    children,
    openPopup,
    setOpenPopup,
    isNested = false,
    isCenter = false,
    normalPopUp = false,
    crewAssign,
    navigation
  } = props;
  const classes = useStyles(Colors);

  if (normalPopUp) {
    return (
      <Dialog
        open={openPopup}
        maxWidth="md"
        classes={{
          paper: classes.dialogWrapperNormal,
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: "flex" }}>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <Controls.ActionButton
              backgroundColor={colors.denim}
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              <CloseIcon />
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent
          className={isCenter ? classes.conataintCenter : classes.conataint}
        >
          {children}
        </DialogContent>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        open={openPopup}
        maxWidth="md"
        classes={{
          paper: isNested
            ? classes.dialogWrapperNested
            : classes.dialogWrapperNormal,
        }}
      >
        <DialogTitle className={navigation === "refer fbo" ? classes.inviteDialogTitle : classes.dialogTitle}>
          <div style={{ display: "flex" }}>
            <Typography variant="h6" component="div" className={navigation === 'refer fbo' ? classes.inviteTextStyle : classes.textStyle }>
              {title}
            </Typography>
            <Controls.ActionButton
              backgroundColor={colors.denim}
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              {crewAssign !== true ? (
                <CloseIcon />
              ) : (
                <div className={classes.submitBtn}>Done</div>
              )}
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent
           className={isCenter 
            ? navigation === 'refer fbo' ? classes.inviteConataintCenter : classes.conataintCenter 
            : classes.conataint}
        >
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}
