import { Button, makeStyles, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import commonClasses from "../../../constants/common";
import * as authService from "../../../services/authService.js";

const useStyles = makeStyles((theme) => ({
  main: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  form: {
    width: "100%",
    marginTop: -20,
  },
  submit: {
    background: "linear-gradient(270deg, rgb(63, 152, 255), rgb(71, 215, 231))",
    color: "#fff",
    margin: theme.spacing(3, 0, 2),
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
  floatingLabelFocusStyle: {
    color: "#000",
  },
  label: {
    "&$focusedLabel": {
      color: "#000",
    },
    "&$erroredLabel": {
      color: "#000",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  underline: {
    "&$error:after": {
      borderBottomColor: "black",
    },
    "&:after": {
      borderBottom: `2px solid black`,
    },
  },
  error: {},
  notchedOutline: {
    borderWidth: "1px",
    padding: 5,
    borderColor: "black !important",
    color: "#fff",
    hintColor: "#fff",
  },
  editFiled: {
    marginBottom: 20,
  },
}));

const ForgotPassword = (props) => {
  const { setOpenPopup } = props;
  const classes = useStyles();
  const commonStyles = commonClasses();
  const { account } = useSelector((state) => ({
    account: state.account,
  }));
  const [email, setEmail] = useState("");

  const validateForm = () => {
    var pattern = new RegExp(/^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i);
    return pattern.test(email);
  };

  const sendResetPasswordLink = async () => {
    setOpenPopup(false);
    const res = await authService.forgotPassword({ email: email.toLowerCase(), portal: 1 });
    if (res.response_type === "fail") {
      toast.info(res.response.message, {
        position: "bottom-center",
        closeButton: false,
      });
    } else {
      toast(res.response.message, {
        position: "bottom-center",
        closeButton: false,
      });
    }
  };

  return (
    <div className={classes.main}>
      <form autoComplete="off" className={classes.form} noValidate>
        <div style={{ height: 6 }}></div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          size="small"
          fullWidth
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          label="Email Address"
          name="email"
          autoComplete="off"
          autoFocus
          className={classes.editFiled}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{
            style: { color: "#000" },
            classes: {
              notchedOutline: classes.notchedOutline,
              input: classes.inputLine,
              colorSecondary: {
                color: "#000",
              },
            },
            color: "#fff",
          }}
        />

        <Button
          disabled={!validateForm()}
          onClick={sendResetPasswordLink}
          fullWidth
          variant="contained"
          className={
            account.isParagonUser !== undefined &&
            account.isParagonUser === true
              ? `${commonStyles.submitBtnsmall} ${commonStyles.submitParagonBtnsmall}`
              : commonStyles.submitBtnsmall
          }
        >
          Send Me The Link
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;