import { makeStyles } from "@material-ui/core";
import React from "react";
import userLocationMarker from "../../../../assets/images/userLocationMarker.png";

const useStyles = makeStyles(() => ({
  marker: {
    width: 30,
    height: 30,
    position: "absolute",
    transform: "translateZ(0) translate(-50%, -50%)",
  },
}));

const GoogleMarker = (props) => {
  const classes = useStyles();

  return <img alt='' src={userLocationMarker} className={classes.marker} />;
};

export default GoogleMarker;
