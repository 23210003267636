import React from "react";
import AppLayout from "../Layout/AppLayout";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import NozlHeadings from "../../components/ui/NozlHeadings";
import NudgeDetail from "../../components/scene/app/NudgeDetail";
import { Grid } from "@material-ui/core";

export const IconWrapper = styled.div`
  background-color: white;
  width: 28px;
  height: 28px;
  padding-top: 5px;
`;

export default function NudgeDetails(props) {
  const history = useHistory();

  const goBack = (value) => {
    if (value === true) {
      history.goBack()
    }
  }
  return (
    <Grid container={true} className="rootContainer">
      <AppLayout>
        <NozlHeadings title="Nudge Details" goBack={true} onClickBack={goBack} />
        <NudgeDetail location={props.location} />
      </AppLayout>
    </Grid>
  );
}
