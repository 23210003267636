import * as Yup from "yup";

export default Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[a-zA-Z 0-9-,'&]*$/, "Invalid input")
    .required("First Name is required"),
  last_name: Yup.string()
    .matches(/^[a-zA-Z 0-9-,'&]*$/, "Invalid input")
    .required("Last Name is required"),
  email: Yup.string()
    .email("Email must be a valid email")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
  title: Yup.string().required("Title is required"),
  phone: Yup.string()
    .min(10, "Minimum 10 characters required")
    .required("Mobile Number is required"),
});
