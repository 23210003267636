import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";
import fontWeights from "../../constants/fontWeights";

const useStyles = makeStyles(() => ({
  mainCont: {
    width: "398px",
    padding: "8px 12px 8px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headingTextStyle: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 16,
    fontWeight: fontWeights.fontWeight600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: colors.grey40,
  },
  renderMainCont: {
    width: "100%",
  },
  detailStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  root: { marginRight: -5 },
  priceContainer:{
    width: '100%',
    display: "flex"
  },
  customTooltipStyle: {
    width: 250,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: fontWeights.fontWeight500,
    marginTop: 0,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    textAlign: "left",
    color: colors.white,
  },
  infoImageStyle: {
    width: 15,
    height: 15,
  },
  tooltipCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12);",
  },
  priceDetailCont: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  showNotesStyle: {
    width: 18,
    height: 18,
    color: "#666",
    borderRadius: 10,
    cursor: "pointer",
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: "space-between",
    padding: "8px 12px 8px 12px",
  }
}));

export { useStyles };
