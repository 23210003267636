import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../../../assets/styles/BookingDetailsStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  Tooltip,
} from "@material-ui/core";
import colors from "../../../../constants/colors";
import {
  cancelRequest,
  sendFeedback,
} from "../../../../redux/requests/requests.actions";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";
import NozlRating from "../../../ui/NozlRating";
import NozlRatingBar from "../../../ui/NozlRatingBar";
import Popup from "../../profile-setup/Popup";
import commonStyles from "../../../../constants/common";
import Styled from "styled-components";
import PriceView from "../PriceView";
import TotalPrice from "../Fuelrequest/TotalPrice";

const StyledFboName = Styled(Grid)`
  display: -webkit-box;
  width: 100%;
  height: 38px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const BookingDetail = (props) => {
  const classes = useStyles(colors);
  const commonClasses = commonStyles();
  const dispatch = useDispatch();

  const params = props?.location?.state?.param;
  const { fbo: data } = params;

  const { auth, requests } = useSelector((state) => ({
    auth: state.auth,
    requests: state.requests,
  }));

  const [dialog, setdialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rateMsg, setRateMsg] = useState("");
  const [rateValue, setRateValue] = useState(0);

  useEffect(() => {}, [requests.sendFeedbackResponse]);

  const {
    isRated,
    isPriceChange = false,
    rating,
    rateType,
    bookingId,
    date,
    fbo,
    aircraft: aircraftName,
    airport,
    discountAmount,
    isOperatorSpecificDiscount,
    isRegistryDiscount,
    fuelCost,
    isDiscount,
    rampFee: rFee,
    registry: registryName,
    status,
    isTailWiseDiscount,
    volume,
    discountFuelType,
    pilotsDetail = [],
    requestType,
    etd,
    flyingFrom,
    flyingTo,
    isParagonUser,
    comments,
    oldRate,
    isServiceRequest,
    internationalFeeAndTaxes,
    isRampFee,
    isFBOInternationalServices,
    ancillaryServices,
    internationalServicesCost,
    actualCost
  } = data;

  const notification = {
    aircraft: aircraftName,
    fuelCost: typeof fuelCost === "string" ? parseFloat(fuelCost) : fuelCost,
    rampFee: typeof rFee === "string" ? parseFloat(rFee) : rFee,
    requestedGallons: volume,
    finalGallons: volume,
    oldRate: typeof fuelCost === "string" ? parseFloat(oldRate) : oldRate,
    dateOfUplift: date,
    registry: registryName,
    discountAmount: discountAmount || 0,
    isDiscount: isDiscount || false,
    operator: airport,
    status: status,
    discountFuelType: discountFuelType || 1,
    rateType: rateType,
    isPriceChange: isPriceChange,
    isOperatorSpecificDiscount: isOperatorSpecificDiscount || false,
    isRegistryDiscount: isRegistryDiscount || false,
    isTailWiseDiscount: isTailWiseDiscount || false,
    eta: "",
    etd: "",
    requestType: "",
    flyingFrom: "",
    flyingTo: "",
    isParagonUser: isParagonUser || false,
    memberSince: "",
    internationalFeeAndTaxes: [],
    ancillaryServices:[],
    actualCost
  };

  const {
    requestedGallons = "??",
    operator = "??",
    dateOfUplift = "??",
    registry = "??",
    rampFee = 0,
  } = notification;

  const renderServicesList = () => {
    let tempArray = [];
    let list = !!internationalFeeAndTaxes && internationalFeeAndTaxes?.length > 0 ? internationalFeeAndTaxes: ancillaryServices;
    if (list?.length > 0) {
      list?.map((item) => {
        tempArray.push(item.name);
      });
      return tempArray.join(", ");
    } else {
      return "No service added.";
    }
  };

  const renderInternationalServiceList = () => (
    internationalFeeAndTaxes.length > 0 ? (
      <PriceView
      item={data}
      services="confirmationServices" 
      navigation="finalBookingScreen"
      />
    ) : (
      <Grid container item xs={12}>
        <Grid
          item
          xs={6}
          className={classes.headingTextStyle2}
        >
          Services & Fees Total
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.valuePriceStyle}
        >
          {internationalFeeAndTaxes.length > 0
            ? `$${formatNumWithCommas(parseFloat(internationalServicesCost).toFixed(
                2)
              )}`
            : "$0.00"}
            <div className={classes.extraSpace} />
        </Grid>
      </Grid>
    )
  )

  const renderPilotList = () => {
    let tempArray = [];
    if (pilotsDetail.length > 0) {
      pilotsDetail.map((pilotItem) => {
        tempArray.push(pilotItem);
      });
      return tempArray.join(", ");
    } else {
      return "NA";
    }
  };

  const openRateExpModal = () => {
    setOpenModal(true);
    setRateValue(0);
  };

  const showRatingButton = (isRated, rating) => {
    if (isRated) {
      return (
        <div className={classes.rateConatiner}>
          <NozlRatingBar rate={rating} navigation="fromHistory" />
        </div>
      );
    } else {
      return (
        <div className={classes.rateEx}>
          <Button onClick={openRateExpModal} className={classes.submitBtn}>
            Rate your experience
          </Button>
        </div>
      );
    }
  };

  const cancelBookingRequest = () => {
    setdialog(false);
    dispatch(
      cancelRequest({
        booking: { bookingId: bookingId },
        token: auth.token,
      })
    );
  };

  const onSendFeedback = () => {
    const feedback = {
      rating: rateValue,
      comments: rateMsg,
      bookingId: bookingId,
    };
    dispatch(
      sendFeedback({
        feedback: feedback,
        token: auth.token,
      })
    );
    setOpenModal(false);
  };

  return (
    <div className={classes.bookingOverflowStyle}>
    <Grid container item xs={12} className={classes.mainCont}>
      <Grid className={classes.main} item xs={12}>
        <Grid container className={classes.innerCont}>
          <Grid item xs={6}>
            <StyledFboName>
              <Tooltip title={fbo}>
                <Grid className={classes.fboNameStyle}>{fbo}</Grid>
              </Tooltip>
            </StyledFboName>
            <Grid item xs={12} className={classes.innerCont1}>
              <Grid container item xs={12}>
                <Grid item xs={6} className={classes.containerStyle}>
                  <Grid className={classes.headingStyle}>Aircraft Tail</Grid>
                  <Grid className={classes.aircraftTail}>
                    {registry.toUpperCase()}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid className={classes.containerStyle}>
                    <Grid className={classes.headingStyle}>Fuel Requested</Grid>
                    <Grid className={classes.aircraftTail}>
                      {isServiceRequest
                        ? "NA"
                        : requestedGallons === "0"
                        ? "Captain's Request"
                        : formatNumWithCommas(requestedGallons)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6} className={classes.containerStyle}>
                  <Grid className={classes.headingStyle}>ETA</Grid>
                  <Grid className={classes.aircraftTail}>
                    {requestType === 1 ? "Aircraft on Ground" : dateOfUplift}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid className={classes.containerStyle}>
                    <Grid className={classes.headingStyle}>ETD</Grid>
                    <Grid className={classes.aircraftTail}>
                      {etd.includes("Invalid date") ? "TBD" : etd}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6} className={classes.containerStyle}>
                  <Grid className={classes.headingStyle}>Flying From</Grid>
                  <Grid className={classes.aircraftTail}>
                    {flyingFrom === "" ? "NA" : flyingFrom}
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.containerStyle}>
                  <Grid className={classes.headingStyle}>Flying To</Grid>
                  <Grid className={classes.aircraftTail}>
                    {flyingTo === "" ? "NA" : flyingTo}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item xs={12} style={{ paddingBottom: 5 }}>
                <Grid item xs={12} className={classes.containerStyle}>
                  <Grid className={classes.headingStyle}>Crew</Grid>
                  <Grid
                    className={classes.aircraftTail}
                    style={{ paddingRight: 5 }}
                  >
                    {renderPilotList()}
                  </Grid>
                </Grid>
              </Grid>
              {internationalFeeAndTaxes.length>0 || ancillaryServices.length>0 ? (
              <Grid container item xs={12} style={{ paddingBottom: 5 }}>
                <Grid item xs={12} className={classes.containerStyle}>
                  <Grid className={classes.headingStyle}>Services & Fees</Grid>
                  <Grid
                    className={classes.aircraftTail}
                    style={{ paddingRight: 5 }}
                  >
                    {renderServicesList()}
                  </Grid>
                </Grid>
              </Grid>
              ): null}
              <Grid container item xs={12}>
                {comments !== undefined && comments !== "" ? (
                  <Grid item xs={12} className={classes.containerStyle}>
                    <Grid className={classes.headingStyle}>Comments</Grid>
                    {comments.length > 60 ? (
                      <Tooltip
                        title={<div style={{ fontSize: 12 }}>{comments}</div>}
                      >
                        <Grid className={classes.aircraftTail}>
                          {comments.slice(0, 120)}
                          <span>...</span>
                        </Grid>
                      </Tooltip>
                    ) : (
                      <Grid className={classes.aircraftTail}>{comments}</Grid>
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.priceCont}>
              <>
                <div
                  className={classes.valueStatus}
                  style={{
                    background:
                      status === "pending"
                        ? "#ff9800"
                        : status === "completed"
                          ? "#29df67"
                          : status === "acknowledged"
                            ? "#1E69F6"
                            : status === "expired"
                              ? "#F4AD3D"
                              : "#df2935",
                    color: "#fff",
                  }}
                >
                  {status && status.toUpperCase()}
                </div>
                <Grid className={classes.priceContainer}>
                {!isServiceRequest ? ( 
                  <>
                  <Grid item xs={12}>
                  <PriceView item= {data} fuelPrice="confirmationFuel" navigation="finalBookingScreen"/>
                  </Grid>
                  {isRampFee ? (
                  <Grid container>
                    <Grid item xs={6} className={classes.headingTextStyle}>
                      Fee
                    </Grid>
                    <Grid item xs={6} className={classes.valueStyle}>
                      {rampFee === 0 ? "$0.00" : `$${formatNumWithCommas(parseFloat(rampFee).toFixed(2))}`}
                      <div className={classes.extraSpace} />
                    </Grid>
                  </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    {isFBOInternationalServices ? 
                        renderInternationalServiceList()
                       : null
                    }
                  </Grid>
                  </>
                  )
                  : <Grid item xs={12}>
                    {isFBOInternationalServices ? 
                      renderInternationalServiceList() :                       
                        <div className={classes.staticTextStyle}>
                          This FBO is not providing fuel services.
                        </div>
                    }
                  </Grid>
                  }
                </Grid>
                <TotalPrice subtotal={`$${formatNumWithCommas(parseFloat(+fuelCost + +internationalServicesCost).toFixed(2))}`}/>
              </>
          </Grid>
        </Grid>
        <div className={classes.buttonsCont}>
          <div>
            {status === "pending" ? (
              <div className={classes.cancel} onClick={() => setdialog(true)}>
                <div className={classes.cancelText}>Cancel Booking</div>
              </div>
            ) : status === "completed" ? (
              showRatingButton(isRated, rating, registry, operator, bookingId)
            ) : null}
          </div>
        </div>
      </Grid>
      <Dialog
        open={dialog}
        onClose={() => setdialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel this request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setdialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={cancelBookingRequest} color="secondary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Popup
        title="Rate your experience"
        openPopup={openModal}
        setOpenPopup={setOpenModal}
        isCenter={true}
      >
        <div>
          <NozlRating
            style={classes.rating2}
            onValueChange={(val) => {
              setRateValue(val);
            }}
          />
          <TextField
            placeholder="Enter feedback message here"
            className={classes.edit}
            id="outlined-textarea"
            variant="outlined"
            multiline
            rowsMax={3}
            value={rateMsg}
            helperText={`${rateMsg.length}/500`}
            onChange={(e) => setRateMsg(e.target.value)}
            inputProps={{
              maxLength: 500,
            }}
            InputProps={{
              classes: {
                root: classes.root,
                focused: classes.focused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <div className={classes.button}>
            <Button
              disabled={rateValue === 0}
              fullWidth
              variant="contained"
              className={commonClasses.submitBtnSaveButton}
              onClick={onSendFeedback}
            >
              Send Feedback
            </Button>
          </div>
        </div>
      </Popup>
    </Grid>
    </div>
  );
};

export default BookingDetail;
