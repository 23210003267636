import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";
import { useSelector } from "react-redux";
import colors from "../../constants/colors";
import ClearIcon from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ListSubheader, MenuItem, Menu } from "@material-ui/core";
import { SearchTextField } from "../scene/app/map/AircraftSelection";
import { searchHelper } from "../scene/app/map/AircraftSearchHelper";

const useStyles = makeStyles((theme) => ({
  menuButtonStyle: {
    marginLeft: 5,
    width: "100%",
    height: 50,
    display: "flex",
    paddingRight: 10,
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "normal",
    cursor: "pointer",
  },
  margin: {
    color: "#999",
    "& *::-webkit-input-placeholder": {
      color: "#999",
      opacity: 1,
    },
    border: "0px solid white",
    outline: "none",
  },
  regitry: {
    "&:disabled": {
      color: "green",
    },
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    backgroundColor: "#fff",
    background: "#fff",
    color: "#fff",
    marginLeft: 10,
    marginRight: 10,
  },
  root: {
    width: "100%",
    height: 50,
    background: "white",
    border: "1px solid #000 !important",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04))",
    borderRadius: 5,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
  },
  notchedOutline: {
    borderWidth: "1px",
    padding: 5,
    borderColor: "black !important",
    color: "#000",
    hintColor: "#000",
  },
  listItem: {
    cursor: "pointer",
  },
  formControl: {
    width: "100%",
    paddingLeft: 10,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    color: "grey",
  },
  fieldStyle: {
    width: "100%",
  },
  aircraftNameStyle: {
    textTransform: "capitalize",
    borderRadius: 5,
    width: "100%",
    margin: 5,
    "&:focus": {
      backgroundColor: colors.primaryColor,
    },
  },
  inputFocused: {},
  inputLabel: {
    color: "#555",
    "&.Mui-focused": {
      color: colors.primaryColor,
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  labelTextStyle: {
    color: "black",
    fontSize: 15,
    fontWeight: "400",
  },
  aircraftTitleStyle: {
    display: "flex",
    width: '100%',
    flexDirection: "column",
    background: "linear-gradient(to right,  #848484 ,#5A5959)",
  },
  aircraftTitle: {
    display: "flex",
    alignItems: "center",
    width: '100%',
    background: "linear-gradient(to right,  #848484 ,#5A5959)",
    color: "#fff",
    height: 30,
    paddingLeft: 22
  },
  noResultStyle: {
    padding: 10,
    color: "black",
    textAlign: "center",
  },
  menuItemCont: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIconStyle: {
    width: 20,
    height: 20,
    marginRight: 10,
    zIndex: 99,
    color: "#000",
    borderRadius: 10,
    cursor: "pointer",
    background: "#fff",
  },
  iconContStyle: {
    display: "flex",
    alignItems: "center",
    zIndex: 99,
  },
  gutters:{
    padding: 0
  }
}));

const StyledMenu = withStyles({
  paper: {
    width: 365,
    maxHeight: 300,
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const NozlRegistrySelection = (props) => {
  const { data, onChange } = props;
  const [labelText, setLabelText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setAircraftType] = useState("");
  const [aircraftDetail, setAircraft] = useState("");
  const [aircraftArray, setSectionListData] = useState([]);
  const [searchByAircraftName, setSearchByAircraftName] = useState("");

  const { account } = useSelector((state) => ({
    account: state.account,
  }));
  const classes = useStyles();

  useEffect(() => {
    data.map((item) => {
      item.data.sort(function (a, b) {
        if (a?.registryName < b?.registryName) {
          return -1;
        }
        if (a?.registryName > b?.registryName) {
          return 1;
        }
        return 0;
      });
    })
    setSectionListData(data);
    if (
      !!account?.userSelectedAircraft &&
      Object.values(account?.userSelectedAircraft).length > 0
    ) {
      data.map((aircraftType) => {
        {
          aircraftType.data.map((aircraft) => {
            if (aircraft._id === account?.userSelectedAircraft._id) {
              setLabelText(aircraft.registryName);
              setAircraft(account?.userSelectedAircraft);
              onChange(account?.userSelectedAircraft);
            }
          });
        }
      });
    } else {
      setLabelText("");
      onChange("");
    }
  }, [account?.userSelectedAircraft, data]);

  const searchAircrafts = async(search) => {
    setSearchByAircraftName(search);
    let tempList = [];
    if (search !== "") {
       tempList = await searchHelper(
        search,
        data
      );
      setSectionListData(tempList);
    } else {
       setSectionListData(data);
    }
  };

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    if (event === "clear") {
      setLabelText("");
      setAircraftType("");
      setAircraft("");
      onChange("");
    } else if (value === true) {
      setLabelText(event?.registryName);
      setAircraftType(event?.registryName);
      setAircraft(event);
      onChange(event);
    } else {}
  };

  const handleAircraftModal = () => {
    setAnchorEl(null);
  };

  const searchAircraft = () => {
    return (
      <SearchTextField
        fullWidth
        autoFocus={true}
        variant="outlined"
        style={{ color: "grey", paddingLeft: 8 }}
        className={classes.margin}
        InputProps={{
          style: { color: "#000", height: 30 },
          classes: { notchedOutline: classes.notchedOutline },
        }}
        placeholder="Search..."
        onChange={(e) => searchAircrafts(e.target.value)}
        value={searchByAircraftName}
        autoComplete="off"
      />
    )
  }

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <div
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={(e) => {
            handleClick(e, false);
          }}
          label="Select Aircraft"
          className={classes.menuButtonStyle}
        >
          <div
            className={classes.labelTextStyle}
            style={{
              opacity: labelText !== "" ? 1 : 0.5,
            }}
          >
            {labelText !== "" ? labelText.toUpperCase() : "Select Aircraft"}
          </div>
          <ExpandMoreIcon />
        </div>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          label="Aircraft Tail"
          open={Boolean(anchorEl)}
          onClose={handleAircraftModal}
        >
           {searchAircraft()}
          {aircraftArray.length > 0 ? (
            <div>
              {aircraftArray.map((aircraftType, index) => {
                return (
                  <div>
                    <ListSubheader key={index} classes={{ gutters: classes.gutters }} className={classes.aircraftTitleStyle}>
                      <div className={classes.aircraftTitle}>{aircraftType.title}</div>
                    </ListSubheader>
                    {aircraftType.data.map((aircraft, index) => {
                      return (
                        <div
                          className={classes.menuItemCont}
                          style={{
                            background:
                              aircraftDetail !== "" &&
                                !!aircraftDetail.airplaneDetail &&
                                aircraftDetail._id === aircraft?._id
                                ? colors.primaryColor
                                : "transparent",
                          }}
                        >
                          <MenuItem
                            key={index}
                            value={aircraft}
                            className={classes.aircraftNameStyle}
                            name={aircraft.name}
                            style={{
                              background:
                                !!aircraftDetail &&
                                  aircraftDetail !== "" &&
                                  !!aircraftDetail.airplaneDetail &&
                                  aircraftDetail._id === aircraft?._id
                                  ? colors.primaryColor
                                  : null,
                              color:
                                !!aircraftDetail &&
                                  aircraftDetail !== "" &&
                                  !!aircraftDetail.airplaneDetail &&
                                  aircraftDetail._id === aircraft?._id
                                  ? "white"
                                  : null,
                            }}
                            onClick={() => {
                              handleClick(aircraft, true);
                            }}
                          >
                            {aircraft?.registryName.toUpperCase()} (
                            {aircraft?.makerName} {aircraft.name})
                          </MenuItem>
                          <div className={classes.iconContStyle}>
                            {!!aircraftDetail &&
                              aircraftDetail !== "" &&
                              !!aircraftDetail.airplaneDetail &&
                              aircraftDetail._id === aircraft?._id ? (
                              <>
                                <ClearIcon
                                  className={classes.closeIconStyle}
                                  onClick={() => {
                                    handleClick("clear", false);
                                  }}
                                />
                              </>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ) : (<div className={classes.noResultStyle}>No result</div>)}
        </StyledMenu>
      </FormControl>
      <br />
    </div>
  );
};

export default NozlRegistrySelection;