import React, { useEffect, useMemo, useState } from "react";
import GoogleMapReact from "google-map-react";
import { makeStyles } from "@material-ui/core/styles";

import mapStyle from "../menu/mapStyle";
import Marker from "./Marker";
import AirportSearchScreen from "../AirportSearch/AirportSearchScreen";
import { useDispatch, useSelector } from "react-redux";
import GoogleMarker from "./GoogleMarker";
import axios from "axios";
import UserLocationMarker from "./UserLocationMarker";
import { getFboGeoloc, getGeoloc } from "../../../../redux/fbos/fbos.actions";

const useStyles = makeStyles(() => ({
  mapView: {
    height: "100vh",
    width: "100%",
  },
}));

const NozlMapView = (props) => {
  const {
    searchResult,
    centerLatLong,
    googleSuggestions,
    airportItemValue,
    onGoogleSearch,
    userLocation,
    selectedSearchItem,
    locationLatLong,
    searchResults,
    selectionType,
    getNewLat= {},
  } = props;
  const { account, fbos } = useSelector((state) => ({
    account: state.account,
    fbos: state.fbos,
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [onSelectedItem, setOnSelectedItem] = useState({});
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(0);
  const [circle, setCircle] = useState(null);
  const [distanceRange, setDistanceRange] = useState(account?.slidervalue || 0);
  const [latLong, setLatLong] = useState(centerLatLong);
  const [googleLocation, setGoogleLocation] = useState({});

  let searchText = useMemo(() => onGoogleSearch, [onGoogleSearch]);

  let unListedfbo = fbos?.unlistedFbos.map((fbo) => {
    return {
      ...fbo,
    };
  });

  let localArray = searchResults;

  useEffect(() => {
  }, [account.isParagon, airportItemValue]);

  useEffect(() => {
    setGoogleLocation(locationLatLong);
    if (onGoogleSearch === "onSeachClick") {
      setLatLong(latLong);
    }
  }, [onGoogleSearch,locationLatLong, centerLatLong, account.slidervalue, distanceRange]);

  function linear(duration, range, current) {
    return ((duration * 2) / Math.pow(range, 2)) * current;
  }

  useEffect(() => {
    setDistanceRange(account.slidervalue);
  }, [centerLatLong, account.slidervalue, map, distanceRange]);

  useEffect(() => {
    if (Object.values(selectedSearchItem).length > 0) {
      setOnSelectedItem(selectedSearchItem);
      setLatLong({
        latitude: selectedSearchItem.latitude,
        longitude: selectedSearchItem.longitude,
      });
      if(selectionType === "fboItem"){
        getMapZoom();
        getResult(selectedSearchItem);
      }
    } else {
      setOnSelectedItem({});
    }
  }, [selectedSearchItem, fbos.favouriteFboItem, map]);

  const getMapZoom = () => {
    let zoom = 7;
    if (map !== null && Object.values(map).length > 0) {
      map.setZoom(zoom > 10 ? zoom / 2 : 1);
      animateValue(zoom > 9 ? zoom : 11, zoom / 2, 70, linear);
    }
  };

  const getCityLat = (value, description) => {
    dispatch(
      getGeoloc({
        latitude: value.lat,
        longitude: value.lng,
      })
    );

    setLatLong({ latitude: value.lat, longitude: value.lng });
    apiLoaded({ latitude: value.lat, longitude: value.lng });
    predictionSelected(description.place_id, "fromSearch");
    getNewLat(value, description);
  };

  const animateValue = (finalValue, start, duration, easing) => {
    let end = parseInt(finalValue);
    let range = end - start;
    let current = start;
    let increment = end > start ? 0.5 : -0.5;

    let step = function () {
      current += increment;
      map.setZoom(current);

      if (current !== end) {
        setTimeout(step, easing(duration, range, current));
      }
    };
    setTimeout(step, easing(duration, range, start));
  };

  const getResult = (fboItem) => {
    localArray.map((item) => {
      if (
        item.icao ===
        (fboItem.fboDetail !== undefined
          ? fboItem.fboDetail.icaoCode
          : fboItem.icaoCode !== undefined
          ? fboItem.icaoCode
          : fboItem.icao)
      ) {
        setOnSelectedItem(item);
        dispatch(
          getFboGeoloc({
            latitude: item.latitude,
            longitude: item.longitude,
          })
        );
        setLatLong({
          latitude: item.latitude,
          longitude: item.longitude,
        });
      }
    });
  };

  // select data from search
  const selectedItem = (item, title) => {
    setLatLong({
      latitude: item.latitude,
      longitude: item.longitude,
    });
    if (item !== undefined && Object.values(item).length > 0) {
      apiLoaded(item);
    }
    getMapZoom();
    setOnSelectedItem(item);
    props.selectedItem(item, title);
  };

  const selectedFboItem = (item) => {
    if (Object.values(item).length > 0) {
      getResult(item);
      setOnSelectedItem({});
      apiLoaded(userLocation.latitude !== 0 ? userLocation:  {latitude: 37.0902,
        longitude: -95.7128});
    }
    props.selectedFboItem(item);
  };

  const predictionSelected = async (placeId, value) => {
    axios
      .get("https://maps.googleapis.com/maps/api/geocode/json?key="+ process.env.REACT_APP_MAPS_KEY + "&place_id=" + placeId)
      .then((response) => {
        let responseData = response.data.results[0];
        const finalDetails = {
          street:
            extractBasedOnType(responseData.address_components, "street_number") || {},
          locality:
            extractBasedOnType(responseData.address_components, "sublocality_level_1") ||
            {},
          city: extractBasedOnType(responseData.address_components, "locality") || {},
          state:
            extractBasedOnType(
              responseData.address_components,
              "administrative_area_level_1"
            ) || {},
          zipCode:
            extractBasedOnType(responseData.address_components, "postal_code") || {},
          country: extractBasedOnType(responseData.address_components, "country") || {},
          geometry: {
            lng: responseData.geometry.location.lng,
            lat: responseData.geometry.location.lat,
          },
        };
        let zoom = 7;
        if (value === "fromSearch") {
          let currentZoom = 6;
          if (
            Object.values(finalDetails.locality).length > 0 ||
            Object.values(finalDetails.street).length > 0
          ) {
            zoom = 14;
          } else if (Object.values(finalDetails.city).length > 0) {
            zoom = 10;
          } else if (Object.values(finalDetails.state).length > 0) {
            zoom = 7;
          } else if (Object.values(finalDetails.country).length > 0) {
            zoom = 5;
          }
          map.setZoom(currentZoom > zoom + 1 ? currentZoom / 2 : 1);
          animateValue(
            currentZoom > zoom ? currentZoom : zoom,
            currentZoom / 2,
            70,
            linear
          );
        }
      })
      .catch(function (error) {})
  };

  const extractBasedOnType = (data, type) => {
    if (data.length) {
      for (const add of data) {
        if (add.types.includes(type)) {
          return { longName: add.long_name, shortName: add.short_name };
        }
      }
    }
    return null;
  };

  // on tap of marker get fbo details
  const onClickMarker = (item, isClick) => {
    if (isClick === true) {
      setOnSelectedItem(item);
      if (item !== undefined && Object.values(item).length > 0) {
      } else {
        circle.setMap(null);
      }
      props.onClickMarker(item, isClick);
    } else {
      setOnSelectedItem({});
      props.onClickMarker(item, false);
    }
  };

  const closeList = (value) => {
    if (value === true) {
      props.closeList(value);
    }
  };

  const apiLoaded = (item, maps) => {
    if (circle) {
      circle.setMap(null);
    }
    let newCircle = new window.google.maps.Circle({
      strokeColor: "#f4f4f4",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "rgba(171, 178, 185, 0.5)",
      fillOpacity: 0.9,
      map,
      center: {
        lat:  !!item.latitude ? item.latitude : item.lat,
        lng: !!item.longitude ? item.longitude : item.lng
      },
      radius: account.slidervalue * 1609.344,
    });
    
    setCircle(newCircle);
  };

  useEffect(() => {
      if (Object.values(fbos.geoloc).length === 0) {
        if (Object.values(onSelectedItem).length === 0) {
          apiLoaded(userLocation.latitude !== 0 ? userLocation:  {latitude: 37.0902,
            longitude: -95.7128});
        }
         else if (
         Object.values(onSelectedItem).length > 0 ||
          userLocation.latitude === 0
        ) {
          apiLoaded({latitude: onSelectedItem.latitude, longitude: onSelectedItem.longitude});
        }
      }
    else if (account.slidervalue === 0) {
      apiLoaded(latLong);
    }
  }, [userLocation, selectionType, latLong, account.slidervalue, onSelectedItem, fbos.geoloc,]);

  const showAirportList = (value) => {
    value === true && props.showAirportList(true);
  };

  return (
    <>
      <GoogleMapReact
        options={{
          styles: mapStyle,
          panControl: true,
          fullscreenControl: false,
          streetViewControl: false,
          scaleControl: false,
          disableDefaultUI: true,
          mapTypeControl: false,
        }}
        defaultZoom={account.isParagon === true ? 0 : 4}
        zoom={account.isParagon === true ? 0 : 4}
        draggable={true}
        className={classes.mapView}
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
        center={{
          lat: latLong.latitude !== undefined ? latLong.latitude : latLong.lat,
          lng:
            latLong.longitude !== undefined ? latLong.longitude : latLong.lng,
        }}
        yesIWantToUseGoogleMapApiInternals={true}
        onChange={(newLocation) => {
           setLatLong(newLocation.center);
        }}
        onZoomAnimationEnd={(zoomLevel) => {
          setZoom(zoomLevel);
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
        }}
        onDragEnd={(map) => {
          props.dragMap(true);
        }}
      >
        {!!account?.userBaseLocation &&
        Object.values(account?.userBaseLocation).length > 0 ? (
          <UserLocationMarker
            lat={account?.userBaseLocation.latitude}
            lng={account?.userBaseLocation.longitude}
          />
        ) : null}
        {localArray.length > 0 || Object.keys(onSelectedItem).length > 0
          ? localArray.map((itemMark, index) => {
              return (
                <Marker
                  key={index}
                  item={itemMark}
                  onSelectItem={onSelectedItem}
                  onGoogleSearch={onGoogleSearch}
                  onClickMarker={onClickMarker}
                  selectedFboItem={selectedFboItem}
                  userLocation={account?.userBaseLocation}
                  is100LLAircraftSelected={account?.userSelectedAircraft?.airplaneDetail?.fuelType === 2}
                  lat={itemMark.latitude}
                  latLong={latLong}
                  lng={itemMark.longitude}
                  location={props.location}
                  zoom={zoom}
                  selectedSearchItem={selectedSearchItem}
                />
              );
            })
          : null}
       {Object.values(googleLocation).length > 0 ? (
          <GoogleMarker lat={googleLocation.latitude} lng={googleLocation.longitude} googleSearch ={googleLocation}  />
        ) : null}
      </GoogleMapReact>
      {Object.values(selectedSearchItem).length === 0 &&
        (searchText === "onChangeText" || searchText === "onSeachClick" ? (
          <AirportSearchScreen
            hits={searchResult}
            unListedfbos={unListedfbo}
            selectedItem={selectedItem}
            showAirportList={showAirportList}
            selectedFboItem={selectedFboItem}
            closeList={closeList}
            getTextValue={searchText}
            googleSuggestions={googleSuggestions}
            airportItemValue={airportItemValue}
            getCityLat={getCityLat}
          />
        ) : null)}
    </>
  );
};

export default NozlMapView;
