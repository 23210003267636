import { takeLatest, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import * as authService from "../../../services/authService.js";
import { LOGIN_WITH_EMAIL } from "../auth.actionTypes";
import colors from "../../../constants/colors";
import { toast } from "react-toastify";

import "../../../assets/styles/reactToast.css";

function* login(action) {
  let isIFrameLogin = action.payload.isIFrameLogin;
  delete action.payload.isIFrameLogin;
  const apiResponse = yield call(authService.loginApi, action.payload);
  if (apiResponse.response_type === "success") {
    yield put({
      type: LOGIN_WITH_EMAIL.SUCCESS,
      payload: { ...apiResponse.response.data, isIFrameLogin },
    });
  } else {
    toast.info(apiResponse?.response?.message, {
      position: "bottom-center",
      closeButton: false,
      style: {
        color: "#fff",
        backgroundColor: isIFrameLogin ? "#9F215F" : colors.primaryColor,
      },
    });
    yield put({
      type: LOGIN_WITH_EMAIL.FAILURE,
      payload: apiResponse.response,
    });
  }
}

function* loginSuccess(action) {
  if (action.payload.status === 4 && action.payload.isActive === 2) {
    toast.info("Your account is rejected by admin.", {
      position: "bottom-center",
      closeButton: false,
      style: {
        color: "#fff",
        backgroundColor: action.payload.isIFrameLogin
          ? "#9F215F"
          : colors.primaryColor,
      },
    });
    yield put({ type: "UNAUTHRIZED_USER_CALL" });
  } else if (
    action.payload.isActive === 1 &&
    (action.payload.status === 1 || action.payload.status === 4 ||
      action.payload.status === 5 ||
      action.payload.status === 6)
  ) {
    if (
      action.payload.status === 1 &&
      action.payload.userType === 4
    ) {
      if (action.payload.isIFrameLogin) {
        var win = window.open(
          `${process.env.REACT_APP_OPERATOR_URL}signup/profileSetup`,
          "_blank"
        );
        win.focus();
        return false;
      } else {
        yield put(
          push({
            pathname: "./signup/profileSetup",
            state: {
              param: {
                isFromPilot: true,
                pilotDetail: action.data,
              },
            },
          })
        );
      }
    } else {
      if (action.payload.isIFrameLogin) {
        let win = window.open(`${process.env.REACT_APP_GROUP_NAME}`, "_blank");
        win.focus();
        return false;
      }
      yield put(push("/home"));
    }
  } else if (action.payload.isChangePassword) {
    if (action.payload.isIFrameLogin) {
      let win = window.open(
        `${process.env.REACT_APP_OPERATOR_URL}signup/profileSetup`,
        "_blank"
      );
      win.focus();
      return false;
    } else {
      yield put(
        push({
          pathname: "./signup/profileSetup",
          state: {
            param: {
              isFromPilot: true,
              pilotDetail: action.data,
            },
          },
        })
      );
    }
  }
}

function* loginFailure(action) {}

export function* loginSaga() {
  yield takeLatest(LOGIN_WITH_EMAIL.REQUEST, login);
  yield takeLatest(LOGIN_WITH_EMAIL.SUCCESS, loginSuccess);
  yield takeLatest(LOGIN_WITH_EMAIL.FAILURE, loginFailure);
}
