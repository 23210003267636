import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";
import fontWeights from "../../constants/fontWeights";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 50px)",
  },
  innerContainer: {
    height: 32
  },
  imageCont: {
    width: "100%",
    height: 160,
    background: colors.imageBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  innerCont: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 -4px 10px 0 rgba(153, 153, 153, 0.1)",
  },
  nameTextStyle: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "Arial",
    color: colors.grey,
  },
  textStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Arial",
    color: "#666666",
  },
  textStyleHundredLL: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 16,
    fontWeight: fontWeights.fontWeight600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: colors.grey40,
  },
  detailContainer: {
    borderTop: "1px solid rgba(153, 153, 153, 0.1)",
    borderBottom: "1px solid rgba(153, 153, 153, 0.1)",
  },
  detailCont: {
    width: "100%",
    height: 150,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  rating: {
    display: "flex",
    flexDirection: "row",
    width: 14,
  },
  buttonCont: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  nudgeButton: {
    borderRadius: 5,
    border: `1px solid ${colors.primaryColor}`,
    width: 70,
    boxSizing: "border-box",
  },
  fuelButton: {
    cursor: "pointer",
    borderRadius: 5,
    border: `1px solid ${colors.primaryColor}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    background: colors.primaryColor,
    boxSizing: "border-box",
    color: "#fff",
  },
  disableFuelButton: {
    opacity: 0.4,
    cursor: "default",
  },
  buttonTextStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Arial",
    fontStyle: "normal",
    color: colors.primaryColor,
  },
  discounted: {
    borderRadius: 25,
    border: "1px solid lightgrey",
    textAlign: "center",
    fontSize: 16,
    width: "90%",
    height: 40,
    color: "#666",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Arial",
    fontStyle: "normal",
  },
  discountedDetail: {
    borderRadius: 25,
    border: "1px solid lightgrey",
    textAlign: "center",
    fontSize: "0.85rem",
    width: "90%",
    color: "#666",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Arial",
    fontStyle: "normal",
    height: 30,
  },
  discountedItem: {
    borderRadius: 25,
    border: "1px solid lightgrey",
    textAlign: "center",
    fontSize: 14,
    width: "90%",
    height: 30,
    color: "#666",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Arial",
    fontStyle: "normal",
  },
  contDetail: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  cont: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  container: {
    width: "100px",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  buttonStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  fuelTypeCont: {
    width: "100%",
    height: 40,
    background: "rgba(153, 153, 153, 0.05)",
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
  },
  fuelTypeTextStyle: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: 16,
    color: "#333333",
  },
  typeContainer: {
    maxHeight: 70,
  },
  priceCont: {
    height: 32,
    width: "100%",
    padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  priceCont1: {
    height: 35,
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headingTextStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    color: "#666666",
  },
  hundredllPrice: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontSize: 16,
    color: "#999",
    fontWeight: "600",
    marginRight: 5,
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  priceTextStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    color: "#666",
    display: "flex"
  },
  priceTextStyle100LL: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    color: "#666",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  servicesCont: {
    width: "100%",
    marginBottom: 30,
  },
  servicestextStyle: {
    color: "#333",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
  },
  headingCon: {
    height: 40,
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  accordionStyle: {
    height: 30,
    border: "none",
    outline: "none",
  },
  jetApriceStyle: {
    height: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  jetApriceStyle1: {
    height: 32,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customTooltipStyle: {
    width: 249,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: 500,
    marginTop: 0,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
  },
  tooltipCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12);",
  },
  infoImageStyle: {
    width: 15,
    height: 15,
  },
  noFueltypeTextStyle: {
    margin: "2.4px 20px 17px",
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#666",
  },
  bookFuelIconStyle: {
    height: 15,
    width: 15,
    marginRight: 5,
  },
  chatIconStyle: {
    height: 15,
    width: 15,
    marginRight: 5,
  },
  nudgeModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  markeFboIconCont: {
    display: "flex",
    justifyContent: "flex-end",
  },
  iconStyle: {
    width: 50,
    height: 50,
    cursor: "pointer",
  },
  showNotesStyle: {
    width: 18,
    height: 18,
    color: "#666",
    borderRadius: 10,
    cursor: "pointer",
    marginLeft: 5,
  },
  hundredLLPriceCont: {
    marginLeft: 10,
    marginRight: 10,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  noteStyle: {
    marginLeft: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  priceTierStyle:{
    width: '100%',
    textAlign:'right',
    display: "flex",
    justifyContent: 'flex-end',
  },
  noDataStyle:{
    color: "#fff",
    width :'100%',
    height: 30
  },
  priceContainer:{
    display: "flex"
  },
  mainCont: {
    maxWidth: '100%'
  }
}));
export { useStyles };