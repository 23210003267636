import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { getTimezoneByLatLong } from "./TimeZoneHelper";
import momentTimezone from "moment-timezone";
import FieldSet from "./FieldSet";

const StyledWrapper = styled(Grid)`
  .secondary {
    outline: none;
    width: 100%;
    font-size: 14px;
    border: none;
  }
  .disableField {
    border-width: 1px;
    border-color: #afabab;
    cursor: default;
  }
  .enableField {
    border-width: 1px;
    border-color: #000;
    cursor: pointer;
  }
  .nudgeField {
    border-width: 2px;
    border-color: #555;
    cursor: pointer;
  }
  .react-datepicker-popper {
    z-index: 9999;
  }
`;

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: 55,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  innerCont: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  innerCont1: {
    display: "flex",
    width: "95%",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  gmtTimeStyle: {
    fontSize: 12,
    color: "#555",
    opacity: 0.7,
  },
}));

const FuelRequestDate = (props) => {
  const classes = useStyles();
  const [estimateArrivalTime, setEstimateArrivalTime] = useState(
    props.requestType === undefined ? null : new Date()
  );
  const [estimateDepartureTime, setEstimateDepartureTime] = useState(null);
  const [convertedArrivalTime, setConvertedArrivalTime] = useState("");
  const [convertedDepartureTime, setConvertedDepartureTime] = useState("");
  const [gmtArrivalTime, setGmtArrivalTime] = useState("");
  const [gmtDepartureTime, setGmtDepartureTime] = useState("");

  const { fbos } = useSelector((state) => ({
    fbos: state.fbos,
  }));

  useEffect(() => {
    if (props.requestType === 1) {
      setEstimateArrivalTime(null)
      setConvertedDepartureTime(null);
      props.selectedEstimateArrivalTime("");
    }
    if(props.requestType === 2) {
      setEstimateArrivalTime(new Date());
      props.selectedEstimateArrivalTime(new Date());
      
    }
    setConvertedArrivalTime("");
    dateTimeByZone(new Date(), "arrival");
  }, [props.requestType]);

  const handleEstimateArrival = (date) => {
    if (estimateDepartureTime !== null || date === "Invalid date:undefined") {
      setEstimateDepartureTime(null);
      setConvertedDepartureTime("");
      setGmtDepartureTime("");
      props.selectedEstimateDepartureTime(null);
    }
    setEstimateArrivalTime(date);
    let dateStringWithTime = moment(date).format("YYYY-MM-DD HH:mm:ss");
    let abc = dateStringWithTime.split(" ");
    let time = abc[1];
    time = time.split(":");
    let hour = "";
    if (time[0] === "00") {
      hour = "12";
    } else {
      hour = time[0];
    }
    time = `${hour}:${time[1]}`;
    dateStringWithTime = `${abc[0]} ${time}`;
    dateTimeByZone(date, "arrival");
    props.selectedEstimateArrivalTime(dateStringWithTime);
  };

  const dateTimeByZone = async (date, value) => {
    try {
      let zone = await getTimezoneByLatLong(fbos.fboGeoLoc);
      props.timezone(zone);
      var istDate = new Date(date);
      var utcDate = moment.utc(istDate);
      if (value === "arrival") {
        setConvertedArrivalTime(
          momentTimezone.tz(date, zone).format("MM/DD/YYYY HH:mm")
        );
        setGmtArrivalTime(moment(utcDate).format("MMM/DD/YYYY HH:mm"));
      } else {
        setConvertedDepartureTime(
          momentTimezone.tz(date, zone).format("MM/DD/YYYY HH:mm")
        );
        setGmtDepartureTime(moment(utcDate).format("MMM/DD/YYYY HH:mm"));
      }
    } catch (error) {
      setConvertedArrivalTime(moment(date).format("MMM/DD/YYYY HH:mm"));
    }
  };

  const handleEstimateDeparture = (date) => {
    if (props.requestType === 1) {
      setEstimateArrivalTime(null);
      setConvertedArrivalTime("");
    }
    let dateStringWithTime = moment(date).format("YYYY-MM-DD HH:mm:ss");
    let abc = dateStringWithTime.split(" ");
    let time = abc[1];
    time = time.split(":");
    let hour = "";
    if (time[0] === "00") {
      hour = "12";
    } else {
      hour = time[0];
    }
    time = `${hour}:${time[1]}`;
    dateStringWithTime = `${abc[0]} ${time}`;
    setEstimateDepartureTime(date);
    dateTimeByZone(date, "departure");
    props.selectedEstimateDepartureTime(dateStringWithTime);
  };

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const RenderEtdDate = () => {
    return (<FieldSet
      legend={props.requestType === undefined && estimateArrivalTime !== null ? "ETD(LCL)*" : "ETD(LCL)"}
      navigation={props.requestType === undefined && "fromNudge"}
    >
     
      <DatePicker
        placeholderText={props.requestType === undefined && estimateArrivalTime !== null ? props.navigation === "fromNudge" ? "ETD (LCL)*" : "TBD*" : props.navigation === "fromNudge" ? "ETD (LCL)" : "TBD"}
        minDate={
          estimateArrivalTime === null ? new Date() : estimateArrivalTime
        }
        selected={estimateDepartureTime}
        maxDate={addDays(new Date(), 14)}
        dateFormat="MMM/dd/yyyy HH:mm"
        timeIntervals={15}
        timeFormat="HH:mm"
        onChange={(date) => {
          handleEstimateDeparture(date);
        }}
        showTimeSelect
        filterTime={filterPassedTime}
        className={
          props.requestType === undefined
            ? "nudgeField secondary react-datepicker"
            : "enableField secondary react-datepicker"
        }
        popperPlacement="bottom-end"
      />
    </FieldSet>
    );
  };

  return (
    <StyledWrapper
      container
      item
      xs={12}
      style={{
        height:
          (convertedArrivalTime === "" && convertedDepartureTime === "") ||
            (estimateArrivalTime === null && estimateDepartureTime === null)
            ? 70
            : 85
      }}
    >
      <Grid container item xs={6} className={classes.mainCont}>
        <div className={classes.innerCont}
           style={{
            opacity: props.requestType === 1 ? 0.5 : 1
          }}>
          <FieldSet
            legend={"ETA (LCL)"}
            navigation={props.requestType === undefined && "fromNudge"}
          >
            <DatePicker
              placeholderText="ETA (LCL)"
              minDate={new Date()}
              selected={estimateArrivalTime}
              maxDate={addDays(new Date(), 14)}
              dateFormat="MMM/dd/yyyy HH:mm"
              timeIntervals={15}
              timeFormat="HH:mm"
              onChange={(date) => {
                handleEstimateArrival(date);
              }}
              showTimeSelect
              filterTime={filterPassedTime}
              disabled={props.requestType === 1 ? true : false}
              className={
                props.requestType === undefined
                  ? "nudgeField secondary react-datepicker"
                  : props.requestType === 1
                  ? "disableField secondary react-datepicker"
                  : "enableField secondary react-datepicker"
              }
            />
          </FieldSet>
        </div>
      </Grid>
      <Grid item xs={6} className={classes.mainCont}>
        <div
          className={classes.innerCont1} 
        >
           {RenderEtdDate()}
        </div>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: -15 }}>
        <Grid item xs={6}>
          <div
            className={classes.gmtTimeStyle}
            style={{paddingLeft: 15}}>
            {gmtArrivalTime === "" || estimateArrivalTime === null
              ? null
              : `${gmtArrivalTime} (Z)`}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            className={classes.gmtTimeStyle}
            style={{
              paddingLeft: 20,
            }}
          >
            {gmtDepartureTime === "" || estimateDepartureTime === null
              ? null
              : `${gmtDepartureTime} (Z)`}
          </div>
        </Grid>
      </Grid>
    </StyledWrapper>
  );
};

export default FuelRequestDate;
