import React, { useEffect, useState } from "react";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import styled from "styled-components";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { cancelRequest } from "../../../../redux/requests/requests.actions";
import { getUnreadNotificationCount } from "../../../../redux/notifications/notifications.actions";
import colors from "../../../../constants/colors";
import * as services from "../../../../services/mainService";
import tickIcon from "../../../../assets/images/tickIcon.png";
import poweredByNozl from "../../../../assets/images/poweredByNozl.png";
import { useStyles } from "../../../../assets/styles/FuelBooking";
import PriceView from "../PriceView";
import TotalPrice from "./TotalPrice";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";

const StyledGrid = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const CustomTooltipStyle = withStyles((theme) => ({
  tooltip: {
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    "-webkit-backdrop-filter": "blur(10px)",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(51, 51, 51, 0.7)",
  },
}))(Tooltip);

export default function BookingCreated(props) {
  const classes = useStyles(colors, isMobile);
  const [bookingDetail, setBookingDetail] = useState({});
  const [, setResultList] = React.useState(props.bookingResult);
  const history = useHistory();
  const dispatch = useDispatch();
  const [dialog, setdialog] = useState(false);
  const [isPilotsExpend, setisPilotsExpend] = useState(false);
  const [createdBy, setCreatedBy] = useState("");

  const { auth, account } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
  }));

  const getBookingDetail = async () => {
    const response = await services.getBookings({
      token: auth.token,
      record: {page: 1, rowsNumber: 20, sortBy: "", descending: true, search:"", bookingDate:"", status:""}
    });
    if (response.response_type === "success") {
      const bookingDetails = await services.getNotificationDetail({
        notification: {
          bookingId: response.response.data.data.data[0].bookingId,
        },
        token: auth.token,
      });
      if (response.response_type === "success") {
        setBookingDetail(bookingDetails.response.data);
        setCreatedBy(bookingDetails.response.data.bookingCreatorRegDate);
      }
      dispatch(getUnreadNotificationCount({ token: auth.token }));
    }
  };

  useEffect(() => {
    if (props.aircraftList !== "empty") {
      getBookingDetail();
    }
  }, []);

  useEffect(() => {
    if (props.bookingResult !== "unlistedFbos") {
      setResultList(props.bookingResult);
    }
  }, [bookingDetail, props.bookingResult]);

  const click = () => {
    props.showFboList(true);
  };

  const goToHistory = () => {
    history.push("/history");
  };

  const inviteFriends = () => {
    history.push("/invite-friends");
  };

  const needToCancel = (value, booking) => {
    setdialog(true);
  };

  const renderInternationalServiceList = () =>
    bookingDetail.internationalFeeAndTaxes.length > 0 ? (
      <PriceView item={bookingDetail} services="confirmationServices" />
    ) : (
      <Grid container item xs={12}>
        <Grid item xs={6} className={classes.headingTextStyle2}>
        Services & Fees Total
        </Grid>
        <Grid item xs={6} className={classes.valuePriceStyle}>
          {bookingDetail.internationalFeeAndTaxes.length > 0
            ? `$${formatNumWithCommas(
                parseFloat(bookingDetail.internationalServicesCost).toFixed(2)
              )}`
            : "$0.00"}
          <div className={classes.extraSpace} />
        </Grid>
      </Grid>
    );

  const cancelBookingRequest = () => {
    setdialog(false);
    dispatch(
      cancelRequest({
        booking: { bookingId: bookingDetail.bookingId },
        token: auth.token,
      })
    );
    click();
  };

  const addAircraft = () => {
    history.push({
      pathname: "./aircraft-list",
      state: {
        param: {
          fromSetting: true,
        },
      },
    });
  };

  const renderServicesList = () => {
    let tempArray = [];
    let list = (bookingDetail?.internationalFeeAndTaxes > 0 ||
      Object.values(bookingDetail?.internationalFeeAndTaxes).length > 0) ? bookingDetail?.internationalFeeAndTaxes: bookingDetail.ancillaryServices;
      if (list?.length > 0) {
        list?.map((item) => {
        tempArray.push(item.name);
      });
      return tempArray.join(", ");
    } else {
      return "No service added";
    }
  };

  const renderPilotList = () => {
    let tempArray = [];
    if (!!bookingDetail.pilotsDetail) {
      bookingDetail?.pilotsDetail.map((pilotItem) => {
        tempArray.push(pilotItem);
      });
      return tempArray.join(", ");
    } else {
      return "No crew added";
    }
  };

  return (
    <StyledGrid className={classes.mainContainer}>
      {props.aircraftList !== "empty" ? (
        <div>
          <Grid
            container
            className={
              account.isParagon
                ? classes.imageCont
                : `${classes.imageCont} ${classes.imageCont1}`
            }
          >
            <Grid
              item
              xs={12}
              className={
                account.isParagon === true
                  ? classes.paragonCont
                  : classes.paperContainer
              }
            >
              <div className={classes.detailContainer}>
                <div className={classes.nameStyle}>{account.user.name}</div>
                {account.isParagon === true ? (
                  <div className={classes.headingTextStyle}>
                    <div className={classes.fontWeight}>P09837AG</div>
                  </div>
                ) : (
                  <div className={classes.headingTextStyle}>
                    <div className={classes.fontWeight}>N09837ZL</div>
                  </div>
                )}
                {Object.values(bookingDetail).length > 0 && (
                  <div className={classes.headingTextStyle}>
                    <div className={classes.fontWeight}>
                      Member since:{" "}
                      {createdBy !== "Invalid date" && createdBy !== ""
                        ? moment(createdBy).format("YYYY")
                        : ""}
                    </div>
                  </div>
                )}
              </div>
              {account.isParagon === true && (
                <Grid item xs={12} className={classes.poweredByImgCont}>
                  <img
                    alt=""
                    src={poweredByNozl}
                    className={classes.imageStyle}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <div className={classes.headingContainer}>
            <div className={classes.bottomContainer}>
              {props.bookingResult !== "unlistedFbos" ? (
                <>
                  <div className={classes.rateContainer}>
                    {Object.values(bookingDetail).length > 0 && (
                      <div
                        className={classes.statusTextStyle}
                        style={{
                          background:
                            bookingDetail.status === 1
                              ? "#ff9800"
                              : bookingDetail.status === 3
                              ? "#29df67"
                              : bookingDetail.status === 2
                              ? "#1E69F6"
                              : bookingDetail.status === 5
                              ? "#F4AD3D"
                              : "#df2935",
                        }}
                      >
                        {bookingDetail.status === 1
                          ? "PENDING"
                          : bookingDetail.status === 2
                          ? "ACKNOWLEDGED"
                          : bookingDetail.status === 3
                          ? "COMPLETED"
                          : bookingDetail.status === 4
                          ? "CANCELLED"
                          : "EXPIRED"}
                      </div>
                    )}
                    {Object.values(bookingDetail).length > 0 && (
                      <Grid className={classes.priceContainer}>
                        <Grid container>
                          {!bookingDetail.isServiceRequest ? (
                            <>
                              <Grid item xs={12}>
                                <PriceView
                                  item={bookingDetail}
                                  fuelPrice="confirmationFuel"
                                />
                              </Grid>
                              {bookingDetail.isRampFee ? (
                                <>
                                  <Grid
                                    item
                                    xs={6}
                                    className={classes.headingTextStyle1}
                                  >
                                    Fee
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    className={classes.valueStyle2}
                                  >
                                    {bookingDetail.rampFee === 0
                                      ? "$0.00"
                                      : `$${formatNumWithCommas(
                                          parseFloat(
                                            bookingDetail.rampFee
                                          ).toFixed(2)
                                        )}`}
                                    <div className={classes.extraSpace}></div>
                                  </Grid>
                                </>
                              ) : null}
                              <Grid item xs={12}>
                                {bookingDetail.isFBOInternationalServices
                                  ? renderInternationalServiceList()
                                  : null}
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              {bookingDetail.isFBOInternationalServices ? (
                                renderInternationalServiceList()
                              ) : (
                                <div className={classes.staticTextStyle}>
                                  This FBO is not providing fuel services.
                                </div>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <TotalPrice
                      subtotal={`$${formatNumWithCommas(
                        parseFloat(
                          bookingDetail.fuelCost +
                            bookingDetail.internationalServicesCost
                        ).toFixed(2)
                      )}`}
                      navigation="confirmationScreen"
                    />
                  </div>
                  <div className={classes.bottomCont}>
                    <Grid container item xs={12}>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.fboNameStyle}
                      >
                        <Grid item xs={12} className={classes.heading}>
                          FBO Name
                        </Grid>
                        <Grid item xs={12} className={classes.headingTextStyle}>
                          <div className={classes.fontWeight}>
                            {Object.values(bookingDetail).length > 0 && (
                              <div>{bookingDetail?.fboName}</div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <div className={classes.borderStyle} />
                      <Grid
                        container
                        item
                        xs={12}
                        className={`${classes.container} `}
                      >
                        <Grid item xs={6}>
                          <div className={classes.heading}>Aircraft Tail</div>
                          <div className={classes.textStyle}>
                            {(Object.values(bookingDetail).length > 0 ||
                              bookingDetail.length > 0) &&
                              bookingDetail?.aircraftRegistryName.toUpperCase()}
                          </div>
                        </Grid>
                        <Grid item xs={6} className={classes.marginStyle}>
                          <div className={classes.heading}>Fuel Requested</div>
                          <div className={classes.textStyle}>
                            {Object.values(bookingDetail).length > 0 && (
                              <div>
                                {bookingDetail.isServiceRequest
                                  ? "NA"
                                  : bookingDetail.volume === 0
                                  ? "Captain's Request"
                                  : bookingDetail.volume}
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={`${classes.container1} ${classes.marginStyle}`}
                      >
                        <Grid item xs={6}>
                          <div className={classes.heading}>ETA</div>
                          <div className={classes.textStyle}>
                            {(Object.values(bookingDetail).length > 0 ||
                              bookingDetail.length > 0) &&
                            bookingDetail.requestType === 2
                              ? moment(bookingDetail.dateOfUplift).format(
                                  "MMM/DD/YYYY HH:mm"
                                )
                              : "Aircraft on Ground"}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.heading}>ETD</div>
                          <div className={classes.textStyle}>
                            {Object.values(bookingDetail).length > 0 &&
                            bookingDetail.etd !== undefined &&
                            bookingDetail.etd !== null
                              ? moment(bookingDetail.etd).format(
                                  "MMM/DD/YYYY HH:mm"
                                )
                              : "TBD"}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={`${classes.container1} ${classes.marginStyle}`}
                      >
                        <Grid item xs={6}>
                          <div className={classes.heading}>Flying From</div>
                          <div className={classes.textStyle}>
                            {Object.values(bookingDetail).length > 0 && (
                              <div>
                                {bookingDetail.flyingFrom !== "" &&
                                bookingDetail.flyingFrom !== null
                                  ? bookingDetail.flyingFrom
                                  : "NA"}
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.heading}>Flying To</div>
                          <div className={classes.textStyle}>
                            {Object.values(bookingDetail).length > 0 && (
                              <div>
                                {bookingDetail.flyingTo !== "" &&
                                bookingDetail.flyingTo !== null
                                  ? bookingDetail.flyingTo
                                  : "NA"}
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        {Object.values(bookingDetail).length > 0 &&
                        bookingDetail.pilotsDetail.length > 0 ? (
                          <Grid container className={classes.headingStyle}>
                            <Grid item xs={12} className={classes.heading}>
                              Crew
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                item
                                xs={12}
                                className={classes.serviceStyleCont}
                              >
                                <Grid className={classes.valueStyle}>
                                  {renderPilotList()}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                      {Object.values(bookingDetail).length > 0 && 
                      (bookingDetail?.internationalFeeAndTaxes.length>0|| bookingDetail.ancillaryServices.length > 0) ? (
                        <Grid container className={classes.headingStyle}>
                          <Grid item xs={12} className={classes.heading}>
                          Services & Fees
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              item
                              xs={12}
                              className={classes.serviceStyleCont}
                            >
                              <Grid className={classes.valueStyle}>
                                {renderServicesList()}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                      {Object.values(bookingDetail).length > 0 &&
                      bookingDetail.message ? (
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.headingStyle}
                        >
                          <div>
                            <div className={classes.heading}>Comments</div>
                            <div className={classes.textStyle}>
                              {bookingDetail.message.length > 100 ? (
                                <Tooltip
                                  title={
                                    <div className={classes.messageStyle}>
                                      {bookingDetail.message}
                                    </div>
                                  }
                                >
                                  <div>
                                    {bookingDetail.message.slice(0, 100)}
                                    <span>...</span>
                                  </div>
                                </Tooltip>
                              ) : (
                                <div>{bookingDetail.message}</div>
                              )}
                            </div>
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </div>
                </>
              ) : (
                <div className={classes.unlistedCont}>
                  <img
                    src={tickIcon}
                    alt="Booking Icon"
                    className={classes.tickIconStyle}
                  />
                  <div className={classes.textValue}>
                    Your arrival request has been created
                  </div>
                  <div className={classes.textValue2}>
                    Please check your email for the confirmation.
                  </div>
                </div>
              )}
              <Grid
                container
                item
                xs={12}
                className={classes.bottomButtonLinkStyle}
                style={{
                  position: props.bookingResult === "unlistedFbos" && "fixed",
                }}
              >
                {props.bookingResult !== "unlistedFbos" ? (
                  <div
                    className={classes.needToCancelStyle}
                    onClick={() => needToCancel()}
                  >
                    Need to Cancel?
                  </div>
                ) : null}
                <Grid container item xs={12}>
                  <Grid
                    container
                    item
                    xs={12}
                    className={
                      props.bookingResult !== "unlistedFbos"
                        ? classes.buttonContainer
                        : classes.buttonContainer1
                    }
                  >
                    {props.bookingResult !== "unlistedFbos" && (
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          className={classes.historyButton}
                          onClick={() => goToHistory()}
                        >
                          Go to History
                        </Button>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={props.bookingResult !== "unlistedFbos" ? 6 : 12}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        className={classes.button}
                        onClick={() => click()}
                      >
                        Home
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <div
                  className={classes.inviteFriendsStyle}
                  onClick={() => inviteFriends()}
                >
                  Invite Friends
                </div>
              </Grid>
            </div>
          </div>
          <Dialog
            open={dialog}
            onClose={() => setdialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel this request?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setdialog(false)} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={cancelBookingRequest}
                color="secondary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className={classes.headingContainer}>
            <div className={classes.heading2}>
              {auth.userType !== 2
                ? " There are no aircraft assigned to you. You can add an  aircraft(non custom and custom) to proceed with the arrival request."
                : "You cannot create an arrival request as your custom aircraft is awaiting approval. You can add an aircraft(non-custom) to proceed with the arrival request."}
            </div>
            <div className={classes.buttonCont}>
              <Button
                fullWidth
                variant="contained"
                className={classes.button}
                onClick={() => addAircraft()}
              >
                Add Aircraft
              </Button>
            </div>
          </div>
        </div>
      )}
    </StyledGrid>
  );
}
