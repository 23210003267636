import React, { useState } from "react";
import EmailIcon from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useStyles } from "../../assets/styles/IframeStyles";
import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import { toast } from "react-toastify";
import ForgotSchema from "../../utils/forms/schema.iframeForgotPassword";
import * as authService from "../../services/authService.js";
import errorIcon from "../../assets/images/errorIcon.png";

const IframeForgotPassword = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const { handleCancel } = props;

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.passwordStyle}>Forgot your password?</div>
      <div className={classes.textCont}>
        Enter your registered email below to receive reset password instruction.
      </div>
      <div className={`${classes.textStyle2} ${classes.textCommonStyle}`}>
        Flight Department
      </div>

      <Formik
        validationSchema={ForgotSchema}
        initialValues={{
          email: "",
        }}
        isInitialValid={false}
        onSubmit={async (values) => {
          const res = await authService.forgotPassword({
            email: email.toLowerCase(),
            portal: 1,
          });
          if (res.response_type === "fail") {
            toast.info(res.response.message, {
              position: "bottom-center",
              closeButton: false,
              style: { color: "white", background: "#9f215f" },
            });
          } else {
            toast(res.response.message, {
              position: "bottom-center",
              closeButton: false,
              style: { color: "white", background: "#9f215f" },
            });
            props.closeForgotPassword(false);
          }
        }}
      >
        {({ handleChange, handleBlur, touched, errors, handleSubmit }) => (
          <div className={classes.forgotFieldCont}>
            <TextField
              required
              id="email"
              placeholder="Email Address"
              value={email}
              variant="outlined"
              autoComplete="off"
              onChange={(e) => {
                if (
                  e.target.value.match("^(?!\\s)[a-zA-Z 0-9_@.'&/#-]*$") != null
                ) {
                  handleChange(e);
                  setEmail(e.target.value);
                }
              }}
              onBlur={handleBlur}
              type="text"
              inputProps={{
                style: {
                  height: 5,
                },
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
              className={classes.customLabelInput}
              InputProps={{
                style: { color: "#000" },
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon style={{ color: "#7a869a" }} />
                  </InputAdornment>
                ),
                endAdornment:
                  Object.keys(errors).length > 0 && errors.email ? (
                    <InputAdornment position="end">
                      <img src={errorIcon} alt="" style={{ width: 20, height: 20 }} />
                    </InputAdornment>
                  ) :  null,
              }}
              {...(Object.keys(errors).length > 0 &&
                errors.email && {
                  error: true,
                  helperText: (
                    <div className={classes.errorTextStyle}>{errors.email}</div>
                  ),
                })}
            />
            <div
              disabled={Object.keys(errors).length > 0}
              onClick={handleSubmit}
              autoFocus={true}
              className={`${classes.submitButton} ${classes.forgotSubmitButton}`}
            >
              Send me the link
            </div>
            <div onClick={handleCancel} className={classes.cancelCont}>
              <div>Cancel</div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default IframeForgotPassword;
