import React from "react";
import AircraftListComponent from "../../components/scene/profile-setup/AircraftList";
import AppLayout from "../Layout/AppLayout";
import { StyledContainer } from "../History";
import NozlHeadings from "../../components/ui/NozlHeadings";

export default function Home(props) {
  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <AppLayout>
        <NozlHeadings title="Manage Aircraft" />
        <AircraftListComponent location={props.location} />
      </AppLayout>
    </StyledContainer>
  );
}
