import { makeStyles } from "@material-ui/core/styles";
import nozlBg2 from "../../assets/images/nozlBg2.png";
import paragonBg1 from "../../assets/images/paragonBg1.png";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "linear-gradient(99deg, #212121 15%, #000000 85%)",
    backgroundColor: "#000",
    overflow: "auto",
    height: "calc(100vh - 40px)",
    width: 400,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  paperContainer: {
    backgroundImage: `url(${nozlBg2})`,
    backgroundRepeat: "no-repeat",
    color: "white",
    width: 374,
    height: 177,
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 11px 16px",
    objectFit: "contain",
    borderRadius: 3,
  },
  paragonCont: {
    backgroundImage: `url(${paragonBg1})`,
    backgroundRepeat: "no-repeat",
    color: "white",
    width: 374,
    height: 205,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "16px 16px 11px 16px",
    alignItems: "center",
    objectFit: "contain",
    borderRadius: 3,
  },
  imageCont: {
    display: "flex",
    width: "100%",
    height: 210,
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  imageCont1: {
    height: 180,
  },
  detailContainer: {
    width: 338,
  },
  poweredByImgCont: {
    width: 338,
    height: 60,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  imageContainer: {
    height: 150,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  heading1: {
    fontWeight: "bold",
    fonstSize: 20,
    color: "white",
    padding: 10,
  },
  heading2: {
    fontSize: 14,
    color: "white",
    padding: 10,
    width: "90%",
  },
  button: {
    backgroundColor: (colors) => colors.primaryColor,
    color: "#fff",
    width: "95%",
    borderRadius: 3,
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: (colors) => colors.primaryColor,
    },
  },
  historyButton: {
    color: "#fff",
    width: "95%",
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 3,
    background: "transparent",
    border: "1px solid white",
    textTransform: "capitalize",
  },
  customAircraft: {
    background: "linear-gradient(99deg, #212121 15%, #000000 85%)",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 400,
  },
  bottomButtonLinkStyle: {
    width: 400,
    height: 160,
    marginTop: 10,
    textAlign: "center",
    bottom: 0,
  },
  bottomCont: {
    marginTop: 20,
    width: 374,
    background: "linear-gradient(to right, #484747, #222121)",
    backgroundColor: "#000",
    borderRadius: 3,
  },
  formCard2: {
    padding: 15,
    background: "linear-gradient(to right, #484747, #222121)",
    backgroundColor: "#000",
    width: 400,
    justifyContent: "center",
    alignSelf: "center",
    alignItem: "center",
    alignContent: "center",
    border: "0px solid black",
    borderRadius: "5px!important",
  },
  detailCont: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    paddingLeft: 10,
  },
  heading: {
    color: "white",
    fontSize: 14,
  },
  textStyle: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 5,
  },
  rampFeeDiv: {
    color: "white",
    fontSize: 14,
  },
  rateCont: {
    color: "white",
    fontWeight: "bold",
    fontSize: 20,
  },
  actualRateCont: {
    fontSize: 14,
    color: "white",
  },
  statusTextStyle: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
    padding: 10,
    borderRadius: 14,
    marginTop: 16,
  },
  actualRateCont1: {
    fontSize: 14,
    color: "white",
    margin: 5,
    marginBottom: 10,
  },
  crossAmount: {
    alignSelf: "center",
    fontSize: 20,
    marginTop: 10,
    color: "#fff",
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  rateContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  bottomContainer: {
    width: 400,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonContainer1: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  pilotHead: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    borderRadius: 5,
    height: 30,
    borderColor: (colors) => colors.denim,
  },
  titlePilot: {
    color: "#fff",
    justifyContent: "center",
    fontSize: 14,
    alignSelf: "center",
  },
  pilotName: {
    fontSize: 16,
    color: "white",
  },
  pilotItem: {
    paddingVertical: 6,
    paddingHorizontal: 4,
  },
  rateCont1: {
    color: "white",
    fontWeight: "bold",
    fontSize: 30,
  },
  inviteFriendsStyle: {
    color: "#fff",
    textAlign: "center",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
    textDecoration: "underline",
    cursor: "pointer",
  },
  needToCancelStyle: {
    height: 40,
    color: "#fff",
    textAlign: "center",
    width: "100%",
    marginTop: 20,
    marginBottom: 10,
    cursor: "pointer",
  },
  pristFeeStyle: {
    paddingLeft: 15,
    marginTop: 5,
  },
  unlistedCont: {
    width: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 365px)",
  },
  textValue: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 20,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#fff",
    marginTop: 10,
  },
  textValue2: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#fafbfc",
    marginTop: 10,
  },
  headingStyle: {
    paddingBottom: 24,
    paddingLeft: 15,
    paddingRight: 15,
  },
  messageStyle: {
    fontSize: 12,
    padding: 1,
  },
  tickIconStyle: {
    width: 70,
    height: 70,
  },
  needToCancelCont: {
    width: 400,
    height: 160,
    marginTop: 10,
    textAlign: "center",
    bottom: 0,
  },
  buttonCont: {
    width: "90%",
    marginTop: 50,
  },
  nameStyle: {
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  headingTextStyle: {
    marginTop: 5,
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  container: {
    paddingLeft: 15,
    marginTop: 20,
  },
  container1: {
    paddingLeft: 15,
  },
  fontWeight: {
    fontWeight: "bold",
  },
  padding: {},
  marginTop: {
  },
  imageStyle: {
    width: 108,
    height: 60,
  },
  valueStyle: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  naValueStyle: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 3,
  },
  valueStyle1: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "right",
  },
  serviceStyleCont: {
    paddingTop: 8,
  },
  priceContainer: {
    width: "90%",
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 32,
    padding: "16px",
    borderRadius: "5px",
    boxShadow:
      "2px 2px 10px 0 rgba(0, 0, 0, 0.2), -2px -2px 5px 0 rgba(120, 120, 120, 0.05)",
    border: "solid 1px rgba(238, 238, 238, 0.05)",
    backgroundColor: "#131415",
  },
  headingTextStyle1: {
    width: "100%",
    height: 40,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    color: "#fff",
  },
  valueStyle2: {
    width: "100%",
    height: 40,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#fff",
  },
  headingCont: {
    flexGrow: 0,
    marginTop: 24,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#eee",
  },
  headingDetailCont: {
    color: "#000",
  },
  priceValueStyle: {
    flexGrow: 0,
    margin: 4,
    fontFamily: "Arial",
    fontSize: "32px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fafafa",
  },
  priceDetailValueStyle: {
    color: "#333",
  },
  staticTextStyle: {
    flexGrow: 0,
    margin: "4px 0 0",
    opacity: 0.7,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fafafa",
  },
  staticDetailTextStyle: {
    color: "#333",
  },
  headingTextStyle2: {
    display: "flex",
    width: "100%",
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "15px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#fff",
    alignItems: "center",
  },
  valuePriceStyle: {
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    display: "flex",
    textAlign: "right",
    color: "#fff",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  extraSpace: {
    width: 20,
    height: 20,
  },
  fboNameStyle: {
    margin: 16,
  },
  marginStyle: {
    marginBottom: 24,
  },
  borderStyle: {
    height: "1px",
    width: "100%",
    marginLeft: 15,
    marginRight: 15,
    border: "1px solid #666",
  },
}));

export { useStyles };
