import { createAction } from "../utils";
import * as actionTypes from "./account.action-types";

export const getProfile = (payload) =>
  createAction(actionTypes.GET_PROFILE.REQUEST, payload);

export const getProfileResponse = (payload) =>
  createAction(actionTypes.GET_PROFILE.SUCCESS, payload);

export const submitProfile = (profile) =>
  createAction(actionTypes.SUBMIT_PROFILE.REQUEST, profile);

export const updateProfileSettings = (profile) =>
  createAction(actionTypes.UPDATE_PROFILE_SETTINGS.REQUEST, profile);

export const getAirplaneList = () =>
  createAction(actionTypes.GET_AIRPLANE_LIST.REQUEST, {});

export const getAircraftList = (payload) =>
  createAction(actionTypes.GET_AIRCRAFT_LIST.REQUEST, payload);

export const getAircraftListByInviteePilot = (payload) =>
  createAction(actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.REQUEST, payload);

export const addAircraft = (aircraft) =>
  createAction(actionTypes.ADD_AIRCRAFT.REQUEST, aircraft);

export const deleteAircraft = (aircraft) =>
  createAction(actionTypes.DELETE_AIRCRAFT.REQUEST, aircraft);

export const editAircraft = (manager) =>
  createAction(actionTypes.EDIT_AIRCRAFT.REQUEST, manager);

export const setUserSelectedAircraft = (payload) => ({
  type: actionTypes.SET_USER_SELECTED_AIRCRAFT,
  payload: payload,
});

export const clearAssignedAircraft = () =>
  createAction(actionTypes.CLEAR_ASSIGNED_AIRCRAFT, {});

export const clearSelectedAircraft = () =>
  createAction(actionTypes.CLEAR_USER_SELECTED_AIRCRAFT, {});

export const getAssignedPilotAircraftList = (token, aircraftId) =>
  createAction(actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.REQUEST, {
    token,
    aircraftId,
  });

export const assignPILOTS = (token, pilotUserId, aircraftId) =>
  createAction(actionTypes.ASSIGN_PILOTS.REQUEST, {
    token,
    pilotUserId,
    aircraftId,
  });

export const getAircraftMakerList = (payload) => {
  return createAction(actionTypes.GET_AIRCRAFT_MAKER_LIST.REQUEST, {});
};

export const addCustomAircraft = (aircraft) =>
  createAction(actionTypes.ADD_CUSTOM_AIRCRAFT.REQUEST, aircraft);

export const addOperatorManager = (manager) =>
  createAction(actionTypes.ADD_OPERATOR_MANAGER.REQUEST, manager);

export const addAdditionalOperatorManager = (manager) =>
  createAction(actionTypes.ADD_ADDITIONAL_MANAGER.REQUEST, manager);

export const deleteManager = (manager) =>
  createAction(actionTypes.DELETE_MANAGER.REQUEST, manager);

export const editManager = (manager) =>
  createAction(actionTypes.EDIT_MANAGER.REQUEST, manager);

export const submitManager = (payload) =>
  createAction(actionTypes.SUBMIT_MANAGER.REQUEST, payload);

export const advancedSearch = (payload) =>
  createAction(actionTypes.ADVANCED_SEARCH, payload);

export const rangeValue = (payload) =>
  createAction(actionTypes.SLIDER_VALUE, payload);

export const contactAdmin = (payload) =>
  createAction(actionTypes.CONTACT_ADMIN.REQUEST, payload);

export const isParagon = (payload) =>
  createAction(actionTypes.IS_PARAGON, payload);

export const isParagonUser = (payload) =>
  createAction(actionTypes.IS_PARAGON_USER, payload);

export const searchHistoryList = (payload) =>
  createAction(actionTypes.SEARCH_HISTORY, payload);

export const userBaseLocation = (payload) =>
  createAction(actionTypes.USER_BASE_LOCATION, payload);
