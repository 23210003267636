import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  customLabelInput: {
    width: "100%",
    color: "white",
    "& label": {
      transform: "translate(14px, 13px) scale(1)",
      "&.Mui-focused": {
        color: "black",
      },
      "&.Mui-error": {
        color: "#666",
      },
    },
  },
  notchedOutline: {
    borderColor: "#ddd !important",
    borderWidth: "2px",
    boxSizing: "border box",
    borderRadius: 5,
    color: "#666",
    hintColor: "#666",
  },
}));

export default function Input(props) {
  const {
    name,
    label,
    id,
    value,
    theme,
    onClick,
    error = null,
    onChange,
    navigation,
    ...other
  } = props;
  const classes = useStyles();
  return (
    <TextField
      className={classes.customLabelInput}
      variant="outlined"
      label={label}
      name={name}
      id={id}
      value={value}
      fullWidth
      onChange={onChange}
      autoComplete="off"
      {...other}
      inputProps={{
        style: {
          height: 5,
        },
        autocomplete: "off",
        form: {
          autocomplete: "off",
        },
      }}
      InputProps={{
        style: { color: "#000" },
        autoComplete: "off",
        classes: {
          notchedOutline: classes.notchedOutline,
          input: classes.inputLine,
        },
        color: "#000",
      }}
      {...(error && { error: true, helperText: error })}
    />
  );
}
