import React from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { useButtonStyles } from "../../../assets/styles/SignupButtonStyles";
import { useSelector } from "react-redux";

const BottomButton = (props) => {
  const classes = useButtonStyles();
  const { account, auth } = useSelector((state) => ({
    account: state.account,
    auth: state.auth
  }));

  const { disabled, onBackClick = {}, onNextClick = {}, isSubmitting, isInvitePilot } = props;

  return (
    <Grid container={true} item xs={12} className={classes.bottomButtonCont}>
      <Grid item xs={12} md={6} lg={6}>
        <Grid container={true} item xs={12} className={classes.bottomButtonCont}>
          <Button
            container
            disabled={isInvitePilot}
            className={classes.backButtonText}
            onClick={onBackClick}
          >
            Back
          </Button>

          <Button
            className={
              (account.isParagonUser !== undefined &&
              account.isParagonUser === true) || auth.isIframe
                ? `${classes.backButtonText} ${classes.paragonNextButtonText}`
                : `${classes.backButtonText} ${classes.nextButtonText}`
            }
            disabled={disabled}
            onClick={onNextClick}
          >
            {isSubmitting === true && (
              <CircularProgress
                style={{ color: "white" }}
                size={20}
                thickness={5}
              />
            )}
            {!isSubmitting && "Next"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BottomButton;
