import { put, call, takeLatest } from "redux-saga/effects";
import * as authActionTypes from "./account.action-types";
import * as mainServices from "../../services/mainService";
import * as actionTypes from "./account.action-types";
import * as authActionType from "../auth/auth.actionTypes";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { emitCustomEvent } from "react-custom-events";
import { showLoader, removeLoader } from "../loader/loader.actions";

/**********/
/* Profile */
/**********/

function* getProfile(action) {
  const apiResponse = yield call(mainServices.getProfile, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      if (apiResponse.response.data?.operatorUserDetail) {
        yield put({
          type: authActionType.UPDATE_PROFILE_STATUS.REQUEST,
          payload: apiResponse.response.data?.operatorUserDetail,
        });
      }
      yield put({
        type: authActionTypes.GET_PROFILE.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: authActionTypes.GET_PROFILE.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* getProfileSuccess(action) {
 // yield put({ type: "CLEAR_PROFILE_RESPONSE", payload: {} });
}

function* getProfileFailure(action) {
  //Alert.alert("Cannot get profile.");
}

function* getAirplaneList(action) {
  const apiResponse = yield call(mainServices.getAirplaneList, {});
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_AIRPLANE_LIST.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_AIRPLANE_LIST.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* getAirplaneListSuccess() { }

function* getAirplaneListFailure() { }

function* addAircraft(action) {
  const apiResponse = yield call(mainServices.addAircraft, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      apiResponse.response.action = action.payload;
      yield put({
        type: actionTypes.ADD_AIRCRAFT.SUCCESS,
        payload: apiResponse.response,
      });
      yield put({
        type: actionTypes.GET_AIRCRAFT_LIST.SUCCESS,
        payload: apiResponse.response.data,
      });
      toast(apiResponse.response.message, { position: "bottom-center" });
    } else {
      yield put({
        type: actionTypes.ADD_AIRCRAFT.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* addAircraftSuccess(action) {
  yield put({
    type: actionTypes.GET_AIRCRAFT_LIST.REQUEST,
    payload: { token: action.payload.action.token },
  });
}

function* addAircraftFailure(action) {
  if(action.payload.message.includes("User not found") || action.payload.message === "Invalid Token"){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    toast.info(action.payload.message, { autoClose: 2000, position: "bottom-center" })
  }
}

function* deleteAircraft(action) {
  const apiResponse = yield call(mainServices.deleteAircraft, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      apiResponse.response.action = action.payload;
      yield put({
        type: actionTypes.DELETE_AIRCRAFT.SUCCESS,
        payload: apiResponse.response,
      });
      if (action.payload.onSearchList === false) {
        yield put({
          type: actionTypes.GET_AIRCRAFT_LIST.SUCCESS,
          payload: apiResponse.response.data,
        });
      }
    } else {
      yield put({
        type: actionTypes.DELETE_AIRCRAFT.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* deleteAircraftSuccess(action) {
  if (action.payload.action.onSearchList === false) {
    yield put({
      type: actionTypes.GET_AIRCRAFT_LIST.REQUEST,
      payload: { token: action.payload.action.token },
    });
  }
}

function* deleteAircraftFailure(action) {
  //yield put({ type: actionTypes.CLEAR_DELETE_AIRCRAFT_RESPONSE });
}

//edit aircraft
function* editAircraft(action) {
  const apiResponse = yield call(mainServices.editAircraft, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      apiResponse.response.data.action = action.payload;
      yield put({
        type: actionTypes.EDIT_AIRCRAFT.SUCCESS,
        payload: apiResponse.response.data,
      });
      toast(apiResponse.response.message, { position: "bottom-center" });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center" });
      yield put({
        type: actionTypes.EDIT_AIRCRAFT.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* editAircraftSuccess(action) {
  yield put({
    type: actionTypes.GET_AIRCRAFT_LIST.REQUEST,
    payload: { token: action.payload.action.token },
  });
}

function* editAircraftFailure(action) {
  //yield put({ type: actionTypes.CLEAR_EDIT_AIRCRAFT_RESPONSE });
}

//get aircraft list
function* getAircraftList(action) {
  yield put(showLoader());
  const apiResponse = yield call(mainServices.getAircraftList, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_AIRCRAFT_LIST.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_AIRCRAFT_LIST.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
  yield put(removeLoader());
}

//get aircraft list by invitee pilot
function* getAircraftListByInviteePilot(action) {
  yield put(showLoader());
  const apiResponse = yield call(mainServices.getAircraftListByInviteePilotApi, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
  yield put(removeLoader());
}

function* getAircraftListSuccess(action) { }

function* getAircraftListFailure(action) {
  if(action.payload.message.includes("User not found") || action.payload.message === "Invalid Token"){
  yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
} }

function* submitProfile(action) {
  const apiResponse = yield call(mainServices.submitProfile, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      const tempData = {
        isActive: action.payload.isActive,
      };
      apiResponse.response.data = tempData;
      yield put({
        type: actionTypes.SUBMIT_PROFILE.SUCCESS,
        payload: apiResponse.response.data,
      });
      if (action.payload.isActive !== 1) {
        yield put({ type: authActionTypes.LOG_OUT.SUCCESS });
      } else {
        yield put(push("/home"));
      }
    } else {
      yield put({
        type: actionTypes.SUBMIT_PROFILE.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

const showAlert = (title, msg) => {
  if (msg !== undefined && msg !== "") {
    setTimeout(() => {
      // Alert.alert(title, msg);
    }, 500);
  }
};

function* submitProfileSuccess(action) {
  if (action?.payload?.isActive === 1) {
    //yield put(push("/home"));
  } else {
    toast(
      "Your account has been created successfully and sent for account approval.", { position: "bottom-center" }
    );
    yield put(push("/login"));
    setTimeout(() => {
      emitCustomEvent("nozlEvent", { showPopup: true });
    }, 1200);
    // yield
  }
}

function* submitProfileFailure(action) { }

function* updateProfileSettings(action) {
  yield put(showLoader());
  const apiResponse = yield call(mainServices.editProfile, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast(apiResponse.response.message, { position: "bottom-center" });
      yield put({
        type: actionTypes.UPDATE_PROFILE_SETTINGS.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center" });
      yield put({
        type: actionTypes.UPDATE_PROFILE_SETTINGS.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
  yield put(removeLoader());
}

function* updateProfileSettingsSuccess(action) { }

function* updateProfileFailure(action) {
  showAlert(`${action.payload.message}`);
}

// get assigned pilot to aircraft
function* getAssignedPilotAircraftList(action) {
  const apiResponse = yield call(
    mainServices.getAssignedPilotAircraftList,
    action.payload
  );
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}
function* getAssignedPilotAircraftListSuccess(action) { }
function* getAssignedPilotAircraftListFailure(action) { }

// assign pilot to aircraft
function* assignPilotToAircraft(action) {
  const apiResponse = yield call(
    mainServices.assignPilotToAircraft,
    action.payload
  );

  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.ASSIGN_PILOTS.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center" });
      yield put({
        type: actionTypes.ASSIGN_PILOTS.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* assignPilotToAircraftSuccess(action) { }
function* assignPilotToAircraftFailure(action) { }

//add custom aircraft
function* addCustomAircraft(action) {
  const apiResponse = yield call(
    mainServices.addCustomAircraft,
    action.payload
  );
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      apiResponse.response.data.action = action.payload;
      yield put({
        type: actionTypes.ADD_CUSTOM_AIRCRAFT.SUCCESS,
        payload: apiResponse.response.data,
      });
      yield put({
        type: actionTypes.GET_AIRCRAFT_LIST.SUCCESS,
        payload: apiResponse.response.data,
      });
      toast(apiResponse.response.message, { position: "bottom-center" });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center" });
      yield put({
        type: actionTypes.ADD_CUSTOM_AIRCRAFT.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* addCustomAircraftSuccess(action) {
  yield put({
    type: actionTypes.GET_AIRCRAFT_LIST.REQUEST,
    payload: { token: action.payload.action.token },
  });
}

function* addCustomAircraftFailure(action) { }

// get aircraft maker list
function* getAircraftMakerList(action) {
  const apiResponse = yield call(
    mainServices.getAircraftMakerListApi,
    action.payload
  );
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_AIRCRAFT_MAKER_LIST.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: authActionTypes.GET_AIRCRAFT_MAKER_LIST.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* getAircraftMakerListSuccess(action) { }

function* getAircraftMakerListFailure(action) { }

//add Manage Contacts
function* addOperatorManager(action) {
  const apiResponse = yield call(
    mainServices.addOperatorManagerApi,
    action.payload
  );
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      apiResponse.response.data.action = action.payload;
      yield put({
        type: actionTypes.ADD_OPERATOR_MANAGER.SUCCESS,
        payload: apiResponse.response.data,
      });
      toast("Manage Managers added successfully", { position: "bottom-center" });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center" });
      yield put({
        type: actionTypes.ADD_OPERATOR_MANAGER.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* addOperatorManagerSuccess(action) {
  yield put({
    type: actionTypes.GET_PROFILE.REQUEST,
    payload: { token: action.payload.action.token },
  });
}

function* addOperatorManagerFailure(action) { }

//add additional Manage Contacts
function* addAdditionalOperatorManager(action) {
  const apiResponse = yield call(
    mainServices.addAdditionalOperatorManagerApi,
    action.payload
  );
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      apiResponse.response.data.action = action.payload;
      yield put({
        type: actionTypes.ADD_ADDITIONAL_MANAGER.SUCCESS,
        payload: apiResponse.response.data,
      });
      toast("Additional Manage Contacts added successfully", { position: "bottom-center" });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center" });
      yield put({
        type: actionTypes.ADD_ADDITIONAL_MANAGER.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* addAdditionalOperatorManagerSuccess(action) {
  yield put({
    type: actionTypes.GET_PROFILE.REQUEST,
    payload: { token: action.payload.action.token },
  });
}

function* addAdditionalOperatorManagerFailure(action) { }

// delete manager
function* deleteManager(action) {
  const apiResponse = yield call(mainServices.deleteManagerApi, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      apiResponse.response.action = action.payload;
      yield put({
        type: actionTypes.DELETE_MANAGER.SUCCESS,
        payload: apiResponse.response,
      });
      yield put({
        type: actionTypes.GET_PROFILE.SUCCESS,
        payload: apiResponse.response.data,
      });
      toast(apiResponse.response.message, { position: "bottom-center" });
    } else {
      yield put({
        type: actionTypes.DELETE_MANAGER.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* deleteManagerSuccess(action) {
  yield put({
    type: actionTypes.GET_PROFILE.REQUEST,
    payload: { token: action.payload.action.token },
  });
}

function* deleteManagerFailure(action) {
  //yield put({ type: actionTypes.CLEAR_DELETE_AIRCRAFT_RESPONSE });
}

//edit manager
function* editManager(action) {
  const apiResponse = yield call(mainServices.editManagerApi, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      apiResponse.response.data.action = action.payload;
      yield put({
        type: actionTypes.EDIT_MANAGER.SUCCESS,
        payload: apiResponse.response.data,
      });
      toast(apiResponse.response.message, { position: "bottom-center" });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center" });
      yield put({
        type: actionTypes.EDIT_MANAGER.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* editManagerSuccess(action) {
  yield put({
    type: actionTypes.GET_AIRCRAFT_LIST.REQUEST,
    payload: { token: action.payload.action.token },
  });
}

function* editManagerFailure(action) {
  //yield put({ type: actionTypes.CLEAR_EDIT_AIRCRAFT_RESPONSE });
}

// contact admin
function* contactAdmin(action) {
  const apiResponse = yield call(mainServices.contactAdminApi, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      toast(apiResponse.response.message, { position: "bottom-center" });
    } else {
      toast.info(apiResponse.response.message, { position: "bottom-center" });
    }
  }
}

function* contactAdminSuccess(action) { }

function* contactAdminFailure(action) {
  //yield put({ type: actionTypes.CLEAR_EDIT_AIRCRAFT_RESPONSE });
}

export function* accountSaga() {
  // fetch user profile
  yield takeLatest(actionTypes.GET_PROFILE.REQUEST, getProfile);
  yield takeLatest(actionTypes.GET_PROFILE.SUCCESS, getProfileSuccess);
  yield takeLatest(actionTypes.GET_PROFILE.FAILURE, getProfileFailure);

  // get airplane list
  yield takeLatest(actionTypes.GET_AIRPLANE_LIST.REQUEST, getAirplaneList);
  yield takeLatest(
    actionTypes.GET_AIRPLANE_LIST.SUCCESS,
    getAirplaneListSuccess
  );
  yield takeLatest(
    actionTypes.GET_AIRPLANE_LIST.FAILURE,
    getAirplaneListFailure
  );

  // add new aircraft
  yield takeLatest(actionTypes.ADD_AIRCRAFT.REQUEST, addAircraft);
  yield takeLatest(actionTypes.ADD_AIRCRAFT.SUCCESS, addAircraftSuccess);
  yield takeLatest(actionTypes.ADD_AIRCRAFT.FAILURE, addAircraftFailure);

  // delete aircraft
  yield takeLatest(actionTypes.DELETE_AIRCRAFT.REQUEST, deleteAircraft);
  yield takeLatest(actionTypes.DELETE_AIRCRAFT.SUCCESS, deleteAircraftSuccess);
  yield takeLatest(actionTypes.DELETE_AIRCRAFT.FAILURE, deleteAircraftFailure);

  // edit aircraft detail
  yield takeLatest(actionTypes.EDIT_AIRCRAFT.REQUEST, editAircraft);
  yield takeLatest(actionTypes.EDIT_AIRCRAFT.SUCCESS, editAircraftSuccess);
  yield takeLatest(actionTypes.EDIT_AIRCRAFT.FAILURE, editAircraftFailure);

  // get aircraft list
  yield takeLatest(actionTypes.GET_AIRCRAFT_LIST.REQUEST, getAircraftList);
  yield takeLatest(
    actionTypes.GET_AIRCRAFT_LIST.SUCCESS,
    getAircraftListSuccess
  );
  yield takeLatest(
    actionTypes.GET_AIRCRAFT_LIST.FAILURE,
    getAircraftListFailure
  );

  // get aircraft list by invitee pilot
  yield takeLatest(actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.REQUEST, getAircraftListByInviteePilot);
  yield takeLatest(
    actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.SUCCESS,
    getAircraftListSuccess
  );
  yield takeLatest(
    actionTypes.GET_AIRCRAFT_LIST_BY_INVITEE_PILOT.FAILURE,
    getAircraftListFailure
  );

  // submit user profile
  yield takeLatest(actionTypes.SUBMIT_PROFILE.REQUEST, submitProfile);
  yield takeLatest(actionTypes.SUBMIT_PROFILE.SUCCESS, submitProfileSuccess);
  yield takeLatest(actionTypes.SUBMIT_PROFILE.FAILURE, submitProfileFailure);

  // update profile
  yield takeLatest(
    actionTypes.UPDATE_PROFILE_SETTINGS.REQUEST,
    updateProfileSettings
  );
  yield takeLatest(
    actionTypes.UPDATE_PROFILE_SETTINGS.SUCCESS,
    updateProfileSettingsSuccess
  );
  yield takeLatest(
    actionTypes.UPDATE_PROFILE_SETTINGS.FAILURE,
    updateProfileFailure
  );

  // get assigned pilot to aircraft
  yield takeLatest(
    actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.REQUEST,
    getAssignedPilotAircraftList
  );
  yield takeLatest(
    actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.SUCCESS,
    getAssignedPilotAircraftListSuccess
  );
  yield takeLatest(
    actionTypes.GET_ASSIGNED_PILOT_AIRCRAFT_LIST.FAILURE,
    getAssignedPilotAircraftListFailure
  );

  // assign pilot to aircraft
  yield takeLatest(actionTypes.ASSIGN_PILOTS.REQUEST, assignPilotToAircraft);
  yield takeLatest(
    actionTypes.ASSIGN_PILOTS.SUCCESS,
    assignPilotToAircraftSuccess
  );
  yield takeLatest(
    actionTypes.ASSIGN_PILOTS.FAILURE,
    assignPilotToAircraftFailure
  );

  //add custom aircraft
  yield takeLatest(actionTypes.ADD_CUSTOM_AIRCRAFT.REQUEST, addCustomAircraft);
  yield takeLatest(
    actionTypes.ADD_CUSTOM_AIRCRAFT.SUCCESS,
    addCustomAircraftSuccess
  );
  yield takeLatest(
    actionTypes.ADD_CUSTOM_AIRCRAFT.FAILURE,
    addCustomAircraftFailure
  );

  // add Manage Contacts
  yield takeLatest(
    actionTypes.ADD_OPERATOR_MANAGER.REQUEST,
    addOperatorManager
  );
  yield takeLatest(
    actionTypes.ADD_OPERATOR_MANAGER.SUCCESS,
    addOperatorManagerSuccess
  );
  yield takeLatest(
    actionTypes.ADD_OPERATOR_MANAGER.FAILURE,
    addOperatorManagerFailure
  );

  // add additional Manage Contacts
  yield takeLatest(
    actionTypes.ADD_ADDITIONAL_MANAGER.REQUEST,
    addAdditionalOperatorManager
  );
  yield takeLatest(
    actionTypes.ADD_ADDITIONAL_MANAGER.SUCCESS,
    addAdditionalOperatorManagerSuccess
  );
  yield takeLatest(
    actionTypes.ADD_ADDITIONAL_MANAGER.FAILURE,
    addAdditionalOperatorManagerFailure
  );

  //get aircraft maker list
  yield takeLatest(
    actionTypes.GET_AIRCRAFT_MAKER_LIST.REQUEST,
    getAircraftMakerList
  );
  yield takeLatest(
    actionTypes.GET_AIRCRAFT_MAKER_LIST.SUCCESS,
    getAircraftMakerListSuccess
  );
  yield takeLatest(
    actionTypes.GET_AIRCRAFT_MAKER_LIST.FAILURE,
    getAircraftMakerListFailure
  );

  // delete manager
  yield takeLatest(actionTypes.DELETE_MANAGER.REQUEST, deleteManager);
  yield takeLatest(actionTypes.DELETE_MANAGER.SUCCESS, deleteManagerSuccess);
  yield takeLatest(actionTypes.DELETE_MANAGER.FAILURE, deleteManagerFailure);

  // edit manager detail
  yield takeLatest(actionTypes.EDIT_MANAGER.REQUEST, editManager);
  yield takeLatest(actionTypes.EDIT_MANAGER.SUCCESS, editManagerSuccess);
  yield takeLatest(actionTypes.EDIT_MANAGER.FAILURE, editManagerFailure);

  // contact admin
  yield takeLatest(actionTypes.CONTACT_ADMIN.REQUEST, contactAdmin);
  yield takeLatest(actionTypes.CONTACT_ADMIN.SUCCESS, contactAdminSuccess);
  yield takeLatest(actionTypes.CONTACT_ADMIN.FAILURE, contactAdminFailure);
}