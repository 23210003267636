import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: 1,
    background: "rgb(0, 0, 0)",
  },
  innerCont: {
    [theme.breakpoints.between("sm", "xl")]: {
      width: 450,
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    background: "#222",
    height: 300,
  },
  headingStyle: {
    color: "#fff",
    marginBottom: 20,
  },
  form: {
    width: "100%",
    height: "100%",
  },
  textField: {
    color: "#fff",
    width: "100%",
    fontColor: "#fff",
    hintColor: "#fff",
    "&$focused": {
      color: "#fff",
    },
  },
  floatingLabelFocusStyle: {
    color: "#fff",
  },
  label: {
    "&$focusedLabel": {
      color: "white",
    },
    "&$erroredLabel": {
      color: "white",
    },
  },
  errorLabelonType: {
    color: colors.primaryColor,
  },
  focusedLabel: {},
  erroredLabel: {},
  notchedOutline: {
    borderWidth: "1px",
    padding: 5,
    borderColor: "white !important",
    color: "#fff",
    hintColor: "#fff",
  },
  inputLine: {
    color: "#fff",
    hintColor: "#fff",
  },
  submitButtonCont: {
    [theme.breakpoints.between("sm", "xl")]: {
      width: 400,
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    display: "flex",
    justifyContent: "center",
    height: 45,
  },
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
    [theme.breakpoints.between("sm", "xl")]: {
      width: 400,
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    height: 45,
  },
  submitParagonBtn: {
    backgroundColor: colors.paragonPrimaryColor,
    "&:hover": {
      backgroundColor: colors.paragonPrimaryColor,
    },
  },
  cardCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  popUpStyle: {
    transform: `translate(0%, 0%)`,
    width: 513,
    height: 356,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    padding: "50px 60px",
    borderRadius: 10,
    border: "none",
    outline:'none',
    boxShadow:
      "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  iconStyle: {
    width: 64,
    height: 64,
    flexGrow: 0,
    objectFit: "contain",
  },
  headingStyle1: {
    flexGrow: 0,
    margin: 10,
    fontFamily: "Arial",
    fontSize: 24,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.67px",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#333",
  },
  textStyle: {
    flexGrow: 0,

    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#333",
  },
  buttonCont: {
    height: 40,
    width: 80,
    background: colors.primaryColor,
    color: "#fff",
    fontSize: 16,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  link: {
    color: colors.primaryColor,
  },
  right: {
    textAlign: "right",
  },
}));

export { useStyles };
