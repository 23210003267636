export default {
  aquaMarine: '#47d1ea',
  lightAquaMarine: '#DCF3FA',
  white: '#ffffff',
  darkSlateBlue: '#253f68',
  denim: '#355280',
  aquaMarineTwo: '#47d7e7',
  dodgerBlue0: 'rgba(63, 152, 255, 0)',
  dodgerBlue: '#3f98ff',
  navyBlue: '#002257',
  white20: 'rgba(255, 255, 255, 0.2)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white50: 'rgba(255, 255, 255, 0.5)',
  iceBlue: '#f6faff',
  lightBlue: '#4dcceb',
  dodgerBlue30: 'rgba(66, 178, 244, 0.3)',
  charcoalGrey: '#2d343e',
  mediumPink: '#f1678c',
  lightPink: '#fff9fa',
  paleGrey: '#ebf4fe',
  veryLightBlue: '#def0ff',
  brightLightBlue: '#38d8e8',
  lightPinkTwo: '#fef4f8',
  iceBlueTwo: '#edf3f8',
  lightSkyBlue: '#d4e6f4',
  lightGreyBlue: '#a9b1bc',
  listGreyBackground: '#EDF1F5',
  darkishPink: '#e24d75',
  lightGreen: '#f3faf6',
  mediumGreen: '#1eb661',
  primaryColor: "#df2935",
  grey: '#333333',
  imageBackground: '#F3F2F2',
  paragonPrimaryColor: "#ae0061",
  gradientParagonColor: "linear-gradient(to bottom, #9f215f 0%, #7a003c 100%)",
  lightTextColor: "#999999",
  lightGrey: "#fafafa",
  blueGrey:"#7a869a",
  darkTextColor: "#333333",
  darkGrey: "#555555",
  grey40: "#666666",
  darkBlueGrey: "#42526E"
};
