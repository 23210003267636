import React, { useState } from "react";
import NozlRateUnfill from "../../assets/images/staroutline.png";
import NozlRateFill from "../../assets/images/starfilled.png";
import { useForceUpdate } from "../../customHooks/useForceUpdate";

const UnfilledStar = (props) => {
  return (
    <div>
      <img src={NozlRateUnfill} alt="Logo" className={props.style} />
    </div>
  );
};

const FilledStar = (props) => {
  return (
    <div>
      <img src={NozlRateFill} alt="Logo" className={props.style} />
    </div>
  );
};

const Star = (props) => {
  const { filled = false, size, style, handlePress, data } = props;

  return (
    <div onClick={() => handlePress(data)}>
      {filled ? (
        <FilledStar size={size} style={style} />
      ) : (
        <UnfilledStar size={size} style={style} />
      )}
    </div>
  );
};

const NozlRating = (props) => {
  const { size = 32, style } = props;

  const [rating, setRating] = useState(0);
  const forceUpdate = useForceUpdate();

  const handlePress = (rating) => {
    setRating(rating);
    if (props?.onValueChange) {
      props.onValueChange(rating);
      forceUpdate();
    }
  };

  return (
    <div className={style}>
      <Star key={1} filled={rating >= 1} size={size} style={style} handlePress={handlePress} data={1} />
      <Star key={2} filled={rating >= 2} size={size} style={style} handlePress={handlePress} data={2} />
      <Star key={3} filled={rating >= 3} size={size} style={style} handlePress={handlePress} data={3}/>
      <Star key={4} filled={rating >= 4} size={size} style={style} handlePress={handlePress} data={4}/>
      <Star key={5} filled={rating >= 5} size={size} style={style} handlePress={handlePress} data={5}/>
    </div>
  );
};

export default NozlRating;
