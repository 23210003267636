import { createAction } from "../utils";
import * as actionTypes from "./signup.actionTypes";

export const registerAs = (payload) => {
  return createAction(actionTypes.REGISTER_AS, payload);
};

export const basicDetails = (payload) => {
  return createAction(actionTypes.BASIC_DETAILS, payload);
};

export const usersList = (payload) => {
  return createAction(actionTypes.USERS_LIST, payload);
};

export const aircraftsList = (payload) => {
  return createAction(actionTypes.AIRCRAFTS_LIST, payload);
};

export const timeline = (payload) => {
  return createAction(actionTypes.TIMELINE, payload);
};
