const RampFeeType = {
  MTOW_RAMPFEE: 1,
  MLW_RAMPFEE: 2,
  PARKING_AREA_RAMPFEE: 3,
  SIZE_BASED_RAMPFEE: 4,
  FILE_UPLOAD_RAMPFEE: 5,
  CUSTOM_RAMPFEE: 6,
  NOZL_SIZE_RAMPFEE: 7
};

export default RampFeeType;
