import * as actionTypes from "./requests.action-types";
import { LOG_OUT } from "../../redux/auth/auth.actionTypes";

const initialState = {
  status: "pending",
  requests: [],
  requestsMetaData: [],
  sendFeedbackResponse: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get history list
    case actionTypes.GET_REQUESTS.REQUEST:
      return { ...state, status: "loading" };
    case actionTypes.GET_REQUESTS.SUCCESS:
      return {
        ...state,
        requests: action.payload.data.data,
        requestsMetaData: action.payload.data.metadata,
        status: "success",
      };
    case actionTypes.GET_REQUESTS.FAILURE:
      return {
        ...state,
        status: "loading",
      };

    // send feedback
    case actionTypes.SEND_FEEDBACK.REQUEST:
      return { ...state, status: "loading" };
    case actionTypes.SEND_FEEDBACK.SUCCESS: {
      // const { requests } = state;
      // const newRequests = requests.map((request) => {
      //   if (request.bookingId === action.payload.bookingId) {
      //     return action.payload;
      //   }
      //   return request;
      // });
      return {
        ...state,
        sendFeedbackResponse: action.payload,
        // requests: newRequests,
        status: "success",
      };
    }
    case actionTypes.SEND_FEEDBACK.FAILURE:
      return {
        ...state,
        status: "failed",
      };

    // clear fuel request
    case actionTypes.CLEAR_ADD_FUEL_REQUEST_RESPONSE:
      return {
        ...state,
      };

    // cancel request
    case actionTypes.CANCEL_REQUEST.REQUEST:
    case actionTypes.CANCEL_REQUEST.SUCCESS: {
      // const { requests } = state;
      // const newRequests = requests.map((request) => {
      //   if (request.bookingId === action.payload.bookingId) {
      //     return action.payload;
      //   }
      //   return request;
      // });
      return {
        ...state,
        // requests: newRequests,
        status: "success",
      };
    }
    case actionTypes.CANCEL_REQUEST.FAILURE:
      break;
    case LOG_OUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
