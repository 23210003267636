import React from "react";

import ShowInfoTiers from "./ShowInfoTiers";
import { useStyles } from "../../../../assets/styles/FboItemStyles";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";
import DiscountType from "../../../../config/constants";

const FboPriceView = (props) => {
  const classes = useStyles();
  const { tiers, retailPrice, item } = props;
  let retailValue = formatNumWithCommas(parseFloat(retailPrice).toFixed(2));
  let value = !!tiers && tiers.length > 0 ? formatNumWithCommas(parseFloat(tiers[0].retailPrice).toFixed(2)) : formatNumWithCommas(parseFloat(retailPrice).toFixed(2));

  const isDiscount = () => {
    return (item.discountFuelType === DiscountType.discount.fuel_Type || DiscountType.discount.jet_A ||
      DiscountType.discount.saf ||
      DiscountType.discount.jetA_Saf_Both) &&
      (item.discountCount === 1 ||
        item.operatorRegistryDiscountCount === 1 ||
        item.operatorSpecificDiscountCount === 1 ||
        item.tailWiseDiscountCount === 1)
  }
  return (
    <div className={classes.tiersStyle}>
      {value !== retailValue ? (
        <div className={classes.discountedPriceStyle}>
          {`$${retailValue}/gal`}
        </div>
      ) : null}
      <div className={classes.priceTextStyle}>{`$${value}/gal`}</div>
      {tiers?.length > 1 && (
        <ShowInfoTiers
          tiers={tiers}
          retailPrice={retailPrice}
          discount={isDiscount}
        />
      )}
    </div>
  );
};

export default FboPriceView;
