import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import HomeComponent from "../../components/scene/app/menu/Home";
import AppLayout from '../Layout/AppLayout';

const StyledContainer = styled.div`
    overflow: auto;
`;

const StyledGridMainContainer = styled(Grid)``;

export default function Home(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
      <StyledContainer container={true} spacing={0} className="rootContainer">
        <AppLayout>
        <StyledGridMainContainer container={true} spacing={0} className="mainContainer">
          <Grid item={true} xs={12} className="contentContainer">
            <HomeComponent {...props} handleDrawerToggle={handleDrawerToggle} />
          </Grid>
        </StyledGridMainContainer>
        </AppLayout>
      </StyledContainer>
  );
}
