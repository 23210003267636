import * as Yup from "yup";

export default Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z 0-9-@.,'&/#-]*$/, "Invalid input")
    .min(3, "Minimum 3 characters required")
    .required("Name is required"),
  email: Yup.string()
    .email("Email must be a valid email")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
  mobileNumber: Yup.string()
    .min(10, "Minimum 10 characters required")
    .required("Mobile Number is required"),
});