import { Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "../../../../assets/styles/FuelBooking";

const TotalPrice = (props) => {
  const { subtotal, navigation } = props;
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.rateContainer}>
      <div
        className={
          navigation === "confirmationScreen"
            ? classes.headingCont
            : `${classes.headingCont} ${classes.headingDetailCont}`
        }
      >
        Subtotal
      </div>
      <div
        className={
          navigation === "confirmationScreen"
            ? classes.priceValueStyle
            : `${classes.priceValueStyle} ${classes.priceDetailValueStyle}`
        }
      >
        {subtotal === "$NaN" ? "$0.00": subtotal}
      </div>
      <div
        className={
          navigation === "confirmationScreen"
            ? classes.staticTextStyle
            : `${classes.staticTextStyle} ${classes.staticDetailTextStyle}`
        }
      >
        (all taxes not included, total subject to change)
      </div>
    </Grid>
  );
};

export default TotalPrice;
