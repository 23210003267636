import React from "react";
import { Grid, Link } from "@material-ui/core";

import { useStyles } from "../../../../assets/styles/ProfileSummaryStyles";
import { useSelector } from "react-redux";
import ContactDetailView from "./ContactDetailView";
import { useHistory } from "react-router-dom";

const ContactDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { signup } = useSelector((state) => ({
    signup: state.signup,
  }));

  return (
    <Grid container item xs={12} className={classes.secondCont}>
      <Grid item xs={12} className={classes.profileTextHeading}>
        <Grid container>
          <Grid item xs={11} className={classes.headingStyle}>
            Users
          </Grid>
          <Grid item xs={1} className={classes.editButton}>
            <Link
              href="#"
              id="edit"
              variant="body2"
              color={"inherit"}
              onClick={() => {signup.registerAs === 2 ? history.push("/signup/users") : history.push("/signup/profileSetup")}}
            >
              Edit
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.profileDetail}>
        <ContactDetailView
          detailList={signup.contactList}
          navigation="userProfileSummary"
        />
      </Grid>
    </Grid>
  );
};

export default ContactDetails;
