import React, { useState, useEffect } from "react";
import { Grid, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import Heading from "../Heading";
import HeadingText from "../HeadingText";
import { useStyles } from "../../../../assets/styles/ContactStyles";
import BottomButton from "../BottomButton";
import AddAircraftDetails from "./AddAircraftDetails";
import addUserContacts from "../../../../assets/images/addUserContacts.png";
import AircraftListData from "./AircraftListData";
import {
  getAircraftMakerList,
  getAirplaneList,
} from "../../../../redux/account/account.actions";
import {
  aircraftsList,
  timeline,
} from "../../../../redux/signup/signup.actions";
import { useHistory } from "react-router-dom";
import * as services from "../../../../services/mainService";
import { toast } from "react-toastify";
import DeleteModal from "../DeleteModal";
import colors from "../../../../constants/colors";

let aircraftArray = [];

const AddAircraft = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { signup, auth, account } = useSelector((state) => ({
    signup: state.signup,
    auth: state.auth,
    account: state.account
  }));
  const [editListItem, setEditListItem] = useState([]);
  const [aircraftList, setAircraftList] = useState(
    signup.aircraftList.length > 0 ? signup.aircraftList : []
  );
  const [addAircraft, setAddAircraft] = useState(false);
  const [, setIsAircraftAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});

  useEffect(() => {
    signup.aircraftList.length > 0 && setEditListItem([]);
    setAircraftList(signup.aircraftList);
  }, []);

  useEffect(() => {
    dispatch(getAirplaneList());
    dispatch(getAircraftMakerList(""));
    signup.aircraftList.length > 0 && setAircraftList(signup.aircraftList);
  }, []);

  const addNewAircraft = (newAircraft) => {
    setAddAircraft(false);
    if (editListItem.length > 0) {
      let list = [...aircraftList];
      let index1 = list.findIndex(
        (it) => it.registryName === editListItem[0].registryName
      );
      list[index1] = newAircraft;
      aircraftArray = list;
    } else {
      aircraftArray = signup.aircraftList;
      aircraftArray.push(newAircraft);
    }
    setAircraftList(aircraftArray);
    dispatch(aircraftsList(aircraftArray));
    setIsAircraftAdded(true);
    setEditListItem([]);
  };

  const addMoreAircrafts = () => {
    setEditListItem([]);
    setAddAircraft(true);
  };

  const editItem = (item, index) => {
    let list = {};
    list = aircraftList.filter((e) => {
      return e.registryName === item.registryName;
    });
    if (addAircraft === true) {
      setAddAircraft(false);
    }
    setEditListItem(list);
  };

  const deleteItem = (item) => {
    setDeletedItem(item);
    setOpenDeletePopup(true);
  };

  const handleDelete = () => {
    let list = [];
    list = aircraftList.filter((e) => {
      return e.registryName !== deletedItem.registryName;
    });
    if (list.length > 0) {
      let data = list;
      setAircraftList(data);
      dispatch(aircraftsList(data));
    } else if (list.length === 0) {
      setAircraftList([]);
      setAddAircraft(false);
      setIsAircraftAdded(false);
      dispatch(aircraftsList([]));
    }
    setOpenDeletePopup(false);
  };

  const onClickNextButton = async () => {
    setIsLoading(true);
    let newArray = JSON.parse(JSON.stringify(aircraftList));
    newArray = newArray.map(function (item) {
      delete item.manufacturerName;
      delete item.modelName;
      delete item.customAircraftObj;
      return item;
    });
    const detail = {
      stepNumber: 3,
      aircrafts: newArray,
      isInviteePilot: auth.status === 1 ? true : false
    };
    const res = await services.signUpUrl(detail);
    setIsLoading(false);
    if (res.response_type === "success") {
      dispatch(
        timeline({
          ...signup.timelineSteps,
          aircraftDetails: {
            ...signup.timelineSteps.aircraftDetails,
            selected: true,
          },
          verifyDetails: {
            ...signup.timelineSteps.verifyDetails,
            isActive: true,
          },
        })
      );
      history.push("/signup/verifyDetails");
    } else {
      toast.error(res.response.message, {
        position: "bottom-center",
        closeButton: false,
        style: {
          color: "white",
          backgroundColor: account.isParagonUser || auth.isIframe ? colors.paragonPrimaryColor : colors.primaryColor
        }
      });
    }
  };

  const cancelUser = (value) => {
    setEditListItem([]);
    setAddAircraft(false);
  };

  const handleCloseModal = () => {
    setOpenDeletePopup(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container item xs={12} className={classes.firstCont}>
          <Grid item xs={12}>
            <Heading heading="Aircraft" />
            <HeadingText
              navigation="aircrafts"
              text="Create at least one aircraft profile to finish the account setup. You can add all of your aircraft now or complete this at a later time under the Manage Aircraft tab. (Fees are auto-calculated based on aircraft type.)"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.secondCont}>
        <Grid container>
          <Grid item xs={11} style={{ height: "calc(100vh - 236px - 50px)" }}>
            {signup.aircraftList.length === 0 && account.assignedAircraftList.length === 0 ? (
              <AddAircraftDetails
                addNewAircraft={addNewAircraft}
                cancelUser={cancelUser}
                title="Aircraft"
              />
            ) : (
              <AircraftListData
                addNewAircraft={addNewAircraft}
                aircraftList={aircraftList}
                addAircraft={addAircraft}
                editListItem={editListItem}
                editItem={editItem}
                cancelUser={cancelUser}
                deleteItem={deleteItem}
              />
            )}
          </Grid>
          {(aircraftList.length > 0 || account.assignedAircraftList.length > 0) ? (
            <Grid item xs={1} className={classes.imageCont}>
              <img alt=""
                src={addUserContacts}
                onClick={addAircraft === true ||
                  editListItem.length > 0 ||
                  Object.values(editListItem).length > 0 ? null : addMoreAircrafts}
                className={
                  addAircraft === true ||
                    editListItem.length > 0 ||
                    Object.values(editListItem).length > 0
                    ? classes.imageDisableStyle
                    : classes.imageStyle
                }
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container item xs={12} className={classes.thirdCont}>
          <BottomButton
            disabled={
              (aircraftList.length ===0 && account.assignedAircraftList.length === 0) || editListItem.length > 0 || isLoading
            }
            isSubmitting={isLoading}
            onNextClick={onClickNextButton}
            onBackClick={() => {
              signup.registerAs === 2
                ? history.push("/signup/users")
                : history.push("/signup/profileSetup");
            }}
          />
        </Grid>

        <Modal
          open={openDeletePopup}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DeleteModal
            openDeletePopup={openDeletePopup}
            handleCloseModal={handleCloseModal}
            handleDelete={handleDelete}
          />
        </Modal>
      </Grid>
    </Grid>
  );
};

export default AddAircraft;
