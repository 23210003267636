import React from "react";
import { Grid, Link, Button, makeStyles } from "@material-ui/core";

import sessionWarning from "../../../assets/images/sessionWarning.png";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  dialogCont: {
    width: 496,
    height: 298,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
    padding: "20px 30px",
    borderRadius: 10,
    boxShadow:
      "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  iconStyle: {
    width: 71.4,
    height: 70,
    flexGrow: 0,
    objectFit: "contain",
  },
  messageTextStyle: {
    height: 40,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 24,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.67,
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#333",
  },
  mailTextStyle: {
    height: 48,
    alignSelf: "stretch",
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#999",
  },
  buttonContainer: {
    width: 160,
    height: 40,
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    padding: "12px 20px",
    borderRadius: 5,
    cursor: "pointer",
    backgroundImage: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
  },
  buttonTextStyle: {
    pointerEvents: "none",
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.14,
    letterSpacing: 1,
    textAlign: "left",
    color: "#fff",
  },
  paragonButtonCont: {
    backgroundImage: "linear-gradient(to bottom, #9f215f 0%, #7a003c 100%)",
  }
}));

const ErrorPopUp = (props) => {
  const { handleCloseModal = {}, errorMessage } = props;
  const classes = useStyles();
  const { account, auth } = useSelector((state) => ({
    account: state.account,
    auth: state.auth
  }));

  const handleClick = () => {
    let mailId = "";
    if (account?.isParagonUser === true) {
      mailId = "preferred@paragonaviationgroup.com";
    } else {
      mailId = "support@nozlapp.com";
    }
    window.open(`mailto:${mailId}`);
  };

  return (
    <Grid className={classes.dialogCont}>
      <img src={sessionWarning} alt="warning" className={classes.iconStyle} />
      <Grid className={classes.messageTextStyle}>{errorMessage}</Grid>
      <Grid className={classes.mailTextStyle}>
        If you have any queries, please contact us at &nbsp;
        <Link style={{ cursor: "pointer" }} onClick={handleClick}>
          {account?.isParagonUser === true
            ? "preferred@paragonaviationgroup.com"
            : "tailwinds@nozlapp.com"}
        </Link>
        .
      </Grid>

      <Grid
        item
        xs={12}
        className={(account.isParagonUser !== undefined &&
          account.isParagonUser === true) || auth.isIframe ? 
          `${classes.buttonContainer} ${classes.paragonButtonCont}` : classes.buttonContainer}
        onClick={handleCloseModal}
      >
        <Button className={classes.buttonTextStyle}>OK</Button>
      </Grid>
    </Grid>
  );
};

export default ErrorPopUp;
