import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import nozlSignUpLogo from "../../../assets/images/nozlSignUpLogo.png";
import paragonMainLogoDark from "../../../assets/images/paragonMainLogoDark.png";
import ParagonNozlLogo from "../../../assets/images/paragonNozlLogo.png";
import statusCheck from "../../../assets/images/statusCheck.png";
import paragonDarkStatusCheck from "../../../assets/images/paragonDarkStatusCheck.png";
import { useStyles } from "../../../assets/styles/SignUpSideBarStepsStyles";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { timeline } from "../../../redux/signup/signup.actions";

let stepsList = [];

const SideBarSteps = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { signup, account, auth } = useSelector((state) => ({
    signup: state.signup,
    account: state.account,
    auth: state.auth
  }));
  const [, setTemp] = useState(null);

  useEffect(() => {
    stepsList = signup.timelineSteps;
    setTemp(stepsList);
  }, [stepsList, signup]);

  const handleClick = (item) => {
    if (stepsList[item].name === "Profile" && signup.registerAs !== "") {
      history.push("/signup/profileSetup");
    } else if (
      stepsList[item].name === "Users" &&
      (signup.basicDetails.length > 0 ||
        Object.values(signup.basicDetails).length > 0)
    ) {
      history.push("/signup/users");
    } else if (
      stepsList[item].name === "Aircraft" &&
      (signup.registerAs === 2
        ? signup.contactList.length > 0
        : signup.basicDetails.length > 0 ||
        Object.values(signup.basicDetails).length > 0)
    ) {
      signup.registerAs === 2 ?
        dispatch(
          timeline({
            ...signup.timelineSteps,
            userDetails: {
              ...signup.timelineSteps.userDetails,
              selected: true,
            },
            aircraftDetails: {
              ...signup.timelineSteps.aircraftDetails,
              isActive: true
            }
          })
        ) : 
        dispatch(
          timeline({
            ...signup.timelineSteps,
            aircraftDetails: {
              ...signup.timelineSteps.aircraftDetails,
              isActive: true
            }
          }));
      history.push("/signup/aircrafts");
    } else if (
      stepsList[item].name === "Verify Details" &&
      signup.aircraftList.length > 0
    ) {
      dispatch(
        timeline({
          ...signup.timelineSteps,
          aircraftDetails: {
            ...signup.timelineSteps.aircraftDetails,
            selected: true
          },
          verifyDetails: {
            ...signup.timelineSteps.verifyDetails,
            isActive: true
          }
        })
      );
      history.push("/signup/verifyDetails");
    }
  };

  return (
    <Grid container item xs={12} className={classes.mainCont}>
      <Grid item xs={12} className={classes.innerCont}>
        <img alt=""
          src={
            (account.isParagonUser !== undefined &&
              account.isParagonUser === true) || auth.isIframe
              ? auth.isIframe ? paragonMainLogoDark : ParagonNozlLogo
              : nozlSignUpLogo
          }
          className={
            (account.isParagonUser !== undefined &&
              account.isParagonUser === true) || auth.isIframe
              ? auth.isIframe ? classes.paragonIframeImageStyle : classes.paragonImageStyle
              : classes.imageStyle
          }
        />
        <Grid className={classes.innerContainer}>
          {Object.keys(stepsList).map((item, index) => {
            return (
              <Grid key={index}>
                <Grid
                  className={classes.countCont}
                  onClick={() => handleClick(item)}
                >
                  <Grid
                    className={
                      stepsList[item].selected === true
                        ? auth.isIframe ? classes.iframeCountFinishedCont : classes.countFinishedCont
                        : stepsList[item].isActive === true
                          ? auth.isIframe ? classes.iframeActiveCountInnerCont : classes.activeCountInnerCont
                          : auth.isIframe ? classes.iframeUnselectedCountInnerCont : classes.unselectedCountInnerCont
                    }
                  >
                    {stepsList[item].selected === false ? (
                      <Grid
                        className={
                          stepsList[item].isActive === true && auth.isIframe
                            ? classes.iframeNumberActiveStyle
                            : classes.iframeNumberInactiveStyle
                        }
                      >{stepsList[item].stepIndex} </Grid>
                    ) : (
                      <img alt=""
                        className={classes.checkImageStyle}
                        src={auth.isIframe ? paragonDarkStatusCheck : statusCheck}
                      />
                    )}
                  </Grid>
                  <Grid
                    className={`${classes.nameStyle} ${stepsList[item].selected === true
                      ? auth.isIframe ? classes.iframeSelectedNameStyle : classes.selectedNameStyle
                      : stepsList[item].isActive === true
                        ? auth.isIframe ? classes.iframeActiveNameStyle : classes.activeNameStyle
                        : auth.isIframe ? classes.iframeUnselectedNameStyle : classes.unselectedNameStyle
                      }`}
                  >
                    {stepsList[item].name}
                  </Grid>
                </Grid>
                {signup.registerAs === 4 ? (
                  stepsList[item].stepIndex <= 2 ? (
                    <Grid
                      className={
                        stepsList[item].selected === false
                          ? auth.isIframe ? classes.iframeLineStyle : classes.lineStyle
                          : auth.isIframe ? classes.iframeLineSelectedStyle : classes.lineSelectedStyle
                      }
                    />
                  ) : null
                ) : stepsList[item].stepIndex <= 3 ? (
                  <Grid
                    className={
                      stepsList[item].selected === false
                        ? auth.isIframe ? classes.iframeLineStyle : classes.lineStyle
                        : auth.isIframe ? classes.iframeLineSelectedStyle : classes.lineSelectedStyle
                    }
                  />
                ) : null}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SideBarSteps;
