import React from "react";
import { Grid, Tooltip } from "@material-ui/core";

import editUser from "../../../../assets/images/editUser.png";
import deleteUsers from "../../../../assets/images/deleteUsers.png";
import { useStyles } from "../../../../assets/styles/DetailContactStyles";
import { prettyString } from "../../../ui/TextTransform";

const DetailContacts = (props) => {
  const { item, navigation, index } = props;
  const classes = useStyles();

  const editDetail = () => {
    props.editItem(item, index);
  };

  const deleteDetail = () => {
    props.deleteItem(item, index);
  };

  return (
    <Grid container style={{ direction: "ltr", height: 260 }}>
      <Grid item xs={12} className={classes.mainCont}>
        <Grid container>
          <Grid item xs={8} className={classes.firstDetailCont}>
            <Grid container className={classes.nameTextCont}>
              <Tooltip
                title={
                  navigation === "aircraft"
                    ? item.operatoraircraftsDetail === undefined
                      ? item.registryName.toUpperCase()
                      : item.operatoraircraftsDetail.registryName.toUpperCase()
                    : `${item.first_name} ${item.last_name}`
                }
              >
                <div className={classes.dotStyle}>
                  {navigation === "aircraft"
                    ? item.operatoraircraftsDetail === undefined
                      ? item.registryName.toUpperCase()
                      : item.operatoraircraftsDetail.registryName.toUpperCase()
                    : `${item.first_name} ${item.last_name}`}
                </div>
              </Tooltip>
            </Grid>
            <Grid container className={classes.emailTextStyle}>
              <Tooltip
                title={
                  navigation === "aircraft"
                    ? item.operatoraircraftsDetail === undefined
                      ? item.modelName !== "Other" && item.modelName !== ""
                        ? item.modelName
                        : prettyString(item.name)
                      : prettyString(
                          item.operatoraircraftsDetail.airplaneDetail.name
                        )
                    : item.email
                }
              >
                <div className={classes.dotStyle}>
                  {navigation === "aircraft"
                    ? item.operatoraircraftsDetail === undefined
                      ? item.modelName !== "Other" && item.modelName !== ""
                        ? item.modelName
                        : prettyString(item.name)
                      : prettyString(
                          item.operatoraircraftsDetail.airplaneDetail.name
                        )
                    : item.email}
                </div>
              </Tooltip>
            </Grid>
            <Grid
              container
              className={classes.emailTextStyle}
              style={{ marginTop: 5 }}
            >
              {navigation === "aircraft"
                ? item.operatoraircraftsDetail === undefined
                  ? item.baseLocation
                  : item.operatoraircraftsDetail.baseLocation
                : `+ ${item.phone}`}
            </Grid>
          </Grid>
          {navigation !== "aircraft" ? (
            <Grid item xs={4} className={classes.textStyle2}>
              <Grid>{item.contactTitle}</Grid>
            </Grid>
          ) : (
            <Grid item xs={4} className={classes.textStyle2}>
              <Grid>
                {item.isOtherMaker === true || item.isCustomAircraft === true
                  ? "Custom"
                  : ""}
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          className={classes.secondContainer}
          style={{ marginTop: 40 }}
        >
          <Grid container item xs={9}>
            <Tooltip
              title={
                navigation === "aircraft"
                  ? item.operatoraircraftsDetail === undefined
                    ? item.manufacturerName !== "Other" &&
                      item.manufacturerName !== ""
                      ? item.manufacturerName
                      : item.otherMakerName
                    : item.operatorDetail.name
                  : item.title
              }
            >
              <div className={classes.dotStyle}>
                {navigation === "aircraft"
                  ? item.operatoraircraftsDetail === undefined
                    ? item.manufacturerName !== "Other" &&
                      item.manufacturerName !== ""
                      ? item.manufacturerName
                      : item.otherMakerName
                    : item.operatorDetail.name
                  : item.title}
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={3} className={classes.buttonCont}>
            {item.operatoraircraftsDetail === undefined ||
            item.hasOwnProperty("email") ? (
              <>
                <img
                  src={editUser}
                  onClick={editDetail}
                  className={classes.imageStyle}
                  alt="edit-user"
                />
                {(navigation === "contacts" &&
                  item.contactTitle !== "Primary") ||
                (navigation === "aircraft" &&
                  props.aircraftList.length !== 1) ? (
                  <img
                    src={deleteUsers}
                    onClick={deleteDetail}
                    className={classes.imageStyle}
                    alt="delete-user"
                    style={{ marginLeft: 20 }}
                  />
                ) : null}
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailContacts;
