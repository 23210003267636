import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const LabelTextField = withStyles({
    root: {
      width: "100%",
      color: "white",
      "& label": {        
        transform: "translate(14px, 13px) scale(1)",
          "&.Mui-focused": {
            color: "black"
          },
          "&.Mui-error": {
            color: "#999"
          },
       },
    },
  })(TextField);

export default LabelTextField