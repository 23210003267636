import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

import TextField from "../../../controls/Input";
import { Grid } from "@material-ui/core";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#df2935",
    },
    "& .MuiTextField-root > label": {
      backgroundColor: "#df2935",
      color: "#df2935",
    },

    "& .MuiTextField-root > .MuiFormLabel-root.Mui-focused": {
      color: "#df2935",
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  notchedOutline1: {
    color: "#000",
    fontFamily: "Arial",
    hintColor: "#8F8A89",
    background: "transparent",
    border: "2px solid #8F8A89 !important",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04))",
    borderRadius: 5,
  },
  textStyle: {
    marginTop: 10,
    marginBottom: 10,
    color: "#555",
    fontSize: 14,
    fontFamily: "Arial",
  },
  edit: {
    color: "#000",
    fontFamily: "Arial",
    hintColor: "#8F8A89",
    border: "2px solid #8F8A89 !important",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 4px 8px rgba(44, 39, 56, 0.04))",
    borderRadius: 5,
  },
  label: {
    color: "#555",
    marginRight: 10,
  },
  helperText:{
    width:'100%',
    textAlign:'right'
  }
}));

const NudgePrice = (props) => {
  const classes = useStyles();
  const [price, setPrice] = useState("");
  const [gallons, setGallons] = useState("");
  const [nudgeMessage, setNudgeMessage] = useState("");

  const handleChange = (e) => {
    setNudgeMessage(e.target.value);
    props.nudgeMsg(e.target.value);
  };

  const onPriceHandle = (e) => {
    if (
      e.target.validity.valid ||
      e.target.value === "" ||
      e.target.value === 0
    ) {
      setPrice(e.target.value);
      props.onPriceChange(e.target.value);
    }
  };

  const onGallonsChange = (e) => {
    if (
      e.target.validity.valid ||
      e.target.value === "" ||
      e.target.value === 0
    ) {
      setGallons(e.target.value);
      props.totalGallons(e.target.value);
    }
  };

  return (
    <Grid container xs={12}>
      {props.isPriceType === true && (
        <CssTextField
          id="filled-required"
          label="Price Match*"
          placeholder="Price Match*"
          defaultValue=""
          value={price}
          variant="outlined"
          onChange={onPriceHandle}
          type="number"
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          inputProps={{
            type: "number",
            min: "1",
            step: "0.01",
            pattern: "[0-9]*",
          }}
          className={classes.rightSide}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                style={{
                  height: 56,
                }}
              >
                <div style={{ color: "#555" }}> /gal</div>
              </InputAdornment>
            ),
            style: { color: "#000" },
            classes: {
              notchedOutline: classes.notchedOutline1,
            },
          }}
        />
      )}
      <Grid container item xs={12} style={{ marginTop: 15 }}>
        <CssTextField
          id="filled-required"
          label="Gallons Requested*"
          placeholder="Gallons Requested*"
          value={gallons}
          variant="outlined"
          onChange={onGallonsChange}
          type="number"
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          inputProps={{
            min: "1",
            pattern: "[0-9]*",
          }}
          className={classes.rightSide}
          InputProps={{
            style: { color: "#000" },
            classes: {
              notchedOutline: classes.notchedOutline1,
            },
          }}
        />
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
        <CssTextField
          id="filled-required"
          label="Comments*"
          placeholder="Payment type? Any other services? Etc.."
          value={nudgeMessage}
          helperText={<div className={classes.helperText}>{`${nudgeMessage.length}/500`}</div>}
          variant="outlined"
          multiline
          rowsMax={4}
          onChange={(e) => handleChange(e)}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          inputProps={{
            maxLength: 500,
          }}
          className={classes.rightSide}
          InputProps={{
            style: { color: "#000" },
            classes: {
              notchedOutline: classes.notchedOutline1,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default NudgePrice;
