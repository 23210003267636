import React, { useEffect, useState } from "react";
import { useStyles } from "../../../../../../assets/styles/AssigneAircraftStyles";
import FlatList from "flatlist-react";
import { isMobile } from "react-device-detect";
import colors from "../../../../../../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { useForceUpdate } from "../../../../../../customHooks/useForceUpdate";
import { assignAirCraftToPilot } from "../../../../../../redux/pilots/pilots.actions";

const AssigneAircraftPage = (props) => {
  const classes = useStyles(colors, isMobile);
  const dispatch = useDispatch();

  const { setOpenPopup, assignedAircraftList, userId } = props;

  const { account, auth } = useSelector((state) => ({
    account: state.account,
    auth: state.auth,
  }));

  const forceUpdate = useForceUpdate();

  const submitAssignAircraftToPilot = () => {
    const filterData = data.filter(function (item) {
      return item.isSelect === true;
    });

    const aircraftId = [];
    filterData.map((item) => {
      aircraftId.push(item._id);
    });
    dispatch(assignAirCraftToPilot(auth.token, userId, aircraftId));
    setOpenPopup(false, data);
  };

  const aircartClick = (index) => {
    const newData = data;
    newData[index].isSelect = !newData[index].isSelect;
    setData(newData);
    forceUpdate();
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    const alreadydata = assignedAircraftList || [];
    const tempData = account?.aircraftList;
    tempData !== undefined &&
      tempData.map(async (item) => {
        const filterData = alreadydata.filter(function (itemin) {
          return itemin.aircraftId === item._id;
        });
        if (filterData === "undefined" || filterData.length === 0) {
          item.isSelect = false;
        } else {
          item.isSelect = true;
        }
        return item;
      });
    setData(tempData);
  }, [account?.aircraftList, assignedAircraftList]);

  return (
    <div className={classes.mid}>
      <div style={{ maxHeight: 500, overflowY: "auto" }}>
        <div className={classes.titleCon}>
          <div className={classes.titleText}>Aircraft List</div>
          <Button
            onClick={submitAssignAircraftToPilot}
            variant="contained"
            className={classes.submitBtn}
          >
            Done
          </Button>
        </div>
        <div style={{ height: 20 }} />
        <FlatList
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          list={data}
          renderItem={(item, index) => {
            return (
              <div
                key={index}
                className={classes.items}
                onClick={() => aircartClick(index)}
              >
                {item.isSelect ? (
                  <div className={classes.checkMarkFill}></div>
                ) : (
                  <div className={classes.checkMark}></div>
                )}
                <div>
                  <div className={classes.pilotName}>
                    {item?.airplaneDetail?.name}
                  </div>
                  <div className={classes.pilotName1}>{item?.registryName.toUpperCase()}</div>
                </div>
              </div>
            );
          }}
          renderWhenEmpty={() => <div>List is empty!</div>}
        />
      </div>
      <br />
    </div>
  );
};

export default AssigneAircraftPage;
