import { put, call, takeLatest } from "redux-saga/effects";
// import { Alert } from 'react-native'

import * as actionTypes from "./notifications.action-types";
import * as mainService from "../../services/mainService";

import * as authActionTypes from "../../redux/auth/auth.actionTypes";
import { showLoader, removeLoader } from "../loader/loader.actions";
import { toast } from "react-toastify";


function* getNotifications(action) {
  yield put(showLoader());
  const apiResponse = yield call(mainService.getNotifications, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_NOTIFICATIONS.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_NOTIFICATIONS.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
  yield put(removeLoader());
}

function* getNotificationsSuccess(action) {}

function* getNotificationsFailure(action) {
  if (
    action.payload.message.includes("User not found") ||
    action.payload.message === "Invalid Token"
  ) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    toast.info("Session expired. Please login.", {
      position: "bottom-center",
      closeButton: false,
    });
  }
}

function* getUnreadNotificationCount(action) {
  const apiResponse = yield call(mainService.getUnreadNotificationCount, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_UNREAD_NOTIFICATION_COUNT.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_UNREAD_NOTIFICATION_COUNT.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* getUnreadNotificationCountSuccess(action) {}

function* getUnreadNotificationCountFailure(action) {
  if (
    action.payload.message.includes("User not found") ||
    action.payload.message === "Invalid Token"
  ) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    toast.info("Session expired. Please login.", {
      position: "bottom-center",
      closeButton: false,
    });
  }
}

function* getNotificationDetail(action) {
  const apiResponse = yield call(
    mainService.getNotificationDetail,
    action.payload
  );
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_NOTIFICATION_DETAIL.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_NOTIFICATION_DETAIL.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* getNotificationDetailSuccess(action) {}

function* getNotificationDetailFailure(action) {
  if (
    action.payload.message.includes("User not found") ||
    action.payload.message === "Invalid Token"
  ) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
}

function* markNotificationRead(action) {
  const apiResponse = yield call(
    mainService.markNotificationRead,
    action.payload
  );
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.MARK_NOTIFICATION_READ.SUCCESS,
        payload: apiResponse.response,
      });
    } else {
      yield put({
        type: actionTypes.MARK_NOTIFICATION_READ.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* markNotificationReadSuccess(action) {}

export function* notificationsSaga() {
  yield takeLatest(actionTypes.GET_NOTIFICATIONS.REQUEST, getNotifications);
  yield takeLatest(
    actionTypes.GET_NOTIFICATIONS.SUCCESS,
    getNotificationsSuccess
  );

  yield takeLatest(
    actionTypes.GET_NOTIFICATIONS.FAILURE,
    getNotificationsFailure
  );
  yield takeLatest(actionTypes.GET_UNREAD_NOTIFICATION_COUNT.REQUEST, getUnreadNotificationCount);
  yield takeLatest(actionTypes.GET_UNREAD_NOTIFICATION_COUNT.SUCCESS, getUnreadNotificationCountSuccess);

  yield takeLatest(
    actionTypes.GET_UNREAD_NOTIFICATION_COUNT.FAILURE,
    getUnreadNotificationCountFailure
  );

  yield takeLatest(
    actionTypes.GET_NOTIFICATION_DETAIL.REQUEST,
    getNotificationDetail
  );
  yield takeLatest(
    actionTypes.GET_NOTIFICATION_DETAIL.SUCCESS,
    getNotificationDetailSuccess
  );
  yield takeLatest(
    actionTypes.GET_NOTIFICATION_DETAIL.FAILURE,
    getNotificationDetailFailure
  );

  yield takeLatest(
    actionTypes.MARK_NOTIFICATION_READ.REQUEST,
    markNotificationRead
  );
  yield takeLatest(
    actionTypes.MARK_NOTIFICATION_READ.SUCCESS,
    markNotificationReadSuccess
  );
}
