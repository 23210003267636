import React, { useEffect, useState } from "react";
import { makeStyles, Grid, IconButton, Tooltip } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import TextInput from "./SignupTextInput";
import LabelTextField from "../../../assets/styles/SignUpLabelStyles";
import { useSelector } from "react-redux";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles(() => ({
  secondCont: {
    marginTop: 36,
  },
  notchedOutline: {
    padding: 5,
    borderColor: "#ddd !important",
    borderWidth: "2px",
    boxSizing: "border box",
    borderRadius: 5,
    color: "#666",
    hintColor: "#666",
  },
  basicDetailText: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    height: 20,
    fontSize: 16,
    color: "#333333",
  },
  inputFieldStyle: {
    marginTop: 28,
  },
  firstNameStyle: {
    width: 332,
    height: 40,
  },
  iconStyle: {
    color: "#212121",
    width: 18,
    height: 18,
  },
  customWidth: {
    maxWidth: 500,
    height: 50,
    backgroundColor: "#555",
  },
  setNewPasswordTextStyle: {
    fontSize: 12,
    color: "orange",
    height: 20,
    width: "100%",
    marginLeft: 15,
    display: "flex",
    alignItems: "center",
  },
}));

const LoginDetails = (props) => {
  const classes = useStyles();
  const { onChange, onBlur, errors, touched } = props;
  const { signup, auth, account } = useSelector((state) => ({
    signup: state.signup,
    auth: state.auth,
    account: state.account,
  }));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (window.location.search !== '') {
      let pilotDetail = window.location.search;
      let pilot = pilotDetail.split("&email=");
      let pilotEmail = pilot[1].split("&userType");
      setEmail(decodeURIComponent(pilotEmail[0]));
    }
    if (Object.values(signup.basicDetails).length > 0) {
      setEmail(signup.basicDetails.email);
      setPassword(signup.basicDetails.password);
      setConfirmPassword(signup.basicDetails.confirmPassword);
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid item xs={12} className={classes.secondCont}>
      <Grid container item xs={12} className={classes.basicDetailText}>
        Login Details
        {!account.isParagonUser || auth.status === 1 ? (
          <div>
            <Tooltip
              title={
                <div>
                  <div style={{ fontSize: 12, padding: 1, marginTop: 5 }}>
                    1. Your email address will be used as your username.
                  </div>
                  <div style={{ fontSize: 12, padding: 1 }}>
                    2. The password entered must contain a minimum of 7
                    characters.
                  </div>
                </div>
              }
              placement="right-start"
              classes={{ tooltip: classes.customWidth }}
            >
              <Info
                style={{
                  marginLeft: 5,
                  width: 18,
                  height: 18,
                  color: "#666",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        ) : null}
      </Grid>
      <Grid
        container
        item
        xs={8}
        spacing={3}
        className={classes.inputFieldStyle}
      >
        <Grid container item xs={12}>
          <TextInput
            id="email"
            name="email"
            disabled={auth.status === 1 ? true : false}
            style={{
              opacity: auth.status === 1 ? 0.4 : 1,
            }}
            label="Email Address*"
            fullWidth
            onChange={(e) => {
              if (
                e.target.value.match("^(?!\\s)[a-zA-Z 0-9_@.'&/#+-]*$") != null
              ) {
                onChange(e);
                setEmail(e.target.value);
                props.enterEmailValue(e.target.value);
              }
            }}
            onBlur={onBlur}
            value={email}
            error={touched.email && errors.email ? errors.email : ""}
          />
        </Grid>
        {auth.status === 1 ? (
          <div className={classes.setNewPasswordTextStyle}>
            Set new Password
          </div>
        ) : null}
        {!account.isParagonUser || auth.status === 1 ? (
          <>
            <Grid container item xs={6}>
              <LabelTextField
                variant="outlined"
                id="password"
                name="password"
                label="Password*"
                value={password}
                type={showPassword === true ? "text" : "password"}
                fullWidth
                onChange={(e) => {
                  onChange(e);
                  setPassword(e.target.value);
                  props.enterPasswordValue(e.target.value);
                }}
                onBlur={onBlur}
                autoComplete="off"
                inputProps={{
                  style: {
                    height: 5,
                  },
                }}
                InputProps={{
                  style: { color: "#000" },
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.inputLine,
                  },
                  color: "#000",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ width: "20px" }}
                        onClick={handleClickShowPassword}
                      >
                        {showPassword === true ? (
                          <Visibility className={classes.iconStyle} />
                        ) : (
                          <VisibilityOff className={classes.iconStyle} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...(errors.password &&
                  touched.password && {
                    error: true,
                    helperText: errors.password,
                  })}
              />
            </Grid>
            <Grid container item xs={6}>
              <TextInput
                id="confirmPassword"
                name="confirmPassword"
                fullWidth
                type="password"
                onChange={(e) => {
                  onChange(e);
                  setConfirmPassword(e.target.value);
                  props.enterConfirmPasswordValue(e.target.value);
                }}
                onBlur={onBlur}
                value={confirmPassword}
                label="Confirm Password*"
                error={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : ""
                }
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default LoginDetails;