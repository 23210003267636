import { apiRequest } from "./service";
import { FORGOT_PASSWORD, LOGIN, LOGOUT, RESET_PASSWORD, VERIFY_RESET_PASSWORD, TERMS_AND_CONDITIONS } from "./apiUrl";

export const forgotPassword = (payload) => {
  return apiRequest({
    apiUrl: FORGOT_PASSWORD,
    method: "POST",
    payload,
  });
}

export const loginApi = (payload) =>
  apiRequest({
    apiUrl: LOGIN,
    method: "POST",
    payload,
  });

export const logoutApi = (payload) =>
  apiRequest({
    apiUrl: LOGOUT,
    method: "POST",
    token: payload.token,
  });

export const verifyResetPassword = (payload) =>
  apiRequest({
    apiUrl: VERIFY_RESET_PASSWORD,
    method: "POST",
    payload,
  });

export const resetPassword = (payload) =>
  apiRequest({
    apiUrl: RESET_PASSWORD,
    method: "POST",
    payload,
  });

export const termsAndConditionsApi = (payload) => {
  return apiRequest({
    apiUrl: TERMS_AND_CONDITIONS,
    method: "GET",
    token: payload.token
  });
}