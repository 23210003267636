import {
  Avatar,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  AppBar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FlatList from "flatlist-react";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../redux/account/account.actions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Popup from "../../scene/profile-setup/Popup";
import commonStyles from "../../../constants/common";
import fontStyles from "../../../constants/fonts";
import { favouriteFboItem } from "../../../redux/fbos/fbos.actions";

import colors from "../../../constants/colors";
import AddManagerOperator from "./AddManagerOperator";
import managerListIcon from "../../../assets/images/managerListIcon.png";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { prettyString } from "../../ui/TextTransform";
import CustomLoader from "../../ui/CustomLoader";

const useStyles = makeStyles(() => ({
  items: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "#000",
    cursor: "pointer",
  },
  pilotName: {
    fontSize: 18,
    color: "#000",
    fontWeight: "500",
  },
  header: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "space-between",
  },
  button: {
    width: 300,
  },
  button2: {
    width: "400px !important",
    marginLeft: 100,
    marginRight: 100,
  },
  appMain: {},
  submitBtn: {
    background: colors.primaryColor,
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  paragonSubmit: {
    background: colors.paragonPrimaryColor,
    backgroundColor: colors.paragonPrimaryColor,
    "&:hover": {
      backgroundColor: colors.paragonPrimaryColor,
    },
  },
}));

const ManageOperators = (props) => {
  const dispatch = useDispatch();
  const { auth, account } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
  }));
  const classes = useStyles();
  const fontClasses = fontStyles();
  const commonClasses = commonStyles();
  const [sectionListData, setSectionListData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [editObj, setEditObj] = useState({});

  useEffect(() => {
    dispatch(
      getProfile({
        token: auth.token,
      })
    );
  }, [auth.token, dispatch]);

  useEffect(() => {
    dispatch(favouriteFboItem({}));
    if (account?.user?.primaryManagerDetail !== undefined) {
      setSectionListData(account?.user?.primaryManagerDetail);
    }
  }, [account?.user?.primaryManagerDetail]);

  const setOpenAddPopup = async (value) => {
    if (account?.user?.primaryManagerDetail !== undefined) {
      setSectionListData(account?.user?.primaryManagerDetail);
    }
    setOpenPopup(false);
  };

  useEffect(() => {}, [account.user]);

  const onManagerEdit = (index, item) => {
    setEditObj(item);
    setOpenEditPopup(true);
  };

  const editManager = (value) => {
    dispatch(
      getProfile({
        token: auth.token,
      })
    );
    setOpenEditPopup(false);
  };

  const Appbar = () => {
    return (
      <AppBar position="static" className={commonClasses.pageHeader}>
        <Toolbar>
          <Typography className={fontClasses.pageHeading}>
            Manage Managers
          </Typography>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <div style={{ padding: 5 }}>
      {account.status === "loading" ? (
        <CustomLoader />
      ) : (
        <div className={classes.appMain}>
          <Appbar />
          <br />
          <div style={{ paddingBottom: 30 }} className={classes.header}>
            <div style={{ minWidth: 200 }}>
              <Button
                fullWidth
                className={classes.submitBtn}
                onClick={() => {
                  setOpenPopup(true);
                }}
                disabled={sectionListData.length > 0 ? true : false}
                disableElevation
              >
                + Add Primary Manager
              </Button>
            </div>
          </div>
          <div
            style={{
              maxHeight: "calc(100vh - 190px)",
              overflowY: "auto",
            }}
          >
            {sectionListData.length > 0 ? (
              <FlatList
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                list={sectionListData}
                renderItem={(item, index) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      alignItems="flex-start"
                    >
                      <ListItemIcon>
                        <Avatar className={commonStyles.managerIcon}>
                          <img
                            style={{ maxWidth: 40, maxHeight: 40 }}
                            src={managerListIcon}
                            alt="managerIcon"
                          />
                        </Avatar>
                      </ListItemIcon>

                      <ListItemText
                        primary={`${item?.title}`}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              display="block"
                              style={{ textTransform: "capitalize" }}
                            >
                              {item?.first_name !== undefined
                                ? `${prettyString(
                                    item?.first_name
                                  )} ${prettyString(item?.last_name)}`
                                : ""}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              display="block"
                            >
                              {item?.email}
                            </Typography>
                          </React.Fragment>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => onManagerEdit(index, item)}
                        >
                          <EditIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }}
              />
            ) : null}
            {sectionListData.length === 0 ? (
              <h4
                style={{
                  alignSelf: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: 200,
                  color: colors.lightGreyBlue,
                }}
              >
                Please add Primary Manager information to proceed.
              </h4>
            ) : null}
          </div>
          <Popup
            title="Add Primary Manager"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            normalPopUp={true}
          >
            <AddManagerOperator
              setOpenPopup={setOpenAddPopup}
              operatorName={account?.user?.name}
              location={props.location}
            />
          </Popup>

          <Popup
            title="Edit Primary Manager"
            openPopup={openEditPopup}
            setOpenPopup={setOpenEditPopup}
            normalPopUp={true}
          >
            <AddManagerOperator
              setOpenEditPopup={editManager}
              location={props.location}
              editObj={editObj}
              isEdit={true}
            />
          </Popup>
        </div>
      )}
    </div>
  );
};

export default ManageOperators;
