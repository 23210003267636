import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    height: "calc(100vh - 65px)",
    width: "100%",
    borderRight: "1px solid #ddd",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItem: {
    paddingLeft: 0,
    width: "100%",
    listStyleType: "none",
    borderBottom: "1px solid #ddd",
    paddingTop: 15,
    paddingBottom: 15,
    color: "#555",
    fontWeight: 400,
    cursor: "pointer",
  },
  privacyListItem: {
    paddingLeft: 0,
    width: "100%",
    listStyleType: "none",
    paddingTop: 15,
    paddingBottom: 15,
    color: "#000",
    fontWeight: 400,
    cursor: "pointer",
  },
  bb0: {
    borderBottom: 0,
  },
  card: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "[5px 10px]",
    paddingRight: 20,
  },
  activeMenuTxt: {
    color: "#000",
  },
  cursor: {
    cursor: "pointer",
  },
  versionTxt: {
    color: colors.darkGrey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position:"absolute",
    bottom: 10,
  },
}));

export { useStyles };