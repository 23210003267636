import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Grid, Tooltip } from "@material-ui/core";
import Styled from "styled-components";
import { toast } from "react-toastify";

import { favouriteFboItem } from "../../../../redux/fbos/fbos.actions";
import colors from "../../../../constants/colors";
import { useStyles } from "../../../../assets/styles/FboItemStyles";
import NozlRatingBar from "../../../ui/NozlRatingBar";
import * as mainServices from "../../../../services/mainService.js";
import bookmarkOutline from "../../../../assets/images/bookmarkOutline.png";
import bookmarkFill from "../../../../assets/images/bookmarkFill.png";
import NudgePopup from "./NudgePopup";
import InternationalServices from "./InternationalServices";
import FboLogo from "./FboLogo";
import FboButtons from "./FboButtons";
import FboJetASafPrice from "./FboJetASafPrice";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";
import DiscountType from "../../../../config/constants";

export const StyledName = Styled(Grid)`
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
`;

const FboItem = (props) => {
  const { item, isUnlisted } = props;
  const [openModal, setOpenModal] = useState(false);
  const [markIcon, setMarkIcon] = useState(0);
  const classes = useStyles(colors, isMobile);
  const dispatch = useDispatch();
  const fboFuelType = item.isJetAFuel === 0 && item.is100LLFuel === 0 && item.isSAFFuel === 0;

  const { auth, account, fbos } = useSelector((state) => ({
    auth: state.auth,
    fbos: state.fbos,
    account: state.account,
  }));

  const fuelType =
    !!account?.userSelectedAircraft &&
      Object.values(account?.userSelectedAircraft).length > 0
      ? account?.userSelectedAircraft?.airplaneDetail?.fuelType
      : 0;

  const _100LLDiscount =
    item.discountFuelType === DiscountType.discount.hundredLL
      ? item.discountTiers[0]?.retailPrice
      : item.retailPriceLL;

  useEffect(() => {
    setMarkIcon(item.isFavourite === 1 ? true : false);
  }, [item]);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = (value, nudgeCount) => {
    if (nudgeCount === 1) {
      item.nudgeCount = 1;
    }
    setOpenModal(false);
  };

  let offerVal = "";

  if (
    item.discountCount === 1 ||
    item.operatorRegistryDiscountCount === 1 ||
    item.operatorSpecificDiscountCount === 1 ||
    item.tailWiseDiscountCount === 1
  ) {
    offerVal = "Discounted";
  }

  const handleChildClick = (e) => {
    e.stopPropagation();
    handleOpen();
  };

  const handleEditText = (e) => {
    e.stopPropagation();
  };

  const handleParentClick = () => {
    openModal !== true && props.clickFboItem(item, true, !isUnlisted);
  };

  const selectFavouriteFbo = async () => {
    setMarkIcon(!markIcon);
    let payload = {
      token: auth.token,
    };
    if (!!item.userId) {
      payload.fboUserId = item.userId;
      payload.type = 1;
    } else {
      payload.fboUserId = item._id;
      payload.type = 2;
    }
    if (markIcon) {
      let params = {
        fboUserId: payload.fboUserId,
        token: auth.token,
      };
      const res = await mainServices.removeFavouriteFbo(params);
      dispatch(favouriteFboItem({}));
      if (res.response_type === "success") {
        toast(res.response.message, {
          position: "bottom-center",
          closeButton: false,
        });
      } else {
        setMarkIcon(markIcon);
        toast.info(res.response.message, {
          position: "bottom-center",
          closeButton: false,
        });
      }
    } else {
      const res = await mainServices.addFavouriteFbo(payload);
      if (res.response_type === "success") {
        toast(res.response.message, {
          position: "bottom-center",
          closeButton: false,
        });
      } else {
        setMarkIcon(markIcon);
        toast.info(res.response.message, {
          position: "bottom-center",
          closeButton: false,
        });
      }
    }
  };

  const showFboDetail = () => {
    props.showFboDetail(item, "fboItem");
  };

  const renderHunderedLL = () => {
    return (
      <div className={`${classes.headingCon} ${classes.hunderedLLContainer}`}>
        <div className={classes.hundredLLPriceTextStyle}>100 LL</div>
        <div className={classes.innerCont}>
          {item.discountFuelType === DiscountType.discount.hundredLL &&
            parseFloat(item.retailPriceLL).toFixed(2) !== parseFloat(_100LLDiscount).toFixed(2) ? (
            <div className={classes.discountedPriceStyle}>
              {`$${formatNumWithCommas(parseFloat(item.retailPriceLL).toFixed(2))}/gal`}
            </div>
          ) : null}
          <div className={classes.priceTextStyle}>
            {`$${formatNumWithCommas(parseFloat(_100LLDiscount).toFixed(2))}/gal`}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Grid key={item.fboId} className={classes.root}>
      <Grid container item xs={12} className={classes.mainContainer}>
        <Grid container item xs={12} className={classes.innerContainer}>
          <Grid
            onClick={showFboDetail}
            item
            xs={5}
            className={
              item.logo !== undefined && item.logo !== null
                ? classes.imageContainer
                : classes.imageContainer1
            }
          >
            <FboLogo logo={item.logo} navigation="fboItem" />
          </Grid>
          <Grid item xs={7} className={classes.detailCont}>
            <div className={classes.nameCont}>
              <Grid container item xs={10} onClick={showFboDetail}>
                <StyledName item xs={12}>
                  <Tooltip title={item.fboName}>
                    <div className={classes.fboNameText}>{item.fboName}</div>
                  </Tooltip>
                </StyledName>
                <Grid item xs={12} style={{ height: 30 }}>
                  <NozlRatingBar
                    style={classes.rating}
                    rate={item.rating}
                    isUnlisted
                    navigation="fboItem"
                  />
                </Grid>
              </Grid>
              <Grid item xs={2} onClick={selectFavouriteFbo}>
                <img
                  src={markIcon ? bookmarkFill : bookmarkOutline}
                  className={classes.iconCont}
                  alt="favorite fbo"
                />
              </Grid>
            </div>
            <Grid>
              <FboButtons
                offerVal={offerVal}
                item={item}
                account={account}
                showFboDetail={showFboDetail}
                nudgeCountValue={item.nudgeCount}
                handleParentClick={handleParentClick}
                handleChildClick={handleChildClick}
                navigation="fromFboList"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.dividerCont} onClick={showFboDetail}>
        <div className={classes.dividerStyle} />
      </div>
      {fboFuelType ? (
        <div className={classes.textStyle} onClick={showFboDetail}>
          This FBO is not providing fuel services.
        </div>
      ) : null}
      <div className={classes.jetAStyle} onClick={showFboDetail}>
        {isUnlisted ? null : fuelType < DiscountType.fuelType.HUNDRED_LL &&
          item.isJetAFuel === DiscountType.fuelApplied.jetA ? (
          <FboJetASafPrice item={item} navigation="fromFboList" />
        ) : null}
        {isUnlisted ? null : fuelType < DiscountType.fuelType.HUNDRED_LL &&
         item.isSAFFuel === DiscountType.fuelApplied.saf ? (
          <FboJetASafPrice item={item} navigation="forSAF" />
        ) : null}
        {item.is100LLFuel === DiscountType.fuelApplied.hundredLL && (fuelType === DiscountType.fuelType.fuel_type || fuelType === DiscountType.fuelType.HUNDRED_LL || item.discountFuelType === DiscountType.discount.hundredLL) ? (
          renderHunderedLL()
        ) : null}
      </div>
      <div className={classes.servicesCont}>
        <InternationalServices item={item?.internationalFeeTaxes?.length > 0 ? item.internationalFeeTaxes : fbos.fboDefaultServices} />
      </div>
      <Modal
        disableBackdropClick={false}
        open={openModal}
        onBackdropClick={handleClose}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.nudgeModal}
      >
        <NudgePopup
          item={item}
          handleClose={handleClose}
          handleEditText={handleEditText}
        />
      </Modal>
    </Grid>
  );
};

export default FboItem;
