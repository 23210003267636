import {
  Avatar,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FlatList from "flatlist-react";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteManager,
  getProfile,
} from "../../../redux/account/account.actions";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Popup from "./Popup";
import commonStyles from "../../../constants/common";
import fontStyles from "../../../constants/fonts";
import * as services from "../../../services/mainService";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import colors from "../../../constants/colors";
import AddAdditionalManager from "./AddAdditionalManager";
import managerListIcon from "../../../assets/images/managerListIcon.png";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { prettyString } from "../../../components/ui/TextTransform";
import CustomLoader from "../../../components/ui/CustomLoader";

const useStyles = makeStyles(() => ({
  items: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "#000",
    cursor: "pointer",
  },
  pilotName: {
    fontSize: 18,
    color: "#000",
    fontWeight: "500",
  },
  header: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "space-between",
  },
  button: {
    width: 300,
  },
  button2: {
    width: "400px !important",
    marginLeft: 100,
    marginRight: 100,
  },
  appMain: {},
  submitBtn: {
    background: colors.primaryColor,
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  paragonSubmit: {
    background: colors.paragonPrimaryColor,
    backgroundColor: colors.paragonPrimaryColor,
    "&:hover": {
      backgroundColor: colors.paragonPrimaryColor,
    },
  },
}));

let params = {};

const ManageOperators = (props) => {
  const dispatch = useDispatch();
  const { auth, account } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
  }));
  const classes = useStyles();
  const fontClasses = fontStyles();
  const commonClasses = commonStyles();
  const [sectionListData, setSectionListData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (account?.user?.additionalManagerDetail !== undefined) {
      setSectionListData(account?.user?.additionalManagerDetail);
    }
  }, [account?.user?.additionalManagerDetail]);

  const onManagerDelete = (index, item) => {
    params = {
      id: item._id,
      token: auth.token,
    };
    setOpen(true);
  };

  const setOpenAddPopup = async (value) => {
    if (account?.user?.additionalManagerDetail !== undefined) {
      setSectionListData(account?.user?.additionalManagerDetail);
    }
    setOpenPopup(false);
  };

  useEffect(() => { }, [account.user]);

  const openDialog = async () => {
    dispatch(deleteManager(params));
    dispatch(getProfile({ token: auth.token }));
    setOpen(false);
  };

  const onManagerEdit = (index, item) => {
    setEditObj(item);
    setOpenEditPopup(true);
  };

  const editManager = (value) => {
    dispatch(getProfile({ token: auth.token }));
    setOpenEditPopup(false);
  };

  const Appbar = () => {
    return (
      <AppBar position="static" className={commonClasses.pageHeader}>
        <Toolbar>
          <Typography className={fontClasses.pageHeading}>
            Manage Managers
          </Typography>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <div style={{ padding: 5 }}>
      {account.status === 'loading' ?
        <CustomLoader />
        :
        <div className={classes.appMain}>
          <Appbar />
          <br />
          <div style={{ paddingBottom: 10 }} className={classes.header}>
            <div style={{ minWidth: 200 }}>
              <Button
                fullWidth
                className={classes.submitBtn}
                onClick={() => {
                  setOpenPopup(true);
                }}
                disableElevation
              >
                + Add Additional Manager
              </Button>
            </div>
          </div>
          <div
            style={{
              paddingBottom: 30,
              padding: 10,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{ cursor: "pointer", color: colors.primaryColor, paddingBottom: 10 }}
              onClick={async () => {
                await services.submitAdditionalOperatorManagerApi({
                  token: auth.token,
                });
                props.history.push("/aircraftList");
              }}
            >
              Skip
            </div>
          </div>
          <div
            style={{
              maxHeight: "calc(100vh - 190px)",
              overflowY: "auto",
            }}
          >
            {sectionListData.length > 0 ? (
              <FlatList
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                list={sectionListData}
                renderItem={(item, index) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      alignItems="flex-start"
                    >
                      <ListItemIcon>
                        <Avatar className={commonStyles.managerIcon}>
                          <img
                            style={{ maxWidth: 40, maxHeight: 40 }}
                            src={managerListIcon}
                            alt="managerIcon"
                          />
                        </Avatar>
                      </ListItemIcon>

                      <ListItemText
                        primary={`${item?.title}`}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              display="block"
                              style={{ textTransform: "capitalize" }}
                            >{`${prettyString(item?.first_name)} ${prettyString(item?.last_name)}`}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              display="block"
                            >
                              {item?.email}
                            </Typography>
                          </React.Fragment>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => onManagerEdit(index, item)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => onManagerDelete(index, item)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }}
              />
            ) : null}
            {sectionListData.length === 0 ? (
              <h4
                style={{
                  alignSelf: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: 200,
                  color: colors.lightGreyBlue,
                }}
              >
                No additional primary manager added.
              </h4>
            ) : null}
          </div>
          <Popup
            title={
                account?.user?.primaryManagerDetail !== undefined &&
                account?.user?.primaryManagerDetail.length === 0
                ? "Add Primary Manager"
                : "Add Additional Manager"
            }
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            normalPopUp={true}
          >
            <AddAdditionalManager
              setOpenPopup={setOpenAddPopup}
              location={props.location}
              operatorName={account?.user?.name}
            />
          </Popup>
          <Popup
            title={
                account?.user?.primaryManagerDetail !== undefined &&
                account?.user?.primaryManagerDetail.length === 0
                ? "Edit Primary Manager"
                : "Edit Additional Manager"
            }
            openPopup={openEditPopup}
            setOpenPopup={setOpenEditPopup}
            normalPopUp={true}
          >
            <AddAdditionalManager
              setOpenEditPopup={editManager}
              location={props.location}
              editObj={editObj}
              isEdit={true}
            />
          </Popup>
          {/* Dialog for show to confirm before dialog close  */}

          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this contact?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={openDialog} color="secondary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      }
    </div>
  );
};

export default ManageOperators;
