import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidationTextField } from "./AddAircraftForm";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { IFrameSignUpTextField, ValidationSignUpTextField } from "../../../assets/styles/SignUpManufacturerStyles";

const useStyles = makeStyles((theme) => ({
  itemStyle: {
    textTransform: "lowercase",
    "& > :first-letter": {},
  },
  aircraftStyle: {
    color: "black",
    textTransform: "capitalize",
  },
  paper: {
    textTransform: "capitalize",
  },
}));
const CustomAircraft = (props) => {
  const classes = useStyles();
  const { onBlur = {} } = props;
  const { account } = useSelector((state) => ({
    account: state.account,
  }));
  const [makerList, setMakerList] = useState([]);
  const [code, setCode] = useState("");
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = React.useState("");

  const onModalTypeChange = (obj, clearModal) => {
    props.modalType(obj, clearModal);
  };

  useEffect(() => {
    setCode(
      props.manufactureName !== undefined || props.manufactureName !== ""
        ? props.manufactureName
        : ""
    );

    const obj = [{ _id: "others", name: "Other" }];
    if (account?.aircraftMakerList !== undefined) {
      const localArray = [...account?.aircraftMakerList, ...obj];
      setMakerList(localArray);
      if (props.manufactureName !== undefined || props.manufactureName !== "") {
        localArray.map((item) => {
          if (item.name === props.manufactureName) {
            onModalTypeChange(item, false);
          }
        });
      }
    }
  }, [account?.aircraftMakerList, props.manufactureName]);

  useEffect(() => {
    setValue(
      makerList.find(
        (e) => e.name?.toLowerCase() === props.manufactureName?.toLowerCase()
      )
    );
    setInputValue(props.manufactureName?.toLowerCase());
  }, [makerList]);

  return (
    <div style={{ width: "100%" }}>
      <Autocomplete
        inputValue={inputValue}
        value={value}
        size={props.navigation === "signup" || props.navigation === "iframeSignup" ? "small" : "normal"}
        classes={{ input: classes.paper, paper: classes.paper }}
        options={makerList}
        className={classes.itemStyle}
        getOptionLabel={(option) => option?.name || ""}
        renderInput={(params) =>
          props.navigation === "signup" ? (
            <ValidationSignUpTextField
              {...params}
              className={classes.aircraftStyle}
              label="Select Manufacturer*"
              variant="outlined"
              fullWidth
            />
          ) : props.navigation === "iframeSignup" ? (
            <IFrameSignUpTextField
              {...params}
              className={classes.aircraftStyle}
              label="Select Manufacturer*"
              variant="outlined"
              fullWidth
            />
          ) : (
            <ValidationTextField
              {...params}
              label="Select Manufacturer*"
              InputLabelProps={code !== "" ? { shrink: true } : undefined}
              className={classes.aircraftStyle}
              placeholder={"Select Manufacturer*"}
              variant="outlined"
              fullWidth
            />
          )
        }
        onChange={(event, newValue) => {
          setValue(newValue);
          onModalTypeChange(newValue, true);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onBlur={onBlur}
      />
    </div>
  );
};

export default React.memo(CustomAircraft);
