import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  mainCont: {
    width: "100%",
    minHeight: "calc(100vh - 80px)",
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bookingOverflowStyle: {
    overflowY: "auto",
    height: "calc(100vh - 80px)",
  },
  main: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 10,
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 5,
  },
  innerCont: {
    width: "840px",
    minHeight: "361px",
    borderRadius: "10px",
    background: "#fafafa",
    padding: 30,
  },
  topContainer: {
    marginTop: 10,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItem: "center",
    justifyContent: "center",
  },
  mainAmount: {
    alignSelf: "center",
    justifyContent: "center",
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 32,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#333",
    marginTop: 5,
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  formCard: {
    marginTop: 20,
    padding: 10,
    background: "linear-gradient(to right, #484747, #222121)",
    backgroundColor: "#000",
    justifyContent: "center",
    alignSelf: "center",
    alignItem: "center",
    alignContent: "center",
    border: "0px solid black",
    borderRadius: "5px!important",
    width: 374,
  },
  formCard2: {
    marginTop: 20,
    padding: 10,
    background: "linear-gradient(to right, #484747, #222121)",
    backgroundColor: "#000",
    width: "374px",
    justifyContent: "center",
    alignSelf: "center",
    alignItem: "center",
    alignContent: "center",
    border: "0px solid black",
    borderRadius: "5px!important",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    "&$focused$notchedOutline": {
      borderColor: "black",
    },
  },
  rampFeeDiv: {
    alignSelf: "center",
    flexGrow: 0,
    opacity: 0.7,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#333",
  },
  crossAmount: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    opacity: 0.7,
    letterSpacing: "normal",
    color: "#131415",
  },
  fuelRate: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#333",
    marginTop: 10,
  },
  aircraftTail: {
    margin: "2px 0 0",
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#333",
  },
  valueStatus: {
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    padding: 8,
    textTransform: "capitalize",
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    marginTop: 5,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#fff",
    borderRadius: 14,
  },
  statusContainer: {
    width: 100,
    textAlign: "center",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FEF4F8",
    marginTop: 18,
    border: "0px solid black",
    borderRadius: "5px!important",
  },
  infoStyle: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
  },
  infoHead: {
    fontSize: 15,
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: 0.3,
    textAlign: "left",
    color: "#000",
  },
  infoValue: {
    marginTop: 4,
    fontSize: 16,
    fontWeight: 400,
    fontStyle: "normal",
    textAlign: "left",
    color: "#555",
  },
  heading: {
    color: "white",
    fontSize: 14,
  },
  textStyle: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 5,
  },
  valuContainer: {
    marginTop: 10,
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  extra: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    marginTop: 18,
  },
  pilotHead: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    borderRadius: 5,
    height: 30,
    borderColor: (colors) => colors.denim,
  },
  titlePilot: {
    color: "#fff",
    justifyContent: "center",
    fontSize: 14,
    alignSelf: "center",
  },
  pilotName: {
    fontSize: 16,
    color: "",
  },
  pilotItem: {
    paddingVertical: 6,
    paddingHorizontal: 4,
  },
  cancel: {
    width: 140,
    height: 40,
    flexGrow: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bordeRadius: 5,
    border: "1px solid #666",
  },
  cancelText: {
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    cursor: "pointer",
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    color: "#666",
  },
  rateConatiner: {
    flexDirection: "row",
    width: 394,
    height: 100,
    justifyContent: "center",
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    marginTop: 10,
  },
  rating: {
    display: "flex",
    flexDirection: "row",
    width: 34,
    marginLeft: -34,
    margin: 36,
    justifyContent: "space-between",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
  },
  rating2: {
    display: "flex",
    flexDirection: "row",
    width: 34,
    marginLeft: -2,
    margin: 36,
    marginTop: -7,
    justifyContent: "space-between",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
  },
  rateEx: {
    display: "flex",
    width: "100%",
  },
  edit: {
    width: 300,
    height: 60,
    marginBottom: 40,
    marginTop: -50,
  },
  button: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: -20,
  },
  focused: {},
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "black !important",
  },
  saveButton: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  submitBtn: {
    backgroundColor: (colors) => colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 3,
    height: 40,
    "&:hover": {
      backgroundColor: (colors) => colors.primaryColor,
    },
  },
  innerCont1: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(361px - 116px)",
    justifyContent: "space-around",
  },
  fboNameStyle: {
    height: 38,
    width: "100%",
    fontFamily: "Arial",
    fontSize: 32,
    fontWeight: "bold",
    textAlign: "left",
    color: "#333",
  },
  headingStyle: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "1px",
    textSlign: "left",
    color: "#999",
  },
  containerStyle: {
    display: "flex",
    flexDirection: "column",
  },
  priceCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "24px 20px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: "#eee",
  },
  priceHeadingStyle: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#000",
  },
  buttonsCont: {
    flexDirection: "row",
    width: 840,
    height: 60,
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    marginTop: 10,
  },
  priceContainer: {
    width: "100%",
    margin: "25px 0 24px",
    padding: "16px",
    borderRadius: "5px",
    background: "#f9f9fb",
  },
  rampFeeTextStyle: {
    height: 17,
    flexGrow: 0,
    opacity: "0.7",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#333",
    marginTop: 5,
    marginBottom: 5,
  },
  headingTextStyle: {
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#333",
  },
  valueStyle: {
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#131415",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  priceCont1: {
    display: "flex",
    lineHeight: 2.29,
  },
  fuelPriceStyle: {
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    color: "#131415",
    marginLeft: 5,
  },
  infoIconStyle: {
    marginLeft: 5,
    width: 15,
    height: 15,
    color: "#666",
    borderRadius: 10,
    cursor: "pointer",
  },
  valuePriceStyle: {
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    display: "flex",
    textAlign: "right",
    color: "#333",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  headingTextStyle2: {
    display: "flex",
    width: "100%",
    height: 32,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#333",
    alignItems: "center",
  },
  staticTextStyle: {
    flexGrow: 0,
    fontStyle: "italic",
    margin: "4px 0 0",
    opacity: 0.7,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#333",
  },
  extraSpace: {
    width: 20,
    height: 20,
  },
}));

export { useStyles };
