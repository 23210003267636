import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import RegisterOption from "./RegisterOption";
import SideBar from "./SideBar";
import SideBarSteps from "./SideBarSteps";
import SignUpProfileSetUp from "./SignUpProfileSetUp";
import { useDispatch, useSelector } from "react-redux";
import { registerAs } from "../../../redux/signup/signup.actions";
import { useHistory } from "react-router-dom";
import { clearAssignedAircraft, isParagon, getAircraftListByInviteePilot } from "../../../redux/account/account.actions";
import { getDetailOfInviteePilot, isIframe } from "../../../redux/auth/auth.actions";
import { invitedPilot } from "../../../redux/auth/auth.actions";

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: "100%",
    height: "100vh",
  },
  firstContainer: {
    display: "flex",
    width: 394,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(99deg, #212121 15%, #000000 85%)",
  },
  iframeFirstContainer:{
    display: "flex",
    width: 394,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#e8e8e8",
  },
  secondContainer: {
    width: "100%",
    height: "100vh",

    background: "#ffffff",
  },
}));

const PrimarySignUp = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [, setUserType] = useState("");

  const { signup, auth } = useSelector((state) => ({
    signup: state.signup,
    auth: state.auth,
  }));

  useEffect(() => {
    if (window.location.search !== '') {
      let pilotDetail = window.location.search;
      let pilot = pilotDetail.split("&email=");
      let pilotUserId = pilot[0].replace("?user=", "")
      let pilotEmail = pilot[1].split("&userType");
      dispatch(getDetailOfInviteePilot({ userId: pilotUserId }));
      dispatch(invitedPilot(decodeURIComponent(pilotEmail[0])));
      dispatch(registerAs(4));
      dispatch(
        getAircraftListByInviteePilot({
          userId: pilotUserId,
          isInvitePilot: true,
        }));
    }
    else {
      dispatch(invitedPilot(''));
    }
    let loc = window.location.origin; 
    if (loc.includes("paragon")) {
      dispatch(isParagon(true));
    } else {
      dispatch(isParagon(false));
    }
    dispatch(clearAssignedAircraft({}));
    if (window.location.pathname === "/signup/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4="){
      dispatch(isIframe(true));
    }
    if (window.location.pathname === "/signup"){
      dispatch(isIframe(false));
    }
  }, []);

  const handleClick = (value) => {
    dispatch(registerAs(value));
    setUserType(value);
    history.push("/signup/profileSetup");
  };

  useEffect(() => {
    setUserType(signup.registerAs);
  }, [signup]);

  return (
    <Grid container item xs={12} className={classes.mainContainer}>
      <Grid item xs={3} className={window.location.pathname === "/signup/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4=" || auth.isIframe ? classes.iframeFirstContainer : classes.firstContainer}>
        {signup.registerAs === "" || window.location.pathname === "/signup" || window.location.pathname === "/signup/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4=" ? (
          <SideBar/>
        ) : (
          (window.location.pathname !== "/signup" || window.location.pathname === "/signup/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4=") && <SideBarSteps />
        )}
      </Grid>
      <Grid
        item
        xs={9}
        className={classes.secondContainer}
        style={{
          padding: 40,
        }}
      >
        {signup.registerAs === "" || window.location.pathname === "/signup" || window.location.pathname === "/signup/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4="? (
          <RegisterOption handleClick={handleClick} />
        ) : (
          <SignUpProfileSetUp {...props}/>
        )}
      </Grid>
    </Grid>
  );
};

export default PrimarySignUp;
