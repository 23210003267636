import React from "react";
import ContactUsComponent from '../../components/scene/app/settings/ContactUsComponent';
import SettingsLayout from '../Settings';
import NozlInnerHeading from "../../components/ui/NozlInnerHeading";

const ContactUs = () => {
  return (
    <SettingsLayout>
      <NozlInnerHeading title="Contact Us" />
      <ContactUsComponent />
    </SettingsLayout>
  )
}

export default ContactUs;
