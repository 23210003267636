import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CardContent,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { useStyles } from "../../../assets/styles/ForgotPasswordStyles";
import * as authService from "../../../services/authService.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/auth/auth.actions";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import sessionWarning from "../../../assets/images/sessionWarning.png";

const ResetPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { account } = useSelector((state) => ({
    account: state.account,
  }));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPasswordScreen, setShowPasswordScreen] = useState(false);
  const error = password !== confirmPassword;

  function validateForm() {
    return (
      password === confirmPassword &&
      password.length >= 7 &&
      confirmPassword.length >= 7
    );
  }

  useEffect(() => {
    if (!isMobile && (isTablet || isDesktop)) {
      getValue();
    }
  }, []);

  const getValue = async () => {
    let token = props.location.hash.replace("#", "");
    const res = await authService.verifyResetPassword({
      resetPasswordToken: token,
    });
    if (res.response_type === "fail") {
      setShowPasswordScreen(true);
    } else {
      setEmail(res.response.data.email);
      setShowPasswordScreen(false);
    }
  };

  const submitPassword = async () => {
    let token = props.location.hash.replace("#", "");
    const res = await authService.resetPassword({
      resetPasswordToken: token,
      newPassword: password,
    });
    if (res.response_type === "fail") {
      toast.info(res.response.message, {
        position: "bottom-center",
        closeButton: false,
      });
    } else {
      toast.info(res.response.message, {
        position: "bottom-center",
        closeButton: false,
      });
      history.push({
        pathname: "./login",
      });    }
  };

  const handleClose = () => {
    history.push({
      pathname: "./login",
    });
  };

  const onContactAdminClick = () => {
    let mailId = "";
    if (account?.isParagonUser) {
      mailId = "preferred@paragonaviationgroup.com";
    } else {
      mailId = "tailwinds@nozlapp.com";
    }
    window.open(`mailto:${mailId}`);
  };

  const body = (
    <Grid className={classes.popUpStyle}>
      <img src={sessionWarning} alt="warning" className={classes.iconStyle} />
      <Grid className={classes.headingStyle1}>This link has expired.</Grid>
      <Grid className={classes.textStyle}>
        Your account may have already been set up by your flight department.
        Please contact your flight department or{" "}
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            onContactAdminClick();
          }}
        >
          {account?.isParagonUser === true
            ? "preferred@paragonaviationgroup.com"
            : "tailwinds@nozlapp.com"}
        </Link>{" "}
        for further assistance.
      </Grid>
    </Grid>
  );

  return (
    <Grid>
      <Grid className={classes.mainCont}>
        <Typography
          component="h1"
          variant="h4"
          className={classes.headingStyle}
        >
          NEW PASSWORD
        </Typography>
        <Grid className={classes.innerCont}>
          <CardContent className={classes.cardCont}>
            <form autoComplete="off" className={classes.form} noValidate>
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                required
                size="medium"
                id="password"
                onChange={(e) => {
                  let value = e.target.value.split(" ").join("");
                  setPassword(value);
                }}
                autoFocus
                value={password}
                label="Password"
                name="password"
                type="password"
                autoComplete="off"
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                  className: classes.floatingLabelFocusStyle,
                }}
                InputProps={{
                  style: { color: "#fff" },
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.inputLine,
                    colorSecondary: { color: "#fff" },
                  },
                  color: "#fff",
                }}
              />
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="normal"
                required
                size="medium"
                type="password"
                id="confirmPassword"
                helperText={
                  confirmPassword !== "" && password !== confirmPassword
                    ? "Password and Confirm Password do not match"
                    : ""
                }
                error={confirmPassword !== "" && error}
                onChange={(e) => {
                  setConfirmPassword(e.target.value.split(" ").join(""));
                }}
                value={confirmPassword}
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="off"
                InputLabelProps={{
                  classes: {
                    root:
                      confirmPassword !== "" && password !== confirmPassword
                        ? classes.errorLabelonType
                        : classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel,
                  },
                  className: classes.floatingLabelFocusStyle,
                }}
                InputProps={{
                  style: { color: "#fff" },
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    input: classes.inputLine,
                    colorSecondary: { color: "#fff" },
                  },
                  color: "#fff",
                }}
              />
            </form>

            <Button
              disabled={!validateForm()}
              onClick={submitPassword}
              fullWidth
              className={
                account.isParagonUser !== undefined &&
                account.isParagonUser === true
                  ? `${classes.submitBtn} ${classes.submitParagonBtn}`
                  : classes.submitBtn
              }
              disableElevation
            >
              Submit
            </Button>
          </CardContent>
        </Grid>
      </Grid>

      <Modal
        disableBackdropClick={true}
        open={showPasswordScreen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
        }}
      >
        {body}
      </Modal>
    </Grid>
  );
};

export default ResetPassword;
