import React, { useState } from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { emitCustomEvent } from "react-custom-events";

import Heading from "../Heading";
import HeadingText from "../HeadingText";
import { useStyles } from "../../../../assets/styles/ProfileSummaryStyles";
import ProfileDetails from "./ProfileDetails";
import ContactDetails from "./ContactDetails";
import { useButtonStyles } from "../../../../assets/styles/SignupButtonStyles";
import AircraftDetails from "./AircraftDetails";
import TermsAndConditions from "./TermsAndConditions";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as services from "../../../../services/mainService";
import { toast } from "react-toastify";
import colors from "../../../../constants/colors";
import Modal from "@material-ui/core/Modal";
import SubmitSignUp from "../../signUp/SubmitSignUp";
import { useDispatch } from "react-redux";
import {
  registerAs,
  usersList,
  aircraftsList,
  basicDetails
} from "../../../../redux/signup/signup.actions";
import { login } from "../../../../redux/auth/auth.actions";

const StyledGrid = styled(Grid)`
  overflow-y: auto;
  display: flex-wrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const VerifyDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const buttonClass = useButtonStyles();
  const dispatch = useDispatch();
  const { signup, auth, account } = useSelector((state) => ({
    signup: state.signup,
    auth: state.auth,
    account: state.account,
  }));
  const [accept, setAccept] = useState(false);
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [openThankyou, setOpenThankyou] = useState(false);

  const acceptTermsandCondition = (value) => {
    setAccept(value);
  };

  const acceptPrivacyPolicyCond = (value) => {
    setAcceptPrivacyPolicy(value);
  };

  const backButton = () => {
    history.goBack();
  };

  const submitSignup = async () => {
    setLoading(true);
    let newContactArray = JSON.parse(JSON.stringify(signup.contactList));
    newContactArray = newContactArray.map(function (item) {
      if (item.countryCode) {
        item.phone = item.phone.replace(
          item.countryCode.replace("+", "") || "",
          ""
        );
      }
      delete item.contactTitle;
      return item;
    });
    let newAircraftArray = JSON.parse(JSON.stringify(signup.aircraftList));
    newAircraftArray = signup.aircraftList.map(function (item) {
      delete item.manufacturerName;
      delete item.customAircraftObj;
      delete item.modelName;
      return item;
    });
    const detail = {
      terms: true,
      name: signup.basicDetails.name,
      mobileNumber: signup.basicDetails.mobileNumber.replace(
        signup?.basicDetails?.countryCode?.replace("+", "") || "",
        ""
      ),
      baseLocation: signup.basicDetails.baseLocation,
      addLat: signup.basicDetails.addLat,
      addLng: signup.basicDetails.addLng,
      city: signup.basicDetails.city,
      state: signup.basicDetails.state,
      country: signup.basicDetails.country,
      email: signup.basicDetails.email,
      password: signup.basicDetails.password,
      confirmPassword: signup.basicDetails.confirmPassword,
      userType: signup.basicDetails.userType,
      isParagonUser: account.isParagonUser,
      countryCode: signup.basicDetails.countryCode,
      isInviteePilot: auth.status === 1 ? true : false,
      contacts: newContactArray,
      aircrafts: newAircraftArray,
    };
    const res = await services.signUpVerifyUrl(detail);
    if (res.response_type === "success") {
      setLoading(false);
      if (auth.status === 1) {
        dispatch(login(signup.basicDetails.email.toLowerCase(), signup.basicDetails.password, ""));
      } else {
        if (auth.isIframe) {
          setOpenThankyou(true);
        } else {
          setTimeout(() => {
            emitCustomEvent("nozlEvent", { showPopup: true });
          }, 1000);
          history.push("/login");
        }
      }
    } else {
      setLoading(false);
      toast.error(res.response.message, {
        position: "bottom-center",
        closeButton: false,
        style: {
          color: "white",
          backgroundColor:
            account.isParagonUser || auth.isIframe
              ? colors.paragonPrimaryColor
              : colors.primaryColor,
        },
      });
    }
  };

  const handleCloseThankyou = () => {
    setOpenThankyou(false);
    dispatch(registerAs(""));
    dispatch(usersList([]));
    dispatch(aircraftsList([]));
    dispatch(basicDetails({}));
    history.push("/signup/bF6PafGuEPd49g0aLc0SVuYWWkpfGtMSugS7UDnbeM4=");
  };

  return (
    <Grid container>
      <StyledGrid item xs={12} className={classes.summaryCont}>
        <Grid item xs={12}>
          <Grid container item xs={10} className={classes.firstCont}>
            <Heading heading="Verify Details" />
            <HeadingText
              navigation="aircrafts"
              text="Please confirm that all of your information is correct. Once you submit, your account will be sent to our Customer Relations team for verification."
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ProfileDetails />
        </Grid>
        {signup.registerAs === 2 ? (
          <div>
            <Grid item xs={12} className={classes.lineStyle} />
            <Grid item xs={12} className={classes.userCont}>
              <ContactDetails />
            </Grid>
          </div>
        ) : null}

        <Grid item xs={12} className={classes.lineStyle} />

        <Grid item xs={12} className={classes.userCont}>
          <AircraftDetails />
        </Grid>

        <Grid item xs={12} className={classes.lineStyle} />

        <Grid item xs={12} className={classes.userCont}>
          <TermsAndConditions
            acceptTermsandCondition={acceptTermsandCondition}
            acceptPrivacyPolicyCond={acceptPrivacyPolicyCond}
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          className={buttonClass.bottomButtonCont}
          style={{ marginTop: 40, paddingRight: 10 }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Grid
              container
              item
              xs={12}
              className={buttonClass.bottomButtonCont}
            >
              <Button
                container
                className={buttonClass.backButtonText}
                onClick={backButton}
              >
                Back
              </Button>
              <Button
                container
                className={
                  accept === true && acceptPrivacyPolicy === true
                    ? (account.isParagonUser !== undefined &&
                        account.isParagonUser === true) ||
                      auth.isIframe
                      ? `${buttonClass.backButtonText} ${buttonClass.nextButtonText} ${buttonClass.paragonNextButtonText}`
                      : `${buttonClass.backButtonText} ${buttonClass.nextButtonText}`
                    : (account.isParagonUser !== undefined &&
                        account.isParagonUser === true) ||
                      auth.isIframe
                    ? `${buttonClass.paragonDisabledButton} ${buttonClass.disabledButton}`
                    : `${buttonClass.disabledButton}`
                }
                disabled={
                  accept === true && acceptPrivacyPolicy === true ? false : true
                }
                onClick={submitSignup}
              >
                {isLoading === true && (
                  <CircularProgress
                    style={{ color: "white" }}
                    size={20}
                    thickness={5}
                  />
                )}
                {!isLoading && "Create Account"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disableBackdropClick={false}
        open={openThankyou}
        onClose={handleCloseThankyou}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SubmitSignUp clickOkButton={handleCloseThankyou} />
      </Modal>
    </Grid>
  );
};

export default VerifyDetails;
