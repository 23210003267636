import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    justifyContent:"center",
    display:'flex'
  },
}));

  const TablePaginationActions = (props) => {
  const classes = useStyles();
  const { page, pageCount, rowsPerPage, onPageChange, notificationLength } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1, pageCount - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1, pageCount + 1);
  };

  return (
    <Grid className={classes.root}>
      <Button 
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
         {"< "}Prev
      </Button>
      <Button
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(notificationLength / rowsPerPage) - 1}
        aria-label="next page"
      >
        Next{" >"}
      </Button>
    </Grid>
  );
}

export default TablePaginationActions;