import { withStyles } from "@material-ui/core";
import TextField from "../../components/controls/Input";

export const ValidationSignUpTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "black",
      textTransform: "capitalize",
    },
    "& label.Mui-focused": {
      color: "#666",
      textTransform: "capitalize",
    },
    "& label.MuiFormLabel-filled":{
      color: "#666"
    },
    "& .MuiInput-underline:after": {
      border: "2px solid #ddd !important",
      color: "black",
      textTransform: "capitalize",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "black",
        border: "2px solid #ddd",
        textTransform: "capitalize",
      },
      "&:hover fieldset": {
        textTransform: "capitalize",
        color: "black",
        border: "2px solid #ddd !important",
      },
      "&.Mui-focused fieldset": {
        textTransform: "capitalize",
        color: "black",
        border: "2px solid #ddd !important",
      },
      color: "black",
      textTransform: "capitalize",
    },
  },
})(TextField);


export const IFrameSignUpTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "black",
      textTransform: "capitalize",
    },
    "& label.Mui-focused": {
      color: "#666",
      textTransform: "capitalize",
    },
    "& label.MuiFormLabel-filled":{
      color: "#666"
    },
    "& .MuiInput-underline:after": {
      border: "1px solid #ddd !important",
      color: "black",
      textTransform: "capitalize",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "black",
        border: "1px solid #ddd",
        textTransform: "capitalize",
      },
      "&:hover fieldset": {
        textTransform: "capitalize",
        color: "black",
        border: "1px solid #ddd !important",
      },
      "&.Mui-focused fieldset": {
        textTransform: "capitalize",
        color: "black",
        border: "1px solid #ddd !important",
      },
      color: "black",
      textTransform: "capitalize",
    },
  },
})(TextField);
