import strings from "../../constants/strings";
import * as actionTypes from "./auth.actionTypes";

const initialState = {
  status: "loading",
  token: null,
  uid: null,
  loading: false,
  profileStatus: 0,
  userType: 2,
  signLoading: false,
  email: "",
  isChangePassword: false,
  termsAndConditions: "",
  alreadyExists: false,
  password: "",
  isIframe: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //login in website
    case actionTypes.LOGIN_WITH_EMAIL.REQUEST:
    case actionTypes.LOGIN_WITH_EMAIL.SUCCESS: {
      return {
        email: action.payload.email,
        isActive: action.payload.isActive,
        isChangePassword: action.payload.isChangePassword,
        status: action.payload.status,
        token: action.payload.token,
        userType: action.payload.userType,
        loading: false,
        signLoading: false,
        termsAndConditions: action.payload.terms,
        isIframe: action.payload.isIFrameLogin,
      };
    }
    case actionTypes.LOGIN_WITH_EMAIL.FAILURE: {
      return {
        loading: false,
        signLoading: false,
      };
    }

    // logout from website
    case actionTypes.LOG_OUT.REQUEST:
      return { ...state, status: "loading", loading: false, token: null };
    case actionTypes.LOG_OUT.SUCCESS: {
      return {
        ...initialState,
        status: "failed",
        userSelectedAircraft: {},
        token: null,
        profileStatus: 0,
        loading: false,
        uid: null,
      };
    }

    case actionTypes.LOG_OUT.FAILURE:
    case strings.HIDE_PROGRESS:
      return {
        ...state,
        loading: false,
      };

    // fetch user profile
    case actionTypes.GET_PROFILE_BY_TOKEN:
      return {
        ...state,
        email: action.payload.data?.email,
        uid: action?.payload?.data?._id,
        profileStatus: action?.payload?.data?.status,
        isChangePassword: action?.payload?.data?.isChangePassword,
        isActive: action?.payload?.data?.isActive,
        userType: action?.payload?.data?.userType || 4,
        loading: false,
      };

    case actionTypes.UPDATE_PROFILE_STATUS.REQUEST: {
      return {
        ...state,
        profileStatus: action?.payload?.status,
        isChangePassword: action?.payload?.isChangePassword,
        isActive: action?.payload.isActive,
      };
    }

    case actionTypes.IS_IFRAME:
      return {
        ...state,
        isIframe: action.payload,
      };

    case actionTypes.TERMS_CONDITION: {
      return {
        ...state,
        termsAndConditions: true,
      };
    }

    case actionTypes.INVITED_PILOT:
      if (action.payload !== "") {
        return {
          ...state,
          email: action.payload,
          status: 1,
        };
      } else {
        return {
          ...state,
          email: "",
          status: "",
        };
      }

    default:
      return state;
  }
};
