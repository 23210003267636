import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "../../../../assets/styles/FboDetailStyles";
import bookFuelIcon from "../../../../assets/images/bookFuelIcon.png";
import chatIcon from "../../../../assets/images/chatIcon.png";

const FboButtons = (props) => {
  const classes = useStyles();
  const { offerVal, item, account, navigation } = props;

  const fboFuelType = item.isJetAFuel === 0 && item.is100LLFuel === 0 && item.isSAFFuel === 0;
  const handleChildClick = (e) => {
    props.handleChildClick(e);
  };

  const handleParentClick = (e) => {
    props.handleParentClick(e);
  };

  const showFboDetail = () =>{
    props.showFboDetail();
  }

  return (
    <Grid
      container
      className={classes.buttonCont}
      style={{ height: props.navigation === "fromDetail" ? 80 : null }}
    >
      <Grid item xs={props.navigation === "fromDetail" ? 6 : 5} className={classes.container}>
        {!fboFuelType ? offerVal === "" ? (
          item.nudgeCount === 0 &&
          (account.userSelectedAircraft.operatorName === undefined ||
            account.userSelectedAircraft.operatorName === "Aircraft") ? (
            <Grid
              item
              xs={12}
              className={navigation === "fromFboList" ? classes.cont: classes.contDetail} style={{ height: navigation === 'fromDetail' ? 40 : 30 }}
            >
              <div
               onClick={fboFuelType ? null : handleChildClick}
                style={{
                  width: props.navigation === "fromDetail" ? 170 : "95%",
                }}
                className={
                  fboFuelType
                    ? `${classes.fuelButton} ${classes.disableFuelButton}`
                    : classes.fuelButton
                }
              >
                {props.navigation === "fromDetail" && (
                  <img
                    src={chatIcon}
                    className={classes.chatIconStyle}
                    alt=""
                  />
                )}
                <div
                  className={classes.buttonTextStyle}
                  style={{
                    color: "#fff",
                    fontSize: props.navigation === "fromDetail" ? "16px" : 14,
                  }}
                >
                  Nudge
                </div>
              </div>
            </Grid>
          ) :
          <Grid item xs={12} onClick={props.navigation === "fromDetail" ? null : showFboDetail} className={classes.noDataStyle}>.</Grid>
        ) : (
          <Grid item xs={12} className={classes.cont} style={{ width: "90%" }}>
            <div className={props.navigation === "fromDetail" ? classes.discounted : classes.discountedDetail}>{offerVal}</div>
          </Grid>
        ) :  <Grid item xs={12} onClick={props.navigation === "fromDetail" ? null : showFboDetail} className={classes.noDataStyle}>.</Grid>}
      </Grid>
      <Grid
        item
        xs={props.navigation === "fromDetail" ? 6 : 7}
        className={classes.buttonStyle}
        style={{ height: props.navigation === "fromDetail" ? 40 : 30 }}
      >
        <div
          className={classes.fuelButton}
          onClick={handleParentClick}
          style={{ width: props.navigation === "fromDetail" ? 170 : "100%" }}
        >
          {props.navigation === "fromDetail" && (
            <img
              src={bookFuelIcon}
              className={classes.bookFuelIconStyle}
              alt=""
            />
          )}
          <div
            className={classes.buttonTextStyle}
            style={{
              color: "#fff",
              fontSize: props.navigation === "fromDetail" ? "16px" : 14,
            }}
          >
            Arrival Request
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default FboButtons;
