export default function getDistanceFromLatLonInKm(start, end, unit = "mile") {
  const { lat: startLat, lon: startLon } = start;
  const { lat: endLat, lon: endLon } = end;

  const radii = {
    km: 6371,
    mile: 3960,
    meter: 6371000,
    nmi: 3440
  };

  const earthRadius = radii[unit];

  const degToRad = function(deg) {
    return deg * (Math.PI / 180);
  };
  const deltaLat = degToRad(endLat - startLat);
  const deltaLon = degToRad(endLon - startLon);
  const latInRad1 = degToRad(startLat);
  const latInRad2 = degToRad(endLat);

  const arc =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2) *
      Math.cos(latInRad1) *
      Math.cos(latInRad2);

  const line = 2 * Math.atan2(Math.sqrt(arc), Math.sqrt(1 - arc));
  const distance = earthRadius * line;
  return distance;
}
