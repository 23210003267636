import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 16,
    borderBottomWidth: 4,
  },
  label: {
    fontWeight: "400",
    fontFamily: 'Arial',
    fontStyle: "normal",
    display: "flex",
    alignItems: "center",
  },
  value: {
    fontWeight: "600",
    fontFamily: 'Arial',
    fontStyle: "bold",
  },
}));

const TransactionDetailRow = (props) => {
  const { key, label, value } = props;
  const classes = useStyles();

  return (
    <div>
      <div
        key={key}
        className={classes.main}
        style={{
          borderBottomColor:
            props.fromTooltip === "fromTooltip" ? "#fff" : "#666",
          paddingTop: props.fromTooltip === "fromTooltip" ? 5 : 12,
          paddingBottom: props.fromTooltip === "fromTooltip" ? 5 : 12,
        }}
      >
        <div
          className={classes.label}
          style={{
            color: props.fromTooltip === "fromTooltip" ? "#fff" : "#666",
            fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,
          }}
        >
          {label}
        </div>
        <div
          className={classes.value}
          style={{
            color: props.fromTooltip === "fromTooltip" ? "#fff" : "#666",
            fontSize: props.fromTooltip === "fromTooltip" ? 14 : 16,
          }}
        >
          {value}
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default TransactionDetailRow;
