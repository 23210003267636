import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  Select,
  useTheme,
  Checkbox,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import colors from "../../constants/colors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  checkboxStyle: {},
  formControl: {
    width: "100%",
    height: 56,
    paddingLeft: 10,
    paddingRight: 10,
    background: 'white',
    border: "1px solid #000 !important",
    boxSizing: "border-box",
    borderRadius: 5
  },
  fieldStyle: {
    marginTop: -50,
    width: "100%",
  },
  inputFocused: {},
  inputLabel: {
    color: "#555",
    "&.Mui-focused": {
      color: colors.primaryColor,
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
  },
};

function getStyles(name, pilotName, theme) {
  return {
    width: "100%",
    display: "flex",
    alignItems: "space-between",
    justifyContent: "space-between",
    fontWeight:
      pilotName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const NozlPilotSelection = (props) => {
  const { data, onChange } = props;
  const theme = useTheme();
  const [selectedPilots, setselectedPilots] = useState([]);
  const classes = useStyles();

  const { account, pilots } = useSelector((state) => ({
    pilots: state.pilots,
    account: state.account
  }));


  useEffect(() => { }, props.selectedPilot);

  useEffect(() => {
    if (props.navigation === "fromAircraft") {
      const assignedPilotListData = account?.assignedPilotListForAircraft || [];
      const tempData = pilots?.pilotList;
      tempData.map((item) => {
        const filterData = assignedPilotListData.filter(function (assignedItem) {
          return (
            assignedItem?.pilotDetail?.userId === item.pilotUserId ||
            assignedItem?.pilotUserId === item.pilotUserId
          );
        });
        if (filterData === undefined || filterData.length === 0) {
          item.isSelect = false;
        } else {
          item.isSelect = true;
        }
        return item;
      });
      let pilot = [];
      tempData.filter((item) => {
        if (item.isSelect === true) {
          pilot.push(item);
          setselectedPilots(pilot);
        }
      })
    } else {
      setselectedPilots([]);
    }
  }, [
    account?.assignedPilotListForAircraft,
    props.editObj,
    pilots?.pilotList,
    account?.assignedPilotListForAircraft,
    data, props.selectedPilot
  ]);

  const handleChange = (e) => {
    let value = e.target.value;
    let pilotsLists = [];
    if (props.navigation === 'fromAircraft') {
      if (value.length > 0) {
        value.map((item) => {
          pilotsLists.push(item.pilotUserId);
        });
      } else {
        pilotsLists = [];
      }
    }
    onChange(value, pilotsLists, true);
    setselectedPilots(value);
  };

  return (
    <Grid container item xs={12} style={{ marginTop: 10, background: "white" }}>
      {data.length > 0 ? (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            id="demo-mutiple-checkbox-label"
            style={{
              background: "white",
              pointerEvents: 'none'
            }}
            classes={{ focused: classes.inputFocused }}
            className={classes.inputLabel}
          >
            {props.navigation === 'fromAircraft' ? "Assign Pilot" : "Assign Crew"}
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            disableUnderline
            multiple
            value={selectedPilots}
            onChange={handleChange}
            renderValue={(selected) => {
              let tempArray = [];
              selected.map((item, index) => {
                tempArray.push(props.navigation === "fromAircraft" ? `${item?.pilotDetail.length > 0 ? item?.pilotDetail[0].name : item.email}` : `${item?.pilotDetail?.name}`);
              });
              return tempArray.join(", ");
            }}
            input={<Input />}
            MenuProps={MenuProps}
            className={classes.fieldStyle}
            inputProps={{
              style: { color: "#000", backgroundColor: "white" },
            }}
          >
            {data.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  value={item}
                  style={getStyles(item, selectedPilots, theme)}
                >
                  {props.navigation === "fromAircraft" ? `${item?.pilotDetail.length > 0 ? item?.pilotDetail[0].name : item.email}` : `${item?.pilotDetail?.name}`}
                  <Checkbox checked={selectedPilots.indexOf(item) > -1} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <div
          className={classes.formControl}
          style={{
            backgroundColor: "#dadada",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Assign crew list is empty
        </div>
      )}
    </Grid>
  );
};

export default NozlPilotSelection;
