import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  padding: {
    minHeight: "0px!important",
    padding: "0!important",
    paddingTop: "0px",
    paddingRight: "0px",
  },
  root: {
    flex: 1,
    height: "100vh",
    width: "100%",
  },
  noData: {
    display: "flex",
    flex: 1,
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  listContainer: {
    width: '100%',
    display: 'flex',
    padding: '0 20px 0 0',
  },
  locationContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
  },
  detailContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-around',
    justifyContent: 'space-around',
  },
  topContainer: {
    display: 'flex',
  },
  bottomContainer: {
    width: '100%',
    textAlign: 'right',
  },
  replyContainer: {
  },
  messageTextStyle: {
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    color: '#666'
  },
  fboCount: {
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    textAlign: "right",
    color: "#4dcceb",
  },
  code: {
    fontSize: 14,
    fontStyle: "normal",
    letterSpacing: 0.3,
    color: "#999",
    fontWeight: 'bold',
    fontFamily: 'Arial'
  },
  rootHead: {
    backgroundColor: (colors) => colors.denim,
  },
  filledCircle: {
    backgroundColor: colors.primaryColor,
    width: 8,
    height: 8,
    border: 8 / 2,
    borderRadius: 8 / 2,
  },
  Circle: {
    backgroundColor: "#ddd",
    width: 8,
    height: 8,
    border: 8 / 2,
    borderRadius: 8 / 2,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainContainer: {
    height: "calc(100vh - 120px)",
    background: 'white'
  },
  notifyCont: {
    width: 5,
    margin: '0 20px 0 0',
    backgroundColor: '#df2935'
  },
  unreadNotifyCont: {
    width: 5,
    margin: '0 20px 0 0',
    backgroundColor: 'transparent'
  },
  fboNameStyle: {
    height: 20,
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    color: '#999'
  },
  bottomCont: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  datetextStyle: {
    fontStyle: "normal",
    letterSpacing: 0.3,
    color: "#999",
    fontWeight: 'bold',
    fontFamily: 'Arial',
    fontSize: 12
  },
  headingTextStyle: {
    fontFmily: 'Arial',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#999',
  }
}));
export { useStyles };
