import * as actionTypes from "./fbos.action-types";
import { LOG_OUT } from "../auth/auth.actionTypes";

const initialState = {
  status: "loading",
  fbos: [],
  fboDetails: [],
  unlistedfboDetails: [],
  unlistedFbos: [],
  discountCheckFboDetail: {},
  searchText: "",
  favouriteFbosList: [],
  favouriteFboItem: [],
  geoloc: {},
  fboGeoLoc: {},
  currentFavoriteFbo: '',
  fboDefaultServices: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // fetch fbo detail list
    case actionTypes.GET_FBO_DETAIL_LIST.REQUEST:
      return {
        ...state,
        fboDetails: [],
        unlistedfboDetails: [],
        status: "loading",
      };

    case actionTypes.GET_FBO_DETAIL_LIST.SUCCESS:
      return { ...state, fboDetails: action.payload, status: "success" };
    case actionTypes.GET_FBO_DETAIL_LIST.FAILURE:
      break;

    // invite fbo
    case actionTypes.INVITE_FBO.REQUEST:
      return { ...state };
    case actionTypes.INVITE_FBO.SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case actionTypes.INVITE_FBO.FAILURE:
      return { ...state, status: "failed" };

    // favourite fbo
    case actionTypes.FAVOURITE_FBOS.REQUEST:
      return { ...state, status: "loading" };
    case actionTypes.FAVOURITE_FBOS.SUCCESS:
      return { ...state, favouriteFbosList: action.payload, status: "success" };
    case actionTypes.FAVOURITE_FBOS.FAILURE:
      return { ...state, status: "failed" };

    //add favourite fbo
    case actionTypes.ADD_FAVORITE_FBO.REQUEST:
      return { ...state };
    case actionTypes.ADD_FAVORITE_FBO.SUCCESS:
      return {
        ...state,
        currentFavoriteFbo: action.payload.data._id,
        status: "success",
      };
    case actionTypes.ADD_FAVORITE_FBO.FAILURE:
      return { ...state, status: "failed" };

    //unmark favourite fbo
    case actionTypes.UNMARK_FAVORITE_FBO.REQUEST:
      return { ...state };
    case actionTypes.UNMARK_FAVORITE_FBO.SUCCESS:
      return { ...state, currentFavoriteFbo: "", status: "success" };
    case actionTypes.UNMARK_FAVORITE_FBO.FAILURE:
      return { ...state, status: "failed" };

    // get fbos list
    case actionTypes.GET_FBOS.SUCCESS:
      return { ...state, fbos: action.payload, status: "success" };
    case actionTypes.GET_FBOS.FAILURE:
      return { ...state, status: "failure", unlistedfboDetails: [] };

    // get unlisted fbos
    case actionTypes.GET_UNLISTEDFBOS.SUCCESS:
      return { ...state, unlistedFbos: action.payload, status: "success" };

    // get unlisted fbo detail
    case actionTypes.GET_UNLISTEDFBO_DETAIL_LIST.REQUEST:
      return {
        ...state,
        fboDetails: [],
        unlistedfboDetails: [],
        status: "loading",
      };

    case actionTypes.GET_UNLISTEDFBO_DETAIL_LIST.SUCCESS:
      return {
        ...state,
        unlistedfboDetails: action.payload,
        status: "success",
      };

    case actionTypes.SEARCH_FBO_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };

    case actionTypes.FAVOURITE_FBO_ITEM:
      return {
        ...state,
        favouriteFboItem: action.payload,
      };

    case actionTypes.SAVE_GEOLOC:
      return {
        ...state,
        geoloc: action.payload,
      };

    case actionTypes.SAVE_FBO_GEOLOC:
      return {
        ...state,
        fboGeoLoc: action.payload,
      };

    case actionTypes.FBO_DEFAULT_SERVICES.REQUEST: {
      return {
        ...state,
        fboDefaultServices: []
      }
    }

    case actionTypes.FBO_DEFAULT_SERVICES.SUCCESS:{
      return {
        ...state,
        status: "success",
        fboDefaultServices: action.payload
      }
    }
    case actionTypes.FBO_DEFAULT_SERVICES.FAILURE:{
      return { ...state, status: "failure", unlistedfboDetails: [] };
    }
    case LOG_OUT.SUCCESS:
      return initialState;

    default:
      return { ...state };
  }
};
