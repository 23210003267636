import {
  Avatar,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FlatList from "flatlist-react";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import commonClasses from "../../../../constants/common";
import * as mainServices from "../../../../services/mainService.js";
import planeIcon from "../../../../assets/images/planeIcon.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import colors from "../../../../constants/colors";
import {
  getFavouriteFbos,
  favouriteFboItem
} from "../../../../redux/fbos/fbos.actions";
import { useHistory } from "react-router-dom";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { toast } from "react-toastify";
import CustomLoader from "../../../ui/CustomLoader";

const useStyles = makeStyles(() => ({
  items: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "#000",
    cursor: "pointer",
  },
  noDataFound:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    height: "calc(100vh - 150px)",
    color: colors.lightGreyBlue,
  },
  pilotName: {
    fontSize: 18,
    color: "#000",
    fontWeight: "500",
  },
  header: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "space-between",
  },
  button: {
    width: 300,
  },
  button2: {
    width: "400px !important",
    marginLeft: 100,
    marginRight: 100,
  },
  appMain: {},
}));

let params = {};

const ManageOperators = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth, fbos } = useSelector((state) => ({
    auth: state.auth,
    fbos: state.fbos,
  }));
  const classes = useStyles();
  const commonStyles = commonClasses();

  const [sectionListData, setSectionListData] = useState([]);
  const [, setDeleteItem] = useState({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(favouriteFboItem({}));
    getFboList();
  }, [auth.token]);

  const getFboList = () => {
    dispatch(getFavouriteFbos({ token: auth.token }));
  };

  useEffect(() => {
    if (
      fbos.favouriteFbosList !== undefined &&
      fbos.favouriteFbosList.length > 0
    ) {
      fbos.favouriteFbosList.sort(function (a, b) {
        if (a?.fboName < b?.fboName) {
          return -1;
        }
        if (a?.name > b?.name) {
          return 1;
        }
        return 0;
      });
      setSectionListData(fbos.favouriteFbosList);
    }
  }, [fbos.favouriteFbosList]);

  const onDelete = (index, item) => {
    params = {
      fboUserId: item._id,
      token: auth.token,
    };
    setDeleteItem(item);
    setOpen(true);
  };

  const openDialog = async () => {
    const res = await mainServices.removeFavouriteFbo(params);
    if (res.response_type === "success") {
      toast(res.response.message, { position: "bottom-center", closeButton: false });
      dispatch(getFavouriteFbos({ token: auth.token }));
    } else {
      toast.info(res.response.message, { position: "bottom-center", closeButton: false });
    }
    setOpen(false);
  };

  useEffect(() => {
    setSectionListData(fbos.favouriteFbosList);
  }, [fbos.favouriteFbosList]);

  const onClickFavourite = (item) => {
    dispatch(favouriteFboItem(item));
    history.push({
      pathname: "./home",
      state: {
        path: 'favoriteFbos',
      },
    });

  };

  return (
    <div style={{ padding: 5 }}>
      {
        fbos.status === 'loading' ?
          <CustomLoader />
          :
          <div className={classes.appMain}>
            <div
              style={{
                maxHeight: "calc(100vh - 100px)",
                overflowY: "auto",
              }}
            >
              {sectionListData.length > 0 ? (
                <FlatList
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  list={sectionListData}
                  renderItem={(item, index) => {
                    return (
                      <ListItem
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          onClickFavourite(item);
                        }}
                        className={classes.listItem}
                        alignItems="flex-start"
                      >
                        <ListItemIcon>
                          <Avatar className={commonStyles.managerIcon}>
                            <img
                              style={{ maxWidth: 40, maxHeight: 40 }}
                              src={planeIcon}
                              alt="managerIcon"
                            />
                          </Avatar>
                        </ListItemIcon>

                        <ListItemText
                          primary={`${item?.fboName}`}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                display="block"
                              >
                                {item?.icaoCode}
                              </Typography>
                            </React.Fragment>
                          }
                        />

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => onDelete(index, item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  }}
                />
              ) : null}
              {sectionListData.length === 0 ? (
                <h4
                 className={classes.noDataFound}
                >
                  No Favorite FBO in the list
                </h4>
              ) : null}
            </div>

            {/* Dialog for show to confirm before dialog close  */}

            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to remove?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary">
                  Cancel
                </Button>
                <Button onClick={openDialog} color="secondary" autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
      }
    </div>
  );
};

export default ManageOperators;