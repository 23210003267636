import React, { useState, useEffect } from "react";
import { useStyles } from "../../../../assets/styles/FuelRequestStyles";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import TextField from "../../../controls/Input";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Formik } from "formik";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { getAircraftList } from "../../../../redux/account/account.actions";
import NozlRegistrySelection from "../../../ui/NozlRegistrySelection";
import * as services from "../../../../services/mainService";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";
import NozlPilotSelection from "../../../ui/NozlPilotSelection";
import { toast } from "react-toastify";
import moment from "moment";
import colors from "../../../../constants/colors";
import {
    getRequests,
    clearFuelRequestResponse,
} from "../../../../redux/requests/requests.actions";
import { getTimezoneByLatLong } from "./TimeZoneHelper";
import RampFeeType from "../../../../config/RampFeeType";
import FuelRequestSchema from "../.../../../../../utils/forms/schema.fuelRequest";
import { calculateUpdateList, calculateRateType, calculatePrice, setWaiveoff } from "./FuelRequestHelper";
import FuelRequestAsPerPilot from "./FuelRequestAsPerPilot";
import FuelRequestFboArrived from "./FuelRequestFboArrived";
import FuelRequestDate from "./FuelRequestDate";
import noFboLogo from "../../../../assets/images/fboItem.png";
import { getImageURLFromAWS, calculateRateValue } from "../AirportFboScreen/FboDetailHelper";
import InternationalServies from "./InternationalServices";
import FuelTypeToggle from "./FuelTypeToggle";
import DiscountType from "../../../../config/constants";
import SafAndJetAType from "../../../../config/SafAndJetAType";
import constants from "../../../../config/constants";

let params = [];
let parkingfee = 0;
let isFboArrived = false;
let zone = "";

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#df2935",
        },
        "& .MuiTextField-root > label": {
            backgroundColor: "#df2935",
            color: "#df2935",
        },

        "& .MuiTextField-root > .MuiFormLabel-root.Mui-focused": {
            color: "#df2935",
        },
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
    },
})(TextField);

const CssCommentTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#df2935",
        },
        "& .MuiTextField-root > label": {
            backgroundColor: "#df2935",
            color: "#df2935",
        },
        "& .MuiTextField-root > .MuiFormLabel-root.Mui-focused": {
            color: "#df2935",
        },
    },
})(TextField);

let fuelselectedType = 1;
let waiveOff = '';

const Fuelrequest = (props) => {
    const classes = useStyles(colors, isMobile);
    const dispatch = useDispatch();
    const { auth, account, fbos } = useSelector((state) => ({
        auth: state.auth,
        account: state.account,
        fbos: state.fbos,
    }));

    const [isPristPriceEnable, setisPristPriceEnable] = useState(false);
    const [pristPriceValue, setpristPriceValue] = useState(0);
    const [sectionListData, setSectionListData] = useState([]);
    const [selectedRegistry, setSelectedRegistry] = useState({});
    const [volume, setVolume] = useState(0);
    const [gallon, setGallon] = useState("");
    const [waiveOffType, setWaiveOffType] = useState("");
    const [originalRate, setoriginalRate] = useState(0);
    const [rate, setrate] = useState(0);
    const [rampFee, setRampFee] = useState(0);
    const [isPilotsAsiggnale, setisPilotsAsiggnale] = useState(false);
    const [aircraftTypeName, setAircraftTypeName] = useState("");
    const [aircraftFuelType, setAircraftFuelType] = useState(1);
    const [allAssignedPilots, setAllAssignedPilots] = useState([]);
    const [assignedPilot, setAssignedPilot] = useState([]);
    const [comment, setComment] = useState("");
    const [isRampFeeLineWillShow, setIsRampFeeLineWillShow] = useState(true);
    const [arrAircraft, setArrAircraft] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [enterQuantity, setEnterQuantity] = useState(false);
    const [fuelRequest, setFuelRequest] = useState("as per request");
    const [isAsPerPilot, setIsAsPerPilot] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [internationalServices, setInternationalServices] = useState([]);
    const [ancillaryService, setAncillaryService] = useState([]);
    const [estimateArrivalTime, setEstimateArrivalTime] = useState(new Date());
    const [estimateDepartureTime, setEstimateDepartureTime] = useState(null);
    const [timeZone, setTimeZone] = useState("");
    const [requestType, setRequestType] = useState(2);
    const [flyingFrom, setFlyingFrom] = useState("");
    const [flyingTo, setFlyingTo] = useState("");
    const [serviceTotalPrice, setServiceTotalPrice] = useState(0);
    const [isEtdTime, setEtdTime] = useState(false);
    const [getFuelType, setGetFuelType] = useState(1)

    useEffect(() => {
        updateList();
        params = props.selectedFboItem;
        waiveOff= '';
        setAircraftTypeName("");
    }, [account.aircraftList, account.assignedAircraftList]);

    const fuelTypeJetA = getFuelType === SafAndJetAType.JET_A_TYPE;
    const fuelTypeSaf = getFuelType === SafAndJetAType.SAF_TYPE;
    const noJetAService = params.isJetAFuel === constants.fuelApplied.noJetA 
    const noSAFService = params.isSAFFuel === constants.fuelApplied.noSaf
    const no100llService = params.is100LLFuel === constants.fuelApplied.noHundredLL
    const noFuelService = noJetAService && no100llService && noSAFService
    const fboFuelType = noFuelService && props.selectedFboItem.listedFboStatus;
    const jetaAircraftSelected = aircraftFuelType === constants.fuelType.JET_A
    const hunredLLAircraftSelected = aircraftFuelType === constants.fuelType.HUNDRED_LL

    const selectedFuelType =
        !(
            (noSAFService && jetaAircraftSelected && fuelTypeSaf) ||
            (noJetAService && jetaAircraftSelected && fuelTypeJetA) ||
            (no100llService && (hunredLLAircraftSelected || aircraftFuelType === constants.fuelType.fuel_type))
        ) && props.selectedFboItem.listedFboStatus;

    useEffect(() => {
        setInternationalServices([]);
        setAircraftTypeName("");
        dispatch(getAircraftList({ token: auth.token }));
        setSubTotal(0);
        getTimeZone();
    }, [auth.token]);

    const getTimeZone = async () => {
        zone = await getTimezoneByLatLong(fbos.fboGeoLoc);
    };

    useEffect(() => {
        if(noJetAService && jetaAircraftSelected) {
            fuelselectedType = SafAndJetAType.SAF_TYPE
        }
        if (gallon === "") {
            setVolume(0);
        } else {
            setVolume(gallon);
        }
        fuelValues()
    }, [
        gallon,
        volume,
        selectedRegistry.name,
        selectedRegistry.registryName,
        pristPriceValue,
        aircraftTypeName,
        getFuelType,
        params,
        aircraftFuelType
    ]);

    useEffect(() => {}, [allAssignedPilots]);

    const updateList = async () => {
        try {
            let finalList = await calculateUpdateList(account, "withoutCustom");
            setArrAircraft(finalList[0]);
            setSectionListData(finalList[1]);
            params.rampFeeType === RampFeeType.SIZE_BASED_RAMPFEE
                ? setwaiveOff()
                : setWaiveOffForOtherFuelType();
        } catch (error) {}
    };

    function setwaiveOff() {
        if (account.aircraftList[0].airplaneDetail.size === "large") {
            waiveOff= params?.waiveOff?.large;
            setWaiveOffType("largeAirplane");
        } else if (account.aircraftList[0].airplaneDetail.size === "medium") {
            waiveOff=params?.waiveOff?.medium;
            setWaiveOffType("mediumAirplane");
        } else {
            waiveOff=params?.waiveOff?.small;
            setWaiveOffType("smallAirplane");
        }
    }

    const setWaiveOffForOtherFuelType = async (obj) => {
        setAircraftFuelType(obj?.airplaneDetail?.fuelType);
        try {
            let getvaluesRelatedToWaiveoff = await setWaiveoff(params, obj );
            parkingfee = getvaluesRelatedToWaiveoff.parkingfee;
            setIsRampFeeLineWillShow(getvaluesRelatedToWaiveoff.setIsRampFeeLineWillShow)
            waiveOff=getvaluesRelatedToWaiveoff.setWaiveOff;
        } catch (error) {};
    };

    const calculateRate = async (volume) => {
        return new Promise(async (resolve, reject) => {
            try {
                let price = await calculatePrice(params, fuelselectedType, volume, getFuelType);
                if(props.selectedFboItem.listedFboStatus) {
                    setrate(price.setrate);
                    setoriginalRate(price.setoriginalRate);
                }
                resolve(price.setrate);
            } catch (error) {
                reject(error);
            }
        })
    };

    const calculateRampFee = async (val) => {
        return new Promise(async (resolve, reject) => {
            try {
                let calculaterampFee = await calculateRateValue(
                    params,
                    waiveOffType,
                    val,
                    isRampFeeLineWillShow,
                    RampFeeType,
                    parkingfee,
                    waiveOff
                );
                if(props.selectedFboItem.listedFboStatus) {
                    setRampFee(calculaterampFee.setRampFee);
                }
                if(val !== 0){
                    setIsRampFeeLineWillShow(calculaterampFee.setIsRampFeeLineWillShow);}
                resolve(calculaterampFee.setRampFee);
            } catch (error) {
                reject(error);
            }
        })
    };

    const getPilots = async (id) => {
        const response = await services.getPilots({
            aircraftId: id,
            token: auth.token,
        });
        if (response?.response_type === "success" && response?.response?.status) {
            setAllAssignedPilots(response?.response?.data);
        }
    };

    const setFuelValues = async (val) => {
        let pristPrice = 0;
        if (isPristPriceEnable) {
            pristPrice = val * pristPriceValue;
        }
        setSubTotal(parseFloat(val, 10) * await calculateRate(val) + await calculateRampFee(val) + pristPrice);
    };

    const getMaskedRate = () =>
        `$${rate !== "" && rate !== undefined ? formatNumWithCommas(rate.toFixed(2)) : 0}/gal`;

    const isDiscount = () => {
        const discountJetAandSafBoth = params.discountFuelType === constants.discount.jetA_Saf_Both;
        const jetA = params.discountFuelType === constants.discount.jet_A;
        const saf = params.discountFuelType === constants.discount.saf;
        const hundredLL = params.discountFuelType === constants.discount.hundredLL;
        const isDiscount = params?.discountCount === 1 ||
            params.operatorRegistryDiscountCount === 1 ||
            params.operatorSpecificDiscountCount === 1 ||
            params.tailWiseDiscountCount === 1
        switch (true) {
            case isDiscount && (discountJetAandSafBoth || jetA || saf || hundredLL):
                switch(true){
                    case fuelTypeJetA && (jetA || discountJetAandSafBoth):
                        return true;
                    case fuelTypeSaf && (discountJetAandSafBoth || saf):
                        return true;
                    case (fuelselectedType === constants.fuelType.HUNDRED_LL) && hundredLL:
                        return true;
                }
            default:
                return false;
        }
    }

    const preferredPrice = () => {
        if(fuelTypeJetA && params.isJetPreferredDiscount){
            return rate;
        } else if(fuelTypeSaf && params.isSAFPreferredDiscount){
            return rate;
        }
        else{ return 0;}
    };

    const onConfirmPaymentInformationPress = async () => {
        let serviceList = [];
        internationalServices.map((item) => {
            serviceList.push(item.value);
        });
        try {
            let rateType = await calculateRateType(params, fuelselectedType, props.selectedFboItem.listedFboStatus);
            const objProps = params;
            const isDiscountValue = isDiscount();
            let data = {};
            const pilotsList = [];
            assignedPilot.map((item) => {
                pilotsList.push(item.pilotDetail.userId);
            });
            if (timeZone === "") {
                zone = await getTimezoneByLatLong(fbos.fboGeoLoc);
            } else {
                zone = timeZone;
            }
            let abc = [];
            data = {
                fboUserId: objProps?.userId,
                aircraftId: selectedRegistry?._id,
                dateOfUplift: estimateArrivalTime === "Invalid Date" ? new Date() : estimateArrivalTime,
                rampFee: rampFee,
                volume: volume,
                message: comment,
                flyingTo: flyingTo,
                isDiscount: isDiscountValue,
                rateType: rateType,
                rate: rate,
                actualRate: originalRate,
                bookPricingMethod: objProps?.pricingMethod,
                isPristFee: isPristPriceEnable,
                pristFee: pristPriceValue,
                fuelType: fuelselectedType,
                isAsPerPilot: noFuelService ? null : isAsPerPilot,
                requestType: requestType,
                isServiceRequest: noFuelService,
                airportTimeZone: zone,
                preferredPricing: preferredPrice(),
                operatorTimeZone: moment.tz.guess(),
            };
            if (objProps.tailWiseDiscountCount === 1 && isDiscountValue) {
                data.isTailWiseDiscount = true;
                data.isRegistryDiscount = false;
                data.isDiscount = false;
                data.isOperatorSpecificDiscount = false;
                data.tailWiseDiscountId = objProps.tailWiseDiscountId;
            } else if (objProps.operatorRegistryDiscountCount === 1 && isDiscountValue) {
                data.isRegistryDiscount = true;
                data.isTailWiseDiscount = false;
                data.isDiscount = false;
                data.isOperatorSpecificDiscount = false;
                data.registryDiscountId = objProps.registryDiscountId;
            } else if (objProps.operatorSpecificDiscountCount === 1 && isDiscountValue) {
                data.isRegistryDiscount = false;
                data.isTailWiseDiscount = false;
                data.isDiscount = false;
                data.isOperatorSpecificDiscount = true;
                data.operatorSpecificDiscountId = objProps.operatorSpecificDiscountId;
            } else if (isDiscountValue) {
                data.isRegistryDiscount = false;
                data.isTailWiseDiscount = false;
                data.isDiscount = true;
                data.isOperatorSpecificDiscount = false;
                data.discountId = objProps.discountId;
                data.operatorDiscountId = objProps.operatorDiscountId;
            }
            if (pilotsList.length > 0) {
                data.pilots = pilotsList;
            }
            let list = [];
            ancillaryService.map((val) => {
                let dataSet = {
                    name: val.label,
                    _id: val.value,
                };
                list.push(dataSet);
            });
            data.ancillaryServices = list;
            data.internationalServices = serviceList;
            if (requestType === 2) {
                data.flyingFrom = flyingFrom;
            }
            if (estimateDepartureTime !== null) {
                data.etd = estimateDepartureTime;
            }
            if (props.selectedFboItem.listedFboStatus) {
                var startTime = new Date(estimateArrivalTime);
                var endTime = new Date(estimateDepartureTime);
                startTime = startTime.setSeconds(startTime.getSeconds() + 30);
                if (estimateDepartureTime !== null && estimateArrivalTime !== "Invalid Date" && startTime > endTime) {
                    setEtdTime(true);
                    toast.info("ETA should be earlier than ETD", {
                        position: "bottom-center",
                        closeButton: false,
                    });
                } else if (estimateArrivalTime === "Invalid Date" && requestType === 2) {
                    toast.info("Please enter ETA", {
                        position: "bottom-center",
                        closeButton: false,
                    });
                } else if (requestType === 2 && estimateArrivalTime === "Invalid Date") {
                    setIsLoading(false);
                    toast.info("Please enter valid ETA", {
                        position: "bottom-center",
                        closeButton: false,
                    });
                } else if (
                    estimateDepartureTime === "Invalid date" ||
                    estimateDepartureTime === "Invalid Date"
                ) {
                    setIsLoading(false);
                    toast.info("Please enter ETD", {
                        position: "bottom-center",
                        closeButton: false,
                    });
                } else {
                    setEtdTime(false);
                    setTimeout(async () => {
                        const response = await services.createBooking({
                            booking: data,
                            token: auth.token,
                        });
                        if (response.response.status === true) {
                            props.closeLayout(
                                true,
                                response.response.data,
                                params,
                                assignedPilot
                            );
                            toast("Arrival Request created successfully.", {
                                position: "bottom-center",
                                closeButton: false,
                            });
                        } else {
                            setIsLoading(false);
                            toast.info(response.response.message, {
                                position: "bottom-center",
                                closeButton: false,
                            });
                        }
                    }, 500);
                }
            } else {
                let departureTime = null;
                let dateofUpLiftNew =
                    moment(estimateArrivalTime).format("YYYY-MM-DD HH:mm");
                if (estimateDepartureTime !== null) {
                    departureTime = moment(estimateDepartureTime).format(
                        "YYYY-MM-DD HH:mm"
                    );
                } else {
                    departureTime = "TBD";
                }
                let tempArray = [];
                if (abc !== undefined && abc.length > 0) {
                    abc.map((item, index) => {
                        tempArray.push(item.name);
                    });
                } else {
                    tempArray = "NA";
                }
                if (estimateArrivalTime === "Invalid Date" && requestType === 2) {
                    setIsLoading(false);
                    toast.info("Please enter ETA", {
                        position: "bottom-center",
                        closeButton: false,
                    });
                } else {
                    let list = [];
                    ancillaryService.map((val) => {
                        list.push(val.label);
                    });
                    dateofUpLiftNew =
                        estimateArrivalTime === "Invalid Date" && requestType === 1? "Aircraft on Ground" : `${moment(estimateArrivalTime).format("YYYY-MM-DD HH:mm")} Local`;
                    const message = `Registry : ${selectedRegistry?.registryName} (${
                        selectedRegistry?.name
                    }) <br/> Gallons: ${
                        volume === 0 || volume === "0" ? "Captain's Request" : volume
                    } <br/> ETA: ${dateofUpLiftNew} <br/> ETD: ${departureTime} ${
                        departureTime === "TBD" ? "" : "Local"
                    } <br/> FuelType: ${
                        hunredLLAircraftSelected ?  "100LL" : jetaAircraftSelected && fuelTypeJetA ? "Jet A" : "SAF"
                    } <br/> ${list.length > 0 ? `Services & Fees: ${list.join(", ")}`:""} <br/> ${
                        comment !== undefined && comment !== ""
                            ? `Comments: ${comment}`
                            : ""
                    }`;
                    const nudgeRequest = {
                        fboId: props.selectedFboItem._id,
                        pilots: pilotsList,
                        message: message,
                    };
                    const response = await services.sendUnlistedNudge({
                        nudge: nudgeRequest,
                        token: auth.token,
                    });
                    if (response.response.status === true) {
                        props.closeLayout(true, "unlistedFbos");
                        toast("Request sent successfully.", {
                            position: "bottom-center",
                            closeButton: false,
                        });
                    } else {
                        setIsLoading(false);
                        toast.info(response.response.message, {
                            position: "bottom-center",
                            closeButton: false,
                        });
                    }
                }
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const setSizeBasedRampfee = (size, planeType) => {
        waiveOff=size;
        setWaiveOffType(planeType);
    };

    const updateData = () => {
        dispatch(
            getRequests({
                token: auth.token,
                record: {
                    page: 1,
                    rowsNumber: 20,
                    sortBy: "",
                    descending: true,
                    search: "",
                    bookingDate: "",
                    status: "",
                },
            })
        );
        dispatch(clearFuelRequestResponse());
    };

    const onRegisterSelection = async (obj, values, setFieldValue) => {
        setGetFuelType(noJetAService ? SafAndJetAType.SAF_TYPE : SafAndJetAType.JET_A_TYPE);
        setAircraftTypeName(obj.name);
        setAircraftFuelType( obj?.airplaneDetail?.fuelType);
        fuelselectedType = obj?.airplaneDetail?.fuelType;
        setSelectedRegistry(obj);
        setAllAssignedPilots([]);
        setisPilotsAsiggnale(
            auth.userType === 4 && obj.operatorName === "Personal Aircraft"
            ? false
            : true
        );
        const getDetailList = await services.getFboDetailList({
            fbo: {
                icaoCode: params?.icaoCode,
                aircraftId: obj?._id,
                fboUserId: params?.userId,
            },
            token: auth.token,
        });
        if (getDetailList.response_type === "success") {
            if (getDetailList.response.data.fbo !== undefined) {
                params = getDetailList.response.data.fbo;
                updateData(getDetailList.response.data.fbo);
            }
        }
        getPilots(obj._id);
        setFieldValue("assinedPilotss", "");
        setFieldValue("registry", obj.registryName);
        if (obj == null) {
            setFieldValue("aircraft", "");
        } else {
            setFieldValue("aircraft", obj?.name);
        }
        if (params.rampFeeType === RampFeeType.SIZE_BASED_RAMPFEE) {
            if (obj?.size === "large") {
                setSizeBasedRampfee(params?.waiveOff?.large, "largeAirplane");
            } else if (obj?.size === "medium") {
                setSizeBasedRampfee(params?.waiveOff?.medium, "mediumAirplane");
            } else {
                setSizeBasedRampfee(params?.waiveOff?.small, "smallAirplane");
            }
        } else {
            setWaiveOffForOtherFuelType(obj);
        }
    };

    const fuelValues = () => {
        if (volume !== undefined) {
            setFuelValues(volume);
        } else {
            setFuelValues(0);
        }
    }

    const handlePristPriceEnableChange = () => {
        if (isPristPriceEnable) {
            setpristPriceValue(0);
        } else {
            setpristPriceValue(fuelTypeJetA ? params?.pristFee : params?.pristFeeSAF);
        }
        setisPristPriceEnable(!isPristPriceEnable);
    };

    const onGallonsChange = (e) => {
        if (
            e.target.validity.valid ||
            e.target.value === "" ||
            e.target.value === 0
        ) {
            setGallon(e.target.value);
        }
    };

    const onFuelRequestSubmit = () => {
        if (selectedRegistry?.name === "" || selectedRegistry?.name === undefined) {
            setIsLoading(false);
            toast.info("Please select aircraft tail type", {
                position: "bottom-center",
                closeButton: false,
            });
        } else if (volume === "" || volume < 0) {
            setIsLoading(false);
            toast.info("Please enter valid gallon.", {
                position: "bottom-center",
                closeButton: false,
            });
        } else if (
            fuelRequest === "enter quantity" &&
            (volume === "0" || volume === 0 || volume < 0)
        ) {
            setIsLoading(false);
            toast.info("Please enter valid gallon.", {
                position: "bottom-center",
                closeButton: false,
            });
        } else if (fuelRequest === "") {
            setIsLoading(false);
            toast.info("Please select arrival request.", {
                position: "bottom-center",
                closeButton: false,
            });
        } else if (isEtdTime === true) {
            toast.info("ETA should be earlier than ETD", {
                position: "bottom-center",
                closeButton: false,
            });
        } else {
            setIsLoading(true);
            onConfirmPaymentInformationPress();
        }
    };

    const enterGallons = (obj) => {
        setIsLoading(false);
        if (obj === 2) {
            setEnterQuantity(true);
            setFuelRequest("enter quantity");
            setIsAsPerPilot(false);
        } else if (obj === 1) {
            setEnterQuantity(false);
            setGallon("");
            setVolume(0);
            setFuelRequest("as per request");
            setIsAsPerPilot(true);
        } else {
            setFuelRequest("");
            setEnterQuantity(false);
        }
    };

    useEffect(() => {
        setAircraftTypeName("");
    }, []);

    const fboArrived = (value) => {
        setIsLoading(false);
        if (value === constants.fboArrived.fboArrived) {
            isFboArrived = false;
            setRequestType(constants.fboArrived.fboArrived);
        } else {
            isFboArrived = true;
            setRequestType(constants.fboArrived.fboNotArrived);
        }
    };

    const selectAncillaryService = (value) => {
        if (value.length > 0) {
            if(!!params.internationalFeeTaxes && params.internationalFeeTaxes.length>0){
                setInternationalServices(value);
                var price = props.selectedFboItem.internationalFeeTaxes.filter((item) => {
                    return value.some((selectedValue) => {
                        return item._id === selectedValue.value;
                    });
                });
                let sum = price.map((el) => el.price).reduce((a, b) => a + b);
                setServiceTotalPrice(sum);
            } else{
                setAncillaryService(value);
            }
        } else {
            setServiceTotalPrice(0);
            setAncillaryService([]);
            setInternationalServices([]);
        }
    };


    const selectedEstimateArrivalTime = (date) => {
        setIsLoading(false);
        if (date === "Invalid date:undefined" || date === "") {
            setEstimateArrivalTime("Invalid Date");
        } else {
            let abc = new Date(date);
            setEstimateArrivalTime(abc);
        }
    };

    const selectedEstimateDepartureTime = (date) => {

        if (date === "Invalid date:undefined") {
            setEtdTime(false);
            setEstimateDepartureTime("Invalid Date");
        } else {
            let abc = new Date(date);
            setEstimateDepartureTime(abc);
            var startTime = new Date(estimateArrivalTime);
            var endTime = new Date(date);
            if (startTime > endTime) {
                setEtdTime(true);
                toast.info("ETA should be earlier than ETD", {
                    position: "bottom-center",
                    closeButton: false,
                });
            } else {
                setEtdTime(false);
            }
        }
    };

    const timezone = (zone) => {
        setTimeZone(zone);
    };

    const handleFlyingFrom = (event) => {
        setFlyingFrom(event.target.value);
    };

    const handleFlyingTo = (event) => {
        setFlyingTo(event.target.value);
    };

    const handleComment = (event) => {
        let value = event.target.value;
        if (value[0] === " " && value[0] !== undefined) {
            setComment("");
        } else {
            setComment(value);
        }
    };

    const changeFuelType = (obj) => {
        setisPristPriceEnable(false);
        setpristPriceValue(0);
        setGetFuelType(obj);
        fuelselectedType=obj;
    };

    return (
        <div className={classes.pageBackground}>
        <div className={classes.main}>
        <div className={classes.mainCont}>
        {!fboFuelType ? (
            <Paper className={classes.paperContainer}>
            <div className={classes.topContainer}>
            {selectedFuelType ? (
                <div className={classes.changeTag}>
                Pricing may change on a weekly basis.
                </div>
            ) : null}
            {selectedFuelType &&
                    Object.values(selectedRegistry).length > 0 ? (
                        <div className={classes.priceCont}>
                        <div className={classes.priceApplyedText}>
                        Price/gal:&nbsp;
                        </div>
                        {(params?.discountCount === 1 ||
                                params.operatorRegistryDiscountCount === 1 ||
                                params.operatorSpecificDiscountCount === 1 ||
                                params.tailWiseDiscountCount === 1 || 
                                params?.tiersSAF?.length > 0 || 
                                params?.tiersJetA?.length > 0) && parseFloat(rate).toFixed(2) !== parseFloat(originalRate).toFixed(2) ? (
                                    <div className={classes.priceApplyed}>
                                    ${formatNumWithCommas(parseFloat(originalRate).toFixed(2))}/gal&nbsp;
                                    </div>
                                ) : null}
                        <div className={classes.originalRateMask}>
                        {`${getMaskedRate()}`}
                        </div>
                        </div>
                    ) : null}
            {selectedFuelType &&
                    Object.values(selectedRegistry).length > 0 ? (
                        <div className={classes.fuelTotalCont}>
                        <div className={classes.fuelTextStyle}>
                        Fuel Total:&nbsp;
                        </div>
                        <div className={classes.fuelTextStyle}>{`$${
                            isNaN(subTotal) ? '0.00' : formatNumWithCommas(parseFloat(subTotal).toFixed(2))
                        }`}</div>
                        </div>
                    ) : null}
            {selectedFuelType ? (
                <div className={classes.fuelTotalCont}>
                <div className={classes.mainAmount}>Subtotal:&nbsp;</div>
                <div className={classes.mainAmount}>{`$${
                    (isNaN(subTotal) || Object.values(selectedRegistry).length === 0)
                        ? '0.00'
                        : formatNumWithCommas(parseFloat(subTotal + serviceTotalPrice).toFixed(2))
                }`}</div>
                </div>
            ) : null}
            {no100llService && hunredLLAircraftSelected && props.selectedFboItem.listedFboStatus && (
                <div className={classes.changeTag}>
                FBO does not provide 100LL type fuel.
                </div>
            )}
            {noJetAService && noSAFService && jetaAircraftSelected && props.selectedFboItem.listedFboStatus && (
                <div className={classes.changeTag}>
                FBO does not provide Jet A and SAF type fuel.
                </div>
            )}
            {!(
                (no100llService && hunredLLAircraftSelected) ||
                (noJetAService && jetaAircraftSelected && fuelTypeJetA) ||
                (noSAFService && jetaAircraftSelected && fuelTypeSaf)) &&
                    props.selectedFboItem.listedFboStatus
                    ? (
                        <div className={classes.changeTag}>
                        (all taxes not included, total subject to change)
                        </div>
                    ) : null}
            </div>
            </Paper>
        ) : (
            <Grid container item xs={12} className={classes.imageCont}>
            {props.selectedFboItem.logo !== undefined &&
                props.selectedFboItem.logo !== null ? (
                    <img
                    src={getImageURLFromAWS(props.selectedFboItem.logo, 300, 150)}
                    alt="logo"
                    className={classes.iconStyle}
                    />
                ) : (
                    <img
                    src={noFboLogo}
                    alt="logo"
                    className={classes.noFboStyle}
                    />
                )}
            </Grid>
        )}
        {fboFuelType ? (
            <div className={classes.textStyle}>
            This FBO is not providing fuel services.
            </div>
        ) : null}
        <Formik
        validationSchema={FuelRequestSchema}
        initialValues={{
            registry: account?.userSelectedAircraft || "",
                aircraft: arrAircraft[0] || "",
                assinedPilotss: [],
        }}
        isInitialValid={false}
        >
        {({
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            isValid,
        }) => {
            return (
                <div className={classes.formCard}>
                <div className={classes.root}>
                <Grid container item xs={11}>
                <>{sectionListData.length > 0 && (
                    <NozlRegistrySelection
                    data={sectionListData}
                    onChange={(obj) => {
                        if (obj !== "") {
                            onRegisterSelection(obj, values, setFieldValue);
                        } else {
                            setSelectedRegistry(
                                account?.userSelectedAircraft || {}
                            );
                            setAircraftTypeName(
                                account?.userSelectedAircraft.name || ""
                            );
                        }
                    }}
                    />
                )}</>
                <>{Object.values(selectedRegistry).length === 0 && (
                    <div className={classes.initialPriceTextStyle}>
                    Please select aircraft for fuel price.
                    </div>
                )}</>
                </Grid>
                <>{!fboFuelType && aircraftFuelType !== constants.fuelType.HUNDRED_LL && !noJetAService && !noSAFService ? (
                    <Grid container item xs={11}>
                    <FuelTypeToggle
                    getFuelType={getFuelType}
                    onFuelChange={(obj) => {
                        changeFuelType(obj)
                    }}
                    />
                    </Grid>
                ) : null}</>
                <>{!fboFuelType ? (
                    <Grid container item xs={11}>
                    <FuelRequestAsPerPilot
                    onChange={(obj) => {
                        enterGallons(obj);
                    }}
                    />
                    </Grid>
                ) : null}</>
                <Grid container item xs={11}>
                {enterQuantity === true ? (
                    <>
                    <CssTextField
                    required
                    id="filled-required"
                    inputVariant="outlined"
                    label="Gallons"
                    placeholder={"Gallons *"}
                    defaultValue=""
                    value={gallon}
                    variant="outlined"
                    autoComplete="off"
                    onChange={onGallonsChange}
                    type="number"
                    InputLabelProps={{
                        classes: {
                            root: classes.label,
                        },
                    }}
                    inputProps={{
                        min: "1",
                            pattern: "[0-9]*",
                    }}
                    className={classes.rightSide}
                    InputProps={{
                        style: { color: "#000" },
                            classes: {
                                notchedOutline: classes.notchedOutline1,
                            },
                    }}
                    />
                    {props.selectedFboItem.listedFboStatus === true &&
                        params.isRampFee === constants.rampFee.isRampFee &&
                        isRampFeeLineWillShow ? (
                            <div className={classes.rampFeeTag1}>
                            {`Request ${waiveOff} gal or more to waive $${formatNumWithCommas(
                                parseFloat(parkingfee).toFixed(2)
                            )} ramp fee`}
                            </div>
                        ) : null}
                    </>
                ) : null}
                </Grid>
                <Grid container item xs={11}>
                <FuelRequestFboArrived
                onChange={(obj) => {
                    fboArrived(obj);
                }}
                />
                </Grid>

                <Grid container item xs={11}>
                <FuelRequestDate
                requestType={requestType}
                isFilterValue={isFboArrived}
                selectedEstimateArrivalTime={
                    selectedEstimateArrivalTime
                }
                selectedEstimateDepartureTime={
                    selectedEstimateDepartureTime
                }
                timezone={timezone}
                />
                </Grid>

                <Grid container item xs={11}>
                <Grid item xs={6}>
                <CssTextField
                id="filled-required"
                disabled={requestType === 1 ? true : false}
                inputVariant="outlined"
                label="Flying From"
                placeholder={"Flying From"}
                defaultValue=""
                value={flyingFrom}
                variant="outlined"
                onChange={(e) => {
                    if (
                        e.target.value.match(
                            "^(?!\\s)[a-zA-Z 0-9_@.,/#&+-]*$"
                        ) != null
                    ) {
                        handleFlyingFrom(e);
                    }
                }}
                className={classes.flyingRightSide}
                InputProps={{
                    style: { color: "#000" },
                        classes: {
                            notchedOutline:
                            requestType === 1
                                ? classes.notchedOutline
                                : classes.notchedOutline1,
                        },
                }}
                />
                </Grid>
                <Grid item xs={6}>
                <CssTextField
                id="filled-required"
                inputVariant="outlined"
                label="Flying To"
                placeholder={"Flying To"}
                defaultValue=""
                value={flyingTo}
                variant="outlined"
                onChange={(e) => {
                    if (
                        e.target.value.match(
                            "^(?!\\s)[a-zA-Z 0-9_@.,/#&+-]*$"
                        ) != null
                    ) {
                        handleFlyingTo(e);
                    }
                }}
                className={`${classes.flyingRightSide} ${classes.flyingDetailFieldMargin}`}
                InputProps={{
                    style: { color: "#000" },
                        classes: {
                            notchedOutline: classes.notchedOutline1,
                        },
                }}
                />
                </Grid>
                </Grid>
                <Grid container item xs={11}>
                {selectedRegistry.registryName !== "" &&
                    isPilotsAsiggnale ? (
                        <NozlPilotSelection
                        data={allAssignedPilots}
                        className={classes.dateArea}
                        onChange={(data) => {
                            setAssignedPilot(data);
                        }}
                        />
                    ) : null}
                </Grid>
                {!fboFuelType && ((params?.isPristFee && fuelTypeJetA) || (params?.isPristFeeSAF && fuelTypeSaf))
                    && aircraftFuelType !== constants.fuelType.HUNDRED_LL && Object.values(selectedRegistry).length? (
                        <Grid container item xs={11}>
                        <FormControlLabel
                        className={classes.checkboxContainer}
                        control={
                            <Checkbox
                            className={classes.checkbox}
                            checked={isPristPriceEnable}
                            onChange={handlePristPriceEnableChange}
                            name="checkedB"
                            color=""
                            />
                        }
                        label={`Add Prist at $${
                            !!params?.pristFee && fuelTypeJetA
                                ? params?.pristFee.toFixed(2)
                                : !!params?.pristFeeSAF && fuelTypeSaf 
                                ? params?.pristFeeSAF.toFixed(2)
                                : 0
                        }/gallon.`}
                        />
                        </Grid>
                    ) : null}
                <Grid container item xs={11}>
                <InternationalServies
                internationalService={
                    params.internationalFeeTaxes?.length >  0 ? params.internationalFeeTaxes : fbos.fboDefaultServices
                }
                onChange={selectAncillaryService}
                />
                {internationalServices.length > 0 ? (
                    <Grid className={classes.servicesPriceStyle}>
                    Services & Fees Total:{" "}
                    {`$${formatNumWithCommas(parseFloat(serviceTotalPrice).toFixed(2))}`}
                    </Grid>
                ) : null}
                </Grid>
                <Grid container item xs={11}>
                <CssCommentTextField
                id="addComment"
                name="addComment"
                label="Comments"
                placeholder="Comments"
                value={comment}
                helperText={
                    <div
                    className={classes.helperText}
                    >{`${comment.length}/1000`}</div>
                }
                variant="outlined"
                multiline
                rowsMax={4}
                onChange={(e) => {
                    handleComment(e);
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.label,
                    },
                }}
                inputProps={{
                    maxLength: 1000,
                }}
                className={classes.commentContainer}
                InputProps={{
                    style: { color: "#000" },
                        classes: {
                            notchedOutline: classes.notchedOutline2,
                        },
                }}
                />
                </Grid>
                </div>
                <div className={classes.buttonContainer}>
                <Button
                disabled={
                    (props.selectedFboItem.listedFboStatus && !fboFuelType &&
                        ((no100llService && hunredLLAircraftSelected) ||
                            (noJetAService && jetaAircraftSelected && fuelTypeJetA) ||
                            (noSAFService && jetaAircraftSelected && fuelTypeSaf))) ||
                    isLoading === true 
                }
                variant="contained"
                className={classes.submitBtnFuel}
                onClick={() => onFuelRequestSubmit()}
                >
                Submit Request
                </Button>
                </div>
                </div>
            );
        }}
        </Formik>
        </div>
        </div>
        </div>
    );
};

export default Fuelrequest;
