import * as actionTypes from "./notifications.action-types";
import { createAction } from "../utils";

export const getNotifications = (notification) =>
  createAction(actionTypes.GET_NOTIFICATIONS.REQUEST, notification);

export const getUnreadNotificationCount = (notification) =>
  createAction(actionTypes.GET_UNREAD_NOTIFICATION_COUNT.REQUEST, notification);

export const getNotificationDetail = (notification) =>
  createAction(actionTypes.GET_NOTIFICATION_DETAIL.REQUEST, notification);

export const markAsReadNotification = (notification) =>
  createAction(actionTypes.MARK_NOTIFICATION_READ.REQUEST, notification);

export const clearNotificationDetailResponse = () =>
  createAction(actionTypes.CLEAR_NOTIFICATION_DETAIL_RESPONSE, {});

export const clearNotificationReadResponse = () =>
  createAction(actionTypes.CLEAR_NOTIFICATION_READ_RESPONSE, {});