import React from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import searchClearIcon from "../../../../assets/images/searchClearIcon.png";
import filterHistory from "../../../../assets/images/filterHistory.png";
import { useStyles } from "../../../../assets/styles/HistoryItemStyles";
import { InputAdornment, TextField } from "@material-ui/core";

const HistoryFilterValue = (props) => {
  const { filteredValue, setFilteredValue, handleChangeSelect, openPickerList, setHistoryFilter, historyFilter, setselectDate } = props;
  const classes = useStyles();

  const handleSelect = (event) => {
    let selectValue = event.target.value
    event.stopPropagation();
    if (
      selectValue === "Show all" ||
      selectValue === "Pending" ||
      selectValue === "Completed" ||
      selectValue === "Cancelled" ||
      selectValue === "Acknowledged" ||
      selectValue === "Expired"
    ) {
      handleChangeSelect(selectValue)
      setHistoryFilter(selectValue)
    } else if (selectValue === undefined || selectValue === "Reset Filters") {
      handleChangeSelect("")
      setHistoryFilter("")
    } else if (selectValue === "Select date") {
      openPickerList(true)
      setFilteredValue("")
      setHistoryFilter("Select date")
    }
  };
  const setValue = () => {
    setselectDate(new Date())
    setHistoryFilter("")
    handleChangeSelect("")
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={classes.formControl}
      classes={{ root: classes.quantityRoot }}
    >
      <TextField
        select={historyFilter === "Select date" ? false : true}
        variant="outlined"
        disableUnderline
        value={
          historyFilter === "Select date" ? moment(props.selectDate).format("MMM/DD/YYYY") : filteredValue
        }
        size="small"
        SelectProps={{
          IconComponent: () => (
            <img
              alt=''
              src={filterHistory}
              style={{ width: 20, height: 20, marginRight: 10 }}
            />
          ),
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null,

          }
        }}
        autoFocus={false}
        onOpen={() => setFilteredValue(filteredValue)}
        label="Filter :"
        onChange={handleSelect}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        InputProps={{
          readOnly: historyFilter === "Select date" ? true : false,
          className: classes.input,
          endAdornment:
            historyFilter === "Select date" ?
              <InputAdornment position="end">
                <img
                  alt=''
                  src={searchClearIcon}
                  style={{
                    width: 30,
                    height: 30,
                    marginRight: -10,
                    cursor: "pointer",
                  }}
                  onClick={setValue}
                />
              </InputAdornment>
              : null
        }}
      >
        <MenuItem disabled className={classes.disableText}>Status</MenuItem>
        <MenuItem value="Show all">Show all</MenuItem>
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Acknowledged">Acknowledged</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
        <MenuItem value="Cancelled">Cancelled</MenuItem>
        <MenuItem value="Expired">Expired</MenuItem>
        <MenuItem disabled className={classes.disableText}>Date</MenuItem>
        <MenuItem value="Select date">Select date</MenuItem>
        <div className={classes.resetButton}>
          <div className={classes.resetButtonText}>Reset</div>
        </div>
      </TextField>
    </FormControl>
  );
};

export default HistoryFilterValue;
