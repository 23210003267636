import { Button, TextField } from "@material-ui/core";
import { useStyles } from '../../../../../assets/styles/PilotProfileStyles';
import colors from "../../../../../constants/colors";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inviteToPilot, getAllInvitedPilots } from "../../../../../redux/pilots/pilots.actions";
import commonClasses from "../../../../../constants/common";

const AddPilotForm = (props) => {
  const { isPopupOpen } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles(colors);
  const commonStyles = commonClasses();
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => ({
    auth: state.auth,
  }));

  const [email, setEmail] = useState("");

  const validateForm = () => {
    var pattern = new RegExp(/^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i);
    return pattern.test(email);
  };

  const onClickButton = () => {
    setLoading(true);
    dispatch(inviteToPilot(email.toLowerCase(), auth.token));
    dispatch(getAllInvitedPilots(auth.token));
    isPopupOpen(false, email);
  }
  
  return (
    <div className={classes.main}>
      <form autoComplete="off" className={classes.form} noValidate onSubmit={e => { e.preventDefault(); }}>
        <div style={{ height: 2 }}></div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          size="small"
          fullWidth
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          label="Email Address"
          name="email"
          autoComplete="off"
          autoFocus
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
            className: classes.floatingLabelFocusStyle,
          }}
          InputProps={{
            style: { color: "#000" },
            classes: {
              notchedOutline: classes.notchedOutline,
              input: classes.inputLine,
              colorSecondary: {
                color: "#000",
              },
            },
            color: "#fff",
          }}
        />
        <Button
          disabled={!validateForm() || loading === true}
          onClick={() => {
            onClickButton()
          }}
          fullWidth
          variant="contained"
          className={commonStyles.submitBtnsmall}
        >
          Add pilot
        </Button>
      </form>
    </div>
  );
};

export default AddPilotForm;
