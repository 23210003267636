import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import star from "../../assets/images/star.png";
import unfilledStar from "../../assets/images/unfilledStar.png";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    left: "33.33%",
    right: '63.61%',
    top: '20.79%',
    bottom: '73.03%',
    display: 'flex',
    alignItems: 'center'
  },
  textStyle: {
    fontSize: 14,
    fontFamily: "Arial",
  }
}));
const UnfilledStar = (props) => {
  return (
    <div>
      <img src={unfilledStar} alt='' style={{ width: props.navigation === 'fboDetail' ? 16 : props.navigation === 'fboItem' ? 13 : 24, height: props.navigation === 'fboDetail' ? 16 : props.navigation === 'fboItem' ? 13 : 24, marginLeft: props.navigation !== 'fromHistory' && 5 }} className={props.style} />
    </div>
  );
};

const FilledStar = (props) => {
  return (
    <div>
      <img src={star} alt='' style={{ width: props.navigation === 'fboDetail' ? 16 : props.navigation === 'fboItem' ? 13 : 24, height: props.navigation === 'fboDetail' ? 16 : props.navigation === 'fboItem' ? 13 : 24, marginLeft: props.navigation !== 'fromHistory' && 5 }} className={props.style} />
    </div>
  );
};

const Star = (props) => {
  const { filled = false, size, style, navigation } = props;

  return (
    <div>
      {filled ? (
        <FilledStar size={size} style={style} navigation={navigation} />
      ) : (
        <UnfilledStar size={size} style={style} navigation={navigation} />
      )}
    </div>
  );
};

const NozlRatingBar = (props) => {
  const classes = useStyles();
  const { size = 52, readOnly = true, rate = props.isUnlisted ? 4 : 0, style, navigation } = props;

  return (
    <div className={style} pointerEvents={readOnly ? "none" : "auto"} style={{ display: 'flex', width: navigation === 'fromHistory' && 200, justifyContent: 'space-between' }}>
      {props.navigation !== 'fromHistory' && <div className={classes.textStyle}
        style={{
          color: navigation === 'fboDetail' ? '#666' : "#333",
          fontWeight: navigation === 'fboDetail' ? 'bold' : 'normal'
        }}>{rate.toFixed(1)}</div>
      }
      <Star filled={rate >= 1} size={size} style={style} navigation={navigation} />
      <Star filled={rate >= 2} size={size} style={style} navigation={navigation} />
      <Star filled={rate >= 3} size={size} style={style} navigation={navigation} />
      <Star filled={rate >= 4} size={size} style={style} navigation={navigation} />
      <Star filled={rate >= 5} size={size} style={style} navigation={navigation} />
    </div>
  );
};

export default NozlRatingBar;