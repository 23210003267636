import React from "react";
import Styled from "styled-components";

const StyledFieldset = Styled.fieldset`
    border: 1px solid black;
    height: 55px;
    width: 100%;
    border-radius: 3px;
`;

const StyledFieldNudgeset = Styled.fieldset`
    border: 2px solid #8E8C8B;
    height: 55px;
    width: 100%;
    border-radius: 3px;
`;

const StyledFieldContactset = Styled.fieldset`
    border: 1px solid #000;
    height: 60px;
    border-radius: 5px;
    width: 100%;
`;

const StyledLegend = Styled.legend`
    font-size: 12px;
    text-align: left;
`;

const StyledServicesLegend = Styled.legend`
    font-size: 12px;
    text-align: left;
    margin-left: 10px;
`;

const StyledFieldServicesset = Styled.fieldset`
    border: 1px solid #000;
    min-height: 56px;
    border-radius: 5px;
    width: 100%;
    padding: 0px;
`;

const FieldSet = (props) => {
  const { legend, children, navigation } = props;

  return (
    <div>
      {!!navigation ? (
        navigation === "fromNudge" ? (
        <StyledFieldNudgeset>
            <StyledLegend>{legend}</StyledLegend>
            {children}
          </StyledFieldNudgeset>
        ) : navigation === "fromContact" ? (
          <StyledFieldContactset>
            <StyledLegend>{legend}</StyledLegend>
            {children}
          </StyledFieldContactset>
        ) : (
          <StyledFieldServicesset>
            <StyledServicesLegend>{legend}</StyledServicesLegend>
            {children}
          </StyledFieldServicesset>
        )
      ) : (
        <StyledFieldset>
          <StyledLegend>{legend}</StyledLegend>
          {children}
        </StyledFieldset>
      )}
    </div>
  );
};

export default FieldSet;
