import React from "react";
import { makeStyles } from "@material-ui/core";
import markerIcon from "../../../../assets/images/markerIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { favouriteFboItem } from "../../../../redux/fbos/fbos.actions";
import "./Marker.css";
import paragonMarker from "../../../../assets/images/paragonMarker.png";
import unregisteredFbo from "../../../../assets/images/unregisteredFbo.png";
import getDistanceFromLatLonInKm from "../../../../utils/map/getDistance";

const useStyles = makeStyles(() => ({
    card: {
        width: 190,
        backgroundColor: "#000",
        border: 10,
        borderRadius: 10,
        marginTop: -100,
        padding: 6,
        justifyContent: "center",
        alignContent: "center",
        zIndex: 1000,
        transform: "translateX(-50%) translateZ(1px)",
        position: "absolute",
    },
    name: {
        fontSize: 16,
        textAlign: "center",
        marginTop: 5,
        marginBottom: 5,
        color: "#fff",
    },
    fboCount: {
        fontSize: 16,
        fontWeight: "bold",
        fontStyle: "normal",
        letterSpacing: 0,
        textAlign: "center",
        color: "#DF2936",
    },
    distance: {
        fontSize: 10,
        color: "#DF2936",
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: 30,
    },
    marker: {
        width: 50,
        height: 50,
        position: "absolute",
        transform: "translateZ(0) translate(-50%, -50%)",
    },
    unregisteredMarker: {
        width: 40,
        height: 40,
        position: "absolute",
        transform: "translateZ(0) translate(-50%, -50%)",
    },
    noFboIconMarker: {
        width: 30,
        height: 30,
        position: "absolute",
        transform: "translateZ(0) translate(-50%, -50%)",
    },
    fboPrice: {
        padding: 2,
        fontSize: 14,
        borderRadius: 4,
        zIndex: 100,
        color: "black",
        backgroundColor: "white",
        fontWeight: "bold",
        position: "absolute",
        transform: "translateZ(0) translate(-50%, -210%)",
    }
}));

const Marker = (props) => {
    const classes = useStyles();
    const {
        item,
        onSelectItem,
        zoom,
        selectedSearchItem,
        latLong,
        userLocation,
    } = props;
    const dispatch = useDispatch();
    const { fbos, account } = useSelector((state) => ({
        fbos: state.fbos,
        account: state.account,
    }));
    const totalFbos = item.totalFboCount;

    const distance = getDistanceFromLatLonInKm(
        { lat: item.latitude, lon: item.longitude },
        {
            lat: Object.values(onSelectItem).length > 0? Object.values(fbos.geoloc).length > 0? fbos.geoloc.latitude : !!userLocation.latitude ?  userLocation.latitude :  37.0902 : latLong.latitude,
            lon: Object.values(onSelectItem).length > 0? Object.values(fbos.geoloc).length > 0? fbos.geoloc.longitude : !!userLocation.longitude ? userLocation.longitude :  -95.7128 : latLong.longitude,
        }
    );

    // on tap of marker get fbo details
    const selectedItem = () => {
        dispatch(favouriteFboItem({}));
        props.selectedFboItem({});
        if (onSelectItem.id !== item.id) {
            props.onClickMarker(item, true);
        } else {
            props.onClickMarker(item, false);
        }
    };

    const getFboPrice = (fbos) => {
        const matchedFbos = fbos.filter((fbo) => {
            return fbo.icaoCode === item.icao;
        });
        if (matchedFbos?.length && props.zoom > 7) {
            let shownPrice = 0;
            const prices = [];
            if (props.is100LLAircraftSelected) {
                matchedFbos.forEach((fbo) => {
                    if (fbo.is100LLFuel) {
                        prices.push(fbo.retailPriceLL);
                    }
                });        
            } else {
                matchedFbos.forEach((fbo) => {
                    if (fbo.isJetAFuel) {
                        prices.push(fbo.retailPrice);
                    }
                    if (fbo.tiersJetA.length) {
                        prices.push(fbo.tiersJetA[0].retailPrice)
                    }
                });        
            }
            shownPrice = prices.length && Math.min(...prices);
            if (shownPrice) {
                return (
                     <div className={classes.fboPrice}>${shownPrice.toFixed(2)}</div>
                ); 
            }
            return null;
        }
        return null;
    };

    // show airport details on marker
    const Detail = () => {
        return (
            <div
            className={totalFbos === 0 ? "bubble noRegisteredBubble" : "bubble"}
            key={item.id}
            onClick={selectedItem}
            style={{ zIndex: 99 }}
            >
            <div className={classes.id}>{item.id}</div>
            <div className={classes.name}>
            {item.name} ({item.icao})
            </div>
            <div className={classes.fboCount}>{`${totalFbos} ${
                totalFbos > 1 ? "FBOs" : "FBO"
            }`}</div>
            <div className={classes.distance}>{`${distance.toFixed(2)} miles`}</div>
            </div>
        );
    };

    return (
        <div>
        <div onClick={selectedItem} className={item.id}>
        {item.nozlFboCount === 0 &&
            item.paragonFboCount === 0 &&
            item.unregisteredFboCount !== 0 &&
            zoom > 5 &&
            !account.isParagon ? (
                <img
                src={unregisteredFbo}
                className={classes.unregisteredMarker}
                alt={item.name}
                title={item.name}
                />
            ) : totalFbos === 0 ? null : item.nozlFboCount !== 0 ||
            item.paragonFboCount !== 0 ? (
                <div>
                    {getFboPrice(fbos.fbos)}
                    <img
                        src={item.paragonFboCount !== 0 
                        ? item.isSAF 
                        ? '/assets/safParagonMarkerIcon.png' 
                        : paragonMarker
                        : item.isSAF 
                        ? '/assets/safNozlMarkerIcon.png'
                        : markerIcon}
                    className={classes.marker}
                    alt={item.name}
                    title={item.name}
                    />
                </div>
            ) : null}
        <div>
        {!!onSelectItem.id &&
            onSelectItem.id === item.id &&
            Object.values(selectedSearchItem).length > 0 ? (
                <Detail />
            ) : null}
        </div>
        </div>
        </div>
    );
};

export default Marker;
