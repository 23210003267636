import React from "react";
import { useHistory } from "react-router-dom";

import AppLayout from '../Layout/AppLayout';
import { StyledContainer } from '../History';
import PilotProfileComponent from "../../components/scene/app/settings/ManagePilots/PilotProfile";
import NozlHeadings from "../../components/ui/NozlHeadings";

export default function ManagePilots(props) {
  const history = useHistory();

  const goBack = (value) => {
    if (value === true) {
      history.goBack()
    }
  }

  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <AppLayout>
        <NozlHeadings title="Pilot Profile" goBack={true} onClickBack={goBack} />
        <PilotProfileComponent location={props.location} />
      </AppLayout>
    </StyledContainer>
  );
}
