import { createAction } from "../utils";
import * as actionTypes from "./fbos.action-types";

export const getFbos = (payload) =>
  createAction(actionTypes.GET_FBOS.REQUEST, payload);
export const getFboDetailList = (payload) =>
  createAction(actionTypes.GET_FBO_DETAIL_LIST.REQUEST, payload);
export const sendNudge = (payload) =>
  createAction(actionTypes.SEND_NUDGE.REQUEST, payload);
export const inviteFbo = (invite) =>
  createAction(actionTypes.INVITE_FBO.REQUEST, invite);
export const sendUnlistedNudge = (payload) =>
  createAction(actionTypes.SEND_UNLISTED_NUDGE.REQUEST, payload);
export const fboSearchText = (payload) =>
  createAction(actionTypes.SEARCH_FBO_TEXT, payload);
export const getFavouriteFbos = (payload) =>
  createAction(actionTypes.FAVOURITE_FBOS.REQUEST, payload);
export const favouriteFboItem = (payload) =>
  createAction(actionTypes.FAVOURITE_FBO_ITEM, payload);
export const getGeoloc = (payload) =>
  createAction(actionTypes.SAVE_GEOLOC, payload);
export const markFavoriteFbo = (payload) =>
  createAction(actionTypes.ADD_FAVORITE_FBO.REQUEST, payload);
export const unmarkFavoriteFbo = (payload) =>
  createAction(actionTypes.UNMARK_FAVORITE_FBO.REQUEST, payload);
export const getFboGeoloc = (payload) =>
  createAction(actionTypes.SAVE_FBO_GEOLOC, payload);
export const getFboDefaultServices = (payload) => 
  createAction(actionTypes.FBO_DEFAULT_SERVICES.REQUEST, payload);
