import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Box,
  makeStyles,
  Button,
  Link,
} from "@material-ui/core";

import TextField from "../../../controls/Input";
import textStyles from "../../../../constants/fonts";
import colors from "../../../../constants/colors";
import { contactAdmin } from "../../../../redux/account/account.actions";
import { ValidationTextField } from "../../../../assets/styles/CurrentStyles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft: 20,
  },
  emailCont: {
    marginTop: 20,
  },
  textColor: {
    color: "grey",
  },
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const textClasses = textStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const { account, auth } = useSelector((state) => ({
    account: state.account,
    auth: state.auth,
  }));

  useEffect(() => {
    data();
  }, [account]);

  const data = () => {
    setName(account?.user?.name);
    setEmail(account?.user?.email);
  };

  const submitAllDetails = () => {
    const body = {
      message: message,
    };
    dispatch(contactAdmin({ token: auth.token, body }));
    setMessage("");
  };

  const onContactAdminClick = () => {
    let mailId = "";
    if (account?.isParagon === true) {
      mailId = "preferred@paragonaviationgroup.com";
    } else {
      mailId = "support@nozlapp.com";
    }
    window.open(`mailto:${mailId}`);
  };

  const handleMessage = (event) => {
    let value = event.target.value;
    if (value[0] === " " && value[0] !== undefined) {
      setMessage("");
    } else {
      setMessage(value);
    }
  };

  return (
    <Grid container item xs={12} className={classes.mainContainer}>
      <Grid container item xs={6}>
        <Grid item xs={12} style={{ margin: "0px 0px 20px 0px" }}>
          <Box mx={3}>
            <Typography
              className={`${textClasses.heading3} ${textClasses.colorGray}`}
            >
              Name
            </Typography>
            <TextField
              disabled
              className={classes.textColor}
              theme="black"
              id="name"
              value={name}
              placeholder="Name"
            />
          </Box>
        </Grid>

        <Grid item xs={12} style={{ margin: "0px 0px 20px 0px" }}>
          <Box mx={3}>
            <Typography
              className={`${textClasses.heading3} ${textClasses.colorGray}`}
            >
              Email
            </Typography>
            <TextField
              disabled
              className={classes.textColor}
              theme="black"
              id="email"
              value={email}
              placeholder="Email"
            />
          </Box>
        </Grid>

        <Grid item xs={12} style={{ margin: "0px 0px 20px 0px" }}>
          <Box mx={3}>
            <Typography
              className={`${textClasses.heading3} ${textClasses.colorGray}`}
            >
              Message*
            </Typography>
            <ValidationTextField
              variant="outlined"
              className={classes.margin}
              placeholder="Message"
              required
              size="medium"
              fullWidth
              id="message"
              multiline
              rows={3}
              value={message}
              helperText={`${message.length}/500`}
              onChange={(event) => {
                handleMessage(event);
              }}
              inputProps={{
                maxLength: 500,
              }}
              name="message"
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  focused: classes.focusedLabel,
                  error: classes.erroredLabel,
                },
                className: classes.floatingLabelFocusStyle,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} style={{ margin: "0px 0px 20px 0px" }}>
          <Box mx={14}>
            <Button
              disabled={message.length <= 1}
              onClick={() => submitAllDetails()}
              fullWidth
              className={classes.submitBtn}
              disableElevation
            >
              SEND
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          item
          xs={12}
          className={classes.emailCont}
          style={{ display: "flex", padding: "20px 0px 20px 20px" }}
        >
          <Grid style={{ fontWeight: "bold" }}>Customer Support : &nbsp;</Grid>
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              onContactAdminClick();
            }}
          >
            {account?.isParagon === true ? "preferred@paragonaviationgroup.com" : "support@nozlapp.com"}
          </Link>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ display: "flex", padding: "0px 0px 20px 20px", height: 50 }}
        >
          <Grid style={{ fontWeight: "bold" }}>
            Customer Support Phone number: &nbsp;
          </Grid>
          <Grid>{account?.isParagon === true ? "+1 (877) 545-4724" : "+1 (301) 202-1583"}</Grid>
        </Grid>

      </Grid>
    </Grid>
  );
};

export default ContactUs;
