import { makeStyles } from "@material-ui/core/styles";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  firstCont: {
    width: 440,
    height: 370,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 30,
    padding: "40px 50px",
    borderRadius: 10,
    boxShadow:
      "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(0, 0, 0, 0.1)",
    background: "#fff",
  },
  firstInnerCont: {
    width: 320,
    height: 172,
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  thankyouStyle: {
    height: 32,
    flexGrow: 0,
    margin: "20px 10px 10px",
    fontFamily: "Arial",
    fontSize: 24,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "-0.25px",
    textAlign: "center",
    color: "#333",
  },
  textStyle: {
    width: 320,
    height: 40,
    flexGrow: 0,
    margin: "10px 0 0",
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#666",
  },
  secondInnerCont: {
    width: 320,
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    padding: 0,
  },
  okButtonStyle:{
    width: 320,
    flexGrow: 0,
    height:40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    color:'#fff',
    marginTop: 30,
    borderRadius:5,
    cursor:'pointer',
    background: colors.paragonPrimaryColor
  },
  textStyle2: {
    width: 320,
    height: 20,
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#999",
    marginTop: 20
  },
  imageCont: {
    alignSelf: "stretch",
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 30,
    padding: 0,
  },
  imageStyle: {
    width: 28,
    height: 28,
    flexGrow: 0,
    margin: "0 30px 0 0",
    cursor: 'pointer'
  },
  submitImageStyle: {
    width: 70,
    height: 70,
  },
  imageInstaStyle: {
    width: 28,
    height: 28,
    borderRadius: 8,
    margin: "0 0 0 30px",
    backgroundImage:
      "radial-gradient(circle at 69% 95%, rgba(140, 58, 170, 0) 61%, #8c3aaa 95%)",
    cursor: 'pointer'
  },
  youTubeStyle: {
    width: 28,
    height: "19.7px",
    backgroundColor: "#df2935",
    borderRadius: 5,
    cursor: 'pointer'
  },
}));

export { useStyles };