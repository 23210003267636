import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../../../constants/colors";

import AirportSearchResultList from "./AirportSearchResultList";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    zIndex: 9,
  },
  rootHead: {
    backgroundColor: colors.denim,
  },
  noData: {
    position: "absolute",
    width: "403px",
    left: 30,
    top: 80,
    textAlign: "center",
    padding: 30,
    zIndex: 9999,
    height: "calc(50vh - 200px)",
    backgroundColor: "#fff",
    borderRadius: 8,
  },
}));

const AirportSearchScreen = (props) => {
  const classes = useStyles();
  const {getTextValue, airportItemValue} = props;

  const { fbos, account } = useSelector((state) => ({
    fbos: state.fbos,
    account: state.account,
  }));
  const [, setIsFilter] = React.useState(false);

  useEffect(() => {
    if (
      account?.advanceSearch !== undefined &&
      account?.advanceSearch.length > 0
    ) {
      setIsFilter(true);
    }
  }, [account?.advanceSearch, props]);

  const selectedItem = (item, title) => {
    props.selectedItem(item, title);
    props.selectedFboItem({});
  };
  const selectedFboItem = (item) => {
    props.selectedFboItem(item);
  };

  const getCityLat = (value, description) => {
    props.getCityLat(value, description);
  };

  const closeList = (value) => {
    if (value === true) {
      props.closeList(true);
    }
  };

  const showAirportList = (value)=>{
    value === true && props.showAirportList(true);
  }

  return (
    <div className={classes.root}>
      {(airportItemValue !== undefined && airportItemValue.length > 0) ||
      (!!account.searchHistoryList && (Object.values(account.searchHistoryList).length > 0 ||
      account.searchHistoryList.length > 0)) ? (
        <AirportSearchResultList
          showAirportList={showAirportList}
          selectedItem={selectedItem}
          selectedFboItem={selectedFboItem}
          getTextValue={getTextValue}
          fbos={fbos.fbos}
          closeList={closeList}
          unListedfbos={props?.unListedfbos || []}
          airportItemValue={getTextValue === "onChangeText" ? airportItemValue :  account.searchHistoryList}
          getCityLat={getCityLat}
        />
      ) : null}
    </div>
  );
};

export default AirportSearchScreen;