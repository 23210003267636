import getDistance from "../../../../utils/map/getDistance";
import { FuelType } from "../../../../config";
import algoliasearch from "algoliasearch";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_APPLICATION_KEY
);

const searchIndex = searchClient.initIndex(
  process.env.REACT_APP_ALGOLIA_INIT_INDEX
);
let fboAiportList = [];

export const getSearchResult = async (
  searchVal,
  fbos,
  unListedFbos,
  searchableAttributes,
  userSelectedAircraft,
  currentLocation,
  discounts,
  userId,
  userData,
  googleData,
  token,
  paragonUser
) => {
  return Promise.all([
    await searchAlgoliaIndices(searchVal, searchableAttributes),
  ])
    .then((result) => {
      return categorizeSearchResults(
        searchVal,
        result,
        fbos,
        unListedFbos,
        searchableAttributes,
        userSelectedAircraft,
        currentLocation,
        discounts,
        userId,
        userData,
        googleData,
        token,
        paragonUser
      );
    })
    .catch((error) => {
      return [];
    });
};

const categorizeSearchResults = async (
  searchVal,
  resultArr,
  fbos,
  unListedFbos,
  searchableAttributes,
  userSelectedAircraft,
  currentLocation,
  discounts,
  userId,
  userData,
  googleData,
  token,
  paragonUser
) => {
  try {
    let finalDetails = [];
    if (resultArr && resultArr.length && resultArr[0]) {
      fboAiportList = mergeFbosAirports(
        resultArr[0]?.hits || [],
        fbos,
        unListedFbos,
        userSelectedAircraft
      )?.map((result) => {
        return {
          ...result,
          discounts: discounts,
          userId: userId,
          userData: userData,
          distance: getDistance(
            { lat: result.latitude, lon: result.longitude },
            {
              lat: currentLocation.latitude,
              lon: currentLocation.longitude,
            }
          ).toFixed(2),
        };
      });
      if (searchVal) {
        fbos.filter((e) => {
          if (e.isParagonUser === true) {
            return e;
          }
        });
        //iata
        if (
          searchableAttributes.length > 0 &&
          searchableAttributes !== undefined &&
          Object.values(searchableAttributes).length > 0
        ) {
          searchableAttributes.map(async (item) => {
            if (item.key === "iata") {
              let iata = await searchResult(
                "iata",
                searchVal,
                fbos,
                searchableAttributes,
                paragonUser
              );
              if (iata.data.length) {
                finalDetails.push(iata);
              }
            }
            if (item.key === "icao") {
              let icao = await searchResult(
                "icao",
                searchVal,
                fbos,
                searchableAttributes,
                paragonUser
              );
              if (icao.data.length) {
                finalDetails.push(icao);
              }
            }
            if (item.key === "name") {
              let airport = await searchResult(
                "airport",
                searchVal,
                fbos,
                searchableAttributes,
                paragonUser
              );
              if (airport.data.length) {
                finalDetails.push(airport);
              }
            }
            if (item.key === "city") {
              const cities = {
                title: "Locations",
                index: 4,
                data: googleData.map((e) => {
                  return {
                    ...e,
                    key: e.place_id,
                    sectionType: 5,
                  };
                }),
              };
              if (cities.data.length) {
                finalDetails.push(cities);
              }
            }
            if (item.key === "fbo") {
              let fboList = await fboListResult(
                searchVal,
                fbos,
                unListedFbos,
                paragonUser
              );
              if (Object.values(fboList).length > 0 && fboList.data.length) {
                fboList.data = fboList.data.sort((a, b) => {
                  if (!!a.isParagonUser === !!b.isParagonUser) return 0;
                  if (!!a.isParagonUser) return -1;
                  return 1;
                });
                finalDetails.push(fboList);
              }
            }
            finalDetails.sort((a, b) => {
              if (a.index < b.index) return -1;
              if (a.index > b.index) return 1;
              return 0;
            });
          });
        }
        if (
          Object.values(searchableAttributes).length === 0 ||
          searchableAttributes.length === 0
        ) {
          //iata
          let iata = await searchResult(
            "iata",
            searchVal,
            fbos,
            searchableAttributes,
            paragonUser
          );
          if (iata.data.length) {
            finalDetails.push(iata);
          }
          //icao
          let icao = await searchResult(
            "icao",
            searchVal,
            fbos,
            searchableAttributes,
            paragonUser
          );
          if (icao.data.length) {
            finalDetails.push(icao);
          }
          //airports
          let airport = await searchResult(
            "airport",
            searchVal,
            fbos,
            searchableAttributes,
            paragonUser
          );
          if (airport.data.length) {
            finalDetails.push(airport);
          }
          //google address
          const cities = {
            title: "Locations",
            data: googleData.map((e) => {
              return {
                ...e,
                key: e.place_id,
                sectionType: 5,
              };
            }),
          };
          if (cities.data.length) {
            finalDetails.push(cities);
          }
          //fbo
          let fboList = await fboListResult(
            searchVal,
            fbos,
            unListedFbos,
            paragonUser
          );
          if (Object.values(fboList).length > 0 && fboList.data.length) {
            fboList.data = fboList.data.sort((a, b) => {
              if (!!a.isParagonUser === !!b.isParagonUser) return 0;
              if (!!a.isParagonUser) return -1;
              return 1;
            });
            finalDetails.push(fboList);
          }
        }
      }
    }
    return finalDetails;
  } catch (error) {}
};

const searchAlgoliaIndices = async (searchVal, searchableAttributes) => {
  try {
    let result = await searchIndex.search(searchVal, {
      hitsPerPage: 20,
    });
    return result;
  } catch (error) {
    return [];
  }
};

export const mergeFbosAirports = (
  airports,
  fbos,
  unListedfbos,
  userSelectedAircraft
) => {
  const fuelType = userSelectedAircraft?.airplaneDetail?.fuelType || 0;
  if (fuelType === FuelType.HUNDRED_LL_FUELTYPE) {
    return airports.map((airport) => {
      const fbosInAirport = fbos?.filter(
        (fbo) =>
          fbo?.fboDetail?.icaoCode === airport.icao &&
          fbo?.fboDetail?.is100LLFuel === 1
      );
      const unListedfbosAirport = unListedfbos.filter(
        (unListedfbo) => unListedfbo?.icaoCode === airport.icao
      );
      return {
        ...airport,
        fbos: fbosInAirport || [],
        unListedfbos: unListedfbosAirport || [],
      };
    });
  } else {
    return airports.map((airport) => {
      const fbosInAirport = fbos.filter(
        (fbo) => fbo?.fboDetail?.icaoCode === airport.icao
      );
      const unListedfbosAirport = unListedfbos.filter(
        (unListedfbo) => unListedfbo?.icaoCode === airport.icao
      );
      return {
        ...airport,
        fbos: fbosInAirport || [],
        unListedfbos: unListedfbosAirport || [],
      };
    });
  }
};

export const airportList = (airport, fbos) => {
  return new Promise((resolve, reject) => {
    try {
      let list = [];
      let fbo = [];
      list = fbos.fbos.filter((e) => {
        if (e.isParagonUser === true) {
          return e;
        }
      });
      airport.map((item) => {
        for (var i = 0; i < list.length; i++) {
          if (
            list[i].fboDetail !== undefined &&
            list[i].fboDetail.icaoCode === item.icao
          ) {
            fbo.push(
              Object.assign(item, {
                isParagonUser:
                  list[i].isParagonUser === undefined
                    ? false
                    : list[i].isParagonUser,
              })
            );
            break;
          }
        }
      });
      airport.map((item) => {
        if (item.isParagonUser === undefined) {
          Object.assign(item, {
            isParagonUser: false,
          });
        }
      });
      airport.sort(function (x, y) {
        const xUser = x.isParagonUser;
        const yUser = y.isParagonUser;
        return yUser - xUser;
      });
      resolve(airport);
    } catch (error) {
      resolve([]);
    }
  });
};

export const isParagonNozlAirport = (airports, fbos, paragonUser) => {
  return new Promise((resolve, reject) => {
    try {
      //listed fbos
      for (let fbo of fbos) {
        for (let airport of airports) {
          if (
            !airport.isParagon &&
            fbo?.fboDetail?.icaoCode === airport.icao &&
            fbo?.isParagonUser
          ) {
            airport.isParagonUser = true;
          }
        }
      }
      let neww = airports.sort(function (x, y) {
        const xLength = x.fbos?.length + x.unListedfbos?.length;
        const yLength = y.fbos?.length + y.unListedfbos?.length;
        return yLength - xLength;
      });
      neww.sort((a, b) => {
        if (!!a.isParagonUser === !!b.isParagonUser) return 0;
        if (!!a.isParagonUser) return -1;
        return 1;
      });
      resolve(neww);
    } catch (error) {
      resolve(airports);
    }
  });
};

export const isParagonAirport = (airports, fbos, isSort) => {
  return new Promise((resolve, reject) => {
    let paragonAiports = [];
    try {
      for (let fbo of fbos) {
        for (let airport of airports) {
          if (
            !airport.isParagonUser &&
            fbo?.fboDetail?.icaoCode === airport.icao &&
            fbo?.isParagonUser
          ) {
            airport.isParagonUser = true;
            paragonAiports.push(airport);
          }
        }
      }
      if (isSort) {
        paragonAiports.sort(function (x, y) {
          const xLength = x.fbos?.length + x.unListedfbos?.length;
          const yLength = y.fbos?.length + y.unListedfbos?.length;
          return yLength - xLength;
        });
        paragonAiports.sort((a, b) => {
          if (!!a.isParagonUser === !!b.isParagonUser) return 0;
          if (!!a.isParagonUser) return -1;
          return 1;
        });
      }
      resolve(paragonAiports);
    } catch (error) {
      resolve(paragonAiports);
    }
  });
};

const searchResult = async (
  val,
  searchVal,
  fbos,
  searchableAttributes,
  paragonUser
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let searchValue = [];
      searchableAttributes.length > 0 &&
        searchableAttributes.map((e) => {
          return searchValue.push(e.key);
        });
      //listed fbos
      let results = fboAiportList
        .filter((e) => {
          return val === "iata"
            ? searchValue.length > 0
              ? searchValue.includes("iata") &&
                e.iata?.toLowerCase()?.includes(searchVal?.toLowerCase())
              : e.iata?.toLowerCase()?.includes(searchVal?.toLowerCase())
            : val === "icao"
            ? searchValue.length > 0
              ? searchValue.includes("icao") &&
                e.icao?.toLowerCase()?.includes(searchVal?.toLowerCase()) &&
                !(
                  searchValue.includes("iata") &&
                  e.iata?.toLowerCase()?.includes(searchVal.toLowerCase())
                )
              : e.icao?.toLowerCase()?.includes(searchVal?.toLowerCase()) &&
                !e.iata?.toLowerCase()?.includes(searchVal.toLowerCase())
            : searchValue.length > 0
            ? searchValue.includes("name") &&
              (e.name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                e.city?.toLowerCase()?.includes(searchVal.toLowerCase()))
            : e.name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
              e.city?.toLowerCase()?.includes(searchVal.toLowerCase());
        })
        .map((e) => {
          return {
            ...e,
            key: e.id,
            subTitle: e.iata,
            sectionType: val === "iata" ? 1 : val === "icao" ? 2 : 3,
            fboCount: e.fbos?.length + e?.unListedfbosAirport?.length,
          };
        })
        .sort((a, b) => {
          return b.fboCount - a.fboCount;
        });
      const iata = {
        title: val === "iata" ? "IATA" : val === "icao" ? "ICAO" : "Airport",
        index: val === "iata" ? 1 : val === "icao" ? 2 : 3,
        data: await isParagonNozlAirport(results, fbos, paragonUser),
      };

      resolve(iata);
    } catch (error) {
      resolve([]);
    }
  });
};

const fboListResult = async (searchVal, fbos, unListedFbos, paragonUser) => {
  return new Promise(async (resolve, reject) => {
    try {
      let resultFbo =
        fbos
          ?.filter((fbo) =>
            fbo?.fboDetail?.fboName
              ?.toLowerCase()
              ?.includes(searchVal.toLowerCase())
          )
          .map((e) => {
            return {
              ...e,
              key: e?.fboDetail?.fboId,
              isUnlisted: false,
              title: e?.fboDetail?.fboName || "",
            };
          }) || [];
      const resultUnlistedFbo =
        unListedFbos
          ?.filter((fbo) =>
            fbo?.fboName?.toLowerCase()?.includes(searchVal.toLowerCase())
          )
          .map((e) => {
            return {
              ...e,
              key: e?._id,
              isUnlisted: true,
              title: e?.fboName || "",
            };
          }) || [];
      let fboResult = {
        title: "FBO",
        index: 5,
        data: [...resultFbo, ...resultUnlistedFbo].map((e) => {
          return {
            ...e,
            sectionType: 4,
          };
        }),
      };
      resolve(fboResult);
    } catch (error) {
      return resolve([]);
    }
  });
};
