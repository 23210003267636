import { put, call, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./pilots.action-types";
import * as services from "../../services/mainService";
import { toast } from "react-toastify";
import * as authActionTypes from "../auth/auth.actionTypes";


function showmsg(msg) {
  setTimeout(() => {
    if (msg !== undefined && msg !== "") {
    }
  }, 80);
}

// get pilot list
function* getInvitePilotsList(action) {
  const apiResponse = yield call(services.getInvitesPilotsList, action.payload);
  yield put({
    type: actionTypes.REMOVE_PROGRESS,
  });

  if (apiResponse?.response_type === "success") {
    yield put({
      type: actionTypes.INVITE_PILOTS_LIST.SUCCESS,
      payload: apiResponse.response.data,
    });
  } else {
    showmsg(apiResponse?.response?.message);
    yield put({
      type: actionTypes.INVITE_PILOTS_LIST.FAILURE,
      payload: apiResponse.response,
    });
  }
}

function* getInvitePilotsListSuccess(action) { }

function* getInvitePilotsListFailure(action) { }

// get assigned aircraft list
function* getAssignedAircraft(action) {
  const apiResponse = yield call(
    services.getAssingedAircraftList,
    action.payload
  );
  if (apiResponse?.response_type === "success") {
    yield put({
      type: actionTypes.ASSIGNED_AIRCRAFT_LIST.SUCCESS,
      payload: apiResponse.response.data,
    });
  } else {
    showmsg(apiResponse?.response?.message);
    yield put({
      type: actionTypes.ASSIGNED_AIRCRAFT_LIST.FAILURE,
      payload: apiResponse.response,
    });
  }
}

function* getAssignedAircraftSuccess(action) { }

function* getAssignedAircraftFailure(action) { 
  if(action.payload.message.includes("User not found") || action.payload.message === "Invalid Token"){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
}

// get selected aircraft list
function* selectedAirCraftList(action) {
  const apiResponse = yield call(
    services.getAssingedAircraftList,
    action.payload
  );
  if (apiResponse?.response_type === "success") {
    yield put({
      type: actionTypes.SELECTED_AIRCRAFT_LIST.SUCCESS,
      payload: apiResponse.response.data,
    });
  } else {
    showmsg(apiResponse?.response?.message);
    yield put({
      type: actionTypes.SELECTED_AIRCRAFT_LIST.FAILURE,
      payload: apiResponse.response,
    });
  }
}

function* selectedAirCraftListSuccess(action) { }

function* selectedAirCraftListFailure(action) {
  if(action.payload.message.includes("User not found") || action.payload.message === "Invalid Token"){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
 }

function* invitePilots(action) {
  const apiResponse = yield call(services.invitePilots, action.payload);
  yield put({
    type: actionTypes.REMOVE_PROGRESS,
  });
  if (apiResponse?.response_type === "success") {
    apiResponse.response.action = action;
    toast(apiResponse?.response?.message, { position: "bottom-center", closeButton: false });
    yield put({
      type: actionTypes.INVITE_PILOT.SUCCESS,
      payload: apiResponse.response,
    });
  } else {
    toast.info(apiResponse?.response?.message, { position: "bottom-center", closeButton: false });
    yield put({
      type: actionTypes.INVITE_PILOT.FAILURE,
      payload: apiResponse.response,
    });
  }
}

function* invitePilotsSuccess(action) {
  yield put({
    type: actionTypes.INVITE_PILOTS_LIST.REQUEST,
    payload: { token: action.payload.action.payload.token },
  });
}

function* invitePilotsFailure(action) {
  if(action.payload.message.includes("User not found") || action.payload.message === "Invalid Token"){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
 }

// Assign Aicraft to pilots
function* assignAirCraftPilot(action) {
  const apiResponse = yield call(
    services.assignedAircraftPilot,
    action.payload
  );
  yield put({
    type: actionTypes.REMOVE_PROGRESS,
  });
  if (apiResponse?.response_type === "success") {
    toast(apiResponse?.response?.message, { position: "bottom-center", closeButton: false });
    yield put({
      type: actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.SUCCESS,
      payload: apiResponse.response.data,
    });
    yield put({
      type: actionTypes.ASSIGNED_AIRCRAFT_LIST.REQUEST,
      payload: {
        pilotUserId: action.payload.pilotUserId,
        token: action.payload.token,
      },
    });
  } else {
    toast.info(apiResponse?.response?.message, { position: "bottom-center", closeButton: false });
    yield put({
      type: actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.FAILURE,
      payload: apiResponse.response,
    });
  }
}

function* assignAirCraftPilotSuccess(action) { }

function* assignAirCraftPilotFailure(action) {
  // if(action.payload.message === "User not found."){
  //   yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  // }
 }

// Remove pilot from pilot list
function* removePilot(action) {
  const apiResponse = yield call(services.removePilots, action.payload);
  if (apiResponse?.response_type === "success") {
    toast(apiResponse?.response?.message, { position: "bottom-center", closeButton: false });
    yield put({
      type: actionTypes.REMOVE_PILOT_BY_ID.SUCCESS,
      payload: action.payload,
    });
  } else {
    toast.info(apiResponse?.response?.message, { position: "bottom-center", closeButton: false });
    yield put({
      type: actionTypes.REMOVE_PILOT_BY_ID.FAILURE,
      payload: action.payload,
    });
  }
}

function* removePilotSuccess(action) {
  yield put({
    type: actionTypes.INVITE_PILOTS_LIST.REQUEST,
    payload: action.payload,
  });
}

function* removePilotFailure(action) {
  yield put({
    type: actionTypes.INVITE_PILOTS_LIST.REQUEST,
    payload: action.payload,
  });
  if(action.payload.message.includes("User not found") || action.payload.message === "Invalid Token"){
    yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
  }
}

export function* pilotsSaga() {
  // get assigned aircraft list
  yield takeLatest(
    actionTypes.ASSIGNED_AIRCRAFT_LIST.REQUEST,
    getAssignedAircraft
  );
  yield takeLatest(
    actionTypes.ASSIGNED_AIRCRAFT_LIST.SUCCESS,
    getAssignedAircraftSuccess
  );
  yield takeLatest(
    actionTypes.ASSIGNED_AIRCRAFT_LIST.FAILURE,
    getAssignedAircraftFailure
  );

  // get pilot list
  yield takeLatest(actionTypes.INVITE_PILOTS_LIST.REQUEST, getInvitePilotsList);
  yield takeLatest(
    actionTypes.INVITE_PILOTS_LIST.SUCCESS,
    getInvitePilotsListSuccess
  );
  yield takeLatest(
    actionTypes.INVITE_PILOTS_LIST.FAILURE,
    getInvitePilotsListFailure
  );

  // get selected aircraft list
  yield takeLatest(
    actionTypes.SELECTED_AIRCRAFT_LIST.REQUEST,
    selectedAirCraftList
  );
  yield takeLatest(
    actionTypes.SELECTED_AIRCRAFT_LIST.SUCCESS,
    selectedAirCraftListSuccess
  );
  yield takeLatest(
    actionTypes.SELECTED_AIRCRAFT_LIST.FAILURE,
    selectedAirCraftListFailure
  );

  // remove pilot from pilot list
  yield takeLatest(actionTypes.REMOVE_PILOT_BY_ID.REQUEST, removePilot);
  yield takeLatest(actionTypes.REMOVE_PILOT_BY_ID.SUCCESS, removePilotSuccess);
  yield takeLatest(actionTypes.REMOVE_PILOT_BY_ID.FAILURE, removePilotFailure);

  // invite pilot
  yield takeLatest(actionTypes.INVITE_PILOT.REQUEST, invitePilots);
  yield takeLatest(actionTypes.INVITE_PILOT.SUCCESS, invitePilotsSuccess);
  yield takeLatest(actionTypes.INVITE_PILOT.FAILURE, invitePilotsFailure);

  // assign aircraft to pilot
  yield takeLatest(
    actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.REQUEST,
    assignAirCraftPilot
  );
  yield takeLatest(
    actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.SUCCESS,
    assignAirCraftPilotSuccess
  );
  yield takeLatest(
    actionTypes.ASSIGN_AIRCRAFT_TO_PILOT.FAILURE,
    assignAirCraftPilotFailure
  );
}
