import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  getAllInvitedPilots,
  removePilotsByID,
} from "../../../../../redux/pilots/pilots.actions";
import { getAircraftList } from "../../../../../redux/account/account.actions"
import { favouriteFboItem } from "../../../../../redux/fbos/fbos.actions";

import Popup from "../../../../scene/profile-setup/Popup";
import AddPilotForm from "./AddPilotForm";
import Button from "@material-ui/core/Button";
import Colors from "../../../../../constants/colors";
import pilotIcon from "../../../../../assets/images/pilotIcon.png";

const useStyles = makeStyles((theme) => ({
  pilotsListWrap: {
    maxHeight: "calc(100vh - 190px)",
    overflowY: "auto",
    cursor: "pointer",
  },
  submitBtn: {
    backgroundColor: Colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: Colors.primaryColor,
    },
  },
}));

let pathName = "";

const ManagePilots = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { pilots, auth } = useSelector((state) => ({
    pilots: state.pilots,
    auth: state.auth,
  }));

  const [openPopup, setOpenPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = useState(false);

  const openAddPilotPopup = () => {
    setOpenPopup(true);
  };

  const deletePilotById = (itemData) => {
    setItem(itemData);
    setOpen(true);
  };

  const deletePilot = () => {
    dispatch(removePilotsByID(auth.token, item?.pilotUserId));
    setOpen(false);
  };

  useEffect(() => {
    dispatch(favouriteFboItem({}));
    dispatch(getAllInvitedPilots(auth.token));
    dispatch(getAircraftList({ token: auth.token }));
  }, [auth.token]);

  useEffect(() => { }, [pilots?.pilotList]);

  const isPopupOpen = (closePopup, email) => {
    setEmail(email);
    if (closePopup === false) {
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    pathName = "./pilot-profile";
    if (email !== "") {
      pilots?.pilotList
        .filter((item) => {
          return item.email === email;
        })
        .map((item) => {
          history.push({
            pathname: pathName,
            state: {
              param: {
                data: item,
              },
            },
          });
        });
    }
  }, [pilots?.pilotList, email, history, props.location.pathname]);

  return (
    <div style={{ padding: "0 20px" }}>
      <br />
      <div style={{ maxWidth: 250, paddingBottom: 30 }}>
        <Button
          fullWidth
          className={classes.submitBtn}
          onClick={openAddPilotPopup}
          disableElevation
        >
          + Add Pilot
        </Button>
      </div>
      <div className={classes.pilotsListWrap}>
        {pilots?.pilotList?.length > 0 ? (
          pilots?.pilotList?.map((pilotItem) => {
            return (
              <ListItem
                className={classes.listItem}
                onClick={() => {
                  pathName = "./pilot-profile";
                  history.push({
                    pathname: pathName,
                    state: {
                      param: {
                        data: pilotItem,
                      },
                    },
                  });
                }}
              >
                <ListItemIcon>
                  <img
                    style={{ maxWidth: 40, maxHeight: 40 }}
                    src={pilotIcon}
                    alt="pilotIcon"
                  />
                </ListItemIcon>

                <ListItemText
                  primary={`${pilotItem?.email}`}
                  secondary={
                    pilotItem?.pilotDetail?.length > 0
                      ? `${pilotItem?.pilotDetail[0].name}`
                      : "Invited"
                  }
                />

                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => deletePilotById(pilotItem)}
                  >
                  {
                    pilotItem?.pilotDetail?.length > 0 ? 
                    <CancelIcon /> : <DeleteIcon /> 
                  }
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        ) : (
          <div className={classes.notfound}>No pilots have been added to this account.</div>
        )}
      </div>

      <Popup
        title="Add Pilot"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        isCenter={true}
      >
        <AddPilotForm isPopupOpen={isPopupOpen} />
      </Popup>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          { 
            item?.pilotDetail?.length > 0 ? 
            "Are you sure you want to remove the pilot from the account?" : "Are you sure you want to delete this pilot?"
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={deletePilot} color="secondary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagePilots;
