import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import nozlSignUpLogo from "../../../assets/images/nozlSignUpLogo.png";
import signupSideLogo from "../../../assets/images/signupSideLogo.png";
import { useStyles } from "../../../assets/styles/SignupUserTypeStyles";
import paragonMainLogoDark from "../../../assets/images/paragonMainLogoDark.png";
import ParagonNozlLogo from "../../../assets/images/paragonNozlLogo.png";
import signupSidebarParagonMap from "../../../assets/images/signupSidebarParagonMap.png";

const SideBar = () => {
  const classes = useStyles();

  const { account, auth } = useSelector((state) => ({
    account: state.account,
    auth: state.auth
  }));

  return (
    <Grid container={true} item xs={12} className={classes.mainCont}>
      <Grid item xs={12} className={classes.innerCont}>
        <img alt=""
          src={
            (account.isParagonUser !== undefined &&
            account.isParagonUser === true) || auth.isIframe
              ? auth.isIframe ? paragonMainLogoDark : ParagonNozlLogo
              : nozlSignUpLogo
          }
          className={
            ((account.isParagonUser !== undefined &&
            account.isParagonUser === true) || auth.isIframe)
              ? auth.isIframe ? classes.paragonIframeImageStyle : classes.paragonImageStyle
              : classes.imageStyle
          }
        />
        <Grid className={auth.isIframe ? classes.iframeTextCont : classes.textCont}>
          Request fuel in under three clicks.
        </Grid>
        <Grid>
          <img alt=""
            src={
              (account.isParagonUser !== undefined &&
              account.isParagonUser === true) || auth.isIframe
                ? signupSidebarParagonMap
                : signupSideLogo
            }
            className={auth.isIframe ? classes.imageIframeContainer : classes.imageContainer}
          />
        </Grid>
        <Grid className={auth.isIframe ? classes.iframeTextStyle2 : classes.textStyle2}>Let's get started.</Grid>
      </Grid>
    </Grid>
  );
};

export default SideBar;
