import React from "react";
import FavouriteFbosList from "../../components/scene/app/FavouriteFbos";
import AppLayout from "../Layout/AppLayout";
import { StyledContainer } from '../History';
import NozlHeadings from "../../components/ui/NozlHeadings";

export default function FavouriteFbos(props) {
  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <AppLayout>
        <NozlHeadings title=" Favorite FBOs" />
        <FavouriteFbosList location={props.location} />
      </AppLayout>
    </StyledContainer>
  );
}
