import React from "react";

import { Grid, makeStyles } from "@material-ui/core";
import mobileDetectInfoIcon from "../../assets/images/mobileDetectInfoIcon.png";
import { Button } from "evergreen-ui";
import colors from "../../constants/colors";


const useStyles = makeStyles(() => ({
  firstCont: {
    width: '100%',
    margin:20,
    height: 487,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
    padding: "30px 40px",
    borderRadius: 10,
    boxShadow:
      "4px 4px 10px 0 rgba(0, 0, 0, 0.1), -4px -4px 10px 0 rgba(0, 0, 0, 0.1)",
    background: "#fff",
  },
  imageStyle: {
    width: 67.6,
    height: 70,
    flexGrow: 0,
    objectFit: "contain",
  },
  textStyle: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: 16,
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#666",
  },
  submitButton:{
    padding: 16,
    borderRadius: 5,
    color: colors.white,
    background: colors.primaryColor,
    flexGrow: 0,
    "&:hover": { backgroundColor: colors.primaryColor },
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 2,
    letterSpacing: 1,
    cursor: "pointer",
  }
}));

const DetectMobilePopup = () => {
  const classes = useStyles();
const handleButton = () =>{
  window.location.replace("nozlapp://");
  setTimeout(() => {
    window.location.replace(
      "https://apps.apple.com/tr/app/nozl-fbo-locator/id1550554215"
    );
  }, 500);
}
  return (
    <Grid container className={classes.firstCont}>
      <img alt="" src={mobileDetectInfoIcon} className={classes.imageStyle} />
      <Grid className={classes.textStyle}>
        This website is not optimized for mobile, please continue signup from a
        desktop. Once you set your new password you can download the NOZL app to
        experience NOZL on your mobile device.
      </Grid>
      <Button
        onClick={handleButton}
        className={classes.submitButton}>
        Get Mobile app
      </Button>
    </Grid>
  );
};

export default DetectMobilePopup;
