import constants from "../../../../config/constants";

export const calculateRateType = (
  item,
  RampFeeType,
  account,
  mtowLb,
  mlwLbs,
  parkingAreaSf
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let showRampFee = false;
      let waiveOff = 0;
      let parkingFee = 0;
      if (
        item.rampFeeType === RampFeeType.MTOW_RAMPFEE ||
        item.rampFeeType === RampFeeType.MLW_RAMPFEE ||
        item.rampFeeType === RampFeeType.PARKING_AREA_RAMPFEE
      ) {
        let claculate = await calculateWaiveOff(item.rampFeeType, item.waiveOffTier, false, RampFeeType, mtowLb, mlwLbs, parkingAreaSf);
        showRampFee = claculate.showRampFee;
        waiveOff = claculate.waiveOff;
        parkingFee = claculate.parkingFee;
      } else if (item.rampFeeType === RampFeeType.CUSTOM_RAMPFEE) {
        let claculate = await calculateWaiveOffForCustomRampFee(account, item, RampFeeType, mtowLb, mlwLbs, parkingAreaSf);
        showRampFee = claculate.showRampFee;
        waiveOff = claculate.waiveOff;
        parkingFee = claculate.parkingFee;
      } else if (item.rampFeeType === RampFeeType.NOZL_SIZE_RAMPFEE) {
        if (item.nozlSizeRampFee?.length > 0) {
          const filterNozlSizeRampFee = item.nozlSizeRampFee?.filter(
            (item) =>
              item.categoryId ===
              account.userSelectedAircraft?.airplaneDetail
                ?.airplaneNozlSizeCategoryId
          );
          if (filterNozlSizeRampFee?.length === 0) {
            parkingFee = 0;
            showRampFee = false;
          } else {
            showRampFee = true;
            waiveOff = filterNozlSizeRampFee[0].waiveOff;
            parkingFee = filterNozlSizeRampFee[0].rampFee;
          }
        } else {
          showRampFee = false;
        }
      }
      resolve({ "showRampFee": showRampFee, "waiveOff": waiveOff, "parkingFee": parkingFee });
    } catch (error) {
      reject(error);
    }
  });
};

export const calculateWaiveOff = (rampFeeTypee, waiveOffArray, isCustom, RampFeeType, mtowLb, mlwLbs, parkingAreaSf) => {
  return new Promise((resolve, reject) => {
    try {
      let showRampFee = false;
      let waiveOff = 0;
      let parkingFee = 0;
      if (waiveOffArray?.length > 0) {
        let mtowLbLocal = 0;
        switch (rampFeeTypee) {
          case RampFeeType.MTOW_RAMPFEE:
            mtowLbLocal = mtowLb;
            break;
          case RampFeeType.MLW_RAMPFEE:
            mtowLbLocal = mlwLbs;
            break;
          case RampFeeType.PARKING_AREA_RAMPFEE:
            mtowLbLocal = parkingAreaSf;
            break;
          default:
            mtowLbLocal = 0;
            break;
        }
        let willApplyWaiveOff = false;
        let WaiveOffTierObj = {};
        waiveOffArray.map((item) => {
          if (
            mtowLbLocal >= (isCustom ? item.min : item.limitLow) &&
            mtowLbLocal <= (isCustom ? item.max : item.limitHigh)
          ) {
            willApplyWaiveOff = true;
            WaiveOffTierObj = item;
          }
        });
        if (willApplyWaiveOff) {
          waiveOff = isCustom ? WaiveOffTierObj.waiveOff : WaiveOffTierObj.waiver;
          parkingFee = WaiveOffTierObj.parkingFee;
          showRampFee = true;
        } else {
          showRampFee = false;
        }
      } else {
        showRampFee = false;
      }
      resolve({ "showRampFee": showRampFee, "waiveOff": waiveOff, "parkingFee": parkingFee });
    } catch (error) {
      reject(error);
    }
  });
};

const calculateWaiveOffForCustomRampFee = (account, item, RampFeeType, mtowLb, mlwLbs, parkingAreaSf) => {
  return new Promise((resolve, reject) => {
    try {
      let showRampFee = false;
      let waiveOff = 0;
      let parkingFee = 0;
      const filterCustomeRampFee = item.customRampFee?.filter(
        (item) =>
          item.categoryId ===
          account.userSelectedAircraft?.airplaneDetail?.airplaneCategoryId
      );
      if (filterCustomeRampFee?.length === 0) {
        showRampFee = false;
      } else if (
        filterCustomeRampFee?.length === 1 &&
        filterCustomeRampFee[0]?.rampFeeType == null
      ) {
        const WaiveOffTierObj = filterCustomeRampFee[0];
        parkingFee = WaiveOffTierObj.parkingFee;
        waiveOff = WaiveOffTierObj.waiveOff;
        showRampFee = true;
      } else {
        const rampFeeTypeLocal = filterCustomeRampFee[0].rampFeeType;
        calculateWaiveOff(rampFeeTypeLocal, filterCustomeRampFee, true, RampFeeType, mtowLb, mlwLbs, parkingAreaSf);
      }
      resolve({ "showRampFee": showRampFee, "waiveOff": waiveOff, "parkingFee": parkingFee });
    } catch (error) {
      reject(error);
    }
  });
};

export const getImageURLFromAWS = (path, width = 20, height = 20) => {
  const imageRequest = JSON.stringify({
    bucket: process.env.REACT_APP_BUCKET,
    key: path,
    edits: {
      resize: {
        width: width,
      },
    },
  });
  return `${process.env.REACT_APP_CLOUD_FRONT_URL}/${Buffer.from(
    imageRequest
  ).toString("base64")}`;
};

const waiveOffPromise = (waiveOffType) => {
  return new Promise((resolve) => {
    let waiveTypeLocal = 0;
    const largeAirplane = "largeAirplane";
    const mediumAirplane = "mediumAirplane";
    switch (waiveOffType) {
      case largeAirplane:
        waiveTypeLocal = constants.waiveTypeLocal.waiveTypeLocal1;
        break;
      case mediumAirplane:
        waiveTypeLocal = constants.waiveTypeLocal.waiveTypeLocal2;
        break;
      default:
        waiveTypeLocal = constants.waiveTypeLocal.waiveTypeLocal3;
        break;
    }
    resolve({"waiveTypeLocal": waiveTypeLocal});
  });
}


export const calculateRateValue = (
  params,
  waiveOffType,
  volume,
  isRampFeeLineWillShow,
  RampFeeType,
  parkingfee,
  waiveOff
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let setRampFee = 0;
      let setIsRampFeeLineWillShow = false;
      if (volume === 0) {
        setRampFee = 0;
        resolve({ "setRampFee": 0, "setIsRampFeeLineWillShow": setIsRampFeeLineWillShow });
      } else {
        if (params.rampFeeType === RampFeeType.SIZE_BASED_RAMPFEE) {
          const { rampFee } = params;
          let waiveOffValue= await waiveOffPromise(waiveOffType);
          let waiveTypeLocal = waiveOffValue.waiveTypeLocal;
          if (volume <= 0) {
            setRampFee = 0;
          }
          if (params.isRampFee === 0) {
            setRampFee = 0;
          } else {
            if (volume < parseFloat(waiveOff)) {
              switch (waiveTypeLocal) {
                case waiveTypeLocal < constants.waiveTypeLocal.waiveTypeLocal2:
                  setRampFee = parseFloat(rampFee?.large);
                  break;
                case constants.waiveTypeLocal.waiveTypeLocal2:
                  setRampFee = parseFloat(rampFee?.medium);
                  break;
                default:
                  setRampFee = parseFloat(rampFee?.small);
                  break;
              }
            } else {
              setRampFee = 0;
            }
          }
        } else if (
          params.rampFeeType === RampFeeType.MTOW_RAMPFEE ||
          RampFeeType.MLW_RAMPFEE ||
          RampFeeType.PARKING_AREA_RAMPFEE
        ) {
          switch (true) {
            case params.isRampFee === 0:
              setRampFee = 0;
              break;
            case isRampFeeLineWillShow && waiveOff > volume:
              setRampFee = parkingfee;
              setIsRampFeeLineWillShow = true;
              break;
            case waiveOff > volume && parkingfee > 0:
              setIsRampFeeLineWillShow = true;
              setRampFee = parkingfee;
              break;
            case waiveOff <= volume:
              setRampFee = 0;
              setIsRampFeeLineWillShow = false;
              break;
            default:
              setIsRampFeeLineWillShow = false;
              setRampFee = 0;
              break;
          }
        } else {
          setRampFee = 0;
        }
        resolve({ "setRampFee": setRampFee, "setIsRampFeeLineWillShow": setIsRampFeeLineWillShow });
      }
    } catch (error) {
      reject(error);
    }
  });
};