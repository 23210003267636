import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  innerCont: {
    width: "100%",
    minWidth: 0,
    height: "auto",
    minHeight: 0,
    maxHeight: "100%",
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 4px 8px 0 rgba(153, 153, 153, 0.4)",
    border: "solid 1px #eee",
    backgroundColor: "#fff",
  },
  imageCont: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImageCont: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImageStyle: {
    textAlign: "center",
    width: "50vmin",
    maxWidth: "40vmin",
    height: "100%",
    marginLeft: -20,
  },
  backButtonStyle: {
    width: 32,
    height: 32,
    flexGrow: 0,
    cursor: "pointer",
  },
  backButtonCont: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    marginLeft:-20
  },
  textCommonStyle: {
    flexGrow: 0,
    width: "100%",
    fontWeight: "500",
    fontFamily: "Arial",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "center",
  },
  textStyle: {
    fontSize: 20,
    marginTop: 24,
    color: "#9F215F",
    lineHeight: 1.6,
    fontWeight: "bold",
    letterSpacing: "-0.25px",
  },
  textStyle1: {
    fontSize: 16,
    color: "#999999",
    lineHeight: 2,
    fontWeight:"600",
    letterSpacing: "normal",
  },
  textStyle2: {
    fontSize: 14,
    color: "#666",
    marginTop: 24,
    lineHeight: 1.71,
    letterSpacing: "normal",
  },
  fieldCont: {
    width: "100%",
    height: 258,
    margin: "4px 0 0 0",
    padding: "20px",
    maxWidth: "498px",
    borderRadius: 5,
    border: "solid 1px #e8e8e8",
  },
  forgotFieldCont: {
    marginTop: "12px",
    width: "100%",
    height: 208,
    padding: "20px",
    maxWidth: "498px",
    borderRadius: 5,
    border: "solid 1px #e8e8e8",
  },
  optionFieldCont: {
    width: "100%",
    height: 258,
    marginTop: "72px",
    borderRadius: 5,
  },
  customLabelInput: {
    minWidth: "0%",
    width:'100%',
    height: 40,
    display: "inline-flex",
    borderWidth:1,
    color: "#7a869a",
    "& label": {
      transform: "translate(14px, 13px) qascale(1)",
      borderWidth:1,
      border: "solid 1px #e8e8e8",
      "&.Mui-focused": {
        color: "black",
        borderWidth:1,
        border: "solid 1px #e8e8e8"
      },
      "&.Mui-error": {
        color: "#7a869a",
        borderWidth:1,
        border: "solid 1px #e8e8e8"
      },
      ".MuiFormControl-root": {
        borderWidth:1,
        border: "solid 1px #e8e8e8",
        display: "inline-flex",
      },
    },
    "& ::placeholder": {
      color: "#7a869a",
      opacity: 1,
    },
  },
  notchedOutline: {
    width:'100%',
    borderColor: "#e8e8e8 !important",
    borderWidth: "1px",
    boxSizing: "border box",
    borderRadius: 5,
    fontSize: 14,
    color: "#7a869a",
    hintColor: "#7a869a",
  },
  contStyle: {
    width: "100%",
  },
  forgotPasswordStyle: {
    marginTop: 24,
    width: "100%",
    color: "#03558f",
    fontSize: 14,
    cursor: "pointer",
    fontWeight: "normal",
    textAlign: "right",
    backgroundClip: "text",
    fontFamily: "Arial",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textDecoration: "underline",
  },
  submitButton: {
    height: 40,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    marginTop: 24,
    borderRadius: 5,
    color: "#fff",
    background: "linear-gradient(to bottom, #9f215f 0%, #7a003c 100%)",
    flexGrow: 0,
    "&:hover": {
      background: "linear-gradient(to bottom, #9f215f 0%, #7a003c 100%)",
      backgroundColor: "linear-gradient(to bottom, #9f215f 0%, #7a003c 100%)",
    },
    fontFamily: "Arial",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 2,
    letterSpacing: 1,
    cursor: "pointer",
  },
  forgotSubmitButton: {
    marginTop: 32,
  },
  passwordStyle: {
    width: "100%",
    marginTop: 24,
    fontSize: 20,
    textAlign: "center",
    color: "#131415",
    fontWeight: "600",
  },
  textCont: {
    width: "100%",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    marginTop: 8,
    textAlign: "center",
    color: "#999",
  },
  margin: {
    width: "100%",
    marginTop: 30,
    marginBottom: 30,
  },
  cancelCont: {
    fontSize: 14,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#333",
    border: "solid 1px #dfe5e9",
    borderRadius: 5,
    cursor: "pointer",
    fontWeight: "600",
    marginTop: 16,
    "&:hover": {
      background: "#ebf1f5",
      backgroundColor: "#ebf1f5",
    },
  },
  optionCont: {
    width: "100%",
    flexGrow: 0,
    margin: "0 0 24px",
    fontFamily: "Arial",
    fontSize: 20,
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.6,
    letterSpacing: -0.25,
    textAlign: "center",
    color: "#666",
  },
  optionStyle: {
    fontSize: "16px",
    cursor: "pointer",
    color: "#fff",
    display: "flex",
    fontWeight: "500",
    alignItems: "center",
    width: "100%",
    maxWidth: 500,
    height: 56,
    flexGrow: 0,
    background: "#9f215f",
    textTransform: "capitalize",
    margin: "24px 0 0",
    borderRadius: 5,
    border: "solid 1px #dfe5e9",
    justifyContent: "center",
    "&:hover": {
      background: "#9f215f",
      backgroundColor: "#9f215f",
    },
  },
  signupCont: {
    marginTop: 70,
    width: "100%",
    display: "flex",
    height: 24,
    alignItems: "center",
    justifyContent: "space-between",
  },
  signupforgotCont: {
    marginTop: 155,
    width: "100%",
    display: "flex",
    height: 24,
    alignItems: "center",
    justifyContent: "space-between",
  },
  memberStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    color: "#7A869A",
  },
  linkStyle: {
    color: "#003459",
    fontSize: 14,
    cursor: "pointer",
    lineHeight: "1.14px",
    fontWeight: "500",
  },
  textButtonStyle: {
    textTransform: "capitalize",
  },
  errorTextStyle: {
    color: "#9F215F",
    fontSize: 11,
    fontFamily: "Arial",
  },
  textfieldStyle:{
    width:'100%'
  }
}));

export { useStyles };