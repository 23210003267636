import React from "react";
import { Grid } from "@material-ui/core";

import AddPrimaryContacts from "./AddPrimaryContacts";

const Contacts = () => {
  return (
    <Grid container>
      <AddPrimaryContacts />
    </Grid>
  );
};

export default Contacts;
