import React from "react";
import AppLayout from "../Layout/AppLayout";
import BookingDetailsComponent from "../../components/scene/app/BookingDetail";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { StyledContainer } from '../History';
import NozlHeadings from "../../components/ui/NozlHeadings";

export const IconWrapper = styled.div`
  background-color: white;
  width: 28px;
  height: 28px;
  padding-top: 5px;
`;

export default function BookingDetails(props) {
  const history = useHistory();

  const goBack = (value) => {
    if (value === true) {
      history.goBack()
    }
  }
  return (
    <StyledContainer container={true} spacing={0} className="rootContainer">
      <AppLayout>
        <NozlHeadings title="Arrival Request Details" goBack={true} onClickBack={goBack} />
        <BookingDetailsComponent location={props.location} />
      </AppLayout>
    </StyledContainer>
  );
}
