import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

import colors from "../../constants/colors";
import fontWeights from "../../constants/fontWeights";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
    width: "100%",
    cursor: "pointer",
    background: "#fff",
    boxShadow:
      "0px -4px 10px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px 5px 0px 0px",
  },
  mainContainer: {
    padding: 10,
    width: "100%",
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  nudgeCon: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  rating: {
    display: "flex",
    flexDirection: "row",
    width: 14,
    justifyContent: "space-between",
    alignItems: "center",
  },
  view: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    alignContent: "center",
    textAlign: "center",
    paddingTop: 3,
    paddingBottom: 3,
    borderBottom: "1.5px solid lightgrey",
  },
  paper: {
    position: "absolute",
    width: 420,
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: theme.shadows[5],
  },
  selectedAircraftStyle: {
    fontSize: 14,
    color: "#555",
  },
  headingStyle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headingTextStyle: {
    color: "#555",
    fontWeight: 700,
    fontSize: 32,
    width: 380,
    textAlign: "center",
  },
  imageCont: {
    borderRadius: 20 / 2,
    backgroundColor: "#555555",
    display: "flex",
    right: 0,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  imageStyle: {
    width: 15,
    height: 15,
    color: "white",
  },
  priceHeading: {
    fontSize: 18,
    color: "#555",
    fontWeight: 500,
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
  },
  fboNameStyle: {
    color: "#555",
    fontWeight: 700,
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
  },
  priceStyle: {
    fontSize: 14,
    color: "#555",
    fontWeight: "500",
    opacity: 0.8,
  },
  priceCont: {
    marginBottom: 7,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  statictext: { fontWeight: "bold", marginRight: 5 },
  textStyle1: {
    color: "#666666",
    fontStyle: "normal",
    fontSize: 14,
  },
  edit: {
    width: "100%",
    height: 60,
    marginBottom: 40,
    borderColor: "black",
  },
  label: {
    "&$focusedLabel": {
      color: "black",
    },
    "&$erroredLabel": {
      color: "black",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "black !important",
  },
  ratingContainer: {
    display: "flex",
    paddingLeft: "10px",
    marginBottom: "10px",
    justifyContent: "space-between",
  },
  nameText: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: 1.3,
    color: colors.grey,
  },
  nudge: {
    borderRadius: 14,
    fontSize: 11,
    color: colors.white,
    padding: 5,
    width: isMobile ? "90%" : "90%",
    textAlign: "center",
    background: colors.primaryColor,
  },
  discounted: {
    borderRadius: 15,
    padding: 5,
    textAlign: "center",
    fontSize: 14,
    color: "#666",
  },
  discountedStyle: {
    height: 30,
    width: "100%",
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid lightgrey",
    textAlign: "center",
  },
  priceTextStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "600",
    flexGrow: 0,
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#333",
  },
  hundredLLPriceTextStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    color: "#666",
    marginTop: -3,
  },
  hundredLLDiscountStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    color: "#666",
    marginLeft: 5,
  },
  hundredllPriceStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontSize: 14,
    color: "#999",
    fontWeight: "700",
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  headingCon: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cont: {
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  buttonCont: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nudgeButton: {
    borderRadius: 5,
    border: `1px solid ${colors.primaryColor}`,
    width: 70,
    height: 30,
    boxSizing: "border-box",
  },
  fuelButton: {
    height: 30,
    borderRadius: 5,
    border: `1px solid ${colors.primaryColor}`,
    width: "95%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: colors.primaryColor,
    boxSizing: "border-box",
    color: "#fff",
  },
  disableFuelButton: {
    opacity: 0.4,
    cursor: "default",
  },
  nameCont: {
    display: "flex",
    height: 100,
  },
  buttonStyle: {
    height: 30,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonTextStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "25px",
    flexDirection: "column",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontSize: "14px",
    color: colors.primaryColor,
  },
  imageContainer: {
    display: "flex",
    height: 100,
    width: 100,
  },
  ratingDetailStyle: {
    height: 100,
  },
  imageContainer1: {
    display: "flex",
    height: 100,
    width: 100,
    background: "#dcdcdc",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },
  dividerCont: {
    height: 10,
    display: "flex",
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  dividerStyle: {
    borderBottom: "1px solid #dcdcdc",
    height: 10,
    width: "100%",
    display: "flex",
  },
  detailCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 100,
    width: "100%",
    paddingLeft: 10,
  },
  discountedPrice: {
    marginRight: 6,
    color: "#999999",
    marginLeft: 10,
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
  },
  innerCont: {
    display: "flex",
    alignItems: "center",
    height: 32,
  },
  textCont: {
    flexGrow: 0,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#666",
  },
  infoHeadingCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 16,
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: "1px solid #fff",
  },
  textStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    color: "#666",
    marginLeft: 10,
    lineHeight: 3,
  },
  servicesCont: {
    width: "100%",
    boxShadow: "inset 0 7px 9px -7px rgba(0,0,0,0.4)",
    padding: 10,
  },
  triggerStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  serviceHeadingStyle: {
    fontFamily: "Arial",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: "24px;",
    color: "#666666",
  },
  expandIconStyle: {
    width: 20,
    height: 20,
    color: "#666",
  },
  collapsibleCont: {
    marginTop: 5,
  },
  divider: {
    color: "#666",
  },
  detailContainer: {
    display: "flex",
    marginTop: 10,
    justifyContent: "space-between",
    width: "100%",
  },
  valueStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "right",
    color: "#666",
  },
  nameStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "left",
    color: "#666",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  serviceContainer: {
    width: "100%",
    height: 40,
    marginTop: 10,
    background: "rgba(153, 153, 153, 0.05)",
  },
  headingDetailTextStyle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Arial",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: 16,
    color: "#333333",
    height: 40,
    paddingLeft: 15,
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 10,
  },
  detailViewCont: {
    display: "flex",
    height: 32,
    alignItems: "center",
  },
  numberCont: {
    width: 24,
    height: 24,
    background: "rgba(0, 92, 191, 0.1)",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#42526E",
  },
  detailNameStyle: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "right",
    color: "#666",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  nudgeModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headingTextCont: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    color: "#666",
    marginTop: -3,
  },
  showTiersCont: {
    marginLeft: 10,
  },
  fboNameText: {
    fontWeight: "700",
  },
  iconCont: {
    width: 40,
    height: 40,
    marginRight: -15,
    marginTop: -8,
    cursor: "pointer",
  },
  jetAStyle: {},
  hundredLLPriceCont: {
    marginLeft: 2,
    marginRight: 2,
  },
  infoDetailStyle: {
    width: 18,
    height: 18,
    color: "#666",
    borderRadius: 10,
    cursor: "pointer",
  },
  infoListStyle: {
    marginTop: 2,
    width: 15,
    height: 15,
    marginLeft: 4,
    borderRadius: 10,
    cursor: "pointer",
  },
  priceTextCont: {
    width: "100%",
    height: 32,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tiersStyle: { display: "flex", alignItems: "center" },
  discountedPriceStyle: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
    flexGrow: 1,
    fontFamily: "Arial",
    fontSize: 14,
    marginRight: 8,
    fontWeight: fontWeights.fontWeight600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: colors.lightTextColor,
  },
  infoHeadingContainer: {
    display: "flex",
    width: 150,
    padding: 8,
    borderBottom: "1px solid #fff",
    justifyContent: "space-between",
  },
  headingContStyle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  tierPriceStyle: {
    width: 150,
  },
  boxStyle: {
    fontWeight: fontWeights.fontWeightBold, 
    fontSize: 16
  },
  fuelPriceStyle: {
    display: "flex",
    lineHeight: 1.50,
    alignItems: "center",
    justifyContent: "center",
  },
  discountedStyle: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
    flexGrow: 1,
    fontFamily: "Arial",
    fontSize: 12,
    marginRight: 5,
    fontWeight: fontWeights.fontWeight600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: colors.lightTextColor,
  },
  textPriceStyle: {
    flexGrow: 1,
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: fontWeights.fontWeight600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: colors.lightTextColor,
  },
  noFueltypeTextStyle: {
    margin: "0px 0px 7px",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: fontWeights.fontWeight500,
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "left",
    color: colors.grey40,
  },
}));

export { useStyles };
