import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  submitProfile,
  deleteManager,
} from "../../../../../redux/account/account.actions";
import Popup from "../../../../scene/profile-setup/Popup";
import AddAdditionalManager from "../../../profile-setup/AddAdditionalManager";
import commonClasses from "../../../../../constants/common";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import colors from "../../../../../constants/colors";
import { toast } from "react-toastify";
import ManagersTable from "./ManagersTable";
import * as services from "../../../../../services/mainService";
import { useStyles } from "../../../../../assets/styles/ManagersListStyles";

let params = {};
const ManageOperator = (props) => {
  const dispatch = useDispatch();
  const { auth, account } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
  }));
  const classes = useStyles();
  const commonStyles = commonClasses();

  const [sectionListData, setSectionListData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [editObj, setEditObj] = useState({});
  const [open, setOpen] = useState(false);
  const [primaryData, setPrimaryData] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    dispatch(getProfile({ token: auth.token }));
  }, [auth.token, dispatch]);

  useEffect(() => {
    if (
      account.user.additionalManagerDetail !== undefined ||
      account?.user?.primaryManagerDetail !== undefined
    ) {
      setSectionListData(account.user.additionalManagerDetail);
      setPrimaryData(account?.user.primaryManagerDetail);
    }
  }, [
    account.user.additionalManagerDetail,
    account.user?.primaryManagerDetail,
  ]);

  const setOpenAddPopup = async (value) => {
    dispatch(getProfile({ token: auth.token }));
    setOpenPopup(false);
  };

  useEffect(() => {}, [account.aircraftList, account.user.additionalManagerDetail,
    account?.user?.primaryManagerDetail ]);


  useEffect(() => {}, [
    account?.assignedPilotListForAircraft,
    account.tempAircraft,
  ]);

  const openDialog = async () => {
    dispatch(deleteManager(params));
    dispatch(getProfile({ token: auth.token }));
    setOpen(false);
  };

  const onManagerDelete = (index, item) => {
    params = {
      id: item._id,
      token: auth.token,
    };
    setOpen(true);
  };

  const onManagerEdit = (index, item, value) => {
    setEditObj(item);
    setValue(value);
    setOpenEditPopup(true);
  };

  const editManager = (value) => {
    dispatch(getProfile({ token: auth.token }));
    setOpenEditPopup(false);
  };

  const emailToggle = async (item, title) => {
    const data = {
      _id: item?._id,
      title: item?.title,
      first_name: item?.first_name,
      last_name: item?.last_name,
      email: item?.email,
      countryCode:
        item?.countryCode[0] === "+"
          ? item?.countryCode
          : `+${item?.countryCode}`,
      phone: item?.phone,
      email_group: {
        arrival_request: item.email_group
          ? !item.email_group.arrival_request
          : true,
      },
    };
    if (title === "Additional Manager") {
      let index = sectionListData.findIndex((value) => value._id === data._id);
      sectionListData[index] = data;
    } else {
      let index = primaryData.findIndex((value) => value._id === data._id);
      primaryData[index] = data;
    }
    const managerObj = {
      manager: data,
      token: auth.token,
    };
    const res = await services.editManagerApi(managerObj);
    if (res.response_type === "success") {
      toast(!item.email_group.arrival_request ? "Arrival Request Emails On.": "Arrival Request Emails Off.", {
        position: "bottom-center",
        closeButton: false,
      });
      dispatch(getProfile({ token: auth.token }));
    } else {
      toast.info(res.response.message, {
        position: "bottom-center",
        closeButton: false,
      });
    }
  };

  return (
    <div style={{ padding: "0px 20px" }}>
      <div className={classes.appMain}>
        <div style={{ paddingBottom: 30 }} className={classes.header}>
          <div style={{ minWidth: 200 }}>
            <Button
              fullWidth
              className={classes.submitBtn}
              onClick={() => {
                setOpenPopup(true);
              }}
              disableElevation
            >
              {primaryData.length === 0
                ? "+ Add Primary Manager"
                : "+ Add Additional Manager"}
            </Button>
          </div>
        </div>
        <div
          style={{
            maxHeight: "calc(100vh - 190px)",
            overflowY: "auto",
          }}
        >
          {primaryData.length > 0 ? (
            <div>
              <ManagersTable
                data={primaryData}
                onManagerDelete={onManagerDelete}
                onManagerEdit={onManagerEdit}
                title={"Primary Manager"}
                emailToggle={emailToggle}
              />
            </div>
          ) : null}
          {sectionListData.length > 0 ? (
            <div>
              <ManagersTable
                data={sectionListData}
                onManagerDelete={onManagerDelete}
                onManagerEdit={onManagerEdit}
                title={"Additional Manager"}
                emailToggle={emailToggle}
              />
            </div>
          ) : null}

          {sectionListData.length === 0 && primaryData.length === 0 ? (
            <h4
              style={{
                alignSelf: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: 200,
                color: colors.lightGreyBlue,
              }}
            >
              No Manage Managers Added.
            </h4>
          ) : null}
        </div>
        <Popup
          title={"Add Additional Manager"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          normalPopUp={true}
        >
          <AddAdditionalManager
            editManager={value}
            location={props.location}
            setOpenPopup={setOpenAddPopup}
            operatorName={account?.user?.name}
          />
        </Popup>
        <Popup
          title={
            value === "Primary Manager"
              ? "Edit Primary Manager"
              : "Edit Additional Manager"
          }
          openPopup={openEditPopup}
          setOpenPopup={setOpenEditPopup}
          normalPopUp={true}
        >
          <AddAdditionalManager
            editManager={value}
            location={props.location}
            setOpenEditPopup={editManager}
            editObj={editObj}
            isEdit={true}
          />
        </Popup>

        {/* Dialog for show to confirm before dialog close  */}

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this contact?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={openDialog} color="secondary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ManageOperator;
