import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Typography, Button, Tooltip, Box, Toolbar } from "@material-ui/core";

import FboList from "./FboList";
import InviteFboForm from "./InviteFboForm";
import Popup from "../../../scene/profile-setup/Popup";
import FuelRequest from "../../../../containers/FuelRequest";
import colors from "../../../../constants/colors";
import commonStyles from "../../../../constants/common";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import airplaneIcon from "../../../../assets/images/airplaneIcon.png";
import bookFuelIcon from "../../../../assets/images/bookFuelIcon.png";
import { useStyles } from "../../../../assets/styles/AirportFboScreenStyles";
import FboDetail from "./FboDetail";
import { isMobile } from "react-device-detect";
import CustomLoader from "../../../ui/CustomLoader";
import * as services from "../../../../services/mainService";

const IconWrapper = styled.div`
  background-color: ${(props) => props.bgColor && props.bgColor};
  width: 38px;
  height: 38px;
  cursor: pointer;
  vertical-align: center;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

export const StyledHeadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledGrid = styled.div`
  height: calc(100vh - 165px);
  overflow: auto;
  display: flex-wrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const AirportFboScreen = (props) => {
  const classes = useStyles(colors, isMobile);
  const commonClasses = commonStyles();
  const { selectedFbo, selectionType } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [isUnlisted, setIsUnlisted] = useState(false);
  const [fboSelectedItem, setFboSelectedItem] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [selectedFboDetail, setFboDetail] = useState({});
  const [showFboDetailPage, setShowFboDetail] = useState(false);
  const [unlistedfboDetails, setUnlistedfboDetails] = useState([]);
  const [fboDetails, setfboDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { auth, fbos, account } = useSelector((state) => ({
    auth: state.auth,
    fbos: state.fbos,
    account: state.account,
  }));

  let userSelectedAircraft = useMemo(
    () => account?.userSelectedAircraft,
    [account?.userSelectedAircraft]
  );


  const airportName =
    selectedFbo.fboDetail !== undefined
      ? selectedFbo.fboDetail.fboName
      : selectedFbo.name !== undefined
      ? selectedFbo.name
      : selectedFbo.fboName;

  useEffect(() => {
    if ((Object.values(userSelectedAircraft).length > 0) &&
      (Object.values(selectedFboDetail).length > 0) &&
      (selectedFboDetail.icaoCode === (!!selectedFbo.fboDetail
        ? selectedFbo.fboDetail.icaoCode
        : !!selectedFbo.icaoCode
          ? selectedFbo.icaoCode
          : selectedFbo.icao))) {
      fboDetailList(
        selectedFboDetail,
        userSelectedAircraft !== undefined && showFboDetailPage ? "fboItem" : ""
      );
    } else {
      setFboDetail({});
      fboDetailList(selectedFbo);
    }
  }, [selectedFbo, userSelectedAircraft]);

  useEffect(() => {
    if (Object.values(selectedFbo).length === 0) {
      getList();
    }
    if (fbos.unlistedfboDetails.length === 0) {
      setUnlistedfboDetails([]);
    }
    if (fbos.fboDetails.length === 0) {
      setfboDetails([]);
    }
  }, [fbos.fboDetails, fbos.unlistedfboDetails, selectedFbo]);

  const getList = () => {
    setfboDetails(fbos.fboDetails);
    setUnlistedfboDetails([]);
  };

  const selectFboItem = (item, isClick, isUnlisted) => {
    item.listedFboStatus = isUnlisted;
    if (isClick === true) {
      setFboSelectedItem(item);
      setIsClick(true);
    }
  };
  const goBack = (isGoBack) => {
    if (isGoBack === true) {
      setIsClick(false);
      if (showFboDetailPage === true) {
        setShowFboDetail(true);
      } else {
        setShowFboDetail(false);
      }
    }
  };

  const closeLayout = (onClick, value) => {
    setIsClick(false);
    fboDetailList(selectedFbo)
  };

  const showFboDetail = (item, isUnlisted) => {
    if (item !== undefined) {
      setShowFboDetail(true);
      setFboDetail(item);
      setIsUnlisted(isUnlisted);
    }
  };
  const handleBackButton = () => {
    if (showFboDetailPage === false) {
      props.closelayout(true);
    } else {
      if (
        Object.values(selectedFbo).length > 0 &&
        selectionType === "fboItem"
      ) {
        props.closelayout(true);
      }
      setShowFboDetail(false);
    }
  };

  const fboDetailList = async (item, value) => {
    let res = [];
    if (
      item !== undefined &&
      (Object.values(item).length > 0 || item.length > 0)
    ) {
      setLoading(true);
      res = await services.getFboDetailList({
        fbo: userSelectedAircraft?._id
          ? {
              icaoCode:
                item.fboDetail !== undefined
                  ? item.fboDetail.icaoCode
                  : item.icaoCode !== undefined
                  ? item.icaoCode
                  : item.icao,
              aircraftId: userSelectedAircraft?._id,
            }
          : {
              icaoCode:
                item.fboDetail !== undefined
                  ? item.fboDetail.icaoCode
                  : item.icaoCode !== undefined
                  ? item.icaoCode
                  : item.icao,
            },
        token: auth.token,
      });
      if (res !== undefined && res !== null) {
        setLoading(false);
        setfboDetails(res.response.data.fbo);
        setUnlistedfboDetails(res.response.data.unlistedFbo);
        if (res?.response?.data?.fbo.length > 0) {
          if (selectionType === "fboItem" || value === "fboItem") {
            res?.response?.data?.fbo.map((fbo) => {
              if (
                item.fboDetail !== undefined
                  ? fbo.fboName === item.fboDetail.fboName
                  : fbo.fboName === item.fboName
              ) {
                showFboDetail(fbo, false);
              }
            });
          } else {
            setShowFboDetail(false);
          }
        }
        if (res?.response?.data?.unlistedFbo.length > 0) {
          if (selectionType === "fboItem" || value === "fboItem") {
            res?.response?.data?.unlistedFbo.map((unlistedFbo) => {
              if (
                item.fboDetail !== undefined
                  ? unlistedFbo.fboName === item.fboDetail.fboName
                  : unlistedFbo.fboName === item.fboName
              ) {
                showFboDetail(unlistedFbo, true);
              }
            });
          } else {
            setShowFboDetail(false);
          }
        }
        if (
          res?.response?.data?.unlistedFbo.length === 0 &&
          res?.response?.data?.fbo.length === 0
        ) {
          setLoading(false);
          setFboDetail({});
          setShowFboDetail(
            selectionType === "fboItem" || value === "fboItem" ? true : false
          );
        }
      }
    }
  };

  const AirportName = () => {
    return (
      <StyledHeadingContainer className={classes.nameHeading}>
        <Toolbar className={commonClasses.pl0}>
          <IconWrapper onClick={() => handleBackButton()}>
            <Icon
              icon={faArrowLeft}
              onClick={() => handleBackButton()}
              color="white"
              className={classes.icon}
            />
          </IconWrapper>
          <div className={classes.airportNameCont}>
            {!!selectedFbo.name ? (
              <img src={airplaneIcon} alt="" className={classes.imageCont} />
            ) : (
              <img src={bookFuelIcon} alt="" className={classes.imageCont} />
            )}
            <Tooltip title={airportName}>
              <Box
                component="div"
                my={2}
                textOverflow="ellipsis"
                overflow="hidden"
                style={{ fontWeight: "bold" }}
              >
                {airportName}
              </Box>
            </Tooltip>
          </div>
        </Toolbar>
      </StyledHeadingContainer>
    );
  };

  return (
    <div className={classes.root}>
      {isClick === false ? (
        <div>
          <AirportName />
          {loading === true ? (
            <CustomLoader navigation="fboList" />
          ) : (
            <div>
              {showFboDetailPage === false ? (
                <div>
                  {fboDetails.length === 0 &&
                    unlistedfboDetails.length === 0 && (
                      <div className={classes.noData}>
                        There are no FBOs available at this airport.
                      </div>
                    )}
                  <StyledGrid>
                    <FboList
                      unlistedFbos={
                        unlistedfboDetails.length === 0
                          ? []
                          : unlistedfboDetails
                      }
                      data={fboDetails.length === 0 ? [] : fboDetails}
                      isUnlisted={isUnlisted}
                      selectFboItem={selectFboItem}
                      fboDetailList={fboDetailList}
                    />
                  </StyledGrid>
                </div>
              ) : (
                <FboDetail
                  item={selectedFboDetail}
                  airportName={
                    selectedFbo.fboDetail !== undefined
                      ? selectedFbo.fboDetail.airportName
                      : selectedFbo.airportName !== undefined
                      ? selectedFbo.airportName
                      : selectedFbo.name
                  }
                  isUnlisted={isUnlisted}
                  selectFboItem={selectFboItem}
                />
              )}
            </div>
          )}
          {showFboDetailPage === false && (
            <div className={classes.bottomFboContainer}>
              <Button
                onClick={() => setOpenPopup(true)}
                className={classes.submitBtn}
                disableElevation
              >
                Didn't find the right FBO?
              </Button>
              <Typography className={classes.fboTextStyle}>
                Share the name and location of the FBO you are looking for and
                we'll add them to our database.
              </Typography>
            </div>
          )}
          <Popup
            title="Refer FBO"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            isCenter={true}
            navigation="refer fbo"
          >
            <InviteFboForm
              setOpenPopup={setOpenPopup}
              airPortName={ Object.values(props.selectedFbo).length > 0
                ? !!props.selectedFbo.fboDetail
                  ? props.selectedFbo.fboDetail.airportName
                  : !!props.selectedFbo.airportName ? props.selectedFbo.airportName: props.selectedFbo.name
                : !!fbos.favouriteFboItem &&
                  Object.values(fbos.favouriteFboItem).length > 0
                ? fbos.favouriteFboItem.airportName
                : props.selectedFbo.name}
              icaoCode={
                Object.values(props.selectedFbo).length > 0
                  ? !!props.selectedFbo.fboDetail
                    ? props.selectedFbo.fboDetail.icaoCode
                    : props.selectedFbo.icao
                  : Object.values(fbos.favouriteFboItem).length > 0
                    ? fbos.favouriteFboItem.icaoCode
                    : props.selectedFbo.icao
              }
            />
          </Popup>
        </div>
      ) : (
        <FuelRequest
          selectedFboItem={fboSelectedItem}
          goBack={goBack}
          showFboList={closeLayout}
        />
      )}
    </div>
  );
};

export default AirportFboScreen;
