import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  customInput: {
    color: "#fff",
    borderRadius: 5,
    marginBottom: 15,
    "& label.MuiInputLabel-outlined": {
      color: "#555",
    },
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& label.MuiFormLabel-filled": {
      color: "#fff",
    },
    "& div.MuiInputBase-formControl": {
      borderRadius: 5,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 15,
      color: "#fff",
      "& fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
  customBlackInput: {
    color: "#fff",
    borderRadius: 5,
    marginBottom: 15,
    floatingLabelFocus: {
      color: "#df2935"
    },
    "& label.MuiInputLabel-outlined": {
      color: "#555",
    },
    "& label.Mui-focused": {
      color: "#000",
    },

    "& label.MuiFormLabel-filled": {
      color: "#000",
    },
    "& div.MuiInputBase-formControl": {
      borderRadius: 5,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 15,
      color: "#000",
      "& fieldset": {
        borderColor: "#000",
        borderWidth: 1,
        color: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000",
        borderWidth: 1
      },
    },
  },
}));

export default function Input(props) {
  const { name, label, value, theme, error = null, onChange, ...other } = props;
  const classes = useStyles();
  return (
    <TextField
      className={`${classes.customInput} ${props.theme === "black" ? classes.customBlackInput : ""
        }`}
      variant="outlined"
      label={label}
      name={name}
      value={value}
      fullWidth
      floatingLabelFocusStyle={classes.floatingLabelFocus}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
