import { all, fork, takeLatest, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as actionTypes from "./auth.actionTypes";
import { loginSaga } from "./login/login.saga";
import * as authService from "../../services/authService.js";
import * as mainService from "../../services/mainService.js";
import { toast } from "react-toastify";

function* logout(action) {
  yield call(authService.logoutApi, action.payload);
  yield put({ type: actionTypes.LOG_OUT.SUCCESS, payload: {} });
}

function* logoutSuccess(action) {
  yield put(push("/login"));
}

function* getProfileByInviteePilot(action) {
  const apiResponse = yield call(mainService.getDetailOfInviteePilotApi, action.payload);
  if (Object.values(apiResponse).length === 0) {
    yield put({ type: actionTypes.LOG_OUT.SUCCESS, payload: {} });
  } else {
    if (apiResponse.response_type === "success") {
      yield put({
        type: actionTypes.GET_PROFILE_BY_INVITEE_PILOT.SUCCESS,
        payload: apiResponse.response.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_PROFILE_BY_INVITEE_PILOT.FAILURE,
        payload: apiResponse.response,
      });
    }
  }
}

function* getProfileByInviteePilotSuccess(action) {
  if (action.payload.status === 4) {
    yield put(push("/login"));
    toast.info("Profile is already created. Please sign in.", { autoClose: 2000, position: "bottom-center" })
  }
}

function* getProfileByInviteePilotFailure(action) {
  if(action.payload.message.includes("User not found") || action.payload.message === "Invalid Token") {
    yield put({ type: actionTypes.LOG_OUT.SUCCESS, payload: {} });
    toast.info("Pilot is deleted.", { autoClose: 2000, position: "bottom-center" })
  } else {
    toast.info(action.payload.message, { autoClose: 2000, position: "bottom-center" })
  }
}

export function* authSaga() {
  // logout
  yield takeLatest(actionTypes.LOG_OUT.REQUEST, logout);
  yield takeLatest(actionTypes.LOG_OUT.SUCCESS, logoutSuccess);
  yield takeLatest(actionTypes.GET_PROFILE_BY_INVITEE_PILOT.REQUEST, getProfileByInviteePilot);
  yield takeLatest(actionTypes.GET_PROFILE_BY_INVITEE_PILOT.SUCCESS, getProfileByInviteePilotSuccess);
  yield takeLatest(actionTypes.GET_PROFILE_BY_INVITEE_PILOT.FAILURE, getProfileByInviteePilotFailure)

  yield all([fork(loginSaga)]);
  
}
