import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginLeft: theme.spacing(2),
  },
  list: {
    width: 300,
    height: "100vh",
  },
  filterBodyCont: {
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 20,
    display: "flex",
    alignItems: "center",
  },
  mainCont: {
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  closeIconStyle: {
    width: 20,
    height: 20,
    marginRight: 10,
    zIndex: 99,
    color: "black",
    borderRadius: 10,
    cursor: "pointer",
    background: "white",
  },
  titleStyle: {
    color: "black",
    fontSize: 15,
    fontWeight: "400",
  },
  fullList: {
    width: "100%",
  },
  mapView: {
    height: "100vh",
    width: "100%",
  },
  container: {
    flex: 1,
    height: "100vh",
    width: "100%",
    display: "flex",
    position: "relative",
  },
  menuButton: {
    marginLeft: 3,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  searchbody: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 16,
    width: "100%",
  },
  iconStyle: {
    height: 56,
    width: 23,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyHits: {
    width: 0,
    height: 0,
    marginTop: 0,
  },
  dialogRoot: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
    cursor: "pointer",
    width: "20px",
    height: "20px",
  },
  aircraftTypeContainer: {
    height: 56,
    width: "100%",
    justifyContent: "center",
    display: "flex",
    border: "1px solid #E4E2E2",
    borderRadius: 5,
  },
  formControl: {
    width: 130,
    marginLeft: 10,
    height: "100%",
  },
  selectEmpty: {},
  headingStyle: {
    width: "100%",
    marginBottom: 5,
    color: "white",
  },
  listItemStyle: {
    cursor: "pointer",
    color: "grey",
    border: "1px solid #555",
    borderRadius: 3,
    width: 60,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  dropdownHeading: {
    display: "flex",
    justifyContent: "space-evenly",
    height: 40,
    width: "100%",
    alignItems: "center",
  },
  tooltipStyle: {
    overflow: "hidden",
  },
  aircraftTitleStyle: {
    display: "flex",
    width: '100%',
    flexDirection: "column",
    background: "linear-gradient(to right,  #848484 ,#5A5959)",
  },
  aircraftTitle: {
    display: "flex",
    alignItems: "center",
    width: '100%',
    background: "linear-gradient(to right,  #848484 ,#5A5959)",
    color: "#fff",
    height: 30,
    paddingLeft: 16
  },
  gutters:{
    padding: 0
  },
  menuStyle: {
    width: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 40,
    border: "none",
    outlineColor: "#fff",
  },
  menuButtonStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "normal",
    cursor: "pointer",
    height: 56,
    width: "100%",
    border: "1px solid #E4E2E2",
    boxShadow:
      "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: 5,
  },
  aircraftNameStyle: {
    textTransform: "capitalize",
    height: 40,
    justifyContent: "space-between",
  },
  filterCont: {
    display: "flex",
    height: 80,
    width: "100%",
    justifyContent: "center",
    borderRadius: 5,
  },
  margin: {
    color: "black",
    "& *::-webkit-input-placeholder": {
      color: "black",
      opacity: 1,
    },
    border: "0px solid white",
    outline: "none",
  },
  notFilterSearchcard: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 80,
    marginLeft: (colors, isMobile) => (isMobile ? 15 : 0),
    width: (colors, isMobile) => (isMobile ? "85%" : "100%"),
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  filterSearchcard: {
    position: "absolute",
    top: 0,
    marginLeft: (colors, isMobile) => (isMobile ? 15 : 0),
    width: (colors, isMobile) => (isMobile ? "85%" : "100%"),
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  notFilterSearchContainer: {
    display: "flex",
    position: "absolute",
    alignContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    width: "100%",
    right: 0,
    height: 80,
    zIndex: 10,
    boxShadow:
      "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
  },
  filterSearchContainer: {
    display: "flex",
    position: "absolute",
    alignContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    right: 0,
    paddingTop: 10,
    zIndex: 10,
    boxShadow:
      "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
  },
  filterContainer: {
    border: "1px solid #E4E2E2",
    boxShadow:
      "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: 5,
    paddingLeft: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
  },
  filterContainer1: {
    border: "1px solid #E4E2E2",
    boxShadow:
      "0px -4px 10px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: 5,
    paddingLeft: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
  },
  filterStyle: {
    borderRadius: 3,
    padding: 5,
    display: "flex",
  },
  filterItemStyle: {
    color: "#555",
    fontWeight: "600",
  },
  imageStyle: {
    margin: 10,
    width: 10,
    height: 10,
    cursor: "pointer",
  },
  filterItemCont: {
    padding: 20,
    display: "flex",
    alignItems: "center",
  },
  loaderStyle: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flex: 1,
    height: "100vh",
    width: "100%",
    position: "relative",
  },
  noResultTextStyle: {
    padding: 10,
    color: "black",
    textAlign: "center",
  },
  iconContStyle: {
    display: "flex",
    alignItems: "center",
    zIndex: 99,
  },
  menuItemCont: {
    display: "flex",
    justifyContent: "space-between",
  },
  modalCont: {
    width: 390,
    height: 238,
    display: "flex",
    background: "#fff",
    padding: "18px 20px",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 5,
  },
  linkContMain: {
    width: "100%",
    height: 64,
    marginTop: 16,
    display: "flex",
    alignItems: "center",
  },
  linkCont: {
    color: "#333333",
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "center",
    lineHeight: 1.6,
    letterSpacing: "normal",
    marginTop: 10
  },
  link: {
    color: "#1e69f6",
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: "500",
    textDecoration: "underline",
    lineHeight: "1.47px",
    letterSpacing: "-0.25px",
  },
  buttonCont: {
    width: "100%",
    height: 40,
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonCont2: {
    width: "100%",
    height: 40,
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  buttonStyle: {
    padding: "12px 20px",
    borderRadius: "5px",
    border: "solid 1px #797f8a",
    backgroundColor: "#fff",
    gap: "10px",
    width: "165px",
    height: 40,
    color: "#333",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    cursor: "pointer",
    fontStyle: "normal",
    lineHeight: "1.14px",
    letterSpacing: "1px",
    "&:hover": {
      backgroundColor: "#fafafa",
    },
    textTransform: "capitalize",
  },
  buttonAcceptStyle: {
    padding: "12px 20px",
    borderRadius: "5px",
    gap: "10px",
    cursor: "pointer",
    width: "165px",
    height: 40,
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.14px",
    letterSpacing: "1px",
    backgroundImage: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
    color: "#fff",
    border: "solid 1px #df2935",
    textTransform: "capitalize",
    "&:hover": {
      color: "#fff",
      backgroundColor: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
      backgroundImage: "linear-gradient(to bottom, #df2935 0%, #b10000 100%)",
    },
  },
  warningImageStyle: {
    width: 50,
    height: 50,
  },
}));

export { useStyles };