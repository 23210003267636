import React, { useEffect, useState } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import moment from "moment";
import moment_timezone from "moment-timezone";
import FlatList from "flatlist-react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as services from "../../../../services/mainService";
import Collapsible from "react-collapsible";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DatePicker from "react-datepicker";
import "../../../../assets/styles/react-datepicker.css";
import { addDays, subMonths } from "date-fns";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "../../../../assets/styles/HistoryItemStyles";
import searchImg from "../../../../assets/images/search.png";
import { Wrap } from "../../../../containers/History";
import HistoryFilterValue from "./HistoryFilterValue";
import "./HistoryItems.css";
import { prettyString } from "../../../ui/TextTransform";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";

let historyList = [];

const HistoryItems = (props) => {
  const {
    requests = [],
    filteredValue,
    handleChangeSelect,
    setFilteredValue,
    search,
    handleDebounce,
    setHistoryFilter,
    historyFilter,
    setselectDate,
    selectDate,
  } = props;
  const { auth } = useSelector((state) => ({ auth: state.auth }));
  const classes = useStyles();
  const history = useHistory();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  useEffect(() => {
    historyList = requests;
  }, [requests]);

  const TransactionsListItem = (props) => {
    const { item, index } = props;
    let status = "pending";
    if (item.status === 2) {
      status = "acknowledged";
    } else if (item.status === 3) {
      status = "completed";
    } else if (item.status === 4) {
      status = "cancelled";
    } else if (item.status === 5) {
      status = "expired";
    }
    const transaction = {
      bookingId: item?.bookingId,
      fboId: item.fboDetail?.fboId,
      fbo: item?.fboName,
      airport: item?.aircraftRegistryName ?? " ",
      date: item.operatorDateOfUplift
        ? moment(item.operatorDateOfUplift).utc().format("MMM/DD/YYYY HH:mm")
        : moment(item.dateOfUplift)
            .tz(item.operatorTimeZone)
            .format("MMM/DD/YYYY HH:mm") +
          " (" +
          moment_timezone().tz(item.operatorTimeZone).zoneAbbr() +
          ")",
      status: status,
      fuelType: item?.fuelType,
      aircraft: item?.aircraftNameConcat ?? "",
      registry: item?.aircraftRegistryName ?? "",
      fuelCost: item.fuelCost.toString(),
      oldFuelCost: item.actualFuelCost.toString(),
      rampFee: item.rampFee.toString(),
      volume: item.volume.toString(),
      rate: item.rate.toString(),
      oldRate: item.actualRate.toString(),
      discountAmount: item.discountAmount || 0,
      isDiscount: item?.isDiscount,
      isOperatorSpecificDiscount: item?.isOperatorSpecificDiscount || false,
      isRegistryDiscount: item?.isRegistryDiscount || false,
      finalAmount: item.actualFuelCost || 0,
      isRated: item.isRated || false,
      rating: item.rating || 0,
      rateType: item.rateType,
      isPriceChange: item.isPriceChange || false,
      isPristFee: item.isPristFee || false,
      pristFee: item.pristFee || 0,
      pilotsDetail: item.pilotsDetail || [],
      discountFuelType: item.discountFuelType || 1,
      isTailWiseDiscount: item.isTailWiseDiscount || false,
      ancillaryServices: item.ancillaryServices || [],
      eta: item.operatorDateOfUplift
        ? moment(item.operatorDateOfUplift).utc().format("MMM/DD/YYYY HH:mm")
        : moment(item.dateOfUplift)
            .tz(item.operatorTimeZone)
            .format("MMM/DD/YYYY HH:mm") || "NA",
      etd: item.operatorEtd
        ? moment(item.operatorEtd).utc().format("MMM/DD/YYYY HH:mm")
        : moment(item.etd)
            .tz(item.operatorTimeZone)
            .format("MMM/DD/YYYY HH:mm") +
            " (" +
            moment_timezone().tz(item.operatorTimeZone).zoneAbbr() +
            ")" || "NA",
      flyingFrom: item.flyingFrom || "NA",
      flyingTo: item.flyingTo || "NA",
      quantity: item.volume || "",
      memberSince: item?.bookingCreatorRegDate,
      comments: item?.message,
      requestType: item?.requestType,
      isServiceRequest: item?.isServiceRequest,
      internationalFeeAndTaxes: item?.internationalFeeAndTaxes,
      isRampFee: item?.isRampFee,
      isFBOInternationalServices: item?.isFBOInternationalServices,
      internationalServicesCost: item?.internationalServicesCost,
      pristFeeAmount: item?.pristFeeAmount,
      actualRate: item?.actualRate,
      ancillaryServices: item?.ancillaryServices
    };

    const renderServicesList = () => {
      let tempArray = [];
      if (
        transaction.internationalFeeAndTaxes.length > 0 ||
        Object.values(transaction.internationalFeeAndTaxes).length > 0
      ) {
        transaction.internationalFeeAndTaxes.map((item, index) => {
          tempArray.push(item.name);
        });
        return tempArray.join(", ");
      } else {
        return "No service added.";
      }
    };

    const getDetails = async () => {
      const bookingDetails = await services.getNotificationDetail({
        notification: { bookingId: item.bookingId },
        token: auth.token,
      });
      if (bookingDetails.response_type === "success") {
        transaction.isParagonUser = bookingDetails.response.data?.isParagonUser;
        history.push({
          pathname: "/bookingDetail",
          state: { param: { fbo: transaction } },
        });
      }
    };

    return (
      <Wrap>
        <Collapsible
          trigger={
            <Grid
              container
              spacing={0}
              item
              xs={12}
              className={classes.collapsibleCont}
            >
              <Grid item className={classes.bodyCont}>
                <Grid
                  item
                  style={{ width: "10%" }}
                  className={classes.imageContainer}
                >
                  <ExpandMoreIcon />
                </Grid>
                <Grid
                  item
                  style={{ width: "18%", paddingRight: 10 }}
                  className={classes.tableBody}
                >
                  {item?.fboName}
                </Grid>
                <Grid
                  item
                  style={{ width: "18%", paddingRight: 10 }}
                  className={classes.tableBody}
                >
                  {prettyString(item?.aircraftNameConcat)}
                </Grid>
                <Grid
                  item
                  style={{ width: "18%", paddingRight: 10, paddingLeft: 2 }}
                  className={classes.tableBody}
                >
                  {transaction.requestType === 2
                    ? transaction.eta
                    : "Aircraft on Ground"}
                </Grid>
                <Grid
                  item
                  style={{ width: "18%", paddingRight: 10, paddingLeft: 6 }}
                  className={classes.tableBody}
                >
                  {item.isServiceRequest
                    ? "NA"
                    : transaction?.volume === "0"
                    ? "Captain's Request"
                    : transaction?.volume}
                </Grid>
                <Grid
                  item
                  style={{ width: "18%", paddingRight: 10, paddingLeft: 11 }}
                  className={classes.tableBody}
                >
                  {item.isServiceRequest
                    ? "NA"
                    : transaction.fuelType === 1
                    ? "Jet A"
                    : "100 LL"}
                </Grid>
              </Grid>
            </Grid>
          }
        >
          <Grid container className={classes.collapsedCont}>
            {!item.isServiceRequest ? (
              <Grid
                container
                item
                xs={12}
                className={classes.bodyCont}
                style={{ cursor: "pointer" }}
                key={`${index}${item?.bookingCreatedByDetail?._id}`}
                onClick={() => getDetails()}
              >
                {!item.isServiceRequest ? (
                  <>
                    <Grid item xs={12} className={classes.mainHeading}>
                      <Grid item xs={2} className={classes.tableHeading}>
                        Fee:{" "}
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        className={classes.tableBody2}
                      >{`$${parseFloat(item.rampFee).toFixed(2)}`}</Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.mainHeading}>
                      <Grid item xs={2} className={classes.tableHeading}>
                        Discount:{" "}
                      </Grid>
                      <Grid item xs={10} className={classes.tableBody2}>
                        {(transaction.rateType === 3 ||
                          transaction.rateType === 10 ||
                          transaction.isPriceChange ||
                          transaction.isDiscount ||
                          transaction.isOperatorSpecificDiscount ||
                          transaction.isRegistryDiscount ||
                          transaction.isTailWiseDiscount) &&
                        transaction.discountAmount > 0 ? (
                          <div>
                            {`$${parseFloat(
                              transaction?.discountAmount
                            ).toFixed(2)}`}
                          </div>
                        ) : (
                          "NA"
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.mainHeading}>
                      <Grid item xs={2} className={classes.tableHeading}>
                        Final Amount(Incl. Tax):{" "}
                      </Grid>
                      <Grid item xs={10} className={classes.finalAmountStyle}>
                        <Grid className={classes.fuelCostStyle}>{`$${formatNumWithCommas(parseFloat(
                          +transaction?.fuelCost +
                            transaction.internationalServicesCost
                        ).toFixed(2))}`}</Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                {transaction.isServiceRequest &&
                transaction.isFBOInternationalServices ? (
                  <Grid item xs={12} className={classes.mainHeading}>
                    <Grid item xs={2} className={classes.tableHeading}>
                      Services & Fees:
                    </Grid>
                    <Grid item xs={10} className={classes.tableBody2}>              
                        <div>{renderServicesList()}</div>    
                    </Grid>
                  </Grid>
                ) : null}
                {transaction.comments !== undefined &&
                transaction.comments !== "" ? (
                  <Grid item xs={12} className={classes.mainHeading}>
                    <Grid item xs={2} className={classes.tableHeading}>
                      Comments:{" "}
                    </Grid>
                    <Grid item xs={10} className={classes.tableBody2}>
                      {transaction.comments.length > 100 ? (
                        <Tooltip
                          title={
                            <div className={classes.commentStyle}>
                              {transaction.comments}
                            </div>
                          }
                        >
                          <div>
                            {transaction.comments.slice(0, 100)}
                            <span>...</span>
                          </div>
                        </Tooltip>
                      ) : (
                        <div>{transaction.comments}</div>
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            ) : (
                <Grid container item xs={12} className={classes.containerStyle} onClick={() => getDetails()}>
                  This FBO is not providing fuel services.
              </Grid>
            )}
          </Grid>
        </Collapsible>
      </Wrap>
    );
  };

  const handleClose = () => {
    setOpenDatePicker(false);
    setHistoryFilter("");
  };

  const onSelectDate = (selectedDate) => {
    setselectDate(selectedDate);
    props.onSelectDate(selectedDate);
  };

  const openPickerList = (value) => {
    if (value === true) {
      setOpenDatePicker(true);
    }
  };

  return (
    <div>
      <div className={classes.mainCont}>
        <div className={classes.inputContainer} id="input_container">
          <input
            value={search}
            type="text"
            id="input"
            autoFocus={search.length > 0 ? true : false}
            className={classes.inputCont}
            autoComplete="off"
            style={{ width: 440 }}
            placeholder="Search by FBO Name, Aircraft Tail & Aircraft Type"
            onChange={(e) => {
              handleDebounce(e.target.value);
            }}
          />
          <img alt='' src={searchImg} className={classes.searchIcon} />
        </div>
        <HistoryFilterValue
          handleChangeSelect={handleChangeSelect}
          setFilteredValue={setFilteredValue}
          filteredValue={filteredValue}
          selectDate={selectDate}
          openPickerList={openPickerList}
          setHistoryFilter={setHistoryFilter}
          historyFilter={historyFilter}
          setselectDate={setselectDate}
        />
      </div>
      <Grid container item xs={12} className={classes.dflex}>
        <Grid item style={{ width: "10%" }} className={classes.tableHead} />
        <Grid
          item
          style={{ width: "18%", paddingLeft: 8 }}
          className={classes.tableHead}
        >
          FBO Name
        </Grid>
        <Grid
          item
          style={{ width: "18%", paddingLeft: 4 }}
          className={classes.tableHead}
        >
          Aircraft Type
        </Grid>
        <Grid item style={{ width: "18%" }} className={classes.tableHead}>
          Date(ETA)
        </Grid>
        <Grid item style={{ width: "18%" }} className={classes.tableHead}>
          Fuel Requested
        </Grid>
        <Grid item style={{ width: "18%" }} className={classes.tableHead}>
          Fuel Type
        </Grid>
      </Grid>
      <div className={classes.historyListStyles}>
        <FlatList
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          list={historyList}
          renderItem={(item, index) => {
            return (
              <TransactionsListItem item={item} key={index} index={index} />
            );
          }}
          renderWhenEmpty={() => (
            <h4 className={classes.noDataStyle}>No data found</h4>
          )}
        />
      </div>

      <Dialog
        open={openDatePicker}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            boxShadow: "10",
          },
        }}
      >
        <DialogActions>
          <div className={classes.dialogRoot}>
            <DatePicker
              selected={selectDate}
              calendarClassName={classes.datePickerStyle}
              dayClassName={classes.dayStyle}
              onChange={onSelectDate}
              maxDate={addDays(new Date(), 14)}
              minDate={subMonths(new Date(), 2)}
              inline
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HistoryItems;
