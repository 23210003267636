import React from "react";
import { Divider, Grid, Tooltip } from "@material-ui/core";
import Collapsible from "react-collapsible";
import { isMobile } from "react-device-detect";

import colors from "../../../../constants/colors";
import { Wrap } from "../../../../containers/History";
import { useStyles } from "../../../../assets/styles/FboItemStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import formatNumWithCommas from "../../../../utils/helpers/formatNumWithCommas";

const InternationalServices = (props) => {
  const { item, navigation } = props;
  const classes = useStyles(colors, isMobile);

  const triggerView = () => (
    <Grid container>
      <Grid item xs={12} className={classes.triggerStyle}>
        <Grid className={classes.serviceHeadingStyle}>Services & Fees</Grid>
        <Grid>
          <ExpandMoreIcon className={classes.expandIconStyle} />
        </Grid>
      </Grid>
    </Grid>
  );

  const collapsibleView = (item) => (
    <Grid className={classes.collapsibleCont}>
      <Divider className={classes.divider} />
      {item.map((item) => (
        <Grid container className={classes.detailContainer}>
          <Tooltip title={item.name} disableHoverListener={item.name.length < 26 ? true : false}>
          <Grid item xs={9}>
            <div className={classes.nameStyle}>{item.name}</div>
          </Grid>
          </Tooltip>
          <Grid item xs={3}>
            <div className={classes.valueStyle}>
            {item.price !== undefined ? `$${formatNumWithCommas(parseFloat(item?.price).toFixed(2))}`:''}
            </div>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const servicesListView = (item) => {
    return item.map((item, index) => (
      <Grid container className={classes.listContainer}>
        <Grid item xs={9} className={classes.detailViewCont}>
          <Tooltip title={item.name} disableHoverListener={item.name.length < 26 ? true : false}>
            <div className={classes.detailNameStyle}>{item.name}</div>
          </Tooltip>
        </Grid>
        <Grid item xs={3} className={classes.valueStyle}>
          {item.price !== undefined ? `$${formatNumWithCommas(parseFloat(item?.price).toFixed(2))}`: "" }
        </Grid>
      </Grid>
    ));
  };

  return (
    <>
      {navigation === "fboDetail" ? (
        <Grid container className={classes.serviceContainer}>
          <Grid item xs={12} className={classes.headingDetailTextStyle}>
            Services & Fees
          </Grid>
          <Grid item xs={12} className={classes.servicesListContainer}>
            {servicesListView(item)}
          </Grid>
        </Grid>
      ) : (
        <Wrap>
          <Collapsible tabIndex={0} trigger={triggerView()}>
            {collapsibleView(item)}
          </Collapsible>
        </Wrap>
      )}
    </>
  );
};

export default InternationalServices;
