import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "../../controls/Input";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../../constants/colors";
import { getProfile } from "../../../redux/account/account.actions";
import { toast } from "react-toastify";
import * as services from "../../../services/mainService";
import FieldSet from "../app/Fuelrequest/FieldSet";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { prettyString, oneSpace, textString} from "../../ui/TextTransform";

export const ValidationTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      color: "black",
    },
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      border: "1px solid black !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        color: "black",
      },
      "&:hover fieldset": {
        border: "1px solid black !important",
      },
      "&.Mui-focused fieldset": {
        textTransform: "capitalize",
        border: "1px solid black !important",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  items: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    alignItems: "center",
    borderBottom: "#000",
    cursor: "pointer",
  },
  checkMark: {
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    borderWidth: 2,
    borderColor: colors.primaryColor,
    fontSize: isMobile ? 10 : 14,
    padding: 5,
    textAlign: "left",
    marginRight: 20,
    border: "1px solid red",
    cursor: "pointer",
  },
  checkMarkFill: {
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    borderWidth: 2,
    borderColor: colors.primaryColor,
    backgroundColor: colors.primaryColor,
    fontSize: isMobile ? 10 : 14,
    padding: 5,
    textAlign: "left",
    marginRight: 20,
    border: "1px solid red",
    cursor: "pointer",
  },
  textStyle: {
    color: "grey",
    fontSize: 14,
  },
  emailTextStyle: {
    fontSize: 14,
    color: "black",
  },
  submitBtn: {
    backgroundColor: colors.primaryColor,
    color: "#fff",
    padding: 12,
    borderRadius: 5,
    height: 45,
    "&:hover": {
      backgroundColor: colors.primaryColor,
    },
  },
  paragonSubmit: {
    background: colors.paragonPrimaryColor,
    backgroundColor: colors.paragonPrimaryColor,
    "&:hover": {
      backgroundColor: colors.paragonPrimaryColor,
    },
  },
  aircraftNameStyle: {
    backgroundColor: "#ddd",
    border: "1px solid #ddd",
    borderColor: "#000",
    height: 56,
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
  },
  container: {
    width: "100%",
    position: "relative",
  },
  itemStyle: {
    textTransform: "lowercase",
    "& > :first-letter": {
      textTransform: "capitalize",
    },
  },
  aircraftStyle: {
    color: "black",
    textTransform: "lowercase",
    "& > :first-letter": {
      textTransform: "capitalize",
    },
  },
  paper: {
    textTransform: "capitalize",
  },
  phonenNumberStyle: {
    marginTop: -20,
    marginBottom: 20,
    width: "100%",
    height: 60,
  },
  inputContainer: {
    marginTop: -13,
    marginBottom: 20,
    width: "100%",
    height: 54,
    marginLeft: -10,
    display: "flex",
    alignItems: "center",
  },
  phoneInputCont: {
    border: "none",
    height: 54,
    width: "100%",
    color: "black",
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
  },
}));

export default function AddManagerOperator(props) {
  const {
    setOpenPopup: setOpenAddPopup,
    setOpenEditPopup,
    editObj = {},
    isEdit = false,
    editManager,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+1");

  const validateForm = () => {
    var pattern = new RegExp(/^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i);
    return (
      pattern.test(email) &&
      phone.slice(countryCode.length).length > 8 &&
      first_name !== null &&
      first_name !== "" &&
      title !== null &&
      title !== ""
    );
  };

  const { auth, account } = useSelector((state) => ({
    auth: state.auth,
    account: state.account,
  }));

  const onOperatorFormSubmit = async () => {
    if (isEdit === false) {
      const data = {
        title: title,
        first_name: first_name,
        last_name: last_name,
        email: email.toLowerCase(),
        countryCode: `+${countryCode}`,
        phone: phone.slice(countryCode.length)
      };
      const managerObj = {
        manager: data,
        token: auth.token,
      };
      if (
        account?.user?.primaryManagerDetail !== undefined &&
        account?.user?.primaryManagerDetail.length === 0
      ) {
        const response = await services.addOperatorManagerApi(managerObj);
        if (response.response_type === "success") {
          toast("Additional Manager added successfully.", {
            position: "bottom-center",
            closeButton: false,
          });
          dispatch(
            getProfile({
              token: auth.token,
            })
          );
          setOpenAddPopup(false);
        } else {
          toast.info(response.response.message, {
            position: "bottom-center",
            closeButton: false,
          });
        }
      } else {
        const res = await services.addAdditionalOperatorManagerApi(managerObj);
        if (res.response_type === "success") {
          dispatch(
            getProfile({
              token: auth.token,
            })
          );
          toast("Additional Manager added successfully.", {
            position: "bottom-center",
            closeButton: false,
          });
          setOpenAddPopup(false);
        } else {
          toast.info(res.response.message, {
            position: "bottom-center",
            closeButton: false,
          });
        }
      }
    } else {
      const data = {
        _id: editObj?._id,
        title: title,
        first_name: first_name,
        last_name: last_name,
        email: email,
        email_group: {
          arrival_request: editObj.email_group ? editObj.email_group.arrival_request : false,
        },
        countryCode: countryCode[0] === "+" ? countryCode : `+${countryCode}`,
        phone: phone.slice(countryCode.length),
      };
      const managerObj = {
        manager: data,
        token: auth.token,
      };
      const res = await services.editManagerApi(managerObj);
      if (res.response_type === "success") {
        toast(res.response.message, {
          position: "bottom-center",
          closeButton: false,
        });
        setOpenEditPopup(false);
      } else {
        toast.info(res.response.message, {
          position: "bottom-center",
          closeButton: false,
        });
      }
    }
  };

  React.useEffect(() => {
    if (isEdit === true) {
      setTitle(editObj?.title);
      setEmail(editObj?.email);
      setFirstName(editObj?.first_name);
      setLastName(editObj?.last_name);
      if (editObj?.phone !== undefined) {
        setPhone(
          editObj?.countryCode === undefined
            ? editObj?.phone
            : editObj?.countryCode + editObj?.phone
        );
      } else {
        setPhone("");
      }
      setCountryCode(
        editObj?.countryCode !== undefined ? editObj?.countryCode : ""
      );
    }
  }, [
    editObj?.email,
    editObj?.phone,
    editObj?.first_name,
    editObj?.last_name,
    editObj?.title,
    editObj?.countryCode,
    isEdit,
  ]);

  return (
    <form autoComplete="off" className={classes.form} noValidate>
      <div className={classes.container}>
        <TextField
          theme={"black"}
          required
          label="First Name"
          placeholder="First Name"
          value={first_name}
          onChange={(e) => {
            if (e.target.value.match("^(?!\\s)[a-zA-Z', &]*$") !== null) {
              setFirstName(textString(e.target.value.split('').filter(oneSpace).join('')));
            }
          }}
        />

        <TextField
          theme={"black"}
          required
          label="Last Name"
          placeholder="Last Name"
          value={last_name}
          onChange={(e) => {
            if (e.target.value.match("^(?!\\s)[a-zA-Z', &]*$") !== null) {
              setLastName(textString(e.target.value.split('').filter(oneSpace).join('')));
            }
          }}
        />

        <TextField
          theme={"black"}
          required
          label="Job Title"
          placeholder="Job Title"
          value={title}
          onChange={(e) => {
              if (e.target.value[0] === " " && e.target.value[0] !== undefined) {
                setTitle("");
              } else {
                setTitle(prettyString(e.target.value));
              }
          }}
        />

        <div className={classes.phonenNumberStyle}>
          <FieldSet legend={"Phone*"} navigation={"fromContact"}>
            <div className={classes.inputContainer}>
              <PhoneInput
                country={"us"}
                inputProps={{ id: "phone" }}
                value={phone}
                enableSearch={true}
                enableAreaCodes={false}
                specialLabel="Phone*"
                placeholder="Phone*"
                autoFormat={true}
                disableSearchIcon={true}
                inputStyle={{
                  height: 54,
                  width: "100%",
                  border: "none",
                  color: "black",
                }}
                containerStyle={{
                  width: "100%",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  height: 54,
                  borderRadius: 5,
                }}
                buttonStyle={{ border: "none", background: "transparent" }}
                onChange={(phone, data) => {
                  setPhone(phone);
                  setCountryCode(data.dialCode);
                }}
              />
            </div>
          </FieldSet>
        </div>

        <TextField
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
          theme={"black"}
          label="Email"
          placeholder="Email"
        />
        <div>
          <Button
            disabled={!validateForm()}
            onClick={() => onOperatorFormSubmit()}
            fullWidth
            className={classes.submitBtn}
          >
            {isEdit ? (
              <div>{editManager === "primary" ? "Update" : "Update"}</div>
            ) : (
              <div>
                {"Add"}
              </div>
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}
