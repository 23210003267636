import { ListItem, ListItemText } from "@material-ui/core";
import { useStyles } from "../../../../assets/styles/SettingStyles";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { favouriteFboItem } from "../../../../redux/fbos/fbos.actions";

const Settings = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  let currentVersion = localStorage.getItem("nozl_version");

  useEffect(() => {
    dispatch(favouriteFboItem({}));
  }, []);

  const onTermsConditionClick = () => {
    let win = window.open(process.env.REACT_APP_TERMS_CONDITION, "_blank");
    win.focus();
  };

  const onPrivacyPolicyClick = () => {
    let win = window.open(process.env.REACT_APP_PRIVACY_POLICY, "_blank");
    win.focus();
  };

  const onContactAdminClick = () => {
    window.open("mailto:support@paragonavaitaiongroup.com");
  };

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <ListItem
          className={classes.listItem}
          onClick={() => history.push("/profile")}
        >
          <ListItemText
            className={`
                 ${
                   history.location.pathname === "/profile"
                     ? classes.activeMenuTxt
                     : ""
                 }`}
            primary={`Profile`}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit">
              {history.location.pathname === "/profile" ? (
                <ChevronRightIcon />
              ) : (
                ""
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
      <div className={classes.card}>
        <ListItem
          className={classes.listItem}
          onClick={() => history.push("/change-password")}
        >
          <ListItemText
            className={`
                 ${
                   history.location.pathname === "/change-password"
                     ? classes.activeMenuTxt
                     : ""
                 }`}
            primary={`Change Password`}
          />
          <ListItemSecondaryAction>
            {history.location.pathname === "/change-password" ? (
              <IconButton edge="end" aria-label="edit">
                <ChevronRightIcon />
              </IconButton>
            ) : (
              ""
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </div>

      <div className={classes.card}>
        <ListItem
          className={classes.listItem}
          onClick={() => history.push("/invite-friends")}
        >
          <ListItemText
            className={`
                 ${
                   history.location.pathname === "/invite-friends"
                     ? classes.activeMenuTxt
                     : ""
                 }`}
            primary={`Invite Friends`}
          />
          <ListItemSecondaryAction>
            {history.location.pathname === "/invite-friends" ? (
              <IconButton edge="end" aria-label="edit">
                <ChevronRightIcon />
              </IconButton>
            ) : (
              ""
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </div>
      <div className={classes.card}>
        <ListItem className={classes.listItem} onClick={onContactAdminClick}>
          <ListItemText primary={`Contact Admin`} />
        </ListItem>
      </div>

      <div className={classes.card}>
        <ListItem className={classes.listItem} onClick={onTermsConditionClick}>
          <ListItemText primary={`Terms & Conditions`} />
        </ListItem>
      </div>

      <div className={classes.card}>
        <ListItem
          className={classes.listItem}
          onClick={onPrivacyPolicyClick}
        >
          <ListItemText primary={`Privacy Policy `} />
        </ListItem>
      </div>
      <div className={classes.card}>
        <ListItem
          className={classes.listItem}
          onClick={() => history.push("/contact-us")}
        >
          <ListItemText
            className={`
                 ${
                   history.location.pathname === "/contact-us"
                     ? classes.activeMenuTxt
                     : ""
                 }`}
            primary={`Contact Us`}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit">
              {history.location.pathname === "/contact-us" ? (
                <ChevronRightIcon />
              ) : (
                ""
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
      <div className={`${classes.versionTxt} `}>          
          {`Version: ${currentVersion}`}
      </div>
    </div>
  );
};
export default Settings;
