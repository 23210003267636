import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import AircraftAdded from "./AircraftAdded";
import AddAircraftDetails from "./AddAircraftDetails";

const useStyles = makeStyles(() => ({
  addNewUserStyle: {
    marginLeft: 30,
  },
}));

const AircraftListData = (props) => {
  const classes = useStyles();
  const { aircraftList, addAircraft, editListItem } = props;

  const deleteItem = (item) => {
    props.deleteItem(item);
  };

  const addNewAircraft = (newAircraft) => {
    props.addNewAircraft(newAircraft);
  };

  const editItem = (item, index) => {
    props.editItem(item, index);
  };

  const cancelUser = (value) => {
    props.cancelUser(value);
  };

  return (
    <div style={{ overflowX: "scroll", display: "flex", overflowY: "hidden" }}>
      <AircraftAdded
        aircraftList={aircraftList}
        addAircraft={addAircraft}
        editItem={editItem}
        deleteItem={deleteItem}
        cancelUser={cancelUser}
        addNewAircraft={addNewAircraft}
        editListItem={editListItem}
      />
      <Grid className={classes.addNewUserStyle}>
        {addAircraft === true && (
          <AddAircraftDetails
          cancelUser={cancelUser}
            addNewAircraft={addNewAircraft}
            title="Aircraft"
          />
        )}
      </Grid>
    </div>
  );
};

export default AircraftListData;
