import React from "react";

import FboItem from "./FboItem";

const FboList = (props) => {

  const { data, unlistedFbos } = props;

  const clickFboItem = (item, isClick, isUnlisted) => {
    if (isClick === true) {
      props.selectFboItem(item, isClick, isUnlisted);
    }
  };

  const showFboDetail = (item, value) => {
    if (item !== undefined) {
      props.fboDetailList(item, value);
    }
  }

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      {data.length !== undefined ?
        <div>{(data.length > 0 || Object.values(data).length > 0)
          ? data.map((item) => {
            return (
              <div>
                <FboItem
                  item={item}
                  isUnlisted={false}
                  clickFboItem={clickFboItem}
                  showFboDetail={showFboDetail}
                />
              </div>
            );
          })
          : null}
          {(unlistedFbos.length > 0 || Object.values(unlistedFbos).length > 0) || (unlistedFbos !== undefined &&
            unlistedFbos !== null)
            ? unlistedFbos.map((item) => {
              return (
                <div>
                  <FboItem
                    item={item}
                    isUnlisted={true}
                    clickFboItem={clickFboItem}
                    showFboDetail={showFboDetail}
                  />
                </div>
              );
            })
            : null}
        </div>
        :
        <FboItem
          unlistedFbos={props.unlistedFbos}
          item={props.data}
          isUnlisted={props.isUnlisted}
          clickFboItem={clickFboItem}
          showFboDetail={showFboDetail}
        />
      }
    </div>
  );
};

export default FboList;