export const searchHelper = (search, arrayList) => {
  return new Promise((resolve, reject) => {
    try {
      let tempList = [];
      arrayList.map((section) => {
        let data = {
          title: section.title,
          data: section.data.filter(
            (e) =>
              e?.registryName
                .toString()
                ?.toLowerCase()
                ?.includes(search?.toLowerCase()) ||
              e?.name
                ?.toString()
                ?.toLowerCase()
                ?.includes(search?.toLowerCase()) ||
              e?.airplaneDetail?.airplaneMakerDetail?.name
                ?.toString()
                ?.toLowerCase()
                .includes(search?.toLowerCase())
          ),
        };
        if (data.data.length) {
          tempList.push(data);
        }
      });
      resolve(tempList);
    } catch (error) {
      reject(error);
    }
  });
};
